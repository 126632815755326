import { PrivilegeCardModel } from './../../models/privilege-card';
import { DataService } from './../../../components/app-send-gift/services/shared/data.service';
import { NewPrivilegeCardComponent } from './../new-privilege-card/new-privilege-card.component';
import { Component, OnInit, Injector } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { CompanyRole } from 'src/app/enums';
import { CompanyService, AccountServiceService, AlertService, CommonService } from 'src/app/services';
import { BaseComponent } from 'src/app/components/base/base.component';


@Component({
    selector: 'app-privilege-card',
    templateUrl: './privilege-card.component.html',
    styleUrls: ['./privilege-card.component.css']
})
export class PrivilegeCardComponent extends BaseComponent implements OnInit {

    userInfo: any;
    dataLoaded: Promise<boolean>;

    currentPage: number = 1;
    pageSize: number = 10;
    itemCountText: string;
    totalCards: number;

    pageTitle: string = "Card Creation & Assignment";
    offerProviderSelection: string = "Cards";

    merchants: any[];
    merchantFilter: any = { name: '' };
    searchedMerchantLogo: string = null;
    showMerchantSearch: boolean = false;
    selectedMerchantId: number = 0;

    offerProviders: any[];
    offerProviderFilter: any = { name: '' };
    searchOfferProviderLogo: string = null;
    showOfferProviderSearch: boolean = false;
    selectedOfferProviderId: number = 0;

    cardSubmenu: any[] = [];
    activeSubMenu: string = 'Details';
    selectedSubMenuIndex: number = 0;
    hasToShowOfferTextImg = false;
    isMerchantLoggedIn: boolean = false;

    // privilege card
    cards: any[];
    filteredCards: any[];
    cardFilter: any = { cardName: '' };
    selectedCard: PrivilegeCardModel;
    selectedCardIndex: number = -1;
    selectedCardName: string;

    constructor(private dialog: MatDialog, private dataService: DataService,
        private companyService: CompanyService,
        private accountService: AccountServiceService,
        private alertService: AlertService,
        private commonService: CommonService,
        injector: Injector
    ) { super(injector) }

    ngOnInit() {
        if (this.checkPermission(['ViewGolpCard'])) this.cardSubmenu.push({ "name": "Details", "dataTarget": "cardDetailsTab" });
        if (this.checkPermission(['CreateGolpCard'])) this.cardSubmenu.push({ "name": "Card Issuers", "dataTarget": "cardIssuersTab" });
        if (this.checkPermission(['ViewGolpCard'])) this.cardSubmenu.push({ "name": "Offers and Discounts", "dataTarget": "cardFacilitiesTab" });
        if (this.checkPermission(['ViewCardBeneficiary'])) this.cardSubmenu.push({ "name": "Assignments", "dataTarget": "cardAssignmentsTab" });

        this.getLoggedInUser();
        this.getMerchants();
        this.getOfferProviders();
        this.dataLoaded = Promise.resolve(true);

        this.getCards();
    }

    getCards() {
        let url = this.checkPermission(['CreateGolpCard']) ? "api/Cards" : `api/cards?issuerId=${this.userInfo?.companyId}`;
        this.dataService.get(url).subscribe((res: any) => {
            if (res.success) {
                this.cards = res.data;
                this.filteredCards = this.cards.filter(x => x != null);
                this.totalCards = res.data.length;
                this.itemCount();
                this.highlightFirstItem(this.cards);
            }
            else {
                this.alertService.error("Something went wrong");
            }
        }, err => {
            console.log(err);
        });
    }

    createPrivilegeCard() {
        const dialogConfig = new MatDialogConfig();

        // Setting different dialog configurations
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'golp-dialog';
        const cardCreateDialog = this.dialog.open(NewPrivilegeCardComponent, dialogConfig);


        cardCreateDialog.afterClosed().subscribe(() => {
        });
    }

    onCardChange(card, index) {
        if (this.selectedCardIndex != index) {
            this.selectedCardIndex = index === this.selectedCardIndex ? -1 : index;
            this.selectedCard = card;
        }
        let body = document.getElementsByTagName('body')[0];
        body.classList.add("display-tab-content");
        this.commonService.itemSelectionUpdated(this.selectedCard);
    }

    onSubMenuChange(name, index) {
        if (this.selectedSubMenuIndex != index) {
            this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
            this.activeSubMenu = this.cardSubmenu?.filter(c => c.name === name)[0].name;
        }
    }

    // capture child component change event
    getCardsDetailsChange(event) {
        let card = this.cards?.filter(c => c.cardId === event.cardId)[0];
        card.cardName = event.cardName;
        card.validFrom = event.validFrom;
        card.validTill = event.validTill;
        card.isActive = event.isActive;
        this.selectedCard = card;
    }

    cardFilteredItem(value) {
        this.selectedCardName = value;
        this.getAndSetFilteredElementByName(value);
    }

    getAndSetFilteredElementByName(name: string) {
        name = name.toLowerCase();
        if (name == "") {
            this.filteredCards = this.cards?.filter(x => x != null);
        }
        this.filteredCards = this.cards?.filter(x => x.cardName.toLowerCase().includes(name));
        this.highlightFirstItem(this.filteredCards);
    }

    getMerchants() {
        this.companyService.getMerchantBasics()
            .subscribe(data => {
                this.merchants = data;

                if (data.length > 1 || !(CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin)) {
                    this.showMerchantSearch = true;
                }
            });
    }

    getOfferProviders() {
        this.companyService.getOfferProviderBasics()
            .subscribe(data => {
                this.offerProviders = data;
                if (this.userInfo.isSystemAdmin) {
                    this.showOfferProviderSearch = true;
                }
            });
    }

    getLoggedInUser() {
        this.userInfo = this.accountService.getLoggedInUserInfo();
        if (CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
            this.isMerchantLoggedIn = true;
        }
    }

    highlightFirstItem(cards: any[]) {
        if (this.filteredCards !== null) {
            this.selectedCard = this.filteredCards[0];
            this.selectedCardIndex = 0;
            this.dataLoaded = Promise.resolve(true);
        }
        else if (cards != null && cards.length > 0) {
            this.selectedCard = cards[0];
            this.selectedCardIndex = 0;
            this.dataLoaded = Promise.resolve(true);
        }
    }

    pageChanged(event) {
        this.selectedCardIndex = 0;
        this.currentPage = event;
    }

    itemCount() {
        let firstItem = (this.currentPage - 1) * this.pageSize + 1;
        let lastItem = (this.currentPage) * this.pageSize;
        if (lastItem > this.totalCards)
            lastItem = this.totalCards;
        this.itemCountText = firstItem + '-' + lastItem + ' of ' + this.totalCards + ' Cards';
    }

    setSelectedOffer(cards) {
        if (cards.length > 0) {
            this.selectedCardIndex = 0;
            this.selectedCard = cards[0];
        }
        else {
            this.selectedCard = null;
        }
    }

    errorHandlerOnImg(offerId) {
        document.getElementById('merchantImgLogotxt_' + offerId).style.display = "block";
        document.getElementById('merchantImgLogo_' + offerId).style.display = "none";
    }
}
