import { Component, OnInit } from '@angular/core';
import * as SignalR from '@aspnet/signalr';
import { AlertService, NotificationService, CommonService } from 'src/app/services';

@Component({
  selector: 'app-header-notification',
  templateUrl: './header-notification.component.html',
  styleUrls: ['./header-notification.component.css']
})
export class HeaderNotificationComponent implements OnInit {

  notifications: any[];
  hasUnreadNotification: boolean = true;

  constructor(private notificationService: NotificationService,
    private alertService: AlertService,
    private commonService: CommonService
    ) { }

  ngOnInit() {
    this.getHeaderNotification();
    // signalR notification

    let options = {
      transport: SignalR.HttpTransportType.WebSockets | SignalR.HttpTransportType.LongPolling,
      accessTokenFactory: async () => {
        return localStorage.getItem('userToken');
      }
    };

    const _hubConnection = new SignalR.HubConnectionBuilder().withUrl('/notify', options).build();
    _hubConnection
      .start()
      .then(() => console.log('connection started!'))
      .catch(err => console.log('error while establishing connection'));
    _hubConnection.on('BroadcastMessage',
      (messageType: string, message: string) => {
        if (messageType == 'backgroundJob') {
         let model = JSON.parse(message);
         this.commonService.backgroundJobEventEmitter(model);
        }
        // else if(messageType == 'availRequest')
        // {
        //   // fire a event, a new avail request come.
        //   console.log('new avail request come');
        //   this.commonService.newAvailRequestEventEmitter("new request");
        // }
         else {
          this.alertService.info(message);
          this.getHeaderNotification();
          this.hasUnreadNotification = true;
        }

      });
  }

  getHeaderNotification(){
    this.notificationService.getHeaderNotification()
      .subscribe((data:any) => {
        this.notifications = data.data;
        let unreadNotificationIndex = this.notifications.findIndex(c => c.readStatus == false);
        if(unreadNotificationIndex < 0){
          this.hasUnreadNotification = false;
        }
      });
  }

  markAllAsRead(){
    this.hasUnreadNotification = false;

    this.notificationService.markAllNotificationAsRead()
      .subscribe((data:any) => {
        for (let notification of this.notifications) {
          if (notification) {
            notification.readStatus = true;
          }
        }
      },
      (error)=>{
        console.log(error);
      });

    return;
  }
  onHeaderNotifcationImgErrEvent(event, notificationId) {
    document.getElementById('headerNotifcationImgText_' + notificationId).style.display = "block";
    document.getElementById('headerNotifcationImg_' + notificationId).style.display = "none";
  }
}
