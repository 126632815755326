import { Component, OnInit } from '@angular/core';
import { ServiceConsumersService } from 'src/app/services/service-consumers.service';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/services';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-lock-unlock',
  templateUrl: './lock-unlock.component.html',
  styleUrls: ['./lock-unlock.component.css']
})
export class LockUnlockComponent implements OnInit {

  userInfo: any;
  consumer: any;
  phone: any;
  notFoundMessage: string = "search user phone number";
  noCouponFound: boolean = true;

  constructor(
    private consumerService: ServiceConsumersService,
    private dialog: MatDialog,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
  }

  searchConsumer() {
    this.consumer = {};
    this.noCouponFound = true;
    if (this.phone == undefined) return;
    if (this.phone.length == 0) return;
    this.searchConsumerByPhone(this.phone);
  }

  searchConsumerByPhone(phone: any) {
    this.consumerService
      .searchConsumerByPhone(phone)
      .subscribe((result) => {
        if (result.success) {
          this.noCouponFound = false;
          this.consumer = result.data;
        }
      },
        (error) => {
          this.notFoundMessage = error.data;
        });
  }

  onLockUser(item: any) {
    const confirmationDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    confirmationDialogConfig.data = `Are you sure, you want to lock this user.`;
    confirmationDialogConfig.panelClass = "golp-dialog";
    const confirmationDialog = this.dialog.open(
      ConfirmationDialogComponent,
      confirmationDialogConfig
    );

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.lockUser(item);
      }
    });
  }

  onUnlockUser(item: any) {
    const confirmationDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    confirmationDialogConfig.data = `Are you sure, you want to unlock this user.`;
    confirmationDialogConfig.panelClass = "golp-dialog";
    const confirmationDialog = this.dialog.open(
      ConfirmationDialogComponent,
      confirmationDialogConfig
    );

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.unlockUser(item);
      }
    });
  }

  lockUser(consumer: any) {
    var requestModel = {
      consumerId: this.consumer.consumerId,
    };

    this.consumerService.lockUser(requestModel).subscribe(
      (data) => {
        this.noCouponFound = true;
        this.phone = '';
        this.alertService.success("User has locked");
      },
      (error) => {
        this.noCouponFound = true;
        this.notFoundMessage = error.message;
      }
    );
  }

  unlockUser(consumer: any) {
    var requestModel = {
      consumerId: this.consumer.consumerId,
    };

    this.consumerService.unlockUser(requestModel).subscribe(
      (data) => {
        this.noCouponFound = true;
        this.phone = '';
        this.alertService.success("User has successfully unlocked");
      },
      (error) => {
        this.noCouponFound = true;
        this.notFoundMessage = error.message;
      }
    );
  }

}
