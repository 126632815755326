import { DataService } from './../../../components/app-send-gift/services/shared/data.service';
import { NewPrivilegeCardComponent } from './../new-privilege-card/new-privilege-card.component';
import { Component, EventEmitter, Injector, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AccountServiceService, AlertService } from 'src/app/services';
import { PrivilegeCardModel } from '../../models/privilege-card';
import { BaseComponent } from 'src/app/components/base/base.component';

@Component({
  selector: 'app-privilege-card-details',
  templateUrl: './privilege-card-details.component.html',
  styleUrls: ['./privilege-card-details.component.css']
})
export class PrivilegeCardDetailsComponent  extends BaseComponent implements OnInit {

  @Input() cardDetailsInput: PrivilegeCardModel;
  @Output() cardDetailsInputChange: EventEmitter<PrivilegeCardModel> = new EventEmitter<PrivilegeCardModel>();
  
  cardDetails: PrivilegeCardModel;
  userInfo: any;
  detailsLoaded: Promise<boolean>;

  canEdit: boolean = false;

  constructor(public dialog: MatDialog, private accountService: AccountServiceService, private dataService:DataService,
    private alertService: AlertService,
    private injector: Injector
    ) { 
      super(injector);
    }

  ngOnInit() {
    this.getLoggedInUser();
    this.cardDetails = this.cardDetailsInput;
    console.log("card details : ", this.cardDetailsInput);
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['cardDetailsInput']){
      setTimeout(()=>{
        this.cardDetails = this.cardDetailsInput;
        if(this.cardDetails != null){
          this.cardEditable();
        }
        this.makeSomeDelay(this.cardDetails);
      });
    }
 }

  editOffer(offer: PrivilegeCardModel){

    const editDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    editDialogConfig.disableClose = true;
    editDialogConfig.autoFocus = true;
    editDialogConfig.panelClass = 'golp-dialog';

    editDialogConfig.data = offer

    const offerEditDialog = this.dialog.open(NewPrivilegeCardComponent, editDialogConfig);

    const successFullEdit = offerEditDialog.componentInstance.onOfferEditEvent.subscribe((result:any) =>{
      this.cardDetails = result;
      this.cardDetailsInputChange.emit(this.cardDetails);
      this.makeSomeDelay(this.cardDetails);
      this.cardEditable();
    });

    offerEditDialog.afterClosed().subscribe(() => {
      Promise.resolve(true);
      //successFullEdit.unsubscribe();
      //this.detailsLoaded = Promise.resolve(false);
    });
  }

  availedConsumerList(offer: any){

    
  }

  

  activateOffer(cardDetails: PrivilegeCardModel){
    let url = "api/cards/create-update-card"
    cardDetails.isActive = true;

    this.dataService.post(url, cardDetails)
      .subscribe((data) => {
        this.cardDetails = cardDetails;
        this.cardEditable();
        this.alertService.success("Card activated successfully");
      },
      (error) =>{
        console.log(error);
      });

  }


  makeSomeDelay(card: PrivilegeCardModel){
    if(card != null){
      this.detailsLoaded = Promise.resolve(true);
    }
  }

  getLoggedInUser(){
    this.userInfo = this.accountService.getLoggedInUserInfo();
  }

  cardEditable(){
    this.canEdit = this.accountService.getLoggedInUserInfo().isSystemAdmin;
  }

}
