export class ConstantText {
    //text section
    static readonly VoucharCodeUploadConfirmationText = 'Are you sure to Upload the Gifty Voucher?';
    static readonly UploadGiftVoucherModalTitle = 'Uload Gifty Voucher Code';
    static readonly VoucharCodeUploadSuccessMessage = 'Upload voucher code succfully.';
    static readonly VoucharCodeUploadFailedMessage = 'Voucher code upload failed.';
    static readonly NewAssignmentModalTitle = 'NEW ASSIGNMENT';
    static readonly BulkAssignmentModalTitle = 'Bulk Code Generate';

    //Api Section
    static readonly VoucharCodeUploadApi = 'api/contact-support/uload-gifty-vouchar-codes';
    static readonly BulkCodeDownloadApi = 'api/Coupon/download-gifty-code';
    static readonly RevenueDashboardApi = `api/RevenueDashboard/revenue`;
    static readonly RevenueReportDownloadApi = 'api/RevenueDashboard/download-revenue-details';
    static readonly OfferProviderApi = 'api/company/offer-provider';
}