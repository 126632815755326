import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-json-description-viewer',
  templateUrl: './json-description-viewer.component.html',
  styleUrls: ['./json-description-viewer.component.css']
})
export class JsonDescriptionViewerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
  }

}
