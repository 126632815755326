import { DataService } from './../../services/data.service';
import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { ExtendGiftExpireDateInput } from '../../models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OfferAssignmentService, AlertService } from '../../services';

@Component({
  selector: 'app-extend-gift-expire-date',
  templateUrl: './extend-gift-expire-date.component.html',
  styleUrls: ['./extend-gift-expire-date.component.css']
})

export class ExtendGiftExpireDateComponent implements OnInit {
  assignmentId: string;
  currentExpireDate: string;
  newExpireDate: string;
  extendGiftExpireDateForm: FormGroup;
  submitted: boolean;
  onExtendGiftExpireDateEvent: EventEmitter<any> = new EventEmitter();

  //navigation
  initFrom:string = "";

  constructor(private formBuilder: FormBuilder, private offerAssignmentService: OfferAssignmentService,
    private dialogRef: MatDialogRef<ExtendGiftExpireDateComponent>, private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) data, private dataService: DataService) {

    if (isNaN(data)) { // coming from user list page
      this.assignmentId = data.assignmentId;
      this.currentExpireDate = data.currentExpiredDate;
      if(data.initFrom && data.initFrom != null){
        this.initFrom = data.initFrom;
      }
      //this.currentExpireDate = (new Date(data.currentExpiredDate)).toString();

    }
  }

  ngOnInit() {
    this.buildForm();
  }

  close() {
    this.dialogRef.close();
  }

  buildForm() {
    this.extendGiftExpireDateForm = this.formBuilder.group({
      currentExpireDate: [this.currentExpireDate],
      newExpireDate: [this.newExpireDate, [Validators.required]],
    });
  }

  get f() { return this.extendGiftExpireDateForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.extendGiftExpireDateForm.invalid) {
      return;
    }

    var extendExpireDate = new ExtendGiftExpireDateInput();
    extendExpireDate.offerAssignmentId = this.assignmentId;
    extendExpireDate.currentExpireDate = this.currentExpireDate;
    extendExpireDate.newExpireDate = this.f.newExpireDate.value;

    this.dialogRef.close();
    if(this.initFrom && this.initFrom == "MANAGE_GIFT_CARD"){
      let url = "api/giftcardmanagement/extend-gift-expiration";
      this.dataService.post(url, extendExpireDate).subscribe(res=>{
        this.alertService.success("Gift Expiration Date changed successfully.");
        this.onExtendGiftExpireDateEvent.emit(extendExpireDate);
      }, err=>{}
      )
    }
    else{
      this.offerAssignmentService.extendGiftExpireDate(extendExpireDate).subscribe(result => {
        this.alertService.success("Gift Expiration Date changed successfully.");
        this.onExtendGiftExpireDateEvent.emit(extendExpireDate);
      });
    }
    

  }

}

