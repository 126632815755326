import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import { DataService } from '../../services/shared/data.service';
import { SendGiftCradReportResponse } from '../shared/gift-transaction-report/gift-transaction-report.component';
import { saveAs } from 'file-saver';
import { MerchantOutletResponse } from 'src/app/models/view-models/merchant-outlet-response';

@Component({
  selector: 'app-gift-expiry-report',
  templateUrl: './gift-expiry-report.component.html',
  styleUrls: ['./gift-expiry-report.component.css']
})
export class GiftExpiryReportComponent implements OnInit {

  counter: number = 0;
  offerReportForm: FormGroup;


  currentPage: number = 1;
  pageSize: number = 25;
  expandedIndex: number = -1;
  totalItems: number = 0;
  pageSizes = [25, 50, 100];
  paginationInfo: string = "";

  defaultStartDate: Date = this.startOfMonth(new Date());
  defaultEndDate: Date = new Date();
  paginationPageNumbers: any[];
  currentPageNumberDetails: string;
  submitted: boolean;
  companyOutletId: any;

  userInfo: any;
  reportSummary: any[];

  soldGift: number = 0;
  soldGiftAmount: number = 0;
  redeemedGift: number = 0;
  redeemedGiftAmount: number = 0;
  p: number = 1;
  merchantOutlets: any;
  constructor(private formBuilder: FormBuilder, private dataService: DataService) {
    this.buildForm();
  }

  ngOnInit(): void {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userInfo = userInfo;
    if (this.userInfo.outletQrCode == undefined || this.userInfo.outletQrCode == null)
      this.getMerchantOutlet();
    if (this.userInfo.outletQrCode != undefined && this.userInfo.outletQrCode != null)
      this.companyOutletId = this.userInfo.outletQrCode;
  }

  buildForm() {
    this.offerReportForm = this.formBuilder.group({
      fromDate: [this.defaultStartDate, Validators.required],
      toDate: [this.defaultEndDate, Validators.required],
      outletId: ['', Validators.nullValidator],
      hasAvailed: ['', Validators.nullValidator],
      receiverPhoneNumber: '',
    });
  }

  startOfMonth(date) {
    let startDateOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    startDateOfMonth.setMinutes(date.getMinutes() + 360); // 360 is for GMT+6 hours
    return startDateOfMonth;
  }

  getOfferReportData() {

    this.submitted = true;
    // stop here if form is invalid
    if (this.offerReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    let fromDate = moment(this.f.fromDate.value).format("YYYY/MM/DD");
    let todate = moment(this.f.toDate.value).format("YYYY/MM/DD");
    let outletId = this.f.outletId.value;
    let hasAvailedForm = this.f.hasAvailed.value
    let hasAvailed = null;
    if (hasAvailedForm == 1) {
      hasAvailed = true;
    }
    else if (hasAvailedForm == 2) {
      hasAvailed = false;
    }
    else hasAvailed = null;

    if (outletId != 0)
      this.companyOutletId = outletId.qrCode;

    let url = `api/giftCard/expiry-gift-card-reports/merchant/${this.userInfo.companyId}?fromDate=${fromDate}&toDate=${todate}`;
    if (this.userInfo.outletQrCode != null || this.userInfo.outletQrCode != undefined || outletId != 0)
      url += `&outletId=${this.companyOutletId}`;
    if (hasAvailed != null)
      url += `&hasAvailed=${hasAvailed}`;

    this.dataService.get(url)
      .subscribe((response: SendGiftCradReportResponse) => {
        this.p = 1;
        if (response.success) {
          this.reportSummary = response.data;
          this.getCurrentRange();
        }
      });
  }

  exportXLReportFromServer() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.offerReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    let fromDate = moment(this.f.fromDate.value).format("YYYY/MM/DD");
    let todate = moment(this.f.toDate.value).format("YYYY/MM/DD");
    let outletId = this.f.outletId.value;

    let hasAvailedForm = this.f.hasAvailed.value
    let hasAvailed = null;
    if (hasAvailedForm == 1) {
      hasAvailed = true;
    }
    else if (hasAvailedForm == 2) {
      hasAvailed = false;
    }

    if (outletId != 0)
      this.companyOutletId = outletId.qrCode;
    let url = `api/report/expiry-gift-card-reports/merchant/${this.userInfo.companyId}`;

    let payload = {
      "fromDate": fromDate,
      "toDate": todate,
      "outletId": this.companyOutletId,
      "hasAvailed": hasAvailed
    }

    this.dataService.postWithOptions(url, payload, {
      responseType: 'blob'
    })
      .subscribe((response: any) => {
        this.downloadFile(response);
      }, () => { console.log("Error Occurred") }, () => { console.log("Download Finished") });
  }

  downloadFile(data) {
    const blob2 = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var filename = "Gift-Expiry-Report-" + moment().format('MMMM Do YYYY, h:mm:ss').valueOf() + ".xlsx";
    saveAs(blob2, filename);
  }

  get f() { return this.offerReportForm.controls; }

  getMerchantOutlet() {
    let url = `api/Outlets/company/${this.userInfo.companyId}/`;
    this.dataService.get(url).subscribe((data: MerchantOutletResponse[]) => {
      if (data != null && data.length > 0) {
        this.merchantOutlets = data;
      }
    });
  }

  handlePageChange(event: number): void {
    this.p = event;
    this.getCurrentRange();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = parseInt(event.target.value);
    this.p = 1;
    this.getCurrentRange();
  }

  getCurrentRange() {
    let itemCounts = this.reportSummary?.length;
    let currentPageStart = (this.p - 1) * this.pageSize;
    let maxCurrentPageItem = itemCounts > currentPageStart + this.pageSize ? currentPageStart + this.pageSize : itemCounts;
    this.paginationInfo = `Showing ${currentPageStart + 1}-${maxCurrentPageItem} of ${itemCounts} items`
  }

}
