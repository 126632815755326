import { Component, OnInit, Injector } from '@angular/core';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-premium-users-report',
  templateUrl: './premium-users-report.component.html',
  styleUrls: ['./premium-users-report.component.css']
})

export class PremiumUsersReportComponent extends BaseComponent implements OnInit {

  premiumUserReportsSubMenu: any[] = [];
  selectedSubMenuIndex = 0;
  isAdmin = false;
  activeSubMenu = 'Gift Sender Report';

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.setSubMenu();

  }

  setSubMenu() {
    if (this.permissionCheckerService.hasPermission(['PremiumUserCreditUsageReport'])) {
      this.premiumUserReportsSubMenu.push({ 'name': 'Gift Sender Report', 'dataTarget': 'giftSenderReportTab' });
      this.activeSubMenu = 'Gift Sender Report';
    }
    if (this.permissionCheckerService.hasPermission(['PremiumUserCreditLimitExceedReport'])) {
      this.premiumUserReportsSubMenu.push({ 'name': 'Credit Limit Exceeded', 'dataTarget': 'creditLimitExceededReportTab' });
      this.activeSubMenu = 'Credit Limit Exceeded';
    }

  }

  onSubMenuChange(name, index) {
    if (this.selectedSubMenuIndex !== index) {
      this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
      this.activeSubMenu = this.premiumUserReportsSubMenu.filter(c => c.name === name)[0].name;
    }
  }
}
