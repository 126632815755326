import { FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DataService } from 'src/app/components/app-send-gift/services/shared/data.service';
import { NewBillSettingsKeyComponent } from './new-bill-settings-key/new-bill-settings-key.component';

@Component({
  selector: 'app-bill-settings-key',
  templateUrl: './bill-settings-key.component.html',
  styleUrls: ['./bill-settings-key.component.css']
})
export class BillSettingsKeyComponent implements OnInit {

  constructor(private dialog: MatDialog, private dataService: DataService) { 
    this.loadBillSettingsKey();
  }
  
  billSettingsKey:string;

  billSettingsKeySearchForm: FormGroup;
  billSettingsKeyResult : any[]


  //pagination
  currentPage: number = 1;
  pageSize: number = 25;
  expandedIndex: number = -1;
  totalItems: number = 0;
  count = 0;
  pageSizes = [25, 50, 100];


  ngOnInit(): void {}

  onSubmit(isReset:boolean = false){

  }

  loadBillSettingsKey(){
    let url = "api/billsettingskey/get-all";
    this.dataService.get(url).subscribe((res:any)=>{
      if(res.result){
        this.billSettingsKeyResult = res.data;
        this.count = this.billSettingsKeyResult.length;

      }
      else{this.billSettingsKeyResult = [] ;this.count = 0;}
    }, err=>{
      console.log(err);
    });
  }

  handlePageChange(event: number): void {
    this.currentPage = event;
    this.loadBillSettingsKey();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.currentPage = 1;
    this.loadBillSettingsKey();
  }

  getCurrentRange(){
    return `Showing ${((this.currentPage - 1) * this.pageSize) + 1}-${((this.currentPage - 1) * this.pageSize) + this.billSettingsKeyResult?.length} of ${this.count}`
  }

  addNewClicked(){
    const addBillSettingDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    addBillSettingDialogConfig.disableClose = true;
    addBillSettingDialogConfig.autoFocus = true;
    addBillSettingDialogConfig.panelClass = "golp-dialog";

    const createAssignmentDialog = this.dialog.open(NewBillSettingsKeyComponent, addBillSettingDialogConfig);

    const successfullCreate = createAssignmentDialog.componentInstance.onAddBillSettingKeyEvent.subscribe((data) => {
      this.loadBillSettingsKey()
    });

    createAssignmentDialog.afterClosed().subscribe(() => {
    });

  }
}
