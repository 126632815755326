import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { PaymentSession } from 'src/app/models/payment-session';
import { PaymentOptionsComponent } from '../payment-options/payment-options.component';

@Component({
  selector: 'app-add-transaction',
  templateUrl: './payment-session.component.html',
  styleUrls: ['./payment-session.component.css']
})
export class PaymentSessionComponent implements OnInit {
  paymentSessionFrom: FormGroup;
  paymentSession: PaymentSession;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<PaymentSessionComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    public dialog: MatDialog,
    private zone: NgZone) {
    this.paymentSession = new PaymentSession();
    
    if (data !== null) {
      this.paymentSession = data;
    }
  }

  ngOnInit() {
    this.paymentSession.amount = 0;
    this.paymentSessionFrom = this.formBuilder.group({
      amount: [this.paymentSession.amount, [Validators.required, Validators.pattern('^\\d+(\\.\\d{1,2})?$'), Validators.pattern('^[1-9]+[0-9]*$')]]
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.paymentSessionFrom.invalid) {
      return;
    }

    this.paymentSession.amount = this.paymentSessionFrom.controls['amount'].value;

    const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'golp-dialog';
            dialogConfig.data = { isInvoiceBillPayment : true, data: this.paymentSession };
        
      this.zone.run(() =>{
          const paymentOptionDialog = this.dialog.open(PaymentOptionsComponent, dialogConfig);
          const successfullCreate = paymentOptionDialog.componentInstance.onOptionSelectionEvent.subscribe((data) => {
          });
      
          paymentOptionDialog.afterClosed().subscribe(() => {
          });
      });

    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  // convenience getter for easy access to form fields
  get f() { return this.paymentSessionFrom.controls; }
}
