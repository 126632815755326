import { Component, OnInit, Input, SimpleChanges, Injector } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { CreateCompanyCreditComponent } from '../create-company-credit/create-company-credit.component';
import { Company } from '../../models';
import { CompanyCreditModel } from '../../models/company-credit-model';
import { CompanyService, AlertService } from '../../services';
import { UserType, CompanyType } from '../../enums';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { CompanyCreditSummary } from 'src/app/models/company-credit-summary';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-company-credit-details',
  templateUrl: './company-credit-details.component.html',
  styleUrls: ['./company-credit-details.component.css']
})
export class CompanyCreditDetailsComponent extends BaseComponent implements OnInit {

  @Input() providerDetails: Company;
  companyCredits: CompanyCreditModel[];
  companyId: number;
  companyType: CompanyType;
  isPremiumCompany = false;
  companyCreditSummary: CompanyCreditSummary;
  isSystemAdmin = false;

  constructor(public dialog: MatDialog, private companyService: CompanyService, private alertService: AlertService, injector: Injector) {
    super(injector);
    if (this.providerDetails != null) {
      this.companyId = this.providerDetails.id;
      this.companyType = this.providerDetails.companyType;
      this.isPremiumCompany = this.providerDetails.companyType === CompanyType.Premium;

    }
  }

  ngOnInit() {
    this.isSystemAdmin = this.checkPermission(['SystemAdmin']);
    this.getCompanyCredits();
    this.getCompanyCreditSummary(this.providerDetails.id);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['providerDetails']) {
      setTimeout(() => {
        if (this.providerDetails != null) {
          this.companyId = this.providerDetails.id
          this.companyType = this.providerDetails.companyType;
          this.isPremiumCompany = this.providerDetails.companyType === CompanyType.Premium;
          this.getCompanyCredits();
          this.getCompanyCreditSummary(this.providerDetails.id);
        }

      });
    }
  }
  createCompanyCredit() {
    const dialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "golp-dialog";
    var companyId = this.providerDetails ? this.providerDetails.id : null;
    dialogConfig.data = companyId != null ? { companyId: companyId } : null;

    const companyCreditCreateDialog = this.dialog.open(CreateCompanyCreditComponent, dialogConfig);

    const successfullCreate = companyCreditCreateDialog.componentInstance.onCreditCreateEvent.subscribe((data) => {
      this.getCompanyCredits();
      this.getCompanyCreditSummary(this.providerDetails.id);

    });

    companyCreditCreateDialog.afterClosed().subscribe(() => {
      //successFullEdit.unsubscribe();
      //this.detailsLoaded = Promise.resolve(false);
    });
  }
  editCompanyCredit(item: CompanyCreditModel) {
    {
      const dialogConfig = new MatDialogConfig();

      // Setting different dialog configurations
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = item;
      dialogConfig.panelClass = 'golp-dialog';

      const companyCreditEditDialog = this.dialog.open(CreateCompanyCreditComponent, dialogConfig);

      const successfullEdit = companyCreditEditDialog.componentInstance.onCreditEditEvent.subscribe((data) => {
        this.getCompanyCredits();
      });

      companyCreditEditDialog.afterClosed().subscribe(() => {
      });
    }
  }
  getCompanyCredits() {
    if (this.companyId > 0) {
      this.companyService.getCompanyCreditsByCompanyId(this.companyId).subscribe(data => {
        this.companyCredits = data;
      });
    }
  }

  changeStatus(item: CompanyCreditModel, active: boolean) {
    const confirmationDialogConfig = new MatDialogConfig()
    var text = "Are you sure to activate this company credit?";
    var successMessage = "Company credit activated successfully";
    if (active == false) {
      text = "Are you sure to deactivate this company credit?";
      successMessage = "Company credit deactivated successfully";
    }
    var companyCredit = new CompanyCreditModel();
    companyCredit.isActive = active;
    companyCredit.id = item.id;
    // Setting different dialog configurations
    confirmationDialogConfig.data = text;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.companyService.changeCompanyCreditStatus(companyCredit).subscribe(result => {
          this.alertService.success(successMessage);
          this.getCompanyCredits();
          this.getCompanyCreditSummary(this.companyId);
        });
      }
    });
  }

  getCompanyCreditSummary(companyId: number) {
    if (companyId) {
      this.companyService.getCompanyCreditSummaryByCompanyId(companyId).subscribe(result => {
        this.companyCreditSummary = result;
      });
    }
  }

  changeStatusForAll(active: boolean) {
    const confirmationDialogConfig = new MatDialogConfig()
    var text = "Are you sure to activate all company credits?";
    var successMessage = "Company credits activated successfully";
    if (active == false) {
      text = "Are you sure to deactivate all company credits?";
      successMessage = "Company credits deactivated successfully";
    }
    // Setting different dialog configurations
    confirmationDialogConfig.data = text;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.companyService.changeCompanyAllCreditStatus(this.companyId, active).subscribe(result => {
          this.alertService.success(successMessage);
          this.getCompanyCredits();
          this.getCompanyCreditSummary(this.companyId);
        });
      }
    });
  }
}
