import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class OfferAvailMethodService {

  private baseUri = 'api/offerAvailMethods';

  constructor(
    private http: HttpClient
  ) { }


  GetAllOfferAvailMethods(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUri + '/getAll/');
  }
}
