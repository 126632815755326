import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GiftOccasionService {
  private baseUri = "api/gift-occasions";
  requestHeader = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' })};

  constructor(private http: HttpClient) { }

  getOccasions(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUri, this.requestHeader);
  }
}
