import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import * as moment from 'moment';
import { CorporateOfferService } from 'src/app/corporate-offers/services/corporate-offer.service';
import { AlertService, CompanyService, AccountServiceService } from 'src/app/services';
import { CompanyRole } from 'src/app/enums';
import { ReplaySubject } from 'rxjs';
import { Company } from 'src/app/models';

@Component({
  selector: 'app-create-bulk-offer',
  templateUrl: './create-bulk-offer.component.html',
  styleUrls: ['./create-bulk-offer.component.css']
})
export class CreateBulkOfferComponent implements OnInit {

  @Output() bulkOfferCreateEvent: EventEmitter<any> = new EventEmitter<any>();

  userInfo: any;

  merchantFilterCtrl: FormControl = new FormControl();
  filteredMerchants: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);

  offerProviderFilterCtrl: FormControl = new FormControl();
  filteredOfferProviders: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);

  showProviderSection: boolean = true;
  showMerchantSection: boolean = true;

  offerProviders: Company[];

  merchants: Company[];

  fileName: string = '';
  imagePreviewPath: string = '../../../assets/images/icon-excel.jpg';
  fileValidationError: string;

  corporateOfferForm: FormGroup;
  bulkOfferData: FormData;
  submitted = false;

  private bulkOfferFiles: FormData;

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateBulkOfferComponent>,
    private offerService: CorporateOfferService,
    private companyService: CompanyService,
    private accountService: AccountServiceService,
    private alertService: AlertService,) {
    this.getLoggedInUser();
  }

  ngOnInit(): void {

    this.buildForm();
    this.getOfferProviders();
    this.getMerchants();

    if (CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
      this.showProviderSection = false;
      this.showMerchantSection = false;
    }

    if (CompanyRole.GiftSender === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
      this.showProviderSection = false;
    }
  }

  buildForm() {
    this.corporateOfferForm = this.formBuilder.group({
      offerProviderId: [],
      merchantId: [[Validators.required]],
      validFrom: [],
      validTill: [],
    });
  }

  getLoggedInUser() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
  }

  getOfferProviders() {
    if (CompanyRole.GiftSender === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
      this.companyService.getCompanyById(this.userInfo.companyId)
        .subscribe((data: Company) => {
          this.offerProviders = [];
          this.offerProviders.push(data);
          this.corporateOfferForm.get('offerProviderId').setValue(this.offerProviders[0].id);
          this.filteredOfferProviders.next(this.offerProviders.slice());
        });
    } else {
      this.companyService.getOfferProviders()
        .subscribe(data => {
          this.offerProviders = data;
          this.filteredOfferProviders.next(this.offerProviders.slice());

        });
    }
  }

  getMerchants() {
    if (CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
      this.companyService.getCompanyById(this.userInfo.companyId)
        .subscribe((data: Company) => {
          this.merchants = [];
          this.merchants.push(data);
          this.corporateOfferForm.get('merchantId').setValue(this.merchants[0].id);
          this.filteredMerchants.next(this.merchants.slice());
        });
    } else {
      this.companyService.getMerchants()
        .subscribe(data => {
          this.merchants = data;
          // this.setSalesCommission(this.selectedMerchantId);
          this.filteredMerchants.next(this.merchants.slice());
        });
    }
  }

  bindFilterChangeEvent() {
    // listen for search field value changes
    this.merchantFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterMerchants();
      });
  }

  protected filterMerchants() {
    if (!this.merchants) {
      return;
    }
    // get the search keyword
    let search = this.merchantFilterCtrl.value;
    if (!search) {
      this.filteredMerchants.next(this.merchants);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the merchants
    this.filteredMerchants.next(
      this.merchants.filter(merchant => merchant.name.toLowerCase().indexOf(search) > -1)
    );
  }

  openFileBrowser(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById('corporateOfferFileId') as HTMLElement;
    element.click();
  }

  onUploadFile(files) {
    this.fileValidationError = null;
    if (files.length === 0)
      return;

    const formData = new FormData();
    let fileType = files[0].type;

    if (fileType != 'application/vnd.ms-excel' && fileType != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.fileValidationError = "Please upload excel file.";
      return;
    }

    for (let file of files) {
      formData.append(file.name, file);
      this.fileName += ' ' + file.name;
    }
    this.bulkOfferFiles = formData;
  }

  onSubmit() {
    this.submitted = true;
    this.fileValidationError = null;

    if (CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
      this.corporateOfferForm.value.merchantId = this.userInfo.companyId;
      this.corporateOfferForm.value.offerProviderId = this.userInfo.companyId;
    }
    else if (CompanyRole.GiftSender === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
      this.corporateOfferForm.value.offerProviderId = this.userInfo.companyId;
    }

    // stop here if form is invalid
    if (this.corporateOfferForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    if (this.bulkOfferFiles == null) {
      this.fileValidationError = "Please upload a file."
      return;
    }

    this.bulkOfferData = new FormData();
    if (this.bulkOfferFiles != null) {
      this.bulkOfferData = this.bulkOfferFiles;
    }

    var fromDate = moment(this.corporateOfferForm.value.validFrom).format("YYYY/MM/DD  HH:mm");
    const toDate = moment(this.corporateOfferForm.value.validTill).format("YYYY/MM/DD  HH:mm");

    this.bulkOfferData.append('validFrom', fromDate);
    this.bulkOfferData.append('validTill', toDate);
    this.bulkOfferData.append('merchantId', this.corporateOfferForm.value.merchantId);
    this.bulkOfferData.append('offerProviderId', this.corporateOfferForm.value.offerProviderId);


    this.offerService.post('create-bulk-offer', this.bulkOfferData)
      .subscribe((response: any) => {
        if (response.success) {
          //this.alertService.success('Offer send successfully.');
          this.bulkOfferCreateEvent.emit(response);
          //this.dialogRef.close();
        }
        if (!response.success) {
          this.fileValidationError = response.data;
        }
      },
        (error: any) => {
        });
  }

  close() {
    this.dialogRef.close();
  }

  get f() { return this.corporateOfferForm.controls; }

}
