import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { GetOffersPagedListInput, ChangeGiftAssignmentInput, ExtendGiftExpireDateInput } from '../models';
import { AssignmentResponse } from '../models/assignmentresponse';

@Injectable({
  providedIn: 'root'
})
export class OfferAssignmentService {

  private offerAssignmentBaseUrl = 'api/offerassignments';

  private changedOfferId = new Subject<number>();

  constructor(private http: HttpClient) { }

  assignOffer(offerAssignmentData: FormData): Observable<AssignmentResponse> {
    var requestHeader = { headers: new HttpHeaders({ 'No-Auth': 'False' }) };
    return this.http.post<AssignmentResponse>(this.offerAssignmentBaseUrl + '/assign', offerAssignmentData, requestHeader);
  }

  deleteOfferAssignment(assignmentId: string) {
    var requestHeader = { headers: new HttpHeaders({ 'No-Auth': 'False' }) };
    return this.http.delete(this.offerAssignmentBaseUrl + '/' + assignmentId, requestHeader);
  }

  getOfferAssignments(id: number): Observable<any[]> {
    var requestHeader = { headers: new HttpHeaders({ 'No-Auth': 'False' }) };
    return this.http.get<any[]>(this.offerAssignmentBaseUrl + '/offer/' + id, requestHeader);
  }

  getOfferAssignmentsBulkGenerated(id: number): Observable<any[]> {
    var requestHeader = { headers: new HttpHeaders({ 'No-Auth': 'False' }) };
    return this.http.get<any[]>(this.offerAssignmentBaseUrl + '/offer/bulk-generate/' + id, requestHeader);
  }

  getOfferAssignmentSummary(id: number): Observable<any> {
    var requestHeader = { headers: new HttpHeaders({ 'No-Auth': 'False' }) };
    return this.http.get<any>(this.offerAssignmentBaseUrl + '/summary/offer/' + id, requestHeader);
  }


  getOfferAssignmentByAssignmentId(id: any): Observable<any> {
    var requestHeader = { headers: new HttpHeaders({ 'No-Auth': 'False' }) };
    return this.http.get<any>(this.offerAssignmentBaseUrl + '/' + id, requestHeader);
  }

  offerAssignmentChanged(id: number) {
    this.changedOfferId.next(id);
  }

  getChangedAssignmentOfferId(): Observable<number> {
    return this.changedOfferId.asObservable();
  }

  getAvailedConsumers(id: number): Observable<any[]> {
    var requestHeader = { headers: new HttpHeaders({ 'No-Auth': 'False' }) };
    return this.http.get<any[]>(this.offerAssignmentBaseUrl + '/availed-consumers/offer/' + id, requestHeader);
  }

  getConsumerOffersPagedList(input: GetOffersPagedListInput): Observable<any> {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post<any[]>(this.offerAssignmentBaseUrl + '/paged-list', input, requestHeader);
  }
  markOfferAssignmentAsFavorite(offerAssignment: any) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.put(this.offerAssignmentBaseUrl + '/mark-favorite', offerAssignment, requestHeader);
  }

  changeOfferAssignment(offerAssignment: ChangeGiftAssignmentInput) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.offerAssignmentBaseUrl + '/change-assignment', offerAssignment, requestHeader);
  }

  extendGiftExpireDate(offerAssignment: ExtendGiftExpireDateInput) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.offerAssignmentBaseUrl + '/extend-gift-expiration', offerAssignment, requestHeader);
  }


  assignMerchant(assignMerchant: any) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.offerAssignmentBaseUrl + '/assign-merchant', assignMerchant, requestHeader);
  }

  makeOpenMerchant(makeOpenMerchantCommand: any) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.offerAssignmentBaseUrl + '/make-open', makeOpenMerchantCommand, requestHeader);
  }

  approveAssignmentByInvoice(invoice: any) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.offerAssignmentBaseUrl + '/approve-gift-for-invoice', invoice, requestHeader);
  }

  getGiftCode(id: any): Observable<any> {
    var requestHeader = { headers: new HttpHeaders({ 'No-Auth': 'False' }) };
    return this.http.get<any>(`${this.offerAssignmentBaseUrl}/view-gift-code/${id}`, requestHeader);
  }

  getGiftByGiftCode(searchObject: any): Observable<any> {
    var requestHeader = { headers: new HttpHeaders({ 'No-Auth': 'False' }) };
    return this.http.post<any>(`${this.offerAssignmentBaseUrl}/verify-gift-code`, searchObject, requestHeader);
  }

}
