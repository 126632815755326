import { Component, OnInit, Input, SimpleChanges, EventEmitter, Injector } from '@angular/core';
import { Offer } from 'src/app/models/offer';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, OfferAssignmentService } from 'src/app/services';
import { saveAs } from 'file-saver';
import { DataService } from 'src/app/services';
import { BaseComponent } from '../base/base.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { CreateOfferAssignmentComponent } from '../create-offer-assignment/create-offer-assignment.component';
import { ConstantText } from 'src/app/constants/constant.text';

@Component({
  selector: 'app-bulk-code-generate',
  templateUrl: './bulk-code-generate.component.html',
  styleUrls: ['./bulk-code-generate.component.css']
})
export class BulkCodeGenerateComponent extends BaseComponent implements OnInit {

  @Input() offerDetails: Offer;

  offerAssignments: any[];

  expandedIndex: number = -1;

  constructor(private dialog: MatDialog,
    private offerAssignmentService: OfferAssignmentService,
    private alertService: AlertService,
    private dataService: DataService,
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['offerDetails']){
      setTimeout(()=>{
        if(this.offerDetails != null){
          this.getOfferAssignments(this.offerDetails.id);
        }
        
      });
    }
 }

  generateBulkCode(offer: Offer){
    const createBulkCodeGenerateDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    createBulkCodeGenerateDialogConfig.disableClose = true;
    createBulkCodeGenerateDialogConfig.autoFocus = true;
    createBulkCodeGenerateDialogConfig.panelClass = "golp-dialog";

    createBulkCodeGenerateDialogConfig.data = offer
    createBulkCodeGenerateDialogConfig.data.title = ConstantText.BulkAssignmentModalTitle;
    createBulkCodeGenerateDialogConfig.data.showPhoneNumberSection = false;
    createBulkCodeGenerateDialogConfig.data.showFileUpload = false;
    createBulkCodeGenerateDialogConfig.data.bulkCodeGenerate = true;

    const createAssignmentDialog = this.dialog.open(CreateOfferAssignmentComponent, createBulkCodeGenerateDialogConfig);

    const successfullCreate = createAssignmentDialog.componentInstance.onAssignmentEvent.subscribe((data) =>{
      this.getOfferAssignments(data);
      this.offerAssignmentService.offerAssignmentChanged(this.offerDetails.id);
    });

    createAssignmentDialog.afterClosed().subscribe(() => {
    });

  }

  onDeleteAssignment(item){
    const confirmationDialogConfig = new MatDialogConfig();
    // Setting different dialog configurations
    confirmationDialogConfig.data = "Are you sure to delete the assignment " + item.phoneNumber;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if(result != undefined){
        this.deleteAssignment(item.assignmentId);
      }
    });
  }

  DownloadAssignmentCodePin(idx: any) {
    const data = this.offerAssignments[idx];
    const assignmentIds = [];
    data.assignmentPhoneNumbers.forEach(element => {
      assignmentIds.push(element.assignmentId);
    });
    const body = {
      "FromDate": data.effectiveDate,
      "ToDate": data.expirationDate,
      "AssignmentIds": assignmentIds
    }
    this.downloadTheGeneratedCode(assignmentIds).
                                  subscribe(data => this.downloadFile(data, idx));
                              
  }

  downloadFile(data, idx) {
    const blob2 = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var filename = 'Generated-Coupon-Code-For-EffectiveDate-' + this.offerAssignments[idx].effectiveDate + "-to-ExpirationDate-" + this.offerAssignments[idx].expirationDate +".xlsx";
    saveAs(blob2, filename);
  }

  downloadTheGeneratedCode(body: any) {
    let url = ConstantText.BulkCodeDownloadApi;
    return this.dataService.postBolb(url, body);
  }

  expandCollapse(index){
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }

  getOfferAssignments(id:number){
    this.offerAssignmentService.getOfferAssignmentsBulkGenerated(id)
      .subscribe((data) => {
        this.offerAssignments = data; 
      })
  }

  deleteAssignment(assignmentId: string){
    this.offerAssignmentService.deleteOfferAssignment(assignmentId).subscribe((data) => {
      this.alertService.success('Assignment deleted successfully');
      this.getOfferAssignments(this.offerDetails.id);
      this.offerAssignmentService.offerAssignmentChanged(this.offerDetails.id);
    },
    (error) => {
    });
  }

}
