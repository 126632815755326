export enum PermissionEnum {
  NotSet = 0,

  // system admin permission
  SystemAdmin = 0x01,

  CompanyRead = 0x10,
  CompanyCreate = 0x11,
  CompanyUpdate = 0x12,
  CompanyDelete = 0x13,

  // merchant permission
  MerchantRead = 0x14,
  MerchantCreate = 0x15,
  MerchantUpdate = 0x16,
  MerchantDelete = 0x17,

  // provider permission
  ProviderRead = 0x18,
  ProviderCreate = 0x19,
  ProviderUpdate = 0x1A,
  ProviderDelete = 0x1B,

  // offer permission
  OfferRead = 0x20,
  OfferCreate = 0x21,
  OfferUpdate = 0x22,
  OfferDelete = 0x23,
  OfferApprove = 0x24,
  OfferReject = 0x25,

  // offer assignment
  OfferAssignmentRead = 0x30,
  OfferAssignmentCreate = 0x31,
  OfferAssignmentUpdate = 0x32,
  OfferAssignmentDelete = 0x33,

  // offer avail
  OfferAvailInitiate = 0x40,
  OfferAvailAccept = 0x41,
  OfferAvailDecline = 0x42,
  OfferAvailTransactionRead = 0x43,

  // outlet permission
  OutletRead = 0x50,
  OutletCreate = 0x51,
  OutletUpdate = 0x52,
  OutletDelete = 0x53,

  // billing
  InvoiceCreate = 0x60,
  ProviderInvoiceCreate = 0x61,
  MerchantInvoiceCreate = 0x62,
  ReadInvoice = 0x63,
  CreateTransaction = 0x64,
  ReadTransaction = 0x65,

  // consumer
  ConsumerRead = 0x70,
  ConsumerCreate = 0x71,
  ConsumerUpdate = 0x72,
  ConsumerDelete = 0x73,

  // consumer
  CompanySettingRead = 0x80,
  CompanySettingCreate = 0x81,
  CompanySettingUpdate = 0x82,
  CompanySettingDelete = 0x83,

}
