import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Company } from 'src/app/models/company';
import { UploadDiscountHolderComponent } from '../upload-discount-holder/upload-discount-holder.component';
import { DiscountHoldersService, AlertService } from 'src/app/services';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-discount-holders',
  templateUrl: './discount-holders.component.html',
  styleUrls: ['./discount-holders.component.css']
})
export class DiscountHoldersComponent implements OnInit {

  @Input() offerorDetails: Company;

  discountHolders: any;
  pageNumber: number = 1;
  pageSize: number = 25;

  constructor(public dialog: MatDialog,
    private discountHoldersService: DiscountHoldersService ,
    private alertService: AlertService
    ) { }

  ngOnInit() {
    this.offerorDetails = new Company();
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['offerorDetails']){
      if(this.offerorDetails != null){
        this.getDiscountHolders(this.offerorDetails.id, this.pageNumber, this.pageSize);
      }
      // setTimeout(()=>{
        
      // });
    }
 }

  uploadDiscountHolder(serviceOfferor: Company){
    const uploadDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    uploadDialogConfig.disableClose = true;
    uploadDialogConfig.autoFocus = true;
    uploadDialogConfig.panelClass = 'golp-dialog';

    uploadDialogConfig.data = serviceOfferor;

    const uploadDialog = this.dialog.open(UploadDiscountHolderComponent, uploadDialogConfig);

    const successfullUpload = uploadDialog.componentInstance.onUploadDiscountHolderEvent.subscribe((data) =>{
      this.getDiscountHolders(data, this.pageNumber, this.pageSize);
    });

    uploadDialog.afterClosed().subscribe(() => {
    });
  }

  onDeleteDiscountHolder(item){
    const confirmationDialogConfig = new MatDialogConfig();
    console.log(item);
    // Setting different dialog configurations
    confirmationDialogConfig.data = "Are you sure to delete the number " + item.phoneNumber;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if(result != undefined){
        this.deleteDiscountHolder(item);
      }
    });
  }

  getDiscountHolders(offerorId:number, pageNumber: number, pageSize: number){
    this.discountHoldersService.getDiscountHolders(offerorId, pageNumber, pageSize)
      .subscribe((data) => {
        this.discountHolders = data;
        console.log(data);
      })
  }

  deleteDiscountHolder(discountHolder: any){
    this.discountHoldersService.deleteDiscountHolder(discountHolder.id).subscribe((data) => {
      this.alertService.success('Offer holder deleted successfully');
      this.getDiscountHolders(this.offerorDetails.id, this.pageNumber, this.pageSize);
      //this.offerAssignmentService.offerAssignmentChanged(this.offerDetails.id);
    },
    (error) => {
      //this.alertService.error('Internal server error happen');
      console.log(error);
    });
  }

}
