import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormGroup, FormBuilder } from '@angular/forms';
import { MerchantAvailInstruction } from '../../../models/merchant-avail-instruction';

@Component({
  selector: 'app-add-avail-instruction',
  templateUrl: './add-avail-instruction.component.html',
  styleUrls: ['./add-avail-instruction.component.css']
})
export class AddAvailInstructionComponent implements OnInit {

  keyValues = [{ key: '', value: '' }];
  @Input() currentInstruction: any;
  @Input() isSubmitted: false;
  @Output() addAvailInstructionEmitter: EventEmitter<any> = new EventEmitter<any>();

  submitted = false;
  keyValuesJson: any;
  keyValuesJsonArray: any;
  public keyValuesForm: FormGroup;

  constructor(private _fb: FormBuilder) { }

  ngOnInit() {
    this.keyValuesForm = this._fb.group({
      itemRows: this._fb.array([this.initItemRows()])
    });

    this.setFormArrayValue();
  }

  public setFormArrayValue() {
    var existingSlabArray: string[] = [];

    if (this.currentInstruction) {
      var aa = JSON.parse(this.currentInstruction) as MerchantAvailInstruction;
      existingSlabArray = aa.availInstructions;
    }

    if (existingSlabArray && existingSlabArray.length > 0) {
      this.deleteFirstEmptyRow();

      var i = 0;
      Object.entries(existingSlabArray).forEach(element => {
        this.formArr.push(this.initItemRowsForEdit(existingSlabArray[i]));
        i = i + 1;

      });
      this.setKeyValuesJsonAndEmit();
    }
  }

  get formArr() {
    return this.keyValuesForm.get('itemRows') as FormArray;
  }

  initItemRowsForEdit(element: any) {
    return this._fb.group({
      value: [element]
    });
  }

  initItemRows() {
    return this._fb.group({
      value: ['']
    });
  }

  addNewInstruction() {
    this.formArr.push(this.initItemRows());
    this.setKeyValuesJsonAndEmit();
  }

  deleteFirstEmptyRow() {
    this.formArr.removeAt(0);
    this.setKeyValuesJsonAndEmit();
  }

  deleteRow(index: number) {
    this.formArr.removeAt(index);
    this.setKeyValuesJsonAndEmit();
  }

  setKeyValuesJson(): void {
    var customArray = this.keyValuesForm.controls.itemRows.value;
    var object = {};

    var slabArray: string[] = [];

    customArray.forEach((element1) => {
      var val = element1.value;
      if (val)
        slabArray.push(element1.value);
    });

    this.keyValuesJson = slabArray;
  }

  setKeyValuesJsonAndEmit(): void {
    this.setKeyValuesJson();
    this.addAvailInstructionEmitter.emit(this.keyValuesJson);
  }

  get formData() { return <FormArray>this.keyValuesForm.get('itemRows'); }

}

