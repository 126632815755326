import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-consumer-gift-reports',
  templateUrl: './consumer-gift-reports.component.html',
  styleUrls: ['./consumer-gift-reports.component.css']
})
export class ConsumerGiftReportsComponent implements OnInit {

  consumerGiftTransactionReportsSubMenu: any[] = [];
  selectedSubMenuIndex = 0;
  isAdmin = false;
  activeSubMenu = 'Active Gifts';

  constructor() {
    this.setSubMenu();
  }

  ngOnInit() {
  }

  setSubMenu() {

    this.consumerGiftTransactionReportsSubMenu.push({ 'name': 'Active Gifts', 'dataTarget': 'activeConsumerGiftReportTab' });
    this.consumerGiftTransactionReportsSubMenu.push({ 'name': 'Inactive Gifts', 'dataTarget': 'inActiveConsumerGiftReportTab' });

  }

  onSubMenuChange(name, index) {
    if (this.selectedSubMenuIndex !== index) {
      this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
      this.activeSubMenu = this.consumerGiftTransactionReportsSubMenu.filter(c => c.name === name)[0].name;
    }
  }
}
