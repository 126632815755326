import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountServiceService, AlertService, FileUploadService } from 'src/app/services';
import { DataService } from '../../app-send-gift/services/shared/data.service';

@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.css']
})
export class CreateProductComponent implements OnInit {
  // onCreate event
  onProductCreateEvent: EventEmitter<any> = new EventEmitter<any>();
  // onEdit event;
  onProductEditEvent: EventEmitter<any> = new EventEmitter<any>();

  userInfo: any;
  submitted = false;

  productForm: FormGroup;
  productFormData: FormData = new FormData();
  uploadedFiles: FormData;

  inEditMode: boolean = false;

  fileName: string = '';
  imagePreviewPath: string = '../../../assets/images/default.png';
  fileValidationError: string;

  product: any = {};

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateProductComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private dataService: DataService,
    private alertService: AlertService,
    private accountService: AccountServiceService,
    private fileUploadService: FileUploadService,
  ) {
    this.getLoggedInUser();
    this.setEditModeData(data);
  }

  async ngOnInit() {
    this.buildForm();
  }

  async ngOnChanges() {
  }

  setEditModeData(data: any) {
    if (data !== null) {
      this.product = data;
      this.imagePreviewPath = `image/merchant-product/${this.product.productImage}`;;

      this.inEditMode = true;
      this.fileName = this.product.productImage;
    }
  }

  buildForm() {
    this.productForm = this.formBuilder.group({
      productName: [this.product.productName, [Validators.required, Validators.maxLength(250)]],
      productPrice: [this.product.productPrice, [Validators.required, Validators.pattern('^\\d+(\\.\\d{1,2})?$'), Validators.pattern('^[1-9]+[0-9]*$')]]
    });
  }

  openFileBrowser(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById('productPhotoId') as HTMLElement;
    element.click();
  }

  // onChangeMerchant(merchantId) {
  //   this.selectedMerchantId = merchantId;
  //   this.getSettingsBySelectedCompany(merchantId);
  // }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.productForm.invalid) {
      return;
    }

    if (this.product.id === undefined) { // create
      this.createProduct();
    }
    else if (this.product.id != null) { // edit
      this.editProduct();
    }

    this.dialogRef.close();
  }

  createProduct() {

    Object.keys(this.productForm.value).forEach(key => {
      this.productFormData.append(key, this.productForm.value[key])
    });

    this.productFormData.append("merchantId", this.userInfo.companyId);

    this.dataService.post(`api/merchant/add-product`, this.productFormData)
      .subscribe((result: any) => {

        if (result.success) {
          this.onProductCreateEvent.emit(result.data);
          this.alertService.success('product created successfully.');
        }
      },
        (error: any) => {
          this.alertService.error(error);
        })
  }

  editProduct() {
    Object.keys(this.productForm.value).forEach(key => {
      this.productFormData.append(key, this.productForm.value[key])
    });

    this.productFormData.append('id', this.product.id);

    this.dataService.post(`api/merchant/update-product`, this.productFormData)
      .subscribe((result: any) => {

        if (result.success) {
          this.onProductEditEvent.emit(result.data);
          this.alertService.success('product updated successfully.');
        }
      },
        (error: any) => {
          this.alertService.error(error);
        })
  }

  uploadLogo(files) {
    this.fileValidationError = null;
    let fileInfo = this.fileUploadService.imageFileUpload(files);
    if (fileInfo.validationError != null) {
      this.fileValidationError = fileInfo.validationError;
      return;
    }
    this.fileName = fileInfo.fileName;
    this.uploadedFiles = fileInfo.formData;

    let fileToUpload = <File>files[0];
    this.productFormData.append('productImage', fileToUpload, fileToUpload.name);

    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imagePreviewPath = event.target.result;
    }
    reader.readAsDataURL(files[0]);
  }

  getLoggedInUser() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
    console.log(this.userInfo);
  }

  close() {
    this.dialogRef.close();
  }

  // convenience getter for easy access to form fields
  get f() { return this.productForm.controls; }
} 