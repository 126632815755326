import { Component, OnInit } from '@angular/core';
import { Company } from 'src/app/models';
import { CompanyRole } from 'src/app/enums';
import { CompanyService, AccountServiceService, CommonService, AlertService } from 'src/app/services';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-payment-report',
  templateUrl: './payment-report.component.html',
  styleUrls: ['./payment-report.component.css']
})

export class PaymentReportComponent implements OnInit {

  dataLoaded: Promise<boolean>;

  currentPage: number = 1;
  pageSize: number = 10;
  itemCountText: string;

  userInfo: any;
  canCreate: boolean = true;

  filteredsiteAdmins: any[];
  siteAdmins: any[];

  siteAdminFilter: any = { name: '' };
  siteAdminFilterText: string;
  selectedsiteAdminIndex: number = -1;
  selectedsiteAdmin: Company = null;
  selectedsiteAdminName: string;

  siteAdminSubMenu: any[] = [];
  selectedSubMenuIndex: number = 0;
  activeSubMenu: string = "Payment Dues";
  companyRoleType: CompanyRole = CompanyRole.None;

  constructor(private companyService: CompanyService, private dialog: MatDialog,
    private accountService: AccountServiceService,
    private commonService: CommonService,
    private alertService: AlertService
    ) { }

  ngOnInit() {
    this.getLoggedInUser();

    // this.siteAdminSubMenu.push({"name": "Users", "dataTarget": "usersTab"});
    // this.siteAdminSubMenu.push({"name": "Roles", "dataTarget": "rolesTab" });
    // this.siteAdminSubMenu.push({"name": "Permissions", "dataTarget": "permissionsTab" });
    this.siteAdminSubMenu.push({"name": "Payment Dues", "dataTarget": "giftGenerationReportsTab" });

    

    this.dataLoaded = Promise.resolve(true);

  }


  onSubMenuChange(name, index){
    if(this.selectedSubMenuIndex != index){
      this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
      this.activeSubMenu = this.siteAdminSubMenu.filter(c => c.name === name)[0].name;
    }
  }

  getLoggedInUser(){
    this.userInfo = this.accountService.getLoggedInUserInfo();
    this.canCreate = true;
    // if(CompanyRole.siteAdmin === this.userInfo.companyRole && !this.userInfo.isSystemAdmin){
    //   this.canCreate = false;
    // }
  }

}
