import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Company, Offer, GiftAmountSetting } from 'src/app/models';
import { OfferType } from 'src/app/enums';
import { CompanyService, AlertService, OfferService, CompanyOutletService, AccountServiceService, FileUploadService, GiftOccasionService } from 'src/app/services';
import { ReplaySubject } from 'rxjs';
import { CompanySettingType } from '../../enums/company-setting.type';
import { CompanySettingService } from '../../services/company-setting.service';
import { GolpProductService } from '../../services/golp-product.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-create-golp-product',
  templateUrl: './create-golp-product.component.html',
  styleUrls: ['./create-golp-product.component.css']
})
export class CreateGolpProductComponent implements OnInit {
  // onCreate event
  onOfferCreateEvent: EventEmitter<Offer> = new EventEmitter();
  // onEdit event;
  onOfferEditEvent: EventEmitter<Offer> = new EventEmitter();

  userInfo: any;
  submitted = false;

  offerForm: FormGroup;
  uploadedFiles: FormData;

  inEditMode: boolean = false;
  isOwnOffer: boolean = false;
  isOutletSpecific: boolean = false;
  selectedMerchantId: number;
  selectedOccasionId: number;

  fileName: string = '';
  imagePreviewPath: string = '../../../assets/images/default.png';
  fileValidationError: string;

  offer: Offer;
  merchants: Company[];
  occasions: any[];

  showOfferPriceSection: boolean = false;
  showPayableAmountSection: boolean = false;
  showAssignmentPerUserControl: boolean = false;
  showGiftSlabs: boolean = false;

  offerType = OfferType;
  offerTypeKeys: any[];

  globalGiftAmountSetting: GiftAmountSetting;
  globalMinAmount: number = 0;
  globalMaxAmount?: number;

  minAmount: number;
  maxAmount?: number;
  useSlab: boolean = false;
  slabs: any;

  bundleOffers: any[];
  showBundleOfferSection: boolean = false;
  merchantHasBundleOffer: boolean = true;

  offerTypeKeyFilterCtrl: FormControl = new FormControl();
  filteredOfferTypeKeys: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  merchantFilterCtrl: FormControl = new FormControl();
  occasionFilterCtrl: FormControl = new FormControl();
  filteredMerchants: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);
  filteredOccasions: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  offerPriceSlab: any;

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateGolpProductComponent>,
    @Inject(MAT_DIALOG_DATA) data, private companyService: CompanyService,
    private companySettingService: CompanySettingService,
    private offerService: OfferService,
    private golpProductService: GolpProductService,
    private alertService: AlertService,
    private accountService: AccountServiceService,
    private fileUploadService: FileUploadService,
    private giftOccasions: GiftOccasionService,
  ) {
    this.getLoggedInUser();
    this.getMerchants();
    this.getOccasions();
    this.offerTypeKeys = Object.keys(this.offerType).filter(Number).filter(c => c != '3');
    // When offerTypeKeys comes from service offerTypeKeys need to be populate in subscribe.
    this.filteredOfferTypeKeys.next(this.offerTypeKeys);

    this.offer = new Offer();
    this.setEditModeData(data);
  }

  async ngOnInit() {
    this.buildForm();
    this.bindFilterChangeEvent();
    this.getGlobalSettings();
    await this.getSettingsBySelectedCompany(this.selectedMerchantId);

  }

  async ngOnChanges() {
    await this.getSettingsBySelectedCompany(this.selectedMerchantId);
  }

  setEditModeData(data: any) {
    if (data !== null) {
      this.offer = new Offer(data);
      this.imagePreviewPath = this.offer.offerImageUrl;

      this.inEditMode = true;
      this.isOutletSpecific = this.offer.isOutletSpecific;
      this.selectedMerchantId = this.offer.merchantId;
      this.showAssignmentPerUserControl = this.offer.isMultiTimeAssignable;
      this.fileName = this.offer.imageName;
    }
    else {
      this.offer.isMultiTimeAssignable = false;
      this.offer.assignmentPerUser = 0;
      this.offer.offerType = this.offerType.CompanyGift;
    }
  }

  buildForm() {
    this.offerForm = this.formBuilder.group({
      name: [this.offer.name, [Validators.required, Validators.maxLength(250)]],
      orderNo: [this.offer.orderNo, [Validators.min(1)]],
      offerShortName: [this.offer.offerShortName],
      offerCode: this.offer.offerCode,
      description: [this.offer.description, [Validators.maxLength(500)]],
      merchantId: [this.offer.merchantId],
      offerPrice: [this.offer.offerPrice, [Validators.required, Validators.pattern('^\\d+(\\.\\d{1,2})?$'), Validators.pattern('^[1-9]+[0-9]*$'),
      (control: AbstractControl) => Validators.max(this.maxAmount)(control),
      (control: AbstractControl) => Validators.min(this.minAmount)(control)]],
      offerPriceSlab: [this.offerPriceSlab],
      assignmentPerUser: [this.offer.assignmentPerUser],
      offerType: [this.offer.offerType ? this.offer.offerType.toString() : '', [Validators.required]],
      referenceOfferId: [this.offer.referenceOfferId],
      occasionId: [this.offer.occasionId]
    });

    this.onPriceChange();
  }

  openFileBrowser(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById('offerPhotoId') as HTMLElement;
    element.click();
  }

  onChangeMerchant(merchantId) {
    this.selectedMerchantId = merchantId;
    this.getSettingsBySelectedCompany(merchantId);
  }

  onChangeOccasion(occasionId) {
    this.selectedOccasionId = occasionId;
  }

  onPriceChange() {
    this.offerForm.get('offerPrice').valueChanges
      .subscribe((value) => {
        let shortName = `${value} Taka Gift`;
        this.offerForm.get('offerShortName').setValue(shortName);
      });

    this.offerForm.get('offerPriceSlab').valueChanges
      .subscribe((value) => {
        let shortName = `${value ? value : ""} Taka Gift`;
        this.offerForm.get('offerShortName').setValue(shortName);
      });
  }

  uploadLogo(files) {
    this.fileValidationError = null;
    let fileInfo = this.fileUploadService.imageFileUpload(files);
    if (fileInfo.validationError != null) {
      this.fileValidationError = fileInfo.validationError;
      return;
    }
    this.fileName = fileInfo.fileName;
    this.uploadedFiles = fileInfo.formData;

    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imagePreviewPath = event.target.result;
    }
    reader.readAsDataURL(files[0]);
  }

  onSubmit() {
    this.submitted = true;
    const formData = new FormData();

    // stop here if form is invalid
    if (this.offerForm.invalid) {
      return;
    }

    if (this.offer.id === undefined) { // create
      this.createOffer();
    }
    else if (this.offer.id > 0) { // edit
      this.editOffer();
    }

    this.dialogRef.close();
  }

  createOffer() {
    this.offer = new Offer(this.offerForm.value);
    if (this.offer !== null) {
      if (this.useSlab) {
        this.offer.offerPrice = this.offerPriceSlab;
      }
      this.offer.isActive = false;

      this.offer.offerType = OfferType.GolpProduct;
      this.offer.maximumConsumer = 1;
      //this.offer.occasionId 
    }

    this.golpProductService.createGolpProduct(this.offer)
      .subscribe((data: any) => {
        this.offer.id = data.id;
        let logoChanged = false;
        // upload company logo
        if (this.uploadedFiles != null) {
          logoChanged = true;
          this.uploadedFiles.append('id', data.id)
          this.offerService.uploadFile(data.id, this.uploadedFiles).subscribe((result: any) => {
            this.offer.offerImageUrl = result.offerImageUrl;
            this.onOfferCreateEvent.emit(this.offer);
            this.alertService.success('Golp Product created successfully');
          },
            (error: any) => {
              this.showErrorMessage(error);
            });
        }
        if (!logoChanged) {
          this.offer.offerImageUrl = this.imagePreviewPath;
          this.onOfferCreateEvent.emit(this.offer);
          this.alertService.success('Golp Product created successfully');
        }
      },
        (error: any) => {
          this.showErrorMessage(error);
        })
  }

  editOffer() {
    let newData = new Offer(this.offerForm.value);
    if (this.offer !== null) {
      if (this.useSlab) {
        this.offer.offerPrice = this.offerPriceSlab;
      }
      this.offer.offerType = OfferType.GolpProduct;
      this.offer.name = newData.name;
      this.offer.offerShortName = newData.offerShortName;
      this.offer.offerCode = newData.offerCode;
      this.offer.description = newData.description;
      this.offer.maximumConsumer = newData.maximumConsumer;
      this.offer.orderNo = newData.orderNo;
      this.offer.occasionId = newData.occasionId;


      if (newData.merchantId != null) {
        this.offer.merchantId = newData.merchantId;
        this.setMerchantDetails(this.offer.merchantId);
      }
      this.offer.offerPrice = newData.offerPrice;

      this.golpProductService.editGolpProduct(this.offer)
        .subscribe((data: any) => {
          let logoChanged = false;
          //change logo if updated
          if (this.uploadedFiles != null) {
            logoChanged = true;
            // upload company logo
            this.uploadedFiles.append('id', this.offer.id.toString())
            this.offerService.uploadFile(this.offer.id, this.uploadedFiles).subscribe((result: any) => {
              this.offer.offerImageUrl = result.offerImageUrl;
              this.onOfferEditEvent.emit(this.offer);
              this.alertService.success('Golp product edited successfully');
            },
              (error: any) => {
                this.showErrorMessage(error);
              });
          }
          if (!logoChanged) {
            this.onOfferEditEvent.emit(this.offer);
            this.alertService.success('Golp Product edited successfully');
          }
        },
          (error: any) => {
            this.showErrorMessage(error);
          })
    }
  }


  setMerchantDetails(merchantId: number) {
    let provider = this.merchants.filter(c => c.id == merchantId)[0];
    this.offer.merchant = provider.name;
    this.offer.merchantLogoUrl = provider.companyLogoUrl;
  }

  showErrorMessage(error: any) {
    console.log(error);
  }

  getLoggedInUser() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
    console.log(this.userInfo);
  }

  getMerchants() {
    this.companyService.getMerchants()
      .subscribe(data => {
        this.merchants = data;
        this.filteredMerchants.next(this.merchants);
      });
  }

  getOccasions() {
    this.giftOccasions.getOccasions()
      .subscribe(data => {
        this.occasions = data;
        this.filteredOccasions.next(this.occasions);
      });
  }

  resetMerchant() {
    this.offerForm.controls.merchantId.reset();
    this.offer.merchantId = null;
  }

  resetOccasions() {
    this.offerForm.controls.occasionId.reset();
    this.offer.occasionId = null;
  }

  async  getSettingsBySelectedCompany(merchantId: number) {
    this.companySettingService.getSettingsByCompanyAndType(CompanySettingType.GiftAmount, merchantId).subscribe(result => {
      if (result) {
        var giftAmountSetting = new GiftAmountSetting(JSON.parse(result.value));
        this.minAmount = giftAmountSetting.minAmount;
        this.maxAmount = giftAmountSetting.maxAmount ? giftAmountSetting.maxAmount : undefined;
        this.useSlab = giftAmountSetting.useSlab;
        this.slabs = giftAmountSetting.slabs;
        if (this.inEditMode && this.useSlab)
          this.offerForm.get('offerPriceSlab').setValue(this.offer.offerPrice);
      }
      else {
        this.minAmount = this.globalMinAmount;
        this.maxAmount = this.globalMaxAmount;
        this.useSlab = false;
      }

      this.setOfferPriceOrSlabValidators();
    }, error => {

    });

  }

  getGlobalSettings() {
    this.companySettingService.getSettingsByCompanyAndType(CompanySettingType.GiftAmount).subscribe(result => {
      if (result) {
        this.globalGiftAmountSetting = new GiftAmountSetting(JSON.parse(result.value));
        this.globalMinAmount = this.globalGiftAmountSetting.minAmount;
        this.globalMaxAmount = this.globalGiftAmountSetting.maxAmount ? this.globalGiftAmountSetting.maxAmount : undefined;
        this.minAmount = this.globalMinAmount;
        this.maxAmount = this.globalMaxAmount;
        this.useSlab = false;
      }
    });
  }


  bindFilterChangeEvent() {
    this.offerTypeKeyFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterGiftType();
      });

    this.merchantFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterData(this.merchants, this.merchantFilterCtrl.value, this.filteredMerchants);
      });

    this.occasionFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterData(this.occasions, this.occasionFilterCtrl.value, this.filteredOccasions);
      });
  }

  filterData(allData: any, search: string, filterData: any) {
    if (!allData) {
      return;
    }
    if (!search) {
      filterData.next(allData);
      return;
    } else {
      search = search.toLowerCase();
    }
    filterData.next(
      allData.filter(data => data.name.toLowerCase().indexOf(search) > -1)
    );
  }

  filterGiftType() {
    if (!this.offerTypeKeys) {
      return;
    }

    let search = this.offerTypeKeyFilterCtrl.value;
    if (!search) {
      this.filteredOfferTypeKeys.next(this.offerTypeKeys);
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredOfferTypeKeys.next(
      this.offerTypeKeys.filter(key => this.offerType[key].toLowerCase().indexOf(search) > -1)
    );
  }

  close() {
    this.dialogRef.close();
  }

  // convenience getter for easy access to form fields
  get f() { return this.offerForm.controls; }

  setOfferPriceOrSlabValidators() {
    const offerPriceControl = this.offerForm.get('offerPrice');
    const offerPriceSlabControl = this.offerForm.get('offerPriceSlab');

    if (this.useSlab == false) {
      offerPriceControl.setValidators([Validators.required]);
      offerPriceSlabControl.setValidators(null);
    }

    if (this.useSlab == true) {
      offerPriceControl.setValidators(null);
      offerPriceSlabControl.setValidators([Validators.required]);
    }

    offerPriceControl.updateValueAndValidity();
    offerPriceSlabControl.updateValueAndValidity();
  }
}
