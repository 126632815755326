import { Injectable, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Invoice } from '../models/invoice';
import { AddTransaction } from '../models/add-transaction';
import { PaymentSession } from '../models/payment-session';
import { GetInvoiceInput } from '../models/get-invoice-input';
import { GetTransactionInput } from '../models/get-transaction-input';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  private billingBaseUri = 'api/billing';

  constructor(
    private http: HttpClient
  ) { }

  getNonPaidInvoices(serviceOfferorId, outletQRCode): Observable<any[]> {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    if (!outletQRCode)
      return this.http.get<any[]>(this.billingBaseUri + '/get-non-paid-invoices/' + serviceOfferorId, requestHeader);
    else
      return this.http.get<any[]>(this.billingBaseUri + '/get-non-paid-invoices/' + serviceOfferorId + '/outletQRCode/' + outletQRCode, requestHeader);

  }

  getNonPaidInvoicesPagedList(input: GetInvoiceInput): Observable<any> {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post<any[]>(this.billingBaseUri + '/get-non-paid-invoice-paged-list/', input, requestHeader);
  }
  
  getPaidInvoices(serviceOfferorId, outletQRCode): Observable<any[]> {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    if(!outletQRCode)
      return this.http.get<any[]>(this.billingBaseUri + '/get-paid-invoices/' + serviceOfferorId, requestHeader);
    else
      return this.http.get<any[]>(this.billingBaseUri + '/get-paid-invoices/' + serviceOfferorId + '/outletQRCode/' + outletQRCode, requestHeader);
  }

  getPaidInvoicesPagedList(input: GetInvoiceInput): Observable<any> {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post<any[]>(this.billingBaseUri + '/get-paid-invoice-paged-list/', input, requestHeader);
  }

  getCompanyCurrentBalance(companyId, outletQRCode): Observable<any[]> {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    if(!outletQRCode)
      return this.http.get<any[]>(this.billingBaseUri + '/get-company-current-balance/' + companyId, requestHeader);
    else
      return this.http.get<any[]>(this.billingBaseUri + '/get-company-current-balance/' + companyId + '/outletQRCode/' + outletQRCode, requestHeader);
  }

  getInvoiceDetail(invoiceId): Observable<any[]> {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.get<any[]>(this.billingBaseUri + '/get-invoice-detail/' + invoiceId, requestHeader);
  }

  createInvoiceForOfferProvider(invoice: Invoice) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.billingBaseUri + '/create-giftsender-invoice', invoice, requestHeader);
  }

  createInvoiceForMerchant(invoice: Invoice) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.billingBaseUri + '/create-invoice-for-merchant', invoice, requestHeader);
  }

  startPayment(paymentSession: PaymentSession) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.billingBaseUri + '/start-payment', paymentSession, requestHeader);
  }

  startGolpCreditPayment(paymentSession: PaymentSession) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.billingBaseUri + '/start-credit-payment', paymentSession, requestHeader);
  }

  addTransactionForCompany(addTransaction: AddTransaction) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.billingBaseUri + '/add-transaction-for-company', addTransaction, requestHeader);
  }
  getTransactionHistories(companyId,outletQRCode): Observable<any[]> {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    if(!outletQRCode)
      return this.http.get<any[]>(this.billingBaseUri + '/get-transaction-histories/' + companyId, requestHeader);
    else
      return this.http.get<any[]>(this.billingBaseUri + '/get-transaction-histories/' + companyId + '/outletQRCode/' + outletQRCode, requestHeader);

  }

  getTransactionHistoriesPagedList(input: GetTransactionInput): Observable<any> {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post<any[]>(this.billingBaseUri + '/get-transaction-histories-paged-list/',input, requestHeader);
  }
 
}
