import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../services/report.service';
import { PermissionCheckerService } from '../../services/permission-checker.service';

@Component({
  selector: 'app-user-registration-report',
  templateUrl: './user-registration-report.component.html',
  styleUrls: ['./user-registration-report.component.css']
})

export class UserRegistrationReportComponent implements OnInit {

  userRegistrationSubMenu: any[] = [];
  selectedSubMenuIndex = 0;
  isAdmin = false;
  activeSubMenu = 'User Registration Report';

  constructor(
    private permissionCheckerService: PermissionCheckerService
  ) {
    this.setSubMenu();
  }

  ngOnInit() {
  }

  setSubMenu() {
    this.userRegistrationSubMenu.push({ 'name': 'User Registration Report', 'dataTarget': 'userRegistrationReportTab' });
    this.userRegistrationSubMenu.push({ 'name': 'Lock Unlock User', 'dataTarget': 'lockUnlockUserTab' });

    if (this.permissionCheckerService.hasPermission(['UserRegistrationAdminReport'])) {
      this.isAdmin = true;
      this.userRegistrationSubMenu.push(
        { 'name': 'User Registration Report For Admin', 'dataTarget': 'userRegistrationReportForAdminTab' });
    }
  }

  onSubMenuChange(name, index) {
    if (this.selectedSubMenuIndex !== index) {
      this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
      this.activeSubMenu = this.userRegistrationSubMenu.filter(c => c.name === name)[0].name;
    }
  }
}
