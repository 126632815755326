export class PrivilegeCardModel {
    cardId:string;
    cardName:string;
    validFrom:Date;
    validTill:Date;
    description:string;
    isActive:boolean;

    public constructor(init?: Partial<PrivilegeCardModel>) {
        Object.assign(this, init);
    }
}