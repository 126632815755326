import { PaymentStatus } from "../enums/payment-status.enum";

export class Invoice {
  id: number;
  invoiceNo: string;
  dueDate: string;
  paidDate: string;
  paymentStatus: PaymentStatus;
  amount: number;
  offerIds: number[];
  offerAssignmentIds: string[];
  discountAmount: number;
  companyId: number;
  outletQRCode: any;

  public constructor(init?: Partial<Invoice>) {
    Object.assign(this, init);
  }
}
