import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Company } from '../models/company';
import { CompanyRole } from '../enums/company-role.enum';
import { GetInvoiceInput, TrendingMerchant } from '../models';
import { CompanyCreditModel } from '../models/company-credit-model';
import { CompanyReferenceModel } from '../models/company-reference-model';
import { CompanyCreditSummary } from '../models/company-credit-summary';
import { MerchantAvailInstruction } from '../models/merchant-avail-instruction';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private companyBaseUri = 'api/company';
  requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };

  constructor(
    private http: HttpClient
  ) { }

  createCompany(company: Company) {
    return this.http.post(this.companyBaseUri, company, this.requestHeader);
  }

  editCompany(company: Company) {
    return this.http.put(this.companyBaseUri, company, this.requestHeader);
  }

  deleteCompany(companyId: number) {
    return this.http.delete(this.companyBaseUri + '/' + companyId, this.requestHeader);
  }

  enableDisableMerchant(company: Company) {
    return this.http.put(`${this.companyBaseUri}/merchant/activate`, company, this.requestHeader);
  }

  getCompaniesByRoleType(companyRole: CompanyRole): Observable<any[]> {
    return this.http.get<any[]>(this.companyBaseUri + '/companies/' + companyRole);
  }

  getMerchants(): Observable<any[]> {
    return this.http.get<any[]>(this.companyBaseUri + '/merchant', this.requestHeader);
  }

  getMerchantBasics(): Observable<any[]> {
    return this.http.get<any[]>(this.companyBaseUri + '/merchant/basics', this.requestHeader);
  }

  getAllMerchants(): Observable<any[]> {
    return this.http.get<any[]>(this.companyBaseUri + '/all-merchant', this.requestHeader);
  }

 
  getOfferProviders(): Observable<any[]> {
    return this.http.get<any[]>(this.companyBaseUri + '/offer-provider');
  }

  getOfferProviderBasics(): Observable<any[]> {
    return this.http.get<any[]>(this.companyBaseUri + '/offer-provider/basics');
  }

  getCompanyById(companyId: number): Observable<Company> {
    return this.http.get<Company>(this.companyBaseUri + '/' + companyId);
  }

  uploadFile(uploadedFileData: FormData) {
    return this.http.post(this.companyBaseUri + '/upload-file', uploadedFileData);
  }

  saveCompanyCredit(companyCredit: CompanyCreditModel) {
    return this.http.put(this.companyBaseUri + '/save-credit', companyCredit, this.requestHeader);
  }
  getCompanyCreditsByCompanyId(companyId: number) {
    return this.http.get<CompanyCreditModel[]>(this.companyBaseUri + '/getall-credit/' + companyId);
  }

  changeCompanyCreditStatus(companyCredit: CompanyCreditModel) {
    return this.http.put(this.companyBaseUri + "/change-credit-status", companyCredit, this.requestHeader);
  }
  changeCompanyAllCreditStatus(comanyId: number, isActive: boolean) {
    return this.http.put(this.companyBaseUri + "/change-all-credit-status/" + comanyId + "/" + isActive, this.requestHeader);
  }
  saveCompanyReference(companyReference: CompanyReferenceModel) {
    return this.http.put(this.companyBaseUri + '/save-reference', companyReference, this.requestHeader);
  }
  getAllCompanyReferencesByCompanyId(companyId: number) {
    return this.http.get<CompanyReferenceModel[]>(this.companyBaseUri + '/getall-reference/' + companyId);
  }
  deleteReference(referenceId: number) {
    return this.http.delete(this.companyBaseUri + '/delete-reference/' + referenceId, this.requestHeader);
  }

  getCompanyCreditSummaryByCompanyId(companyId: number): Observable<CompanyCreditSummary> {
    return this.http.get<CompanyCreditSummary>(this.companyBaseUri + '/' + companyId + '/creditsummary');
  }

  getMerchantAvailInstructionsByCompanyId(companyId: number): Observable<any> {
    return this.http.get<any>(this.companyBaseUri + '/avail-instructions/' + companyId);
  }

  addOrEditMerchantAvailInstructions(instruction: MerchantAvailInstruction) {
    return this.http.put(this.companyBaseUri +'/add-avail-instruction', instruction, this.requestHeader);
  }
}
