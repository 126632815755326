import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router, NavigationExtras } from '@angular/router';
import { MerchantOutletResponse } from '../../models/view-models/merchant-outlets-response';
import { DataService } from '../../services/shared/data.service';
import { UploadBulkAmountComponent } from '../upload-bulk-amount/upload-bulk-amount.component';

@Component({
  selector: 'appc-gift-information',
  templateUrl: './gift-information.component.html',
  styleUrls: ['./gift-information.component.scss']
})
export class GiftInformationComponent implements OnInit {
  userInfo: any;
  giftInfoForm: FormGroup;
  merchantOutlets: any;
  submitted: boolean = false;
  giftInformation: any;
  merchantId: any;
  companyOutletId: any;
  showOutlet: boolean = true;
  selectedOutlet: any;
  outletValidator: any;
  isPaperGiftCard: boolean = false;
  isSerialVerified: boolean = false;
  checkSerialVerification: boolean = true;
  paperGiftCode: string = "";
  paperGiftErrorMessage: string;
  paperGiftResponseData: any;
  isAmountFieldDisabled: boolean = false;
  giftAmount: number;
  salesAmount: number;
  giftOccasions: any;
  occasionId: any;
  selectedOccasion: any;

  //settings
  settings: any;
  isCheckboxDisabled: boolean = false;
  paperFormatAvailable: boolean = false;

  //html element
  @ViewChild("digitalGiftCheck") digitalGift: ElementRef;

  //form data
  isActiveGift: boolean = true;

  constructor(public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
    private dataService: DataService,
    private cdRef: ChangeDetectorRef) {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.getGiftOccasions();
    let navigation = this.router.getCurrentNavigation();
    let state = navigation.extras.state as {
      transId: string,
      workQueue: boolean,
      services: number,
      code: any
    };

    if (state != undefined && state.code != undefined) {
      this.giftInformation = state.code;
      if (state.transId != undefined && state.transId != null && state.transId == "giftInformation") {
        this.isPaperGiftCard = this.giftInformation?.isPaperGiftCard;
        this.isSerialVerified = this.giftInformation?.isSerialVerified;
        this.paperGiftCode = this.giftInformation?.paperGiftCode;
        this.isAmountFieldDisabled = this.giftInformation?.isAmountFieldDisabled;

        this.paperGiftResponseData = this.giftInformation?.paperGiftResponseData;
        this.merchantOutlets = this.giftInformation?.merchantOutlets;
        this.salesAmount = this.giftInformation?.salesAmount;
        this.isActiveGift = this.giftInformation?.isActive;
      }

      this.giftAmount = this.giftInformation?.amount;
      this.isAmountFieldDisabled = this.giftInformation?.isPopularGift;
    }
    this.setUserInfo();
    this.buildFormAndData();

    if (state != undefined && state.code != undefined) {
      this.giftInfoForm.controls['amount'].setValue(this.giftAmount);
      if (this.isAmountFieldDisabled)
        this.giftInfoForm.controls['amount'].disable();
    }
    this.loadSettings();
  }

  setUserInfo() {
    this.merchantId = this.userInfo.companyId;
    this.companyOutletId = this.userInfo.outletQrCode;
    if (this.companyOutletId == undefined || this.companyOutletId == null) {
      this.companyOutletId = this.giftInformation?.outletId;
      this.selectedOutlet = this.companyOutletId;
      this.showOutlet = true;
      this.outletValidator = Validators.required;
    }
    else {
      this.showOutlet = false;
      this.selectedOutlet = this.companyOutletId;
      this.outletValidator = Validators.nullValidator;
    }
  }

  loadSettings() {
    this.settings = JSON.parse(localStorage.getItem("_availableFormats"));
    this.paperFormatAvailable = (this.settings?.includes(2) || this.settings?.includes(3)) ? true : false;
  }

  buildFormAndData() {
    this.giftInfoForm = this.formBuilder.group({
      sender: [this.giftInformation?.sender, Validators.required],
      receiver: [this.giftInformation?.receiver, Validators.required],
      amount: [this.giftAmount, [Validators.required, amountCheck(50)]],
      salesAmount: [this.salesAmount, [Validators.nullValidator]],
      outletId: [this.selectedOutlet, this.outletValidator],
      message: [this.giftInformation?.message, Validators.nullValidator],
      occasionId: [this.occasionId],
      smsTemplate: [this.selectedOccasion?.smsTemplate],
      receiverEmail: [this.giftInformation?.receiverEmail],
    });
  }

  ngOnInit() {
    //this.getGiftOccasions();
    if (this.companyOutletId == null && this.merchantId != null) {
      if (this.merchantOutlets == null || this.merchantOutlets.length <= 0) {
        this.getMerchantOutlet();
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.settings?.includes(2)) {
      this.isCheckboxDisabled = true;
      this.isPaperGiftCard = true;
      this.digitalGift?.nativeElement?.click();
      this.cdRef.detectChanges();
    }
  }

  get f() { return this.giftInfoForm.controls; }

  setSelectedOutlet() {
    this.selectedOutlet = this.merchantOutlets.find(m => m.id == this.companyOutletId);
    if (this.selectedOutlet != null || this.selectedOutlet != undefined)
      this.giftInfoForm = this.formBuilder.group({
        sender: [this.giftInformation?.sender, Validators.required],
        receiver: [this.giftInformation?.receiver, Validators.required],
        amount: [this.giftAmount, [Validators.required, amountCheck(50)]],
        salesAmount: [this.salesAmount, Validators.nullValidator],
        outletId: [this.selectedOutlet, Validators.nullValidator],
        message: [this.giftInformation?.message, Validators.nullValidator],
        smsTemplate: [this.selectedOccasion?.smsTemplate],
        receiverEmail: [this.giftInformation?.receiverEmail],
      });
  }

  giftAmountTouched() {
    this.isSerialVerified = false;
  }

  onSubmit() {

    this.submitted = true;
    this.checkSerialVerification = true;

    // console.log("form : ", this.giftInfoForm, " is invalid ",this.giftInfoForm.invalid , "is paper gift ", this.isPaperGiftCard , "serial verified : ", this.isSerialVerified)
    if (this.giftInfoForm.invalid || (this.isPaperGiftCard && !this.isSerialVerified)) {
      return;
    }
    this.submitted = false;
    let giftInfo = this.getGiftInfo(this.giftInfoForm.value)
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.giftInfoForm.value))
    giftInfo["isActive"] = this.isActiveGift;
    let navigationEgolps: NavigationExtras = {
      state: {
        transId: 'giftInformation',
        workQueue: false,
        services: 1,
        code: giftInfo,
      }
    };

    this.router.navigate(['send-gift-confirmation'], navigationEgolps);
  }

  verifyPaperSerialNo() {
    if (!this.paperGiftCode || this.paperGiftCode == "") {
      this.paperGiftErrorMessage = "Invalid Paper Gift Code.";
      return;
    }
    let url = `api/gift-templates/verify-paper-card/merchant/${this.merchantId}/${this.paperGiftCode}/`;
    this.dataService.get(url).subscribe((data: any) => {
      if (data.success) {
        this.isSerialVerified = true;
        this.checkSerialVerification = false;
        this.paperGiftErrorMessage = "";
        this.paperGiftResponseData = data;
        this.isAmountFieldDisabled = true;
        this.giftAmount = this.paperGiftResponseData.data.giftAmount;
        this.giftInfoForm.controls['amount'].setValue(this.giftAmount);
        this.giftInfoForm.controls['amount'].disable();
      }
      else {
        this.isSerialVerified = false;
        this.checkSerialVerification = true;
        this.paperGiftErrorMessage = "Invalid Paper Gift Code.";
      }
    }, () => {
      this.isSerialVerified = false;
      this.checkSerialVerification = true;
      this.paperGiftErrorMessage = "Invalid Paper Gift Code.";
      this.isAmountFieldDisabled = false;
    });
  }

  changeOccasion(event: any) {
    this.selectedOccasion = this.giftOccasions.filter((item: any) => item.id == event)[0];

    this.selectedOccasion.smsTemplate = this.selectedOccasion?.smsTemplate
      .replace('{{receiver}}', this.giftInfoForm.value?.receiver)
      .replace('{{sender}}', this.giftInfoForm.value?.sender)
      .replace('{{amount}}', this.giftAmount != undefined ? this.giftAmount : this.giftInfoForm.value?.amount);

    this.giftInfoForm.get('smsTemplate').setValue(this.selectedOccasion.smsTemplate);
  }

  getGiftInfo(data) {
    if (this.isPaperGiftCard) {
      return {
        "sender": data.sender,
        "receiver": data.receiver,
        "receiverEmail": data.receiverEmail,
        "paperGiftCardId": this.paperGiftResponseData.data.id,
        "amount": this.giftAmount,
        "salesAmount": this.salesAmount,
        "message": data.smsTemplate != null ? data.smsTemplate : data.message,
        "outletId": this.showOutlet ? data.outletId : this.selectedOutlet,
        "showOutlet": this.showOutlet,
        "isPaperGiftCard": this.isPaperGiftCard,
        "isSerialVerified": this.isSerialVerified,
        "paperGiftCode": this.paperGiftCode,
        "isAmountFieldDisabled": this.isAmountFieldDisabled,
        "paperGiftResponseData": this.paperGiftResponseData,
        "merchantOutlets": this.merchantOutlets,
        "smsTemplate": data.smsTemplate,
        "smsTemplateId": this.selectedOccasion?.templateId
      }
    }
    else if (this.giftInformation?.isPopularGift) {
      return {
        "sender": data.sender,
        "receiver": data.receiver,
        "receiverEmail": data.receiverEmail,
        "amount": this.giftAmount,
        "salesAmount": data.salesAmount,
        "message": data.smsTemplate != null ? data.smsTemplate : data.message,
        "outletId": this.showOutlet ? data.outletId : this.selectedOutlet,
        "showOutlet": this.showOutlet,
        "isPaperGiftCard": this.isPaperGiftCard,
        "isSerialVerified": this.isSerialVerified,
        "paperGiftCode": this.paperGiftCode,
        "isAmountFieldDisabled": this.isAmountFieldDisabled,
        "paperGiftResponseData": this.paperGiftResponseData,
        "merchantOutlets": this.merchantOutlets,
        "isPopularGift": this.giftInformation?.isPopularGift,
        "smsTemplate": data.smsTemplate,
        "smsTemplateId": this.selectedOccasion?.templateId
      }
    }
    return {
      "sender": data.sender,
      "receiver": data.receiver,
      "receiverEmail": data.receiverEmail,
      "amount": data.amount,
      "salesAmount": data.salesAmount,
      "message": data.smsTemplate != null ? data.smsTemplate : data.message,
      "outletId": this.showOutlet ? data.outletId : this.selectedOutlet,
      "showOutlet": this.showOutlet,
      "isPaperGiftCard": this.isPaperGiftCard,
      "isSerialVerified": this.isSerialVerified,
      "paperGiftCode": this.paperGiftCode,
      "isAmountFieldDisabled": this.isAmountFieldDisabled,
      "paperGiftResponseData": this.paperGiftResponseData,
      "merchantOutlets": this.merchantOutlets,
      "smsTemplate": data.smsTemplate,
      "smsTemplateId": this.selectedOccasion?.templateId
    }
  }

  changedSerial($event) {
    this.isSerialVerified = false;
    this.checkSerialVerification = false;
    this.paperGiftCode = $event.target.value;
    this.isAmountFieldDisabled = false;
    this.giftInfoForm.controls['amount'].enable();
  }
  getMerchantOutlet() {
    let url = `api/Outlets/company/${this.merchantId}/`;
    // if(this.companyOutletId != null)
    //   url += `${this.companyOutletId}`
    this.dataService.get(url).subscribe((data: MerchantOutletResponse[]) => {
      if (data != null && data.length > 0) {
        this.merchantOutlets = data;
        this.setSelectedOutlet();
      }
    });
  }

  getGiftOccasions() {
    let url = `api/giftcard/gift-occasion/${this.userInfo.companyId}/`;
    this.dataService.get(url).subscribe((data: any) => {
      if (data.status) {
        this.giftOccasions = data.data;
        //console.log(this.giftOccasions);
      }
    });
  }

  linkPaperGiftChanged() {
    this.isPaperGiftCard = !this.isPaperGiftCard;
    if (!this.isPaperGiftCard) {
      this.isAmountFieldDisabled = false;
      this.giftInfoForm.controls['amount'].enable();
    }
    else if (this.isPaperGiftCard && this.isSerialVerified) {
      this.giftInfoForm.controls['amount'].disable();
      this.isAmountFieldDisabled = true;
    }
  }

  uploadBulkClicked() {
    this.openBulkDialog();
  }

  downloadDemo() {
    window.open(`/api/giftcard/DownloadBulkReportDemo`, '_blank');
  }

  openBulkDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'golp-dialog';
    dialogConfig.width = '45%';

    const dialogRef = this.dialog.open(UploadBulkAmountComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {
    });
  }
}

export function amountCheck(value: number) {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const input = Number(control.value);
    return input < value ? { amountError: { value: control.value } } : null;
  };
}

