import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'appc-gift-sending-progress',
  templateUrl: './gift-sending-progress.component.html',
  styleUrls: ['./gift-sending-progress.component.scss']
})
export class GiftSendingProgressComponent implements OnInit {

  @Input() step: number = 0;

  constructor() { }

  ngOnInit() {
  }

}
