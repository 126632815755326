import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { CompanyCategoryService } from 'src/app/services/company-category.service';
import { CompanyCategory } from 'src/app/models/company-category';
import { Company } from 'src/app/models/company';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, ReplaySubject } from 'rxjs';
import { PaymentMethod } from 'src/app/enums/payment-method.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyRole } from 'src/app/enums/company-role.enum';
import { CompanyService } from 'src/app/services/company.service';
import { AlertService, FileUploadService, AccountServiceService } from 'src/app/services';
import { ValidationUtility } from '../../utility/validation.utility';

@Component({
  selector: 'app-create-merchant',
  templateUrl: './create-merchant.component.html',
  styleUrls: ['./create-merchant.component.css']
})
export class CreateMerchantComponent implements OnInit {

  // onCreate event
  onMerchantCreateEvent: EventEmitter<Company> = new EventEmitter();
  // onEdit event;
  onMerchantEditEvent: EventEmitter<Company> = new EventEmitter();

  companyCategories: CompanyCategory[];
  merchant: Company;

  categoryForm = new FormControl();
  filteredCategory: Observable<CompanyCategory[]>;

  paymentOptions = PaymentMethod;
  paymentOptionsKeys: any[];
  merchantCreationForm: FormGroup;
  submitted = false;
  uploadedFiles: FormData;
  inEditMode: boolean = false;
  salesCommissionTypes: any;

  fileName: string = '';
  imagePreviewPath: string = '../../../assets/images/default.png';
  fileValidationError: string;

  merchantProviderFilterCtrl: FormControl = new FormControl();
  filteredMerchantProviders: ReplaySubject<CompanyCategory[]> = new ReplaySubject<CompanyCategory[]>(1);

  constructor(private companyCategoryService: CompanyCategoryService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateMerchantComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private companyService: CompanyService,
    private alertService: AlertService,
    private fileUploadService: FileUploadService, private accountService: AccountServiceService
  ) {

    this.merchant = new Company();

    if (data !== null) {
      this.merchant = new Company(data);
      this.inEditMode = true;
      this.fileName = this.merchant.imageName;
      this.imagePreviewPath = this.merchant.companyLogoUrl;
    }
    this.buildForm();
  }

  ngOnInit() {
    this.getCompanyCategories();
    this.paymentOptionsKeys = Object.keys(this.paymentOptions).filter(Number);
    this.bindFilterChangeEvent();
    this.salesCommissionTypes = [{ text: 'Sales Amount', value: 1 }, { text: 'Gift Amount', value: 2 }];

  }

  buildForm() {
    this.merchantCreationForm = this.formBuilder.group({
      name: [this.merchant.name, [Validators.required, Validators.maxLength(250)]],
      shortName: [this.merchant.shortName, [Validators.required, Validators.maxLength(10)]],
      phone: [this.merchant.phone, Validators.maxLength(50)],
      email: [this.merchant.email, [Validators.email, Validators.maxLength(50)]],
      address: [this.merchant.address, [Validators.maxLength(250)]],
      webAddress: [this.merchant.webAddress, [Validators.maxLength(100)]],
      companyCategoryId: [this.merchant.companyCategoryId, Validators.required],
      salesCommission: [{
        value: this.merchant.salesCommission,
        disabled: !this.accountService.getLoggedInUserInfo().isSystemAdmin
    }, [Validators.required, Validators.pattern('^\\d+(\\.\\d{1,2})?$')]],
      salesCommissionType: [{
        value: this.merchant.salesCommissionType,
        disabled: !this.accountService.getLoggedInUserInfo().isSystemAdmin
    }, [Validators.required, Validators.pattern('^\\d+(\\.\\d{1,2})?$')]],

    });
  }

  onUploadLogo(files) {
    this.fileValidationError = null;
    let fileInfo = this.fileUploadService.imageFileUpload(files);
    if (fileInfo.validationError != null) {
      this.fileValidationError = fileInfo.validationError;
      return;
    }
    this.fileName = fileInfo.fileName;
    this.uploadedFiles = fileInfo.formData;

    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imagePreviewPath = event.target.result;
    }
    reader.readAsDataURL(files[0]);
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.merchantCreationForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    if (this.merchant.id === undefined) { // create
      this.createMerchant();
    }
    else if (this.merchant.id > 0) { // edit
      this.editMerchant();
    }
    this.dialogRef.close();
  }

  createMerchant() {
    this.merchant = new Company(this.merchantCreationForm.value);
    if (this.merchant !== null) {
      this.merchant.companyRole = CompanyRole.Merchant;
      this.merchant.paymentMethod = PaymentMethod.None;
    }

    this.companyService.createCompany(this.merchant)
      .subscribe((data: any) => {
        let logoUpload = false;
        // upload company logo
        if (this.uploadedFiles != null) {
          logoUpload = true;
          this.uploadedFiles.append('id', data.id)
          this.companyService.uploadFile(this.uploadedFiles).subscribe((result: any) => {
            this.onMerchantCreateEvent.emit(result);
            this.alertService.success("Merchant created successfully");
          },
            (error: any) => {
              this.showErrorMessage(error);
            });
        }
        if (!logoUpload) {
          this.merchant.id = data.id;
          this.merchant.companyCategory = this.companyCategories.filter(c => c.id === this.merchant.companyCategoryId)[0].name;
          this.merchant.companyLogoUrl = this.imagePreviewPath;
          this.onMerchantCreateEvent.emit(this.merchant);
          this.alertService.success("Merchant created successfully");
        }
      },
        (error: any) => {
          this.showErrorMessage(error);
        })
  }

  editMerchant() {
    let newData = new Company(this.merchantCreationForm.getRawValue());
    if (this.merchant !== null) {
      this.merchant.companyCategoryId = newData.companyCategoryId;
      this.merchant.name = newData.name;
      this.merchant.shortName = newData.shortName;
      this.merchant.phone = newData.phone;
      this.merchant.email = newData.email;
      this.merchant.address = newData.address;
      this.merchant.webAddress = newData.webAddress;
      this.merchant.salesCommission = newData.salesCommission;
      this.merchant.salesCommissionType = newData.salesCommissionType;

      this.companyService.editCompany(this.merchant)
        .subscribe((data: any) => {
          let logoChanged = false;
          //change logo if updated
          if (this.uploadedFiles != null) {
            logoChanged = true;
            // upload company logo
            this.uploadedFiles.append('id', this.merchant.id.toString())
            this.companyService.uploadFile(this.uploadedFiles).subscribe((result: any) => {
              this.merchant.companyLogoUrl = result.companyLogoUrl;
              this.merchant.imageName = result.imageName;
              this.merchant.companyCategory = this.companyCategories.filter(c => c.id === this.merchant.companyCategoryId)[0].name;
              this.onMerchantEditEvent.emit(this.merchant);
              this.alertService.success("Merchant edited successfully");
            },
              (error: any) => {
                this.showErrorMessage(error);
              });
          }
          if (!logoChanged) {
            this.merchant.companyCategory = this.companyCategories.filter(c => c.id === this.merchant.companyCategoryId)[0].name;
            this.onMerchantEditEvent.emit(this.merchant);
            this.alertService.success("Merchant edited successfully");
          }
        },
          (error: any) => {
            this.showErrorMessage(error);
          });
    }
  }

  getCompanyTypeId(companyCategoryId) {
    this.merchant.companyCategoryId = companyCategoryId.id;
  }

  getCompanyCategories() {
    this.companyCategoryService.getCompanyCategories()
      .subscribe(data => {
        this.companyCategories = data;
        this.filteredMerchantProviders.next(this.companyCategories);
      });
  }

  displayCategoryFn(category?: CompanyCategory): string | undefined {
    let data = this.companyCategories;
    return category ? category.name : undefined;
  }

  bindFilterChangeEvent() {
    this.merchantProviderFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterMerchantproviders();
      });
  }

  protected filterMerchantproviders() {
    if (!this.companyCategories) {
      return;
    }

    let search = this.merchantProviderFilterCtrl.value;
    if (!search) {
      this.filteredMerchantProviders.next(this.companyCategories);
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredMerchantProviders.next(
      this.companyCategories.filter(comapnyCategory => comapnyCategory.name.toLowerCase().indexOf(search) > -1)
    );
  }

  showErrorMessage(error: any) {
    console.log(error);
    //this.alertService.error("Internal server error happen");
  }

  openFileBrowser(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById('CompanyPhotoId') as HTMLElement;
    element.click();
  }

  close() {
    this.dialogRef.close();
  }

  // convenience getter for easy access to form fields
  get f() { return this.merchantCreationForm.controls; }

}
