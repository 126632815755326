import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  constructor() { }

  getCompanySettingTypes() {
    var companySettingTypes = [{ text: 'Merchant Acceptance Avail Method', value: 1 }, { text: 'Real Time Payment', value: 2 },
    { text: 'API End Point Configuration', value: 3 }, { text: 'Gift Pre Enabled', value: 4 },
    { text: 'Payment On Outlet/Centrally', value: 5 }, { text: 'Gift Amount', value: 6 },
    { text: 'Promotional Gift', value: 7 }, { text: 'Trending Merchants', value: 8 },
    { text: 'Merchant Due Payment', value: 9 }, { text: 'Merchant Payment Account Info', value: 10 },
    { text: 'Gift Usage Limit', value: 11 }, { text: 'Exclude Merchants', value: 12 },
    { text: 'Gift Resend Policy', value: 13 },
    { text: 'Loyalty Card Tier Map', value: 14 }, { text: 'Loyalty Point Settings', value: 15 }];

    return companySettingTypes;
  }

  getCompanyPaymentOnTypes() {
    var companyPaymentOnTypes = [{ text: 'Centrally', value: 1 }, { text: 'Outlet Specific', value: 2 }];
    return companyPaymentOnTypes;
  }

  getDailyWeeklyMonthlyTypes() {
    var companyPaymentOnTypes = [{ text: 'Daily', value: 1 }, { text: 'Weekly', value: 2 }, { text: 'Monthly', value: 3 }];
    return companyPaymentOnTypes;
  }


  getRolePermissionTypes() {
    var rolePermissionTypes = [
      { text: 'NotSet', value: '0x0' },
      { text: 'SystemAdmin', value: '0x1' },
      { text: 'Company Read', value: '0x10' }, { text: 'Company Create', value: '0x11' },
      { text: 'Company Update', value: '0x12' }, { text: 'Company Delete', value: '0x13' },
      { text: 'Merchant Read', value: '0x14' }, { text: 'Merchant Create', value: '0x15' },
      { text: 'Merchant Update', value: '0x16' }, { text: 'Merchant Delete', value: '0x17' },
      { text: 'Provider Read', value: '0x18' }, { text: 'Provider Create', value: '0x19' },
      { text: 'Provider Update', value: '0x1a' }, { text: 'Provider Delete', value: '0x1b' },
      { text: 'Offer Read', value: '0x20' }, { text: 'Offer Create', value: '0x21' },
      { text: 'Offer Update', value: '0x22' }, { text: 'Offer Delete', value: '0x23' },
      { text: 'Offer Approve', value: '0x24' }, { text: 'Offer Reject', value: '0x25' },
      { text: 'OfferAvailedConsumerRead', value: '0x26' }, { text: 'ExtendGiftExpireDate', value: '0x27' },
      { text: 'Offer Assignment Read', value: '0x30' }, { text: 'Offer Assignment Create', value: '0x31' },
      { text: 'Offer Assignment Update', value: '0x32' }, { text: 'Offer Assignment Delete', value: '0x33' },
      { text: 'Block Gift Card', value: '0x34' },
      { text: 'Resend Code', value: '0x35' },
      { text: 'Change Merchant', value: '0x36' },
      { text: 'Offer Avail Initiate', value: '0x40' }, { text: 'Offer Avail Accept', value: '0x41' },
      { text: 'Offer Avail Decline', value: '0x42' }, { text: 'Offer Avail Transaction Read', value: '0x43' },
      { text: 'Outlet Read', value: '0x50' }, { text: 'Outlet Create', value: '0x51' },
      { text: 'Outlet Update', value: '0x52' }, { text: 'Outlet Delete', value: '0x53' },
      { text: 'Invoice Create', value: '0x60' }, { text: 'Provider Invoice Create', value: '0x61' },
      { text: 'Merchant Invoice Create', value: '0x62' }, { text: 'Read Invoice', value: '0x63' },
      { text: 'Create Transaction', value: '0x64' }, { text: 'Read Transaction', value: '0x65' },
      { text: 'Read Payment Due Report', value: '0x66' },
      { text: 'Consumer Read', value: '0x70' }, { text: 'Consumer Create', value: '0x71' },
      { text: 'Consumer Update', value: '0x72' }, { text: 'Consumer Delete', value: '0x73' },
      { text: 'Company Setting Read', value: '0x80' }, { text: 'Company Setting Create', value: '0x81' },
      { text: 'Company Setting Update', value: '0x82' }, { text: 'Company Setting Delete', value: '0x83' },
      { text: 'User Read', value: '0x91' }, { text: 'User Create', value: '0x92' },
      { text: 'User Update', value: '0x93' },
      { text: 'User Delete', value: '0x94' },
      { text: 'Reset Password', value: '0x95' },

      { text: 'View Reports', value: '0x90' }, { text: 'Consumer Gift Report', value: '0x96' },
      { text: 'Corporate Gift Report', value: '0x97' }, { text: 'User Registration Report', value: '0x98' },
      { text: 'User Registration Admin Report', value: '0x99' },
      { text: 'Premium User Credit Usage Report', value: '0x9a' },
      { text: 'Premium User Credit Limit Exceed Report', value: '0x9b' },
      // Hex value converting to smaller case. So we marked 9A to 9a


      { text: 'Golp User Credit Read', value: '0xa0' }, { text: 'Golp User Credit Create', value: '0xa1' },
      { text: 'Golp User Credit Update', value: '0xa2' }, { text: 'Golp User Credit Delete', value: '0xa3' },

      { text: 'Golp Company Credit Read', value: '0xa6' }, { text: 'Golp Company Credit Create', value: '0xa7' },
      { text: 'Golp Company Credit Update', value: '0xa8' }, { text: 'Golp Company Credit Delete', value: '0xa9' },

      { text: 'Golp Reference Read', value: '0xaa' }, { text: 'Golp Reference Create', value: '0xab' },
      { text: 'Golp Reference Update', value: '0xac' }, { text: 'Golp Reference Delete', value: '0xad' },

      { text: 'View Customer Support', value: '0xae' }, { text: 'Make Open Merchant', value: '0xaf' },
      { text: 'Change Offer Assignment', value: '0xb0' },

      { text: 'Trending Merchant Read', value: '0xb1' }, { text: 'Trending Merchant Create', value: '0xb2' },
      { text: 'Trending Merchant Update', value: '0xb3' }, { text: 'Trending Merchant Delete', value: '0xb4' },

      { text: 'Read Gift Template', value: '0xb7' }, { text: 'Create Gift Template', value: '0xb8' },
      { text: 'Edit Gift Template', value: '0xb9' }, { text: 'Delete Gift Template', value: '0xba' },

      { text: 'View Gift Template Assignments', value: '0xbb' }, { text: 'Print Gift Template', value: '0xbc' },
      { text: 'View Gift Template', value: '0xbd' }, { text: 'Assign Gift Template', value: '0xbe' },

      // Corporate Offer Permissions
      { text: 'View Corporate Offer', value: '0xbf' },
      { text: 'Create Corporate Offer', value: '0xc0' },
      { text: 'Delete Corporate Offer', value: '0xc1' },
      { text: 'Activate Corporate Offer', value: '0xc2' },
      { text: 'View Corporate Report', value: '0xc3' },
      { text: 'Search Customer Offer', value: '0xc4' },
      { text: 'View Offer Summary', value: '0xc5' },

      { text: 'Issue Gift Card', value: '0xc6' },
      { text: 'View Gift Card', value: '0xc7' },
      { text: 'Edit Gift Card', value: '0xc8' },
      { text: 'View Gift Card Report', value: '0xc9' },
      { text: 'View Voucher', value: '0xca' },
      { text: 'Sync Voucher', value: '0xcb' },
      { text: 'View Voucher Summary', value: '0xcc' },

      { text: 'Create Reseller Partner', value: '0xd3' },
      { text: 'View Reseller Partner', value: '0xd4' },
      { text: 'Edit Reseller Partner', value: '0xd5' },
      { text: 'Delete Reseller Partner', value: '0xd6' },
      { text: 'View Order Transaction', value: '0xd7' },

      { text: 'Create Golp Card', value: '0xd8' },
      { text: 'Edit Golp Card', value: '0xd9' },
      { text: 'Delete Golp Card', value: '0xda' },
      { text: 'View Golp Card', value: '0xdb' },

      { text: 'Add Card Facility', value: '0xdc' },
      { text: 'Delete Card Facility', value: '0xdd' },

      { text: 'Add Card Beneficiary', value: '0xde' },
      { text: 'Delete Card Beneficiary', value: '0xdf' },
      { text: 'View Card Beneficiary', value: '0xe0' },
      { text: 'View Own GolpCard', value: '0xe1' },
      { text: 'View GolpCard Transaction', value: '0xe2' },

      { text: 'Create StakeHolder', value: '0xe3' },
      { text: 'View StakeHolder', value: '0xe4' },
      { text: 'Edit StakeHolder', value: '0xe5' },
      { text: 'Delete StakeHolder', value: '0xe6' },


    ];

    return rolePermissionTypes;
  }


  getPaginationByPageNumbers() {
    var pageNumbers = [{ text: 5, value: 5 }, { text: 10, value: 10 },
    { text: 25, value: 25 }, { text: 50, value: 50 },
    { text: 100, value: 100 }, { text: 500, value: 500 },];
    return pageNumbers;
  }


  getDayOfWeeks() {
    var dayOfWeeks = [{ text: 'Sunday', value: 0 }, { text: 'Monday', value: 1 }, { text: 'Tuesday', value: 2 },
    { text: 'Wednesday', value: 3 }, { text: 'Thursday', value: 4 },
    { text: 'Friday', value: 5 }, { text: 'Saturday', value: 6 }];

    return dayOfWeeks;
  }


  getPaidByTypes() {
    var dayOfWeeks = [{ text: 'Sunday', value: 0 }, { text: 'Monday', value: 1 }, { text: 'Tuesday', value: 2 },
    { text: 'Wednesday', value: 3 }, { text: 'Thursday', value: 4 },
    { text: 'Friday', value: 5 }, { text: 'Saturday', value: 6 }];

    return dayOfWeeks;
  }

  getbKashAccountTypes() {
    var bkashAccounts = [{ text: 'Personal', value: 1 }, { text: 'Merchant', value: 2 }, { text: 'Agent', value: 3 }];

    return bkashAccounts;
  }
}
