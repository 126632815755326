
export class IndividualGiftReportInput {
  from: string;
  to: string;
  type: any;
  phoneNumber: any;

  pageNumber: number;
  pageSize: number;

  public constructor(init?: Partial<IndividualGiftReportInput>) {
    Object.assign(this, init);
  }
}


export class IndividualGiftReportOutput {
  sender: string;
  receiver: string;
  giftAmount: any;
  merchantId: any;
  merchantName: any;
  sentOn: any;
  expiresOn: any;

  pageNumber: number;
  pageSize: number;

  public constructor(init?: Partial<IndividualGiftReportInput>) {
    Object.assign(this, init);
  }
}
