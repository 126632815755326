export enum RuleCondition {
    None = 0,
    Equal = 1,
    GreaterThanEqual = 2,
    LessThanEqual = 3,
    GreaterThan = 4,
    LessThan = 5,
    In = 6,
    And = 7,
    Or = 8,
    Range = 9
}
