import { BillingModule } from './billing/billing.module';
import { PrivilegeCardModule } from './privilege-card/privilege-card.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth/auth-guard';
import { AuthInterceptor } from './auth/auth-interceptor';
import { AcceptAvailRequestComponent } from './components/accept-avail-request/accept-avail-request.component';
import { AcceptOtpComponent } from './components/accept-otp/accept-otp.component';
import { AccountSettingsComponent } from './components/account-settings/account-settings.component';
import { ActiveConsumerGiftComponent } from './components/active-consumer-gift/active-consumer-gift.component';
import { ActiveCorporateGiftComponent } from './components/active-corporate-gift/active-corporate-gift.component';
import { ActivePromotionalGiftComponent } from './components/active-promotional-gift/active-promotional-gift.component';
import { AddTransactionComponent } from './components/add-transaction/add-transaction.component';
import { AdvanceSearchComponent } from './components/advance-search/advance-search.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { AppPaymentOptionComponent } from './components/app-payment-option/app-payment-option.component';
import { AssignGiftTemplateComponent } from './components/assign-gift-template/assign-gift-template.component';
import { AvailProcessComponent } from './components/avail-process/avail-process.component';
import { RedemptionComponent } from './components/avail-process/redemption/redemption.component';
import { AvailedConsumerListComponent } from './components/availed-consumer-list/availed-consumer-list.component';
import { BaseComponent } from './components/base/base.component';
import { BillingsComponent } from './components/billings/billings.component';
import { BundleOffersComponent } from './components/bundle-offers/bundle-offers.component';
import { ChangeGiftAssignmentComponent } from './components/change-gift-assignment/change-gift-assignment.component';
import { CompanyCreditDetailsComponent } from './components/company-credit-details/company-credit-details.component';
import { CompanyQrCodeComponent } from './components/company-qr-code/company-qr-code.component';
import { CompanyReferencesComponent } from './components/company-references/company-references.component';
import { CompanySettingsComponent } from './components/company-settings/company-settings.component';
import { CompletedTransactionComponent } from './components/completed-transaction/completed-transaction.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationWithTextComponent } from './components/confirmation-with-text/confirmation-with-text.component';
import { ConsumerGiftReportsComponent } from './components/consumer-gift-reports/consumer-gift-reports.component';
import { ConsumerPaymentStatusComponent } from './components/consumer-payment-status/consumer-payment-status.component';
import { ConsumerSendGiftDetailsComponent } from './components/consumer-send-gift-details/consumer-send-gift-details.component';
import { ConsumerSendGiftComponent } from './components/consumer-send-gift/consumer-send-gift.component';
import { ConsumergiftDetailsComponent } from './components/consumergift-details/consumergift-details.component';
import { ViewGiftCodeComponent } from './components/consumergift-details/view-gift-code/view-gift-code.component';
import { ConsumergiftComponent } from './components/consumergift/consumergift.component';
import { CorporateGiftReportsComponent } from './components/corporate-gift-reports/corporate-gift-reports.component';
import { CreateCompanyCreditComponent } from './components/create-company-credit/create-company-credit.component';
import { CreateCompanyReferenceComponent } from './components/create-company-reference/create-company-reference.component';
import { AddAvailInstructionComponent } from './components/create-company-setting/add-avail-instruction/add-avail-instruction.component';
import { CreateApiEndpointConfigurationComponent } from './components/create-company-setting/create-api-endpoint-configuration/create-api-endpoint-configuration.component';
import { CreateCompanySettingComponent } from './components/create-company-setting/create-company-setting.component';
import { CreateExcludeMerchantsSettingComponent } from './components/create-company-setting/create-exclude-merchants-setting/create-exclude-merchants-setting.component';
import { CreateGiftAmountSettingComponent } from './components/create-company-setting/create-gift-amount-setting/create-gift-amount-setting.component';
import { CreateGiftAmountSlabsSettingComponent } from './components/create-company-setting/create-gift-amount-slab-setting/create-gift-amount-slab-setting.component';
import { CreateGiftResendPolicyComponent } from './components/create-company-setting/create-gift-resend-policy/create-gift-resend-policy.component';
import { CreateGiftUsageLimitSettingComponent } from './components/create-company-setting/create-gift-usage-limit-setting/create-gift-usage-limit-setting.component';
import { CreateLoyaltyCardTierMapComponent } from './components/create-company-setting/create-loyalty-card-tier-map/create-loyalty-card-tier-map.component';
import { CreateLoyaltyPointSettingsComponent } from './components/create-company-setting/create-loyalty-point-settings/create-loyalty-point-settings.component';
import { CreateMerchantAccountInfoSettingComponent } from './components/create-company-setting/create-merchant-account-info-setting/create-merchant-account-info-setting.component';
import { CreateMerchantDuePaymentSettingComponent } from './components/create-company-setting/create-merchant-due-payment-setting/create-merchant-due-payment-setting.component';
import { CreatePromotionalGiftSettingComponent } from './components/create-company-setting/create-promotional-gift-setting/create-promotional-gift-setting.component';
import { CreateTrendingMerchantsSettingComponent } from './components/create-company-setting/create-trending-merchants-setting/create-trending-merchants-setting.component';
import { CreateGiftTemplateComponent } from './components/create-gift-template/create-gift-template.component';
import { CreateInvoiceComponent } from './components/create-invoice/create-invoice.component';
import { CreateLoyaltyMerchantPointComponent } from './components/create-loyalty-merchant-point/create-loyalty-merchant-point.component';
import { CreateLoyaltyMerchantComponent } from './components/create-loyalty-merchant/create-loyalty-merchant.component';
import { CreateMerchantComponent } from './components/create-merchant/create-merchant.component';
import { CreateOfferAssignmentComponent } from './components/create-offer-assignment/create-offer-assignment.component';
import { CreateOfferProviderComponent } from './components/create-offer-provider/create-offer-provider.component';
import { CreateOfferComponent } from './components/create-offer/create-offer.component';
import { CreateOutletComponent } from './components/create-outlet/create-outlet.component';
import { CreatePremiumUserReferenceComponent } from './components/create-premium-user-reference/create-premium-user-reference.component';
import { CreatePremiumUserComponent } from './components/create-premium-user/create-premium-user.component';
import { CreatePromotionComponent } from './components/create-promotion/create-promotion.component';
import { CreateRolePermissionsComponent } from './components/create-role-permissions/create-role-permissions.component';
import { CreateRoleComponent } from './components/create-role/create-role.component';
import { CreateTrendingMerchantComponent } from './components/create-trending-merchant/create-trending-merchant.component';
import { CreateUserCreditComponent } from './components/create-user-credit/create-user-credit.component';
import { CreateUserComponent } from './components/create-user/create-user.component';
import { CreateGolpProductComponent } from './components/create-golp-product/create-golp-product.component';
import { CustomerSupportBaseComponent } from './components/customer-support-base/customer-support-base.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DataHistoryComponent } from './components/data-history/data-history.component';
import { DiscountHoldersComponent } from './components/discount-holders/discount-holders.component';
import { DuePaymentReportBaseComponent } from './components/due-payment-report-base/due-payment-report-base.component';
import { ExtendGiftExpireDateComponent } from './components/extend-gift-expire-date/extend-gift-expire-date.component';
import { FooterComponent } from './components/footer/footer.component';
import { GiftCustomerSupportComponent } from './components/gift-customer-support/gift-customer-support.component';
import { GiftGenerationReportComponent } from './components/gift-generation-report/gift-generation-report.component';
import { GiftSentProcessComponent } from './components/gift-sent-process/gift-sent-process.component';
import { GiftTemplateAssignmentsComponent } from './components/gift-template-assignments/gift-template-assignments.component';
import { GiftTemplateBaseComponent } from './components/gift-template-base/gift-template-base.component';
import { GiftTemplateDetailsComponent } from './components/gift-template-details/gift-template-details.component';
import { GiftTemplatesComponent } from './components/gift-templates/gift-templates.component';
import { GiftTransactionReportsComponent } from './components/gift-transaction-reports/gift-transaction-reports.component';
import { HeaderNotificationComponent } from './components/header-notification/header-notification.component';
import { HeaderComponent } from './components/header/header.component';
import { InactiveConsumerGiftComponent } from './components/inactive-consumer-gift/inactive-consumer-gift.component';
import { InactiveCorporateGiftComponent } from './components/inactive-corporate-gift/inactive-corporate-gift.component';
import { IndividualGiftReportComponent } from './components/individual-gift-report/individual-gift-report.component';
import { LockUnlockComponent } from './components/lock-unlock/lock-unlock.component';
import { LoginComponent } from './components/login/login.component';
import { LoginwithphoneComponent } from './components/loginwithphone/loginwithphone.component';
import { LoyaltyMerchantPointsComponent } from './components/loyalty-merchant-points/loyalty-merchant-points.component';
import { LoyaltyMerchantsDetailsComponent } from './components/loyalty-merchants-details/loyalty-merchants-details.component';
import { LoyaltyMerchantsComponent } from './components/loyalty-merchants/loyalty-merchants.component';
import { MerchantDetailsComponent } from './components/merchant-details/merchant-details.component';
import { MerchantDueReportComponent } from './components/merchant-due-report/merchant-due-report.component';
import { MerchantsComponent } from './components/merchants/merchants.component';
import { NotificationItemsComponent } from './components/notification-items/notification-items.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { OfferAssignmentsComponent } from './components/offer-assignments/offer-assignments.component';
import { OfferDetailsComponent } from './components/offer-details/offer-details.component';
import { OfferProviderDetailsComponent } from './components/offer-provider-details/offer-provider-details.component';
import { OfferProvidersComponent } from './components/offer-providers/offer-providers.component';
import { OfferRejectionComponent } from './components/offer-rejection/offer-rejection.component';
import { OffersComponent } from './components/offers/offers.component';
import { OutletsComponent } from './components/outlets/outlets.component';
import { PaymentDueReportComponent } from './components/payment-due-report/payment-due-report.component';
import { PaymentMethodComponent } from './components/payment-method/payment-method.component';
import { PaymentOptionsComponent } from './components/payment-options/payment-options.component';
import { PaymentReportComponent } from './components/payment-report/payment-report.component';
import { PaymentSessionComponent } from './components/payment-session/payment-session.component';
import { PaymentComponent } from './components/payments/payment.component';
import { PendingAvailRequestComponent } from './components/pending-avail-request/pending-avail-request.component';
import { PremiumUserDetailsComponent } from './components/premium-user-details/premium-user-details.component';
import { PremiumUsersCreditLimitExceededReportComponent } from './components/premium-users-credit-limit-exceeded-report/premium-users-credit-limit-exceeded-report.component';
import { PremiumUsersCreditUsageReportComponent } from './components/premium-users-credit-usage-report/premium-users-credit-usage-report.component';
import { PremiumUsersReportComponent } from './components/premium-users-report/premium-users-report.component';
import { PremiumUsersComponent } from './components/premium-users/premium-users.component';
import { ProfileSettingsComponent } from './components/profile-settings/profile-settings.component';
import { PromotionDetailsComponent } from './components/promotion-details/promotion-details.component';
import { PromotionsComponent } from './components/promotions/promotions.component';
import { RejectionReasonComponent } from './components/rejection-reason/rejection-reason.component';
import { ReportsComponent } from './components/reports/reports.component';
import { ResetPasswordDialogComponent } from './components/reset-password-dialog/reset-password-dialog.component';
import { RolePermissionsComponent } from './components/role-permissions/role-permissions.component';
import { RolesComponent } from './components/roles/roles.component';
import { SelectMerchantComponent } from './components/select-merchant/select-merchant.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ShowAvailInstructionsComponent } from './components/show-avail-instructions/show-avail-instructions.component';
import { ShowMerchantPaymentInfoComponent } from './components/show-merchant-payment-info/show-merchant-payment-info.component';
import { ShowMoreOrLessComponent } from './components/show-more-or-less/show-more-or-less.component';
import { ShowOfferAssignmentDetailsComponent } from './components/show-offer-assignment-details/show-offer-assignment-details.component';
import { ShowOutletsComponent } from './components/show-outlets/show-outlets.component';
import { SplitOfferComponent } from './components/split-offer/split-offer.component';
import { TabHeaderComponent } from './components/tab-header/tab-header.component';
import { TrackOrdersComponent } from './components/track-orders/track-orders.component';
import { TrendingMerchantDetailsComponent } from './components/trending-merchant-details/trending-merchant-details.component';
import { TrendingMerchantsComponent } from './components/trending-merchants/trending-merchants.component';
import { UploadDiscountHolderComponent } from './components/upload-discount-holder/upload-discount-holder.component';
import { UserCreditsComponent } from './components/user-credits/user-credits.component';
import { UserReferencesComponent } from './components/user-references/user-references.component';
import { UserRegistrationDetailReportComponent } from './components/user-registration-detail-report/user-registration-detail-report.component';
import { UserRegistrationReportSummaryComponent } from './components/user-registration-report-summary/user-registration-report-summary.component';
import { UserRegistrationReportComponent } from './components/user-registration-report/user-registration-report.component';
import { UsersComponent } from './components/users/users.component';
import { VerifyCouponComponent } from './components/verify-coupon/verify-coupon.component';
import { GolpAdminComponent } from './components/golp-admin/golp-admin.component';
import { GolpProductDetailComponent } from './components/golp-product-detail/golp-product-detail.component';
import { GolpProductsComponent } from './components/golp-products/golp-products.component';
import { AlertComponent } from './directives/alert/alert.component';
import { CdkDetailRowDirective } from './directives/cdk-detail-row.directive';
import { PaymentHeaderComponent } from './_layout/payment-header/payment-header.component';
import { PaymentLayoutComponent } from './_layout/payment-layout/payment-layout.component';
import { WebAppLayoutComponent } from './_layout/web-app-layout/web-app-layout.component';
import { MaterialModule } from './material';
import { QRCodeModule } from 'angularx-qrcode';
import { BlockUIModule } from 'ng-block-ui';
import { NgOtpInputModule } from 'ng-otp-input';
// import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxPaginationModule } from 'ngx-pagination';
import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { GiftRedemptionComponent } from './components/avail-process/gift-redemption/gift-redemption.component';
import { OfferRedemptionComponent } from './components/avail-process/offer-redemption/offer-redemption.component';
import { MyOffersComponent } from './corporate-offers/components/my-offers/my-offers.component';
import { MyOfferDetailsComponent } from './corporate-offers/components/my-offers/my-offer-details/my-offer-details.component';
import { CorporateOffersComponent } from './corporate-offers/components/corporate-offers/corporate-offers.component';
import { CorporateOfferDetailsComponent } from './corporate-offers/components/corporate-offer-details/corporate-offer-details.component';
import { CreateCorporateOfferComponent } from './corporate-offers/components/create-corporate-offer/create-corporate-offer.component';
import { CorporateOfferConditionsComponent } from './corporate-offers/components/corporate-offer-conditions/corporate-offer-conditions.component';
import { CreateCorporateOfferConditionComponent } from './corporate-offers/components/create-corporate-offer-condition/create-corporate-offer-condition.component';
import { CorporateOfferAssignmentComponent } from './corporate-offers/corporate-offer-assignment/corporate-offer-assignment.component';
import { AddAssignmentComponent } from './corporate-offers/corporate-offer-assignment/add-assignment/add-assignment.component';
import { CreateOfferInformationalConditionComponent } from './corporate-offers/components/create-offer-informational-condition/create-offer-informational-condition.component';
import { CreateOfferOperationalConditionComponent } from './corporate-offers/components/create-offer-operational-condition/create-offer-operational-condition.component';
import { DateRangeOperationConditionComponent } from './corporate-offers/components/operational-conditions/date-range-operation-condition.component';
import { TimeRangeOperationConditionComponent } from './corporate-offers/components/operational-conditions/time-range-operation-condition.component';
import { MerchantLogoRendererComponent } from './components/merchant-logo-renderer/merchant-logo-renderer.component';
import { CopyOfferComponent } from './corporate-offers/components/copy-offer/copy-offer.component';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GiftsSalesChartComponent } from './components/dashboard/gifts-sales-chart/gifts-sales-chart.component';
import { GiftRedeemedChartComponent } from './components/dashboard/gift-redeemed-chart/gift-redeemed-chart.component';
import { GiftConsumptionChartComponent } from './components/dashboard/gift-consumption-chart/gift-consumption-chart.component';
import { CreateSingleOfferComponent } from './corporate-offers/components/create-corporate-offer/create-single-offer/create-single-offer.component';
import { CreateBulkOfferComponent } from './corporate-offers/components/create-corporate-offer/create-bulk-offer/create-bulk-offer.component';
import { OfferReportsComponent } from './corporate-offers/components/offer-reports/offer-reports.component';
import { OfferSummaryReportComponent } from './corporate-offers/components/offer-reports/offer-summary-report/offer-summary-report.component';
import { SearchCustomerOffersComponent } from './corporate-offers/components/offer-reports/search-customer-offers/search-customer-offers.component';
import { GiftSendingProgressComponent } from './components/app-send-gift/components/shared/gift-sending-progress';
import { DataService } from './components/app-send-gift/services/shared/data.service';
import { GiftInformationComponent } from './components/app-send-gift/components/gift-information';
import { GiftConfirmationComponent } from './components/app-send-gift/components/gift-confirmation';
import { GiftSentHistoryComponent } from './components/app-send-gift/components/gift-sent-history/gift-sent-history.component';
import { GiftHistoryItemComponent } from './components/app-send-gift/components/shared/gift-history-item/gift-history-item.component';
import { GiftDetailsComponent } from './components/app-send-gift/components/shared/gift-details/gift-details.component';
import { GiftDeliveredComponent } from './components/app-send-gift/components/shared/gift-delivered/gift-delivered.component';
import { GiftDashboardComponent } from './components/app-send-gift/components/gift-dashboard/gift-dashboard.component';
import { SendGiftPopularComponent } from './components/app-send-gift/components/shared/send-gift-popular/send-gift-popular.component';
import { PopularGiftComponent } from './components/app-send-gift/components/shared/popular-gift/popular-gift.component';
import { SendGiftOccasionalComponent } from './components/app-send-gift/components/shared/send-gift-occasional/send-gift-occasional.component';
import { MerchantProductsComponent } from './components/merchant-products/merchant-products.component';
import { ProductDetailsComponent } from './components/merchant-products/product-details/product-details.component';
import { CreateProductComponent } from './components/merchant-products/create-product/create-product.component';
import { SendGiftReportComponent } from './components/app-send-gift/components/send-gift-report/send-gift-report.component';
import { GiftTransactionReportComponent } from './components/app-send-gift/components/shared/gift-transaction-report/gift-transaction-report.component';
import { SpinnerOverlayComponent } from './components/shared/spinner-overlay/spinner-overlay.component';
import { SpinnerOverlayService } from './services/spinner-overlay.service';
import { SpinnerInterceptor } from './services/spinner.interceptor';
import { DatePipe } from '@angular/common';
import { ManageOrdersComponent } from './reseller-partners/components/manage-orders/manage-orders.component';
import { OrderItemsComponent } from './reseller-partners/components/order-items/order-items.component';
import { BlockGiftCardComponent } from './components/block-gift-card/block-gift-card.component';
import { GiftSellReportComponent } from './components/app-send-gift/components/gift-sell-report/gift-sell-report.component';
import { GiftSellReportSummaryComponent } from './components/app-send-gift/components/gift-sell-report-summary/gift-sell-report-summary.component';
import { GiftSummaryReportComponent } from './components/app-send-gift/components/gift-summary-report/gift-summary-report.component';
import { GiftVsSalesReportComponent } from './components/app-send-gift/components/gift-vs-sales-report/gift-vs-sales-report.component';
import { MerchantService } from './services/merchant.service';
import { GiftVsRedemptionReportComponent } from './components/app-send-gift/components/gift-vs-redemption-report/gift-vs-redemption-report.component';
import { GiftReportComponent } from './components/app-send-gift/components/gift-report/gift-report.component';
import { GiftExpiryReportComponent } from './components/app-send-gift/components/gift-expiry-report/gift-expiry-report.component';
import { UploadBulkAmountComponent } from './components/app-send-gift/components/upload-bulk-amount/upload-bulk-amount.component';
import { UploadAmountReportComponent } from './components/app-send-gift/components/upload-amount-report/upload-amount-report.component';
import { UploadAmountResultComponent } from './components/app-send-gift/components/upload-amount-result/upload-amount-result.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { UploadedXlsPreviewComponent } from './components/app-send-gift/components/uploaded-xls-preview/uploaded-xls-preview.component';
import { SmsStatusComponent } from './components/app-send-gift/components/sms-status/sms-status.component';
import { StockStatusComponent } from './components/stock-status/stock-status.component';
import { AvailInfoComponent } from './components/avail-process/avail-info/avail-info.component';
import { BusinessBulkGiftComponent } from './components/business-bulk-gift/business-bulk-gift.component';
import { RedemptionOtpComponent } from './components/redemption-otp/redemption-otp.component';
import { CustomerSupportPageComponent } from './components/customer-support-page/customer-support-page.component';
import { NgSelect2Module } from 'ng-select2';
import { InfoModalComponent } from './components/info-modal/info-modal.component';
import { ChangeMerchantComponent } from './components/change-merchant/change-merchant.component';
import { GiftWithOfferComponent } from './components/gift-with-offer/gift-with-offer.component';
import { GiftWithOfferByCountComponent } from './components/gift-with-offer-by-count/gift-with-offer-by-count.component';
import { OfferModalComponent } from './components/offer-modal/offer-modal.component';
import { SendOfferPreviewComponent } from './components/send-offer-preview/send-offer-preview.component';
import { UserOfferReportComponent } from './components/user-offer-report/user-offer-report.component';
import { JsonViewerComponent } from './components/json-viewer/json-viewer.component';
import { JsonDescriptionViewerComponent } from './components/json-description-viewer/json-description-viewer.component';
import { JsonTableViewerComponent } from './components/json-table-viewer/json-table-viewer.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { RedeemedTransactionComponent } from './components/avail-process/redeemed-transaction/redeemed-transaction.component';
import { ManageGiftOrderComponent } from './components/customer-support-page/manage-gift-order/manage-gift-order.component';
import { DeliverGiftOrderComponent } from './components/customer-support-page/deliver-gift-order/deliver-gift-order.component';
import { ActivateCardComponent } from './privilege-card/components/privilege-card-assignment/activate-card/activate-card.component';
import { AddCardIssuerComponent } from './privilege-card/components/privilege-card-issuer/add-card-issuer/add-card-issuer.component';
import { OfferMerchantComponent } from './components/offer-providers/offer-merchant/offer-merchant.component';
import { AddOfferMerchantComponent } from './components/offer-providers/add-offer-merchant/add-offer-merchant.component';
import { ManageGiftCardBusinessComponent } from './components/business-solution/manage-gift-card-business/manage-gift-card-business.component';
import { AdvanceBalanceComponent } from './components/avail-process/advance-balance/advance-balance.component';
import { WalletTransactionComponent } from './components/wallet-transaction/wallet-transaction.component';
import { OfferTransactionReportComponent } from './corporate-offers/components/offer-reports/offer-transaction-report/offer-transaction-report.component';
import { GiftCardTransactionComponent } from './components/app-send-gift/components/gift-card-transaction/gift-card-transaction.component';
import { SellTransactionsComponent } from './components/app-send-gift/components/gift-card-transaction/sell-transactions/sell-transactions.component';
import { SellAvailedTransactionsComponent } from './components/app-send-gift/components/gift-card-transaction/sell-availed-transactions/sell-availed-transactions.component';
import { environment } from 'src/environments/environment';
import { BaseUrlInterceptor } from './services/baseurl.interceptor';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { BulkCodeGenerateComponent } from './components/bulk-code-generate/bulk-code-generate.component';
import { RevenueDashboardComponent } from './revenue-dashboard/components/dashboard/revenue-dashboard.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    FilterPipeModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    QRCodeModule,
    BlockUIModule.forRoot(),
    NgxMaterialTimepickerModule,
    NgOtpInputModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    NgCircleProgressModule.forRoot(),
    NgSelect2Module,
    PrivilegeCardModule,
    BillingModule,
    ModalModule,
    ],
  declarations: [
    RevenueDashboardComponent,
    AppComponent,
    MerchantsComponent,
    CreateMerchantComponent,
    OfferProvidersComponent,
    BillingsComponent,
    CreateInvoiceComponent,
    AddTransactionComponent,
    PaymentSessionComponent,
    PaymentComponent,
    CreateOfferProviderComponent,
    OffersComponent,
    CreateOfferComponent,
    OutletsComponent,
    CreateOutletComponent,
    LoginComponent,
    CreateOfferAssignmentComponent,
    UploadDiscountHolderComponent,
    HeaderComponent,
    MerchantDetailsComponent,
    UsersComponent,
    OfferDetailsComponent,
    OfferAssignmentsComponent,
    BulkCodeGenerateComponent,
    OfferProviderDetailsComponent,
    DiscountHoldersComponent,
    AdvanceSearchComponent,
    HeaderNotificationComponent,
    DashboardComponent,
    CdkDetailRowDirective,
    NotificationItemsComponent,
    NotificationsComponent,
    FooterComponent,
    CreateUserComponent,
    AlertComponent,
    OfferRejectionComponent,
    RejectionReasonComponent,
    CompanyQrCodeComponent,
    SettingsComponent,
    ProfileSettingsComponent,
    AccountSettingsComponent,
    ConfirmationDialogComponent,
    TabHeaderComponent,
    BundleOffersComponent,
    AppPaymentOptionComponent,
    WebAppLayoutComponent,
    ConsumerPaymentStatusComponent,
    PaymentHeaderComponent,
    PaymentLayoutComponent,
    CompanySettingsComponent,
    CreateCompanySettingComponent,
    CreateApiEndpointConfigurationComponent,
    RolesComponent,
    RolePermissionsComponent,
    CreateRoleComponent,
    CreateRolePermissionsComponent,
    GiftGenerationReportComponent,
    PendingAvailRequestComponent,
    AcceptAvailRequestComponent,
    CompletedTransactionComponent,
    AvailProcessComponent,
    DataHistoryComponent,
    BaseComponent,
    DataHistoryComponent,
    PaymentDueReportComponent,
    PaymentReportComponent,
    UserRegistrationReportComponent,
    AvailedConsumerListComponent,
    AlertDialogComponent,
    ResetPasswordDialogComponent,
    UserRegistrationReportSummaryComponent,
    UserRegistrationDetailReportComponent,
    ShowMoreOrLessComponent,
    CreateGiftAmountSlabsSettingComponent,
    CreateGiftAmountSettingComponent,
    GolpAdminComponent,
    CreatePromotionalGiftSettingComponent,
    CreateTrendingMerchantsSettingComponent,
    ReportsComponent,
    GiftTransactionReportsComponent,
    CorporateGiftReportsComponent,
    ConsumerGiftReportsComponent,
    ActiveCorporateGiftComponent,
    InactiveCorporateGiftComponent,
    InactiveConsumerGiftComponent,
    ActiveConsumerGiftComponent,
    ShowOfferAssignmentDetailsComponent,
    CreateMerchantDuePaymentSettingComponent,
    CreateMerchantAccountInfoSettingComponent,
    PremiumUsersComponent,
    CreatePremiumUserComponent,
    PremiumUserDetailsComponent,
    ShowMerchantPaymentInfoComponent,
    LoginwithphoneComponent,
    ConsumergiftComponent,
    ConsumergiftDetailsComponent,
    ConsumerSendGiftComponent,
    ConsumerSendGiftDetailsComponent,
    GiftSentProcessComponent,
    UserCreditsComponent,
    CreateUserCreditComponent,
    CompanyCreditDetailsComponent,
    CreateCompanyCreditComponent,
    CompanyReferencesComponent,
    CreateCompanyReferenceComponent,
    PaymentOptionsComponent,
    UserReferencesComponent,
    CreatePremiumUserReferenceComponent,
    ShowMerchantPaymentInfoComponent,
    GolpProductsComponent,
    CreateGolpProductComponent,
    GolpProductDetailComponent,
    CreateGiftUsageLimitSettingComponent,
    SplitOfferComponent,
    CreateExcludeMerchantsSettingComponent,
    PaymentMethodComponent,
    CreateGiftResendPolicyComponent,
    PremiumUsersReportComponent,
    PremiumUsersCreditUsageReportComponent,
    PremiumUsersCreditLimitExceededReportComponent,
    TrendingMerchantsComponent,
    CreateTrendingMerchantComponent,
    DuePaymentReportBaseComponent,
    MerchantDueReportComponent,
    TrendingMerchantDetailsComponent,
    CustomerSupportBaseComponent,
    GiftCustomerSupportComponent,
    ChangeGiftAssignmentComponent,
    PromotionsComponent,
    PromotionDetailsComponent,
    CreatePromotionComponent,
    GiftTemplatesComponent,
    CreateGiftTemplateComponent,
    GiftTemplateDetailsComponent,
    GiftTemplateBaseComponent,
    AssignGiftTemplateComponent,
    GiftTemplateAssignmentsComponent,
    LoyaltyMerchantsComponent,
    LoyaltyMerchantsDetailsComponent,
    CreateLoyaltyMerchantComponent,
    CreateLoyaltyCardTierMapComponent,
    CreateLoyaltyPointSettingsComponent,
    LoyaltyMerchantPointsComponent,
    CreateLoyaltyMerchantPointComponent,
    ActivePromotionalGiftComponent,
    ConfirmationWithTextComponent,
    AcceptOtpComponent,
    ExtendGiftExpireDateComponent,
    IndividualGiftReportComponent,
    VerifyCouponComponent,
    LockUnlockComponent,
    AddAvailInstructionComponent,
    TrackOrdersComponent,
    ShowOutletsComponent,
    ShowAvailInstructionsComponent,
    TrackOrdersComponent,
    ViewGiftCodeComponent,
    RedemptionComponent,
    SelectMerchantComponent,
    GiftRedemptionComponent,
    OfferRedemptionComponent,
    MyOffersComponent,
    MyOfferDetailsComponent,
    CorporateOffersComponent,
    CorporateOfferDetailsComponent,
    CreateCorporateOfferComponent,
    CorporateOfferConditionsComponent,
    CreateCorporateOfferConditionComponent,
    CorporateOfferAssignmentComponent,
    AddAssignmentComponent,
    CreateOfferInformationalConditionComponent,
    CreateOfferOperationalConditionComponent,
    DateRangeOperationConditionComponent,
    TimeRangeOperationConditionComponent,
    MerchantLogoRendererComponent,
    CopyOfferComponent,
    GiftsSalesChartComponent,
    GiftRedeemedChartComponent,
    GiftConsumptionChartComponent,
    CreateSingleOfferComponent,
    CreateBulkOfferComponent,
    OfferReportsComponent,
    OfferSummaryReportComponent,
    SearchCustomerOffersComponent,
    GiftInformationComponent,
    GiftConfirmationComponent,
    GiftSendingProgressComponent,
    GiftSentHistoryComponent,
    GiftHistoryItemComponent,
    GiftDetailsComponent,
    GiftDeliveredComponent,
    GiftDashboardComponent,
    SendGiftPopularComponent,
    PopularGiftComponent,
    SendGiftOccasionalComponent,
    MerchantProductsComponent,
    CreateProductComponent,
    ProductDetailsComponent,
    SendGiftReportComponent,
    GiftTransactionReportComponent,
    SpinnerOverlayComponent,
    ManageOrdersComponent,
    OrderItemsComponent,
    BlockGiftCardComponent,
    GiftSellReportComponent,
    GiftSellReportSummaryComponent,
    GiftSummaryReportComponent,
    GiftVsSalesReportComponent,
    GiftVsRedemptionReportComponent,
    GiftReportComponent,
    GiftExpiryReportComponent,
    UploadBulkAmountComponent,
    UploadAmountReportComponent,
    UploadAmountResultComponent,
    UploadedXlsPreviewComponent,
    SmsStatusComponent,
    StockStatusComponent,
    AvailInfoComponent,
    BusinessBulkGiftComponent,
    RedemptionOtpComponent,
    CustomerSupportPageComponent,
    InfoModalComponent,
    ChangeMerchantComponent,
    GiftWithOfferComponent,
    GiftWithOfferByCountComponent,
    OfferModalComponent,
    SendOfferPreviewComponent,
    UserOfferReportComponent,
    JsonViewerComponent,
    JsonDescriptionViewerComponent,
    JsonTableViewerComponent,
    RedeemedTransactionComponent,
    ManageGiftOrderComponent,
    DeliverGiftOrderComponent,
    OfferMerchantComponent,
    AddOfferMerchantComponent,
    ManageGiftCardBusinessComponent,
    AdvanceBalanceComponent,
    WalletTransactionComponent,
    OfferTransactionReportComponent,
    GiftCardTransactionComponent,
    SellTransactionsComponent,
    SellAvailedTransactionsComponent
  ],
  entryComponents: [
    CreateMerchantComponent,
    CreateOfferProviderComponent,
    CreateOfferComponent,
    CreateOutletComponent,
    CreateOfferAssignmentComponent,
    CreateInvoiceComponent,
    AddTransactionComponent,
    PaymentSessionComponent,
    CreateCorporateOfferComponent,
    UploadDiscountHolderComponent,
    CreateUserComponent,
    OfferRejectionComponent,
    RejectionReasonComponent,
    CompanyQrCodeComponent,
    ConfirmationDialogComponent,
    CreateCompanySettingComponent,
    CreateApiEndpointConfigurationComponent,
    AcceptAvailRequestComponent,
    CreateRoleComponent,
    CreateRolePermissionsComponent,
    AvailedConsumerListComponent,
    AlertDialogComponent,
    ResetPasswordDialogComponent,
    ShowOfferAssignmentDetailsComponent,
    CreatePremiumUserComponent,
    ShowMerchantPaymentInfoComponent,
    GiftSentProcessComponent,
    CreateUserCreditComponent,
    CreateCompanyCreditComponent,
    CreateCompanyReferenceComponent,
    PaymentOptionsComponent,
    CreatePremiumUserReferenceComponent,
    ShowMerchantPaymentInfoComponent,
    CreateGolpProductComponent,
    SplitOfferComponent,
    CreateTrendingMerchantComponent,
    ChangeGiftAssignmentComponent,
    CreatePromotionComponent,
    CreateGiftTemplateComponent,
    AssignGiftTemplateComponent,
    CreateLoyaltyMerchantComponent,
    CreateLoyaltyMerchantPointComponent,
    ConfirmationWithTextComponent,
    ExtendGiftExpireDateComponent,
    AcceptOtpComponent,
    ShowOutletsComponent,
    ShowAvailInstructionsComponent,
    ViewGiftCodeComponent,
    SelectMerchantComponent,
    AddAssignmentComponent,
    CreateCorporateOfferConditionComponent,
    CreateOfferOperationalConditionComponent,
    CopyOfferComponent,
    CreateProductComponent,
    SpinnerOverlayComponent,
    ManageOrdersComponent,
    OrderItemsComponent,
    BlockGiftCardComponent,
    UploadBulkAmountComponent,
    AvailInfoComponent,
    RedemptionOtpComponent,
    ChangeMerchantComponent,
    InfoModalComponent,
    DeliverGiftOrderComponent,
    ActivateCardComponent,
    AddCardIssuerComponent,
    AddOfferMerchantComponent
  ],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    DataService,
    MerchantService,
    DatePipe,
    SpinnerOverlayService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },
    { provide: "BASE_API_URL", useValue: environment.apiUrl },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true
    },BsModalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
