import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-time-range-operation-condition',
  template: `
                  <form class="offer-from" [formGroup]="timeRangeConditionForm">
                      <div class="form-group">
                        <input class="form-control" formControlName="startTime" placeholder="Start Time"
                          [ngxTimepicker]="toggleStartTime" [disableClick]="false" readonly>
                        <ngx-material-timepicker #toggleStartTime></ngx-material-timepicker>
                        <div *ngIf="submitted && ft.startTime.errors">
                          <mat-error *ngIf="ft.startTime.errors.required">Start time is required</mat-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <input class="form-control" formControlName="endTime" placeholder="End Time"
                          [ngxTimepicker]="toggleEndTime" [disableClick]="false" readonly>
                        <ngx-material-timepicker #toggleEndTime></ngx-material-timepicker>
                        <div *ngIf="submitted && ft.endTime.errors">
                          <mat-error *ngIf="ft.endTime.errors.required">End time is required</mat-error>
                        </div>
                      </div>
                  </form>
                `,
  styleUrls: ['./date-range-operation-condition.component.css']
})
export class TimeRangeOperationConditionComponent implements OnInit {

  // operationalInformationConditionEmitter: EventEmitter<any> = new EventEmitter();
  @Input() timeRangeConditionForm: FormGroup;
  @Input() submitted: boolean;

  constructor() { }

  ngOnInit(): void {
  }


  // convenience getter for easy access to form fields
  get ft() { return this.timeRangeConditionForm.controls; }

}
