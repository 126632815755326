import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-rejection-reason',
  templateUrl: './rejection-reason.component.html',
  styleUrls: ['./rejection-reason.component.css']
})
export class RejectionReasonComponent implements OnInit {

  rejectionReason:string;

  constructor(private dialogRef: MatDialogRef<RejectionReasonComponent>,
    @Inject(MAT_DIALOG_DATA) data) { 

      if(data !== null){
        this.rejectionReason = data.rejectionReason;
      }
    }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close();
  }

}
