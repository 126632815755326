import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DataService } from '../../services/shared/data.service';
import { saveAs } from 'file-saver';
import * as fs from 'file-saver';
import { CommonService } from 'src/app/services';

@Component({
  selector: 'app-upload-amount-result',
  templateUrl: './upload-amount-result.component.html',
  styleUrls: ['./upload-amount-result.component.css']
})
export class UploadAmountResultComponent implements OnInit {

  validationStatusMessage: string;
  validationStatus: boolean;
  xlDownloadUrl: string;
  merchantId: number;
  bulkResponse: any;
  processFinished: number = 0;
  totalProcess: number = 0;
  showProgressbar: boolean = false;
  onceLoaded: boolean = false;
  percent: number = 0;
  enableDetailsLink: boolean = false;
  fileToBeUploaded: any;
  constructor(private router: Router, private dataService: DataService, private commonService: CommonService) {
    this.commonService.backgroundJobModel.subscribe(res => {
      console.log("Result : ", res);
      if (res.jobType == "GiftProcess" && this.bulkResponse.data.processId == res.model.processId) {
        this.showProgressbar = true;
        this.processFinished = Number(res.model.processFinished);
        this.totalProcess = Number(res.model.totalProcess);
        this.percent = (this.processFinished / this.totalProcess) * 100;

        if (this.percent == 100) {
          this.enableDetailsLink = true;
        }
      }
    }, err => { })
    this.setUserInfo();
    let navigation = this.router.getCurrentNavigation();
    let state = navigation.extras.state as {
      transId: string,
      workQueue: boolean,
      services: number,
      code: any
    };

    if (state != undefined && state.code != undefined) {
      this.fileToBeUploaded = new FormData();
      this.fileToBeUploaded.append("merchantId", state.code.merchantId);
      this.fileToBeUploaded.append("merchantOutletId", state.code.merchantOutletId);
      this.fileToBeUploaded.append("bulkGiftCardFile", state.code.file);

      if (this.fileToBeUploaded != undefined && this.fileToBeUploaded != null) {
        this.uploadFile();
      }
    }

  }


  uploadFile() {
    this.dataService.post("api/giftcard/send-bulk-gift", this.fileToBeUploaded).subscribe((res: any) => {
      this.bulkResponse = res;
      this.validationStatus = res.data.isValid;

      if (this.validationStatus) {
        // this.validationStatusMessage = "Validation Failed";
      }
      else {
        this.xlDownloadUrl = res.data.errorFileDownloadLink;
      }
    }, err => {

    });
  }

  setUserInfo() {
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.merchantId = userInfo.companyId;
  }
  ngOnInit(): void {
  }

  downloadXl() {
    // window.location.href = 
    window.open(`/api/${this.xlDownloadUrl}`, '_blank');
    // this.dataService.getForBlob(`api/${this.xlDownloadUrl}`).subscribe(res=>{
    //   this.downloadFile(res);
    // }, err=>{
    // });
  }

  downloadFile(data) {
    const blob2 = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var filename = "Gift-Card-Sell-Report-" + moment().format('MMMM Do YYYY, h:mm:ss').valueOf() + ".xlsx";
    saveAs(blob2, filename);
  }

}
