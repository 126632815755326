import { Component, OnInit, EventEmitter, Injector, Input, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { offerconsumedstatus } from '../../enums/offer-consumed-status';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { SplitOfferComponent } from '../split-offer/split-offer.component';
import { ViewGiftCodeComponent } from './view-gift-code/view-gift-code.component';
import { OfferAssignmentService, CompanyService } from 'src/app/services';
import { ShowOutletsComponent } from '../show-outlets/show-outlets.component';
import { ShowAvailInstructionsComponent } from '../show-avail-instructions/show-avail-instructions.component';
import { SelectMerchantComponent } from '../select-merchant/select-merchant.component';

@Component({
  selector: 'app-consumergift-details',
  templateUrl: './consumergift-details.component.html',
  styleUrls: ['./consumergift-details.component.css']
})
export class ConsumergiftDetailsComponent extends BaseComponent implements OnInit {
  @Input() offerDetailsInput: any;
  @Output() offerDetailsInputChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onOfferSplitEvent: EventEmitter<any> = new EventEmitter();

  offerDetails: any;
  offerAssignmentSummary: any;
  userInfo: any;
  detailsLoaded: Promise<boolean>;
  availInstructions: any[] = [];

  constructor(public dialog: MatDialog,
    private offerAssignmentService: OfferAssignmentService,
    private companyService: CompanyService, injector: Injector
  ) { super(injector) }

  ngOnInit() {
    this.getLoggedInUser();
    if (this.offerDetails != null) {
      this.detailsLoaded = Promise.resolve(true);
    }
  }

  getLoggedInUser() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userInfo = userInfo;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['offerDetailsInput']) {
      setTimeout(() => {
        this.offerDetails = this.offerDetailsInput;

        if (this.offerDetails != null && this.offerDetails.merchant != null) {
          this.getAvailInstructionsByCompany(this.offerDetails.merchantId);
        }
        //this.showHideAcceptAction();
        this.makeSomeDelay(this.offerDetails);
      });
    }
  }

  makeSomeDelay(offer: any) {
    if (offer != null) {
      let readSomeValue = offer.offerName;
      this.detailsLoaded = Promise.resolve(true);
    }
  }
  // splitOffer(offer: any) {
  //   const splitDialogConfig = new MatDialogConfig();
  //   splitDialogConfig.disableClose = true;
  //   splitDialogConfig.autoFocus = true;
  //   splitDialogConfig.panelClass = 'golp-dialog';

  //   splitDialogConfig.data = offer

  //   const offerSplitDialog = this.dialog.open(SplitOfferComponent, splitDialogConfig);

  //   const successFullEdit = offerSplitDialog.componentInstance.onOfferSplitEvent.subscribe((data) => {
  //     this.onOfferSplitEvent.emit(true);
  //   });

  //   offerSplitDialog.afterClosed().subscribe(() => {
  //     Promise.resolve(true);
  //   });
  // }

  viewGiftCode(offer: any) {
    this.offerAssignmentService.getGiftCode(offer.id)
      .subscribe((result) => {
        this.showViewCodeDialog(result);
      },
        (error) => {
          console.log(error);
        });
  }

  showViewCodeDialog(codeDetails: any) {
    const viewCodeDialogConfig = new MatDialogConfig();
    // Setting different dialog configurations
    viewCodeDialogConfig.data = { 'status': codeDetails.success, 'data': codeDetails.data };
    viewCodeDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ViewGiftCodeComponent, viewCodeDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
    });
  }


  showOutlets(merchantId, merchantName) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'golp-dialog';

    var merchant = { merchantId, merchantName };
    dialogConfig.data = merchant;

    const dialog = this.dialog.open(ShowOutletsComponent, dialogConfig);

  }

  showAvailInstructions(merchantId, merchantName) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'golp-dialog';
    var merchant = { merchantId, merchantName };

    dialogConfig.data = merchant;

    const dialog = this.dialog.open(ShowAvailInstructionsComponent, dialogConfig);

  }

  selectMerchant(giftId) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'golp-dialog';
    var gift = { id: this.offerDetails.id };

    dialogConfig.data = gift;

    const dialog = this.dialog.open(SelectMerchantComponent, dialogConfig);

    const successfullCreate = dialog.componentInstance.onSaveMerchantEvent.subscribe((data) => {
      this.getGiftDetails();
    });

    dialog.afterClosed().subscribe(() => {
    });
  }


  getAvailInstructionsByCompany(merchantId: any) {
    this.companyService.getMerchantAvailInstructionsByCompanyId(merchantId)
      .subscribe(data => {
        this.availInstructions = data.data.availInstructions;
      });
  }


  getGiftDetails() {
    this.offerAssignmentService.getOfferAssignmentByAssignmentId(this.offerDetails.id).subscribe(result => {
      this.offerDetails = result;
      this.offerDetailsInputChange.emit(this.offerDetails);
    });
  }
}
