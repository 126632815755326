export class PremiumUserCreditUsageReportOutput{
    companyId?: any;
    consumerId: string;
    golpCreditType: number;
    giftSender: string;
    noOfGift: number;
    creditLimit: number;
    usedCredit: number;
    remainingCredits: number;

    public constructor(init?: Partial<PremiumUserCreditUsageReportOutput>) {
        Object.assign(this, init);
    }
}
