import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CompanyRole } from 'src/app/enums';
import { AccountServiceService } from 'src/app/services';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import { DataService } from '../app-send-gift/services/shared/data.service';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-stock-status',
  templateUrl: './stock-status.component.html',
  styleUrls: ['./stock-status.component.css']
})
export class StockStatusComponent extends BaseComponent implements OnInit {

  userInfo: any;
  isMerchantLoggedIn: boolean = false;

  providerSubMenu: any[] = [];
  selectedSubMenuIndex: number = 0;
  activeSubMenu: string = "Voucher";

  history: any;
  summary: any;

  currentPageHistory: number = 1;
  currentPageSizeHistory: number = 25;
  totalCountHistory: number;
  public defaultTime = [];

  currentPageSummary: number = 1;
  currentPageSizeSummary: number = 25;
  totalCountSummary: number;

  pageSizes: number[] = [25, 50, 100];
  offerReportForm: FormGroup;
  offerReportForm1: FormGroup;

  defaultStartDate: Date = new Date();
  defaultEndDate: Date = new Date();
  currentPage: number = 1;
  submitted: boolean;
  submitted1: boolean;

  defaultSyncDate: Date = new Date();

  headerStyle = "20% !important;";

  constructor(private accountService: AccountServiceService, private formBuilder: FormBuilder, private dataService: DataService,
    injector: Injector) {
    super(injector);
    var startDate1 = new Date();
    this.defaultStartDate = this.startOfMonth(startDate1);
    this.defaultEndDate = new Date();
    this.buildForm();
    this.buildForm1();
  }

  buildForm() {
    this.offerReportForm = this.formBuilder.group({
      fromDate: [this.defaultStartDate, Validators.required],
      toDate: [this.defaultEndDate, Validators.required],
      hasSold: ['', Validators.nullValidator],
      voucher: ['', Validators.nullValidator],
    });
  }

  buildForm1() {
    this.offerReportForm1 = this.formBuilder.group({
      fromDate: [this.defaultStartDate, Validators.required],
      toDate: [this.defaultEndDate, Validators.required],
    });
  }

  get f() { return this.offerReportForm.controls; }

  get f1() { return this.offerReportForm1.controls; }

  startOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  ngOnInit(): void {
    this.loadUserInfo();
    if (this.checkPermission(['ViewVoucher'])) {
      this.providerSubMenu.push({ "name": "Voucher", "dataTarget": "historyTab" });
    }
    if (this.checkPermission(['ViewVoucherSummary'])) {
      this.providerSubMenu.push({ "name": "Stock Summary", "dataTarget": "summaryTab" });
    }

    this.activeSubMenu = this.providerSubMenu.findIndex(c => c.name === "Voucher") == 0 ? "Voucher" : "Stock Summary";
    if (this.providerSubMenu.findIndex(c => c.name === "Voucher") == 0) {
      this.loadStockHistory();
    }

    this.loadStockSummary();
  }

  setDefaultTime() {
    let d = new Date();
    this.defaultTime = [d.getHours(), d.getMinutes(), d.getSeconds()]
    console.log(this.defaultTime)
  }

  loadUserInfo() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
    if (CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
      this.isMerchantLoggedIn = true;
    }
  }
  setSyncDate($event) {
    this.defaultSyncDate = $event.target.value;
    console.log("data : ", this.defaultSyncDate);
  }

  loadStockHistory(reset: boolean = false) {
    if (reset) this.currentPageHistory = 1;
    this.submitted = true;
    // stop here if form is invalid
    if (this.offerReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }
    let soldStatus = this.f.hasSold.value;


    let voucher = this.f.voucher.value;


    let inputSoldStatus = null;
    if (soldStatus == 1) {
      inputSoldStatus = true;
    }
    else if (soldStatus == 2) {
      inputSoldStatus = false;
    }
    else inputSoldStatus = null;

    let fromDate = moment(this.f.fromDate.value).format("YYYY/MM/DDTHH:mm:ss");
    let todate = moment(this.f.toDate.value).format("YYYY/MM/DDTHH:mm:ss");

    let url = `api/Coupon/merchant-vouchers/${this.userInfo.companyId}?fromDate=${fromDate}&toDate=${todate}&pageSize=${this.currentPageSizeHistory}&pageNumber=${this.currentPageHistory}`;

    if (inputSoldStatus != null)
      url += `&hasSold=${inputSoldStatus}`;

    if (voucher != null && voucher != undefined && voucher != "") {
      url += `&voucher=${voucher}`;
    }

    this.dataService.get(url)
      .subscribe((response: any) => {
        if (response.success) {
          this.history = response.data.vouchers;
          this.totalCountHistory = response.data.totalCount;
        }
        else {
          console.log("Err : ", response);
        }

      }, err => { console.log("Err : ", err) });
  }

  loadStockSummary(reset: boolean = false) {
    if (reset) this.currentPageSummary = 1;
    this.submitted1 = true;
    // stop here if form is invalid
    if (this.offerReportForm1.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }


    // let fromDate = moment(this.f1.fromDate.value).format("YYYY/MM/DDTHH:mm:ss");
    // let todate = moment(this.f1.toDate.value).format("YYYY/MM/DDTHH:mm:ss");

    let url = `api/Coupon/voucher-stock-status/${this.userInfo.companyId}`;


    this.dataService.get(url)
      .subscribe((response: any) => {
        if (response.success) {
          this.summary = response.data;
        }
        else {
          console.log("Err : ", response);
        }

      }, err => { console.log("Err : ", err) });
  }

  handlePageChangeHistory(event: number): void {
    this.currentPageHistory = event;
    this.loadStockHistory();
  }

  handlePageSizeChangeHistory(event: any): void {
    this.currentPageSizeHistory = event.target.value;
    this.currentPageHistory = 1;
    this.loadStockHistory();
  }

  onSubMenuChange(name, index) {
    this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
    this.activeSubMenu = this.providerSubMenu.filter(c => c.name === name)[0].name;
  }

  sync() {
    let syncDate = moment(this.defaultSyncDate).format("YYYY/MM/DDTHH:mm:ss");

    let url = `api/Coupon/sync-vouchers/${this.userInfo.companyId}?syncDate=${syncDate}`;


    this.dataService.get(url)
      .subscribe((response: any) => {
        if (response.success) {
          this.loadStockHistory();
          this.loadStockSummary();
        }
        else {
          console.log("Err : ", response);
        }

      }, err => { console.log("Err : ", err) });
  }

  getStyle() {
    if (this.history) {
      return `height: 5% !important;`
    }
    else `height: 20% !important;`
  }

}
