import { Component, OnInit } from '@angular/core';
import { AccountServiceService } from 'src/app/services/account-service.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AcceptOtpComponent } from '../accept-otp/accept-otp.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-loginwithphone',
  templateUrl: './loginwithphone.component.html',
  styleUrls: ['./loginwithphone.component.css']
})
export class LoginwithphoneComponent implements OnInit {
  @BlockUI('login-with-phone-section') blockUI: NgBlockUI;

  constructor(private accountService: AccountServiceService,
    private dialog: MatDialog,
    private router: Router) {
  }
  accountkit: any;
  isValidPhone: boolean = true;

  ngOnInit() {
  }

  onSubmit(phoneNumber) {

    if (phoneNumber === "" || phoneNumber.length < 5) {
      this.isValidPhone = false;
      return;
    }
    let userInfo = {
      "phoneNumber": phoneNumber.replace(' ', '').replace('-', '')
    };
    this.blockUI.start();


    this.accountService.sendOtp(userInfo)
      .subscribe((result: any) => {
        this.blockUI.stop();

        if (!result.status) {
          this.isValidPhone = false;
          return;
        }

        const dialogConfig = new MatDialogConfig();
        // Setting different dialog configurations
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'golp-dialog';
        dialogConfig.data = phoneNumber.replace(' ', '').replace('-', '');
        dialogConfig.minWidth = '100vw';

        const offerCreateDialog = this.dialog.open(AcceptOtpComponent, dialogConfig);

        const successfullCreate = offerCreateDialog.componentInstance.onValidOtpEvent.subscribe((data) => {
        });

      },
        (error: any) => {
          this.isValidPhone = true;
          this.blockUI.stop();

        })
  }

  phoneValidationChecker(phoneNumber) {
    var found = phoneNumber.search(/^(\+{1}\d{2,3}\s?[(]{1}\d{1,3}[)]{1}\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}$/);
    if (found > -1) {
      return true;
    }
    else {
      return false;
    }
  }

}
