import { AccountServiceService } from './../../../../services/account-service.service';
import { NewStakeholderComponent } from './../new-stakeholder/new-stakeholder.component';
import { DataService } from './../../../../components/app-send-gift/services/shared/data.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { AlertService } from 'src/app/services';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-manage-stakeholders',
  templateUrl: './manage-stakeholders.component.html',
  styleUrls: ['./manage-stakeholders.component.css']
})
export class ManageStakeholdersComponent implements OnInit {

  stakeHolderResponse: any;
  stakeHolders: any[];

  //pagination
  currentPage: number = 1;
  pageSize: number = 25;
  expandedIndex: number = -1;
  totalItems: number = 0;
  count = 0;
  pageSizes = [25, 50, 100];

  //SELECTION

  allComplete: boolean = false;
  selectedStakeHolders: any[] = [];
  //form related
  stakeHolderSearchForm: FormGroup;

  constructor(private router: Router, private dataService: DataService, private alertService: AlertService, private dialog: MatDialog, private formBuilder: FormBuilder, private accountService: AccountServiceService) {
    this.buildSearchForm();
  }

  ngOnInit(): void {

    this.loadStakeHolders();
  }

  buildSearchForm() {
    this.stakeHolderSearchForm = this.formBuilder.group({
      phone: '',
      email: '',
      globalInput: ''
    });
  }

  loadStakeHolders(reset: boolean = false, data: any = null) {
    let userInfo = this.accountService.getLoggedInUserInfo();

    let url = `api/stakeholder?companyId=${userInfo.companyId}`;
    if (data != null) {
      if (data.phone != "")
        url += `&phoneNumber=${data.phone}`;
      if (data.email != "")
        url += `&email=${data.email}`
      if (data.globalInput != "")
        url += `&globalSearch=${data.globalInput}`
    }
    this.dataService.get(url).subscribe((response: any) => {
      this.stakeHolderResponse = response;
      if (response.success) {
        this.totalItems = this.stakeHolderResponse.data.totalCount;
        this.stakeHolders = this.stakeHolderResponse.data.stakeHolders;

        this.stakeHolders?.forEach(m => {
          if (this.selectedStakeHolders.length > 0) {
            this.selectedStakeHolders.forEach(p => {
              if (p == m.receiverPhone) {
                m["completed"] = true;
              }
            })
          }
          else {
            m["completed"] = false;
          }
        })
        if (reset) {
          this.currentPage = 1;
        }
      }

    }, err => {

    })
  }

  onSubmit(reset: boolean = false) {
    let phone = this.stakeHolderSearchForm.value.phone;
    let email = this.stakeHolderSearchForm.value.email;
    let globalInput = this.stakeHolderSearchForm.value.globalInput;
    let serachOptions = {
      "phone": phone,
      "email": email,
      "globalInput": globalInput
    }
    this.loadStakeHolders(reset, serachOptions);
  }

  updateAllComplete() {
    this.allComplete = this.stakeHolders != null && this.stakeHolders.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.stakeHolders == null) {
      return false;
    }
    return this.stakeHolders.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.stakeHolders == null) {
      return;
    }
    this.stakeHolders.forEach(t => t.completed = completed);
  }


  addNew() {
    const addStakeHolderDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    addStakeHolderDialogConfig.disableClose = true;
    addStakeHolderDialogConfig.autoFocus = true;
    addStakeHolderDialogConfig.panelClass = "golp-dialog";

    addStakeHolderDialogConfig.data = null;

    const createAssignmentDialog = this.dialog.open(NewStakeholderComponent, addStakeHolderDialogConfig);

    const successfullCreate = createAssignmentDialog.componentInstance.onAddStakeholderEvent.subscribe((data) => {
      this.loadStakeHolders(true);
    });

  }

  onEdit(item) {
    const addStakeHolderDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    addStakeHolderDialogConfig.disableClose = true;
    addStakeHolderDialogConfig.autoFocus = true;
    addStakeHolderDialogConfig.panelClass = "golp-dialog";

    addStakeHolderDialogConfig.data = item;

    const createAssignmentDialog = this.dialog.open(NewStakeholderComponent, addStakeHolderDialogConfig);

    const successfullCreate = createAssignmentDialog.componentInstance.onUpdateStakeholderEvent.subscribe((data) => {
      this.onSubmit(false);
    });
  }

  onDelete(item) {
    //delete item
    const confirmationDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    confirmationDialogConfig.data = `Are you sure to delete the number ${item.phoneNumber}`;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        let url = `api/stakeholder/${item.id}`;

        this.dataService.delete(url)
          .subscribe((response: any) => {
            if (response.success) {
              this.alertService.success(response.data);
              this.onSubmit(false);
            } else if (!response.success) {
              this.alertService.error(response.data);
            }
          });
      }
    });
  }


  handlePageChange(event: number): void {
    this.currentPage = event;
    this.loadStakeHolders(false);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.currentPage = 1;
    this.loadStakeHolders(false);
  }

  getCurrentRange() {
    return `Showing ${((this.currentPage - 1) * this.pageSize) + 1}-${((this.currentPage - 1) * this.pageSize) + this.stakeHolders?.length} of ${this.totalItems}`
  }

  sendCard() {
    let selectedStakeHolders = this.stakeHolders.filter(x => x.completed);
    if (selectedStakeHolders.length <= 0) return;

    let navigationEgolps: NavigationExtras = {
      state: {
        transId: 'giftInformation',
        workQueue: false,
        services: 1,
        code: selectedStakeHolders,
      }
    };

    this.router.navigate(['privilege-card/select-privilege-card'], navigationEgolps);
  }

}
