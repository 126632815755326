import { Component, OnInit, Injector } from '@angular/core';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-gift-transaction-reports',
  templateUrl: './gift-transaction-reports.component.html',
  styleUrls: ['./gift-transaction-reports.component.css']
})
export class GiftTransactionReportsComponent extends BaseComponent implements OnInit {

  giftTransactionReportsSubMenu: any[] = [];
  selectedSubMenuIndex = 0;
  isAdmin = false;
  activeSubMenu = 'Consumer Gifts';

  constructor(injector: Injector) {
      super(injector);
  }

  ngOnInit() {
    this.setSubMenu();

  }

  setSubMenu() {
    this.giftTransactionReportsSubMenu.push({ 'name': 'Consumer Gifts', 'dataTarget': 'consumerGiftReportTab' });
    this.giftTransactionReportsSubMenu.push({ 'name': 'Corporate Gifts', 'dataTarget': 'corporateGiftReportTab' });
    this.giftTransactionReportsSubMenu.push({ 'name': 'Promotional Gifts', 'dataTarget': 'promotionalGiftReportTab' });
    this.giftTransactionReportsSubMenu.push({ 'name': 'Individual Gifts', 'dataTarget': 'individualGiftReportTab' });

    if (this.permissionCheckerService.hasPermission(['ConsumerGiftReport'])) {
      this.activeSubMenu = "Consumer Gifts";
    }
    else if (this.permissionCheckerService.hasPermission(['CorporateGiftReport'])) {
      this.activeSubMenu = "Corporate Gifts";
    }
  }

  onSubMenuChange(name, index) {
    if (this.selectedSubMenuIndex !== index) {
      this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
      this.activeSubMenu = this.giftTransactionReportsSubMenu.filter(c => c.name === name)[0].name;
    }
  }
}
