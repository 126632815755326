import { Component, OnInit, Injector } from '@angular/core';
import { CreateMerchantComponent } from '../create-merchant/create-merchant.component';
import { CompanyRole } from 'src/app/enums/company-role.enum';
import { Company } from 'src/app/models/company';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component'
import { CommonService, CompanyService, AccountServiceService, AlertService, TrendingMerchantService } from 'src/app/services';
import { BaseComponent } from '../base/base.component';
import { CreateTrendingMerchantComponent } from '../create-trending-merchant/create-trending-merchant.component';
import { TrendingMerchant, GetTrendingMerchantsPagedListInput } from '../../models';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-trending-merchants',
  templateUrl: './trending-merchants.component.html',
  styleUrls: ['./trending-merchants.component.css']
})

export class TrendingMerchantsComponent extends BaseComponent implements OnInit {
  @BlockUI('trending-merchant-section') blockUI: NgBlockUI;

  dataLoaded: Promise<boolean>;

  currentPage: number = 1;
  pageSize: number = 10;
  itemCountText: string;

  userInfo: any;
  canCreate: boolean = true;

  filteredMerchants: any[];
  merchants: any[];

  merchantFilter: any = { name: '' };
  merchantFilterText: string;
  selectedMerchantIndex: number = -1;
  selectedMerchant: Company;
  selectedMerchantName: string;

  merchantSubMenu: any[] = [];
  selectedSubMenuIndex: number = 0;
  activeSubMenu: string = "About";
  companyRoleType: CompanyRole = CompanyRole.Merchant;
  totalItems: any;
  searchText: string;

  constructor(private companyService: CompanyService, private dialog: MatDialog,
    private accountService: AccountServiceService,
    private commonService: CommonService,
    private trendingMerchantService: TrendingMerchantService,
    private alertService: AlertService,
    injector: Injector
  ) {
    super(injector);
  }
  //
  ngOnInit() {
    this.getLoggedInUser();
    this.getAllTrendingMerchants(null);

    this.merchantSubMenu.push({ "name": "About", "dataTarget": "aboutProviderTab" });

    this.dataLoaded = Promise.resolve(true);
  }

  createTrendingMerchant() {
    const dialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'golp-dialog';

    const merchantCreateDialog = this.dialog.open(CreateTrendingMerchantComponent, dialogConfig);

    const successfullCreate = merchantCreateDialog.componentInstance.onTrendingMerchantCreateEvent.subscribe((data) => {

      this.getAllTrendingMerchants(data);

    });

    const successfullEdit = merchantCreateDialog.componentInstance.onTrendingMerchantEditEvent.subscribe((data) => {

      this.getAllTrendingMerchants(data);

    });

    merchantCreateDialog.afterClosed().subscribe(() => {
      //successFullEdit.unsubscribe();
      //this.detailsLoaded = Promise.resolve(false);
    });

  }

  onMerchantChange(provider, index) {
    if (this.selectedMerchantIndex != index) {
      this.selectedMerchantIndex = index === this.selectedMerchantIndex ? -1 : index;
      this.selectedMerchant = provider;
      this.selectedMerchantName = provider.name;
    }
    let body = document.getElementsByTagName('body')[0];
    body.classList.add("display-tab-content");
    this.commonService.itemSelectionUpdated(this.selectedMerchant);
  }

  onSubMenuChange(name, index) {
    if (this.selectedSubMenuIndex != index) {
      this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
      this.activeSubMenu = this.merchantSubMenu.filter(c => c.name === name)[0].name;
    }
  }

  getMerchantDetailsChange(event) {
    let merchant = this.filteredMerchants.filter(c => c.id === event.id)[0];
    merchant.companyId = event.companyId;
    merchant.isActive = event.isActive;
    merchant.orderNo = event.orderNo;

    this.selectedMerchant = merchant;

    this.getAllTrendingMerchants(this.selectedMerchant);
  }

  OnDeleteTrendingMerchant(trendingMerchant: TrendingMerchant): void {
    const confirmationDialogConfig = new MatDialogConfig()

    // Setting different dialog configurations
    confirmationDialogConfig.data = "Are you sure to delete the merchant " + trendingMerchant.name + " from trending list.";
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.deleteMerchant(trendingMerchant);
      }
    });
  }

  deleteMerchant(trendingMerchant: TrendingMerchant) {
    this.trendingMerchantService.deleteTrendingMerchant(trendingMerchant.id).subscribe((data) => {
      this.alertService.success('Trending Merchant deleted successfully');
      this.getAllTrendingMerchants(null);
    },
      (error) => {
      });
  }

  getAllTrendingMerchants(currentlyCreatedMerchant: any): void {
    var input = new GetTrendingMerchantsPagedListInput();
    input.pageNumber = this.currentPage;
    input.pageSize = this.pageSize;
    input.searchText = this.searchText;

    this.blockUI.start();

    this.trendingMerchantService.getTrendinMerchantPagedList(input)
      .subscribe((result) => {
        this.merchants = result.data;
        this.merchantCopy();

        this.highlightFirstItem(this.filteredMerchants);

        this.totalItems = result.rowCount;
        this.itemCountText = "(Showing " + result.firstRowOnPage + " to " + result.lastRowOnPage + " of " + result.rowCount + " items)"
        this.blockUI.stop();

        //this.itemCount();
      },
        (error) => {
          this.blockUI.stop();

        });
  }

  getLoggedInUser() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
    if (CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
      this.canCreate = false;
    }
  }

  highlightFirstItem(merchants: Company[]) {
    // set initial offeror
    if (merchants !== null && merchants.length > 0) {
      this.selectedMerchant = merchants[0];
      this.selectedMerchantName = this.selectedMerchant.name;
      this.selectedMerchantIndex = 0;
    }
  }

 
  pageChanged(event) {
    this.currentPage = event;
    this.getAllTrendingMerchants(null);
  }

  merchantFilteredItem(value) {
    this.searchText = value;
    this.getAllTrendingMerchants(null);
  }

  merchantCopy() {
    this.filteredMerchants = Object.assign([], this.merchants);
  }


  errorHandlerCompanyLogo(event, merchantId) {
    //event.target.src = "http://place-hold.it/24x24/ebe89f/9D2933&fontsize=12&text=" + merchantFirstChar;

    document.getElementById('companyLogoImgtxt_' + merchantId).style.display = "block";
    document.getElementById('companyLogoImg_' + merchantId).style.display = "none";
  }

}
