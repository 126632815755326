import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CreateCorporateOfferComponent } from '../create-corporate-offer/create-corporate-offer.component';
import { CorporateOffer } from '../../models/corporate-offer';
import { CopyOfferComponent } from '../copy-offer/copy-offer.component';

@Component({
  selector: 'app-corporate-offer-details',
  templateUrl: './corporate-offer-details.component.html',
  styleUrls: ['./corporate-offer-details.component.css']
})

export class CorporateOfferDetailsComponent implements OnInit {
  @Input() offerDetails: CorporateOffer;
  @Output() offerDetailsChange: EventEmitter<any> = new EventEmitter<CorporateOffer>();

  detailsData: CorporateOffer;
  showHideAcceptButton: boolean = true;
  startDateView: string;
  endDateView: string;
  offerConditions: any[]

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    this.offerDetails = new CorporateOffer();
    if (this.detailsData != null) {
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['offerDetails']) {
      this.detailsData = this.offerDetails;
    }
  }

  editOffer(offer: CorporateOffer) {
    const editDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    editDialogConfig.disableClose = true;
    editDialogConfig.autoFocus = true;
    editDialogConfig.panelClass = 'golp-dialog';
    editDialogConfig.data = { offerType: offer.offerType, offer: offer };

    const discountEditDialog = this.dialog.open(CreateCorporateOfferComponent, editDialogConfig);

    const successFullEdit = discountEditDialog.componentInstance.onCorporateOfferEditEvent.subscribe((data) => {
      this.detailsData = data;
      if (this.detailsData !== undefined) {
        this.offerDetailsChange.emit(this.detailsData);
      }
    });

    discountEditDialog.afterClosed().subscribe(() => {

    });
  }

  copyOffer(offer: CorporateOffer) {
    const editDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    editDialogConfig.disableClose = true;
    editDialogConfig.autoFocus = true;
    editDialogConfig.panelClass = 'golp-dialog';
    editDialogConfig.data = { offerType: offer.offerType, offer: offer };

    const discountEditDialog = this.dialog.open(CopyOfferComponent, editDialogConfig);

    const successFullEdit = discountEditDialog.componentInstance.onCopyOfferEvent.subscribe((data) => {
      this.detailsData = data;
      if (this.detailsData !== undefined) {
        this.offerDetailsChange.emit(this.detailsData);
      }
    });

    discountEditDialog.afterClosed().subscribe(() => {

    });
  }
}

