import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { UserModel } from 'src/app/models/user-model';
import { AccountServiceService } from 'src/app/services/account-service.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AlertService, FileUploadService, CompanyOutletService, RolesService } from 'src/app/services';
import { CompanyRole } from '../../enums';
import { CompanyOutlet } from '../../models';
import { ReplaySubject } from 'rxjs';
import { ValidationUtility } from '../../utility/validation.utility';

export class RegistrationValidator {
    static validate(registrationFormGroup: FormGroup) {
        let password = registrationFormGroup.controls.password.value;
        let confirmPassword = registrationFormGroup.controls.confirmPassword.value;
        if (confirmPassword.length <= 0) {
            return null;
        }

        if (confirmPassword !== password) {
            return {
                doesMatchPassword: true
            };
        }

        return null;
    }
}

@Component({
    selector: 'app-create-user',
    templateUrl: './create-user.component.html',
    styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {

    // onCreate event
    onUserCreateEvent: EventEmitter<number> = new EventEmitter();
    // onEdit event;
    onUserEditEvent: EventEmitter<number> = new EventEmitter();

    companyRoleType: CompanyRole = CompanyRole.None;


    userForm: FormGroup
    passwordFormGroup: FormGroup;
    submitted = false;
    user: UserModel;
    companyId: number;
    roles: any[];
    uploadedFiles: FormData;
    inEditMode: boolean = false;

    fileName: string = '';
    imagePreviewPath: string = '../../../assets/images/default.png';
    fileValidationError: string;
    showUserScopeSecion: boolean = false;
    showOutlets: boolean = false;
    userScopes: any;
    companyOutlets: CompanyOutlet[];

    roleFilterCtrl: FormControl = new FormControl();
    userScopeFilterCtrl: FormControl = new FormControl();
    outletFilterCtrl: FormControl = new FormControl();
    filteredRoles: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    filteredUserScopes: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    filteredOutlets: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    constructor(private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<CreateUserComponent>,
        private accountService: AccountServiceService,
        private alertService: AlertService,
        private outletService: CompanyOutletService,
        private fileUploadService: FileUploadService,
        private roleService: RolesService,
        @Inject(MAT_DIALOG_DATA) data) {

        this.getUserScopes();


        this.user = new UserModel();

        if (isNaN(data)) { // coming from user list page


            this.user = new UserModel(data);
            this.inEditMode = true;
            this.companyRoleType = this.user.companyRoleType;
            this.companyId = this.user.companyId;
            this.showHideCompanyOutlets(this.user.userScope);
            //this.showHideUserScope(this.user.roleId);
        }
        else { // coming from other page
            this.companyId = data;
            this.user.companyId = this.companyId;

            this.companyRoleType = this.user.companyRoleType;


            this.passwordFormGroup = this.formBuilder.group({
                password: ['', Validators.required],
                confirmPassword: ['', Validators.required]
            }, {
                validator: RegistrationValidator.validate.bind(this)
            });
        }
        if (this.user.profileImageUrl != null) {
            this.imagePreviewPath = this.user.profileImageUrl;
            this.fileName = this.user.profileImageName;
        }
        this.buildForm();
        if (this.inEditMode === true) {
            this.userForm.controls.email.disable();
        }
        else {
            this.userForm.controls.email.enable();
        }
    }

    ngOnInit() {
        this.getRoles();
        //this.showHideCompanyOutlets();
        this.getOutletsByCompany(this.companyId);
        this.setOutletValidators();
        this.bindRoleFilterChangeEvent();
        this.bindUserScopeFilterChangeEvent();
        this.bindOutletFilterChangeEvent();
    }

    getUserScopes(): void {
        this.userScopes = [{ text: 'None', value: 0 }, { text: 'Outlet', value: 1 },
        { text: 'Terminal', value: 2 }];

        this.filteredUserScopes.next(this.userScopes);
    }

    buildForm() {
        this.userForm = this.formBuilder.group({
            userName: [this.user.userName],
            email: [this.user.email, [Validators.required, Validators.email]],
            middleInitial: this.user.middleInitial,
            firstName: [this.user.firstName, [Validators.maxLength(30)]],
            lastName: [this.user.lastName, [Validators.maxLength(30)]],
            phoneNumber: [this.user.phoneNumber, [Validators.required, Validators.maxLength(50)]],
            profileImageName: this.user.profileImageName,
            companyId: this.user.companyId,
            roleId: [this.user.roleId, [Validators.required]],
            userScope: [this.user.userScope],
            companyOutletId: [this.user.companyOutletId],
            password: [this.user.password],
            confirmPassword: [this.user.confirmPassword],
            passwordFormGroup: this.passwordFormGroup
        });
    }

    onUploadPhoto(files) {
        this.fileValidationError = null;
        let fileInfo = this.fileUploadService.imageFileUpload(files);
        if (fileInfo.validationError != null) {
            this.fileValidationError = fileInfo.validationError;
            return;
        }
        this.fileName = fileInfo.fileName;
        this.uploadedFiles = fileInfo.formData;

        var reader = new FileReader();
        reader.onload = (event: any) => {
            this.imagePreviewPath = event.target.result;
        }
        reader.readAsDataURL(files[0]);
    }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.userForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }
    if (this.user.id === undefined) {
      this.createUser();
    }
    else if (this.user.id > 0) {
      this.editUser();
    }
    this.dialogRef.close();
  }

    getOutletsByCompany(companyId) {
        if (companyId) {
            this.outletService.getOutletsByCompany(companyId)
                .subscribe((data: any) => {
                    this.companyOutlets = data;
                    this.filteredOutlets.next(this.companyOutlets);
                },
                    (error: any) => {
                        console.log(error);
                    });
        }
    }

    createUser() {
        this.user = new UserModel(this.userForm.value);
        if (this.user !== null) {
            this.user.companyId = this.companyId;
            this.user.id = 0;
            this.user.password = this.passwordFormGroup.controls.password.value;
            this.user.userName = this.user.email;
            //this.user.companyOutletId= this
        }
        this.accountService.createUser(this.user)
            .subscribe((data: any) => {
                // upload company logo
                if (this.uploadedFiles != null) {
                    this.uploadedFiles.append('id', data.id)
                    this.accountService.uploadFile(this.uploadedFiles).subscribe((result: any) => {
                    },
                        (error: any) => {
                        });
                }
                this.onUserCreateEvent.emit(this.user.companyId);
                this.alertService.success("User added successfully");
            },
                (error: any) => {

                    if (error && error.error && error.error.Message) {
                        this.alertService.error(error.error.Message);
                    }
                    else {
                        this.showErrorMessage(error);
                    }
                })
    }

    editUser() {
        let newData = new UserModel(this.userForm.value);
        if (this.user !== null) {
            this.user.userName = newData.userName;
            this.user.email = newData.email;
            this.user.firstName = newData.firstName;
            this.user.middleInitial = newData.middleInitial;
            this.user.lastName = newData.lastName;
            this.user.phoneNumber = newData.phoneNumber;
            this.user.profileImageName = newData.profileImageName;
            this.user.companyId = newData.companyId;
            this.user.roleId = newData.roleId;

            this.accountService.editUser(this.user)
                .subscribe((data: any) => {
                    // upload company logo
                    if (this.uploadedFiles != null) {
                        this.uploadedFiles.append('id', this.user.id.toString());
                        this.accountService.uploadFile(this.uploadedFiles).subscribe((result: any) => {
                        },
                            (error: any) => {
                            });
                    }
                    this.onUserEditEvent.emit(this.user.companyId);
                    this.alertService.success("User edited successfully");
                },
                    (error: any) => {
                        this.showErrorMessage(error);
                    });

        }
    }

    getRoles() {
        this.roleService.getFilteredRoles()
            .subscribe((data: any) => {
                this.roles = data;
                var adminRole = this.roles.filter(c => c.name == 'SystemAdmin');
                var salesManagerRole = this.roles.filter(c => c.name == 'SalesManager');
                var serviceConsumerRole = this.roles.filter(c => c.name == 'ServiceConsumer');
                var removeSpecialRoles = true;

                if (this.inEditMode && this.user && (this.user.roleId == adminRole[0].id
                    || this.user.roleId == salesManagerRole[0].id || this.user.roleId == serviceConsumerRole[0].id)) {
                    removeSpecialRoles = false;
                }

                if (removeSpecialRoles)
                    this.roles = this.roles.filter(c => (c.name !== 'SystemAdmin' && c.name !== 'SalesManager' && c.name !== 'ServiceConsumer'));
                else
                    this.userForm.controls.roleId.disable();


                this.setRolesByCompanyRole();

                if (this.user && this.user.roleId)
                    this.showHideUserScope(this.user.roleId);

                this.filteredRoles.next(this.roles);

            });
    }

    setRolesByCompanyRole(): void {
        if (this.companyRoleType == CompanyRole.Merchant) {
            this.roles = this.roles.filter(c => c.roleScope === "Merchant");
        }
        else if (this.companyRoleType == CompanyRole.GiftSender) {
            this.roles = this.roles.filter(c => c.roleScope === "Provider");
        }
        else if (this.companyRoleType == CompanyRole.None) {
            this.roles = this.roles.filter(c => c.roleScope !== "Merchant" && c.roleScope !== "Provider");
        }
    }

    onChangeRole(roleId): void {
        this.showHideUserScope(roleId);
    }

    onChangeUserScope(userScope): void {
        this.showHideCompanyOutlets(userScope);
    }

    showHideUserScope(roleId): void {
        var role = this.roles.find(x => x.id == roleId);
      if (role.name == "OutletAdmin" || role.name == "OutletUser") {

            this.showUserScopeSecion = true;
            this.user.userScope = this.user.userScope;
        }
        else {
            this.showUserScopeSecion = false;
        }
    }

    showHideCompanyOutlets(userScope): void {
        if (userScope == 1) {

            this.showOutlets = true;
        }
        else {
            this.showOutlets = false;
        }
    }

  bindRoleFilterChangeEvent() {
    // listen for search field value changes
    this.roleFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterRoles();
      });
  }
  protected filterRoles() {
    if (!this.roles) {
      return;
    }
    // get the search keyword
    let search = this.roleFilterCtrl.value;
    if (!search) {
      this.filteredRoles.next(this.roles);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the CompanyCategories
    this.filteredRoles.next(
      this.roles.filter(role => role.displayName.toLowerCase().indexOf(search) > -1)
    );
  }




    bindUserScopeFilterChangeEvent() {
        // listen for search field value changes
        this.userScopeFilterCtrl.valueChanges
            .subscribe(() => {
                this.filterUserScopes();
            });
    }
    protected filterUserScopes() {
        if (!this.userScopes) {
            return;
        }
        // get the search keyword
        let search = this.userScopeFilterCtrl.value;
        if (!search) {
            this.filteredUserScopes.next(this.userScopes);
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the CompanyCategories
        this.filteredUserScopes.next(
            this.userScopes.filter(u => u.text.toLowerCase().indexOf(search) > -1)
        );
    }


    bindOutletFilterChangeEvent() {
        // listen for search field value changes
        this.outletFilterCtrl.valueChanges
            .subscribe(() => {
                this.filterOutlets();
            });
    }
    protected filterOutlets() {
        if (!this.companyOutlets) {
            return;
        }
        // get the search keyword
        let search = this.outletFilterCtrl.value;
        if (!search) {
            this.filteredOutlets.next(this.companyOutlets);
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the CompanyCategories
        this.filteredOutlets.next(
            this.companyOutlets.filter(outlet => outlet.name.toLowerCase().indexOf(search) > -1)
        );
    }


    setOutletValidators() {
        this.setValidationForOutlet();

    this.userForm.get('userScope').valueChanges
      .subscribe(userScope => {
        this.setValidationForOutlet();
      });
  }

    private setValidationForOutlet() {
        const outletControl = this.userForm.get('companyOutletId');

    if (this.userForm.get('userScope').value == 1 && this.showUserScopeSecion) { // 1 for outlet
      outletControl.setValidators([Validators.required]);
    }
    else {
      outletControl.setValidators(null);
    }
    outletControl.updateValueAndValidity();
  }

    close() {
        this.dialogRef.close();
    }

    showErrorMessage(error: any) {
        console.log(error);
        //this.alertService.error("Internal server error happen");
    }

    openFileBrowser(event: any) {
        event.preventDefault();
        let element: HTMLElement = document.getElementById('UserPhotoId') as HTMLElement;
        element.click();
    }

    // convenience getter for easy access to form fields
    get f() { return this.userForm.controls; }

}
