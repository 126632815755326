import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileUploadService } from 'src/app/services';
import * as moment from 'moment';
import { ValidationUtility } from 'src/app/utility/validation.utility';


@Component({
  selector: 'app-deliver-gift-order',
  templateUrl: './deliver-gift-order.component.html',
  styleUrls: ['./deliver-gift-order.component.css']
})
export class DeliverGiftOrderComponent implements OnInit {
  confirmationMessage: string;
  deliveryForm: FormGroup;
  deliveryFormData: FormData = new FormData();
  submitted: boolean = false;
  merchantList: any;
  merchantId: any;
  notifyReceiver: boolean = false;
  title: string;
  notifyCheckboxShow: boolean = true;
  effictiveFrom: any;
  validDays: any;
  showValidityAndEffictiveDateRow: boolean = false;
  showFileUploadSection: boolean = true;
  files: any;
  fileName: string;
  invoice: boolean = false;
  invoiceFromDate: any;
  invoiceToDate: any;
  paymentDate: any;
  invoiceReferance: any = '';
  dropdownListName: string = 'Merchants';

  constructor(
    private dialogRef: MatDialogRef<DeliverGiftOrderComponent>,
    private formBuilder: FormBuilder,
    private fileUploadService: FileUploadService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    if (data !== null) {
      this.confirmationMessage = data.confirmationMessage;
      this.merchantList = data.merchantList;
      this.title = data.title;
      this.notifyCheckboxShow = data.notifyCheckboxShow;
      this.showValidityAndEffictiveDateRow = data.showValidityAndEffictiveDateRow;
      this.showFileUploadSection = data.showFileUploadSection ?? true;
      this.invoice = data.invoice ?? false;
      this.dropdownListName = data.dropdownListName ?? 'Merchnats';
    }
    this.invoiceFromDate = new Date();
    this.invoiceToDate = new Date();
    this.paymentDate = new Date();
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.deliveryForm = this.formBuilder.group({
      merchantId: [this.merchantId, [Validators.required]],
      notifyReceiver: this.notifyReceiver,
      effictiveFrom: this.effictiveFrom,
      validDays: this.validDays,
      invoiceFromDate: this.invoiceFromDate,
      invoiceToDate: this.invoiceToDate,
      paymentDate: this.paymentDate,
      invoiceReferance: this.invoiceReferance
    });
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    this.onUploadDataFile(target.files);
  }

  onUploadDataFile(files) {
    let fileInfo = this.fileUploadService.anyFileUpload(files, "dataFile");
    if (fileInfo.validationError != null) {
      alert(fileInfo.validationError);
      return;
    }
    this.fileName = fileInfo.fileName;
    this.files = files;
    this.deliveryFormData.append("deliveryFile", files[0]);
    this.deliveryFormData.append("MerchantCodeFile", files[0]);
  }

  confirm() {
    this.submitted = true;
    if (this.deliveryForm.invalid) {
      this.buildForm();
      return;
    };

    const numberOfDays = this.deliveryForm.get("validDays").value ?? 0;
    const effictiveDate = this.deliveryForm.get("effictiveFrom").value ?? new Date(-62135596800000).toISOString();
    
    this.deliveryFormData.append("merchantId", this.deliveryForm.get('merchantId').value);
    this.deliveryFormData.append("notifyReceiver", this.deliveryForm.get('notifyReceiver').value);
    this.deliveryFormData.append("effictiveFrom", moment(effictiveDate.toString()).format('YYYY/MM/DD'));
    this.deliveryFormData.append("validDays", numberOfDays);
    this.deliveryFormData.append("invoiceFromDate", moment(this.deliveryForm.get('invoiceFromDate').value).format('YYYY/MM/DD'));
    this.deliveryFormData.append("invoiceToDate", moment(this.deliveryForm.get('invoiceToDate').value).format('YYYY/MM/DD'));
    this.deliveryFormData.append("paymentDate", moment(this.deliveryForm.get('paymentDate').value).format('YYYY/MM/DD'));
    this.deliveryFormData.append("invoiceReferance", this.deliveryForm.get("invoiceReferance").value);
    this.dialogRef.close(this.deliveryFormData);
  }

  close() {
    this.dialogRef.close();
  }

  get f() {
    return this.deliveryForm.controls;
  }
}