import { Component, OnInit, Input } from '@angular/core';
import { AccountServiceService, AlertService } from 'src/app/services';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { RolesService } from 'src/app/services/roles.service';
import { Role } from 'src/app/models/role';
import { CreateRoleComponent } from '../create-role/create-role.component';
import { RoleSharedService } from 'src/app/services/role-shared.service';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})

export class RolesComponent implements OnInit {


  roles :any[];
  mssg :string ='Aplectrum' ;
  constructor(private roleService: RolesService, private dialog: MatDialog,
    private roleSharedService : RoleSharedService,
    private alertService: AlertService
    ) { }

  ngOnInit() {
    this.getRoles();
  }

  ngOnChanges() {
  }

  getRoles() {
    this.roleService.getRoles()
        .subscribe(data => {
          this.roles = data;
          this.roleSharedService.newEvent(data);
        });
  }

  addRole() {
    const roleDialogConfig = new MatDialogConfig();
    
    // Setting different dialog configurations
    roleDialogConfig.disableClose = true;
    roleDialogConfig.autoFocus = true;
    roleDialogConfig.data = null;
    roleDialogConfig.panelClass = 'golp-dialog';

    const roleCreateDialog = this.dialog.open(CreateRoleComponent, roleDialogConfig);

    const successfullCreate = roleCreateDialog.componentInstance.onRoleCreateEvent.subscribe((data) => {
      this.getRoles();
    });

    roleCreateDialog.afterClosed().subscribe(() => {
    });

  }

  editRole(roleModel: Role){
    const editDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    editDialogConfig.disableClose = true;
    editDialogConfig.autoFocus = true;
    editDialogConfig.data = roleModel
    editDialogConfig.panelClass = 'golp-dialog';

    const roleEditDialog = this.dialog.open(CreateRoleComponent, editDialogConfig);

    const successfullEdit = roleEditDialog.componentInstance.onRoleEditEvent.subscribe((data) => {
      this.getRoles();
    });

    roleEditDialog.afterClosed().subscribe(() => {
    });
  }

  onDeleteRole(role: Role): void{
    const confirmationDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    confirmationDialogConfig.data = "Are you sure to delete the role " + role.displayName;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if(result != undefined){
        this.deleteRole(role);
      }
    });
  }

  deleteRole(role: Role){
    this.roleService.deleteRole(role.id).subscribe((data) => {
      this.alertService.success('Role deleted successfully');
      this.roles = this.roles.filter(function(item){
        if(item.id !== role.id){
          return true;
        }
        else{
          return false;
        }
      });

      this.roleSharedService.newEvent(this.roles);
    },
    (error) => {
      this.alertService.error('Internal server error happen');
      console.log(error);
    });
  }

}

