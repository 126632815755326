import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';

@Component({
    selector: 'app-create-api-endpoint-configuration',
    templateUrl: './create-api-endpoint-configuration.component.html',
    styleUrls: ['./create-api-endpoint-configuration.component.css']
})
export class CreateApiEndpointConfigurationComponent implements OnInit {

    //keyValues = [{ key: '', value: '' }, {key: '', value: '' }, { key: '', value: '' }, {key: '', value: '' }];
    keyValues = [{ key: '', value: '' }];

    @Input() existingKeyValues: any;

    @Output() keyValuesEmitter: EventEmitter<any> = new EventEmitter<any>();

    keyValuesJson: any;
    keyValuesJsonArray: any;

    public keyValuesForm: FormGroup;

    constructor(private _fb: FormBuilder) { }

    ngOnInit() {
        this.keyValuesForm = this._fb.group({
            itemRows: this._fb.array([this.initItemRows()])
        });

        this.setFormArrayValue();
    }

    public setFormArrayValue() {
        if (this.existingKeyValues) {
            this.deleteFirstEmptyRow();

            this.keyValuesJsonArray = JSON.parse(this.existingKeyValues);

            var i = 0;
            Object.entries(this.keyValuesJsonArray).forEach(element => {
                this.formArr.push(this.initItemRowsForEdit(element));
                i = i + 1;

            });
            this.setKeyValuesJsonAndEmit();
        }
    }

    get formArr() {
        return this.keyValuesForm.get('itemRows') as FormArray;
    }

    initItemRowsForEdit(element: any) {
        return this._fb.group({
            //itemname: ['']
            key: [element[0]],
            value: [element[1]]
        });
    }

    initItemRows() {
        return this._fb.group({
            //itemname: ['']
            key: ['merchant'],
            value: ['']
        });
    }

    addItemRows() {
        return this._fb.group({
            //itemname: ['']
            key: [''],
            value: ['']
        });
    }

    addNewRow() {
        this.formArr.push(this.addItemRows());
        this.setKeyValuesJsonAndEmit();
    }

    deleteFirstEmptyRow() {
        this.formArr.removeAt(0);
        this.setKeyValuesJsonAndEmit();
    }

    deleteRow(index: number) {
        this.formArr.removeAt(index);
        this.setKeyValuesJsonAndEmit();
    }

    setKeyValuesJson(): void {
        var customArray = this.keyValuesForm.controls.itemRows.value;
        var object = {};

        customArray.forEach((element1) => {
            var key = element1.key;
            var value = element1.value;
            if (key)
                object[key] = value;
        });

        this.keyValuesJson = JSON.stringify(object);

    }

    setKeyValuesJsonAndEmit(): void {
        this.setKeyValuesJson();
        this.keyValuesEmitter.emit(this.keyValuesJson);
    }

    get formData() { return <FormArray>this.keyValuesForm.get('itemRows'); }

}
