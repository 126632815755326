import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { DataService } from 'src/app/services';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import { saveAs } from 'file-saver';
import { MerchantOutletResponse } from 'src/app/models/view-models/merchant-outlet-response';
import * as $ from 'jquery';

@Component({
  selector: 'app-offer-transaction-report',
  templateUrl: './offer-transaction-report.component.html',
  styleUrls: ['./offer-transaction-report.component.css']
})
export class OfferTransactionReportComponent implements OnInit {

  offerReportForm: FormGroup;

  currentPage: number = 1;
  pageSize: number = 25;
  totalItems: number = 0;
  count = 0;
  pageSizes = [25, 50, 100];
  paginationInfo: string = '';
  p: number = 1;

  defaultStartDate: Date = this.startOfMonth(new Date());
  defaultEndDate: Date = new Date();
  paginationPageNumbers: any[];
  currentPageNumberDetails: string;
  submitted: boolean;
  companyOutletId: any;

  userInfo: any;

  merchantOutlets: any;
  offerTransactions: any;

  constructor(private formBuilder: FormBuilder, private dataService: DataService) {
    this.buildForm();
  }

  ngOnInit(): void {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userInfo = userInfo;
    if (this.userInfo.outletQrCode == undefined || this.userInfo.outletQrCode == null)
      this.getMerchantOutlet();
    if (this.userInfo.outletQrCode != undefined && this.userInfo.outletQrCode != null)
      this.companyOutletId = this.userInfo.outletQrCode;
  }

  buildForm() {
    this.offerReportForm = this.formBuilder.group({
      fromDate: [this.defaultStartDate, Validators.required],
      toDate: [this.defaultEndDate, Validators.required],
      outletId: ['', Validators.nullValidator],
      hasAvailed: ['', Validators.nullValidator],
      receiverPhoneNumber: '',
      globalInput: '',
    });
  }

  startOfMonth(date: Date) {
    let startDateOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    startDateOfMonth.setMinutes(date.getMinutes() + 360); // 360 is for GMT+6 hours
    return startDateOfMonth;
  }

  getOfferReportData(submitted: boolean = false) {

    this.submitted = submitted;
    // stop here if form is invalid
    if (this.offerReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    let fromDate = moment(this.f.fromDate.value).format("YYYY/MM/DD");
    let todate = moment(this.f.toDate.value).format("YYYY/MM/DD");
    let outletId = this.f.outletId.value;
    let hasAvailedForm = this.f.hasAvailed.value;
    let hasAvailed = null;
    let globalInput = this.f.globalInput.value;

    if (hasAvailedForm == 1) {
      hasAvailed = true;
    }
    else if (hasAvailedForm == 2) {
      hasAvailed = false;
    }

    if (outletId != 0)
      this.companyOutletId = outletId.qrCode;

    let url = `api/offerreports/offer-assignment?merchantId=${this.userInfo.companyId}&fromDate=${fromDate}&toDate=${todate}&pageSize=${this.pageSize}&pageNumber=${this.p}`;

    if (this.userInfo.outletQrCode != null || this.userInfo.outletQrCode != undefined || outletId != 0)
      url += `&outletId=${this.companyOutletId}`;
    if (hasAvailed != null)
      url += `&hasAvailed=${hasAvailed}`;
    if (globalInput != null)
      url += `&receiver=${globalInput}`;

    this.dataService.get(url)
      .subscribe((response: any) => {

        if (this.submitted) this.p = 1;

        if (response.success) {
          this.offerTransactions = response.data.offerAssignmentList;

          console.log(this.offerTransactions);

          this.count = response.data.count;
          this.getCurrentRange();
          //loadJquery();
        }
      });
  }

  exportXLReportFromServer() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.offerReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    let fromDate = moment(this.f.fromDate.value).format("YYYY/MM/DD");
    let todate = moment(this.f.toDate.value).format("YYYY/MM/DD");
    let outletId = this.f.outletId.value;
    let hasAvailedForm = this.f.hasAvailed.value;

    let hasAvailed = null;
    if (hasAvailedForm == 1) {
      hasAvailed = true;
    }
    else if (hasAvailedForm == 2) {
      hasAvailed = false;
    }

    if (outletId != 0)
      this.companyOutletId = outletId.qrCode;

    let url = `api/offerreports/export-offer-transactions`;

    let payload = {
      "merchantId": this.userInfo.companyId,
      "fromDate": fromDate,
      "toDate": todate,
      "outletId": this.companyOutletId,
      "hasAvailed": hasAvailed,
    };

    this.dataService.postWithOptions(url, payload, {
      responseType: 'blob'
    })
      .subscribe((response: any) => {
        this.downloadFile(response);
      }, () => { console.log("Error Occurred") }, () => { console.log("Download Finished") });
  }

  downloadFile(data) {
    const blob2 = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var filename = "Offer_Transactions_" + moment().format('MMMM Do YYYY, h:mm:ss').valueOf() + ".xlsx";
    saveAs(blob2, filename);
  }

  getMerchantOutlet() {
    let url = `api/Outlets/company/${this.userInfo.companyId}/`;
    this.dataService.get(url).subscribe((data: MerchantOutletResponse[]) => {
      if (data != null && data.length > 0) {
        this.merchantOutlets = data;
      }
    });
  }

  handlePageChange(event: number): void {
    this.p = event;
    this.getOfferReportData();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = parseInt(event.target.value);
    this.p = 1;
    this.getOfferReportData();
  }

  getCurrentRange() {
    this.paginationInfo = `Showing ${((this.p - 1) * this.pageSize) + 1}-${((this.p - 1) * this.pageSize) + this.offerTransactions?.length} of ${this.count} items`
  }

  get f() { return this.offerReportForm.controls; }

}

function loadJquery() {
  $(document).ready(function () {
    var cells = $(".redesign");

    for (var headCell = 0; headCell < cells.length;) {
      $(cells[headCell++]).addClass("d-sticky");
      $(cells[headCell++]).addClass("d-sticky2");
      $(cells[headCell++]).addClass("d-sticky3");
      $(cells[headCell++]).addClass("d-sticky4");
    }
  });
}
