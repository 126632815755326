import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RedemptionService {

  private redemptionBaseUrl = 'api/Redemption';

  constructor(private http: HttpClient) { }

  applyRedemption(giftDetails: any): Observable<any> {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post<any>(`${this.redemptionBaseUrl}/redeem`, giftDetails, requestHeader);
  }
}
