import { NewCardAssignmentComponent } from './new-card-assignment/new-card-assignment.component';
import { DataService } from 'src/app/components/app-send-gift/services/shared/data.service';
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { AlertService, AccountServiceService } from 'src/app/services';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Select2OptionData } from 'ng-select2';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { ActivateCardComponent } from './activate-card/activate-card.component';
@Component({
  selector: 'app-privilege-card-assignment',
  templateUrl: './privilege-card-assignment.component.html',
  styleUrls: ['./privilege-card-assignment.component.css']
})
export class PrivilegeCardAssignmentComponent implements OnInit {

  @Input() cardTemplateInput: any;

  cardTemplate: any;
  cards: any[] = [];
  privilegeCardForm: FormGroup;

  //pagination
  currentPageNumber: number = 1;
  pageSize: number = 10;
  expandedIndex: number = -1;
  totalItems: number = 0;
  count = 0;
  pageSizes = [10, 25, 50, 100];
  paginationInfo: any = '';

  //merchants
  merchantList: any;

  select2MerchantInfo: Array<Select2OptionData>;

  showIssuerFilter: boolean = false;
  constructor(private accountService: AccountServiceService, private dataService: DataService, private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private alertService: AlertService) {
    if (accountService.getLoggedInUserInfo().isSystemAdmin)
      this.showIssuerFilter = true;
    this.buildForm();
  }

  ngOnInit(): void {
    this.loadMerchant();
  }

  buildForm() {
    this.privilegeCardForm = this.formBuilder.group({
      merchantId: '',
      phone: '',
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['cardTemplateInput']) {
      setTimeout(() => {
        this.cardTemplate = this.cardTemplateInput;
        if (this.cardTemplate && this.cardTemplate.cardId != null && this.cardTemplate.cardId != undefined)
          this.getBeneficiaryByTemplate(true, this.cardTemplate.cardId, null);
      });
    }
  }

  onSubmit(reset: boolean) {
    let phone = this.privilegeCardForm.value.phone;
    let merchantId = -1;

    if (this.accountService.getLoggedInUserInfo().isSystemAdmin)
      merchantId = this.privilegeCardForm.value.merchantId;
    else merchantId = this.accountService.getLoggedInUserInfo().companyId;
    let obj = {
      "phone": phone,
      "merchantId": merchantId
    }
    this.getBeneficiaryByTemplate(reset, this.cardTemplate.cardId, obj);
  }

  download() {
    let phone = this.privilegeCardForm.value.phone;
    let merchantId = -1;

    if (this.accountService.getLoggedInUserInfo().isSystemAdmin)
      merchantId = this.privilegeCardForm.value.merchantId;
    else merchantId = this.accountService.getLoggedInUserInfo().companyId;
    let obj = {
      "golpCardId": this.cardTemplate.cardId,
      "issuerId": null,
      "customerPhoneNumber": null
    };
    if (merchantId > 0) {
      obj["issuerId"] = Number(merchantId);
    }

    if (phone != "") {
      obj["customerPhoneNumber"] = phone;
    }


    let url = "api/cards/export-privilege-card-details";

    this.dataService.postWithOptions(url, obj, {
      responseType: 'blob'
    }).subscribe((response: any) => {
      this.downloadFile(response);
    }, (err) => console.log(err));
  }

  downloadFile(data) {
    const blob2 = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var filename = "Privilege-Card-Assignment_" + moment().format('MMMM Do YYYY, h:mm:ss').valueOf() + ".xlsx";
    saveAs(blob2, filename);
  }

  getBeneficiaryByTemplate(reset: boolean = false, cardTemplateId: any, search: any) {
    let url = `api/cards/card-beneficiaries/${cardTemplateId}?pageNumber=${this.currentPageNumber}&pageSize=${this.pageSize}`

    if (search?.phone != undefined && search?.phone != null && search?.phone != "") {
      url += `&cardHolderPhone=${search.phone}`;
    }
    if (search?.merchantId != undefined && search?.merchantId != null && search?.merchantId > 0) {
      url += `&cardIssuerId=${search.merchantId}`;
    }

    if (search == null) {
      if (!this.accountService.getLoggedInUserInfo().isSystemAdmin) {
        let currentMerchant = this.accountService.getLoggedInUserInfo().companyId;
        url += `&cardIssuerId=${currentMerchant}`;
      }
    }

    this.dataService.get(url)
      .subscribe((response: any) => {

        if (response.success) {
          this.cards = response.data.beneficiaries;
          this.count = response.data.totalCount;
          if (reset) this.currentPageNumber = 1;
          this.getCurrentRange();
        }
        else {
          this.cards = [];
        }
      });
  }

  loadMerchant() {
    let url = '/api/company/offer-provider/basics';
    this.dataService.get(url).subscribe(
      (data: any) => {
        this.merchantList = data;
        this.select2MerchantInfo = this.merchantList.map((p) => {
          return {
            id: p.id,
            text: p.name,
          };
        });
        let noMerchant: Select2OptionData = {
          id: "-1",
          text: "Select Issuer",
        };
        this.select2MerchantInfo.unshift(noMerchant);
      },
      catchError(() => {
        return throwError('Something went wrong!');
      })
    );
  }

  addAssignment(cardTemplate: any) {
    const addAssignmentDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    addAssignmentDialogConfig.disableClose = true;
    addAssignmentDialogConfig.autoFocus = true;
    addAssignmentDialogConfig.panelClass = "golp-dialog";

    if (this.accountService.getLoggedInUserInfo().isSystemAdmin) {
      cardTemplate["merchants"] = this.merchantList;
      cardTemplate["select2Merchant"] = this.select2MerchantInfo;
    }
    addAssignmentDialogConfig.data = cardTemplate

    const createAssignmentDialog = this.dialog.open(NewCardAssignmentComponent, addAssignmentDialogConfig);
    createAssignmentDialog.componentInstance.onAddAssignmentEvent.subscribe((data) => {
      if (data != null && data != undefined) {
        this.getBeneficiaryByTemplate(true, this.cardTemplate.cardId, null);
      }
    });

    createAssignmentDialog.afterClosed().subscribe(() => { });
  }

  onDelete(item: any) {
    const confirmationDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    confirmationDialogConfig.data = `Are you sure to delete the number ${item.cardHolderPhone}?`;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        let url = "api/cards/delete-beneficiary";
        let payload = {
          "cardId": item.golpCardId,
          "beneficiaryId": item.id,
        }
        this.dataService.post(url, payload)
          .subscribe((response: any) => {
            if (response.success) {
              this.getBeneficiaryByTemplate(true, item.golpCardId, null);
              this.alertService.success(response.data);
            } else if (!response.success) {
              this.alertService.error(response.data);
            }
          });
      }
    });
  }

  onStatusChange(item: any) {
    if (!item.isActive) {
      this.activateCard(item);
    }
    else {
      this.deactivateCard(item);
    }
  }

  activateCard(item: any) {
    const activateDialog = new MatDialogConfig();

    // Setting different dialog configurations
    activateDialog.data = item;
    activateDialog.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ActivateCardComponent, activateDialog);

    confirmationDialog.afterClosed().subscribe((result: any) => {
      if (result != undefined) {
        let url = "api/cards/activate-beneficiary";
        let payload = {
          "activationFlag": !item.isActive,
          "cardId": item.golpCardId,
          "beneficiaryId": item.id,
          "receiver": result.receiver,
          'receiverEmail': result.email,
          "notifyReceiver": result.notifyReceiver
        }
        this.dataService.post(url, payload)
          .subscribe((response: any) => {
            if (response.success) {
              this.getBeneficiaryByTemplate(false, item.golpCardId, null);
              this.alertService.success(response.data);
            } else if (!response.success) {
              this.alertService.error(response.data);
            }
          });
      }
    });
  }

  deactivateCard(item: any) {
    const confirmationDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    let status = item.isActive ? "Deactivate" : "Activate";
    confirmationDialogConfig.data = `Are you sure to ${status.toLowerCase()} the number ${item.cardHolderPhone}?`;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        let url = "api/cards/activate-beneficiary";
        let payload = {
          "activationFlag": !item.isActive,
          "cardId": item.golpCardId,
          "beneficiaryId": item.id,
        }
        this.dataService.post(url, payload)
          .subscribe((response: any) => {
            if (response.success) {
              this.getBeneficiaryByTemplate(false, item.golpCardId, null);
              this.alertService.success(response.data);
            } else if (!response.success) {
              this.alertService.error(response.data);
            }
          });
      }
    });
  }


  handlePageChange(event: number): void {
    this.currentPageNumber = event;
    this.getBeneficiaryByTemplate(false, this.cardTemplate.cardId, null);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.currentPageNumber = 1;
    this.getBeneficiaryByTemplate(false, this.cardTemplate.cardId, null);
  }

  getCurrentRange() {
    this.paginationInfo = `Showing ${((this.currentPageNumber - 1) * this.pageSize) + 1}-${((this.currentPageNumber - 1) * this.pageSize) + this.cards?.length} of ${this.count}`
  }

}



