import { Component, Injector, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DataService } from 'src/app/components/app-send-gift/services/shared/data.service';
import { BaseComponent } from 'src/app/components/base/base.component';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { AccountServiceService, AlertService } from 'src/app/services';
import { AddCardIssuerComponent } from './add-card-issuer/add-card-issuer.component';

@Component({
  selector: 'app-privilege-card-issuer',
  templateUrl: './privilege-card-issuer.component.html',
  styleUrls: ['./privilege-card-issuer.component.css']
})
export class PrivilegeCardIssuerComponent extends BaseComponent implements OnInit {

  @Input() privilegeCardInput: any;

  privilegeCard: any;
  issuers: any[] = [];
  canOperate: boolean = false;

  constructor(private dataService: DataService, private accountService: AccountServiceService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private injector: Injector) {
    super(injector)
    this.canOperateCurrentUser();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['privilegeCardInput']) {
      setTimeout(() => {
        this.privilegeCard = this.privilegeCardInput;
        if (this.privilegeCard && this.privilegeCard.cardId)
          this.getIssuersByCardId(this.privilegeCard.cardId);
      });
    }
  }

  canOperateCurrentUser() {
    this.canOperate = this.accountService.getLoggedInUserInfo().isSystemAdmin;
  }

  getIssuersByCardId(privilegeCardId: any) {
    let url = `api/cards/card-issuers?golpCardId=${privilegeCardId}`
    this.dataService.get(url)
      .subscribe((response: any) => {

        if (response.success) {
          this.issuers = response.data?.cardIssuers;
        }
      });
  }

  addIssuer(privilegeCard: any) {
    const addIssuerDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    addIssuerDialogConfig.disableClose = true;
    addIssuerDialogConfig.autoFocus = true;
    addIssuerDialogConfig.panelClass = "golp-dialog";
    let data = {
      "privilegeCard": privilegeCard,
      "issuer": null
    }
    addIssuerDialogConfig.data = data

    const addIssuerDialog = this.dialog.open(AddCardIssuerComponent, addIssuerDialogConfig);

    const successfullCreate = addIssuerDialog.componentInstance.onAddIssuerEvent.subscribe((data) => {
      this.getIssuersByCardId(data);
    });

    addIssuerDialog.afterClosed().subscribe(() => {
    });
  }


  toggleStatus(item: any) {
    const confirmationDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    let status = item.isActive ? "deactivate" : "activate"
    confirmationDialogConfig.data = `Are you sure to ${status} the issuer?`;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);
    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        let payload = {
          "issuerId": item.issuerId,
          "cardId": item.golpCardId,
          "activationFlag": !item.isActive
        }
        this.dataService.post(`api/cards/activate-card-issuers`, payload)
          .subscribe((response: any) => {
            if (response.success) {
              this.getIssuersByCardId(item.golpCardId);
              this.alertService.success(response.data);
            } else if (!response.success) {
              this.alertService.error(response.data);
            }
          });
      }
    });
  }
}
