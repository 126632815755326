import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-consumer-payment-status',
  templateUrl: './consumer-payment-status.component.html',
  styleUrls: ['./consumer-payment-status.component.css']
})
export class ConsumerPaymentStatusComponent implements OnInit {

  routeParams: any;
  status: boolean = true;

  constructor(private router: ActivatedRoute, private route: Router) { }

  ngOnInit() {
    this.routeParams = this.router.params.subscribe(params => {
      let param = params['status'];
      this.status = JSON.parse(param);
      if (this.status == true) {
        this.route.navigate(['/send-gifts']);
      }
    });
  }

}
