import { Component, OnInit, Inject, SimpleChanges, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, DiscountHoldersService } from 'src/app/services';

@Component({
  selector: 'app-upload-discount-holder',
  templateUrl: './upload-discount-holder.component.html',
  styleUrls: ['./upload-discount-holder.component.css']
})
export class UploadDiscountHolderComponent implements OnInit {

  // onCreate event
  onUploadDiscountHolderEvent: EventEmitter<number> = new EventEmitter();

  uploadDiscountHolderForm: FormGroup;
  submitted = false;
  discountHolderData: FormData;
  discountHolderFiles: FormData;

  offerProviderId: number;

  fileValidationError: string;
  fileName: string = '';
  imagePreviewPath: string = '../../../assets/images/csv-default.png';

  constructor(private formBuilder: FormBuilder,
    private alertService: AlertService,
    private discountHolderService: DiscountHoldersService,
    private dialogRef: MatDialogRef<UploadDiscountHolderComponent>,
    @Inject(MAT_DIALOG_DATA) data) { 
      if(data !== null){
        this.offerProviderId = data.id;
      }

      this.uploadDiscountHolderForm = this.formBuilder.group({
        phoneNumbers: ''
      });

    }

  ngOnInit() {
  }

  onUploadFile(files){
    this.fileValidationError = null;
    if (files.length === 0)
      return;

    const formData = new FormData();

    if(files[0].size > (1024*1024)){
      this.fileValidationError = "Maximum file size should be 1 MB";
      return;
    }

    if(files[0].type != 'application/vnd.ms-excel'){
      this.fileValidationError = "Only CSV file allowed";
      return;
    }

    for (let file of files){
      formData.append(file.name, file);
      this.fileName += ' '+ file.name;
    }
    this.discountHolderFiles = formData;
  }

  onSubmit(){
    this.submitted = true;

    // stop here if form is invalid
    if (this.uploadDiscountHolderForm.invalid) {
       return;
    }


    this.discountHolderData = new FormData();
    if(this.discountHolderFiles != null){
      this.discountHolderData = this.discountHolderFiles;
    }

    this.discountHolderData.append('offerProviderId', this.offerProviderId.toString());
    this.discountHolderData.append('phoneNumbers', this.uploadDiscountHolderForm.controls['phoneNumbers'].value);

    this.discountHolderService.uploadDiscountHolder(this.discountHolderData)
        .subscribe((data: any) => {
          this.onUploadDiscountHolderEvent.emit(this.offerProviderId);
          this.alertService.success("Offer holder uploaded successfully");
          console.log(data);
          this.dialogRef.close();
        },
        (error: any) => {
          console.log(error);
          if(error.status == '406'){
            this.alertService.error(error.error.error[0]);
            this.discountHolderData.delete("offerProviderId");
          }
          else{
            //this.alertService.error("Internal server error happen");
          }
        });
  }

  // convenience getter for easy access to form fields
  get f() { return this.uploadDiscountHolderForm.controls; }

  close() {
    this.dialogRef.close();
  }

  openFileBrowser(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById('discountHolderFileId') as HTMLElement;
    element.click();
  }


}
