import { Component, OnInit } from '@angular/core';
import { UserModel } from 'src/app/models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountServiceService, AlertService } from 'src/app/services';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.css']
})
export class AccountSettingsComponent implements OnInit {

  userInfo: UserModel;
  userRole: string;
  accountSettingsForm: FormGroup;
  submitted = false;
  errorMessage: string;

  constructor(private accountService: AccountServiceService,
    private formBuilder: FormBuilder,
    private alertService: AlertService
  ) {
    this.getLoggedInUser();

    this.accountSettingsForm = this.formBuilder.group({
      email: [{ value: this.userInfo.email, disabled: true }, Validators.required],
      password: ['', [Validators.required]],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });
    if (this.userRole == "ServiceConsumer" ) {
      this.accountSettingsForm.controls['password'].clearValidators();
      this.accountSettingsForm.controls['password'].updateValueAndValidity();
    }
  }

  ngOnInit() {
  }

  onSubmit() {
    this.submitted = true;
    const formData = new FormData();
    // stop here if form is invalid
    if (this.accountSettingsForm.invalid) {
      return;
    }
    let newData = new UserModel(this.accountSettingsForm.value);
    if (this.userInfo !== null && newData.newPassword === newData.confirmPassword) {
      this.userInfo.password = newData.password;
      this.userInfo.newPassword = newData.newPassword;

      this.accountService.updateAccountSettings(this.userInfo)
        .subscribe((data: any) => {
          this.alertService.success("Password updated successfully");
          this.resetField();
          this.errorMessage = null;
        },
          (error: any) => {
            this.errorMessage = "Current password does not match"
            this.resetField();
          })
    }
    else if (this.userInfo !== null && newData.newPassword !== newData.confirmPassword) {
      this.errorMessage = "New password and confirm password does not match"
      this.resetField();
    }
  }

  getLoggedInUser() {
    let userInfo = this.accountService.getLoggedInUserInfo();
    this.userInfo = userInfo;
    this.userRole = userInfo.roles[0].name;
  }

  resetField() {
    this.accountSettingsForm.controls['password'].reset();
    this.accountSettingsForm.controls['newPassword'].reset();
    this.accountSettingsForm.controls['confirmPassword'].reset();
  }

  // convenience getter for easy access to form fields
  get f() { return this.accountSettingsForm.controls; }

}
