import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Workbook } from 'exceljs';
import * as moment from 'moment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CorporateOfferService } from 'src/app/corporate-offers/services/corporate-offer.service';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import { MerchantOutletResponse } from '../../../models/view-models/merchant-outlets-response';
import { DataService } from '../../../services/shared/data.service';
import * as fs from 'file-saver';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-gift-transaction-report',
  templateUrl: './gift-transaction-report.component.html',
  styleUrls: ['./gift-transaction-report.component.css']
})
export class GiftTransactionReportComponent implements OnInit {

  @BlockUI('offer-report-section') blockUI: NgBlockUI;

  offerReportForm: FormGroup;

  currentPage: number = 1;
  pageSize: number = 25;
  expandedIndex: number = -1;
  totalItems: number = 0;

  defaultStartDate: Date = new Date();
  defaultEndDate: Date = new Date();
  paginationPageNumbers: any[];
  currentPageNumberDetails: string;
  submitted: boolean;
  companyOutletId: any;

  userInfo: any;
  reportSummary: any[];

  soldGift: number = 0;
  soldGiftAmount: number = 0;
  redeemedGift: number = 0;
  redeemedGiftAmount: number = 0;
  p: number = 1;
  merchantOutlets:any;
  constructor(private formBuilder: FormBuilder,
    private corporateOfferService: CorporateOfferService, private dataService: DataService) {
    var startDate1 = new Date();
    //startDate1.setDate(startDate1.getDate() - 7);
    this.defaultStartDate = this.startOfMonth(startDate1);
    this.defaultEndDate = new Date();
    this.buildForm();
  }

  ngOnInit(): void {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userInfo = userInfo;
    if(this.userInfo.outletQrCode == undefined || this.userInfo.outletQrCode == null) 
      this.getMerchantOutlet();
    if(this.userInfo.outletQrCode != undefined && this.userInfo.outletQrCode != null)
      this.companyOutletId = this.userInfo.outletQrCode;
    this.getOfferReportData();
  }



  buildForm() {
    this.offerReportForm = this.formBuilder.group({
      fromDate: [this.defaultStartDate, Validators.required],
      toDate: [this.defaultEndDate, Validators.required],
      outletId: ['', Validators.nullValidator],
      hasAvailed: ['', Validators.nullValidator],
      receiverPhoneNumber: '',
    });
  }
  startOfMonth(date)
  {
    return new Date(date.getFullYear(), date.getMonth(), 1); 
  }
  exportToExcel() {

  }

  getOfferReportData() {

    this.submitted = true;
    // stop here if form is invalid
    if (this.offerReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    let fromDate = moment(this.f.fromDate.value).format("YYYY/MM/DD");
    let todate = moment(this.f.toDate.value).format("YYYY/MM/DD");
    let outletId = this.f.outletId.value;
    let hasAvailedForm = this.f.hasAvailed.value
    let hasAvailed = null;
    if(hasAvailedForm == 1) {
      hasAvailed = true;
    }
    else if(hasAvailedForm == 2) {
      hasAvailed = false;
    }
    else hasAvailed = null;

    if(outletId != 0)
      this.companyOutletId = outletId.qrCode;
    let url = `api/giftCard/sell-gift-card-reports/merchant/${this.userInfo.companyId}?fromDate=${fromDate}&toDate=${todate}`;
    if(this.userInfo.outletQrCode != null || this.userInfo.outletQrCode != undefined || outletId != 0)
      url += `&outletId=${this.companyOutletId}`;
    if(hasAvailed != null)
      url += `&hasAvailed=${hasAvailed}`;


    this.dataService.get(url)
      .subscribe((response: SendGiftCradReportResponse) => {
        
        if (response.success) {
          let summary = response.summary;
          this.reportSummary = response.data;
          console.log(this.reportSummary);
          if (summary != null) {
            this.soldGift = summary.giftCardSold;
            this.soldGiftAmount = summary.amountSold;
            this.redeemedGift = summary.giftCardRedeemed;
            this.redeemedGiftAmount = summary.amountRedeemed;
          }

          // console.log("Response here : ", this.soldGift, " ", this.soldGiftAmount);
        }
      });
  }

  expandCollapse($index) {

  }

  exportXLReportFromServer() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.offerReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    let fromDate = moment(this.f.fromDate.value).format("YYYY/MM/DD");
    let todate = moment(this.f.toDate.value).format("YYYY/MM/DD");
    let outletId = this.f.outletId.value;
    let hasAvailedForm = this.f.hasAvailed.value
    let hasAvailed = null;
    if(hasAvailedForm == 1) {
      hasAvailed = true;
    }
    else if(hasAvailedForm == 2) {
      hasAvailed = false;
    }
    else hasAvailed = null;

    if(outletId != 0)
      this.companyOutletId = outletId.qrCode;
    let url = `api/report/sell-gift-card-reports/merchant/${this.userInfo.companyId}`;

    let payload = {
      "fromDate": fromDate,
      "toDate": todate,
      "outletId": this.companyOutletId,
      "hasAvailed": hasAvailed
    }

    this.dataService.postWithOptions(url, payload, {
      responseType: 'blob'
    })
      .subscribe((response: any) => {
       this.downloadFile(response);
      }, err=>{console.log("Error Occurred")}, ()=>{console.log("Download Finished")});
  }

  downloadFile(data) {
    const blob2 = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var filename = "Gift-Card-Sell-Report-" + moment().format('MMMM Do YYYY, h:mm:ss').valueOf() + ".xlsx";
    saveAs(blob2, filename);
  }

  exportXLReportLocal() {
    let workbook = new Workbook();
    //add name to sheet
    let worksheet = workbook.addWorksheet("TransactionReport");
    let header=["Gift Sender","Gift Receiver", "Gift Price", "Has Availed", "Issuance Outlet", "Availed Outlet", "Valid From", "Valid Till"];
    let headerRow = worksheet.addRow(header);
    for (let x1 of this.reportSummary)
      {
        let temp=[]
        temp.push(x1["giftSenderPhone"]);
        temp.push(x1["giftReceiver"]);
        temp.push(x1["giftPrice"]);
        temp.push(x1["hasAvailed"]? "YES" : "NO");
        temp.push(x1["issuanceOutlet"]);//
        temp.push(x1["redemptionOutlet"]);//redemptionOutlet
        let validDateFrom = new Date(x1["validFrom"]).toLocaleString();
        temp.push(validDateFrom);
        let validDateTill = new Date(x1["validTill"]).toLocaleString();
        temp.push(validDateTill);

        worksheet.addRow(temp)
      }

      let fname="Transaction Report"

//add data and file name and download
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, fname+'-'+new Date().valueOf()+'.xlsx');
      });
  }

  get f() { return this.offerReportForm.controls; }

  getMerchantOutlet() {
    let url = `api/Outlets/company/${this.userInfo.companyId}/`;
    // if(this.companyOutletId != null)
    //   url += `${this.companyOutletId}`
    this.dataService.get(url).subscribe((data: MerchantOutletResponse[]) => {
      if(data != null && data.length > 0){
        this.merchantOutlets = data;
        console.log( this.merchantOutlets);
      }
    });
  }

}
export interface SendGiftCradReportResponse {
  success: boolean;
  data:    SendGiftCradReportData[];
  summary: Summary;
}

export interface SendGiftCradReportData {
  orderId:         string;
  giftSenderPhone: string;
  giftReceiver:    string;
  cardIssuer:      string;
  issuanceOutlet:  null;
  giftPrice:       number;
  validFrom:       Date;
  validTill:       Date;
  cardIssueDate:   Date;
  orderNo:         string;
  hasAvailed:      boolean;
}

export interface Summary {
  giftCardSold:     number;
  amountSold:       number;
  giftCardRedeemed: number;
  amountRedeemed:   number;
}
