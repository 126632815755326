import { ReplaySubject } from 'rxjs';
import { Company, Promotion } from '../../models';
import { CompanyService, PromotionService, AlertService } from '../../services';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValidationUtility } from '../../utility/validation.utility';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { Inject, OnInit, EventEmitter, Component } from '@angular/core';

@Component({
  selector: 'app-create-promotion',
  templateUrl: './create-promotion.component.html',
  styleUrls: ['./create-promotion.component.css']
})

export class CreatePromotionComponent implements OnInit {

  // onCreate event
  onPromotionCreateEvent: EventEmitter<Company> = new EventEmitter();
  // onEdit event;
  onPromotionEditEvent: EventEmitter<Company> = new EventEmitter();
  inEditMode: boolean = false;
  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreatePromotionComponent>,
    private companyService: CompanyService,
    @Inject(MAT_DIALOG_DATA) data,
    private alertService: AlertService,
    private promotionService: PromotionService) {
    this.promotion = new Promotion();
    if (data !== null) {
      this.promotion = new Promotion(data);
      this.inEditMode = true;
    }
    this.buildForm();
  }


  promotionForm: FormGroup;
  promotion: Promotion;

  submitted = false;

  promotions: any;

  merchants: Company[];

  promotionsFilterCtrl: FormControl = new FormControl();
  filteredPromotions: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);

  ngOnInit() {

    this.getMerchants();
    this.bindFilterChangeEvent();
  }

  ngOnChanges() {
  }

  buildForm() {
    this.promotionForm = this.formBuilder.group({
      //merchantId: [this.promotion.merchantId, Validators.required],
      //orderNo: [this.promotion.orderNo, [Validators.min(1)]],
      //isActive: [this.promotion.isActive]
    });
  }

  bindFilterChangeEvent() {
    this.promotionsFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterData(this.merchants, this.promotionsFilterCtrl.value, this.filteredPromotions);
      });
  }

  filterData(allData: any, search: string, filterData: any) {
    if (!allData) {
      return;
    }
    if (!search) {
      filterData.next(allData);
      return;
    } else {
      search = search.toLowerCase();
    }
    filterData.next(
      allData.filter(data => data.name.toLowerCase().indexOf(search) > -1)
    );
  }

  getMerchants() {
    this.companyService.getAllMerchants()
      .subscribe(data => {
        this.merchants = data;
        this.filteredPromotions.next(this.merchants);
      });
  }


  get f() { return this.promotionForm.controls; }

  close() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.promotionForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    if (this.promotion.id === undefined) { // create
      this.createPromotion();
    }
    else if (this.promotion.id > 0) { // edit
      this.editPromotion();
    }
    this.dialogRef.close();
  }


  createPromotion() {
    this.promotion = new Promotion(this.promotionForm.value);

    this.promotionService.createPromotion(this.promotion)
      .subscribe((data: any) => {
        this.onPromotionCreateEvent.emit(data);
        this.alertService.success("Promotion added successfully");
      },
        (error: any) => {

        })
  }

  editPromotion() {
    let newData = new Promotion(this.promotionForm.value);
    if (this.promotion !== null) {
      this.promotion.isActive = newData.isActive;
      
      this.promotionService.editPromotion(this.promotion)
        .subscribe((data: any) => {
          this.onPromotionEditEvent.emit(data);
          this.alertService.success("Promotion edited successfully");
        },
          (error: any) => {
            this.showErrorMessage(error);
          });
    }
  }


  showErrorMessage(error: any) {
    console.log(error);
    //this.alertService.error("Internal server error happen");
  }
}
