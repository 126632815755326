import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GetGiftGenerationReportInputDto } from '../models/gift-generation-report-input';
import { PaymentDueReportDto } from '../models/payment-due-report-input';
import { UserRegistrationReportInput } from '../models/user-registration-report-input';
import { NewRegisteredUniqueDeviceCountInput, CreditUsageReportInputDto, GetGiftCustomerSupportInputDto, IndividualGiftReportInput } from '../models';
import { MerchantDueReportInputDto } from '../models/merchant-due-report-input';



@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private baseUri = 'api/report';

  constructor(
    private http: HttpClient
  ) { }

  //Observable<GiftGenerationReport[]>
  getGiftGenerationReportQuery(input: GetGiftGenerationReportInputDto): any {

    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.baseUri + '/giftGenerations', input, requestHeader);
  }

  getGiftGenerationExcelReport(input: GetGiftGenerationReportInputDto): any {
  return this.http.post(this.baseUri + '/gifts/excelReport', input, {
      // headers: new HttpHeaders({'Content-Type': 'application/json'}),
      responseType: 'blob'
    });
  }

  getConsumerGiftGenerationExcelReport(input: GetGiftGenerationReportInputDto): any {

    return this.http.post(this.baseUri + '/consumer-gifts/excelReport', input, {
      // headers: new HttpHeaders({'Content-Type': 'application/json'}),
      responseType: 'blob'
    });
  }

  getCorporateGiftGenerationExcelReport(input: GetGiftGenerationReportInputDto): any {

    return this.http.post(this.baseUri + '/corporate-gifts/excelReport', input, {
      // headers: new HttpHeaders({'Content-Type': 'application/json'}),
      responseType: 'blob'
    });
  }

  getConsumerGiftTransactionReportQuery(input: GetGiftGenerationReportInputDto): any {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.baseUri + '/consumer-gift', input, requestHeader);
  }

  getCorporateGiftTransactionReportQuery(input: GetGiftGenerationReportInputDto): any {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.baseUri + '/corporate-gift', input, requestHeader);
  }


  getPromotionalGiftTransactionReportQuery(input: GetGiftGenerationReportInputDto): any {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.baseUri + '/promotional-gift', input, requestHeader);
  }


  getIndividualGiftReportQuery(input: IndividualGiftReportInput): any {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.baseUri + '/individual-gift', input, requestHeader);
  }


  getPromotionalGiftGenerationExcelReport(input: GetGiftGenerationReportInputDto): any {
    return this.http.post(this.baseUri + '/promotional-gifts/excelReport', input, {
      responseType: 'blob'
    });
  }

  getIndividualGiftExcelReport(input: IndividualGiftReportInput): any {
    return this.http.post(this.baseUri + '/individual-gifts/excelReport', input, {
      responseType: 'blob'
    });
  }

  getGiftCustomerSupportReport(input: GetGiftCustomerSupportInputDto): any {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.baseUri + '/gift/customer-support', input, requestHeader);
  }

  getDuePaymentExcelReport(input: PaymentDueReportDto): any {
    return this.http.post(this.baseUri + '/payment/due/excelReport', input, {
      responseType: 'blob'
    });
  }


  getPaymentDueReport(input: PaymentDueReportDto): any {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.baseUri + '/paymentDueReport', input, requestHeader);
  }

  getMerchantDueReport(input: PaymentDueReportDto): any {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.baseUri + '/merchantDueReport', input, requestHeader);
  }

  getMerchantDueExcelReport(input: MerchantDueReportInputDto): any {
    return this.http.post(this.baseUri + '/merchant/due/excelReport', input, {
      responseType: 'blob'
    });
  }


  getMerchantDueSummaryReport(input: PaymentDueReportDto): any {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.baseUri + '/merchantDueReport/summary', input, requestHeader);
  }


  getUserRegistrationReport(input: UserRegistrationReportInput): any {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.baseUri + '/userRegistrationReport', input, requestHeader);
  }

  getNewUniqueDeviceCountReport(input: NewRegisteredUniqueDeviceCountInput): any {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.baseUri + '/getNewRegisteredUniqueDeviceCount', input, requestHeader);
  }


  getUserRegistrationSummaryReport(): any {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.baseUri + '/userRegistrationSummaryReport', requestHeader);
  }

  getPremiumUserCreditUsageSummaryReport(input: CreditUsageReportInputDto): any {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.baseUri + '/premium/credit-usage/summary', input, requestHeader);
  }

  getPremiumUserCreditUsageSummaryExcelReport(input: CreditUsageReportInputDto): any {
    return this.http.post(this.baseUri + '/premium/credit-usage/summary/excelReport', input, {
      responseType: 'blob'
    });
  }


  getPremiumUserCreditLimitExceededSummaryReport(input: CreditUsageReportInputDto): any {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.baseUri + '/premium/credit-exceed/summary', input,requestHeader);
  }
}
