import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-activate-card',
  templateUrl: './activate-card.component.html',
  styleUrls: ['./activate-card.component.css']
})
export class ActivateCardComponent implements OnInit {

  activateForm: FormGroup;
  submitted: boolean = false;
  notifyReceiver: boolean = false;
  receiver: any;
  email: any;

  constructor(private dialogRef: MatDialogRef<ActivateCardComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data) {

    if (data !== null) {
      this.receiver = data.cardHolderPhone;
      this.email = data.cardHolderEmail;
    }
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.activateForm = this.formBuilder.group({
      receiver: [this.receiver, [Validators.required]],
      email: [this.email],
      notifyReceiver: this.notifyReceiver,
    });
  }

  confirm() {
    this.submitted = true;
    if (this.activateForm.invalid) return;

    let successModel = {
      'receiver': this.activateForm.get('receiver').value,
      'email': this.activateForm.get('email').value,
      'notifyReceiver': this.activateForm.get('notifyReceiver').value
    };
    this.dialogRef.close(successModel);
  }

  close() {
    this.dialogRef.close();
  }

  get f() {
    return this.activateForm.controls;
  }

}
