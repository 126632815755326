import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../services/report.service';

@Component({
  selector: 'app-user-registration-report-summary',
  templateUrl: './user-registration-report-summary.component.html',
  styleUrls: ['./user-registration-report-summary.component.css']
})
export class UserRegistrationReportSummaryComponent implements OnInit {

  totalUsersOnToday = 0;
  totalUsersOnYesterDay = 0;
  totalUsersOnLast7Days = 0;
  totalUsersOnLast30Days = 0;

  constructor(private reportService: ReportService) { }

  ngOnInit() {
    this.getUserRegistrationSummaryReport();

  }

  getUserRegistrationSummaryReport() {
      this.reportService.getUserRegistrationSummaryReport().subscribe(result => {
      this.totalUsersOnToday = result.totalUsersOnToday;
      this.totalUsersOnYesterDay = result.totalUsersOnYesterDay;
      this.totalUsersOnLast7Days = result.totalUsersOnLast7Days;
      this.totalUsersOnLast30Days = result.totalUsersOnLast30Days;
    });
  }

}
