import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TrackOrderService {

  private baseUri = 'api/track-order';

  constructor(
    private http: HttpClient
  ) { }

  getOrderRecords(search: any): any {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(`${this.baseUri}/records`, search, requestHeader);
  }

  processOrderRecords(transactionData: any): any {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(`${this.baseUri}/process-next-step`, transactionData, requestHeader);
  }

}
