import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { PromotionalGiftSetting, Company } from '../../../models';
import { ReplaySubject } from 'rxjs';
import { CompanyService } from '../../../services';

@Component({
  selector: 'app-create-promotional-gift-setting',
  templateUrl: './create-promotional-gift-setting.component.html',
  styleUrls: ['./create-promotional-gift-setting.component.css']
})

export class CreatePromotionalGiftSettingComponent implements OnInit {
  promotionalGiftSlabs: any;

  @Output() promotionalGiftEmitter: EventEmitter<any> = new EventEmitter<any>();


  constructor(private formBuilder: FormBuilder, private companyService: CompanyService) {
    this.buildForm();
  }

  promotionalGiftSettingForm: FormGroup;
  @Input() isSubmitted: false;

  submitted = false;

  @Input() existingPromotionalGiftSetting: any;
  @Input() showSlabSectionInput: boolean = true;
  showSlabSection: boolean = true;

  promotionalGiftSetting: PromotionalGiftSetting = new PromotionalGiftSetting();
  eligibleMerchants: any;
  giftAmount: number;
  type: number;
  validity: number;
  maxGiftAmount: number;
  minGiftSendAmount: number;
  giftSenderId: string;

  merchants: Company[];
  offerProviders: Company[];

  promotionalGiftTypes: any;
  isGiftBasedOnSalesCommission: boolean = false;

  eligibleMerchantsFilterCtrl: FormControl = new FormControl();
  filteredEligibleMerchants: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);

  offerProviderFilterCtrl: FormControl = new FormControl();
  filteredOfferProviders: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);

  ngOnInit() {
    if (this.existingPromotionalGiftSetting) {
      this.promotionalGiftSetting = JSON.parse(this.existingPromotionalGiftSetting); // Need to convert json to this type;

      this.eligibleMerchants = this.promotionalGiftSetting.eligibleMerchants;
      this.giftAmount = this.promotionalGiftSetting.giftAmount;
      this.type = this.promotionalGiftSetting.promotionalGiftType;
      this.maxGiftAmount = this.promotionalGiftSetting.maxGiftAmount;
      this.minGiftSendAmount = this.promotionalGiftSetting.minGiftSendAmount;
      this.giftSenderId = this.promotionalGiftSetting.giftSenderId;
      this.validity = this.promotionalGiftSetting.validity;

      this.promotionalGiftSettingForm.patchValue({
        eligibleMerchants: this.promotionalGiftSetting.eligibleMerchants,
        giftAmount: this.promotionalGiftSetting.giftAmount,
        validity: this.promotionalGiftSetting.validity,
        type: this.promotionalGiftSetting.promotionalGiftType,
        maxGiftAmount: this.promotionalGiftSetting.maxGiftAmount,
        minGiftSendAmount: this.promotionalGiftSetting.minGiftSendAmount,
        giftSenderId: this.promotionalGiftSetting.giftSenderId,
      });
    }
    else {
      this.promotionalGiftSetting = new PromotionalGiftSetting();
    }

    this.showSlabSection = this.showSlabSectionInput;

    this.promotionalGiftTypes = [{ text: 'Fixed', value: 0 }, { text: 'Based On Sales Commission', value: 1 }];

    this.getMerchants();
    this.getOfferProviders();

    this.bindFilterChangeEvent();

    this.setPromotionalGiftAndEmit();
  }

  ngOnChanges() {
    this.submitted = this.isSubmitted;

  }

  buildForm() {
    this.promotionalGiftSettingForm = this.formBuilder.group({
      eligibleMerchants: [this.promotionalGiftSetting.eligibleMerchants, Validators.required],
      giftAmount: [this.promotionalGiftSetting.giftAmount, Validators.required],
      validity: [this.promotionalGiftSetting.validity, Validators.required],
      type: [this.promotionalGiftSetting.promotionalGiftType, Validators.required],
      maxGiftAmount: this.promotionalGiftSetting.maxGiftAmount,
      minGiftSendAmount: [this.promotionalGiftSetting.minGiftSendAmount, Validators.required],
      giftSenderId: [this.promotionalGiftSetting.giftSenderId, Validators.required]
    });
  }

  bindFilterChangeEvent() {
    this.eligibleMerchantsFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterData(this.merchants, this.eligibleMerchantsFilterCtrl.value, this.filteredEligibleMerchants);
      });

    this.offerProviderFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterData(this.offerProviders, this.offerProviderFilterCtrl.value, this.filteredOfferProviders);
      });
  }

  filterData(allData: any, search: string, filterData: any) {
    if (!allData) {
      return;
    }
    if (!search) {
      filterData.next(allData);
      return;
    } else {
      search = search.toLowerCase();
    }
    filterData.next(
      allData.filter(data => data.name.toLowerCase().indexOf(search) > -1)
    );
  }

  getMerchants() {
    this.companyService.getMerchants()
      .subscribe(data => {
        this.merchants = data;
        this.filteredEligibleMerchants.next(this.merchants);
      });
  }

  getOfferProviders() {
    this.companyService.getOfferProviders()
      .subscribe(data => {
        this.offerProviders = data;
        this.filteredOfferProviders.next(this.offerProviders);

      });

  }

  onChangeMerchant($event) {
    this.setPromotionalGiftAndEmit();
  }

  onChangeOfferProvider($event) {
    this.setPromotionalGiftAndEmit();
  }

  onChangePromotionalGiftType($event) {
    //if (this.f.type.value == 1) {
    //  this.isGiftBasedOnSalesCommission = true;
    //}
    //else {
    //  this.isGiftBasedOnSalesCommission = false;
    //}
    this.setPromotionalGiftAndEmit();
  }

  //onChanges(): void {
  //  this.promotionalGiftSettingForm.get('eligibleMerchants').valueChanges.subscribe(val => {
  //    this.setPromotionalGiftAndEmit();
  //  });
  //}

  get f() { return this.promotionalGiftSettingForm.controls; }

  setSlabValuesBypromotionalGift($event) {
    this.promotionalGiftSlabs = $event;
    this.setPromotionalGiftAndEmit();
  }


  setPromotionalGift() {
    this.promotionalGiftSetting.eligibleMerchants = this.f.eligibleMerchants.value;
    this.promotionalGiftSetting.giftAmount = this.f.giftAmount.value;
    this.promotionalGiftSetting.promotionalGiftType = this.f.type.value;
    this.promotionalGiftSetting.maxGiftAmount = this.f.maxGiftAmount.value;
    this.promotionalGiftSetting.minGiftSendAmount = this.f.minGiftSendAmount.value;
    this.promotionalGiftSetting.giftSenderId = this.f.giftSenderId.value;
    this.promotionalGiftSetting.validity = this.f.validity.value;
  }


  setPromotionalGiftAndEmit() {
    this.setPromotionalGift();
    this.promotionalGiftEmitter.emit(this.promotionalGiftSetting);
  }


}
