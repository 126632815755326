import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-privilege-card-facilities',
  templateUrl: './user-privilege-card-facilities.component.html',
  styleUrls: ['./user-privilege-card-facilities.component.css']
})
export class UserPrivilegeCardFacilitiesComponent implements OnInit {

  privilegeCard: any;
  cardFacilities: any[] = [];
  cardName: string;
  cardIssuer: string;


  constructor(private router: Router) {
    let navigation = this.router.getCurrentNavigation();
    let state = navigation.extras.state as {
      transId: string,
      workQueue: boolean,
      services: number,
      code: any
    };

    if (state && state.code != undefined && state.code != null) {
      this.cardFacilities = state.code.cardFacilities;
      this.cardName = state.code.cardDetails.cardName;
      this.cardIssuer = state.code.cardDetails.cardIssuer
    }
  }

  ngOnInit(): void {
  }



  toggleInfo(index) {
    this.cardFacilities[index].show = !this.cardFacilities[index].show;
  }

  setValueType(input) {
    if (input === "Fixed Amount") {
      return "Fixed Amount (BDT)"
    }
    return input;
  }
}
