import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DiscountHoldersService {

  private discountHoldersBaseUrl = 'api/discountholders';

  constructor(private http: HttpClient) { }

  uploadDiscountHolder(discountHolderData: FormData){
    var requestHeader = {headers: new HttpHeaders({ 'No-Auth':'False' })};
    return this.http.post(this.discountHoldersBaseUrl + '/upload', discountHolderData, requestHeader);
  }

  getDiscountHolders(offerorId:number, pageNumber: number, pageSize: number): Observable<any[]>{
    var requestHeader = {headers: new HttpHeaders({ 'No-Auth':'False' })};
    return this.http.get<any[]>(this.discountHoldersBaseUrl + '/offeror/' + offerorId + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize, requestHeader);
  }

  deleteDiscountHolder(id: any){
    var requestHeader = {headers: new HttpHeaders({ 'No-Auth':'False' })};
    return this.http.delete(this.discountHoldersBaseUrl + '/' + id, requestHeader);
  }
}
