export class AssignGiftTemplateInput {
  phoneNumber: string;
  soldDate: any;
  serialNo?: string;
  giftTemplateAssociationIds: any[] = [];

  public constructor(init?: Partial<AssignGiftTemplateInput>) {
    Object.assign(this, init);
  }
}
