import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, Injector } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { CreateMerchantComponent } from '../create-merchant/create-merchant.component';
import { BaseComponent } from '../base/base.component';
import { CompanyService, AlertService } from 'src/app/services';
import { CreateTrendingMerchantComponent } from '../create-trending-merchant/create-trending-merchant.component';
import { TrendingMerchant } from '../../models';

@Component({
  selector: 'app-trending-merchant-details',
  templateUrl: './trending-merchant-details.component.html',
  styleUrls: ['./trending-merchant-details.component.css']
})

export class TrendingMerchantDetailsComponent extends BaseComponent implements OnInit {

  @Input() merchantDetailsInput: TrendingMerchant;
  @Output() merchantDetailsInputChange: EventEmitter<TrendingMerchant> = new EventEmitter<TrendingMerchant>();

  merchantDetails: TrendingMerchant
  merchantLogoOnErr: String
  merchantLogoTxtOnErr = false;

  detailsLoaded: Promise<boolean>;
  showEnableButton: boolean = false;

  constructor(public dialog: MatDialog, private injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.merchantDetails = new TrendingMerchant();
    this.detailsLoaded = Promise.resolve(true);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['merchantDetailsInput']) {
      setTimeout(() => {
        this.merchantDetails = this.merchantDetailsInput;
        this.merchantLogoTxtOnErr = false;
        this.detailsLoaded = Promise.resolve(true);
      });
    }
  }

  editTrendingMerchant(trendingMerchant: TrendingMerchant) {
    const editDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    editDialogConfig.disableClose = true;
    editDialogConfig.autoFocus = true;

    editDialogConfig.data = trendingMerchant
    editDialogConfig.panelClass = 'golp-dialog';

    const merchantEditDialog = this.dialog.open(CreateTrendingMerchantComponent, editDialogConfig);

    const successFullEdit = merchantEditDialog.componentInstance.onTrendingMerchantEditEvent.subscribe((data) => {
      this.merchantDetails.orderNo = data.orderNo;
      this.merchantDetails.merchantId = data.merchantId;
      this.merchantDetails.isActive = data.isActive;
      this.merchantDetailsInputChange.emit(this.merchantDetails);
    });

    merchantEditDialog.afterClosed().subscribe(() => {
    });
  }


  errorHandler(event) {
    //event.target.src = this.merchantLogoOnErr;
    this.merchantLogoTxtOnErr = true;
  }

}
