import { Component, OnInit, Injector } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Company } from 'src/app/models/company';
import { CreateOfferProviderComponent } from '../create-offer-provider/create-offer-provider.component';
import { AccountServiceService, AlertService, CommonService, CompanyService } from 'src/app/services';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component'
import { CompanyRole } from 'src/app/enums/company-role.enum';
import { BaseComponent } from '../base/base.component';
import { CompanyType } from '../../enums';

@Component({
    selector: 'app-offer-providers',
    templateUrl: './offer-providers.component.html',
    styleUrls: ['./offer-providers.component.css']
})
export class OfferProvidersComponent extends BaseComponent implements OnInit {

    dataLoaded: Promise<boolean>;

    currentPage: number = 1;
    pageSize: number = 10;
    itemCountText: string;
    providerCount: number = 41;

    userInfo: any;
    canCreate: boolean = true;
    canEdit: boolean = true;

    offerProviders: any[];
    giftSenderPrimitiveList: any[];
    offerProviderFilter: any = { name: '' };
    giftSenderFilterText: string;

    selectedOfferProviderIndex: number = -1;
    selectedOfferProvider: Company;
    selectedOfferProviderName: string;
    offerProviderLogoOnErr: String;

    subMenu: any[] = [];
    offerProviderSubMenu: any[] = [];
    selectedSubMenuIndex: number = 0;
    activeSubMenu: string = "About";

    companyRoleType: CompanyRole = CompanyRole.GiftSender;
    isSelectedCompanyPremium = false;

    constructor(private companyService: CompanyService, public dialog: MatDialog,
        private accountService: AccountServiceService,
        private commonService: CommonService,
        private alertService: AlertService,
        injector: Injector
    ) {
        super(injector);
    }

    ngOnInit() {
        this.getLoggedInUser();
        this.getOfferProviders();

        this.offerProviderSubMenu.push({ "name": "About", "dataTarget": "aboutOfferorTab" });
        this.offerProviderSubMenu.push({ "name": "Users", "dataTarget": "offerorUersTab" });

        //if (this.checkPermission(['ProviderUpdate'])) {
        //    this.offerProviderSubMenu.push({ "name": "Offer Holders", "dataTarget": "offerorDiscountHolderTab" });
        //}

        if (this.checkPermission(['CompanySettingRead'])) {
            this.offerProviderSubMenu.push({ "name": "Settings", "dataTarget": "providerSettingsTab" });
        }
        if (this.checkPermission(['MerchantCreate'])) {
            this.offerProviderSubMenu.push({ "name": "Offer Merchants", "dataTarget": "offerMerchantsTab" });
        }

        this.submenuCopy();
        this.dataLoaded = Promise.resolve(true);
    }


    createOfferProvider() {
        const dialogConfig = new MatDialogConfig();

        // Setting different dialog configurations
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = "golp-dialog";

        const offerorCreateDialog = this.dialog.open(CreateOfferProviderComponent, dialogConfig);

        offerorCreateDialog.afterClosed().subscribe(() => { });
    }

    onOfferProviderChange(offerProvider, index) {
        if (this.selectedOfferProviderIndex != index) {
            this.selectedOfferProviderIndex = index === this.selectedOfferProviderIndex ? -1 : index;
            this.selectedOfferProvider = offerProvider;
            this.selectedOfferProviderName = offerProvider.name;
            this.selectedOfferProvider.companyType = offerProvider.companyType;

            if (!this.offerProviderSubMenu.some(a => a.name == this.activeSubMenu)) {
                this.onSubMenuChange("About", 0);
            }
        }
        let body = document.getElementsByTagName('body')[0];
        body.classList.add("display-tab-content");
        this.commonService.itemSelectionUpdated(this.selectedOfferProvider);
        this.isSelectedCompanyPremium = this.selectedOfferProvider.companyType === CompanyType.Premium;

        this.removeOrAddPremiumMenus();
    }

    removeOrAddPremiumMenus(): void {
        if (!this.isSelectedCompanyPremium) {
            this.offerProviderSubMenu = this.offerProviderSubMenu.filter(item => item.name != "Golp Credit" && item.name != "References");
        }
        else {
            var hasCreditDetailsMenu = this.offerProviderSubMenu.some(item => item.name == "Golp Credit");

            if (!hasCreditDetailsMenu && this.checkPermission(['ReadGolpCompanyCredit'])) {
                this.offerProviderSubMenu.splice(2, 0, { "name": "Golp Credit", "dataTarget": "creditDetailTab" });
            }

            // var hasReferencesMenu = this.offerProviderSubMenu.find(item => item.name == "References");
            // if (!hasReferencesMenu && this.checkPermission(['ReadGolpReference'])) {
            //     this.offerProviderSubMenu.splice(3, 0, { "name": "References", "dataTarget": "referenceTab" });
            // }
        }

        var hasActiveSubMenu = this.offerProviderSubMenu.some(item => item.name == this.activeSubMenu);

        var indexOfActiveSubmenu = this.offerProviderSubMenu.findIndex(item => item.name == this.activeSubMenu);
        this.selectedSubMenuIndex = indexOfActiveSubmenu;

        if (!hasActiveSubMenu) {
            this.activeSubMenu = "About";
            this.selectedSubMenuIndex = 0;
        }
    }

    onSubMenuChange(name, index) {
        if (this.selectedSubMenuIndex != index) {
            this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
            this.activeSubMenu = this.offerProviderSubMenu.filter(c => c.name === name)[0].name;
        }
    }

    getOfferProviderDetailsChange(event) {
        let offerProvider = this.offerProviders.filter(c => c.id === event.id)[0];
        offerProvider.companyLogoUrl = event.companyLogoUrl;
        offerProvider.name = event.name;
        offerProvider.email = event.email;
        offerProvider.companyType = event.companyType;
        offerProvider.address = event.address;
        offerProvider.companyTypeId = event.companyTypeId;
        offerProvider.phone = event.phone;
        offerProvider.shortName = event.shortName;
        offerProvider.webAddress = event.webAddress;

        offerProvider.isGeneralDiscountEligible = event.isGeneralDiscountEligible;
        this.selectedOfferProvider = offerProvider;
        this.selectedOfferProvider.companyType = offerProvider.companyType;
        this.isSelectedCompanyPremium = this.selectedOfferProvider.companyType === CompanyType.Premium;
        //this.showDiscountHolderTab();
        this.removeOrAddPremiumMenus();
    }

    onDeleteOfferProvider(selectedOfferProvider: Company): void {
        const confirmationDialogConfig = new MatDialogConfig()

        // Setting different dialog configurations
        confirmationDialogConfig.data = "Are you sure to delete the gift sender " + selectedOfferProvider.name;
        confirmationDialogConfig.panelClass = 'golp-dialog';
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

        confirmationDialog.afterClosed().subscribe((result) => {
            if (result != undefined) {
                this.deleteOfferProvider(selectedOfferProvider);
            }
        });
    }

    deleteOfferProvider(offerProvider: Company) {
        this.companyService.deleteCompany(offerProvider.id).subscribe(() => {
            this.alertService.success('Gift sender deleted successfully');
            this.offerProviders = this.offerProviders.filter(function (item) {
                if (item.id !== offerProvider.id) {
                    return true;
                }
                else {
                    return false;
                }
            });
            this.selectedOfferProviderIndex = 0
            this.selectedOfferProvider = this.offerProviders[this.selectedOfferProviderIndex];
            this.selectedOfferProviderName = this.selectedOfferProvider.name;
            this.currentPage = 1;
            this.itemCount();
        },
            (error) => {
                //this.alertService.error('Internal server error happen');
                console.log(error);
            });
    }

    getOfferProviders() {
        this.companyService.getOfferProviders()
            .subscribe(data => {
                this.giftSenderPrimitiveList = data;
                console.log(data);
                this.giftSenderCopy();

                if (CompanyRole.GiftSender === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
                    this.offerProviders = this.offerProviders.filter(c => c.id === this.userInfo.companyId);
                }
                this.highlightFirstItem(this.offerProviders);
                this.itemCount();
            });
    }

    getLoggedInUser() {
        this.userInfo = this.accountService.getLoggedInUserInfo();
        if (CompanyRole.GiftSender === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
            this.canCreate = false;
        }
    }

    giftSenderFilteredItem(value) {
        if (!value) {
            this.giftSenderCopy();
        }
        this.offerProviders = Object.assign([], this.giftSenderPrimitiveList).filter(item => item.name.toLowerCase().indexOf(value.toLowerCase()) > - 1);
        this.highlightFirstItem(this.offerProviders);
        this.itemCount();
    }

    giftSenderCopy() {
        this.offerProviders = Object.assign([], this.giftSenderPrimitiveList);
    }

    highlightFirstItem(offerProviders: Company[]) {

        // set initial offeror
        if (offerProviders !== null) {
            this.selectedOfferProvider = offerProviders[0];
            this.selectedOfferProviderName = this.selectedOfferProvider != null ? this.selectedOfferProvider.name : '';
            this.selectedOfferProviderIndex = 0;
            this.isSelectedCompanyPremium = this.selectedOfferProvider.companyType === CompanyType.Premium;
            //this.showDiscountHolderTab();
            this.removeOrAddPremiumMenus();
        }
    }

    pageChanged(event) {
        this.selectedOfferProviderIndex = 0;
        this.currentPage = event;
        let itemIndex = (this.currentPage * this.pageSize) - this.pageSize;
        this.selectedOfferProvider = this.offerProviders[itemIndex];
        this.itemCount();
    }

    itemCount() {
        let firstItem = (this.currentPage - 1) * this.pageSize + 1;
        let lastItem = (this.currentPage) * this.pageSize;

        this.itemCountText = firstItem + ' - ' + (lastItem < this.offerProviders.length ? lastItem : this.offerProviders.length) + ' of ' + this.offerProviders.length + ' gift sender';

    }

    submenuCopy() {
        this.subMenu = Object.assign([], this.offerProviderSubMenu);
    }

    errorHandlerCompanyLogo(offerProviderId) {
        document.getElementById('companyLogoImgtxt_' + offerProviderId).style.display = "block";
        document.getElementById('companyLogoImg_' + offerProviderId).style.display = "none";
    }
}
