import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-send-gift-occasional',
  templateUrl: './send-gift-occasional.component.html',
  styleUrls: ['./send-gift-occasional.component.css']
})
export class SendGiftOccasionalComponent implements OnInit {

  @Input() occasions: any[];
  @Input() occasionsMessage: any[];

  occasionsData: any[];
  apiBaseUri: any;
  message: string;
  constructor(private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['occasions']) {
      this.occasionsData = this.occasions;
    }
  }

  onClickOccasion(messageIndex: any) {
    console.log(messageIndex, " Data ", this.occasionsMessage[messageIndex]);

    if (messageIndex > this.occasionsMessage.length - 1) {
      this.message = "Happy "
    }
    else {
      this.message = this.occasionsMessage[messageIndex];
    }
    let giftInfo = {
      "message": this.message,
      "isOccasionalGift": true
    };

    let navigationEgolps: NavigationExtras = {
      state: {
        transd: 'occasionalGift',
        workQueue: false,
        services: 1,
        code: giftInfo
      }
    };

    this.router.navigate(['send-gift-information'], navigationEgolps);
  }
}
