import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MerchantOutletResponse } from 'src/app/models/view-models/merchant-outlet-response';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import { DataService } from '../../services/shared/data.service';
import { saveAs } from 'file-saver';
import { DashboardYearlyViewModel } from '../gift-vs-sales-report/gift-vs-sales-report.component';
@Component({
  selector: 'app-gift-vs-redemption-report',
  templateUrl: './gift-vs-redemption-report.component.html',
  styleUrls: ['./gift-vs-redemption-report.component.css']
})
export class GiftVsRedemptionReportComponent implements OnInit {

  giftVsRedeemptionReportForm: FormGroup;
  userInfo: any;
  yearlyGiftsAndSalesInfo: any;
  yearlyGiftVsRedemtionInfo: any;
  merchantOutlets: any;
  submitted: boolean;
  companyOutletId: any;
  p: number = 1;
  defaultStartDate: Date = this.startOfMonth(new Date());
  defaultEndDate: Date = new Date();
  constructor(private formBuilder: FormBuilder, private dataService: DataService) {
    this.buildForm();
  }

  startOfMonth(date) {
    let startDateOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    startDateOfMonth.setMinutes(date.getMinutes() + 360); // 360 is for GMT+6 hours
    return startDateOfMonth;
  }

  ngOnInit(): void {
    this.setUserInfo();
    if (this.userInfo.outletQrCode == undefined || this.userInfo.outletQrCode == null)
      this.getMerchantOutlet();
  }

  buildForm() {
    this.giftVsRedeemptionReportForm = this.formBuilder.group({
      fromDate: [this.defaultStartDate, Validators.required],
      toDate: [this.defaultEndDate, Validators.required],
      outletId: ['', Validators.nullValidator]
    });
  }

  setUserInfo() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.companyOutletId = this.userInfo?.outletQrCode;
  }
  loadYearlyGiftVsReedemptionData() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.giftVsRedeemptionReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    let fromDate = moment(this.f.fromDate.value).format("YYYY/MM/DD");
    let todate = moment(this.f.toDate.value).format("YYYY/MM/DD");
    let outletId = this.f.outletId.value;

    if (outletId != 0)
      this.companyOutletId = outletId.qrCode;

    let url = `api/GiftCard/gift-card-reports-yearly/merchant/${this.userInfo.companyId}?fromDate=${fromDate}&toDate=${todate}`;
    if (this.userInfo.outletQrCode != null || this.userInfo.outletQrCode != undefined || outletId != 0)
      url += `&outletId=${this.companyOutletId}`
    this.dataService.get(url).subscribe((data: DashboardYearlyViewModel) => {
      if (data != null && data.success) {
        this.p = 1;
        this.yearlyGiftsAndSalesInfo = data.summary.yearlyGiftsAndSalesInfos;
        this.yearlyGiftVsRedemtionInfo = data.summary.yearlyGiftVsRedemtionInfos;
      }
    });
  }

  get f() { return this.giftVsRedeemptionReportForm.controls; }

  getMerchantOutlet() {
    let url = `api/Outlets/company/${this.userInfo.companyId}/`;
    this.dataService.get(url).subscribe((data: MerchantOutletResponse[]) => {
      if (data != null && data.length > 0) {
        this.merchantOutlets = data;
      }
    });
  }

  exportXLReportFromServer() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.giftVsRedeemptionReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    let fromDate = moment(this.f.fromDate.value).format("YYYY/MM/DD");
    let todate = moment(this.f.toDate.value).format("YYYY/MM/DD");
    let outletId = this.f.outletId.value;

    if (outletId != 0)
      this.companyOutletId = outletId.qrCode;
    let url = `api/report/gift-vs-reedemption-reports-yearly/merchant/${this.userInfo.companyId}`;

    let payload = {
      "fromDate": fromDate,
      "toDate": todate,
      "outletId": this.companyOutletId
    }

    this.dataService.postWithOptions(url, payload, {
      responseType: 'blob'
    })
      .subscribe((response: any) => {
        this.downloadFile(response);
      }, () => { console.log("Error Occurred") }, () => { console.log("Download Finished") });
  }

  downloadFile(data) {
    const blob2 = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var filename = "Gift-Vs-Reedemption-Report-" + moment().format('MMMM Do YYYY, h:mm:ss').valueOf() + ".xlsx";
    saveAs(blob2, filename);
  }
}
