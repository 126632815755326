import { Component, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
  animations: [
    trigger('detailExpand', [
      state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('*', style({ height: '*', visibility: 'visible' })),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PaymentComponent implements OnInit {
  status: string = null;
  tranId: string = null;
  cardtype: string = null;
  cardno: string = null;
  trandate: string = null;
  amount: string = null;
  currency: string = null;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.status = this.route.snapshot.params.status === '1' ? "SUCCESS" : "FAILED";
    this.tranId = this.route.snapshot.params.tranId;

    this.route.queryParams.subscribe(params => {
      this.cardtype = params.cardtype;
      this.cardno = params.cardno;
      this.trandate = params.trandate;
      this.amount = params.amount;
      this.currency = params.currency;
    });
  }

}
