import { BillClaimComponent } from './../bill-claim/bill-claim.component';
import { BillInvoiceDetailsComponent } from './../bill-invoice/bill-invoice-details/bill-invoice-details.component';
import { NewBillProcessComponent } from './new-bill-process/new-bill-process.component';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as moment from 'moment';
import { Select2OptionData } from 'ng-select2';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataService } from 'src/app/components/app-send-gift/services/shared/data.service';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { MerchantOutletResponse } from 'src/app/models/view-models/merchant-outlet-response';
import { AlertService } from 'src/app/services';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
@Component({
  selector: 'app-bill-process',
  templateUrl: './bill-process.component.html',
  styleUrls: ['./bill-process.component.css']
})
export class BillProcessComponent implements OnInit {

  companyId: any = -1;
  billNumber: any;
  userInfo: any;
  companyOutletId: any;
  merchantOutlets: any;
  orders: any;
  submitted: boolean;
  p: number = 1;
  offerReportForm: FormGroup;

  currentPage: number = 1;
  count = 0;
  pageSize = 25;
  pageSizes = [25, 50, 100];
  public defaultTime = [];


  billTypes: any[];
  bills: any[];

  //company list
  allCompany: any[];
  select2CompanyKeyInfo: Array<Select2OptionData>;
  select2BillType: any[] = [];
  dropdownSettings = {
    singleSelection: false,
    text: "Select Bill Type",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    classes: "myclass custom-class",
    badgeShowLimit: 1,
    enableCheckAll: true
  };
  selectedItems: any[] = [];
  //multiselect options
  options: any = {
    multiple: true
  };

  constructor(private dataService: DataService, private formBuilder: FormBuilder, private alertService: AlertService, private dialog: MatDialog) {

    this.loadAllCompany();
    // this.loadBillType();
  }



  ngOnInit(): void {
  }

  setDefaultTime() {
    let d = new Date();
    this.defaultTime = [d.getHours(), d.getMinutes(), d.getSeconds()]
    console.log(this.defaultTime)
  }

  handlePageChange(event: number): void {
    this.p = event;
    this.loadBill();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.p = 1;
    this.loadBill();
  }

  loadAllCompany() {
    let url = "api/Bills/bill-payers";

    this.dataService.get(url).subscribe(
      (res: any) => {
        this.allCompany = res.data;
        this.select2CompanyKeyInfo = this.allCompany.map((p) => {
          return {
            id: p.id,
            text: p.companyName,
          };
        });
        let noMerchant: Select2OptionData = {
          id: "-1",
          text: "Select Company",
        };
        this.select2CompanyKeyInfo.unshift(noMerchant);
      },
      catchError((error) => {
        return throwError('Something went wrong!');
      })
    );
  }

  loadBillType() {
    let url = "api/billing/bill-settings-meta-data";

    this.dataService.post(url, null).subscribe(
      (data: any) => {
        if (data.success) {
          this.billTypes = data.data?.settingsKey;
          this.select2BillType = this.billTypes.map((p) => {
            return {
              id: p.key,
              itemName: p.name,
            };
          });
        }

      },
      catchError((error) => {
        return throwError('Something went wrong!');
      })
    );
  }



  loadBill() {

    if (Number(this.companyId) < 0) return;

    let url = `/api/Bills?companyId=${this.companyId}&pageSize=${this.pageSize}&pageNumber=${this.p}`;
    if (this.billNumber != null && this.billNumber != "") {
      url += `&filter=${this.billNumber}`;
    }

    this.dataService.get(url).subscribe((res: any) => {
      if (res.result) {
        this.bills = res.data.bills;
        this.count = res.data.totalCount;
      }
      else this.bills = [];
    }, err => console.log(err));
  }



  get f() { return this.offerReportForm.controls; }

  showConfirmation(item) {
    if (item.hasConsumed) return;
    const confirmationDialogConfig = new MatDialogConfig();
    // Setting different dialog configurations
    confirmationDialogConfig.data = `Would you like to proceed? This operation can not be undone.`;
    confirmationDialogConfig.panelClass = "golp-dialog";
    const confirmationDialog = this.dialog.open(
      ConfirmationDialogComponent,
      confirmationDialogConfig
    );

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
  }

  generateBill() {
    const newBillProcessDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    newBillProcessDialogConfig.disableClose = true;
    newBillProcessDialogConfig.autoFocus = true;
    newBillProcessDialogConfig.panelClass = "golp-dialog";

    newBillProcessDialogConfig.data = this.select2CompanyKeyInfo;

    const createNewBillPrcessDialog = this.dialog.open(NewBillProcessComponent, newBillProcessDialogConfig);
  }


  claimBill(bill) {
    const newBillClaimDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    newBillClaimDialogConfig.disableClose = true;
    newBillClaimDialogConfig.autoFocus = true;
    newBillClaimDialogConfig.panelClass = "golp-dialog";

    newBillClaimDialogConfig.data = bill;

    const createNewBillPrcessDialog = this.dialog.open(BillClaimComponent, newBillClaimDialogConfig);
  }

  showInvoice(billDetails) {
    const showInvoiceDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    showInvoiceDialogConfig.disableClose = true;
    showInvoiceDialogConfig.autoFocus = true;
    showInvoiceDialogConfig.panelClass = "golp-dialog";

    showInvoiceDialogConfig.data = billDetails;

    showInvoiceDialogConfig.minWidth = '100vw';
    showInvoiceDialogConfig.height = '920vw';
    const showInvoiceDialog = this.dialog.open(BillInvoiceDetailsComponent, showInvoiceDialogConfig)
  }

  loadDetails(item) {
    let url = `api/Bills/bill-details/${item.id}`;
    this.dataService.get(url).subscribe((res: any) => {
      if (res.result) {
        this.showInvoice(res.data);
      }
      else {
        this.alertService.error(res.message);
      }
    }, err => console.log(err));
  }

  export(item: any) {
    let obj = {
      "billId": item.id
    };
    let url = "api/bills/export-bill-items";

    this.dataService.postWithOptions(url, obj, {
      responseType: 'blob'
    }).subscribe((response: any) => {
      this.downloadFile(response);
    }, (err) => console.log(err));
  }

  downloadFile(data) {
    const blob2 = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var filename = "Billing Items_" + moment().format('MMMM Do YYYY, h:mm:ss').valueOf() + ".xlsx";
    fs.saveAs(blob2, filename);
  }


  email(item) {
    try {
      loadOutlook();
    }
    catch (err) {
      alert(err.message);
    }
  }

}
function loadOutlook() {

}
