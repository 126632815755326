import { DataService } from './../../../components/app-send-gift/services/shared/data.service';
import { Component, OnInit, Input, SimpleChanges, Injector } from '@angular/core';
import { BaseComponent } from 'src/app/components/base/base.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { AlertService, AccountServiceService } from 'src/app/services';
import { NewFacilityComponent } from './new-facility/new-facility.component';
@Component({
  selector: 'app-privilege-card-facilities',
  templateUrl: './privilege-card-facilities.component.html',
  styleUrls: ['./privilege-card-facilities.component.css']
})
export class PrivilegeCardFacilitiesComponent implements OnInit {

  @Input() privilegeCardInput: any;

  privilegeCard: any;
  cards: any[] = [];
  canOperate: boolean = false;

  constructor(private dataService: DataService, private accountService: AccountServiceService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private injector: Injector) {
    this.canOperateCurrentUser();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['privilegeCardInput']) {
      setTimeout(() => {
        this.privilegeCard = this.privilegeCardInput;
        //update table list 
        if (this.privilegeCard && this.privilegeCard.cardId)
          this.getCardsByTemplate(this.privilegeCard.cardId);
      });
    }
  }

  canOperateCurrentUser() {
    this.canOperate = this.accountService.getLoggedInUserInfo().isSystemAdmin;
  }

  getCardsByTemplate(privilegeCardId: any) {
    let url = `api/cards/card-facility/${privilegeCardId}`
    this.dataService.get(url)
      .subscribe((response: any) => {
        this.cards = [];
        if (response.success) {
          let allCard = response.data.filter((item: any) => item.isActive || this.canOperate);
          allCard.forEach(p => {
            let m = p;
            m["show"] = false;
            this.cards.push(m);
          });
        }
        else {
        }

      });
  }

  addNewFacility(privilegeCard: any) {
    const addNewFacilityDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    addNewFacilityDialogConfig.disableClose = true;
    addNewFacilityDialogConfig.autoFocus = true;
    addNewFacilityDialogConfig.panelClass = "golp-dialog";
    let data = {
      "privilegeCard": privilegeCard,
      "facility":null
    }
    addNewFacilityDialogConfig.data = data
    
    const createAssignmentDialog = this.dialog.open(NewFacilityComponent, addNewFacilityDialogConfig);

    const successfullCreate = createAssignmentDialog.componentInstance.onaddNewFacilityEvent.subscribe((data) => {
      this.getCardsByTemplate(data);
    });

    createAssignmentDialog.afterClosed().subscribe(() => {
    });
  }

  onDelete(item: any) {
    const confirmationDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    confirmationDialogConfig.data = `Are you sure to delete the merchant ${item.merchant}?`;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        let payload = {
          "cardId": item.golpCardId,
          "facilityId": item.id
        }
        this.dataService.post(`api/cards/delete-facility`, payload)
          .subscribe((response: any) => {
            if (response.success) {
              this.getCardsByTemplate(item.golpCardId);
              this.alertService.success("Offer deleted successfully");
            } else if (!response.success) {
              this.alertService.error(response.data);
            }
          });
      }
    });
  }

  toggleStatus(item: any) {
    const confirmationDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    let status = item.isActive ? "deactivate" : "activate"
    confirmationDialogConfig.data = `Are you sure to ${status} the facility of merchant ${item.merchant}?`;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);
    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        let payload = {
          "facilityId": item.id,
          "cardId": item.golpCardId,
          "activationFlag": !item.isActive
        }
        this.dataService.post(`api/cards/activate-facility`, payload)
          .subscribe((response: any) => {
            if (response.success) {
              this.getCardsByTemplate(item.golpCardId);
              this.alertService.success(response.data);
            } else if (!response.success) {
              this.alertService.error(response.data);
            }
          });
      }
    });
  }

  toggleInfo(index) {
    this.cards[index].show = !this.cards[index].show;
  }

  onEdit(item){
    const addNewFacilityDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    addNewFacilityDialogConfig.disableClose = true;
    addNewFacilityDialogConfig.autoFocus = true;
    addNewFacilityDialogConfig.panelClass = "golp-dialog";
    let data = {
      "privilegeCard": this.privilegeCard,
      "facility": item
    }
    addNewFacilityDialogConfig.data = data
    
    const createAssignmentDialog = this.dialog.open(NewFacilityComponent, addNewFacilityDialogConfig);

    const successfullCreate = createAssignmentDialog.componentInstance.onaddNewFacilityEvent.subscribe((data) => {
      this.getCardsByTemplate(data);
    });

    createAssignmentDialog.afterClosed().subscribe(() => {
    });
  }
}
