import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Summary } from 'src/app/models/DashboardMonthlyResponse';
import { MerchantOutletResponse } from 'src/app/models/view-models/merchant-outlet-response';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import { DataService } from '../../services/shared/data.service';

@Component({
  selector: 'app-gift-summary-report',
  templateUrl: './gift-summary-report.component.html',
  styleUrls: ['./gift-summary-report.component.css']
})
export class GiftSummaryReportComponent implements OnInit {

  giftSummaryReportForm: FormGroup;
  defaultStartDate: Date = this.startOfMonth(new Date());
  defaultEndDate: Date = new Date();
  notificationItems: any[];
  userInfo: any;
  summary: Summary;

  averageGiftAmount: string = '0.0';
  averageSalesAmount: string = '0.0';
  amountSold: string = '0.0';
  redeemedAmount: string = '0.0';

  diffAmountPercentage: number = 0.0;
  diffRedeemedAmountPercentage: number = 0.0;
  companyOutletId: any;
  merchantOutlets: any;
  submitted: boolean;
  constructor(private formBuilder: FormBuilder, private dataService: DataService) {
    this.buildForm();
  }

  ngOnInit() {
    this.setUserInfo();
    if (this.userInfo.outletQrCode == undefined || this.userInfo.outletQrCode == null)
      this.getMerchantOutlet();
    if (this.userInfo.outletQrCode != undefined && this.userInfo.outletQrCode != null)
      this.companyOutletId = this.userInfo.outletQrCode;
    this.getSummaryReportData();

  }

  startOfMonth(date) {
    let startDateOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    startDateOfMonth.setMinutes(date.getMinutes() + 360); // 360 is for GMT+6 hours
    return startDateOfMonth;
  }

  buildForm() {
    this.giftSummaryReportForm = this.formBuilder.group({
      fromDate: [this.defaultStartDate, Validators.required],
      toDate: [this.defaultEndDate, Validators.required],
      outletId: ['', Validators.nullValidator]
    });
  }

  setUserInfo() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
  }

  getSummaryReportData() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.giftSummaryReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    let fromDate = moment(this.f.fromDate.value).format("YYYY/MM/DD");
    let todate = moment(this.f.toDate.value).format("YYYY/MM/DD");
    let outletId = this.f.outletId.value;

    if (outletId != 0)
      this.companyOutletId = outletId.qrCode;
    let url = `api/giftCard/gift-card-summary-reports/merchant/${this.userInfo.companyId}?fromDate=${fromDate}&toDate=${todate}`;
    if (this.userInfo.outletQrCode != null || this.userInfo.outletQrCode != undefined || outletId != 0)
      url += `&outletId=${this.companyOutletId}`;
    this.dataService.get(url).subscribe((data: any) => {
      if (data != null && data.success) {
        this.amountSold = this.getCommaSeparatedString(data.summary.amountSold);
        this.redeemedAmount = this.getCommaSeparatedString(data.summary.redeemedAmount);
        this.averageGiftAmount = this.getCommaSeparatedString((data.summary.averageGiftAmount != undefined && data.summary.averageGiftAmount != null) ? Math.floor(data.summary.averageGiftAmount) : 0);
        this.averageSalesAmount = this.getCommaSeparatedString((data.summary.averageSalesAmount != undefined && data.summary.averageSalesAmount != null) ? Math.floor(data.summary.averageSalesAmount) : 0);
      }
      else {
        this.amountSold = '0';
        this.redeemedAmount = '0';
        this.averageGiftAmount = '0';
        this.averageSalesAmount = '0';
      }
    });
  }

  get f() { return this.giftSummaryReportForm.controls; }

  getCommaSeparatedString(input) {
    input = input.toString();
    let iLen = input.length;
    if (iLen <= 3) return input;
    var res;
    res = input.substring(iLen - 3, iLen);
    var substr = input.substring(0, iLen - 3);
    substr = substr.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
    res = substr + "," + res;

    return res;
  }

  getMerchantOutlet() {
    let url = `api/Outlets/company/${this.userInfo.companyId}/`;
    this.dataService.get(url).subscribe((data: MerchantOutletResponse[]) => {
      if (data != null && data.length > 0) {
        this.merchantOutlets = data;
      }
    });
  }
}
