import { Component, OnInit, EventEmitter, Inject, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { AlertService, LoyaltyMerchantService, CompanyService } from '../../services';
import { CompanySettingType } from '../../enums/company-setting.type';
import { CompanySetting } from '../../models/company-setting';
import { MerchantAcceptableAvailMethodService } from '../../services/merchant-acceptable-avail-method.service';
import { MerchantAcceptableAvailMethod } from '../../models/merchant-accepltable-avail-metod';
import { CompanySettingService } from '../../services/company-setting.service';
import { OfferAvailMethodService } from '../../services/offer-avail-method.service';
import { OfferAvailMethod } from '../../models/offer-avail-method';
import { ReplaySubject } from 'rxjs';
import { GiftAmountSetting, PromotionalGiftSetting, merchantDuePaymentSetting, MerchantAccountInfoSetting, GiftUsageLimitSetting, LoyaltySettingsKey, LoyaltyMerchantSetting } from '../../models';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { CompanyRole } from '../../enums';
import { SharedService } from '../../services/shared.service';
import { ValidationUtility } from '../../utility/validation.utility';
import { MerchantAvailInstruction } from '../../models/merchant-avail-instruction';
//import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'app-create-company-setting',
  templateUrl: './create-company-setting.component.html',
  styleUrls: ['./create-company-setting.component.css']
})


export class CreateCompanySettingComponent implements OnInit {

  @Input() companyRole: CompanyRole;
  // onCreate event
  onSettingCreateEvent: EventEmitter<number> = new EventEmitter();
  // onEdit event;
  onSettingEditEvent: EventEmitter<number> = new EventEmitter();

  companySettingForm: FormGroup;
  companySettingType: CompanySettingType;
  companySettingTypes: any[];
  companyPaymentOnTypes: any[];
  loyaltyMerchantId: any;

  merchantAcceptableAvailMethods: MerchantAcceptableAvailMethod[];
  offerAvailMethods: OfferAvailMethod[];
  selectedAvailMethods: any;
  selectedPaymentOn: any;
  selectedMethods: string;

  submitted = false;
  companySetting: CompanySetting;
  companyId: number;
  inEditMode: boolean = false;

  fileValidationError: string;

  isEnabled: boolean = true;

  apiEndPointJsonValues: any;

  giftAmountSlabs: number[];

  giftAmountSetting: GiftAmountSetting;

  showSlabSectionOnCreateGiftAmountSetting: boolean = true;

  promotionalGiftSetting: PromotionalGiftSetting;
  giftUsageLimitSetting: GiftUsageLimitSetting;

  companySettingFilterCtrl: FormControl = new FormControl();
  filteredCompanySettingTypes: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  giftAmountSettingJsonValue: string;
  promotionalGiftSettingJsonValue: string;
  giftUsageLimitSettingJsonValue: string;
  trendingMerchantSettingJsonValue: string;
  excludeMerchantSettingJsonValue: string;
  merchantDuePaymentSettingJsonValue: any;
  merchantDuePaymentSetting: merchantDuePaymentSetting;
  merchantAccountInfoSetting: MerchantAccountInfoSetting;
  merchantAccountInfoSettingJsonValue: string;
  giftResendPolicySetting: any;
  giftResendPolicySettingJsonValue: any;

  loyaltyCardTierMapSetting: any;
  loyaltyCardTierMapSettingJsonValue: any;

  loyaltyPointSettingSetting: any;
  loyaltyPointSettingSettingJsonValue: any;
  availInstructions: any;
  availInstructionsSettingJsonValue: string;

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateCompanySettingComponent>,
    private companySettingService: CompanySettingService,
    private loyaltyMerchantService: LoyaltyMerchantService,
    private alertService: AlertService,
    private sharedService: SharedService,
    private merchantAcceptableAvailMethodService: MerchantAcceptableAvailMethodService,
    private offerAvailMethodService: OfferAvailMethodService,
    private companyService: CompanyService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data) {

    this.companySetting = new CompanySetting();

    if (!data.isCreateAction) { // coming from setting list page

      this.companySetting = new CompanySetting(data);
      this.companyId = this.companySetting.companyId;
      this.companyRole = this.companySetting.companyRole;

      if (this.companyRole == CompanyRole.LoyaltyMerchant) {
        this.setCompanySettingFromLoyaltyMerchantSetting(data);
      }
      this.inEditMode = true;
      this.isEnabled = this.companySetting.isEnabled;
      this.companySetting.key = this.companySetting.key;
    }
    else { // coming from other page

      var aa = data.id;
      this.companyRole = data.companyRole;

      this.companyId = data.id;

      if (this.companyRole == CompanyRole.LoyaltyMerchant) {
        this.loyaltyMerchantId = data.id;
      }

      this.companySetting.companyId = this.companyId;
      this.companySetting.isEnabled = this.isEnabled;
    }

    this.showSlabSectionOnCreateGiftAmountSetting = this.companyRole == CompanyRole.Merchant;

    this.buildForm();

    console.log("data : ", data)
  }

  ngOnInit() {
    this.companySettingTypes = this.sharedService.getCompanySettingTypes();

    if (this.companyRole == CompanyRole.GiftSender) {
      this.companySettingTypes = [{ text: 'Gift Pre Enabled', value: 4 }];
    }
    else if (this.companyRole == CompanyRole.Merchant) {
      this.companySettingTypes = [{ text: 'Merchant Acceptance Avail Method', value: 1 }, { text: 'Real Time Payment', value: 2 },
      { text: 'API End Point Configuration', value: 3 }, { text: 'Payment On Outlet/Centrally', value: 5 },
      { text: 'Gift Amount', value: 6 }, { text: 'Merchant Due Payment', value: 9 }, { text: 'Merchant Payment Account Info', value: 10 },
      { text: 'Avail Instruction', value: 16 }];
    }
    else if (this.companyRole == CompanyRole.LoyaltyMerchant) {
      this.companySettingTypes = [{ text: 'Loyalty Card Tier Map', value: 14 }, { text: 'Loyalty Point Settings', value: 15 }];
    }
    else if (this.companyRole == CompanyRole.None) { // GOLP Admin's Global Settings
      this.companySettingTypes = [{ text: 'Gift Amount', value: 6 }, { text: 'Promotional Gift', value: 7 },
      { text: 'Gift Usage Limit', value: 11 },
      { text: 'Exclude Merchants', value: 12 }, { text: 'Gift Resend Policy', value: 13 }];
    }

    // When CompanySettingTypes comes from service filteredCompanySettingTypes need to be populate in subscribe.
    this.filteredCompanySettingTypes.next(this.companySettingTypes);

    this.getMerchantAcceptableAvailMethods();
    this.getCompanyPaymentOnTypes();
    this.getAllOfferAvailMethods();
    this.getValueByType();
    this.bindFilterChangeEvent();
  }

  onChangeSettingKey(key) {
    this.companySetting.key = key;
  }

  buildForm() {
    this.companySettingForm = this.formBuilder.group({
      key: [this.companySetting.key, [Validators.required]],
      companyId: this.companySetting.companyId,
      isEnabled: this.companySetting.isEnabled,
      selectedAvailMethods: this.selectedAvailMethods,
      //selectedPaymentOn: [this.selectedPaymentOn, RxwebValidators.required({ conditionalExpression: (x, y) => y.key == 5 })]
      selectedPaymentOn: [this.selectedPaymentOn]
    });
  }

  setCompanySettingFromLoyaltyMerchantSetting(loyaltySetting: LoyaltyMerchantSetting) {
    this.loyaltyMerchantId = loyaltySetting.loyaltyMerchantId;
    this.companyId = loyaltySetting.loyaltyMerchantId;

    this.companySetting.id = loyaltySetting.id;
    if (loyaltySetting.loyaltySettingsKey == LoyaltySettingsKey.CardTierMap) {
      this.companySetting.key = 14;
    }
    else if (loyaltySetting.loyaltySettingsKey == LoyaltySettingsKey.LoyaltyPointSettings) {
      this.companySetting.key = 15;
    }
    this.companySetting.companyId = loyaltySetting.loyaltyMerchantId;
    this.companySetting.value = loyaltySetting.value;
    this.companySetting.isEnabled = loyaltySetting.isEnabled;
  }

  getCompanyPaymentOnTypes() {
    this.companyPaymentOnTypes = [{ text: 'Centrally', value: 1 }, { text: 'Outlet Specific', value: 2 }];
  }

  getValueByType(): void {
    if (this.companySetting && this.companySetting.id !== undefined) {
      if (this.companySetting.key == 1) {
        this.selectedAvailMethods = this.companySetting.value.split(',').map(function (item) {
          return parseInt(item, 10);
        });;
      }
      else if (this.companySetting.key == 5) {
        //this.selectedPaymentOn = +this.companySetting.value;
        this.companySettingForm.patchValue({
          selectedPaymentOn: +this.companySetting.value
        });
      }
      else if (this.companySetting.key == 6) {
        this.companySettingForm.patchValue({
        });
      }

    }
  }

  getMerchantAcceptableAvailMethods(): void {
    if (this.companyId && this.companyRole != CompanyRole.LoyaltyMerchant) {
      this.merchantAcceptableAvailMethodService.getMerchantAcceptableAvailMethodByCompanyId(this.companyId)
        .subscribe(result => {
          this.merchantAcceptableAvailMethods = result;
        });
    }
  }

  getAllOfferAvailMethods(): void {
    this.offerAvailMethodService.GetAllOfferAvailMethods()
      .subscribe(result => {
        this.offerAvailMethods = result;
      });
  }


  onSubmit() {

    this.submitted = true;
    var isValid = this.validateCompanySetting();

    if (!isValid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }
    if (this.companySetting.id === undefined) {
      this.createSetting();
    }
    else {
      this.editSetting();
    }
    this.dialogRef.close();
  }

  validateCompanySetting(): boolean {
    // stop here if form is invalid

    if (this.companySettingForm.invalid) {
      return false;
    }

    if (this.companySetting.key == 5 && !this.f.selectedPaymentOn.value) {
      this.companySettingForm.controls['selectedPaymentOn'].setErrors({ 'required': true });
      return false;
    }

    else if (this.companySetting.key == 6) {

      if (!this.giftAmountSetting) return;

      else if (this.companyRole != CompanyRole.None && (!this.giftAmountSetting || (this.giftAmountSetting.useSlab &&
        ((this.giftAmountSetting.slabs == undefined || this.giftAmountSetting.slabs.length == 0) || (this.giftAmountSetting.slabs.length > 0 && this.giftAmountSetting.slabs.find(x => x == undefined)))))) {

        const confirmationDialogConfig = new MatDialogConfig()

        // Setting different dialog configurations
        confirmationDialogConfig.data = "Slab value is required";
        confirmationDialogConfig.panelClass = 'golp-dialog';

        const confirmationDialog = this.dialog.open(AlertDialogComponent, confirmationDialogConfig);

        confirmationDialog.afterClosed().subscribe((result) => {
          if (result != undefined) {
            //this.deleteMerchant(selectedMerchant);
          }
        });

        return;
      }

      else if ((!this.giftAmountSetting.useSlab && !this.giftAmountSetting.minAmount))
        return;
    }

    else if (this.companySetting.key == 7) {
      if (!this.promotionalGiftSetting || (!this.promotionalGiftSetting.eligibleMerchants || !this.promotionalGiftSetting.giftAmount || !this.promotionalGiftSetting.giftSenderId
        || !this.promotionalGiftSetting.minGiftSendAmount || !this.promotionalGiftSetting.validity)) return false;
      //else if(this)
    }

    else if (this.companySetting.key == 8) {
      if (!this.companySetting.value) return;
    }

    else if (this.companySetting.key == 9) {
      if (!this.merchantDuePaymentSetting || !this.merchantDuePaymentSetting.showBy)
        return false;

      if (this.merchantDuePaymentSetting.showBy == 1) { // daily
        this.merchantDuePaymentSetting.showOnDay = null;
      }
      else if (this.merchantDuePaymentSetting.showBy == 2) {
        if (this.merchantDuePaymentSetting.showOnDay == null) return false;
      }
      else if (this.merchantDuePaymentSetting.showBy == 3) {
        var days = this.merchantDuePaymentSetting.showOnDay.toString().split(',');

        var isValid = true;

        days.forEach(x => {
          x = x.trim();
          if (!Number(x) && +x != 0) {
            isValid = false;
          }
          else {
            if (+x < 1 || +x > 31) {
              isValid = false;
            }
          }
        });

        return isValid;
      }
    }

    else if (this.companySetting.key == 10) {
      if (!this.merchantAccountInfoSetting || !this.merchantAccountInfoSetting.payBy) return;

      if (this.merchantAccountInfoSetting.payBy == 4) {   // bKash
        if (!this.merchantAccountInfoSetting.bkashAccount
          || !this.merchantAccountInfoSetting.bkashAccount.bKashNumber
          || !this.merchantAccountInfoSetting.bkashAccount.bKashAccountType) {
          return;
        }
      }
      else if (this.merchantAccountInfoSetting.payBy == 3) {  // bank
        if (!this.merchantAccountInfoSetting.bankAccount
          || !this.merchantAccountInfoSetting.bankAccount.bankName
          || !this.merchantAccountInfoSetting.bankAccount.branchName
          || !this.merchantAccountInfoSetting.bankAccount.routingNumber
          || !this.merchantAccountInfoSetting.bankAccount.accountName
          || !this.merchantAccountInfoSetting.bankAccount.accountNumber) {
          return;
        }
      }
    }

    else if (this.companySetting.key == 11) {// Gift Usage Limit setting
      //if (!this.promotionalGiftSetting || (!this.promotionalGiftSetting.eligibleMerchants || !this.promotionalGiftSetting.giftAmount || !this.promotionalGiftSetting.giftSenderId
      //  || !this.promotionalGiftSetting.minGiftSendAmount || !this.promotionalGiftSetting.validity)) return false;
      ////else if(this)
    }

    else if (this.companySetting.key == 12) { //Exclude Merchants
      if (!this.companySetting.value) return;
    }

    else if (this.companySetting.key == 13) { //Gift Resend Policy
      if (!this.companySetting.value) return;
    }

    else if (this.companySetting.key == 14) { //Loyalty Card Tier Map
      if (!this.companySetting.value) return;
    }

    else if (this.companySetting.key == 15) { //Loyalty Point Setting
      if (!this.companySetting.value) return;
    }

    else if (this.companySetting.key == 16) { //Avail Instructions Setting
      if (!this.companySetting.value) return;
    }

    return true;    // default valid
  }

  setValueByType(): void {
    if (this.companySetting.key == 1) {
      this.companySetting.value = this.selectedAvailMethods.join(',');
    }
    else if (this.companySetting.key == 2) {
      this.companySetting.value = this.isEnabled.toString();
    }
    else if (this.companySetting.key == 3) {
      this.companySetting.value = this.apiEndPointJsonValues;
    }
    else if (this.companySetting.key == 4) {
      this.companySetting.value = this.isEnabled.toString();
    }
    else if (this.companySetting.key == 5) {
      this.companySetting.value = this.selectedPaymentOn;
    }
    else if (this.companySetting.key == 6) {
      this.companySetting.value = this.giftAmountSettingJsonValue;
    }
    else if (this.companySetting.key == 7) {
      this.companySetting.value = this.promotionalGiftSettingJsonValue;
    }
    else if (this.companySetting.key == 8) {
      this.companySetting.value = this.trendingMerchantSettingJsonValue;
    }
    else if (this.companySetting.key == 9) {
      this.companySetting.value = this.merchantDuePaymentSettingJsonValue;
    }
    else if (this.companySetting.key == 10) {
      this.companySetting.value = this.merchantAccountInfoSettingJsonValue;
    }
    else if (this.companySetting.key == 11) {
      this.companySetting.value = this.giftUsageLimitSettingJsonValue;
    }
    else if (this.companySetting.key == 12) {
      this.companySetting.value = this.excludeMerchantSettingJsonValue;
    }
    else if (this.companySetting.key == 13) {
      this.companySetting.value = this.giftResendPolicySettingJsonValue;
    }
    else if (this.companySetting.key == 14) {
      this.companySetting.value = this.loyaltyCardTierMapSettingJsonValue;
    }
    else if (this.companySetting.key == 15) {
      this.companySetting.value = this.loyaltyPointSettingSettingJsonValue;
    }
    else if (this.companySetting.key == 16) {
      this.companySetting.value = this.availInstructionsSettingJsonValue;
    }
  }

  createSetting() {
    this.companySetting = new CompanySetting(this.companySettingForm.value);
    this.setValueByType();
    if (this.companySetting !== null) {
      this.companySetting.companyId = this.companyId;
    }

    if (this.companyRole == CompanyRole.LoyaltyMerchant) {
      var loyaltySetting = new LoyaltyMerchantSetting();
      loyaltySetting = this.setLoyaltySettingsFromCompanySetting(loyaltySetting);

      this.loyaltyMerchantService.createOrEditLoyaltyMerchantSetting(loyaltySetting)
        .subscribe((data: any) => {

          this.onSettingCreateEvent.emit(this.companySetting.companyId);
          this.alertService.success("Setting added successfully");
        },
          (error: any) => {
            this.showErrorMessage(error);
          })
    }
    else if (this.companySetting.key == 16) { // Avail Instructions- this will be saved on company table, not in company setting table

      var instructions: string[] = [];

      instructions = JSON.parse(this.companySetting.value);

      var instructionModel = new MerchantAvailInstruction(this.companySetting.companyId, instructions);
      this.companyService.addOrEditMerchantAvailInstructions(instructionModel).subscribe((data: any) => {

        this.onSettingCreateEvent.emit(this.companySetting.companyId);
        this.alertService.success("Setting added successfully");
      },
        (error: any) => {
          this.showErrorMessage(error);
        })
    }
    else {
      this.companySetting.id = 0;

      this.companySettingService.createSetting(this.companySetting)
        .subscribe((data: any) => {

          this.onSettingCreateEvent.emit(this.companySetting.companyId);
          this.alertService.success("Setting added successfully");
        },
          (error: any) => {
            this.showErrorMessage(error);
          })
    }
  }

  editSetting() {
    let newData = new CompanySetting(this.companySettingForm.value);

    if (this.companySetting !== null) {
      this.companySetting.key = newData.key;
      this.companySetting.isEnabled = newData.isEnabled;
      this.isEnabled = newData.isEnabled;
      //this.companySetting.companyId = newData.companyId;

      this.setValueByType();

      if (this.companyRole == CompanyRole.LoyaltyMerchant) {
        var loyaltySetting = new LoyaltyMerchantSetting();
        this.companyId = this.companySetting.companyId;
        this.loyaltyMerchantId = this.companySetting.companyId;

        loyaltySetting = this.setLoyaltySettingsFromCompanySetting(loyaltySetting);

        this.loyaltyMerchantService.createOrEditLoyaltyMerchantSetting(loyaltySetting)
          .subscribe((data: any) => {
            this.onSettingEditEvent.emit(this.companySetting.companyId);
            this.alertService.success("Setting edited successfully");
          },
            (error: any) => {
              this.showErrorMessage(error);
            });
      }

      else if (this.companySetting.key == 16) { // Avail Instructions- this will be saved on company table, not in company setting table

      }

      else {
        this.companySettingService.editSetting(this.companySetting)
          .subscribe((data: any) => {

            this.onSettingEditEvent.emit(this.companySetting.companyId);
            this.alertService.success("Setting edited successfully");
          },
            (error: any) => {
              this.showErrorMessage(error);
            });
      }
    }
  }

  setLoyaltySettingsFromCompanySetting(loyaltySetting: LoyaltyMerchantSetting): LoyaltyMerchantSetting {
    loyaltySetting.id = this.companySetting.id;
    loyaltySetting.loyaltyMerchantId = this.loyaltyMerchantId;
    loyaltySetting.dataType = "Json";
    loyaltySetting.isEnabled = this.companySetting.isEnabled;
    loyaltySetting.value = this.companySetting.value;

    if (this.companySetting.key == 14) {
      loyaltySetting.loyaltySettingsKey = LoyaltySettingsKey.CardTierMap;
    }
    else if (this.companySetting.key == 15) {
      loyaltySetting.loyaltySettingsKey = LoyaltySettingsKey.LoyaltyPointSettings;
    }

    return loyaltySetting;

  }

  bindFilterChangeEvent() {
    this.companySettingFilterCtrl.valueChanges.subscribe(() => {
      this.filterCompanySettings();
    });
  }

  filterCompanySettings() {
    if (!this.companySettingTypes) {
      return;
    }

    let search = this.companySettingFilterCtrl.value;
    if (!search) {
      this.filteredCompanySettingTypes.next(this.companySettingTypes);
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCompanySettingTypes.next(
      this.companySettingTypes.filter(cst => cst.text.toLowerCase().indexOf(search) > -1)
    );
  }


  close() {
    this.dialogRef.close();
  }

  showErrorMessage(error: any) {
    console.log(error);
    this.alertService.error(error.error.error[0]);
  }


  // convenience getter for easy access to form fields
  get f() { return this.companySettingForm.controls; }


  setValuesByApiEndPoint($event): void {
    this.apiEndPointJsonValues = $event;
  }

  setValuesByGiftAmount($event): void {
    this.giftAmountSetting = $event;

    this.giftAmountSettingJsonValue = JSON.stringify(this.giftAmountSetting);

  }
  setValuesByPromotionalGift($event): void {
    this.promotionalGiftSetting = $event;

    this.promotionalGiftSettingJsonValue = JSON.stringify(this.promotionalGiftSetting);
  }

  setValuesByTrendingMerchant($event): void {
    this.trendingMerchantSettingJsonValue = $event.join(',');
    this.companySetting.value = this.trendingMerchantSettingJsonValue;
  }

  setValuesByExcludesMerchant($event): void {
    this.excludeMerchantSettingJsonValue = $event.join(',');
    this.companySetting.value = this.excludeMerchantSettingJsonValue;
  }

  setValuesByDuePaymentSetting($event): void {
    this.merchantDuePaymentSetting = $event;
    this.merchantDuePaymentSettingJsonValue = JSON.stringify(this.merchantDuePaymentSetting);
  }

  setValuesMerchantPaymentAccountInfoSetting($event): void {
    this.merchantAccountInfoSetting = $event;
    this.merchantAccountInfoSettingJsonValue = JSON.stringify(this.merchantAccountInfoSetting);
  }

  setValuesByGiftUsageLimit($event): void {
    this.giftUsageLimitSetting = $event;

    this.giftUsageLimitSettingJsonValue = JSON.stringify(this.giftUsageLimitSetting);
  }

  setValuesByGiftResendPolicy($event): void {
    this.giftResendPolicySetting = $event;
    this.giftResendPolicySettingJsonValue = JSON.stringify(this.giftResendPolicySetting);
    this.companySetting.value = this.giftResendPolicySettingJsonValue;
  }

  setValuesByLoyaltyCardTierMap($event): void {
    // Json converted previously
    //this.loyaltyCardTierMapSetting = $event;
    this.loyaltyCardTierMapSettingJsonValue = $event;
    this.companySetting.value = $event;
  }

  setValuesByLoyaltyPointSetting($event): void {
    // Json converted previously
    this.loyaltyPointSettingSetting = $event;
    this.loyaltyPointSettingSettingJsonValue = JSON.stringify($event);
    this.companySetting.value = this.loyaltyPointSettingSettingJsonValue;
  }

  setValuesByAvailInstructionSetting($event): void {
    // Json converted previously
    this.availInstructions = $event;
    this.availInstructionsSettingJsonValue = JSON.stringify($event);
    this.companySetting.value = this.availInstructionsSettingJsonValue;
  }

}

