import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Offer } from '../models/offer';
import { OfferType, CompanyRole } from '../enums';
import { GetGiftGenerationReportInputDto } from '../models/gift-generation-report-input';
import { GetUnbilledOfferForCompanyInputDto } from '../models/get-unbilled-offers-for-company-input';
import { GetOffersPagedListInput } from '../models';
import { consumerOffer } from '../models/ConsumerOffer';


@Injectable({
  providedIn: 'root'
})
export class OfferService {
  redeemOffer(requestObject: { offerId: any; offerCode: any; pin: any; merchantId: any; purchaseAmount: any; outletQRCode: any; merchantInvoiceNumber: string; }) {
    throw new Error('Method not implemented.');
  }

  private offerBaseUrl = 'api/offers';
  private consumerOfferBaseUrl = 'api/consumer-offer';
  private giftCardOrderBaseUrl = 'api/giftcardorders';

  constructor(private http: HttpClient) { }

  getOffers(): Observable<any[]> {
    return this.http.get<any[]>(this.offerBaseUrl);
  }

  getOffersPagedList(input: GetOffersPagedListInput): Observable<any> {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };

    return this.http.post<any[]>(this.offerBaseUrl + '/paged-list', input, requestHeader);
  }


  getConsumerSendOffersPagedList(input: GetOffersPagedListInput): Observable<any> {
    const requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post<any[]>(this.consumerOfferBaseUrl + '/paged-list', input, requestHeader);
  }

  getOfferById(id: number): Observable<any> {
    return this.http.get<any>(this.offerBaseUrl + "/" + id);
  }

  getOffersForBilling(serviceOfferorId: number, companyRole: CompanyRole, outletQRCode?: string): Observable<any[]> {
    if (companyRole === CompanyRole.GiftSender)
      return this.http.get<any[]>(this.offerBaseUrl + '/giftsender-unbilled-gifts/giftsender/' + serviceOfferorId);
    else {
      if (outletQRCode)
        return this.http.get<any[]>(this.offerBaseUrl + '/getunbilledofferformerchant/' + serviceOfferorId + '/outletQRCode/' + outletQRCode);
      else
        return this.http.get<any[]>(this.offerBaseUrl + '/getunbilledofferformerchant/' + serviceOfferorId);

    }
  }

  getOffersForBillingPagedList(input: GetUnbilledOfferForCompanyInputDto): Observable<any> {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    //return this.http.post(this.baseUri + '/giftGenerations', input, requestHeader);

    if (input.companyRole === CompanyRole.GiftSender)
      return this.http.post<any[]>(this.offerBaseUrl + '/giftsender-unbilled-gifts/', input, requestHeader);
    else {
      if (input.outletQRCode)
        return this.http.post<any[]>(this.offerBaseUrl + '/getUnbilledOffersForMerchantPagedList/', input, requestHeader);
      else
        return this.http.post<any[]>(this.offerBaseUrl + '/getUnbilledOffersForMerchantPagedList/', input, requestHeader);

    }
  }

  getSpecializedOffer(offerType: OfferType): Observable<any[]> {
    return this.http.get<any[]>(this.offerBaseUrl + '/offer-type/' + offerType);
  }
  getSpecializedOfferByMerchant(merchantId: number, offerType: OfferType): Observable<any[]> {
    return this.http.get<any[]>(`${this.offerBaseUrl}/merchant/${merchantId}/offer-type/${offerType}`);
    //this.offerBaseUrl + '/merchant/' + merchantId + '/offer-type/' + offerType
  }
  createOffer(offer: Offer) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.offerBaseUrl, offer, requestHeader);
  }

  editOffer(offer: Offer) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.put(this.offerBaseUrl + '/' + offer.id, offer, requestHeader);
  }

  uploadFile(id: number, uploadedFileData: FormData) {
    return this.http.post(this.offerBaseUrl + '/upload-file/' + id, uploadedFileData);
  }

  activateOffer(offer: Offer) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.put(this.offerBaseUrl + '/activate', offer, requestHeader);
  }

  rejectOffer(offer: Offer) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.put(this.offerBaseUrl + '/reject', offer, requestHeader);
  }

  deleteOffer(offerId: number) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.delete(this.offerBaseUrl + '/' + offerId, requestHeader);
  }

  sendConsumerOffer(consumerOffer: consumerOffer) {
    var requestHeader = { headers: new HttpHeaders({ 'No-Auth': 'False' }) };
    return this.http.post(`${this.giftCardOrderBaseUrl}/send-consumer-gift`, consumerOffer, requestHeader);
  }

  printConsumerGift(offerId: number) {
    //var requestHeader = { headers: new HttpHeaders({ 'No-Auth': 'False' }) };
    return this.http.get<any[]>(this.consumerOfferBaseUrl + '/print-gift/' + offerId);
  }

  startOfferGolpCreditPayment(paymentSession: any) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.offerBaseUrl + '/golpcredit-start-payment', paymentSession, requestHeader);
  }

  startOfferPayment(paymentInformation: any) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.offerBaseUrl + '/start-offer-payment', paymentInformation, requestHeader);
  }

  markOfferAssignmentAsFavorite(offer: any) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.put(this.offerBaseUrl + '/mark-favorite', offer, requestHeader);
  }

  splitOffer(offer: any) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.put(this.offerBaseUrl + '/split-offer', offer, requestHeader);
  }
  getOfferSplitSetting() {
    return this.http.get<any>(this.offerBaseUrl + '/split-setting');
  }
}
