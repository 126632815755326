import { AccountServiceService } from './../../../../services/account-service.service';
import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from 'src/app/components/app-send-gift/services/shared/data.service';
import { AlertService } from 'src/app/services';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-new-stakeholder',
  templateUrl: './new-stakeholder.component.html',
  styleUrls: ['./new-stakeholder.component.css']
})
export class NewStakeholderComponent implements OnInit {

  onAddStakeholderEvent: EventEmitter<number> = new EventEmitter();
  onUpdateStakeholderEvent: EventEmitter<number> = new EventEmitter();

  inputData: any;

  //form related

  stakeholderForm: FormGroup;
  submitted = false;
  
  // xl related
  public showSingleForm:boolean = true;
  public xlData:any[] = [];
  public showFileUploadOption = true;

  //file related 
  fileValidationError: string;
  fileName: string = '';
  assignmentFiles:any;

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<NewStakeholderComponent>,
    private dataService: DataService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) data, private accountService:AccountServiceService) { 
      this.inputData = data;
      if(this.inputData != null){
        this.showFileUploadOption = false;
      }
      this.buildForm();
    }

  ngOnInit(): void {
  }

  onSubmit() {
    this.submitted = true;
    let userInfo = this.accountService.getLoggedInUserInfo();

    // stop here if form is invalid
    if (this.stakeholderForm.invalid && this.showSingleForm) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    
    
    if(this.showSingleForm){
      let stakeHolder = {
        "firstName":this.stakeholderForm.value.firstName,
        "lastName":this.stakeholderForm.value.lastName,
        "phoneNumber":this.stakeholderForm.value.phone,
        "email":this.stakeholderForm.value.email,
        "dateOfBirth":this.stakeholderForm.value.dob,
        "gender":this.stakeholderForm.value.gender,
        "designation":this.stakeholderForm.value.designation,
        "stakeHolderType":this.stakeholderForm.value.stakeHolderType
      }
      if(this.showFileUploadOption){
        let payload = {
          "companyId":userInfo.companyId,
          "stakeHolders":[stakeHolder]
        }
        let url = "api/StakeHolder";
      this.dataService.post(url,payload)
        .subscribe((response: any) => {
          if (response.success) {
            this.onAddStakeholderEvent.emit(1);
            this.alertService.success('Stakeholder added successfully');
            this.dialogRef.close();
          }
          if (!response.success) {
            this.alertService.error(response.data);
          }
        },
          (error: any) => {
          });
      }
      else{
        stakeHolder["id"] = this.inputData.id;
        let url = "api/StakeHolder";
        this.dataService.put(url,stakeHolder)
          .subscribe((response: any) => {
            if (response.success) {
              this.onUpdateStakeholderEvent.emit(1);
              this.alertService.success('Stakeholder updated successfully');
              this.dialogRef.close();
            }
            if (!response.success) {
              this.alertService.error(response.data);
            }
          },
            (error: any) => {
            });
      }
    }
    else{
      let url = `api/StakeHolder/bulk-upload`;
      this.dataService.post(url,this.assignmentFiles)
          .subscribe((response: any) => {
            if (response.success) {
              this.onAddStakeholderEvent.emit(1);
              this.alertService.success('Stakeholders added successfully');
              this.dialogRef.close();
            }
            if (!response.success) {
              this.alertService.error(response.data);
              this.dialogRef.close();
            }
          },
            (error: any) => {
            });
    }
   
    
    
  }

  buildForm(){
    this.stakeholderForm = this.formBuilder.group({
      phone: [this.inputData?.phoneNumber, [Validators.required]],
      firstName: this.inputData?.firstName,
      lastName: this.inputData?.lastName,
      dob: this.inputData?.dateOfBirth,
      designation:this.inputData?.designation,
      email:this.inputData?.email,
      gender: (this.inputData != null && this.inputData?.gender)? this.inputData?.gender: 'Male',
      stakeHolderType:[(this.inputData != null && this.inputData?.stakeHolderType)? this.inputData?.stakeHolderType: 'Employee', [Validators.required]]
    });
  }

  close() {
    this.dialogRef.close();
  }

  uploadLogo(files) {
    this.fileValidationError = null;
    this.showSingleForm = false;
    if (files.length === 0)
      return;

    const formData = new FormData();

    if (files[0].size > (1024 * 1024)) {
      this.fileValidationError = "Maximum file size should be 1 MB";
      return;
    }

    if (files[0].type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.fileValidationError = "Only Excel files allowed";
      return;
    }
    let userInfo = this.accountService.getLoggedInUserInfo();
    for (let file of files) {
      this.fileName += ' ' + file.name;
      // this.getJsonFromFile(file);
      formData.append(file.name, file);
      formData.append("companyId", userInfo.companyId);
    }
    this.assignmentFiles = formData;
  }

  // convenience getter for easy access to form fields
  get f() { return this.stakeholderForm.controls; }

  
  getJsonFromFile(file: any) {
    let exceltoJson = {};
    let headerJson = {};
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(file);
    exceltoJson['filename'] = file.name;
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
      for (var i = 0; i < wb.SheetNames.length; ++i) {
        const wsname: string = wb.SheetNames[i];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
        exceltoJson[`sheet${i + 1}`] = data;
        const headers = this.get_header_row(ws);
        headerJson[`header${i + 1}`] = headers;
        //  console.log("json",headers)
      }
      exceltoJson['headers'] = headerJson;
      console.log(exceltoJson);
      this.xlData.push(exceltoJson);
      return exceltoJson;
  
    }
  }

  get_header_row(sheet) {
    var headers = [];
    var range = XLSX.utils.decode_range(sheet['!ref']);
    var C, R = range.s.r; /* start in the first row */
    /* walk every column in the range */
    for (C = range.s.c; C <= range.e.c; ++C) {
      var cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })] /* find the cell in the first row */
      // console.log("cell",cell)
      var hdr = "UNKNOWN " + C; // <-- replace with your desired default 
      if (cell && cell.t) {
        hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
    }
    return headers;
  }

  openFileBrowser(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById('stakeHolderFileId') as HTMLElement;
    element.click();
  }
}
