export class PremiumUserCreditLimitExceedOutput{
    companyId: number;
    consumerId?: any;
    golpCreditType: number;
    giftSender: string;
    expiredOn: Date;

    public constructor(init?: Partial<PremiumUserCreditLimitExceedOutput>) {
        Object.assign(this, init);
    }
}
