import { CompanyRole, UserType } from "../enums";

export class UserModel {
  id: number;
  userName: string;
  email: string;
  firstName: string;
  middleInitial: string;
  lastName: string;
  phoneNumber: string;
  profileImageName: string;
  profileImageUrl: string;
  companyId: number;
  roleId: number;
  fullName: string;
  companyName: string;
  password: string;
  confirmPassword: string;
  newPassword: string;
  companyRoleType: CompanyRole;
  userScope: any;
  companyOutletId?: number;
  userType: UserType;
  public constructor(init?: Partial<UserModel>) {
    Object.assign(this, init);
  }
}
