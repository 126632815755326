import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert.service';
import { DataService } from '../app-send-gift/services/shared/data.service';

@Component({
  selector: 'app-block-gift-card',
  templateUrl: './block-gift-card.component.html',
  styleUrls: ['./block-gift-card.component.css']
})
export class BlockGiftCardComponent implements OnInit {

  onGiftCardBlockEvent: EventEmitter<boolean> = new EventEmitter();
  giftId: any;
  giftCardBlockForm: FormGroup;

  constructor(private dialogRef: MatDialogRef<BlockGiftCardComponent>,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) data
  ) {

    if (data !== null) {
      this.giftId = data;
    }

    this.giftCardBlockForm = this.formBuilder.group({
      notes: ''
    });
  }

  ngOnInit(): void {
  }

  onSubmit() {
    let requestModel = {
      "giftId": this.giftId,
      "notes": this.f.notes.value
    };

    let url = `api/OfferAssignments/block-gift-card`;
    this.dataService.post(url, requestModel)
      .subscribe((response: any) => {
        if (response.success) {
          this.onGiftCardBlockEvent.emit(true);
          this.alertService.success('Gift card blocked successfully');
          this.dialogRef.close();
          this.dialogRef.close();
        }
        else {
          this.alertService.success(response.data);
        }
      });
  }

  close() {
    this.dialogRef.close();
  }

  // convenience getter for easy access to form fields
  get f() { return this.giftCardBlockForm.controls; }

}
