
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { Select2OptionData } from 'ng-select2';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import { AlertService } from 'src/app/services';
import { DataService } from 'src/app/components/app-send-gift/services/shared/data.service';
@Component({
  selector: 'app-bill-claim',
  templateUrl: './bill-claim.component.html',
  styleUrls: ['./bill-claim.component.css']
})
export class BillClaimComponent implements OnInit {

  submitted: boolean;
  billClaimForm: FormGroup;
  select2CompanyKeyInfo: Array<Select2OptionData>;

  //form-data
  select2PaymentOptionKeyInfo: Array<Select2OptionData>;

  //form-validation
  paymentMethodNotSelected: boolean = true;
  selectedPaymentMethod: any;
  amountError = "";

  // data from parent component
  bill: any;
  billId: any;
  billReceivableAmount: number;

  // data from server
  paymentOptions: any[];

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<BillClaimComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private alertService: AlertService,
    private dataService: DataService
  ) {
    this.bill = data;
    this.billReceivableAmount = this.bill?.billAmount - this.bill?.commission;
    if (this.bill != undefined && this.bill != null) {
      this.billId = this.bill.id;
    }
    this.loadPaymentOptions();
    this.buildForm();
  }



  ngOnInit(): void { }

  buildForm() {
    this.billClaimForm = this.formBuilder.group({
      amount: [this.billReceivableAmount, Validators.required],
      paymentNotes: ['', Validators.nullValidator]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.billClaimForm.controls;
  }

  close() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.billClaimForm.invalid) {
      // ValidationUtility.getError_Scroll(document);
      return;
    }

    if (this.selectedPaymentMethod == undefined || this.selectedPaymentMethod == "") {
      this.paymentMethodNotSelected = true;
      return;
    }
    this.paymentMethodNotSelected = false;

    if (Number(this.billClaimForm.value.amount) <= 0) {
      this.amountError = "Amount must be greater than 0."
      return 0;
    }
    this.amountError = "";

    //communicate with server
    let url = 'api/Bills/bill-bank-payment';
    let payload = {
      billId: this.billId,
      amount: Number(this.billClaimForm.value.amount),
      payThrough: this.selectedPaymentMethod,
      paymentNotes: this.billClaimForm.value.paymentNotes,
    };
    this.dataService.post(url, payload).subscribe(
      (res: any) => {
        if (res.result) {
          this.alertService.success('Bill calimed successfully.');
          this.close();
        } else {
          this.alertService.error(res.message);
        }
      },
      (err) => console.log(err)
    );
  }

  loadPaymentOptions() {
    let url = "api/Bills/bill-payment-option";
    this.dataService.post(url, null).subscribe((res: any) => {
      if (res.success) {
        this.paymentOptions = res.data;
        this.paymentOptions = this.paymentOptions.filter((p) => p.key === "Bank" || p.key === "Cheque");
        this.select2PaymentOptionKeyInfo = this.paymentOptions.map((p) => {
          return {
            id: p.key,
            text: p.key,
          };
        });
        let selectPaymentOption: Select2OptionData = {
          id: "Select",
          text: "Select Payment Option",
        };
        this.select2PaymentOptionKeyInfo.unshift(selectPaymentOption);
      }
      else {
        this.alertService.error("Payment options not available.");
        this.close();
      }

    }, err => console.log(err));
  }
}
