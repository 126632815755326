import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from '../../../services/shared.service';
import { MerchantAccountInfoSetting, bKashAccountInfo, BankAccountInfo } from '../../../models';

@Component({
    selector: 'app-create-merchant-account-info-setting',
    templateUrl: './create-merchant-account-info-setting.component.html',
    styleUrls: ['./create-merchant-account-info-setting.component.css']
})

export class CreateMerchantAccountInfoSettingComponent implements OnInit {

    @Output() merchantAccountInfoEmitter: EventEmitter<any> = new EventEmitter<any>();
    merchantAccountInfoSetting: MerchantAccountInfoSetting = new MerchantAccountInfoSetting();
    payBy: number;
    showOnDay: number;
    payByTypes: any;
    bKashAccountTypes: any;

    bkashAccount: bKashAccountInfo = new bKashAccountInfo();
    bankAccount: BankAccountInfo = new BankAccountInfo({ accountName: "", accountNumber: null, bankName: "", branchName: "", routingNumber: null });

    constructor(private formBuilder: FormBuilder, private sharedService: SharedService) {

    }

    merchantAccountInfoSettingForm: FormGroup;
    @Input() isSubmitted: false;

    submitted = false;

    @Input() existingMerchantAccountInfoSetting: any;

    ngOnInit() {
        this.merchantAccountInfoSetting.bankAccount = this.bankAccount;
        this.merchantAccountInfoSetting.bkashAccount = this.bkashAccount;

        this.buildForm();
        this.setPayByValidators();
        this.getPayByTypes();
        this.getbkashAccountTypes();

        if (this.existingMerchantAccountInfoSetting) {
            this.merchantAccountInfoSetting = JSON.parse(this.existingMerchantAccountInfoSetting); // Need to convert json to this type;

            this.payBy = this.merchantAccountInfoSetting.payBy;
            this.bkashAccount = this.merchantAccountInfoSetting.bkashAccount;
            this.bankAccount = this.merchantAccountInfoSetting.bankAccount;

            this.merchantAccountInfoSettingForm.patchValue({
                payBy: this.merchantAccountInfoSetting.payBy,
                bKashNumber: this.bkashAccount ? this.bkashAccount.bKashNumber : '',
                bKashAccountType: this.bkashAccount ? this.bkashAccount.bKashAccountType : '',
                bankName: this.bankAccount ? this.bankAccount.bankName : '',
                branchName: this.bankAccount ? this.bankAccount.branchName : '',
                routingNumber: this.bankAccount ? this.bankAccount.routingNumber : '',
                accountName: this.bankAccount ? this.bankAccount.accountName : '',
                accountNumber: this.bankAccount ? this.bankAccount.accountNumber : '',
            });

            //this.merchantAccountInfoSettingForm.controls.payBy.setValue(this.payBy);

        }
        else {
            this.merchantAccountInfoSetting = new MerchantAccountInfoSetting();
            this.merchantAccountInfoSetting.bankAccount = this.bankAccount;
            this.merchantAccountInfoSetting.bkashAccount = this.bkashAccount;
        }

        this.setMerchantDuePaymentAndEmit();

    }
    getPayByTypes() {
        var paybyTypeList = [{ text: 'bKash', value: 4 }, { text: 'Bank', value: 3 }];

        this.payByTypes = paybyTypeList;
    }

    ngOnChanges() {
        this.submitted = this.isSubmitted;
    }
    
    getbkashAccountTypes() {
        this.bKashAccountTypes= this.sharedService.getbKashAccountTypes();
    }

    buildForm() {
        this.merchantAccountInfoSettingForm = this.formBuilder.group({
            payBy: [this.payBy, Validators.required],
            bKashNumber: [this.bkashAccount.bKashNumber, Validators.required],
            bKashAccountType: [this.bkashAccount.bKashAccountType, Validators.required],
            bankName: [this.bankAccount.bankName, Validators.required],
            branchName: [this.bankAccount.branchName, Validators.required],
            routingNumber: [this.bankAccount.routingNumber, Validators.required],
            accountName: [this.bankAccount.accountName, Validators.required],
            accountNumber: [this.bankAccount.accountNumber, Validators.required],
        });
    }


    setPayByValidators() {
        const payByControl1 = this.merchantAccountInfoSettingForm.get('payBy');
        const bKashNumberControl = this.merchantAccountInfoSettingForm.get('bKashNumber');
        const bKashAccountTypeControl = this.merchantAccountInfoSettingForm.get('bKashAccountType');
        const bankNameControl = this.merchantAccountInfoSettingForm.get('bankName');
        const branchNameControl = this.merchantAccountInfoSettingForm.get('branchName');
        const routingNumberControl = this.merchantAccountInfoSettingForm.get('routingNumber');
        const accountNameControl = this.merchantAccountInfoSettingForm.get('accountName');
        const accountNumberControl = this.merchantAccountInfoSettingForm.get('accountNumber');

        this.merchantAccountInfoSettingForm.get('payBy').valueChanges
            .subscribe(payByControl => {

                if (payByControl === 'bKash' || payByControl == 4) {
                    if (!this.bkashAccount) {
                        this.bkashAccount = new bKashAccountInfo();
                    }
                    bKashNumberControl.setValidators([Validators.required]);
                    bKashAccountTypeControl.setValidators([Validators.required]);
                    bankNameControl.setValidators(null);
                    branchNameControl.setValidators(null);
                    routingNumberControl.setValidators(null);
                    accountNameControl.setValidators(null);
                    accountNumberControl.setValidators(null);
                }

                if (payByControl === 'Bank' || payByControl == 3) {
                    if (!this.bankAccount) {
                        this.bankAccount = new BankAccountInfo({ accountName: "", accountNumber: null, bankName: "", branchName: "", routingNumber: null });
                    }
                    bKashNumberControl.setValidators(null);
                    bKashAccountTypeControl.setValidators(null);
                    bankNameControl.setValidators([Validators.required]);
                    branchNameControl.setValidators([Validators.required]);
                    routingNumberControl.setValidators([Validators.required]);
                    accountNameControl.setValidators([Validators.required]);
                    accountNumberControl.setValidators([Validators.required]);
                }

                bKashNumberControl.updateValueAndValidity();
                bKashAccountTypeControl.updateValueAndValidity();
                bankNameControl.updateValueAndValidity();
                branchNameControl.updateValueAndValidity();
                routingNumberControl.updateValueAndValidity();
                accountNameControl.updateValueAndValidity();
                accountNumberControl.updateValueAndValidity();

            });
    }

    onChangePayByMethod($event) {
        this.merchantAccountInfoSetting.payBy = this.payBy;
        const payByControl1 = this.merchantAccountInfoSettingForm.get('payBy');
        payByControl1.setValue(this.payBy);
        payByControl1.updateValueAndValidity();
        this.setMerchantDuePaymentAndEmit();
    }

    onChangemerchantAccountInfoType($event) {
        this.setMerchantDuePaymentAndEmit();
    }


    get f() { return this.merchantAccountInfoSettingForm.controls; }


    setMerchantAccountInfo() {
        this.merchantAccountInfoSetting.payBy = this.f.payBy.value;
        if (this.merchantAccountInfoSetting.payBy == 4) { // bKash
            var bkashAccountModel = new bKashAccountInfo();
            bkashAccountModel.bKashNumber = this.f.bKashNumber.value;
            bkashAccountModel.bKashAccountType = this.f.bKashAccountType.value;

            this.merchantAccountInfoSetting.bkashAccount = bkashAccountModel;
        }
        else if (this.merchantAccountInfoSetting.payBy == 3) { // bank
            var bankAccountModel = new BankAccountInfo();
            bankAccountModel.bankName = this.f.bankName.value ? this.f.bankName.value : "";
            bankAccountModel.branchName = this.f.branchName.value ? this.f.branchName.value : "";
            bankAccountModel.routingNumber = this.f.routingNumber.value ? this.f.routingNumber.value : "";
            bankAccountModel.accountName = this.f.accountName.value ? this.f.accountName.value : "";
            bankAccountModel.accountNumber = this.f.accountNumber.value ? this.f.accountNumber.value : "";

            this.merchantAccountInfoSetting.bankAccount = bankAccountModel;
        }
    }


    setMerchantDuePaymentAndEmit() {
        this.setMerchantAccountInfo();
        this.merchantAccountInfoEmitter.emit(this.merchantAccountInfoSetting);
    }


}


