import { Component, OnInit, Inject, EventEmitter, Injector } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BillingService, CompanyOutletService } from 'src/app/services';
import { AddTransaction } from 'src/app/models/add-transaction';
import { AlertService } from 'src/app/services';
import { PaymentType, TransactionType, CompanyRole } from "src/app/enums";
import * as moment from 'moment';
import { CompanyOutlet } from '../../models';
import { ReplaySubject } from 'rxjs';
import { CompanySettingType } from '../../enums/company-setting.type';
import { PaymentOnOutletOrCentrallyType } from '../../enums/PaymentOnOutletOrCentrally-type';
import { CompanySettingService } from '../../services/company-setting.service';
import { ValidationUtility } from '../../utility/validation.utility';
import { BaseComponent } from '../base/base.component';

@Component({
    selector: 'app-add-transaction',
    templateUrl: './add-transaction.component.html',
    styleUrls: ['./add-transaction.component.css']
})
export class AddTransactionComponent extends BaseComponent implements OnInit {
    // onCreate event
    onTransactionCreateEvent: EventEmitter<number> = new EventEmitter();

    addTransactionFrom: FormGroup;
    transaction: AddTransaction;
    submitted = false;
    userInfo: any;
    companyId: number;
    companyRole: CompanyRole;
    transactionType: TransactionType;
    transactionTypes: any[];
    paymentType: PaymentType;
    paymentTypes: any[];
    amount: number;
    referenceNumber: string;
    paymentDate: Date;
    notes: string;
    outletQRCode: any;
    isOutletSpecific: boolean = false;
    companyOutlets: CompanyOutlet[];
    selectedOutlet: string;
    disableSelectOutlet: boolean = false;

    outletFilterCtrl: FormControl = new FormControl();
    filteredOutlets: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    isSystemAdminOrSalesManager: boolean = false;

    // const numericNumberReg = '^-?[0-9]\\d*(\\.\\d{1,2})?$';

    constructor(
        private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<AddTransactionComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private billingService: BillingService,
        private companySettingService: CompanySettingService,
        private outletService: CompanyOutletService,
        private alertService: AlertService, injector: Injector) {
        super(injector);
        if (data !== null) {
            this.companyId = data.companyId;
            this.companyRole = data.companyRole;
            this.userInfo = data.userInfo;
            this.outletQRCode = data.outletQRCode;
            this.isSystemAdminOrSalesManager = data.isSystemAdminOrSalesManager;

            console.log(data);
        }
    }

    ngOnInit() {
        this.transaction = new AddTransaction();
        this.transactionTypes = [{ text: 'Cash', value: 1 }, { text: 'Checque', value: 2 }, { text: 'Bank', value: 3 },
        { text: 'bKash', value: 4 }, { text: 'Rocket', value: 5 }, { text: 'Card', value: 6 }];


        //if (this.isSystemAdminOrSalesManager) { // Changed according to Zafor vi
        if (this.checkPermission(['CreateTransaction'])) {
            this.paymentTypes = [{ text: 'Golp To Gift Sender', value: 1 }, { text: 'Gift Sender To Golp', value: 2 }];
            this.paymentType = PaymentType.GiftSenderToGolp;
            if (this.companyRole == CompanyRole.Merchant) {
                this.paymentTypes = [{ text: 'Golp To Merchant', value: 3 }, { text: 'Merchant To Golp', value: 4 }];
                this.paymentType = PaymentType.GolpToMerchant;
            }
        } else {
            this.paymentTypes = [{ text: 'Gift Sender To Golp', value: 2 }];
            this.paymentType = PaymentType.GiftSenderToGolp;
            if (this.companyRole == CompanyRole.Merchant) {
                this.paymentTypes = [{ text: 'Merchant To Golp', value: 4 }];
                this.paymentType = PaymentType.MerchantToGolp;
            }
        }

        const numericNumberReg1 = '^[1-9]\d*(\.\d+)?$';


        this.addTransactionFrom = this.formBuilder.group({
            amount: [this.amount, [Validators.required, Validators.pattern('^-?[1-9]\\d*(\\.\\d{1,5})?$')]],
            //Validators.pattern('^\$[1-9]+(\.[0-9][0-9])?$')--------to allow decimal
            transactionType: [this.transactionType, Validators.required],
            paymentType: [this.paymentType, Validators.required],
            referenceNumber: [this.referenceNumber],
            paymentDate: [this.paymentDate, Validators.required],
            notes: [this.notes]
        });

        this.companySettingService.getSettingsByCompanyAndType(CompanySettingType.PaymentOnOutletOrCentrally, this.companyId).subscribe(result => {
            if (result && !!result.isEnabled && +result.value == PaymentOnOutletOrCentrallyType.OutletSpecific) {  //&& this.isMerchant
                this.isOutletSpecific = true;
                this.getOutletsByCompany(this.companyId);

            }
        });
        this.bindOutletFilterChangeEvent();
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.addTransactionFrom.invalid) {
            ValidationUtility.getError_Scroll(document);
            return;
        }

        this.transaction = new AddTransaction(this.addTransactionFrom.value);
        this.transaction.companyId = this.companyId;
        //this.transaction.outletQRCode = this.outletQRCode;
        if (this.outletQRCode)
            this.transaction.outletQRCode = this.outletQRCode;
        else if (this.isOutletSpecific)
            this.transaction.outletQRCode = this.selectedOutlet;

        var aa = moment.utc(this.transaction.paymentDate).format("YYYY/MM/DD");
        var paymentDate1 = moment.utc(aa).format();

        var paymentDate = moment(this.transaction.paymentDate, "YYYY/MM/DD").format();

        this.transaction.paymentDate = paymentDate;

        this.billingService.addTransactionForCompany(this.transaction)
            .subscribe((data: any) => {
                console.log(data);
                this.onTransactionCreateEvent.emit(this.transaction.companyId);
            },
                (error: any) => {
                    console.log(error);
                });

        this.dialogRef.close();
    }

    bindOutletFilterChangeEvent() {
        // listen for search field value changes
        this.outletFilterCtrl.valueChanges
            .subscribe(() => {
                this.filterOutlets();
            });
    }

    getOutletsByCompany(companyId) {
        if (companyId !== undefined) {
            this.outletService.getOutletsByCompany(companyId)
                .subscribe((data: any) => {
                    this.companyOutlets = data;
                    this.filteredOutlets.next(this.companyOutlets);

                    if (!this.outletQRCode)
                        this.selectedOutlet = data[0].qrCode;
                    else {
                        this.selectedOutlet = this.outletQRCode;
                        this.disableSelectOutlet = true;
                    }

                    this.onChangeSelectedOutlet();
                },
                    (error: any) => {
                        console.log(error);
                    });
        }
    }

    onChangeSelectedOutlet() {
        //this.offers = this.offersWithoutFilter.filter(x => x.outletQrCode == this.selectedOutlet);
    }


    protected filterOutlets() {
        if (!this.companyOutlets) {
            return;
        }
        // get the search keyword
        let search = this.outletFilterCtrl.value;
        if (!search) {
            this.filteredOutlets.next(this.companyOutlets);
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the CompanyCategories
        this.filteredOutlets.next(
            this.companyOutlets.filter(role => role.name.toLowerCase().indexOf(search) > -1)
        );
    }

    close() {
        this.dialogRef.close();
    }

    // convenience getter for easy access to form fields
    get f() { return this.addTransactionFrom.controls; }
}
