
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PrivilegeCardRoutingModule } from './privilege-card-routing.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material';
import { FilterPipeModule } from 'ngx-filter-pipe';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
//components
import { PrivilegeCardComponent } from './components/privilege-card/privilege-card.component';
import { NewPrivilegeCardComponent } from './components/new-privilege-card/new-privilege-card.component';
import { PrivilegeCardDetailsComponent } from './components/privilege-card-details/privilege-card-details.component';
import { PrivilegeCardFacilitiesComponent } from './components/privilege-card-facilities/privilege-card-facilities.component';
import { NewFacilityComponent } from './components/privilege-card-facilities/new-facility/new-facility.component';
import { PrivilegeCardConditionsComponent } from './components/privilege-card-conditions/privilege-card-conditions.component';
import { PrivilegeCardAssignmentComponent } from './components/privilege-card-assignment/privilege-card-assignment.component';
import { NewCardAssignmentComponent } from './components/privilege-card-assignment/new-card-assignment/new-card-assignment.component';
import { CreateCardInformationalConditionComponent } from './components/privilege-card-conditions/create-card-informational-condition/create-card-informational-condition.component';
import { CreateCardOperationalConditionComponent } from './components/privilege-card-conditions/create-card-operational-condition/create-card-operational-condition.component';
import { CardInformationConditionComponent } from './components/privilege-card-conditions/card-information-condition/card-information-condition.component';
import { CardTimeRangeOperationConditionComponent } from './components/privilege-card-conditions/date-time-operational-conditions/time-range-operation-condition.component';
import { CardDateRangeOperationConditionComponent } from './components/privilege-card-conditions/date-time-operational-conditions/date-range-operation-condition.component';
import { UserPrivilegeCardComponent } from './components/user/user-privilege-card/user-privilege-card.component';
import { UserPrivilegeCardDetailsComponent } from './components/user/user-privilege-card-details/user-privilege-card-details.component';
import { AvailCardComponent } from './components/avail-process/avail-card/avail-card.component';
import { PrivilegeCardRedemptionComponent } from './components/avail-process/privilege-card-redemption/privilege-card-redemption.component';
import { UserPrivilegeCardFacilitiesComponent } from './components/user/user-privilege-card-facilities/user-privilege-card-facilities.component';
import { NgSelect2Module } from 'ng-select2';
import { AvailTransactionHistoryComponent } from './components/avail-process/avail-transaction-history/avail-transaction-history.component';
import { ManageStakeholdersComponent } from './components/manage-stakeholders/manage-stakeholders/manage-stakeholders.component';
import { NewStakeholderComponent } from './components/manage-stakeholders/new-stakeholder/new-stakeholder.component';
import { SelectPrivilegeCardComponent } from './components/select-privilege-card/select-privilege-card.component';
import { ActivateCardComponent } from './components/privilege-card-assignment/activate-card/activate-card.component';
import { PrivilegeCardIssuerComponent } from './components/privilege-card-issuer/privilege-card-issuer.component';
import { AddCardIssuerComponent } from './components/privilege-card-issuer/add-card-issuer/add-card-issuer.component';


@NgModule({
  declarations: [
    PrivilegeCardComponent,
    NewPrivilegeCardComponent,
    PrivilegeCardDetailsComponent,
    PrivilegeCardFacilitiesComponent,
    NewFacilityComponent,
    PrivilegeCardConditionsComponent,
    PrivilegeCardAssignmentComponent,
    NewCardAssignmentComponent,
    CreateCardInformationalConditionComponent,
    CreateCardOperationalConditionComponent,
    CardInformationConditionComponent,
    CardTimeRangeOperationConditionComponent,
    CardDateRangeOperationConditionComponent,
    UserPrivilegeCardComponent,
    UserPrivilegeCardDetailsComponent,
    AvailCardComponent,
    PrivilegeCardRedemptionComponent,
    UserPrivilegeCardFacilitiesComponent,
    AvailTransactionHistoryComponent,
    ManageStakeholdersComponent,
    NewStakeholderComponent,
    SelectPrivilegeCardComponent,
    ActivateCardComponent,
    PrivilegeCardIssuerComponent,
    AddCardIssuerComponent
  ],
  imports: [
    CommonModule,
    PrivilegeCardRoutingModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FilterPipeModule,
    NgxMaterialTimepickerModule,
    NgSelect2Module
  ],
  entryComponents:[NewPrivilegeCardComponent, NewFacilityComponent, NewCardAssignmentComponent, CreateCardInformationalConditionComponent,
    CreateCardOperationalConditionComponent,
    CardInformationConditionComponent, NewStakeholderComponent],
  exports: [
    PrivilegeCardComponent,
    NewPrivilegeCardComponent,
    PrivilegeCardDetailsComponent,
    PrivilegeCardFacilitiesComponent,
    NewFacilityComponent,
    PrivilegeCardConditionsComponent,
    PrivilegeCardAssignmentComponent,
    NewCardAssignmentComponent,
    CreateCardInformationalConditionComponent,
    CreateCardOperationalConditionComponent,
    CardInformationConditionComponent,
    CardTimeRangeOperationConditionComponent,
    CardDateRangeOperationConditionComponent,
    UserPrivilegeCardComponent,
    UserPrivilegeCardDetailsComponent,
    AvailCardComponent,
    PrivilegeCardRedemptionComponent,
    UserPrivilegeCardFacilitiesComponent]
})
export class PrivilegeCardModule { }
