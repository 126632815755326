import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/base/base.component';
import { CompanyRole } from 'src/app/enums';
import { AccountServiceService } from 'src/app/services';

@Component({
  selector: 'app-send-gift-report',
  templateUrl: './send-gift-report.component.html',
  styleUrls: ['./send-gift-report.component.css']
})
export class SendGiftReportComponent extends BaseComponent implements OnInit {

  offerReportsSubMenu: any[] = [];
  selectedSubMenuIndex: number = 0;
  activeSubMenu: string = "Offer Summary";
  isMerchantLoggedIn: boolean = false;

  constructor(private injector: Injector, private accountService: AccountServiceService) {
    super(injector);
  }

  ngOnInit(): void {
  }
}
