import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { CorporateOffer } from '../../models/corporate-offer';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountServiceService, AlertService } from 'src/app/services';
import { CompanyRole } from 'src/app/enums';

@Component({
  selector: 'app-create-corporate-offer',
  templateUrl: './create-corporate-offer.component.html',
  styleUrls: ['./create-corporate-offer.component.css']
})
export class CreateCorporateOfferComponent implements OnInit {

  // onCreate event
  onCorporateOfferCreateEvent: EventEmitter<CorporateOffer> = new EventEmitter();
  // onEdit event;
  onCorporateOfferEditEvent: EventEmitter<CorporateOffer> = new EventEmitter();

  userInfo: any;
  createTypes: any[];
  createType: string;

  offerId: any = null;
  //inEditMode: boolean = false;
  showTypeSelection: boolean = true;

  constructor(private dialogRef: MatDialogRef<CreateCorporateOfferComponent>,
    private alertService: AlertService,
    private accountService: AccountServiceService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.userInfo = this.accountService.getLoggedInUserInfo();
    this.createType = "single";
    this.createTypes = [
      { "id": "single", "name": "Create Single Offer" },
      { "id": "bulk", "name": "Create Bulk Offer" }
    ];

    this.showTypeSelection = this.userInfo.companyRole === CompanyRole.GiftSender ? false : true;

    if (isNaN(data) && data.offer != null) {
      this.offerId = data.offer.id;
      this.showTypeSelection = false;
    }
  }

  ngOnInit() {

  }

  singleOfferCreateEditEventHandler($event) {
    if ($event.operation === 'edit') {
      this.onCorporateOfferEditEvent.emit($event.data);
      this.alertService.success("Offer edited successfully.")
      this.dialogRef.close();
    }
    else {
      this.onCorporateOfferCreateEvent.emit($event.data);
      this.alertService.success("Offer created successfully.")
      this.dialogRef.close();
    }
  }

  bulkOfferCreateEventHandler($event) {
    this.onCorporateOfferCreateEvent.emit($event);
    this.alertService.success("Offers send successfully.")
    this.dialogRef.close();
  }

  onCreateTypChange($event) {
    this.createType = $event.value;
  }

  close() {
    this.dialogRef.close();
  }

}

