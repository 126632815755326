import { Injectable } from '@angular/core';
import * as SignalR from '@aspnet/signalr';
import { Observable, of } from 'rxjs';
import { Message } from '../models/message';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private notificationBaseUri = 'api/notification';
  
  constructor(private http: HttpClient) { }

  getHeaderNotification(): Observable<any[]>{
    return this.http.get<any[]>(this.notificationBaseUri + '/general-notifications' + '?pageNumber=1&pageSize=5');
  }

  getNotifications(pageNumber: number, pageSize: number): Observable<any[]>{
    return this.http.get<any[]>(this.notificationBaseUri + '/general-notifications' + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize);
  }

  markAllNotificationAsRead(): Observable<any>{
    var requestHeader = {headers: new HttpHeaders({ 'Content-Type': 'application/json','No-Auth':'False' })};
    return this.http.put(this.notificationBaseUri + '/general-notifications/mark-all-as-read', requestHeader);
  }

}
