import { Component, OnInit, Injector } from '@angular/core';
import { Company } from 'src/app/models';
import { CompanyRole } from 'src/app/enums';
import { AccountServiceService, CommonService, AlertService, CompanyService } from 'src/app/services';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-customer-support-base',
  templateUrl: './customer-support-base.component.html',
  styleUrls: ['./customer-support-base.component.css']
})

export class CustomerSupportBaseComponent extends BaseComponent implements OnInit {

  dataLoaded: Promise<boolean>;

  userInfo: any;
  canCreate: boolean = true;

  selectedsiteAdminName: string;
  siteAdminSubMenu: any[] = [];
  selectedSubMenuIndex: number = 0;
  activeSubMenu: string = "Open & Change Receiver";

  companyRoleType: CompanyRole = CompanyRole.None;

  constructor(private companyService: CompanyService, private dialog: MatDialog,
    private accountService: AccountServiceService,
    private commonService: CommonService,
    private alertService: AlertService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.getLoggedInUser();
    this.siteAdminSubMenu.push({ "name": "Open & Change Receiver", "dataTarget": "giftsTab" });
    this.dataLoaded = Promise.resolve(true);

  }

  onSubMenuChange(name, index) {
    if (this.selectedSubMenuIndex != index) {
      this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
      this.activeSubMenu = this.siteAdminSubMenu.filter(c => c.name === name)[0].name;
    }
  }

  getLoggedInUser() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
    this.canCreate = true;
  }

}

