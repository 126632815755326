import { Component, OnInit } from '@angular/core';
import { AccountServiceService, AlertService } from 'src/app/services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserModel } from 'src/app/models';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.css']
})
export class ProfileSettingsComponent implements OnInit {

  userInfo: UserModel;

  profileSettingsForm: FormGroup;
  submitted = false;
  uploadedFiles: FormData;

  fileName: string = '';

  constructor(private accountService: AccountServiceService,
    private formBuilder: FormBuilder,
    private alertService: AlertService
    ) { 
      this.getLoggedInUser();
      
      this.profileSettingsForm = this.formBuilder.group({
        firstName: [this.userInfo.firstName, Validators.required],
        lastName: [this.userInfo.lastName, Validators.required],
      });

      this.fileName = this.userInfo.profileImageName;
    }

  ngOnInit() {
  }

  uploadLogo(files){
    if (files.length === 0)
      return;
    const formData = new FormData();

    for (let file of files){
      formData.append(file.name, file);
      this.fileName = file.name;
    }
    this.uploadedFiles = formData;
  }

  onSubmit() {
    this.submitted = true;
    const formData = new FormData();

    // stop here if form is invalid
    if (this.profileSettingsForm.invalid) {
       return;
    }
    
    let newData = new UserModel(this.profileSettingsForm.value);
    if(this.userInfo !== null){
      this.userInfo.firstName = newData.firstName;
      this.userInfo.lastName = newData.lastName;
      

      this.accountService.updateProfileSettings(this.userInfo)
      .subscribe((data: any) =>{
        let profileImageChanged = false;
        //change logo if updated
        if(this.uploadedFiles != null){
          profileImageChanged = true;
          // upload company logo
          var validFileExtensionsString: string = ".jpg,.jpeg,.png"; 

          this.uploadedFiles.append('id', this.userInfo.id.toString());
          this.uploadedFiles.append('validFileExtensionsString', validFileExtensionsString);

          this.accountService.uploadImage(this.uploadedFiles).subscribe((result: any) => {
            this.userInfo.profileImageUrl = 'image\\profile\\systemuser\\' + this.userInfo.id.toString() +'_' + this.fileName;
            localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
            this.accountService.userModelUpdate(this.userInfo);
            this.alertService.success("Profile settings updated successfully");
          },
          (error: any) => {
            console.log(error);
            //this.alertService.error("Internal server error happen");
          });
          this.uploadedFiles = null;
        }
        if(!profileImageChanged){
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
          this.accountService.userModelUpdate(this.userInfo);
          this.alertService.success("Profile settings updated successfully");
        }
      },
      (error: any) => {
        console.log(error);
        //this.alertService.error("Internal server error happen");
      })
    }
}

  openFileBrowser(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById('profilePhotoId') as HTMLElement;
    element.click();
  }

  getLoggedInUser(){
    this.userInfo = this.accountService.getLoggedInUserInfo();
  }

    // convenience getter for easy access to form fields
    get f() { return this.profileSettingsForm.controls; }

}
