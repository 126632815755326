import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { tap, retry, catchError } from "rxjs/operators";
import { AlertService } from "../services";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router: Router, private alertService: AlertService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.get('No-Auth') === 'True') {
            return next.handle(req.clone());
        }

        if (localStorage.getItem('userToken') !== null) {
            const clonedreq = req.clone({
                headers: req.headers.set("Authorization", "Bearer " + localStorage.getItem('userToken'))
            });

            // return next.handle(clonedreq)
            //             .pipe(tap(
            //                 success => {},
            //                 error => {
            //                     if(error.status === 401){
            //                         this.router.navigateByUrl('/login');
            //                     }
            //                     if(error.status === 403){
            //                         this.alertService.error("You do not have permission to perform the operation.");
            //                         return of(error.message);
            //                     }
            //                     return Observable.throw(error.message);
            //                 }
            //             ));

            return next.handle(clonedreq)
                .pipe(
                    //retry(1),
                    catchError((error: HttpErrorResponse) => {
                        let errorMessage = '';
                        if (error.error instanceof ErrorEvent) {
                            // client-side error
                            errorMessage = `Error: ${error.error.message}`;
                        }
                        else {
                            // server-side error
                            if (error.status === 401) {
                                this.router.navigateByUrl('/login');
                            }
                            else if (error.status === 403) {
                                errorMessage = 'Unauthorized access.';
                                this.alertService.error(errorMessage);
                            } else if (error.status === 406 && error.error && error.error.error && error.error.error.length > 0) {
                                errorMessage = error.error.error[0];
                                this.alertService.error(errorMessage);

                            } else {
                                errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                                console.log(errorMessage);
                            }
                        }

                        return throwError(errorMessage)
                    })
                );
        }
    }
}
