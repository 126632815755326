export class TrendingMerchant {
  id: number;
  merchantId: number;
  name: string;
  isActive: boolean= true;
  orderNo: number;
  public constructor(init?: Partial<TrendingMerchant>) {
    Object.assign(this, init);
  }
}
