import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountServiceService, AlertService, CompanyOutletService, FileUploadService, RolesService } from 'src/app/services';
import { RolePermissions } from 'src/app/models/role-permissions';
import { RolePermissionService } from 'src/app/services/role-permission.service';
import { PermissionEnum } from 'src/app/enums/permissions.enum';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-create-role-permissions',
  templateUrl: './create-role-permissions.component.html',
  styleUrls: ['./create-role-permissions.component.css']
})
export class CreateRolePermissionsComponent implements OnInit {

  rolePermissionForm: FormGroup;
  rolePermissions: RolePermissions = new RolePermissions();
  submitted = false;
  rolePermission: RolePermissions;
  roles: any[];
  inEditMode: boolean = false;


  keys: any[];
  permissions = PermissionEnum;
  rolePermissionTypes: any[];
  selectedPermissions: any[] = [];

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateRolePermissionsComponent>,
    private accountService: AccountServiceService,
    private alertService: AlertService,
    private outletService: CompanyOutletService,
    private sharedService: SharedService,
    private fileUploadService: FileUploadService,
    private rolePermissionService: RolePermissionService,
    private roleService: RolesService,
    @Inject(MAT_DIALOG_DATA) data) {


    this.rolePermission = new RolePermissions();

    if (isNaN(data)) { // coming from rolePermission list page


      this.rolePermission = new RolePermissions(data);
      var hexaValues = this.rolePermission.permissionsInRole.map(function (e) {
        var hexaValue = (+e).toString(16);
        var hexaPrefix = '0x';
        var value = hexaPrefix.concat(hexaValue);
        return value;
      });

      this.selectedPermissions = hexaValues;
      this.inEditMode = true;
    }
    else { // coming from other page

    }
    this.buildForm();
    if (this.inEditMode === true) {
      this.rolePermissionForm.controls.roleName.disable();
    }
    else {
      this.rolePermissionForm.controls.roleName.enable();
    }
  }

  private convertToHexaFromDecimal(hex: string): string {
    var hexaValue = (+hex).toString(16);
    var hexaPrefix = '0x';
    var value = hexaPrefix.concat(hexaValue);
    return value;
  }

  buildForm() {
    this.rolePermissionForm = this.formBuilder.group({
      roleName: [this.rolePermission.roleName, [Validators.required]],
      selectedPermissions: [this.selectedPermissions,[Validators.required]]
    });
  }

  ngOnInit() {
    this.getRoles();
    this.getRolePermissionEnums();
  }


  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.rolePermissionForm.invalid) {
      return;
    }

    this.editRolePermission();

    this.dialogRef.close();
  }

  getRolePermissionEnums() {
    this.rolePermissionTypes = this.sharedService.getRolePermissionTypes();
  }

  getRoles() {
    this.roleService.getFilteredRoles()
      .subscribe((data: any) => {
        this.roles = data;
      });
  }

  //RolePermissionService

  editRolePermission() {
    let newData = new RolePermissions(this.rolePermissionForm.value);
    if (this.rolePermission !== null) {

      var permissionInRolesDecimalArray = this.selectedPermissions.map(function (e) {
        var number = parseInt(e,16);
        return number;
      });

      this.rolePermission.permissionsInRole = permissionInRolesDecimalArray;
      this.rolePermissionService.editRolePermissions(this.rolePermission)
        .subscribe((data: any) => {
          //this.onRoleEditEvent.emit();
          this.alertService.success("Role edited successfully");
        },
          (error: any) => {
            this.showErrorMessage(error);
          });

    }
  }


  showErrorMessage(error: any) {
    console.log(error);
    this.alertService.error("Internal server error happen");
  }
  close() {
    this.dialogRef.close();
  }


  get f() { return this.rolePermissionForm.controls; }

}
