import { Component, OnInit } from '@angular/core';
import { AccountServiceService } from '../../services';
import { CompanyRole } from '../../enums';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  isMerchantLoggedIn: boolean = false;
  userInfo: any;

  constructor(private accountService: AccountServiceService) { }

  ngOnInit() {
    this.getLoggedInUser();
  }

  getLoggedInUser() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
    if (CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
      this.isMerchantLoggedIn = true;
    }
  }
}
