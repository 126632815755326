import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CompanySetting } from '../models/company-setting';


@Injectable({
  providedIn: 'root'
})
export class CompanySettingService {

  private settingsBaseUrl = 'api/companysetting';

  constructor(private http: HttpClient) { }

  //getSettings(): Observable<any[]> {
  //  return this.http.get<any[]>(this.settingsBaseUrl);
  //}

  getSettingsByCompany(companyId?: number): Observable<any[]> {
    if (companyId)
      return this.http.get<any[]>(this.settingsBaseUrl + '/company/' + companyId);
    else
      return this.http.get<any[]>(this.settingsBaseUrl + '/getGlobalSettings');

  }

  getSettingsByCompanyAndType(type: number, companyId?: number): Observable<any> {
    if (companyId)
      return this.http.get<any[]>(this.settingsBaseUrl + '/company/' + companyId + '/type/' + type);
    else
      return this.http.get<any[]>(this.settingsBaseUrl + '/getGlobalSettingByType/type/' + type);
  }
  createSetting(setting: CompanySetting) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.settingsBaseUrl, setting, requestHeader);
  }

  editSetting(setting: CompanySetting) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.put(this.settingsBaseUrl, setting, requestHeader);
  }

  deleteSetting(outletId: number) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.delete(this.settingsBaseUrl + '/' + outletId, requestHeader);
  }
}
