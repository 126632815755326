import { Component, OnInit, EventEmitter, NgZone, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OfferAssignment } from 'src/app/models';
import { AssignmentResponse } from 'src/app/models/assignmentresponse';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { OfferAssignmentService, AlertService } from 'src/app/services';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import * as moment from 'moment';
import { CorporateOfferService } from '../../services/corporate-offer.service';

@Component({
  selector: 'app-add-assignment',
  templateUrl: './add-assignment.component.html',
  //styleUrls: ['./add-assignment.component.css']
})
export class AddAssignmentComponent implements OnInit {

  // onCreate event
  onAddAssignmentEvent: EventEmitter<number> = new EventEmitter();

  // // onCreate event
  // @Output() onAssignmentCreateEvent: EventEmitter<AssignmentResponse> = new EventEmitter();
  // // onEdit event;
  // @Output() onAssignmentEditEvent: EventEmitter<AssignmentResponse> = new EventEmitter();
  inputData: any;

  offerAssignmentForm: FormGroup;
  submitted = false;
  offerAssignmentData: FormData;
  assignmentFiles: FormData;
  offerAssignment: OfferAssignment;
  assignmentResponse: AssignmentResponse;

  fileValidationError: string;
  fileName: string = '';

  giftDeliveryMethods: any;
  showDeliveryAddressInput = false;
  showDeliveryMethodSelection = false;

  // date time picker
  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 30;
  public stepSecond = 1;

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddAssignmentComponent>,
    private offerService: CorporateOfferService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private zone: NgZone,
    @Inject(MAT_DIALOG_DATA) data) {

    if (data !== null) {
      this.inputData = data;
    }
    this.offerAssignmentForm = this.formBuilder.group({
      phoneNumbers: '',
      deliveryAddress: '',
      effectiveFrom: [this.inputData.validFrom, Validators.required],
      effectiveFromTime: [''],
      expireDate: [this.inputData.validTill, Validators.required]
    });

  }

  ngOnInit() {
  }

  openFileBrowser(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById('offerAssignmentFileId') as HTMLElement;
    element.click();
  }

  uploadLogo(files) {
    this.fileValidationError = null;
    if (files.length === 0)
      return;

    const formData = new FormData();

    if (files[0].size > (1024 * 1024)) {
      this.fileValidationError = "Maximum file size should be 1 MB";
      return;
    }

    if (files[0].type != `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` && files[0].type != 'text/csv') {
      this.fileValidationError = "Please upload csv or excel file.";
      return;
    }

    for (let file of files) {
      formData.append(file.name, file);
      this.fileName += ' ' + file.name;
    }
    this.assignmentFiles = formData;
  }


  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.offerAssignmentForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    this.offerAssignmentData = new FormData();
    if (this.assignmentFiles != null) {
      this.offerAssignmentData = this.assignmentFiles;
    }

    var fromDate = moment(this.offerAssignmentForm.value.effectiveFrom).format("YYYY/MM/DD  HH:mm");
    const toDate = moment(this.offerAssignmentForm.value.expireDate).format("YYYY/MM/DD  HH:mm");

    this.offerAssignmentData.append('offerTemplateId', this.inputData.id);
    this.offerAssignmentData.append('phoneNumbers', this.offerAssignmentForm.value.phoneNumbers);
    this.offerAssignmentData.append('validFrom', fromDate);
    this.offerAssignmentData.append('validTill', toDate);
    this.offerAssignmentData.append("commercialMessage", "");

    this.offerService.addOfferAssignment(this.offerAssignmentData)
      .subscribe((response: any) => {
        if (response.success) {
          //this.assignmentResponse = data;
          this.onAddAssignmentEvent.emit(this.inputData.id);
          this.alertService.success('Offer send successfully.');
          this.dialogRef.close();
        }
        if (!response.success) {
          this.alertService.error(response.data);
        }
      },
        (error: any) => {
        });
  }

  close() {
    this.dialogRef.close();
  }

  // convenience getter for easy access to form fields
  get f() { return this.offerAssignmentForm.controls; }

}
