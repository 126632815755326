import { Component, OnInit, Injector } from '@angular/core';
import { BaseComponent } from 'src/app/components/base/base.component';
import { CommonService, CompanyService } from 'src/app/services';
import { CompanyRole } from 'src/app/enums';
import { CorporateOfferService } from '../../services/corporate-offer.service';

@Component({
  selector: 'app-my-offers',
  templateUrl: './my-offers.component.html',
  styleUrls: ['./my-offers.component.css']
})
export class MyOffersComponent extends BaseComponent implements OnInit {

  constructor(private offerService: CorporateOfferService,
    private commonService: CommonService,
    private companyService: CompanyService,
    injector: Injector
  ) { super(injector) }

  userInfo: any;
  dataLoaded: Promise<boolean>;
  pageTitle: string = "My Offers";

  currentPage: number = 1;
  pageSize: number = 10;
  itemCountText: string;
  totalItems: number;

  offerSubMenu: any[] = [];
  activeSubMenu: string = 'Details';
  selectedSubMenuIndex: number = 0;

  merchants: any[];
  merchantSelection: string = "Merchant";
  merchantFilter: any = { name: '' };
  searchedMerchantLogo: string = null;
  showMerchantSearch: boolean = false;
  selectedMerchantId: number = 0;

  selectedOffer: any;
  selectedOfferName: string;
  selectedOfferIndex: number = -1;

  offers: any[];
  offerFilter: any = { offerName: '' };

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    console.log("ngInit");
    this.userInfo = userInfo;
    this.getMyOffers();
    this.getMerchants();
    this.dataLoaded = Promise.resolve(true);
  }

  giftFilteredItem(value) {
    this.selectedOfferName = value;
    this.getMyOffers();
  }
  getMyOffers() {
    let merchant = this.selectedMerchantId == 0 ? 0 : this.selectedMerchantId

    let filterParam = {
      'consumerId': this.userInfo.consumerId,
      'merchantId': merchant,
      'offerName': this.selectedOfferName,
      'page': this.currentPage,
      'pageSize': this.pageSize
    };

    this.offerService.myOffers(filterParam)
      .subscribe(data => {
        this.offers = data;
        this.totalItems = data?.length;

        this.highlightFirstItem();
        this.itemCount();
      });
  }
  getMerchants() {
    this.companyService.getMerchantBasics()
      .subscribe(data => {
        this.merchants = data;

        if (data.length > 1 || !(CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin)) {
          this.showMerchantSearch = true;
        }
      });
  }
  highlightFirstItem() {
    if (this.offers !== null) {
      this.selectedOffer = this.offers[0];
      this.selectedOfferIndex = 0;
      this.dataLoaded = Promise.resolve(true);
    }
  }

  selectAllMerchant() {
    this.searchedMerchantLogo = null;
    this.merchantSelection = 'Merchant';
    this.selectedMerchantId = 0;
    this.getMyOffers();
  }

  onOfferChange(offer, index) {
    if (this.selectedOfferIndex != index) {
      this.selectedOfferIndex = index === this.selectedOfferIndex ? -1 : index;
      this.selectedOffer = offer;
    }

    let body = document.getElementsByTagName('body')[0];
    body.classList.add("display-tab-content");
    this.commonService.itemSelectionUpdated(this.selectedOffer);
  }

  itemCount() {
    let firstItem = (this.currentPage - 1) * this.pageSize + 1;
    let lastItem = (this.currentPage) * this.pageSize;
    if (lastItem > this.totalItems)
      lastItem = this.totalItems;
    this.itemCountText = firstItem + '-' + lastItem + ' of ' + this.totalItems + ' Offers';
  }

  pageChanged(event) {
    this.selectedOfferIndex = 0;
    this.currentPage = event;
    this.getMyOffers();
  }

  onMerchantChange(merchant) {
    this.selectedMerchantId = merchant.id;
    this.searchedMerchantLogo = merchant.companyLogoUrl;
    this.merchantSelection = merchant.name.split(' ').slice(0, 2).join(' ');

    this.getMyOffers();
  }

  onMarkAsFavorite() {
    // offer.markAsFavorite = status
    // this.offerService.markOfferAssignmentAsFavorite(offer).subscribe(() => {
    //   this.getMyOffers();
    // });
  }
}
