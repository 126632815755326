import { Component, OnInit, Input, SimpleChanges, Injector } from '@angular/core';
import { CorporateOfferService } from '../services/corporate-offer.service';
import { BaseComponent } from 'src/app/components/base/base.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { AddAssignmentComponent } from './add-assignment/add-assignment.component';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { AlertService, DataService } from 'src/app/services';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-corporate-offer-assignment',
  templateUrl: './corporate-offer-assignment.component.html',
  styleUrls: ['./corporate-offer-assignment.component.css']
})
export class CorporateOfferAssignmentComponent extends BaseComponent implements OnInit {

  @Input() offerTemplateInput: any;

  offerTemplate: any;
  offers: any[];
  phone: string = '';

  offerCardForm: FormGroup;

  //pagination
  currentPageNumber: number = 1;
  pageSize: number = 10;
  expandedIndex: number = -1;
  totalItems: number = 0;
  count = 0;
  pageSizes = [10, 25, 50, 100];
  paginationInfo: any = '';

  constructor(private offerService: CorporateOfferService,
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private injector: Injector) {
    super(injector);
    this.buildForm();
  }

  ngOnInit(): void {
    // if (this.offerTemplateInput !== undefined) {
    //   this.getOffersByTemplate(true, this.offerTemplateInput?.id, null);
    // }
  }

  buildForm() {
    this.offerCardForm = this.formBuilder.group({
      phone: '',
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['offerTemplateInput']) {
      setTimeout(() => {
        this.offerTemplate = this.offerTemplateInput;
        if (this.offerTemplate !== undefined) {
          this.getOffersByTemplate(true, this.offerTemplate?.id, null);
        }
      });
    }
  }

  addAssignment(offerTemplate: any) {
    const addAssignmentDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    addAssignmentDialogConfig.disableClose = true;
    addAssignmentDialogConfig.autoFocus = true;
    addAssignmentDialogConfig.panelClass = "golp-dialog";

    addAssignmentDialogConfig.data = offerTemplate

    const createAssignmentDialog = this.dialog.open(AddAssignmentComponent, addAssignmentDialogConfig);

    const successfullCreate = createAssignmentDialog.componentInstance.onAddAssignmentEvent.subscribe((data) => {
      this.getOffersByTemplate(true, this.offerTemplate.id, null);
    });

    createAssignmentDialog.afterClosed().subscribe(() => {
    });
  }

  onDelete(item: any) {
    const confirmationDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    confirmationDialogConfig.data = `Are you sure to delete the number ${item.consumerPhone}`;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.offerService.put(`api/corporateoffers/offer-delete/${item.id}`, null)
          .subscribe((response: any) => {
            if (response.success) {
              this.getOffersByTemplate(true, this.offerTemplate.id, null);
              this.alertService.success(response.data);
            } else if (!response.success) {
              this.alertService.error(response.data);
            }
          });
      }
    });
  }

  onSubmit(reset: boolean) {
    let phone = this.offerCardForm.value.phone;
    let obj = {
      "phone": phone,
    }
    this.getOffersByTemplate(reset, this.offerTemplate.id, obj);
  }

  getOffersByTemplate(reset: boolean = false, offerTemplateId: any, search: any) {
    let url = `api/CorporateOffers/template/${offerTemplateId}?pageNumber=${this.currentPageNumber}&pageSize=${this.pageSize}`

    if (search?.phone != undefined && search?.phone != null && search?.phone != "") {
      url += `&searchQuery=${search.phone}`;
    }

    this.dataService.get(url)
      .subscribe((response: any) => {

        if (response.success) {
          this.offers = response.data.offers;
          this.count = response.data.totalCount;
          if (reset) this.currentPageNumber = 1;
          this.getCurrentRange();
        }
        else {
          this.offers = [];
        }
      });
  }

  handlePageChange(event: number): void {
    this.currentPageNumber = event;
    this.getOffersByTemplate(false, this.offerTemplate.id, null);
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.currentPageNumber = 1;
    this.getOffersByTemplate(false, this.offerTemplate.id, null);
  }

  getCurrentRange() {
    this.paginationInfo = `Showing ${((this.currentPageNumber - 1) * this.pageSize) + 1}-${((this.currentPageNumber - 1) * this.pageSize) + this.offers?.length} of ${this.count}`
  }

}
