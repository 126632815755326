import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/base/base.component';
import { CompanyRole } from 'src/app/enums';
import { AccountServiceService } from 'src/app/services';

@Component({
  selector: 'app-gift-card-transaction',
  templateUrl: './gift-card-transaction.component.html',
  styleUrls: ['./gift-card-transaction.component.css']
})
export class GiftCardTransactionComponent extends BaseComponent implements OnInit {

  offerReportsSubMenu: any[] = [];
  selectedSubMenuIndex: number = 0;
  activeSubMenu: string = "Transactions";
  isMerchantLoggedIn: boolean = false;

  constructor(private injector: Injector, private accountService: AccountServiceService) {
    super(injector);
    let userInfo = this.accountService.getLoggedInUserInfo();

    this.isMerchantLoggedIn = CompanyRole.Merchant === userInfo.companyRole && !userInfo.isSystemAdmin;

    this.offerReportsSubMenu.push({ "name": "Transactions", "dataTarget": "transactionsTab" });
    this.offerReportsSubMenu.push({ "name": "Sell Transactions", "dataTarget": "sellTransactionsTab" });
  }

  ngOnInit(): void {
  }

  onSubMenuChange(name, index) {
    if (this.selectedSubMenuIndex != index) {
      this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
      this.activeSubMenu = this.offerReportsSubMenu.filter(c => c.name === name)[0].name;
    }
  }

}
