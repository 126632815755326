import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MerchantOutletResponse } from 'src/app/models/view-models/merchant-outlet-response';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import { DataService } from '../../services/shared/data.service';
import { SendGiftCradReportResponse } from '../shared/gift-transaction-report/gift-transaction-report.component';
@Component({
  selector: 'app-gift-sell-report-summary',
  templateUrl: './gift-sell-report-summary.component.html',
  styleUrls: ['./gift-sell-report-summary.component.css']
})
export class GiftSellReportSummaryComponent implements OnInit {

  offerReportForm: FormGroup;

  currentPage: number = 1;
  pageSize: number = 25;
  expandedIndex: number = -1;
  totalItems: number = 0;

  defaultStartDate: Date = this.startOfMonth(new Date());
  defaultEndDate: Date = new Date();
  paginationPageNumbers: any[];
  currentPageNumberDetails: string;
  submitted: boolean;
  companyOutletId: any;

  userInfo: any;
  reportSummary: any[];

  soldGift: string = '0';
  soldGiftAmount: string = '0';
  redeemedGift: string = '0';
  redeemedGiftAmount: string = '0';
  p: number = 1;
  merchantOutlets: any;
  constructor(private formBuilder: FormBuilder, private dataService: DataService) {
    this.buildForm();
  }

  ngOnInit(): void {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userInfo = userInfo;
    if (this.userInfo.outletQrCode == undefined || this.userInfo.outletQrCode == null)
      this.getMerchantOutlet();
    if (this.userInfo.outletQrCode != undefined && this.userInfo.outletQrCode != null)
      this.companyOutletId = this.userInfo.outletQrCode;
    this.getOfferReportData();
  }

  buildForm() {
    this.offerReportForm = this.formBuilder.group({
      fromDate: [this.defaultStartDate, Validators.required],
      toDate: [this.defaultEndDate, Validators.required],
      outletId: ['', Validators.nullValidator],
      hasAvailed: ['', Validators.nullValidator],
      receiverPhoneNumber: '',
    });
  }

  startOfMonth(date) {
    let startDateOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    startDateOfMonth.setMinutes(date.getMinutes() + 360); // 360 is for GMT+6 hours
    return startDateOfMonth;
  }

  getOfferReportData() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.offerReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    let fromDate = moment(this.f.fromDate.value).format("YYYY/MM/DD");
    let todate = moment(this.f.toDate.value).format("YYYY/MM/DD");
    let outletId = this.f.outletId.value;
    let hasAvailedForm = this.f.hasAvailed.value
    let hasAvailed = null;
    if (hasAvailedForm == 1) {
      hasAvailed = true;
    }
    else if (hasAvailedForm == 2) {
      hasAvailed = false;
    }
    else hasAvailed = null;

    if (outletId != 0)
      this.companyOutletId = outletId.qrCode;
    let url = `api/giftCard/sell-gift-card-reports/merchant/${this.userInfo.companyId}?fromDate=${fromDate}&toDate=${todate}`;
    if (this.userInfo.outletQrCode != null || this.userInfo.outletQrCode != undefined || outletId != 0)
      url += `&outletId=${this.companyOutletId}`;
    if (hasAvailed != null)
      url += `&hasAvailed=${hasAvailed}`;

    this.dataService.get(url)
      .subscribe((response: SendGiftCradReportResponse) => {

        if (response.success) {
          let summary = response.summary;
          this.reportSummary = response.data;
          if (summary != null) {
            this.soldGift = this.getCommaSeparatedString(summary.giftCardSold);
            this.soldGiftAmount = this.getCommaSeparatedString(summary.amountSold);
            this.redeemedGift = this.getCommaSeparatedString(summary.giftCardRedeemed);
            this.redeemedGiftAmount = this.getCommaSeparatedString(summary.amountRedeemed);
          }
        }
      });
  }

  getCommaSeparatedString(input) {
    input = input.toString();
    let iLen = input.length;
    if (iLen <= 3) return input;
    var res;
    res = input.substring(iLen - 3, iLen);
    var substr = input.substring(0, iLen - 3);
    substr = substr.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
    res = substr + "," + res;

    return res;
  }

  get f() { return this.offerReportForm.controls; }

  getMerchantOutlet() {
    let url = `api/Outlets/company/${this.userInfo.companyId}/`;
    this.dataService.get(url).subscribe((data: MerchantOutletResponse[]) => {
      if (data != null && data.length > 0) {
        this.merchantOutlets = data;
      }
    });
  }
}
