import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { GiftUsageLimitSetting, Company } from '../../../models';
import { ReplaySubject } from 'rxjs';
import { CompanyService } from '../../../services';

@Component({
  selector: 'app-create-gift-usage-limit-setting',
  templateUrl: './create-gift-usage-limit-setting.component.html',
  styleUrls: ['./create-gift-usage-limit-setting.component.css']
})
export class CreateGiftUsageLimitSettingComponent implements OnInit {
  @Output() giftUsageLimitEmitter: EventEmitter <any> = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder, private companyService: CompanyService) {
    this.buildForm();
  }

  giftUsageLimitSettingForm: FormGroup;
  @Input() isSubmitted: false;

  submitted = false;

  @Input() existingGiftUsageLimitSetting: any;

  giftUsageLimitSetting: GiftUsageLimitSetting = new GiftUsageLimitSetting();
  maxLimitPerTransaction: any;
  maxGiftsPerDay: number;
  maxGiftAmountPerDay: number;
  validity: number;
  maxGiftsPerMonth: number;
  maxGiftAmountPerMonth: number;
  maxGiftSendToSameReceiver: number;

  ngOnInit() {
    if (this.existingGiftUsageLimitSetting) {
      this.giftUsageLimitSetting = JSON.parse(this.existingGiftUsageLimitSetting); // Need to convert json to this type;

      this.maxLimitPerTransaction = this.giftUsageLimitSetting.maxLimitPerTransaction;
      this.maxGiftsPerDay = this.giftUsageLimitSetting.maxGiftsPerDay;
      this.maxGiftAmountPerDay = this.giftUsageLimitSetting.maxGiftAmountPerDay;
      this.maxGiftsPerMonth = this.giftUsageLimitSetting.maxGiftsPerMonth;
      this.maxGiftAmountPerMonth = this.giftUsageLimitSetting.maxGiftAmountPerMonth;
      this.maxGiftSendToSameReceiver = this.giftUsageLimitSetting.maxGiftSendToSameReceiver;

      this.giftUsageLimitSettingForm.patchValue({
        maxLimitPerTransaction: this.giftUsageLimitSetting.maxLimitPerTransaction,
        maxGiftsPerDay: this.giftUsageLimitSetting.maxGiftsPerDay,
        maxGiftAmountPerDay: this.giftUsageLimitSetting.maxGiftAmountPerDay,
        maxGiftsPerMonth: this.giftUsageLimitSetting.maxGiftsPerMonth,
        maxGiftAmountPerMonth: this.giftUsageLimitSetting.maxGiftAmountPerMonth,
        maxGiftSendToSameReceiver: this.giftUsageLimitSetting.maxGiftSendToSameReceiver,
      });
    }
    else {
      this.giftUsageLimitSetting = new GiftUsageLimitSetting();
    }

    this.setGiftUsageLimitAndEmit();
  }

  ngOnChanges() {
    this.submitted = this.isSubmitted;

  }

  buildForm() {
    this.giftUsageLimitSettingForm = this.formBuilder.group({
      maxLimitPerTransaction: [this.giftUsageLimitSetting.maxLimitPerTransaction],
      maxGiftsPerDay: [this.giftUsageLimitSetting.maxGiftsPerDay],
      maxGiftAmountPerDay: [this.giftUsageLimitSetting.maxGiftAmountPerDay],
      maxGiftsPerMonth: [this.giftUsageLimitSetting.maxGiftsPerMonth],
      maxGiftAmountPerMonth: this.giftUsageLimitSetting.maxGiftAmountPerMonth,
      maxGiftSendToSameReceiver: [this.giftUsageLimitSetting.maxGiftSendToSameReceiver],
    });
  }

  onChangeGiftUsageLimitType($event) {
    this.setGiftUsageLimitAndEmit();
  }

  get f() { return this.giftUsageLimitSettingForm.controls; }


  setGiftUsageLimit() {
    this.giftUsageLimitSetting.maxLimitPerTransaction = this.f.maxLimitPerTransaction.value;
    this.giftUsageLimitSetting.maxGiftsPerDay = this.f.maxGiftsPerDay.value;
    this.giftUsageLimitSetting.maxGiftAmountPerDay = this.f.maxGiftAmountPerDay.value;
    this.giftUsageLimitSetting.maxGiftsPerMonth = this.f.maxGiftsPerMonth.value;
    this.giftUsageLimitSetting.maxGiftAmountPerMonth = this.f.maxGiftAmountPerMonth.value;
    this.giftUsageLimitSetting.maxGiftSendToSameReceiver = this.f.maxGiftSendToSameReceiver.value;
  }

  setGiftUsageLimitAndEmit() {
    this.setGiftUsageLimit();
    this.giftUsageLimitEmitter.emit(this.giftUsageLimitSetting);
  }
}
