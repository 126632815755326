import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { MerchantOutletResponse } from 'src/app/models/view-models/merchant-outlet-response';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import { DataService } from '../../services/shared/data.service';
import { saveAs } from 'file-saver';
import { CommonService } from 'src/app/services';
@Component({
  selector: 'app-upload-amount-report',
  templateUrl: './upload-amount-report.component.html',
  styleUrls: ['./upload-amount-report.component.css']
})
export class UploadAmountReportComponent implements OnInit {

  merchantId: any;
  processId: any;
  userInfo: any;
  companyOutletId: any;
  merchantOutlets: any;
  orders: any;
  submitted: boolean;
  p: number = 1;
  offerReportForm: FormGroup;

  currentPage: number = 1;
  count: number = 0;
  pageSize: number = 25;
  pageSizes = [25, 50, 100];
  paginationInfo: string = '';

  expandedIndex: number = -1;
  totalItems: number = 0;
  percent: number = 0;
  defaultStartDate: Date = new Date();
  defaultEndDate: Date = new Date();
  paginationPageNumbers: any[];
  currentRemarks: string;
  processFinished: number = 0;
  totalProcess: number = 0;
  showProgressbar: boolean = false;
  onceLoaded: boolean = false;

  public touchUi = false;
  public enableMeridian = true;
  public stepHour = 1;
  public stepMinute = 1;
  public defaultTime = [];
  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private dataService: DataService, private commonService: CommonService) {
    this.merchantId = this.route.snapshot.params.merchant;
    this.route.queryParams.subscribe(params => {
      this.processId = params['processId'];
    });

    var startDate1 = new Date();
    //startDate1.setDate(startDate1.getDate() - 7);
    this.defaultStartDate = this.startOfMonth(startDate1);
    this.defaultEndDate = new Date();
    this.buildForm();
    this.commonService.backgroundJobModel.subscribe(res => {
      console.log("Result : ", res);
      if (res.jobType == "GiftProcess" && this.processId == res.model.processId) {
        this.showProgressbar = true;
        this.processFinished = Number(res.model.processFinished);
        this.totalProcess = Number(res.model.totalProcess);
        this.percent = (this.processFinished / this.totalProcess) * 100;
        this.loadProgressedData();
      }
    }, () => { })
  }


  loadProgressedData() {
    if (this.percent == 100) {
      this.loadData();
    }
  }
  buildForm() {
    this.offerReportForm = this.formBuilder.group({
      fromDate: [this.defaultStartDate, Validators.required],
      toDate: [this.defaultEndDate, Validators.required],
      outletId: ['', Validators.nullValidator],
      processId: ['', Validators.nullValidator]
    });
  }
  startOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  ngOnInit(): void {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userInfo = userInfo;
    if (this.userInfo.outletQrCode == undefined || this.userInfo.outletQrCode == null)
      this.getMerchantOutlet();
    if (this.userInfo.outletQrCode != undefined && this.userInfo.outletQrCode != null)
      this.companyOutletId = this.userInfo.outletQrCode;
  }

  loadData() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.offerReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    let fromDate = moment(this.f.fromDate.value).format("YYYY/MM/DDTHH:mm:ss");
    let todate = moment(this.f.toDate.value).format("YYYY/MM/DDTHH:mm:ss");
    let outletId = this.f.outletId.value;
    let processId = this.f.processId.value;
    if (processId != undefined && processId != null && processId != "")
      this.processId = processId;
    else this.processId = null;

    if (outletId != 0)
      this.companyOutletId = outletId.qrCode;
    let url = `api/giftcard/order-processed-record/merchant/${this.userInfo.companyId}?fromDate=${fromDate}&toDate=${todate}&pageSize=${this.pageSize}&pageNumber=${this.p}`;
    if (this.userInfo.outletQrCode != null || this.userInfo.outletQrCode != undefined || outletId != 0)
      url += `&outletId=${this.companyOutletId}`;
    if (this.processId != undefined && this.processId != null)
      url += `&processId=${this.processId}`;

    this.dataService.get(url)
      .subscribe((response: any) => {
        if (response.success) {
          this.orders = response.data.orders;
          this.count = response.data.totalCount;
          this.getCurrentRange();
        }
      });
  }

  get f() { return this.offerReportForm.controls; }


  getMerchantOutlet() {
    let url = `api/Outlets/company/${this.userInfo.companyId}/`;
    this.dataService.get(url).subscribe((data: MerchantOutletResponse[]) => {
      if (data != null && data.length > 0) {
        this.merchantOutlets = data;
      }
    });
  }

  exportXLReportFromServer() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.offerReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    this.submitted = true;
    // stop here if form is invalid
    if (this.offerReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    let fromDate = moment(this.f.fromDate.value).format("YYYY/MM/DDTHH:mm:ss");
    let todate = moment(this.f.toDate.value).format("YYYY/MM/DDTHH:mm:ss");
    let outletId = this.f.outletId.value;
    let processId = this.f.processId.value;
    if (processId != undefined && processId != null && processId != "")
      this.processId = processId;

    if (outletId != 0)
      this.companyOutletId = outletId.qrCode;
    let url = `api/report/gift-process-details/merchant/${this.userInfo.companyId}`;

    let payload = {
      "fromDate": fromDate,
      "toDate": todate,
      "outletId": this.companyOutletId,
      "processId": this.companyOutletId,
      "pageSize": 100000,
      "page": 1
    }

    this.dataService.postWithOptions(url, payload, {
      responseType: 'blob'
    })
      .subscribe((response: any) => {
        this.downloadFile(response);
      }, () => { console.log("Error Occurred") }, () => { console.log("Download Finished") });
  }

  downloadFile(data) {
    const blob2 = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var filename = "Process-Details-Report-" + moment().format('MMMM Do YYYY, h:mm:ss').valueOf() + ".xlsx";
    saveAs(blob2, filename);
  }

  handlePageChange(event: number): void {
    this.p = event;
    this.loadData();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = parseInt(event.target.value);
    this.p = 1;
    this.loadData();
  }

  getCurrentRange() {
    this.paginationInfo = `Showing ${((this.p - 1) * this.pageSize) + 1}-${((this.p - 1) * this.pageSize) + this.orders?.length} of ${this.count} items`
  }

  openRemarks(input) {
    this.currentRemarks = input;
  }

  getShortString(input) {
    if (input != undefined && input != null && input.length > 20) {
      input = input.substring(0, 20);
      input += '...';
    }
    return input;
  }

  setDefaultTime() {
    let d = new Date();
    this.defaultTime = [d.getHours(), d.getMinutes(), d.getSeconds()]
    console.log(this.defaultTime)
  }

  ///progressbar settings
  optionsD = {
    percent: 101,
    maxPercent: 100,
    radius: 60,
    showInnerStroke: false,
    outerStrokeWidth: 10,
    innerStrokeWidth: 0,
    backgroundPadding: -10,
    backgroundStrokeWidth: 0,
    outerStrokeColor: '#61A9DC',
    backgroundColor: '#ffffff',
    backgroundGradientStopColor: '#c0c0c0',
    backgroundGradient: true,
    subtitleColor: '#444444',
    startFromZero: false,
    subtitleFormat: (percent: number): string => {
      if (percent >= 100) {
        return "Congratulations!"
      } else {
        return "Progress"
      }
    }
  }
}
