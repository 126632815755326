import * as moment from 'moment';


export class UserRegistrationReportInput {
    pageNumber: number;
    pageSize: number;
    reportType: number;
    fromDateTime: string;
    toDateTime: string;

    public constructor(init?: Partial<UserRegistrationReportInput>) {
        Object.assign(this, init);
    }
}
