import { Component, OnInit, Input, Output, EventEmitter, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { LoyaltyPointSetting, Company } from '../../../models';
import { ReplaySubject } from 'rxjs';
import { CompanyService } from '../../../services';


@Component({
  selector: 'app-create-loyalty-point-settings',
  templateUrl: './create-loyalty-point-settings.component.html',
  styleUrls: ['./create-loyalty-point-settings.component.css']
})

export class CreateLoyaltyPointSettingsComponent implements OnInit, AfterContentChecked {
  @Output() loyaltyPointSettingEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder, private companyService: CompanyService, private cdref: ChangeDetectorRef) {
    this.buildForm();
  }


  loyaltyPointSettingForm: FormGroup;
  @Input() isSubmitted: false;

  submitted = false;

  @Input() existingLoyaltyPointSettings: any;

  loyaltyPointSetting: LoyaltyPointSetting = new LoyaltyPointSetting();
  defaultExpireMonth: any;
  minRedeemablePoint: any;
  pointType: any;
  pointTypes: any;
  point: any;
  money: any;

  ngOnInit() {
    this.pointTypes = [{ text: 'Open Point', value: 1 }, { text: 'Close Point', value: 2 }];

    if (this.existingLoyaltyPointSettings) {
      this.loyaltyPointSetting = JSON.parse(this.existingLoyaltyPointSettings); // Need to convert json to this type;

      this.minRedeemablePoint = this.loyaltyPointSetting.minRedeemablePoint;
      this.defaultExpireMonth = this.loyaltyPointSetting.defaultExpireMonth;
      this.pointType = this.loyaltyPointSetting.pointType;
      this.point = this.loyaltyPointSetting.point;
      this.money = this.loyaltyPointSetting.money;

      this.loyaltyPointSettingForm.patchValue({
        minRedeemablePoint: this.loyaltyPointSetting.minRedeemablePoint,
        defaultExpireMonth: this.loyaltyPointSetting.defaultExpireMonth,
        pointType: this.loyaltyPointSetting.pointType,
        point: this.loyaltyPointSetting.point,
        money: this.loyaltyPointSetting.money,
      });
    }
    else {
      this.loyaltyPointSetting = new LoyaltyPointSetting();
    }

    this.setLoyaltyPointSettingAndEmit();
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnChanges() {
    this.submitted = this.isSubmitted;
  }

  buildForm() {
    this.loyaltyPointSettingForm = this.formBuilder.group({
      minRedeemablePoint: [this.loyaltyPointSetting.minRedeemablePoint, Validators.required],
      defaultExpireMonth: [this.loyaltyPointSetting.defaultExpireMonth, Validators.required],
      pointType: [this.loyaltyPointSetting.pointType, Validators.required],
      point: [this.loyaltyPointSetting.point, Validators.required],
      money: [this.loyaltyPointSetting.money, Validators.required],
    });
  }

  onChangeLoyaltyPointSettingType($event) {
    this.setLoyaltyPointSettingAndEmit();
  }

  get f() { return this.loyaltyPointSettingForm.controls; }


  setLoyaltyPointSetting() {
    this.loyaltyPointSetting.minRedeemablePoint = this.f.minRedeemablePoint.value;
    this.loyaltyPointSetting.defaultExpireMonth = this.f.defaultExpireMonth.value;
    this.loyaltyPointSetting.pointType = this.f.pointType.value;
    this.loyaltyPointSetting.point = this.f.point.value;
    this.loyaltyPointSetting.money = this.f.money.value;
  }

  setLoyaltyPointSettingAndEmit() {
    this.setLoyaltyPointSetting();
    if (!this.loyaltyPointSettingForm.valid) {
      return;
    }
    this.loyaltyPointSettingEmitter.emit(this.loyaltyPointSetting);
  }
}

