export class CompanyReferenceModel {
  id: number;
  companyId: number;
  referenceType: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  isActive: boolean;
  createdBy?: number;
  createdDate?: string;
  public constructor(init?: Partial<CompanyReferenceModel>) {
    Object.assign(this, init);
  }
}

export class UserReferenceModel {
  id: number;
  userId: number;
  referenceType: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  isActive: boolean;
  createdBy?: number;
  createdDate?: string;
  public constructor(init?: Partial<UserReferenceModel>) {
    Object.assign(this, init);
  }
}
