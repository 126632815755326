import { DataService } from './../../../../components/app-send-gift/services/shared/data.service';
import { Component, OnInit, Input, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AlertService, CompanyOutletService } from 'src/app/services';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OfferCodeVerificationDetails } from 'src/app/corporate-offers/models/offer-code-verification-details';
import { CompanyOutlet } from 'src/app/models';
import { ReplaySubject } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-privilege-card-redemption',
  templateUrl: './privilege-card-redemption.component.html',
  styleUrls: ['./privilege-card-redemption.component.css']
})
export class PrivilegeCardRedemptionComponent implements OnInit {

  @Input() cardDetailsInput: any;
  @ViewChild('purchaseamount') purchaseAmountRef: ElementRef;
  @ViewChild('payableAmountEL') payableAmountRef: ElementRef;

  redemptionForm: FormGroup
  submitted = false;

  userInfo: any;
  cardDetails: any;
  giftCode: any;
  giftPin: any;
  redeemSuccessMessage: string = "search by Privilege Card No. / Mobile No.";
  redeemSuccess: boolean = true;
  //offerService: any;
  discountString: string;

  showOutlets: boolean = false;
  payableAmount: number = 0;

  companyOutlets: CompanyOutlet[];
  outletFilterCtrl: FormControl = new FormControl();
  filteredOutlets: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private alertService: AlertService,
    private dataService: DataService,
    private outletService: CompanyOutletService
  ) {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.showOutlets = this.userInfo.roles[0].name === 'MerchantAdmin';
  }

  setDiscountString() {
    let valueType = this.cardDetails.valueType;
    if (valueType == "Fixed Amount") {
      this.discountString = `BDT ${this.cardDetails.value} ${this.cardDetails.facilityType.toLowerCase()}`
    }
    else if (valueType == "Percentage") {
      this.discountString = `${this.cardDetails.value}% ${this.cardDetails.facilityType.toLowerCase()}`
    }
    else {
      this.discountString = `${this.cardDetails.facilityType.toLowerCase()}`;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['cardDetailsInput']) {
      setTimeout(() => {
        this.payableAmount = 0;
        this.cardDetails = this.cardDetailsInput;
        this.setDiscountString();
        if (this.cardDetails != null) {
          this.redeemSuccess = false;
          this.buildForm();
          setTimeout(() => {
            this.purchaseAmountRef.nativeElement.focus();
          }, 100);

        }
        if (this.cardDetails == null) {
          this.redeemSuccessMessage = "Card does not found.";
          this.redeemSuccess = true;
        }
      });
    }
  }

  salesAmountChanged($event) {
    let salesValue = Number($event.target.value);
    if (this.cardDetails.valueType == "Percentage") {
      this.payableAmountRef.nativeElement.value = salesValue - Math.ceil(salesValue * (this.cardDetails.value / 100))
      this.payableAmount = this.payableAmountRef.nativeElement.value;
    }
    else if (this.cardDetails.valueType == "Fixed Amount") {
      let value = salesValue - this.cardDetails.value;
      if (value < 0) {
        this.payableAmountRef.nativeElement.value = 0;
        this.payableAmount = this.payableAmountRef.nativeElement.value;
      }
      else { this.payableAmountRef.nativeElement.value = value; this.payableAmount = this.payableAmountRef.nativeElement.value; };
    }
    else { }
  }

  buildForm() {
    this.redemptionForm = this.formBuilder.group({
      cardHolderPhone: [{ value: this.cardDetails?.cardHolderPhone, disabled: true }],
      cardType: [{ value: this.cardDetails?.cardType, disabled: true }],
      facilityType: [{ value: this.cardDetails?.facilityType, disabled: true }],
      valueType: [{ value: this.cardDetails?.valueType, disabled: true }],
      value: [{ value: this.discountString, disabled: true }],
      invoiceNumber: [],
      invoiceDetails: ['', [Validators.nullValidator]],
      purchaseAmount: [0, [Validators.required]],
      payableAmount: [{ value: this.payableAmount, disabled: true }, [Validators.required]]
    });
  }

  ngOnInit() {
    this.getOutletsByCompany(this.userInfo.companyId);
    this.buildForm();
  }

  onClickRedemption(item: any) {

    this.submitted = true;

    // stop here if form is invalid
    if (this.redemptionForm.invalid) {
      return;
    }

    let newData = this.redemptionForm.value;

    let payload = {
      "cardNumber": this.cardDetails.cardNumber,
      "merchantId": this.userInfo.companyId,
      "outletId": this.userInfo.outletQrCode,
      "salesAmount": newData.purchaseAmount,
      "invoiceNumber": newData.invoiceNumber,
      "invoiceDetails": newData.invoiceDetails,
      "receivedAmount": this.payableAmount
    }
    const confirmationDialogConfig = new MatDialogConfig();
    // Setting different dialog configurations
    confirmationDialogConfig.data = `Offer will be redeemed with the sales amount ${newData.purchaseAmount}. Want to Proceed? `;
    confirmationDialogConfig.panelClass = "golp-dialog";
    const confirmationDialog = this.dialog.open(
      ConfirmationDialogComponent,
      confirmationDialogConfig
    );

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.applyRedemption(payload);
      }
    });
  }

  applyRedemption(payload: any) {
    let url = "api/CardBeneficiary/redeem-card"
    this.dataService.post(url, payload)
      .subscribe((response: any) => {
        if (response.success) {
          this.redeemSuccess = true;
          this.redeemSuccessMessage = "Offer redeemed successfully.";
          this.giftCode = '';
        }
        if (!response.success) {
          this.redeemSuccess = true;
          this.redeemSuccessMessage = response.data;
        }
      }, (error) => {
        console.log(error);
      });
  }

  getOutletsByCompany(companyId) {
    if (companyId) {
      this.outletService.getOutletsByCompany(companyId)
        .subscribe((data: any) => {
          this.companyOutlets = data;
          this.filteredOutlets.next(this.companyOutlets);
        },
          (error: any) => {
            console.log(error);
          });
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.redemptionForm.controls; }




}
