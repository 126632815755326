import { invalid } from "moment";

export class UrlBuilder {
    static buildUrlWithParams(url: string, params={}) : string {

        let query: string = '';
        let flag: boolean = true;

        for (const key in params) {
            const value = params[key];
            if(!value || value == 'Invalid date' || value < 0) continue;
            if(typeof(value) == 'string' && value != '-1') {
                if (params.hasOwnProperty(key)) {
                    if(flag == true) {
                        query = query + `?${key}=${value}`
                    }
                    else {
                        query = query + `&${key}=${value}`
                    }
                    flag = false;  
                }
            }
            else {
                value.forEach(element => {
                    if(element != '-1') {
                        query = query + `&${key}=${element}`
                    }
                });
            }
        }
        return url+query;
    }
}