export class GiftAmountSetting {
  minAmount:number;
  maxAmount: number;
  slabs: number[];
  useSlab: boolean;

  public constructor(init?: Partial<GiftAmountSetting>) {
    Object.assign(this, init);
  }
}
