import { CompanyRole } from "../enums";

export class GetUnbilledOfferForCompanyInputDto {
  companyId: number;
  outletQRCode?: string;
  companyRole: CompanyRole;
  offerAssignmentIds: any;
  pageNumber: number;
  pageSize: number;
  fromDate: string;
  toDate: string;

  public constructor(init?: Partial<GetUnbilledOfferForCompanyInputDto>) {
    Object.assign(this, init);
  }
}
