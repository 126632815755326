import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import { saveAs } from 'file-saver';
import { Workbook } from 'exceljs';
import { MerchantOutletResponse } from 'src/app/models/view-models/merchant-outlet-response';
import * as fs from 'file-saver';
import * as $ from 'jquery';
import { DataService } from '../../../services/shared/data.service';

@Component({
  selector: 'app-sell-availed-transactions',
  templateUrl: './sell-availed-transactions.component.html',
  styleUrls: ['./sell-availed-transactions.component.css']
})
export class SellAvailedTransactionsComponent implements OnInit {

  offerReportForm: FormGroup;

  currentPage: number = 1;
  pageSize: number = 25;
  expandedIndex: number = -1;
  totalItems: number = 0;
  count = 0;
  pageSizes = [25, 50, 100];
  paginationInfo: string = '';
  p: number = 1;

  defaultStartDate: Date = this.startOfMonth(new Date());
  defaultEndDate: Date = new Date();
  paginationPageNumbers: any[];
  currentPageNumberDetails: string;
  submitted: boolean;
  companyOutletId: any;

  userInfo: any;
  reportSummary: any[];

  soldGift: number = 0;
  soldGiftAmount: number = 0;
  redeemedGift: number = 0;
  redeemedGiftAmount: number = 0;

  merchantOutlets: any;
  currentRemarks: string;
  constructor(private formBuilder: FormBuilder, private dataService: DataService) {
    this.buildForm();
  }

  ngOnInit(): void {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userInfo = userInfo;
    if (this.userInfo.outletQrCode == undefined || this.userInfo.outletQrCode == null)
      this.getMerchantOutlet();
    if (this.userInfo.outletQrCode != undefined && this.userInfo.outletQrCode != null)
      this.companyOutletId = this.userInfo.outletQrCode;
  }

  buildForm() {
    this.offerReportForm = this.formBuilder.group({
      fromDate: [this.defaultStartDate, Validators.required],
      toDate: [this.defaultEndDate, Validators.required],
      outletId: ['', Validators.nullValidator],
      hasAvailed: ['', Validators.nullValidator],
      receiverPhoneNumber: '',
      globalInput: '',
    });
  }
  startOfMonth(date: Date) {
    let startDateOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    startDateOfMonth.setMinutes(date.getMinutes() + 360); // 360 is for GMT+6 hours
    return startDateOfMonth;
  }
  exportToExcel() {

  }

  getOfferReportData(submitted: boolean = false) {

    this.submitted = submitted;
    // stop here if form is invalid
    if (this.offerReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    let fromDate = moment(this.f.fromDate.value).format("YYYY/MM/DD");
    let todate = moment(this.f.toDate.value).format("YYYY/MM/DD");
    let outletId = this.f.outletId.value;
    let hasAvailedForm = this.f.hasAvailed.value;
    let hasAvailed = null;
    let globalInput = this.f.globalInput.value;
    if (hasAvailedForm == 1) {
      hasAvailed = true;
    }
    else if (hasAvailedForm == 2) {
      hasAvailed = false;
    }
    else hasAvailed = null;

    if (outletId != 0)
      this.companyOutletId = outletId.qrCode;
    let url = `api/giftCard/sell-gift-card-reports-pagination/merchant/${this.userInfo.companyId}?fromDate=${fromDate}&toDate=${todate}&pageSize=${this.pageSize}&page=${this.p}`;
    if (this.userInfo.outletQrCode != null || this.userInfo.outletQrCode != undefined || outletId != 0)
      url += `&outletId=${this.companyOutletId}`;
    if (hasAvailed != null)
      url += `&hasAvailed=${hasAvailed}`;
    if (globalInput != null)
      url += `&globalInput=${globalInput}`;

    this.dataService.get(url)
      .subscribe((response: any) => {

        if (this.submitted)
          this.p = 1;
        if (response.success) {
          this.reportSummary = response.data;
          this.count = response.count;
          this.getCurrentRange();
          loadJquery();
        }
      });
  }

  exportXLReportFromServer() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.offerReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    let fromDate = moment(this.f.fromDate.value).format("YYYY/MM/DD");
    let todate = moment(this.f.toDate.value).format("YYYY/MM/DD");
    let outletId = this.f.outletId.value;
    let hasAvailedForm = this.f.hasAvailed.value;
    let globalInput = this.f.globalInput.value;

    let hasAvailed = null;
    if (hasAvailedForm == 1) {
      hasAvailed = true;
    }
    else if (hasAvailedForm == 2) {
      hasAvailed = false;
    }
    else hasAvailed = null;

    if (outletId != 0)
      this.companyOutletId = outletId.qrCode;

    let url = `api/report/sell-gift-card-reports/merchant/${this.userInfo.companyId}`;

    let payload = {
      "fromDate": fromDate,
      "toDate": todate,
      "outletId": this.companyOutletId,
      "hasAvailed": hasAvailed,
      "globalInput": globalInput,
      "pageSize": 100000,
      "page": 1
    }

    this.dataService.postWithOptions(url, payload, {
      responseType: 'blob'
    })
      .subscribe((response: any) => {
        this.downloadFile(response);
      }, () => { console.log("Error Occurred") }, () => { console.log("Download Finished") });
  }

  downloadFile(data) {
    const blob2 = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var filename = "Gift-Card-Sell-Report-" + moment().format('MMMM Do YYYY, h:mm:ss').valueOf() + ".xlsx";
    saveAs(blob2, filename);
  }

  exportXLReportLocal() {
    let workbook = new Workbook();
    //add name to sheet
    let worksheet = workbook.addWorksheet("TransactionReport");
    let header = ["Gift Sender", "Gift Receiver", "Gift Price", "Has Availed", "Issuance Outlet", "Availed Outlet", "Valid From", "Valid Till"];
    for (let x1 of this.reportSummary) {
      let temp = []
      temp.push(x1["giftSenderPhone"]);
      temp.push(x1["giftReceiver"]);
      temp.push(x1["giftPrice"]);
      temp.push(x1["hasAvailed"] ? "YES" : "NO");
      temp.push(x1["issuanceOutlet"]);
      temp.push(x1["redemptionOutlet"]);
      let validDateFrom = new Date(x1["validFrom"]).toLocaleString();
      temp.push(validDateFrom);
      let validDateTill = new Date(x1["validTill"]).toLocaleString();
      temp.push(validDateTill);

      worksheet.addRow(temp)
    }

    let fname = "Transaction Report"

    //add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
    });
  }

  get f() { return this.offerReportForm.controls; }

  getMerchantOutlet() {
    let url = `api/Outlets/company/${this.userInfo.companyId}/`;
    this.dataService.get(url).subscribe((data: MerchantOutletResponse[]) => {
      if (data != null && data.length > 0) {
        this.merchantOutlets = data;
      }
    });
  }

  handlePageChange(event: number): void {
    this.p = event;
    this.getOfferReportData();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = parseInt(event.target.value);
    this.p = 1;
    this.getOfferReportData();
  }

  getCurrentRange() {
    this.paginationInfo = `Showing ${((this.p - 1) * this.pageSize) + 1}-${((this.p - 1) * this.pageSize) + this.reportSummary?.length} of ${this.count} items`
  }

  openRemarks(input) {
    this.currentRemarks = input;
  }

  getShortString(input) {
    if (input != undefined && input != null && input.length > 20) {
      input = input.substring(0, 20);
      input += '...';
    }
    return input;
  }
}

function loadJquery() {
  $(document).ready(function () {
    var cells = $(".redesign");

    for (var headCell = 0; headCell < cells.length;) {
      $(cells[headCell++]).addClass("d-sticky");
      $(cells[headCell++]).addClass("d-sticky2");
      $(cells[headCell++]).addClass("d-sticky3");
      $(cells[headCell++]).addClass("d-sticky4");
    }
  });
}
