import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, GiftTemplateService, CompanyService } from '../../services';
import { GiftTemplateInputDto, Company, GiftTemplateAssociationsInputDto, AssignGiftTemplateInput } from '../../models';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-assign-gift-template',
  templateUrl: './assign-gift-template.component.html',
  styleUrls: ['./assign-gift-template.component.css']
})

export class AssignGiftTemplateComponent implements OnInit {

  // onCreate event
  onCreategiftTemplateEvent: EventEmitter<any> = new EventEmitter();
  onEditgiftTemplateEvent: EventEmitter<any> = new EventEmitter();

  giftTemplateForm: FormGroup;
  submitted = false;
  soldDate: any;
  phoneNumber: string = "";
  today = new Date(); 

  serialNo: string;
  isInSingleEditMode: boolean = false;
  willPrint: boolean = false;
  merchants: Company[] = [];
  giftTemplateAssociationIds: any[] = [];
  inputData: AssignGiftTemplateInput = new AssignGiftTemplateInput();


  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AssignGiftTemplateComponent>,
    private alertService: AlertService,
    private companyService: CompanyService,
    private giftTemplateService: GiftTemplateService,
    @Inject(MAT_DIALOG_DATA) data) {

    if (data !== null) {
      this.serialNo = data.serialNo ? data.serialNo : "";
      this.phoneNumber = data.phoneNumber ? data.phoneNumber : "";
      this.soldDate = data.soldDate;
      this.giftTemplateAssociationIds = data.giftTemplateAssociationIds;
    }

    if (this.giftTemplateAssociationIds && this.giftTemplateAssociationIds.length == 0) {
      this.isInSingleEditMode = true;
    }

    this.giftTemplateForm = this.formBuilder.group({
      phoneNumber: [this.phoneNumber, [Validators.required]],
      soldDate: [this.soldDate, [Validators.required]],
      serialNo: [this.serialNo],
    });
  }

  ngOnInit() {

  }

  onSubmit() {
    this.submitted = true;

    if (this.giftTemplateForm.invalid) {
      return;
    }

    this.dialogRef.close();     // to close the modal

    this.assignGiftTemplate();
  }



  assignGiftTemplate() {
    this.inputData.giftTemplateAssociationIds = this.giftTemplateAssociationIds;

    this.inputData.phoneNumber = this.f.phoneNumber.value;
    this.inputData.soldDate = this.f.soldDate.value;

    this.giftTemplateService.assignGiftTemplates(this.inputData)
      .subscribe((data: any) => {
        this.onCreategiftTemplateEvent.emit();

        this.alertService.success('Gift template assigned successfully');

      }, (error: any) => {
      });
  }

  close() {
    this.dialogRef.close();
  }

  // convenience getter for easy access to form fields
  get f() { return this.giftTemplateForm.controls; }

}


