import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CorporateOfferService {

  private offerBaseUrl = "api/CorporateOffers";
  private offerConditionBaseUrl = "api/CorporateOffers/offer-condition";
  private myOfferBaseUrl = "api/CorporateOffers/my-offer";
  private offerTemplateBaseUrl = 'api/CorporateOffers/offer-template';
  private offerRedemptionService = 'api/OfferRedemption';

  constructor(private http: HttpClient) { }

  post(url: any, data: any) {
    var requestHeader = { headers: new HttpHeaders({ 'No-Auth': 'False' }) };
    return this.http.post(`${this.offerBaseUrl}/${url}`, data, requestHeader);
  }

  put(url: any, data: any) {
    var requestHeader = { headers: new HttpHeaders({ 'No-Auth': 'False' }) };
    return this.http.put(url, data, requestHeader);
  }

  get(url: any): Observable<any> {
    return this.http.get<any>(url);
  }

  getOfferTemplates(): Observable<any[]> {
    return this.http.get<any[]>(this.offerTemplateBaseUrl);
  }

  getOfferTemplateForEdit(templateId): Observable<any> {
    return this.http.get<any>(`${this.offerTemplateBaseUrl}/edit/${templateId}`);
  }

  getOfferTemplateById(id: number): Observable<any> {
    return this.http.get<any>(this.offerTemplateBaseUrl + id);
  }

  createOfferTemplate(offer: any) {
    var requestHeader = { headers: new HttpHeaders({ 'No-Auth': 'False' }) };
    return this.http.post(this.offerTemplateBaseUrl, offer, requestHeader);
  }

  editOfferTemplate(offer: any) {
    var requestHeader = { headers: new HttpHeaders({ 'No-Auth': 'False' }) };
    return this.http.put(this.offerTemplateBaseUrl, offer, requestHeader);
  }

  copyOfferTemplate(offer: any) {
    var requestHeader = { headers: new HttpHeaders({ 'No-Auth': 'False' }) };
    return this.http.post(`${this.offerTemplateBaseUrl}/copy-template`, offer, requestHeader);
  }

  uploadFile(id: number, uploadedFileData: FormData) {
    return this.http.post(this.offerTemplateBaseUrl + '/upload-file/' + id, uploadedFileData);
  }

  deleteOfferTemplate(offerId: number) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.delete(this.offerTemplateBaseUrl + '/' + offerId, requestHeader);
  }

  getOffersByTemplateId(offerTemplateId: any): Observable<any[]> {
    return this.http.get<any[]>(`${this.offerBaseUrl}/template/${offerTemplateId}`);
  }

  redeemOffer(offerDetails: any) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(`${this.offerRedemptionService}/redeem-offer`, offerDetails, requestHeader);
  }

  myOffers(filterParam: any): Observable<any[]> {
    return this.http.get<any[]>(`${this.myOfferBaseUrl}/consumer/${filterParam.consumerId}?merchantId=${filterParam.merchantId}&page=${filterParam.page}&pageSize=${filterParam.pageSize}`);
  }

  viewCode(consumerId: any, offerId: any): Observable<any> {
    return this.http.get<any[]>(`${this.myOfferBaseUrl}/offer-code/consumer/${consumerId}/offer/${offerId}`);
  }

  addOfferAssignment(assignmentData: any): Observable<any> {
    var requestHeader = { headers: new HttpHeaders({ 'No-Auth': 'False' }) };
    return this.http.post(`${this.offerBaseUrl}/assign-bulk`, assignmentData, requestHeader);
  }

  getDecomposedConditions(offerId: any): Observable<any[]> {
    return this.http.get<any[]>(`${this.offerConditionBaseUrl}/decomposed-conditions/offer/${offerId}`);
  }

  getOfferConditions(): Observable<any[]> {
    return this.http.get<any[]>(this.offerConditionBaseUrl);
  }

  getOfferConditionByOfferId(id: number): Observable<any> {
    return this.http.get<any>(this.offerConditionBaseUrl + "/offer/" + id);
  }

  createOfferCondition(offerCondition: any) {
    var requestHeader = { headers: new HttpHeaders({ 'No-Auth': 'False' }) };
    return this.http.post(this.offerConditionBaseUrl, offerCondition, requestHeader);
  }

  editOfferCondition(offerCondition: any) {
    var requestHeader = { headers: new HttpHeaders({ 'No-Auth': 'False' }) };
    return this.http.put(this.offerConditionBaseUrl, offerCondition, requestHeader);
  }

  deleteOfferCondition(conditionId: any) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.delete(`${this.offerConditionBaseUrl}/${conditionId}`, requestHeader);
  }
}

