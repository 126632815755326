import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Role } from '../models/role';
import { RolePermissions } from '../models/role-permissions';

@Injectable({
  providedIn: 'root'
})

export class RolePermissionService {

  private rolesBaseUrl = 'api/rolesToPermissions';

  constructor(private http: HttpClient) { }

  getRolePermissionsByName(roleName: string): Observable<any> {
    return this.http.get<any[]>(this.rolesBaseUrl  +'/' + roleName);
  }

  editRolePermissions(rolePermission: RolePermissions) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.put(this.rolesBaseUrl, rolePermission, requestHeader);
  }

}

