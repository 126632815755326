import { Component, OnInit, SimpleChanges, Input, Injector } from '@angular/core';
import { Company } from '../../models';
import { UserType, CompanyType } from '../../enums';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { CompanyService, AlertService } from '../../services';
import { CreateCompanyReferenceComponent } from '../create-company-reference/create-company-reference.component';
import { CompanyReferenceModel } from '../../models/company-reference-model';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { extend } from 'webdriver-js-extender';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-company-references',
  templateUrl: './company-references.component.html',
  styleUrls: ['./company-references.component.css']
})
export class CompanyReferencesComponent extends BaseComponent implements OnInit {

  @Input() providerDetails: Company;
  companyId: number;
  companyType: CompanyType;
  isPremiumCompany = false;
  isSystemAdmin = false;
  companyReferences: CompanyReferenceModel[];
  constructor(public dialog: MatDialog, private companyService: CompanyService,
    private alertService: AlertService, injector: Injector) {
    super(injector);
    if (this.providerDetails != null) {
      this.companyId = this.providerDetails.id
      this.companyType = this.providerDetails.companyType;
      this.isPremiumCompany = this.providerDetails.companyType == CompanyType.Premium;
    }
  }

  ngOnInit() {
    this.isSystemAdmin = this.checkPermission(['SystemAdmin']);
    this.getCompanyReferences();
  }
  getCompanyReferences() {
    if (this.companyId > 0) {
      this.companyService.getAllCompanyReferencesByCompanyId(this.companyId).subscribe((data) => {
        this.companyReferences = data;
      });
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['providerDetails']) {
      setTimeout(() => {
        if (this.providerDetails != null) {
          this.companyId = this.providerDetails.id
          this.companyType = this.providerDetails.companyType;
          this.isPremiumCompany = this.providerDetails.companyType == CompanyType.Premium;
          this.getCompanyReferences();
        }

      });
    }
  }
  createCompanyReference() {
    const dialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "golp-dialog";
    var companyId = this.providerDetails ? this.providerDetails.id : null;
    dialogConfig.data = companyId != null ? { companyId: companyId } : null;

    const companyReferenceCreateDialog = this.dialog.open(CreateCompanyReferenceComponent, dialogConfig);

    const successfullCreate = companyReferenceCreateDialog.componentInstance.onReferenceCreateEvent.subscribe((data) => {
      this.getCompanyReferences();
    });

    companyReferenceCreateDialog.afterClosed().subscribe(() => {
    });
  }
  editReference(reference: CompanyReferenceModel) {
    const dialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "golp-dialog";
    dialogConfig.data = reference;

    const companyReferenceEditDialog = this.dialog.open(CreateCompanyReferenceComponent, dialogConfig);

    const successfullCreate = companyReferenceEditDialog.componentInstance.onReferenceEditEvent.subscribe((data) => {
      this.getCompanyReferences();
    });

    companyReferenceEditDialog.afterClosed().subscribe(() => {
    });
  }
  deleteReference(reference: CompanyReferenceModel) {
    const confirmationDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    confirmationDialogConfig.data = "Are you sure to delete the reference " + reference.firstName + " " + reference.lastName;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.companyService.deleteReference(reference.id).subscribe((data) => {
          this.alertService.success('User deleted successfully');
          this.getCompanyReferences();
        },
          (error) => {
            //this.alertService.error('Internal server error happen');
            console.log(error);
          });
      }
    });
  }
}
