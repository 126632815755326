import { Component, OnInit, Injector } from '@angular/core';
import { CompanyRole } from 'src/app/enums/company-role.enum';
import { Company } from 'src/app/models/company';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component'
import { CommonService, CompanyService, AccountServiceService, AlertService } from 'src/app/services';
import { BaseComponent } from '../base/base.component';
import { CreatePremiumUserComponent } from '../create-premium-user/create-premium-user.component';
import { PremiumUserPagedListInput } from '../../models';
import { ServiceConsumersService } from '../../services/service-consumers.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-premium-users',
  templateUrl: './premium-users.component.html',
  styleUrls: ['./premium-users.component.css']
})


export class PremiumUsersComponent extends BaseComponent implements OnInit {

  dataLoaded: Promise<boolean>;

  currentPage: number = 1;
  pageSize: number = 10;
  itemCountText: string;

  userInfo: any;
  canCreate: boolean = true;

  filteredPremiumUsers: any[];
  premiumUsers: any[];

  premiumUserFilter: any = { firsName: '' };
  premiumUserFilterText: string;
  selectedPremiumUserIndex: number = -1;
  selectedPremiumUser: any;
  selectedPremiumUserName: string;

  premiumUserSubMenu: any[] = [];
  selectedSubMenuIndex: number = 0;
  activeSubMenu: string = "About";
  companyRoleType: CompanyRole = CompanyRole.None;
  totalItems: number = 0;
  selectedPremiumUserPhoneNumber: any;
  isFromEditPage = false;
  editItemIndex = 0;

  constructor(private companyService: CompanyService, private dialog: MatDialog,
    private accountService: AccountServiceService,
    private commonService: CommonService,
    private alertService: AlertService,
    private consumerService: ServiceConsumersService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.getLoggedInUser();
    this.getPremiumUsers();

    this.premiumUserSubMenu.push({ "name": "About", "dataTarget": "aboutUserTab" });

    this.premiumUserSubMenu.push({ "name": "Golp Credit", "dataTarget": "userCreditTab" });
    this.premiumUserSubMenu.push({ "name": "References", "dataTarget": "userReferenceTab" });

    this.dataLoaded = Promise.resolve(true);

  }

  createPremiumUser() {
    const dialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'golp-dialog';
    //dialogConfig.data = { isFromPremiumPage: true, isFromPremiumCreatePage: true };

    const premiumUserCreateDialog = this.dialog.open(CreatePremiumUserComponent, dialogConfig);

    const successfullCreate = premiumUserCreateDialog.componentInstance.onUserCreateEvent.subscribe((data) => {
      this.getPremiumUsers();
    });

    premiumUserCreateDialog.afterClosed().subscribe(() => {
      //successFullEdit.unsubscribe();
      //this.detailsLoaded = Promise.resolve(false);
    });

  }

  onPremiumUserChange(provider, index) {
    if (this.selectedPremiumUserIndex != index) {
      this.selectedPremiumUserIndex = index === this.selectedPremiumUserIndex ? -1 : index;
      this.selectedPremiumUser = provider;
      this.selectedPremiumUserName = provider.firstName + " " + provider.lastName;
      this.selectedPremiumUserPhoneNumber = this.selectedPremiumUser.phoneNumber;

    }
    let body = document.getElementsByTagName('body')[0];
    body.classList.add("display-tab-content");
    this.commonService.itemSelectionUpdated(this.selectedPremiumUser);
  }

  onSubMenuChange(name, index) {
    if (this.selectedSubMenuIndex != index) {
      this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
      this.activeSubMenu = this.premiumUserSubMenu.filter(c => c.name === name)[0].name;
    }
  }

  getPremiumUserDetailsChange(event) {
    //let premiumUser = this.filteredPremiumUsers.filter(c => c.id === event.id)[0];
    //premiumUser.profileImageUrl = event.profileImageUrl;
    //premiumUser.firstName = event.firstName;
    //premiumUser.phoneNumber = event.phoneNumber;
    //premiumUser.firstName = event.lastName;
    //premiumUser.email = event.email;
    this.isFromEditPage = true;
    this.editItemIndex = this.selectedPremiumUserIndex;
    this.getPremiumUsers();
  }

  OnMarkAsGeneralUser(selectedPremiumUser: any): void {
    const confirmationDialogConfig = new MatDialogConfig()

    // Setting different dialog configurations
    confirmationDialogConfig.data = "Are you sure to delete the premium user " + selectedPremiumUser.firstName;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.markAsGeneralUser(selectedPremiumUser);
      }
    });
  }

  markAsGeneralUser(premiumUser: any) {
    this.consumerService.markAsGeneralUser(premiumUser.id).subscribe((data) => {
      this.alertService.success('PremiumUser deleted successfully');
     
      this.getPremiumUsers();
    },
      (error) => {
      });
  }

  getPremiumUsers(): void {
    var input = new PremiumUserPagedListInput();
    input.premiumUserName = this.premiumUserFilter.firstName;
    input.pageNumber = this.currentPage;
    input.pageSize = this.pageSize;

    this.accountService.getPremiumUsersPagedList(input)
      .subscribe((data) => {
        this.premiumUsers = data.data;
        this.premiumUserCopy();

        this.totalItems = data.rowCount;

        this.highlightFirstItem(this.premiumUsers);
        this.itemCount();

      },
        (error) => {
          // if(error.status == 403){
          //   this.alertService.error('You do not have permission');
          // }
        });
  }

  getLoggedInUser() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
    if (CompanyRole.None === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
      this.canCreate = false;
    }
  }

  highlightFirstItem(premiumUsers: any[]) {
    // set initial offeror
    if (premiumUsers !== null) {
      if (this.isFromEditPage) {
        this.isFromEditPage = false;
        this.selectedPremiumUserIndex = this.editItemIndex;
        this.selectedPremiumUser = premiumUsers[this.editItemIndex];

      }
      else {
        this.selectedPremiumUser = premiumUsers[0];

        this.selectedPremiumUserIndex = 0;
      }

      this.selectedPremiumUserName = this.premiumUserFilter.firstName != null ? this.premiumUserFilter.firstName : ""
        + " " + this.premiumUserFilter.lastName != null ? this.premiumUserFilter.lastName : ""
      this.selectedPremiumUserPhoneNumber = this.selectedPremiumUser.phoneNumber;

     
    }
  }

  pageChanged(event) {
    this.selectedPremiumUserIndex = 0;
    this.currentPage = event;
    this.getPremiumUsers();
  }

  premiumUserFilteredItem(value) {
    this.premiumUserFilter.firstName = value;
    this.getPremiumUsers();
  }

  premiumUserCopy() {
    this.filteredPremiumUsers = Object.assign([], this.premiumUsers);
  }

  itemCount() {
    let firstItem = (this.currentPage - 1) * this.pageSize + 1;
    let lastItem = (this.currentPage) * this.pageSize;
    if (lastItem > this.totalItems)
      lastItem = this.totalItems;
    this.itemCountText = firstItem + '-' + lastItem + ' of ' + this.totalItems + ' Gifts';
  }



}
