import { filter, map, switchMap } from 'rxjs/operators';

import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationStart, ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';



@Component({
  selector: 'app-web-app-layout',
  templateUrl: './web-app-layout.component.html',
  styleUrls: ['./web-app-layout.component.css']
})
export class WebAppLayoutComponent implements OnInit {

  showHeader: boolean = false;
  showFooter: boolean = false;

  constructor(private router: Router, private renderer: Renderer2, private activatedRoute: ActivatedRoute){

    
  
    
    let token = localStorage.getItem('userToken');
    router.events.forEach((event) => {

      if (this.router.url === '/login' || this.router.url === '/loginwithphone' ||  (this.router.url === '' && token == null)){
        this.renderer.addClass(document.body, 'layout-sidebar-login');
        this.renderer.removeClass(document.body, 'layout-sidebar');
        this.showHeader = false;
        this.showFooter = false;
      }
      
      else{
        this.showHeader = true;
        this.showFooter = true;
        this.renderer.addClass(document.body, 'layout-sidebar');
        this.renderer.removeClass(document.body, 'layout-sidebar-login');
      }
      // if(event instanceof NavigationStart){
      //   if(event['url'] === '/login' || (event['url'] === '/' && token == null)){
      //     this.renderer.addClass(document.body, 'layout-sidebar-login');
      //     this.renderer.removeClass(document.body, 'layout-sidebar');
      //     this.showHeader = false;
      //     this.showFooter = false;
      //   }
      //   else if(event['url'].startsWith('/payment-option/consumer')){
      //     this.showHeader = false;
      //     this.showFooter = false;
      //   }
      //   else{
      //     this.showHeader = true;
      //     this.showFooter = true;
      //     this.renderer.addClass(document.body, 'layout-sidebar');
      //     this.renderer.removeClass(document.body, 'layout-sidebar-login');
      //   }
      // }
    });

    


  }
  ngOnInit() {
    //let url = this.activatedRoute.snapshot.url;
  }

}
