import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Company, Offer, GiftAmountSetting } from '../../models';
import { ReplaySubject } from 'rxjs';
import { ValidationUtility } from '../../utility/validation.utility';
import { CompanyService, OfferService, AccountServiceService } from '../../services';
import { consumerOffer } from '../../models/ConsumerOffer';
import { Router } from '@angular/router';
import { CompanySettingType } from '../../enums/company-setting.type';
import { CompanySettingService } from '../../services/company-setting.service';

@Component({
  selector: 'app-gift-sent-process',
  templateUrl: './gift-sent-process.component.html',
  styleUrls: ['./gift-sent-process.component.css']
})
export class GiftSentProcessComponent implements OnInit {

  activeSubMenu = 'SendGifts';
  gift: consumerOffer;
  selectedMerchantId: number;
  merchantFilterCtrl: FormControl = new FormControl();
  merchants: Company[];
  sendgiftForm: FormGroup;
  filteredMerchants: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);
  submitted = false;
  userInfo: any;
  token1: any;
  token2: any;

  globalGiftAmountSetting: GiftAmountSetting;
  globalMinAmount: number = 0;
  globalMaxAmount?: number;

  minAmount: number;
  maxAmount?: number;
  useSlab: boolean = false;
  slabs: any;
  offerPriceSlab: any;

  constructor(private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<GiftSentProcessComponent>,
    private companyService: CompanyService,
    private offerService: OfferService,
    private accountService: AccountServiceService,
    private companySettingService: CompanySettingService,
    private router: Router) {
    this.gift = new consumerOffer();
    this.getLoggedInUser();
    this.getLoggedInUserToken();
    this.getMerchants();

  }
  onSentGiftEvent: EventEmitter<any> = new EventEmitter();


  ngOnInit() {
    this.buildForm();;
    this.bindFilterChangeEvent();
    this.getGlobalSettings();
  }


  onChangeMerchant(merchantId) {
    this.selectedMerchantId = merchantId;
    this.getSettingsBySelectedCompany(merchantId);
  }


  bindFilterChangeEvent() {
    this.merchantFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterData(this.merchants, this.merchantFilterCtrl.value, this.filteredMerchants);
      });
  }
  filterData(allData: any, search: string, filterData: any) {
    if (!allData) {
      return;
    }
    if (!search) {
      filterData.next(allData);
      return;
    } else {
      search = search.toLowerCase();
    }
    filterData.next(
      allData.filter(data => data.name.toLowerCase().indexOf(search) > -1)
    );
  }

  buildForm() {
    this.sendgiftForm = this.formBuilder.group({
      phoneNumbers: [this.gift.phoneNumbers, [Validators.required]],
      emailAddress: [this.gift.emailAddress, [Validators.email]],
      merchantId: [this.gift.merchantId],
      offerPrice: [this.gift.offerPrice, [Validators.required, Validators.pattern('^\\d+(\\.\\d{1,2})?$'), Validators.pattern('^[1-9]+[0-9]*$'),
      (control: AbstractControl) => Validators.max(this.maxAmount)(control),
      (control: AbstractControl) => Validators.min(this.minAmount)(control)]],
      offerPriceSlab: [this.offerPriceSlab],
      offerOccasion: [this.gift.offerOccasion]
    });
  }

  getMerchants() {
    this.companyService.getMerchants()
      .subscribe(data => {
        this.merchants = data;
        this.filteredMerchants.next(this.merchants);
      });
  }


  onSubmit() {
    this.submitted = true;
    const formData = new FormData();

    // stop here if form is invalid
    if (this.sendgiftForm.invalid) {
      return;
    }
    this.sendOffer();

    this.dialogRef.close();
  }
  getLoggedInUserToken() {
    let userToken = localStorage.getItem('userToken');
    if (userToken.length > 0) {
      var middle = Math.floor(userToken.length / 2);
      this.token1 = userToken.substr(0, middle);
      this.token2 = userToken.substr(middle);
    }
  }
  getLoggedInUser() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
  }

  getGlobalSettings() {
    this.companySettingService.getSettingsByCompanyAndType(CompanySettingType.GiftAmount).subscribe(result => {
      if (result) {
        this.globalGiftAmountSetting = new GiftAmountSetting(JSON.parse(result.value));
        this.globalMinAmount = this.globalGiftAmountSetting.minAmount;
        this.globalMaxAmount = this.globalGiftAmountSetting.maxAmount ? this.globalGiftAmountSetting.maxAmount : undefined;
        this.minAmount = this.globalMinAmount;
        this.maxAmount = this.globalMaxAmount;
        this.useSlab = false;
      }
    });
  }

  async getSettingsBySelectedCompany(merchantId: number) {
    this.companySettingService.getSettingsByCompanyAndType(CompanySettingType.GiftAmount, merchantId).subscribe(result => {
      if (result) {
        var giftAmountSetting = new GiftAmountSetting(JSON.parse(result.value));
        this.minAmount = giftAmountSetting.minAmount;
        this.maxAmount = giftAmountSetting.maxAmount ? giftAmountSetting.maxAmount : undefined;
        this.useSlab = giftAmountSetting.useSlab;
        this.slabs = giftAmountSetting.slabs;

      }
      else {
        this.minAmount = this.globalMinAmount;
        this.maxAmount = this.globalMaxAmount;
        this.useSlab = false;
      }

      this.setOfferPriceOrSlabValidators();
    }, error => {

    });

  }

  setOfferPriceOrSlabValidators() {
    const offerPriceControl = this.sendgiftForm.get('offerPrice');
    const offerPriceSlabControl = this.sendgiftForm.get('offerPriceSlab');

    if (this.useSlab == false) {
      offerPriceControl.setValidators([Validators.required]);
      offerPriceSlabControl.setValidators(null);
    }

    if (this.useSlab == true) {
      offerPriceControl.setValidators(null);
      offerPriceSlabControl.setValidators([Validators.required]);
    }

    offerPriceControl.updateValueAndValidity();
    offerPriceSlabControl.updateValueAndValidity();
  }

  sendOffer() {
    this.gift = new consumerOffer(this.sendgiftForm.value);
    this.gift.ownedByConsumer = this.userInfo.consumerId;

    if (this.gift !== null) {
    }

    if (this.useSlab) {
      this.gift.offerPrice = this.offerPriceSlab;
    }

    this.offerService.sendConsumerOffer(this.gift)
      .subscribe((data: any) => {
        if (data.billingPageUrl != null) {
          //this.getLoggedInUserToken();
          var paymentUrl = data.billingPageUrl + 'token-1/' + this.token1 + '/token-2/' + this.token2 + '/';
          window.location.href = paymentUrl;
        }
      },
        (error: any) => {
          //this.showErrorMessage(error);
        })
  }

  close() {
    this.dialogRef.close();
  }

  get f() { return this.sendgiftForm.controls; }
}
