import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, Injector } from '@angular/core';
import { Company } from 'src/app/models/company';
import { CreateOfferProviderComponent } from '../create-offer-provider/create-offer-provider.component';
import { BaseComponent } from '../base/base.component';
import { UserType } from '../../enums';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-offer-provider-details',
  templateUrl: './offer-provider-details.component.html',
  styleUrls: ['./offer-provider-details.component.css']
})
export class OfferProviderDetailsComponent extends BaseComponent implements OnInit {

  @Input() offerProviderDetailsInput: any;
  @Output() offerProviderDetailsInputChange: EventEmitter<Company> = new EventEmitter<Company>();
  
  offerProviderDetails: Company;
  offerProviderLogoOnErr: String;
  offerProviderLogoTxtOnErr = false;
  detailsLoaded: Promise<boolean>;
  CompanyType = UserType;

  constructor(public dialog: MatDialog, private injector: Injector) {
      super(injector);
  }

  ngOnInit() {
    this.offerProviderDetails = new Company();
    this.detailsLoaded = Promise.resolve(true);
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['offerProviderDetailsInput']){
      setTimeout(()=>{
        this.offerProviderDetails = this.offerProviderDetailsInput;
        this.offerProviderLogoTxtOnErr = false;
        //this.offerProviderLogoOnErr = "http://place-hold.it/80x80/ebe89f/9D2933&fontsize=40&text="+ this.offerProviderDetails.name.charAt(0);
        this.detailsLoaded = Promise.resolve(true);
      });
    }
 }
 editOfferProvider(offerProvider: Company){
    const editDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    editDialogConfig.disableClose = true;
    editDialogConfig.autoFocus = true;

    editDialogConfig.data = offerProvider;
    editDialogConfig.panelClass = 'golp-dialog';

    const offerorEditDialog = this.dialog.open(CreateOfferProviderComponent, editDialogConfig);

    const successFullEdit = offerorEditDialog.componentInstance.onOfferProviderEditEvent.subscribe((data) => {
      this.offerProviderDetails = data;
      this.offerProviderDetailsInputChange.emit(this.offerProviderDetails);
    });

    offerorEditDialog.afterClosed().subscribe(() => {
    });

  }
  errorHandler(event) {
    //event.target.src = this.offerProviderLogoOnErr;
    this.offerProviderLogoTxtOnErr = true;
  }

}
