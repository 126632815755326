import { Component, OnInit, Injector } from '@angular/core';
import { CompanyRole } from 'src/app/enums';
import { AccountServiceService } from 'src/app/services';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent extends BaseComponent implements OnInit {

  dataLoaded: Promise<boolean>;

  userInfo: any;
  canCreate: boolean = true;
  selectedsiteAdminName: string;
  siteAdminSubMenu: any[] = [];
  selectedSubMenuIndex: number = 0;
  activeSubMenu: string = "Gifts";

  companyRoleType: CompanyRole = CompanyRole.None;

  constructor(private accountService: AccountServiceService,
    injector: Injector
  ) {
    super(injector);
    if (this.permissionCheckerService.hasPermission(['ConsumerGiftReport']) ||
      this.permissionCheckerService.hasPermission(['CorporateGiftReport'])) {
      this.activeSubMenu = "Gifts";
    }
    else if (this.permissionCheckerService.hasPermission(['UserRegistrationReport'])) {
      this.activeSubMenu = "User Registrations";
    }
    else if (this.permissionCheckerService.hasPermission(['ReadPaymentDueReport'])) {
      this.activeSubMenu = "Due Payments";
    }
  }

  ngOnInit() {
    this.getLoggedInUser();
    if (this.permissionCheckerService.hasPermission(['ConsumerGiftReport']) ||
      this.permissionCheckerService.hasPermission(['CorporateGiftReport'])) {
      this.siteAdminSubMenu.push({ "name": "Gifts", "dataTarget": "giftsTab" });
    }
    if (this.permissionCheckerService.hasPermission(['UserRegistrationReport'])) {
      this.siteAdminSubMenu.push({ "name": "User Registrations", "dataTarget": "userRegistrationsTab" });
    }
    if (this.permissionCheckerService.hasPermission(['ReadPaymentDueReport'])) {
      this.siteAdminSubMenu.push({ "name": "Due Payments", "dataTarget": "duePaymentReportTab" });
    }
    if (this.permissionCheckerService.hasPermission(['PremiumUserCreditUsageReport', 'PremiumUserCreditLimitExceedReport'])) {
      this.siteAdminSubMenu.push({ "name": "Premium Users", "dataTarget": "premiumUsersReportTab" });
    }
    this.dataLoaded = Promise.resolve(true);
  }

  onSubMenuChange(name, index) {
    if (this.selectedSubMenuIndex != index) {
      this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
      this.activeSubMenu = this.siteAdminSubMenu.filter(c => c.name === name)[0].name;
    }
  }

  getLoggedInUser() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
    this.canCreate = true;
  }
}
