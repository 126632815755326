import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as moment from 'moment';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { MerchantOutletResponse } from 'src/app/models/view-models/merchant-outlet-response';
import { AlertService } from 'src/app/services';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import { DataService } from '../app-send-gift/services/shared/data.service';

@Component({
  selector: 'app-user-offer-report',
  templateUrl: './user-offer-report.component.html',
  styleUrls: ['./user-offer-report.component.css']
})
export class UserOfferReportComponent implements OnInit {

  merchantId:any;
  processId: any;
  userInfo:any;
  companyOutletId:any;
  merchantOutlets:any;
  orders:any;
  submitted:boolean;
  p: number = 1;
  offerReportForm: FormGroup;
  defaultStartDate: Date = new Date();
  defaultEndDate: Date = new Date();
  currentPage: number = 1;
  count = 0;
  pageSize = 25;
  pageSizes = [25, 50, 100];
  public defaultTime = [];

  sentOffers:any;
  constructor(private dataService: DataService, private formBuilder: FormBuilder, private alertService: AlertService, private dialog: MatDialog) {
    var startDate1 = new Date();
    this.defaultStartDate = this.startOfMonth(startDate1);
    this.defaultEndDate = new Date();
    this.buildForm();
   }

   startOfMonth(date)
  {
    return new Date(date.getFullYear(), date.getMonth(), 1); 
  }

  ngOnInit(): void {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userInfo = userInfo;
    if(this.userInfo.outletQrCode == undefined || this.userInfo.outletQrCode == null) 
      this.getMerchantOutlet();
    if(this.userInfo.outletQrCode != undefined && this.userInfo.outletQrCode != null)
      this.companyOutletId = this.userInfo.outletQrCode;

      this.loadData();
  }

  setDefaultTime(){
    let d = new Date();
    this.defaultTime = [d.getHours(),d.getMinutes(),d.getSeconds()]
    console.log(this.defaultTime)
  }

  handlePageChange(event: number): void {
    this.p = event;
    this.loadData();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.p = 1;
    this.loadData();
  }

  loadData(reset:boolean = false){
    // let url = `api/order-processed-record/merchant/${this.merchantId}?fromDate=${fromDate}&toDate=${todate}`;
    // if(this.userInfo.outletQrCode != null || this.userInfo.outletQrCode != undefined || outletId != 0)
    //   url += `&outletId=${this.companyOutletId}`;
    // if(hasAvailed != null)
    //   url += `&hasAvailed=${hasAvailed}`;
    if(reset) this.p = 1;
    this.submitted = true;
    // stop here if form is invalid
    if (this.offerReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }
    let deliveryStatus = this.f.deliveryStatus.value;

    let receiver = this.f.receiver.value;


    let inputDeliveryStatus = null;
    if(deliveryStatus == 1) {
      inputDeliveryStatus = true;
    }
    else if(deliveryStatus == 2) {
      inputDeliveryStatus = false;
    }
    else inputDeliveryStatus = null;

    let fromDate = moment(this.f.fromDate.value).format("YYYY/MM/DDTHH:mm:ss");
    let todate = moment(this.f.toDate.value).format("YYYY/MM/DDTHH:mm:ss");
    let outletId = this.f.outletId.value;
    if(outletId != 0)
      this.companyOutletId = outletId.qrCode;
    let url = `api/OfferReports/offer-assignment/${this.userInfo.companyId}?fromDate=${fromDate}&toDate=${todate}&pageSize=${this.pageSize}&pageNumber=${this.p}`;
    if(this.userInfo.outletQrCode != null || this.userInfo.outletQrCode != undefined || outletId != 0)
      url += `&outletId=${this.companyOutletId}`;
    if(inputDeliveryStatus != null)
      url += `&hasAvailed=${inputDeliveryStatus}`;

    if(receiver != null && receiver != undefined && receiver != ""){
      url += `&receiver=${receiver}`;
    }

    this.dataService.get(url)
    .subscribe((response: any) => {
      if(response.success){
       this.count = response.data.count;
        this.sentOffers = response.data.offerAssignmentList;
        console.log("response : ", this.sentOffers);
      }
      else{
        console.log(response);
      }
    
    });
  }

  buildForm() {
    this.offerReportForm = this.formBuilder.group({
      fromDate: [this.defaultStartDate, Validators.required],
      toDate: [this.defaultEndDate, Validators.required],
      outletId: ['', Validators.nullValidator],
      processId:['', Validators.nullValidator],
      deliveryStatus: ['', Validators.nullValidator],
      invoiceNo: ['', Validators.nullValidator],
      orderNo: ['', Validators.nullValidator],
      receiver: ['', Validators.nullValidator],
    });
  }

  get f() { return this.offerReportForm.controls; }
 

  getMerchantOutlet() {
    let url = `api/Outlets/company/${this.userInfo.companyId}/`;
    // if(this.companyOutletId != null)
    //   url += `${this.companyOutletId}`
    this.dataService.get(url).subscribe((data: MerchantOutletResponse[]) => {
      if(data != null && data.length > 0){
        this.merchantOutlets = data;
        console.log( this.merchantOutlets);
      }
    });
  }

}
