import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyReferenceModel } from '../../models/company-reference-model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationUtility } from '../../utility/validation.utility';
import { CompanyService, AccountServiceService } from '../../services';

@Component({
  selector: 'app-create-company-reference',
  templateUrl: './create-company-reference.component.html',
  styleUrls: ['./create-company-reference.component.css']
})
export class CreateCompanyReferenceComponent implements OnInit {

  onReferenceCreateEvent: EventEmitter<any> = new EventEmitter();
  onReferenceEditEvent: EventEmitter<any> = new EventEmitter();
  companyReference: CompanyReferenceModel;
  companyId: number;
  isEdit = false;
  userInfo: any;
  companyReferenceForm: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateCompanyReferenceComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private companyService: CompanyService, private accountService: AccountServiceService) {
    this.companyReference = new CompanyReferenceModel();
    if (data != null) {
      if (isNaN(data)) {
        this.companyReference = new CompanyReferenceModel(data);
        this.companyId = this.companyReference.companyId;
        this.isEdit = true;
      }
      else {
        this.isEdit = false;
        this.companyId = data;
      }
    }
  }

  ngOnInit() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
    this.isEdit = this.companyReference.id != undefined;
    this.buildForm();
  }
  close() {
    this.dialogRef.close();
  }
  buildForm() {
    this.companyReferenceForm = this.formBuilder.group({
      //id: [this.companyCredit.id],
      companyId: [this.companyReference.companyId],
      firstName: [this.companyReference.firstName ? this.companyReference.firstName : '', [Validators.required, Validators.maxLength(30)]],
      lastName: [this.companyReference.lastName ? this.companyReference.lastName : '', [Validators.required, Validators.maxLength(30)]],
      email: [this.companyReference.email],
      phone: [this.companyReference.phone ? this.companyReference.phone : '', Validators.required],
      createdBy: [this.companyReference.createdBy],
      isActive: [this.companyReference.isActive],
      address: [this.companyReference.address, Validators.required]
    });
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.companyReferenceForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    if (this.companyReference.id === undefined) { // create
      this.createCompanyReference();
    }
    else if (this.companyReference.id != undefined && this.companyReference.id != null) { // edit
      this.editCompanyReference();
    }
    this.dialogRef.close();
  }
  editCompanyReference() {
    var referenceId = this.companyReference.id;
    var createdDate = this.companyReference.createdDate;
    this.companyReference = new CompanyReferenceModel(this.companyReferenceForm.value);
    this.companyReference.id = referenceId;
    this.companyReference.createdDate = createdDate;
    this.companyService.saveCompanyReference(this.companyReference).subscribe((data: any) => {
      this.onReferenceEditEvent.emit(this.companyReference.id);
    },
      (error: any) => {
        //this.showErrorMessage(error);
      })
  }
  createCompanyReference() {
    this.companyReference = new CompanyReferenceModel(this.companyReferenceForm.value);
    if (this.companyReference !== null) {
      this.companyReference.companyId = this.companyId;
      this.companyReference.isActive = true;
      this.companyReference.createdBy = this.userInfo.id;
      //this.companyReference.createdDate = '';
    }
    this.companyService.saveCompanyReference(this.companyReference).subscribe((data: any) => {
      this.onReferenceCreateEvent.emit(this.companyReference.id);
    },
      (error: any) => {
        //this.showErrorMessage(error);
      });
  }
  get f() { return this.companyReferenceForm.controls; }
}
