import { Component, OnInit, ViewChild, ElementRef, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RedemptionGiftDetails } from 'src/app/models/redemption-gift-details';
import { RedemptionService, AlertService } from 'src/app/services';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { AvailInfoComponent } from '../avail-info/avail-info.component';
import { DataService } from '../../app-send-gift/services/shared/data.service';
import { RedemptionOtpComponent } from '../../redemption-otp/redemption-otp.component';
import { Router } from "@angular/router";

@Component({
  selector: 'app-gift-redemption',
  templateUrl: './gift-redemption.component.html',
  styleUrls: ['./gift-redemption.component.css'],
})
export class GiftRedemptionComponent implements OnInit {
  @Input() giftDetailsInput: RedemptionGiftDetails;
  @ViewChild('purchaseamount') purchaseAmountRef: ElementRef;

  redemptionForm: FormGroup;
  submitted = false;

  userInfo: any;
  giftDetails: RedemptionGiftDetails;
  giftCode: any;
  giftPin: any;
  redeemSuccessMessage: string = 'search gift/offer by code & PIN.';
  redeemSuccess: boolean = true;

  showOtpInput: boolean = false;
  otp: string;
  otpErrorMsg: string;
  otpIssue: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private redemptionService: RedemptionService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private dataService: DataService,
    private router: Router
  ) {
    this.giftDetails = new RedemptionGiftDetails();
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['giftDetailsInput']) {
      setTimeout(() => {
        this.giftDetails = this.giftDetailsInput;
        if (this.giftDetails != null) {
          this.redeemSuccess = false;
          this.buildForm();
          setTimeout(() => {
            this.purchaseAmountRef.nativeElement.focus();
          }, 100);
        }
        if (this.giftDetails == null) {
          this.redeemSuccessMessage = 'Gift does not found.';
          this.redeemSuccess = true;
        }
      });
    }
  }

  buildForm() {
    this.redemptionForm = this.formBuilder.group({
      giftReceiverPhone: [
        { value: this.giftDetails.giftReceiverPhone, disabled: true },
      ],
      giftAmount: [{ value: this.giftDetails.giftAmount, disabled: true }],
      purchaseAmount: [this.giftDetails.purchaseAmount, [Validators.required]],
      merchantInvoiceNumber: [this.giftDetails.merchantInvoiceNumber],
    });
  }

  ngOnInit() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    console.log(this.userInfo);
  }

  onClickRedemption(item: any) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.redemptionForm.invalid) {
      return;
    }

    let newData = new RedemptionGiftDetails(this.redemptionForm.value);

    if (this.giftDetails != null) {
      this.giftDetails.purchaseAmount = newData.purchaseAmount;
      this.giftDetails.merchantInvoiceNumber = newData.merchantInvoiceNumber;
    }
    const confirmationDialogConfig = new MatDialogConfig();
    let giftInformaton: any = {};
    giftInformaton.invoiceNumber = newData.merchantInvoiceNumber;
    giftInformaton.amount = this.giftDetails.giftAmount;
    giftInformaton.salesAmount = newData.purchaseAmount;
    giftInformaton.message = `Do you really want to proceed?`;
    if (this.giftDetails.otpRedemptionEnabled != undefined
      && this.giftDetails.otpRedemptionEnabled != null
      && this.giftDetails.otpRedemptionEnabled) {
      giftInformaton.message += `<br/>An OTP will be sent to ${this.giftDetailsInput.giftReceiverPhone}.`;
    }
    confirmationDialogConfig.data = giftInformaton;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    confirmationDialogConfig.minWidth = 70;
    const confirmationDialog = this.dialog.open(AvailInfoComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        if (this.giftDetails.otpRedemptionEnabled != undefined
          && this.giftDetails.otpRedemptionEnabled != null
          && this.giftDetails.otpRedemptionEnabled
        ) {
          this.SendOtp();
        }

        else this.applyRedemption(this.giftDetails);
      }
    });
  }

  showOtpOption(phone: string) {
    const dialogConfig = new MatDialogConfig();
    // Setting different dialog configurations
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'golp-dialog';
    dialogConfig.data = phone.replace(' ', '').replace('-', '');
    dialogConfig.minWidth = '100vw';

    const redemptionOtpDialog = this.dialog.open(
      RedemptionOtpComponent,
      dialogConfig
    );

    const successfullCreate =
      redemptionOtpDialog.componentInstance.onValidOtpEvent.subscribe(
        (data) => {
          if (data) this.applyRedemption(this.giftDetails);
        }
      );
  }


  SendOtp() {
    let url = 'api/OfferAssignments/send-redemption-otp';
    let payload = {
      merchantId: this.userInfo.companyId,
      phoneNumber: this.giftDetailsInput.giftReceiverPhone,
    };

    this.dataService.post(url, payload).subscribe(
      (res: any) => {
        if (res.success) {
          this.showOtpOption(this.giftDetailsInput.giftReceiverPhone);
        } else {
          this.alertService.error('OTP sending failed, please try again.');
        }
      },
      (err) => {
        this.alertService.error('OTP sending failed, please try again.');
      }
    );
  }

  applyRedemption(giftDetails: any) {
    let requestObject = {
      giftId: giftDetails.giftId,
      giftCode: giftDetails.giftCode,
      merchantId: this.userInfo.companyId,
      purchaseAmount: giftDetails.purchaseAmount,
      outletQRCode: this.userInfo.outletQrCode,
      merchantInvoiceNumber: this.giftDetails.merchantInvoiceNumber,
    };

    this.redemptionService.applyRedemption(requestObject).subscribe(
      (response: any) => {
        if (response.success && response.data.transactionId != null) {
          this.redeemSuccessMessage = 'Gift redeemed successfully.';
          this.redeemSuccess = true;
          this.giftCode = '';
          this.router.navigate(['/redemption-success', response.data.transactionId]);
        }
        if (!response.success) {
          this.redeemSuccess = false;
          this.alertService.error(response.data);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.redemptionForm.controls;
  }
}
