import { Component, OnInit, Output, EventEmitter, Input, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { Company, TrendingMerchant } from '../../models';
import { CompanyService, TrendingMerchantService, AlertService } from '../../services';
import { ValidationUtility } from '../../utility/validation.utility';

@Component({
  selector: 'app-create-trending-merchant',
  templateUrl: './create-trending-merchant.component.html',
  styleUrls: ['./create-trending-merchant.component.css']
})

export class CreateTrendingMerchantComponent implements OnInit {

  // onCreate event
  onTrendingMerchantCreateEvent: EventEmitter<Company> = new EventEmitter();
  // onEdit event;
  onTrendingMerchantEditEvent: EventEmitter<Company> = new EventEmitter();
  inEditMode: boolean = false;
  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateTrendingMerchantComponent>,
    private companyService: CompanyService,
    @Inject(MAT_DIALOG_DATA) data,
    private alertService: AlertService,
    private trendingMerchantService: TrendingMerchantService) {
    this.trendingMerchant = new TrendingMerchant();
    if (data !== null) {
      this.trendingMerchant = new TrendingMerchant(data);
      this.inEditMode = true;
    }
    this.buildForm();
  }

  trendingMerchantForm: FormGroup;
  trendingMerchant: TrendingMerchant;

  submitted = false;

  trendingMerchants: any;

  merchants: Company[];

  trendingMerchantsFilterCtrl: FormControl = new FormControl();
  filteredTrendingMerchants: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);

  ngOnInit() {

    this.getMerchants();
    this.bindFilterChangeEvent();
  }

  ngOnChanges() {
  }

  buildForm() {
    this.trendingMerchantForm = this.formBuilder.group({
      merchantId: [this.trendingMerchant.merchantId, Validators.required],
      orderNo: [this.trendingMerchant.orderNo, [Validators.min(1)]],
      isActive: [this.trendingMerchant.isActive]
    });
  }

  bindFilterChangeEvent() {
    this.trendingMerchantsFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterData(this.merchants, this.trendingMerchantsFilterCtrl.value, this.filteredTrendingMerchants);
      });
  }

  filterData(allData: any, search: string, filterData: any) {
    if (!allData) {
      return;
    }
    if (!search) {
      filterData.next(allData);
      return;
    } else {
      search = search.toLowerCase();
    }
    filterData.next(
      allData.filter(data => data.name.toLowerCase().indexOf(search) > -1)
    );
  }

  getMerchants() {
    this.companyService.getMerchants()
      .subscribe(data => {
        this.merchants = data;
        this.filteredTrendingMerchants.next(this.merchants);
      });
  }


  get f() { return this.trendingMerchantForm.controls; }

  close() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.trendingMerchantForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    if (this.trendingMerchant.id === undefined) { // create
      this.createTrendingMerchant();
    }
    else if (this.trendingMerchant.id > 0) { // edit
      this.editTrendingMerchant();
    }
    this.dialogRef.close();
  }


  createTrendingMerchant() {
    this.trendingMerchant = new TrendingMerchant(this.trendingMerchantForm.value);

    this.trendingMerchantService.createTrendingMerchant(this.trendingMerchant)
      .subscribe((data: any) => {
        this.onTrendingMerchantCreateEvent.emit(data);
        this.alertService.success("Trending Merchant added successfully");
      },
        (error: any) => {

        })
  }

  editTrendingMerchant() {
    let newData = new TrendingMerchant(this.trendingMerchantForm.value);
    if (this.trendingMerchant !== null) {
      this.trendingMerchant.isActive = newData.isActive;
      this.trendingMerchant.merchantId = newData.merchantId;
      this.trendingMerchant.orderNo = newData.orderNo;

      this.trendingMerchantService.editTrendingMerchant(this.trendingMerchant)
        .subscribe((data: any) => {
          this.onTrendingMerchantEditEvent.emit(data);
          this.alertService.success("Trending Merchant edited successfully");
        },
          (error: any) => {
            this.showErrorMessage(error);
          });
    }
  }


  showErrorMessage(error: any) {
    console.log(error);
    //this.alertService.error("Internal server error happen");
  }
}
