import { AlertService } from './../../../../../services/alert.service';
import { DataService } from './../../../../../components/app-send-gift/services/shared/data.service';
import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValidationUtility } from 'src/app/utility/validation.utility';

@Component({
  selector: 'app-new-bill-settings-key',
  templateUrl: './new-bill-settings-key.component.html',
  styleUrls: ['./new-bill-settings-key.component.css']
})
export class NewBillSettingsKeyComponent implements OnInit {
 // onCreate event
  onAddBillSettingKeyEvent: EventEmitter<any> = new EventEmitter();


  //form related
  submitted:boolean = false;

  constructor(private dialogRef: MatDialogRef<NewBillSettingsKeyComponent>,
    @Inject(MAT_DIALOG_DATA) data, private formBuilder: FormBuilder, private dataService:DataService, private alertService: AlertService) {
      this.buildForm();
     }
  
  billSettingsKeyFormGroup: FormGroup;

  ngOnInit(): void {
  }

  buildForm(){
    this.billSettingsKeyFormGroup = this.formBuilder.group({
      keyName: ['', Validators.required],
      valueType: ['', Validators.required],
      order: [0, Validators.required],
      description: [''],
      status: [''],
    });
  }
  onSubmit(){
    this.submitted = true;
    // stop here if form is invalid
    if (this.billSettingsKeyFormGroup.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    let url = "api/billsettingskey/add-update-billsettingskey";
    let formData = this.billSettingsKeyFormGroup.value;
    let payload= {
      "keyName": formData.keyName,
      "valueType": formData.valueType,
      "description": formData.description,
      "isActive": formData.status,
    }

    this.dataService.post(url, payload).subscribe((res : any)=>{
      if(res.result){
        this.onAddBillSettingKeyEvent.next(res);
        this.close();
      }
      else{
        this.alertService.error(res.message)
      }
    },err=>{console.log(err)});

  }

  close(){
    this.dialogRef.close();
  }

  get f() { return this.billSettingsKeyFormGroup.controls; }

}
