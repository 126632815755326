import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services';

@Component({
  selector: 'app-tab-header',
  templateUrl: './tab-header.component.html',
  styleUrls: ['./tab-header.component.css']
})
export class TabHeaderComponent implements OnInit {

  headerItem: any;
  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.commonService.getChangedItem().subscribe((item: any) => {
      this.headerItem = item;
    });
  }

  onBackClick(){
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove("display-tab-content");
  }

}
