import { ManageGiftCardBusinessComponent } from './components/business-solution/manage-gift-card-business/manage-gift-card-business.component';
import { BillInvoiceComponent } from './billing/components/bill-invoice/bill-invoice.component';
import { BillProcessComponent } from './billing/components/bill-process/bill-process.component';
import { BillSettingsComponent } from './billing/components/bill-settings/bill-settings.component';
import { BillingComponent } from './billing/components/billing/billing.component';
import { SelectPrivilegeCardComponent } from './privilege-card/components/select-privilege-card/select-privilege-card.component';
import { AvailTransactionHistoryComponent } from './privilege-card/components/avail-process/avail-transaction-history/avail-transaction-history.component';
import { PrivilegeCardComponent } from './privilege-card/components/privilege-card/privilege-card.component';
import { JsonViewerComponent } from './components/json-viewer/json-viewer.component';
import { UserOfferReportComponent } from './components/user-offer-report/user-offer-report.component';
import { SendOfferPreviewComponent } from './components/send-offer-preview/send-offer-preview.component';
import { OfferModalComponent } from './components/offer-modal/offer-modal.component';
import { GiftWithOfferComponent } from './components/gift-with-offer/gift-with-offer.component';
import { RouterModule, Routes } from "@angular/router";

import { MerchantsComponent } from "./components/merchants/merchants.component";
import { OfferProvidersComponent } from "./components/offer-providers/offer-providers.component";
import { BillingsComponent } from "./components/billings/billings.component";
import { PaymentComponent } from "./components/payments/payment.component";
import { OffersComponent } from "./components/offers/offers.component";
import { AuthGuard } from "./auth/auth-guard";
import { LoginComponent } from "./components/login/login.component";
import { LoginwithphoneComponent } from "./components/loginwithphone/loginwithphone.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { NotificationsComponent } from "./components/notifications/notifications.component";
import { SettingsComponent } from "./components/settings/settings.component";
import { BundleOffersComponent } from "./components/bundle-offers/bundle-offers.component";
import { AppPaymentOptionComponent } from "./components/app-payment-option/app-payment-option.component";
import { WebAppLayoutComponent } from "./_layout/web-app-layout/web-app-layout.component";
import { ConsumerPaymentStatusComponent } from "./components/consumer-payment-status/consumer-payment-status.component";
import { PaymentLayoutComponent } from "./_layout/payment-layout/payment-layout.component";
import { PendingAvailRequestComponent } from "./components/pending-avail-request/pending-avail-request.component";
import { AvailProcessComponent } from "./components/avail-process/avail-process.component";
import { DataHistoryComponent } from "./components/data-history/data-history.component";
import { PaymentReportComponent } from "./components/payment-report/payment-report.component";
import { UserRegistrationReportComponent } from "./components/user-registration-report/user-registration-report.component";
import { GolpAdminComponent } from "./components/golp-admin/golp-admin.component";
import { ReportsComponent } from "./components/reports/reports.component";
import { ConsumergiftComponent } from "./components/consumergift/consumergift.component";
import { ConsumerSendGiftComponent } from "./components/consumer-send-gift/consumer-send-gift.component";
import { PaymentMethodComponent } from "./components/payment-method/payment-method.component";

//import { ConsumergiftComponent } from './components/consumergift';
import { PremiumUsersComponent } from "./components/premium-users/premium-users.component";
import { GolpProductsComponent } from "./components/golp-products/golp-products.component";
import { TrendingMerchantsComponent } from "./components/trending-merchants/trending-merchants.component";
import { CustomerSupportBaseComponent } from "./components/customer-support-base/customer-support-base.component";
import { PromotionsComponent } from "./components/promotions/promotions.component";
import { GiftTemplatesComponent } from "./components/gift-templates/gift-templates.component";
import { GiftTemplateBaseComponent } from "./components/gift-template-base/gift-template-base.component";
import { LoyaltyMerchantsComponent } from "./components/loyalty-merchants/loyalty-merchants.component";
import { NgModule } from '@angular/core';
import { MyOffersComponent } from './corporate-offers/components/my-offers/my-offers.component';
import { CorporateOffersComponent } from './corporate-offers/components/corporate-offers/corporate-offers.component';
import { OfferReportsComponent } from './corporate-offers/components';
import { GiftInformationComponent } from "./components/app-send-gift/components/gift-information";
import { GiftConfirmationComponent } from "./components/app-send-gift/components/gift-confirmation";
import { GiftSentHistoryComponent } from "./components/app-send-gift/components/gift-sent-history/gift-sent-history.component";
import { GiftDetailsComponent } from "./components/app-send-gift/components/shared/gift-details/gift-details.component";
import { GiftDeliveredComponent } from "./components/app-send-gift/components/shared/gift-delivered/gift-delivered.component";
import { GiftDashboardComponent } from "./components/app-send-gift/components/gift-dashboard/gift-dashboard.component";
import { MerchantProductsComponent } from './components/merchant-products/merchant-products.component';
import { SendGiftReportComponent } from "./components/app-send-gift/components/send-gift-report/send-gift-report.component";
import { ManageOrdersComponent } from './reseller-partners/components/manage-orders/manage-orders.component';
import { GiftSellReportComponent } from "./components/app-send-gift/components/gift-sell-report/gift-sell-report.component";
import { GiftSellReportSummaryComponent } from "./components/app-send-gift/components/gift-sell-report-summary/gift-sell-report-summary.component";
import { GiftSummaryReportComponent } from "./components/app-send-gift/components/gift-summary-report/gift-summary-report.component";
import { GiftVsSalesReportComponent } from "./components/app-send-gift/components/gift-vs-sales-report/gift-vs-sales-report.component";
import { GiftVsRedemptionReportComponent } from "./components/app-send-gift/components/gift-vs-redemption-report/gift-vs-redemption-report.component";
import { GiftExpiryReportComponent } from "./components/app-send-gift/components/gift-expiry-report/gift-expiry-report.component";
import { UploadAmountResultComponent } from "./components/app-send-gift/components/upload-amount-result/upload-amount-result.component";
import { UploadAmountReportComponent } from "./components/app-send-gift/components/upload-amount-report/upload-amount-report.component";
import { UploadedXlsPreviewComponent } from "./components/app-send-gift/components/uploaded-xls-preview/uploaded-xls-preview.component";
import { SmsStatusComponent } from "./components/app-send-gift/components/sms-status/sms-status.component";
import { StockStatusComponent } from "./components/stock-status/stock-status.component";
import { BusinessBulkGiftComponent } from "./components/business-bulk-gift/business-bulk-gift.component";
import { GiftCustomerSupportComponent } from "./components/gift-customer-support/gift-customer-support.component";
import { CustomerSupportPageComponent } from "./components/customer-support-page/customer-support-page.component";
import { GiftWithOfferByCountComponent } from './components/gift-with-offer-by-count/gift-with-offer-by-count.component';

import { UserPrivilegeCardComponent } from './privilege-card/components/user/user-privilege-card/user-privilege-card.component';
import { UserPrivilegeCardFacilitiesComponent } from './privilege-card/components/user/user-privilege-card-facilities/user-privilege-card-facilities.component';
import { AvailCardComponent } from './privilege-card/components/avail-process/avail-card/avail-card.component';
import { ManageStakeholdersComponent } from './privilege-card/components/manage-stakeholders/manage-stakeholders/manage-stakeholders.component';
import { RedeemedTransactionComponent } from './components/avail-process/redeemed-transaction/redeemed-transaction.component';
import { ManageGiftOrderComponent } from './components/customer-support-page/manage-gift-order/manage-gift-order.component';
import { WalletTransactionComponent } from './components/wallet-transaction/wallet-transaction.component';
import { GiftCardTransactionComponent } from './components/app-send-gift/components/gift-card-transaction/gift-card-transaction.component';
import { RevenueDashboardComponent } from './revenue-dashboard/components/dashboard/revenue-dashboard.component';

const routes: Routes = [
  {
    path: '',
    component: WebAppLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: '/gifts',
        pathMatch: 'full',
        canActivate: [AuthGuard],
      },
      { path: 'login', component: LoginComponent },
      { path: 'loginwithphone', component: LoginwithphoneComponent },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: { subscriptionRequired: ['giftCard'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'merchants',
        component: MerchantsComponent,
        data: {
          authorites: ['MerchantRead'],
          unauthorizedRole: ['ServiceConsumer'],
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'gift-senders',
        component: OfferProvidersComponent,
        data: { authorites: ['ProviderRead'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'billings',
        component: BillingsComponent,
        data: { authorites: ['ReadInvoice'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'payment-due-report',
        component: PaymentReportComponent,
        data: { authorites: ['ReadPaymentDueReport'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'gifts',
        component: OffersComponent,
        data: {
          authorites: ['OfferRead'],
          unauthorizedRole: ['ServiceConsumer'],
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'golp-products',
        component: GolpProductsComponent,
        data: { authorites: ['SystemAdmin'] },
        canActivate: [AuthGuard],
      },
      //path for privilege card
      {
        path: 'privilege-card',
        component: PrivilegeCardComponent,
        canActivate: [AuthGuard]
      },

      {
        path: 'privilege-card/show-card',
        component: PrivilegeCardComponent,
        canActivate: [AuthGuard]
      },

      {
        path: 'privilege-card/my-privilege-card',
        component: UserPrivilegeCardComponent,
        canActivate: [AuthGuard]
      },

      {
        path: 'privilege-card/card-facilities',
        component: UserPrivilegeCardFacilitiesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'privilege-card/avail-card',
        component: AvailCardComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'privilege-card/card-transaction',
        component: AvailTransactionHistoryComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'privilege-card/select-privilege-card',
        component: SelectPrivilegeCardComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'manage-stakeholders',
        component: ManageStakeholdersComponent,
        canActivate: [AuthGuard]
      },
      // bill setting 

      {
        path: 'bill-settings',
        component: BillSettingsComponent,
        canActivate: [AuthGuard]
      },

      {
        path: 'bill-process',
        component: BillProcessComponent,
        canActivate: [AuthGuard]
      },

      {
        path: 'bill-invoice',
        component: BillInvoiceComponent,
        canActivate: [AuthGuard]
      },
      //{ path: 'bundle-gifts', component: BundleOffersComponent, data: { authorites: ['OfferRead'] }, canActivate: [AuthGuard] },
      {
        path: 'corporate-offers',
        component: CorporateOffersComponent,
        data: {
          authorites: ['ViewCorporateOffer'],
          subscriptionRequired: ['offerCard'],
        },
        canActivate: [AuthGuard],
      },

      {
        path: 'gift-offer',
        component: GiftWithOfferComponent,
        data: {
          authorites: ['ViewCorporateOffer'],
          subscriptionRequired: ['offerCard'],
        },
        canActivate: [AuthGuard],
      },

      {
        path: 'sent-offer-report',
        component: UserOfferReportComponent,
        data: {
          authorites: ['ViewCorporateOffer'],
          subscriptionRequired: ['offerCard'],
        },
        canActivate: [AuthGuard],
      }
      ,

      {
        path: 'send-offer-preview',
        component: SendOfferPreviewComponent,
        data: {
          authorites: ['ViewCorporateOffer'],
          subscriptionRequired: ['offerCard'],
        },
        canActivate: [AuthGuard],
      },

      {
        path: 'gift-offer-by-count',
        component: GiftWithOfferByCountComponent,
        data: {
          authorites: ['ViewCorporateOffer'],
          subscriptionRequired: ['offerCard'],
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'offer-reports',
        component: OfferReportsComponent,
        data: {
          authorites: ['ViewCorporateReport'],
          subscriptionRequired: ['offerCard'],
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'notifications',
        component: NotificationsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'payments/:status/transaction/:tranId',
        component: PaymentComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'golp-admin',
        component: GolpAdminComponent,
        data: { authorites: ['SystemAdmin'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'json-viewer',
        component: JsonViewerComponent,
        data: { authorites: ['SystemAdmin'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'premium-users',
        component: PremiumUsersComponent,
        data: { authorites: ['SystemAdmin'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'reports',
        component: ReportsComponent,
        data: {
          authorites: [
            'SystemAdmin',
            'ViewReports',
            'UserRegistrationReport',
            'ReadPaymentDueReport',
          ],
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'pending-avail-request',
        component: PendingAvailRequestComponent,
        data: { authorites: ['OfferAvailAccept'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'avail-process',
        component: AvailProcessComponent,
        data: { authorites: ['OfferAvailAccept', 'MerchantRead'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'redemption-success/:transactionId',
        component: RedeemedTransactionComponent,
        data: { authorites: ['OfferAvailAccept', 'MerchantRead'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'merchant-products',
        component: MerchantProductsComponent,
        data: { authorites: ['MerchantRead'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'data-history',
        component: DataHistoryComponent,
        data: { authorites: ['SystemAdmin'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'user-registration-report',
        component: UserRegistrationReportComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'receive-gifts',
        component: ConsumergiftComponent,
        data: { authorites: ['ConsumerRead'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'send-gifts',
        component: ConsumerSendGiftComponent,
        data: { authorites: ['ConsumerRead'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'my-offers',
        component: MyOffersComponent,
        data: { authorites: ['ConsumerRead'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'trending-merchants',
        component: TrendingMerchantsComponent,
        data: { authorites: ['TrendingMerchantRead'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'customer-supports',
        component: CustomerSupportPageComponent,
        data: { authorites: ['ViewCustomerSupport'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'manage-gift-orders',
        component: ManageGiftOrderComponent,
        data: { authorites: ['ViewCustomerSupport'] },
        canActivate: [AuthGuard],
      },

      {
        path: 'manage-gift-card-orders',
        component: ManageGiftCardBusinessComponent,
        data: { authorites: ['ViewGiftCardReport'] },
        canActivate: [AuthGuard],
      },

      // {
      //   path: 'customer-supports1',
      //   component: CustomerSupportPageComponent,
      //   data: { authorites: ['ViewCustomerSupport'] },
      //   canActivate: [AuthGuard],
      // },

      {
        path: 'promotions',
        component: PromotionsComponent,
        data: { authorites: ['SystemAdmin'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'gift-templates',
        component: GiftTemplateBaseComponent,
        data: {
          authorites: ['ReadGiftTemplate', 'ViewGiftTemplateAssignments'],
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'loyalty-merchants',
        component: LoyaltyMerchantsComponent,
        data: { authorites: ['SystemAdmin'] },
        canActivate: [AuthGuard],
      },

      {
        path: 'send-gift-information',
        component: GiftInformationComponent,
        data: {
          authorites: ['IssueGiftCard'],
          subscriptionRequired: ['giftCard'],
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'send-gift-confirmation',
        component: GiftConfirmationComponent,
        data: {
          authorites: ['IssueGiftCard'],
          subscriptionRequired: ['giftCard'],
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'gift-sent-history',
        component: GiftSentHistoryComponent,
        data: {
          authorites: ['ViewGiftCard'],
          subscriptionRequired: ['giftCard'],
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'gift-details',
        component: GiftDetailsComponent,
        data: {
          authorites: ['ViewGiftCard'],
          subscriptionRequired: ['giftCard'],
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'send-gift-delivered/:merchantId/:orderId',
        component: GiftDeliveredComponent,
        data: {
          authorites: ['ViewGiftCard'],
          subscriptionRequired: ['giftCard'],
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'send-gift-dashboard',
        component: GiftDashboardComponent,
        data: {
          authorites: ['ViewGiftCard'],
          subscriptionRequired: ['giftCard'],
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'sent-gift-report',
        component: SendGiftReportComponent,
        data: { authorites: ['ViewGiftCardReport'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'manage-orders',
        component: ManageOrdersComponent,
        data: { authorites: ['ViewOrder'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'gift-sell-report',
        component: GiftCardTransactionComponent,
        data: { authorites: ['ViewGiftCardReport'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'gift-sell-report-summary',
        component: GiftSellReportSummaryComponent,
        data: { authorites: ['ViewGiftCardReport'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'gift-summary-report',
        component: GiftSummaryReportComponent,
        data: { authorites: ['ViewGiftCardReport'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'gift-vs-sales-amount-report',
        component: GiftVsSalesReportComponent,
        data: { authorites: ['ViewGiftCardReport'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'gift-vs-redeemption-report',
        component: GiftVsRedemptionReportComponent,
        data: { authorites: ['ViewGiftCardReport'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'gift-expiry-report',
        component: GiftExpiryReportComponent,
        data: { authorites: ['ViewGiftCardReport'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'upload-amount-result',
        component: UploadAmountResultComponent,
        data: { authorites: ['ViewGiftCardReport'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'upload-xl-preview',
        component: UploadedXlsPreviewComponent,
        data: { authorites: ['ViewGiftCardReport'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'upload-amount-report/merchant/:merchant',
        component: UploadAmountReportComponent,
        data: { authorites: ['ViewGiftCardReport'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'sms-status',
        component: SmsStatusComponent,
        data: { authorites: ['ViewGiftCardReport'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'vouchers',
        component: StockStatusComponent,
        data: { authorites: ['ViewGiftCardReport'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'bulk-gift',
        component: BusinessBulkGiftComponent,
        data: { authorites: ['ViewGiftCardReport'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'offer-modal',
        component: OfferModalComponent
      },
      {
        path: 'wallet',
        component: WalletTransactionComponent,
        data: { authorites: ['ProviderRead'] },
        canActivate: [AuthGuard],
      },
      {
        path: 'revenue-dashboard',
        component: RevenueDashboardComponent,
        canActivate: [AuthGuard]
      },
    ],
  },
  {
    path: '',
    component: PaymentLayoutComponent,
    children: [
      {
        path: 'payment-option/consumer/:id/offer/:offerId/assignment/:assignmentId/amount/:amount/token-1/:token1/token-2/:token2',
        component: AppPaymentOptionComponent,
      },
      //{ path: 'payment-method/gift-type/:giftType/gift/:giftId/assignment/:assignmentId/amount/:amount/token-1/:token1/token-2/:token2', component: PaymentMethodComponent },
      {
        //path:"payment-method/session-type/:sessionType/gift/:giftId/assignment/:assignmentId/amount/:amount/resendRequest/:resendRequestId/token-1/:token1/token-2/:token2",
        path: "payment-method/session-type/:sessionType/order/:order/amount/:amount/resendRequest/:resendRequestId/token-1/:token1/token-2/:token2",
        component: PaymentMethodComponent,
      },
      {
        path: 'consumer-payment/:status/:errorCode/:error',
        component: ConsumerPaymentStatusComponent,
      },
      {
        path: 'consumer-payment/:status',
        component: ConsumerPaymentStatusComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
