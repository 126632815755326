import { Component, Injector, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/components/base/base.component';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { CompanyRole } from 'src/app/enums';
import { OfferAvailMethod } from 'src/app/models/offer-avail-method';
import { CompanyService, AlertService } from 'src/app/services';
import { SharedService } from 'src/app/services/shared.service';
import { CorporateOffer } from '../../models/corporate-offer';
import { CorporateOfferService } from '../../services/corporate-offer.service';
import { CreateCorporateOfferConditionComponent } from '../create-corporate-offer-condition/create-corporate-offer-condition.component';
import { CreateOfferOperationalConditionComponent } from '../create-offer-operational-condition/create-offer-operational-condition.component';

@Component({
  selector: 'app-corporate-offer-conditions',
  templateUrl: './corporate-offer-conditions.component.html',
  styleUrls: ['./corporate-offer-conditions.component.css']
})


export class CorporateOfferConditionsComponent extends BaseComponent implements OnInit {

  @Input() offerTemplateInput: any;

  corporateOfferConditions: any[] = [];
  informationalCondition: any;
  operationalCondition: any[];

  corporateOfferTypes: { text: string; value: number; }[];
  constructor(private corporateOfferService: CorporateOfferService, private dialog: MatDialog,
    private sharedService: SharedService, private companyService: CompanyService,
    private alertService: AlertService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
  }

  ngOnChanges() {
    //this.getConditionsByOffer();
  }


  getConditionsByOffer(offerTemplateId: any) {
    //var offerId = this.offerTemplateInput ? this.offerTemplateInput.id : null;
    this.corporateOfferService.getOfferConditionByOfferId(offerTemplateId)
      .subscribe(data => {
        this.corporateOfferConditions = data;
        if (this.corporateOfferConditions != null) {
          this.informationalCondition = this.corporateOfferConditions.filter(c => c.conditionType == "Informational")[0];
          this.operationalCondition = this.corporateOfferConditions.filter(c => c.conditionType == "Operational")
        }
      });
  }


  addCorporateOfferCondition(model: any) {
    const corporateOfferDialogConfig = new MatDialogConfig();
    var companyId = model && model.id ? model.id : undefined;
    // CorporateOffer different dialog configurations
    corporateOfferDialogConfig.disableClose = true;
    corporateOfferDialogConfig.autoFocus = true;
    corporateOfferDialogConfig.data = { offerConditions: this.corporateOfferConditions, offerTemplateId: this.offerTemplateInput.id };
    corporateOfferDialogConfig.panelClass = 'golp-dialog';


    const corporateOfferCreateDialog = this.dialog.open(CreateCorporateOfferConditionComponent, corporateOfferDialogConfig);

    const successfullCreate = corporateOfferCreateDialog.componentInstance.onCorporateOfferConditionCreateEvent.subscribe((data) => {
      this.getConditionsByOffer(data);
    });

    corporateOfferCreateDialog.afterClosed().subscribe(() => {
    });

  }

  editCorporateOfferCondition(model: CorporateOffer) {

    const editDialogConfig = new MatDialogConfig();
    // CorporateOffer different dialog configurations
    editDialogConfig.disableClose = true;
    editDialogConfig.autoFocus = true;
    editDialogConfig.data = model;
    editDialogConfig.panelClass = 'golp-dialog';

    const corporateOfferEditDialog = this.dialog.open(CreateCorporateOfferConditionComponent, editDialogConfig);

    const successfullEdit = corporateOfferEditDialog.componentInstance.onCorporateOfferConditionEditEvent.subscribe((data) => {
      this.getConditionsByOffer(data);
    });

    corporateOfferEditDialog.afterClosed().subscribe(() => {
    });
  }

  addOperationalCondition(template: any) {
    const corporateOfferDialogConfig = new MatDialogConfig();
    var companyId = template && template.id ? template.id : undefined;
    // CorporateOffer different dialog configurations
    corporateOfferDialogConfig.disableClose = true;
    corporateOfferDialogConfig.autoFocus = true;
    corporateOfferDialogConfig.data = { offerConditions: this.corporateOfferConditions, offerTemplateId: this.offerTemplateInput.id };
    corporateOfferDialogConfig.panelClass = 'golp-dialog';


    const corporateOfferCreateDialog = this.dialog.open(CreateOfferOperationalConditionComponent, corporateOfferDialogConfig);

    const successfullCreate = corporateOfferCreateDialog.componentInstance.operationalInformationConditionEmitter.subscribe((data) => {
      this.getConditionsByOffer(data);
    });

    corporateOfferCreateDialog.afterClosed().subscribe(() => {
    });
  }

  editOperationalCondition(condition: any) {
    const corporateOfferDialogConfig = new MatDialogConfig();
    // CorporateOffer different dialog configurations
    corporateOfferDialogConfig.disableClose = true;
    corporateOfferDialogConfig.autoFocus = true;
    corporateOfferDialogConfig.data = {
      offerConditions: this.corporateOfferConditions,
      offerTemplateId: this.offerTemplateInput.id,
      condition: condition
    };
    corporateOfferDialogConfig.panelClass = 'golp-dialog';

    const corporateOfferCreateDialog = this.dialog.open(CreateOfferOperationalConditionComponent, corporateOfferDialogConfig);

    const successfullCreate = corporateOfferCreateDialog.componentInstance.operationalInformationConditionEmitter.subscribe((data) => {
      this.getConditionsByOffer(data);
    });

    corporateOfferCreateDialog.afterClosed().subscribe(() => {
    });
  }

  onDeleteOfferCondition(item: any): void {
    const confirmationDialogConfig = new MatDialogConfig();

    // CorporateOffer different dialog configurations
    //confirmationDialogConfig.data = "Are you sure to delete this corporateOffer " + model.key;
    confirmationDialogConfig.data = "Are you sure to delete this condition?";
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result: any) => {
      if (result === "yes") {
        this.deleteOfferCondition(item);
      }
    });
  }

  deleteOfferCondition(item: any) {
    this.corporateOfferService.deleteOfferCondition(item.offerConditionId).subscribe((data) => {
      this.alertService.success('Condition deleted successfully');
      this.getConditionsByOffer(item.corporateOfferId);
    },
      (error) => {
        console.log(error);
      });
  }

}

