import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/base/base.component';
import { CompanyRole } from 'src/app/enums';
import { AccountServiceService } from 'src/app/services';


@Component({
  selector: 'app-offer-reports',
  templateUrl: './offer-reports.component.html',
  styleUrls: ['./offer-reports.component.css']
})
export class OfferReportsComponent extends BaseComponent implements OnInit {

  offerReportsSubMenu: any[] = [];
  selectedSubMenuIndex: number = 0;
  activeSubMenu: string = "Offer Transactions";
  isMerchantLoggedIn: boolean = false;

  constructor(private injector: Injector, private accountService: AccountServiceService) {
    super(injector);
    let userInfo = this.accountService.getLoggedInUserInfo();

    this.isMerchantLoggedIn = CompanyRole.Merchant === userInfo.companyRole && !userInfo.isSystemAdmin;

    if (this.checkPermission(["ViewOfferSummary"])) {
      this.offerReportsSubMenu.push({ "name": "Offer Transactions", "dataTarget": "offerTransactionsTab" });
      this.offerReportsSubMenu.push({ "name": "Offer Summary", "dataTarget": "offerSummaryTab" });
    }
    if (this.checkPermission(["SearchCustomerOffer"])) {
      this.offerReportsSubMenu.push({ "name": "Search Customer", "dataTarget": "searchCustomerTab" });
      if (this.offerReportsSubMenu.length == 1) {
        this.activeSubMenu = "Search Customer";
      }
    }
  }

  ngOnInit(): void {
  }

  onSubMenuChange(name, index) {
    if (this.selectedSubMenuIndex != index) {
      this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
      this.activeSubMenu = this.offerReportsSubMenu.filter(c => c.name === name)[0].name;
    }
  }

}
