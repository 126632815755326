import { LoginApp } from "../enums/login-app";
import { LoginMethod } from "../enums/LoginMethod";
export class LoginModel {
  userName: string;
  password: string;
  loginApp: LoginApp;
  phoneNumber: string;
  code: string;
  loginMethod: LoginMethod

  public constructor(username: string, password: string) {
    this.userName = username;
    this.password = password;
  }
}
