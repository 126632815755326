export class GiftTemplateInputDto {
  id: any;
  name: string;
  amount: number;
  merchantId?: number;
  totalAssociations: number;
  expirationMonthType: any;

  public constructor(init?: Partial<GiftTemplateInputDto>) {
    Object.assign(this, init);
  }
}
