
export class MerchantAccountInfoSetting {
    payBy: number;
    bkashAccount: bKashAccountInfo = new bKashAccountInfo();
    bankAccount: BankAccountInfo = new BankAccountInfo();
    public constructor(init?: Partial<MerchantAccountInfoSetting>) {
        Object.assign(this, init);
    }
}

export class bKashAccountInfo {
    bKashNumber: number;
    bKashAccountType: number;
    public constructor(init?: Partial<bKashAccountInfo>) {
        Object.assign(this, init);
    }
}


export class BankAccountInfo {

    bankName: string = "";
    branchName: string = "";
    routingNumber: number;
    accountName: string = "";
    accountNumber: number;
    public constructor(init?: Partial<BankAccountInfo>) {
        Object.assign(this, init);
    }
}
