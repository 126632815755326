import * as moment from 'moment';


export class CreditUsageReportInputDto {
    //from: string;
    //to: string;
    //type: any;
    pageNumber: number;
    pageSize: number;

    public constructor(init?: Partial<CreditUsageReportInputDto>) {
        Object.assign(this, init);
    }
}
