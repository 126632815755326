import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as moment from 'moment';
import { Select2OptionData } from 'ng-select2';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService, OfferAssignmentService } from 'src/app/services';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import { DataService } from '../../app-send-gift/services/shared/data.service';
import { saveAs } from 'file-saver';
import { DeliverGiftOrderComponent } from '../deliver-gift-order/deliver-gift-order.component';
import { ConstantText } from 'src/app/constants/constant.text';

@Component({
  selector: 'app-manage-gift-order',
  templateUrl: './manage-gift-order.component.html',
  styleUrls: ['./manage-gift-order.component.css']
})
export class ManageGiftOrderComponent implements OnInit {

  giftSearchForm: FormGroup;

  currentPage: number = 1;
  pageSize: number = 10;
  expandedIndex: number = -1;
  totalItems: number = 0;
  count = 0;
  pageSizes = [10, 25, 50, 100];

  paginationInfo: string = '';

  defaultStartDate: Date = new Date();
  defaultEndDate: Date = new Date();
  paginationPageNumbers: any[];
  currentPageNumberDetails: string;
  submitted: boolean;
  companyOutletId: any;

  userInfo: any;
  reportSummary: any[];

  soldGift: number = 0;
  soldGiftAmount: number = 0;
  redeemedGift: number = 0;
  redeemedGiftAmount: number = 0;
  p: number = 1;
  currentRemarks: string;
  merchantList: any;

  select2MerchantInfo: Array<Select2OptionData>;
  giftResponse: any;

  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private dialog: MatDialog,
    private offerAssignmentService: OfferAssignmentService,
    private alertService: AlertService
  ) {
    // var startDate1 = new Date();
    //startDate1.setDate(startDate1.getDate() - 7);
    // this.defaultStartDate = this.startOfMonth(startDate1);
    // this.defaultEndDate = new Date();
    this.buildForm();
    //this.dateInput.focus();
  }

  ngOnInit(): void {
    this.loadMerchant();
    this.userInfo = JSON.parse(localStorage.getItem('userInfo') ?? "{}");
    // this.getOfferReportData();
  }

  buildForm() {
    this.giftSearchForm = this.formBuilder.group({
      merchantId: '',
      fromDate: '',
      toDate: ''
    });
  }

  loadMerchant() {
    let url = 'api/company/merchant/basics';
    this.dataService.get(url).subscribe(
      (data: any) => {
        this.merchantList = data;
        this.select2MerchantInfo = this.merchantList.map((p) => {
          return {
            id: p.id,
            text: p.name,
          };
        });
        let noMerchant: Select2OptionData = {
          id: "0",
          text: "Select Merchant",
        };
        this.select2MerchantInfo.unshift(noMerchant);
      },
      catchError(() => {
        return throwError('Something went wrong!');
      })
    );
  }

  exportPendingGiftCards() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.giftSearchForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    this.submitted = true;
    // stop here if form is invalid
    if (this.giftSearchForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    let fromDate = this.f.fromDate.value;
    let toDate = this.f.toDate.value;
    let merchantId = this.f.merchantId.value;

    let url = `api/contact-support/export-pending-gifts`;

    let payload = {
      "fromDate": fromDate,
      "toDate": toDate,
      "merchantId": merchantId
    }

    this.dataService.postWithOptions(url, payload, {
      responseType: 'blob'
    })
      .subscribe((response: any) => {
        this.downloadFile(response);
      }, () => { console.log("Error Occurred") }, () => { console.log("Download Finished") });
  }

  downloadFile(data) {
    const blob2 = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var filename = "pending-gift-cards-" + moment().format('MMMM Do YYYY, h:mm:ss').valueOf() + ".xlsx";
    saveAs(blob2, filename);
  }

  startOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  getGiftCardOrders(submitted: boolean = false) {
    this.submitted = submitted;
    // stop here if form is invalid
    if (this.giftSearchForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    let fromDate = this.f.fromDate.value;
    let toDate = this.f.toDate.value;
    let merchantId = this.f.merchantId.value;

    let url = `api/contact-support/pending-gift-cards?pageSize=${this.pageSize}&pageNumber=${this.p}&`;
    url = (fromDate != null && fromDate != '') ? `${url}fromDate=${moment(this.f.fromDate.value).format('YYYY/MM/DD')}&` : url;
    url = (toDate != null && toDate != '') ? `${url}toDate=${moment(this.f.toDate.value).format('YYYY/MM/DD')}&` : url;
    url = (merchantId != null && merchantId > 0) ? `${url}merchantId=${merchantId}` : url;

    this.dataService.get(url).subscribe((response: any) => {
      if (submitted) this.p = 1;
      this.giftResponse = response.data?.pendingGiftCards;
      this.count = response.data?.count;
      this.getCurrentRange();
    });
  }

  deliverGiftCard() {
    //if (item.merchantId == null) return;
    const confirmationDialogConfig = new MatDialogConfig();
    var text = 'Are you sure to process the gift orders?';
    let obj = {
      confirmationMessage: text,
      title: 'Gift Delivery Information',
      merchantList: this.select2MerchantInfo,
      notifyCheckboxShow: true
    };
    confirmationDialogConfig.data = obj;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(
      DeliverGiftOrderComponent,
      confirmationDialogConfig
    );

    confirmationDialog.afterClosed().subscribe((result: FormData) => {
      if (result != undefined) {
        let url = 'api/contact-support/deliver-pending-gifts';
        //remote call 
        this.dataService.postBolb(url, result).subscribe((res: any) => {
          if (res.status == "200") {
            this.alertService.success("Gift card delivered successfully.")
          }
          else {
            this.alertService.error('Fail to deliver the gift card.');
          }
        }, (error: any) => {
          this.alertService.error('Fail to deliver the gift card.');
        });
      }
    });
  }

  uploadGiftVoucher() {
    const confirmationDialogConfig = new MatDialogConfig();
    var text = ConstantText.VoucharCodeUploadConfirmationText;
    let obj = {
      confirmationMessage: text,
      title: ConstantText.UploadGiftVoucherModalTitle,
      notifyCheckboxShow: false,
      merchantList: this.select2MerchantInfo,
      showValidityAndEffictiveDateRow: true
    };
    confirmationDialogConfig.data = obj;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(
      DeliverGiftOrderComponent,
      confirmationDialogConfig
    );
    confirmationDialog.afterClosed().subscribe((result: FormData) => {
      if (result != undefined) {
        let url = ConstantText.VoucharCodeUploadApi;
        this.dataService.postBolb(url, result).subscribe((res: any) => {
          if (res.status == "200") {
            this.alertService.success(ConstantText.VoucharCodeUploadSuccessMessage);
          }
          else {
            this.alertService.error(ConstantText.VoucharCodeUploadFailedMessage);
          }
        }, (error: any) => {
          this.alertService.error(ConstantText.VoucharCodeUploadFailedMessage);
        });
      }
    });
  }


  get f() {
    return this.giftSearchForm.controls;
  }

  handlePageChange(event: number): void {
    this.p = event;
    this.getGiftCardOrders();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.p = 1;
    this.getGiftCardOrders();
  }

  getCurrentRange() {
    this.paginationInfo = `Showing ${(this.p - 1) * this.pageSize + 1}-${(this.p - 1) * this.pageSize + this.giftResponse.length} of ${this.count}`;
  }
}

// function loadJquery() {
//   $(document).ready(function () {
//     // $("th.d-lg-table-cell").addClass('clone');
//     var cells = $('.redesign');

//     for (var headCell = 0; headCell < cells.length;) {
//       $(cells[headCell++]).addClass('d-sticky');
//       $(cells[headCell++]).addClass('d-sticky2');
//       $(cells[headCell++]).addClass('d-sticky3');
//       $(cells[headCell++]).addClass('d-sticky4');
//     }
//   });
// }