import { DataService } from './../../../../components/app-send-gift/services/shared/data.service';
import { Component, EventEmitter, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CorporateOfferService } from 'src/app/corporate-offers/services/corporate-offer.service';
import { AlertService } from 'src/app/services';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import { CreateCardInformationalConditionComponent } from '../create-card-informational-condition/create-card-informational-condition.component';

@Component({
  selector: 'app-card-information-condition',
  templateUrl: './card-information-condition.component.html',
  styleUrls: ['./card-information-condition.component.css']
})
export class CardInformationConditionComponent implements OnInit {

  // view childs
  @ViewChild(CreateCardInformationalConditionComponent) private informationalComponent: CreateCardInformationalConditionComponent;

  // onCreate event
  onCorporateOfferConditionCreateEvent: EventEmitter<any> = new EventEmitter();
  // onEdit event;
  onCorporateOfferConditionEditEvent: EventEmitter<any> = new EventEmitter();

  privilegeCardCondition: any[];

  informationalConditions: any[] = [];
  informationalCondition: any;
  privilegeCardId: any;
  offerConditionId: any;
  merchantId:number;
  offerConditionForm: FormGroup
  submitted = false;

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateCardInformationalConditionComponent>,
    private alertService: AlertService,
    private dataService:DataService,
    @Inject(MAT_DIALOG_DATA) data) {

    if (isNaN(data) && data != null) { // coming from offerCondition list page
      if(data?.informationalCondition !== undefined && data?.informationalCondition !== null && data?.informationalCondition.length > 0){
      this.informationalCondition = data?.informationalCondition[0];
      this.informationalConditions = this.informationalCondition.getTerms;
      }
      this.privilegeCardId = data?.privilegeCardId;
      this.merchantId = data?.merchantId;
      this.offerConditionId = this.informationalCondition != undefined ? this.informationalCondition.offerConditionId : undefined;
    }
    else { // coming from merchant page

    }
    this.buildForm();
  }

  ngOnInit() {
  }

  buildForm() {

  }


  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    // if (this.offerConditionForm.invalid) {
    //   ValidationUtility.getError_Scroll(document);
    //   return;
    // }

    if (this.informationalCondition === undefined) {
      this.createofferCondition();
    }
    else if (this.informationalCondition !== undefined && this.informationalCondition !== null && this.informationalCondition.id > 0) {
      this.editofferCondition();
    }
  };

  createofferCondition() {
    let condition = this.informationalComponent.setKeyValuesJsonAndEmit();
    let url = "api/cards/add-terms-conditions";
    let payload = {
      "golpCardId": this.privilegeCardId,
      "merchantId": this.merchantId,
      "entryType": "Terms",
      "terms":condition,
      "ConditionDetails":""
    }
    this.dataService.post(url, payload)
      .subscribe((response: any) => {
        console.log(response);
        this.dialogRef.close();
        this.onCorporateOfferConditionCreateEvent.emit(this.privilegeCardId);
      });
  }

  editofferCondition() {
    let condition = this.informationalComponent.setKeyValuesJsonAndEmit();
    let url = "api/cards/update-terms-conditions";
    let payload = {
      "golpCardId": this.privilegeCardId,
      "merchantId": this.merchantId,
      "entryType": "Terms",
      "terms":condition,
      "ConditionDetails":"",
      "termsConditionId": this.informationalCondition.id
    }

    this.dataService.post(url, payload)
      .subscribe((response: any) => {
        this.dialogRef.close();
        this.onCorporateOfferConditionCreateEvent.emit(this.privilegeCardId);
      });
  }

  showErrorMessage(error: any) {
    console.log(error);
    //this.alertService.error("Internal server error happen");
  }

  close() {
    this.dialogRef.close();
  }

  // convenience getter for easy access to form fields
  get f() { return this.offerConditionForm.controls; }

  setValuesByInformationalCondition($event): void {
    this.informationalConditions.push($event);
  }

  setValuesByOperationalCondition($event): void {
    // Json converted previously
    // this.availInstructions = $event;
    // this.availInstructionsSettingJsonValue = JSON.stringify($event);
    // this.companySetting.value = this.availInstructionsSettingJsonValue;
  }
}
