import { Component, OnInit, Input, Injector, SimpleChanges } from '@angular/core';
import { BaseComponent } from 'src/app/components/base/base.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OfferAssignmentService, CompanyService } from 'src/app/services';
import { ViewGiftCodeComponent } from 'src/app/components/consumergift-details/view-gift-code/view-gift-code.component';
import { ShowOutletsComponent } from 'src/app/components/show-outlets/show-outlets.component';
import { ShowAvailInstructionsComponent } from 'src/app/components/show-avail-instructions/show-avail-instructions.component';
import { CorporateOfferService } from 'src/app/corporate-offers/services/corporate-offer.service';

@Component({
  selector: 'app-my-offer-details',
  templateUrl: './my-offer-details.component.html',
  styleUrls: ['./my-offer-details.component.css']
})
export class MyOfferDetailsComponent extends BaseComponent implements OnInit {
  @Input() offerDetailsInput: any;

  offerDetails: any;
  offerAssignmentSummary: any;
  userInfo: any;
  detailsLoaded: Promise<boolean>;
  availInstructions: any[] = [];
  offerConditions: any[] = [];

  constructor(public dialog: MatDialog,
    private offerService: CorporateOfferService,
    private companyService: CompanyService, injector: Injector
  ) { super(injector) }

  ngOnInit() {
    this.getLoggedInUser();
    if (this.offerDetails != null) {
      this.detailsLoaded = Promise.resolve(true);
    }
  }

  getLoggedInUser() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userInfo = userInfo;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['offerDetailsInput']) {
      setTimeout(() => {
        this.offerDetails = this.offerDetailsInput;

        if (this.offerDetails != null && this.offerDetails.merchant != null) {
          this.getAvailInstructionsByCompany(this.offerDetails.merchantId);
          this.getDecomposedOfferConditions(this.offerDetails.offerTemplateId);
        }
        //this.showHideAcceptAction();
        this.makeSomeDelay(this.offerDetails);
      });
    }
  }

  makeSomeDelay(offer: any) {
    if (offer != null) {
      let readSomeValue = offer.offerName;
      this.detailsLoaded = Promise.resolve(true);
    }
  }

  viewOfferCode(offer: any) {
    let consumerId = this.userInfo.consumerId;
    this.offerService.viewCode(consumerId, offer.id)
      .subscribe((result: any) => {
        if (result.success) {
          this.showViewCodeDialog(result.data);
        }
      },
        (error) => {
          console.log(error);
        });
  }

  showViewCodeDialog(codeDetails: any) {

    let data = {
      'giftCode': codeDetails.offerCode,
      'giftPin': codeDetails.pin
    };

    const viewCodeDialogConfig = new MatDialogConfig();
    // Setting different dialog configurations
    viewCodeDialogConfig.data = { 'status': true, 'data': data };
    viewCodeDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ViewGiftCodeComponent, viewCodeDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
    });
  }


  showOutlets(merchantId, merchantName) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'golp-dialog';

    var merchant = { merchantId, merchantName };
    dialogConfig.data = merchant;

    const dialog = this.dialog.open(ShowOutletsComponent, dialogConfig);
  }

  showAvailInstructions(merchantId, merchantName) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'golp-dialog';
    var merchant = { merchantId, merchantName };

    dialogConfig.data = merchant;

    const dialog = this.dialog.open(ShowAvailInstructionsComponent, dialogConfig);
  }

  getDecomposedOfferConditions(offerId: any) {
    this.offerService.getDecomposedConditions(offerId)
      .subscribe((data: any) => {
        this.offerConditions = data;
      });
  }

  getAvailInstructionsByCompany(merchantId: any) {
    this.companyService.getMerchantAvailInstructionsByCompanyId(merchantId)
      .subscribe(data => {
        this.availInstructions = data.data.availInstructions;
      });
  }
}
