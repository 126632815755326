import { Component, OnInit } from "@angular/core";
import { CouponService, AlertService } from "src/app/services";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: "app-verify-coupon",
  templateUrl: "./verify-coupon.component.html",
  styleUrls: ["./verify-coupon.component.css"],
})
export class VerifyCouponComponent implements OnInit {
  userInfo: any;
  couponDetails: any;
  coupon: any;
  phone: any;
  notFoundMessage: string = "search coupon by coupon and phone number";
  noCouponFound: boolean = true;

  constructor(
    private couponService: CouponService,
    private dialog: MatDialog,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
  }

  searchCoupon() {
    this.couponDetails = {};
    this.noCouponFound = true;
    if (this.coupon == undefined || this.phone == undefined) return;
    if (this.coupon.length == 0 || this.phone.length == 0) return;
    this.getCouponDeatils(this.userInfo.companyId, this.coupon, this.phone);
  }

  getCouponDeatils(merchantId: any, coupon: any, phone: any) {
    this.couponService
      .getCouponDetails(merchantId, coupon, phone)
      .subscribe((result) => {
        if (result.success) {
          this.noCouponFound = false;
          this.couponDetails = result.data;
        } else {
          this.notFoundMessage = result.message;
        }
      });
  }

  onDeleveredGift(item: any) {
    const confirmationDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    confirmationDialogConfig.data = `Gift delivered to customer ${this.phone}. Process now.`;
    confirmationDialogConfig.panelClass = "golp-dialog";
    const confirmationDialog = this.dialog.open(
      ConfirmationDialogComponent,
      confirmationDialogConfig
    );

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.processGiftDelivery(item);
      }
    });
  }

  processGiftDelivery(couponDetails: any) {
    var requestModel = {
      merchantId: this.userInfo.companyId,
      coupon: couponDetails.giftCoupon,
      phone: couponDetails.giftReceiverPhone,
      giftPrice: couponDetails.giftAmount,
      purchaseAmount: couponDetails.giftAmount,
    };

    this.couponService.processDelivery(requestModel).subscribe(
      (data) => {
        this.noCouponFound = true;
        this.coupon = '';
        this.phone = '';
        this.alertService.success("Process done successfully");
      },
      (error) => {
        this.noCouponFound = true;
        this.notFoundMessage = error.message;
      }
    );
  }
}
