import { AccountServiceService } from './../../../services/account-service.service';
import { AlertService } from './../../../services/alert.service';
import { DataService } from './../../../components/app-send-gift/services/shared/data.service';
import { CompanyService } from './../../../services/company.service';
import { Component, OnInit } from '@angular/core';
import { Select2OptionData } from 'ng-select2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-privilege-card',
  templateUrl: './select-privilege-card.component.html',
  styleUrls: ['./select-privilege-card.component.css'],
})
export class SelectPrivilegeCardComponent implements OnInit {
  //cards
  cards: any[];
  filteredCards: any[];
  facilities: any[];
  privilegeCard: any;
  //merchant dropdown
  merchantList: any[];
  select2MerchantInfo: Array<Select2OptionData>;
  selectedStakeHolders: any[];
  constructor(
    private companyService: CompanyService,
    private dataService: DataService, private router: Router, private alertService: AlertService, private accountService: AccountServiceService
  ) {

    let navigation = this.router.getCurrentNavigation();
    let state = navigation.extras.state as {
      transId: string,
      workQueue: boolean,
      services: number,
      code: any
    };

    if (state == null || state == undefined || state.code == null || state.code == undefined || state?.code?.length <= 0) {
      this.router.navigate(['']);
    }

    let selectedUser = state.code;
    this.selectedStakeHolders = selectedUser;
  }

  ngOnInit(): void {
    // this.loadMerchant();
    this.getCards();
  }

  loadMerchant() {
    this.companyService.getAllMerchants().subscribe(
      (response) => {
        this.merchantList = response;
        this.select2MerchantInfo = this.merchantList.map((p) => {
          return {
            id: p.id,
            text: p.name,
          };
        });
        let noMerchant: Select2OptionData = {
          id: '-1',
          text: 'Select Merchant',
        };
        this.select2MerchantInfo.unshift(noMerchant);
      },
      (err) => console.log(err)
    );
  }
  loadDataByMerchant(input) { }

  getCards() {
    let url = 'api/Cards';
    this.dataService.get(url).subscribe(
      (res: any) => {
        if (res.success) {
          this.cards = res.data;
          this.filteredCards = this.cards.filter((x) => x != null);
        } else {
          console.log('Something went wrong');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  makeProperCase(txt) {
    if (txt == null || txt == undefined || txt == "") return txt;
    return txt.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  itemClicked(input) {
    this.privilegeCard = input;
    this.getCardsByTemplate(input.cardId);

  }
  getCardsByTemplate(privilegeCardId: any) {
    let url = `api/cards/card-facility/${privilegeCardId}`
    this.dataService.get(url)
      .subscribe((response: any) => {
        this.facilities = [];
        if (response.success) {
          let allCard = response.data.filter((item: any) => item.isActive);
          allCard.forEach(p => {
            let m = p;
            m["show"] = false;
            this.facilities.push(m);
          });
        }
        else {
        }

      });
  }
  toggleInfo(index) {
    this.facilities[index].show = !this.facilities[index].show;
  }

  assignCard() {
    let userInfo = this.accountService.getLoggedInUserInfo();
    let beneficiaries = this.getFormattedList();
    let payload = {
      "golpCardId": this.privilegeCard.cardId,
      "cardIssuerId": userInfo.companyId,
      "beneficiaries": beneficiaries,
    }
    let url = `api/cards/add-card-beneficiary`
    this.dataService.post(url, payload)
      .subscribe((response: any) => {
        if (response.success) {
          this.alertService.success('Card sent successfully.');
        }
        if (!response.success) {
          this.alertService.error(response.data);
        }
      },
        (error: any) => {
        });
  }

  getFormattedList() {
    let phoneObjects = [];
    this.selectedStakeHolders.forEach(m => {
      phoneObjects.push({ "id": null, "phoneNumber": m.phoneNumber, "email": m.email });
    });
    return phoneObjects;
  }

}


