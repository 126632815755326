export class OfferCodeVerificationDetails {

    offerId: string;
    offerName: string;
    offerTemplateId: string;
    offerReceiverPhone: string;
    offerCode: string;
    offerPin: string;
    offerValueType: string;
    offerAmount: string;
    merchant: string;
    offerStatus: string;
    offerConditions: any;
    purchaseAmount: number;
    merchantInvoiceNumber: string;
    invoiceDetails:string;
    outletId: string;
    offerValue:number;
    public constructor(init?: Partial<OfferCodeVerificationDetails>) {
        Object.assign(this, init);
    }
}
