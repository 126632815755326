import { Component, OnInit } from '@angular/core';
import { ReportService } from 'src/app/services/report.service';
import { GiftGenerationReport } from 'src/app/models/gift-generation-report';
import { GetGiftGenerationReportInputDto } from 'src/app/models/gift-generation-report-input';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-inactive-corporate-gift',
  templateUrl: './inactive-corporate-gift.component.html',
  styleUrls: ['./inactive-corporate-gift.component.css']
})
export class InactiveCorporateGiftComponent implements OnInit {

  giftGenerationReportForm: FormGroup;
  input: GetGiftGenerationReportInputDto;
  filterTypes: any[];

  currentPage: number = 1;
  pageSize: number = 10;
  expandedIndex: number = -1;
  totalItems: number = 0;

  defaultStartDate: Date;
  defaultEndDate: Date;


  constructor(private formBuilder: FormBuilder, private reportService: ReportService) {
    this.filterTypes = [{ text: 'All', value: 0 }, { text: 'Active', value: 1 }, { text: 'In Active', value: 2 }];

    this.input = new GetGiftGenerationReportInputDto();

    var startDate1 = new Date();
    startDate1.setDate(startDate1.getDate() - 2);
    this.defaultStartDate = startDate1;
    this.defaultEndDate = new Date();
    this.buildForm();
  }

  ngOnInit() {
    this.getAllGiftGenerationReports();
  }

  giftGenerationReports: GiftGenerationReport[] = [];


  buildForm() {
    this.giftGenerationReportForm = this.formBuilder.group({
      fromDate: [this.defaultStartDate, Validators.required],
      toDate: [this.defaultEndDate, Validators.required],
      type: 0
    });
  }


  expandCollapse(index) {
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }


  getAllGiftGenerationReports() {
    this.input.from = moment(this.f.fromDate.value).format("YYYY/MM/DD");
    this.input.to = moment(this.f.toDate.value).format("YYYY/MM/DD");
    this.input.type = this.f.type.value;
    this.input.pageNumber = this.currentPage;
    this.input.pageSize = this.pageSize;


    this.reportService.getGiftGenerationReportQuery(this.input).subscribe(result => {
      this.giftGenerationReports = result.viewModel;
      this.totalItems = result.rowCount;
    });
  }

  get f() { return this.giftGenerationReportForm.controls; }


  exportToExcel() {
    this.input.from = moment(this.f.fromDate.value).format("YYYY/MM/DD");
    this.input.to = moment(this.f.toDate.value).format("YYYY/MM/DD");
    this.input.type = this.f.type.value;
    this.input.pageNumber = this.currentPage;
    this.input.pageSize = this.pageSize;


    this.reportService.getGiftGenerationExcelReport(this.input)
      .subscribe(data => this.downloadFile(data)),//console.log(data),
      error => console.log("Error downloading the file."),
      () => console.log('Completed file download.');
  }



  downloadFile(data) {
    const blob = new Blob([data], { type: 'ext/csvapplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    var blob1 = new Blob([(<any>data)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const blob2 = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var filename = "GiftReports" + moment().format('MMMM Do YYYY, h:mm:ss').valueOf() + ".xlsx";
    saveAs(blob2, filename);
  }

  pageChanged(event) {
    this.currentPage = event;
    this.getAllGiftGenerationReports();
  }

  itemCount() {
    let firstItem = (this.currentPage - 1) * this.pageSize + 1;
    let lastItem = (this.currentPage) * this.pageSize;
  }
}
