import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ReportService } from 'src/app/services/report.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Company } from '../../models';
import { MerchantDueReportInputDto } from '../../models/merchant-due-report-input';
import { CompanyService, DataService } from '../../services';
import { ReplaySubject } from 'rxjs';
import { ValidationUtility } from '../../utility/validation.utility';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-merchant-due-report',
  templateUrl: './merchant-due-report.component.html',
  styleUrls: ['./merchant-due-report.component.css']
})

export class MerchantDueReportComponent implements OnInit {
  @BlockUI('merchant-due-report-section') blockUI: NgBlockUI;
  @BlockUI('merchant-due-summary-report-section') summaryblockUI: NgBlockUI;

  merchantDueReportForm: FormGroup;

  merchantId: number;
  merchants: any[];
  currentPage: number = 1;
  pageSize: number = 25;
  expandedIndex: number = -1;
  expandedGiftIndex: number = -1;
  expandedOutletIndex: number = -1;
  totalItems: number = 0;
  currentPageNumberDetails: string;
  paginationPageNumbers: any[];
  defaultStartDate: Date;
  defaultEndDate: Date;
  merchantFilterCtrl: FormControl = new FormControl();
  filteredMerchants: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);
  totalGiftAmount: number = 0;
  totalSalesAmount: number = 0;
  totalSalesCommission: number = 0;
  totalPayableAmount: number = 0;
  submitted: boolean;

  constructor(private formBuilder: FormBuilder,
    private reportService: ReportService,
    private companyService: CompanyService,
    private router: Router,
    private dataService: DataService,
    private sharedService: SharedService) {
    var startDate1 = new Date();
    this.defaultStartDate = startDate1;
    this.defaultEndDate = new Date();
    this.buildForm();
  }

  ngOnInit() {
    this.paginationPageNumbers = this.sharedService.getPaginationByPageNumbers();
    this.getMerchants();
    this.bindFilterChangeEvent();
  }

  buildForm() {
    this.merchantDueReportForm = this.formBuilder.group({
      fromDate: [this.defaultStartDate, Validators.required],
      toDate: [this.defaultEndDate, Validators.required],
      merchantId: [this.merchantId, Validators.required],
    });
  }

  merchantDueReports: any[] = [];

  expandCollapse(index) {
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }

  expandCollapseGift(index) {
    this.expandedGiftIndex = index === this.expandedGiftIndex ? -1 : index;
  }
  expandCollapseOutlet(index) {
    this.expandedOutletIndex = index === this.expandedOutletIndex ? -1 : index;
  }

  getMerchants() {
    this.companyService.getMerchantBasics()
      .subscribe(data => {
        this.merchants = data;
        this.filteredMerchants.next(this.merchants);
      });
  }

  navigateToBillingPage(merchantId: number, outletQRCode: string, outletName: string) {
    this.router.navigate(['/billings'], { queryParams: { merchantId: merchantId, outletQRCode: outletQRCode, outletName: outletName } });
  }

  onSubmitMerchantDueReport() {
    this.getAllMerchantDueReports();
    //this.getMerchantDueSummaryReport();
  }

  getAllMerchantDueReports() {
    this.submitted = true;
    if (this.merchantDueReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    this.blockUI.start();
    let url = `api/MerchantBilling/unbilled-records?merchantId=${this.merchantId}&pageSize=${this.pageSize}&pageNumber=${this.currentPage}&excludeBilledRecords=false`;
    url = `${url}&fromDate=${(new Date(this.f.fromDate.value)).toDateString()}`;
    url = `${url}&toDate=${(new Date(this.f.toDate.value)).toDateString()}`;

    this.dataService.get(url)
      .subscribe((result: any) => {
        this.merchantDueReports = result.records;
        this.totalItems = result.totalCount;

        this.getCurrentRange();
        this.buildSummary(result.recordSummary);
        this.blockUI.stop();
      }, () => {
        this.blockUI.stop();
      });
  }

  get f() { return this.merchantDueReportForm.controls; }

  bindFilterChangeEvent() {
    this.merchantFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterData(this.merchants, this.merchantFilterCtrl.value, this.filteredMerchants);
      });
  }

  filterData(allData: any, search: string, filterData: any) {
    if (!allData) {
      return;
    }
    if (!search) {
      filterData.next(allData);
      return;
    } else {
      search = search.toLowerCase();
    }
    filterData.next(
      allData.filter(data => data.name.toLowerCase().indexOf(search) > -1)
    );
  }
  pageChanged(event) {
    this.currentPage = event;
    this.getAllMerchantDueReports();
  }

  // exportToExcel() {
  //   var input = new MerchantDueReportInputDto();
  //   input.merchantId = this.merchantId;
  //   input.from = moment(this.f.fromDate.value).format("YYYY/MM/DD");
  //   input.to = moment(this.f.toDate.value).format("YYYY/MM/DD");

  //   this.reportService.getMerchantDueExcelReport(input)
  //     .subscribe(data => this.downloadFile(data)),
  //     () => console.log("Error downloading the file."),
  //     () => console.log('Completed file download.');
  // }

  exportToExcel() {

    let obj = {
      'merchantId': this.merchantId,
      'fromDate': (new Date(this.f.fromDate.value)).toDateString(),
      'toDate': (new Date(this.f.toDate.value)).toDateString()
    };
    let url = "api/MerchantBilling/export-merchant-availed-records";

    this.dataService.postWithOptions(url, obj, {
      responseType: 'blob'
    }).subscribe((response: any) => {
      this.downloadFile(response);
    }, (err) => console.log(err));
  }


  buildSummary(summary: any) {
    this.totalGiftAmount = summary.giftAmount;
    this.totalSalesAmount = summary.salesAmount;
    this.totalSalesCommission = summary.commission;
    this.totalPayableAmount = summary.payableAmount;
  }

  downloadFile(data) {
    const blob2 = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var filename = "Merchant_Availed_Records_" + moment().format('MMMM Do YYYY, h:mm:ss').valueOf() + ".xlsx";
    saveAs(blob2, filename);
  }

  getCurrentRange() {
    this.currentPageNumberDetails = `Showing ${((this.currentPage - 1) * this.pageSize) + 1}-${((this.currentPage - 1) * this.pageSize) + this.merchantDueReports?.length} of ${this.totalItems}`;
  }

}

