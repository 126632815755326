import { Component, OnInit, Inject, EventEmitter, Output, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OfferAssignmentService, AlertService } from 'src/app/services';

import * as moment from 'moment';
import { Offer, OfferAssignment } from 'src/app/models';
import { ValidationUtility } from '../../utility/validation.utility';
import { PaymentOptionsComponent } from '../payment-options/payment-options.component';
import { AssignmentResponse } from 'src/app/models/assignmentresponse';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { ConstantText } from 'src/app/constants/constant.text';

@Component({
  selector: 'app-create-offer-assignment',
  templateUrl: './create-offer-assignment.component.html',
  styleUrls: ['./create-offer-assignment.component.css']
})
export class CreateOfferAssignmentComponent implements OnInit {

  // onCreate event
  onAssignmentEvent: EventEmitter<number> = new EventEmitter();

  // onCreate event
  @Output() onAssignmentCreateEvent: EventEmitter<AssignmentResponse> = new EventEmitter();
  // onEdit event;
  @Output() onAssignmentEditEvent: EventEmitter<AssignmentResponse> = new EventEmitter();
  inputData: Offer;

  offerAssignmentForm: FormGroup;
  submitted = false;
  offerAssignmentData: FormData;
  assignmentFiles: FormData;
  offerAssignment: OfferAssignment;
  assignmentResponse: AssignmentResponse;

  fileValidationError: string;
  fileName: string = '';

  giftDeliveryMethods: any;
  showDeliveryAddressInput = false;
  showDeliveryMethodSelection = false;
  title: string = ConstantText.NewAssignmentModalTitle;
  showFileUpload: boolean = true;
  showPhoneNumberSection: boolean = true;
  bulkCodeGenerate: boolean = true;

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateOfferAssignmentComponent>,
    private offerAssignmentService: OfferAssignmentService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private zone: NgZone,
    @Inject(MAT_DIALOG_DATA) data) {

    if (data !== null) {
      this.inputData = data;
      this.showDeliveryAddressInput = this.inputData.merchantDeliveryMethod === 'HomeDelivery' ? true : false;
      this.showDeliveryMethodSelection = this.inputData.merchantDeliveryMethod === 'Both' ? true : false;
      this.title = data.title;
      this.showFileUpload = data.showFileUpload;
      this.showPhoneNumberSection = data.showPhoneNumberSection;
      this.bulkCodeGenerate = data.bulkCodeGenerate;
    }

    this.offerAssignmentForm = this.formBuilder.group({
      phoneNumbers: '',
      deliveryAddress: '',
      effectiveFrom: ['', Validators.required],
      effectiveFromTime: [''],
      expireDate: ['', Validators.required],
      giftDeliveryMethod: [this.giftDeliveryMethods, [Validators.required]],
      numberofcode: '',
      giftAmount: ''
    });

    this.setDeliveryMethodValidator();
  }

  setDeliveryMethodValidator() {
    const deliveryMethodControl = this.offerAssignmentForm.get('giftDeliveryMethod');
    if (this.inputData.merchantDeliveryMethod === 'Both') {
      deliveryMethodControl.setValidators([Validators.required]);
    }
    else {
      deliveryMethodControl.clearValidators();
    }
  }

  ngOnInit() {
    this.giftDeliveryMethods = [{ text: 'Outlet Pickup', value: 'OutletPickup' }, { text: 'Home Delivery', value: 'HomeDelivery' }];
  }

  openFileBrowser(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById('offerAssignmentFileId') as HTMLElement;
    element.click();
  }

  uploadLogo(files) {
    this.fileValidationError = null;
    if (files.length === 0)
      return;

    const formData = new FormData();

    if (files[0].size > (1024 * 1024)) {
      this.fileValidationError = "Maximum file size should be 1 MB";
      return;
    }

    if (files[0].type != 'text/csv') {
      this.fileValidationError = "Only CSV file allowed";
      return;
    }

    for (let file of files) {
      formData.append(file.name, file);
      this.fileName += ' ' + file.name;
    }
    this.assignmentFiles = formData;
  }


  onSubmit() {
    this.submitted = true;

    this.extractDataFromForm();
    
    this.dialogRef.close();

    this.offerAssignmentService.assignOffer(this.offerAssignmentData)
      .subscribe((data: any) => {
        this.assignmentResponse = data;
        this.onAssignmentEvent.emit(this.offerAssignment.offerId);
        this.alertService.success('Assignment done successfully');
        if (this.assignmentResponse.giftAmount > 0) {
          this.selectpaymentOption();
        }
      },
        (error: any) => {
          this.alertService.error('Failed to Create Assignment. Contact with the Gifty Admin.');
        });
  }

  extractDataFromForm() {

    // stop here if form is invalid
    if (this.offerAssignmentForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    this.offerAssignment = new OfferAssignment(this.offerAssignmentForm.value);
    this.offerAssignment.offerId = this.inputData.id;

    this.offerAssignmentData = new FormData();
    if (this.assignmentFiles != null) {
      this.offerAssignmentData = this.assignmentFiles;
    }

    var fromDate = moment(this.offerAssignment.effectiveFrom).format("YYYY/MM/DD");
    const toDate = moment(this.offerAssignment.expireDate).format("YYYY/MM/DD");


    /// Added for timepicker
    const fromDate1 = moment(this.offerAssignment.effectiveFrom);
    var fromTime2 = this.timeConvertor(this.f.effectiveFromTime.value);

    var time = fromTime2 ? fromTime2.split(':') : fromTime2;

    if (time && time.length > 1) {
      var hours = time[0];
      var minutes = time[1];

      fromDate = fromDate1.add(hours, 'hours').add(minutes, 'minutes').format("YYYY/MM/DD HH:mm");
    }
    /// Added for timepicker
    var deliveryMethodMap = 'OutletPickup';
    if (this.inputData.merchantDeliveryMethod === 'HomeDelivery') deliveryMethodMap = 'HomeDelivery';

    var deliveryMethod = this.inputData.merchantDeliveryMethod === 'Both' ? this.offerAssignment.giftDeliveryMethod : deliveryMethodMap;

    this.offerAssignmentData.append('offerId', this.offerAssignment.offerId.toString());
    this.offerAssignmentData.append('phoneNumbers', this.offerAssignment.phoneNumbers);
    this.offerAssignmentData.append('deliveryAddress', this.offerAssignment.deliveryAddress);
    this.offerAssignmentData.append('giftDeliveryMethod', deliveryMethod);
    this.offerAssignmentData.append('effectiveDate', fromDate);
    this.offerAssignmentData.append('expireDate', toDate);
    this.offerAssignmentData.append('codeGenerate', String(this.bulkCodeGenerate));
    this.offerAssignmentData.append('numberofcode', this.offerAssignment.numberofcode.toString() === '' ? '0' : this.offerAssignment.numberofcode.toString());
  }

  /// Added for timepicker
  timeConvertor(time) {
    var PM = time.match('pm') ? true : false

    time = time.split(':')
    if (time && time.length > 1) {
      var hour;
      var min;

      if (PM) {
        hour = 12 + parseInt(time[0], 10)
        min = time[1].replace('pm', '')
      } else {
        hour = time[0]
        min = time[1].replace('am', '')
      }
      return hour + ":" + min;
    }
    //  console.log(hour + ':' + min + ':' + sec)
  }

  close() {
    this.dialogRef.close();
  }

  selectpaymentOption() {
    const dialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'golp-dialog';
    dialogConfig.data = 
                      { 
                        isInvoiceBillPayment: false, 
                        data: this.assignmentResponse,
                        bulkCodeGenerate: this.bulkCodeGenerate
                       };
    //dialogConfig.data = this.assignmentResponse;

    this.zone.run(() => {
      const offerCreateDialog = this.dialog.open(PaymentOptionsComponent, dialogConfig);
      const successfullCreate = offerCreateDialog.componentInstance.onOptionSelectionEvent.subscribe((data) => {
        this.onAssignmentCreateEvent.emit(data);
      });

      offerCreateDialog.afterClosed().subscribe(() => {
      });
    });
  }

  onChangeDeliveryMethod(deliveryMethod) {
    this.showDeliveryAddressInput = deliveryMethod === 'HomeDelivery' ? true : false;
  }

  // convenience getter for easy access to form fields
  get f() { return this.offerAssignmentForm.controls; }

}
