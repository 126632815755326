import { Component, OnInit } from '@angular/core';
import { ReportService } from 'src/app/services/report.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
import { GiftTransactionReport } from '../../models/gift-transacion-report';
import { GetGiftTransactionReportInputDto } from '../../models/gift-transaction-report-input';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ShowOfferAssignmentDetailsComponent } from '../show-offer-assignment-details/show-offer-assignment-details.component';
import { SharedService } from '../../services/shared.service';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { ValidationUtility } from '../../utility/validation.utility';

@Component({
  selector: 'app-active-promotional-gift',
  templateUrl: './active-promotional-gift.component.html',
  styleUrls: ['./active-promotional-gift.component.css']
})

export class ActivePromotionalGiftComponent implements OnInit {
  @BlockUI('promotional-gift-report-section') blockUI: NgBlockUI;

  activePromotionalGiftReportForm: FormGroup;
  input: GetGiftTransactionReportInputDto;
  filterTypes: any[];
  paymentMethods: any[];
  paymentStatuses: any[];

  currentPage: number = 1;
  pageSize: number = 25;
  expandedIndex: number = -1;
  totalItems: number = 0;

  defaultStartDate: Date;
  defaultEndDate: Date;
  paginationPageNumbers: any[];
  currentPageNumberDetails: string;
  submitted: boolean;



  constructor(private formBuilder: FormBuilder, private reportService: ReportService,
    private dialog: MatDialog, private sharedService: SharedService) {
    //this.filterTypes = [{ text: 'All', value: 0 }, { text: 'Active', value: 1 }, { text: 'In Active', value: 2 }];
    this.filterTypes = [{ text: 'All', value: 0 }, { text: 'Active', value: 1 }, { text: 'In Active', value: 2 }];
    this.paymentMethods = [{ text: 'bKash', value: 1 }, { text: 'Card', value: 3 }];
    this.paymentStatuses = [{ text: 'None', value: 0 }, { text: 'InProgress', value: 5 }, { text: 'Failed', value: 10 }];

    this.input = new GetGiftTransactionReportInputDto();

    var startDate1 = new Date();
    //startDate1.setDate(startDate1.getDate() - 7);
    this.defaultStartDate = startDate1;
    this.defaultEndDate = new Date();
    this.buildForm();
  }

  ngOnInit() {
    this.paginationPageNumbers = this.sharedService.getPaginationByPageNumbers();
  }

  giftTransactionReports: GiftTransactionReport[] = [];


  buildForm() {
    this.activePromotionalGiftReportForm = this.formBuilder.group({
      fromDate: [this.defaultStartDate, Validators.required],
      toDate: [this.defaultEndDate, Validators.required],
      type: 1,
      paidThrough: '',
      paymentStatus: ''
    });
  }


  expandCollapse(index) {
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }


  getAllPromotionalGiftTransactionReports() {
    this.submitted = true;
    if (this.activePromotionalGiftReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    this.input.from = moment(this.f.fromDate.value).format("YYYY/MM/DD");
    this.input.to = moment(this.f.toDate.value).format("YYYY/MM/DD");
    this.input.type = this.f.type.value;
    this.input.paidThrough = this.f.paidThrough.value;
    this.input.paymentStatus = this.f.paymentStatus.value;
    this.input.pageNumber = this.currentPage;
    this.input.pageSize = this.pageSize;

    this.blockUI.start();

    this.reportService.getPromotionalGiftTransactionReportQuery(this.input).subscribe(result => {
      this.giftTransactionReports = result.data;
      this.totalItems = result.rowCount;
      this.currentPageNumberDetails = "(Showing " + result.firstRowOnPage + " to " + result.lastRowOnPage + " of " + result.rowCount + " items)"
      this.blockUI.stop();

    }, error => {
      this.blockUI.stop();
    });
  }

  showOfferAssignments(model: any) {
    const userDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    userDialogConfig.disableClose = true;
    userDialogConfig.autoFocus = true;
    userDialogConfig.data = model;
    userDialogConfig.panelClass = 'golp-dialog';

    const showOfferAssignmentDialog = this.dialog.open(ShowOfferAssignmentDetailsComponent, userDialogConfig);

    //showOfferAssignmentDialog.componentInstance.companyRoleType = this.companyRoleType;
  }


  get f() { return this.activePromotionalGiftReportForm.controls; }


  exportToExcel() {
    this.input.from = moment(this.f.fromDate.value).format("YYYY/MM/DD");
    this.input.to = moment(this.f.toDate.value).format("YYYY/MM/DD");
    this.input.type = this.f.type.value;
    this.input.paidThrough = this.f.paidThrough.value;
    this.input.paymentStatus = this.f.paymentStatus.value;
    this.input.pageNumber = this.currentPage;
    this.input.pageSize = this.pageSize;


    this.reportService.getPromotionalGiftGenerationExcelReport(this.input)
      .subscribe(data => this.downloadFile(data)),//console.log(data),
      error => console.log("Error downloading the file."),
      () => console.log('Completed file download.');
  }



  downloadFile(data) {
    var filterText = this.filterTypes.filter(x => x.value == this.f.type.value)[0].text;

    const blob2 = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var filename = filterText + "-" + "PromotionalGiftReports -" + moment().format('MMMM Do YYYY, h:mm:ss').valueOf() + ".xlsx";
    saveAs(blob2, filename);
  }

  pageChanged(event) {
    this.currentPage = event;
    this.getAllPromotionalGiftTransactionReports();
  }

  itemCount() {
    let firstItem = (this.currentPage - 1) * this.pageSize + 1;
    let lastItem = (this.currentPage) * this.pageSize;
  }
}
