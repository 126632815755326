import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserCredit, UserCreditSummary } from '../models';


@Injectable({
  providedIn: 'root'
})

export class UserCreditsService {

  private userCreditBaseUrl = 'api/userCredit';

  constructor(private http: HttpClient) { }

  getUserCredits(): Observable<any[]> {
    return this.http.get<any[]>(this.userCreditBaseUrl);
  }

  getUserCreditByUserId(userId: number): Observable<any[]> {
    return this.http.get<any[]>(this.userCreditBaseUrl+"/user/" + userId);
  }

  getUserCreditSummaryByUserId(userId: number): Observable<UserCreditSummary> {
    return this.http.get<UserCreditSummary>(this.userCreditBaseUrl + "/user/" + userId+"/summary");
  }

  getUserCreditById(userCreditId: number): Observable<any[]> {
    return this.http.get<any[]>(this.userCreditBaseUrl + userCreditId);
  }

  createUserCredit(userCredit: UserCredit) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.userCreditBaseUrl, userCredit, requestHeader);
  }

  editUserCredit(userCredit: UserCredit) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.put(this.userCreditBaseUrl, userCredit, requestHeader);
  }


  changeUserCreditStatus(userCredit: UserCredit) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.put(this.userCreditBaseUrl+"/change-status", userCredit, requestHeader);
  }


  changeAllUserCreditStatus(userCredit: UserCredit) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.put(this.userCreditBaseUrl + "/user/change-all-status", userCredit, requestHeader);
  }

  deleteUserCredit(userCreditId: number) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.delete(this.userCreditBaseUrl + '/' + userCreditId, requestHeader);
  }
}
