import { Component, OnInit, SimpleChanges, Injector } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { AlertService, GiftTemplateService } from '../../services';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { BaseComponent } from '../base/base.component';
import { CreateGiftTemplateComponent } from '../create-gift-template/create-gift-template.component';
import { GiftTemplateDto, GiftTemplatePagedListInputDto, GiftTemplateAssociationsInputDto } from '../../models';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { SharedService } from '../../services/shared.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
@Component({
  selector: 'app-gift-templates',
  templateUrl: './gift-templates.component.html',
  styleUrls: ['./gift-templates.component.css']
})

export class GiftTemplatesComponent extends BaseComponent implements OnInit {
  @BlockUI('gift-template-block-ui') blockUI: NgBlockUI;

  giftTemplateName: string = "";
  submitted: boolean = false;
  giftTemplateId: any;
  giftTemplates: GiftTemplateDto[] = [];
  //GiftTemplateSummary: GiftTemplateSummary;
  isSystemAdmin = false;
  currentPage: number = 1;
  pageSize: number = 10;
  expandedIndex: number = -1;
  totalItems: number = 0;

  paginationPageNumbers: any[];
  currentPageNumberDetails: string;

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private GiftTemplateService: GiftTemplateService,
    injector: Injector
  ) {
    super(injector);
  }


  ngOnInit() {
    this.buildForm();

    this.getGiftTemplates();
    this.paginationPageNumbers = this.sharedService.getPaginationByPageNumbers();
  }

  ngOnChanges() {

  }


  giftTemplateForm: FormGroup;



  buildForm() {
    this.giftTemplateForm = this.formBuilder.group({
      giftTemplateName: [this.giftTemplateName],

      //fromDate: [this.defaultStartDate, Validators.required],
      //toDate: [this.defaultEndDate, Validators.required],
      //merchantId: [this.merchantId, Validators.required],
    });
  }



  get f() { return this.giftTemplateForm.controls; }


  createGiftTemplate() {
    const createGiftTemplateDialogConfig = new MatDialogConfig();

    createGiftTemplateDialogConfig.disableClose = true;
    createGiftTemplateDialogConfig.autoFocus = true;
    createGiftTemplateDialogConfig.panelClass = "golp-dialog";

    //var GiftTemplate = new GiftTemplateInputDto();
    //createGiftTemplateDialogConfig.data = GiftTemplate;

    const createGiftTemplateDialog = this.dialog.open(CreateGiftTemplateComponent, createGiftTemplateDialogConfig);


    createGiftTemplateDialog.afterClosed().subscribe(() => {
    });
  }

  editGiftTemplate() {
    const editGiftTemplateDialogConfig = new MatDialogConfig();

    editGiftTemplateDialogConfig.disableClose = true;
    editGiftTemplateDialogConfig.autoFocus = true;
    editGiftTemplateDialogConfig.panelClass = "golp-dialog";

    //var GiftTemplate = new GiftTemplateInputDto();
    //createGiftTemplateDialogConfig.data = GiftTemplate;

    const editGiftTemplateDialog = this.dialog.open(CreateGiftTemplateComponent, editGiftTemplateDialogConfig);


    editGiftTemplateDialog.afterClosed().subscribe(() => {
    });
  }

  getGiftTemplateById() {
    if (this.giftTemplateId) {
      this.GiftTemplateService.getGiftTemplateById(this.giftTemplateId).subscribe(() => {
        //this.giftTemplates = result;
      });
    }
  }

  selection(item) {
    item.selected = !item.selected;
    //this.calculateTotal();

  }

  onChangeSelectAll(event) {
    if (event.checked) {
      this.giftTemplates.forEach(obj => {
        obj.selected = true;
      });
    }
    else {
      this.giftTemplates.forEach(obj => {
        obj.selected = false;
      });
    }
    // this.calculateTotal();
  }

  getGiftTemplates() {
    var input = new GiftTemplatePagedListInputDto();
    input.name = this.giftTemplateName;
    input.pageNumber = this.currentPage;
    input.pageSize = this.pageSize;

    this.blockUI.start();

    this.GiftTemplateService.getGiftTemplatesPagedList(input).subscribe(result => {
      this.giftTemplates = result.data;
      this.totalItems = result.rowCount;
      this.currentPageNumberDetails = "(Showing " + result.firstRowOnPage + " to " + result.lastRowOnPage + " of " + result.rowCount + " items)"

      this.blockUI.stop();

    }, () => {
      this.blockUI.stop();
    });
  }



  getGiftTemplateAssociations(giftTemplateId, serialNo) {
    var input = new GiftTemplateAssociationsInputDto();
    //input.giftTemplateAssociationId = this.giftTemplateName;
    input.giftTemplateId = giftTemplateId;
    input.serialNo = serialNo;

    this.blockUI.start();

    this.GiftTemplateService.getGiftTemplateAssociations(input).subscribe(result => {
      //var giftTemplateAssociations = result.data;

      var giftTemplate = this.giftTemplates.find(x => x.id == giftTemplateId);

      if (giftTemplate) {

        giftTemplate.giftTemplateAssociations = result;
      }

      this.blockUI.stop();

    }, () => {
      this.blockUI.stop();
    });
  }
  pageChanged(event) {
    this.currentPage = event;
    this.getGiftTemplates();
  }

  itemCount() {
  }

  printAllGiftTemplateAssociation(giftTemplateId: any) {
    var ids = [];
    ids.push(giftTemplateId);

    var input = { giftTemplateIds: ids };



    const confirmationDialogConfig = new MatDialogConfig()
    var text = "Gift template will be printed only once. Please save it after processing. Are you sure to print?";
    confirmationDialogConfig.data = text;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.GiftTemplateService.printGiftTemplates(input)
          .subscribe((data: any) => {
            if (data != null) {
              //window.open(data.printGiftUrl, '_blank');
              var newWindow = window.open('', "_blank");
              newWindow.location.href = data.printGiftUrl;

              this.getGiftTemplates();
            }
          });
      }
    });
  }


  saveAllAsHtmlGiftTemplateAssociation(giftTemplateId: any) {
    var ids = [];
    ids.push(giftTemplateId);

    var input = { giftTemplateIds: ids };

    this.GiftTemplateService.saveAsHtmlGiftTemplates(input)
      .subscribe((data: any) => {
        if (data != null) {
          var newwindow = window.open('', "_blank");
          newwindow.location.href = data.htmlPath;
        }
      });
  }

  downloadPinAndCode(giftTemplateId: any) {
    var ids = [];
    ids.push(giftTemplateId);

    var input = { giftTemplateIds: ids };
    let option = {
      responseType: 'blob'
    }
    this.GiftTemplateService.downloadXlPinAndCode(input, option)
      .subscribe((data: any) => {
        this.downloadFile(data);
      });
  }


  downloadFile(data) {
    const blob2 = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var filename = "Gift-Pin-Code-" + moment().format('MMMM Do YYYY, h:mm:ss').valueOf() + ".xlsx";
    saveAs(blob2, filename);
  }

  printGiftTemplate(id: any, giftTemplateId: any) {
    var ids = [];
    ids.push(id);

    var input = { giftTemplateAssociationIds: ids };


    const confirmationDialogConfig = new MatDialogConfig()
    var text = "Gift template will be printed only once. Please save it after processing. Are you sure to print?";
    confirmationDialogConfig.data = text;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.GiftTemplateService.printGiftTemplates(input)
          .subscribe((data: any) => {
            if (data != null) {
              //window.open(data.printGiftUrl, '_blank');
              var newWindow = window.open('', "_blank");
              newWindow.location.href = data.printGiftUrl;

              var gT = this.giftTemplates.find(x => x.id == giftTemplateId);

              var ass = gT.giftTemplateAssociations.find(x => x.id == id);

              ass.isPrinted = true;
              ass.printedOn = new Date().toLocaleString();

            }
          });
      }
    });

  }


  exportToExcelGiftTemplate(giftTemplateId: any) {
    var ids = [];
    ids.push(giftTemplateId);

    var input = { giftTemplateIds: ids };

    this.GiftTemplateService.exportToExcelGiftTemplates(input)
      .subscribe((data: any) => {
        if (data != null) {
          var newwindow = window.open('', "_blank");
          newwindow.location.href = data.htmlPath;
        }
      });
  }

  saveAsHtmlGiftTemplate(id: any) {
    var ids = [];
    ids.push(id);

    var input = { giftTemplateAssociationIds: ids };

    this.GiftTemplateService.saveAsHtmlGiftTemplates(input)
      .subscribe((data: any) => {
        if (data != null) {
          var newwindow = window.open('', "_blank");
          newwindow.location.href = data.htmlPath;
        }
      });
  }

  onSearchGiftTemplate() {
    this.submitted = true;

    this.giftTemplateName = this.f.giftTemplateName.value;

    if (!this.giftTemplateName) {
      this.submitted = false; // made false to remove the error message if no name is searched
    }
    //if (this.giftTemplateForm.invalid) {
    //  return;
    //}

    this.getGiftTemplates();
  }


  searchBySerialNo(item, serialNo) {
    this.getGiftTemplateAssociations(item.id, serialNo);
    //if (serialNo) {
    //  var aa = item.giftTemplateAssociations.filter(x => x.serialNo.includes(serialNo));
    //  item.giftTemplateAssociations = aa;
    //}
  }
  //getGiftTemplateSummary() {
  //  if (this.userId) {
  //    this.GiftTemplateService.getGiftTemplateSummaryByUserId(this.userId).subscribe(result => {
  //      this.GiftTemplateSummary = result;
  //    });
  //  }
  //}

  //changeStatus(item: GiftTemplate, active: boolean) {
  //  const confirmationDialogConfig = new MatDialogConfig()
  //  var text = "Are you sure to activate this user credit?";
  //  var successMessage = "User credit activated successfully";
  //  if (active == false) {
  //    text = "Are you sure to deactivate this user credit?";
  //    successMessage = "User credit deactivated successfully";
  //  }
  //  var GiftTemplate = new GiftTemplate();
  //  GiftTemplate.isActive = active;
  //  GiftTemplate.id = item.id;
  //  // Setting different dialog configurations
  //  confirmationDialogConfig.data = text;
  //  confirmationDialogConfig.panelClass = 'golp-dialog';
  //  const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

  //  confirmationDialog.afterClosed().subscribe((result) => {
  //    if (result != undefined) {
  //      this.GiftTemplateService.changeGiftTemplateStatus(GiftTemplate).subscribe(result => {
  //        this.alertService.success(successMessage);
  //        this.getGiftTemplate();
  //        this.getGiftTemplateSummary();
  //      });
  //    }
  //  });
  //}


  expandCollapse(index) {
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }
}
