export class OfferAssignment {
    phoneNumbers: string;
    effectiveFrom: string;
    expireDate: string;
    offerId: number;
    giftDeliveryMethod: string;
    deliveryAddress: string;
    numberofcode: number;

    public constructor(init?: Partial<OfferAssignment>) {
        Object.assign(this, init);
    }
}
