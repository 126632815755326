import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import * as XLSX from 'xlsx';
import { DataService } from '../../services/shared/data.service';

type AOA = any[][];
declare let alertify: any;
@Component({
  selector: 'app-uploaded-xls-preview',
  templateUrl: './uploaded-xls-preview.component.html',
  styleUrls: ['./uploaded-xls-preview.component.css']
})
export class UploadedXlsPreviewComponent implements OnInit {
  fileName: string = 'SheetJS.xlsx';
  data: any;
  headData: any // excel row header
  uploadedFiles: any;
  p: number = 1;
  pageSize: number = 25;
  pageSizes: number[] = [25, 50, 100];
  totalItems: number = 0;
  fileToBeUploaded: any;
  merchantId: any;
  merchantOutletId: any;
  constructor(private router: Router, private dataService: DataService, private dialog: MatDialog) {

    let navigation = this.router.getCurrentNavigation();
    let state = navigation.extras.state as {
      transId: string,
      workQueue: boolean,
      services: number,
      code: any
    };

    if (state != undefined && state.code != undefined) {
      this.headData = state.code.headData;
      this.data = state.code.data;
      console.log("head data : ", this.headData);
      //console.log("body data : ", this.data);
      // for(var i = 0; i < this.data.length; ++i){
      //   for(var j = 0; j < this.headData.length; ++j){
      //     console.log("cell value : ", this.data[i][j]);
      //   }
      // }
      let file = state.code.file;
      this.fileToBeUploaded = file;
      this.totalItems = this.data.length;
      this.merchantId = state.code.merchantId;
      this.merchantOutletId = state.code.merchantOutletId;
    }

    this.initDialog();
  }

  ngOnInit(): void {
  }


  initDialog() {
    try {
      alertify.dialog('confirmAlert', function factory() {
        let callBack;
        return {
          main: function (message, callback) {
            this.message = message;
            callBack = callback;
          },
          setup: function () {
            return {
              buttons: [{ text: "Cancel", key: 27/*Esc*/ }, { text: "Confirm", className: "confirm-dialog" }],
              focus: { element: 0 }
            };
          },
          prepare: function () {
            this.setContent(this.message);
            var header = '<span>Confirmation</span>';
            this.setHeader(header);
          },
          callback: function (closeEvent) {
            if (closeEvent.index == 1) {
              callBack.upload();
            }
          },
        };
      }, true, 'alert');
    }
    catch (e) { }

  }
  /* <input type="file" (change)="onFileChange($event)" multiple="false" /> */
  /* ... (within the component class definition) ... */
  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, range: 0 }));

      this.headData = this.data[0];
      this.data = this.data.slice(1); // remove first header record

      const ws2: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[1]];
      this.readDataSheet(ws2, 10);
    };
    reader.readAsBinaryString(target.files[0]);
  }

  private readDataSheet(ws: XLSX.WorkSheet, startRow: number) {
    /* save data */
    let datas = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, range: startRow }));
    console.log(datas[1]);
    let headDatas = datas[0];
    datas = datas.slice(1); // remove first header record

    for (let i = 0; i < this.data.length; i++) {
      this.data[i][this.headData.length] = datas.filter(x => x[12] == this.data[i][0])
    }
  }

  export(): void {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  uploadMessage() {
    // let object = this;
    // alertify.confirmAlert("<br>Do you really want to confirm? <br><br>This process can not be undone.",object);

    const confirmationDialogConfig = new MatDialogConfig();
    // Setting different dialog configurations
    confirmationDialogConfig.data = `Do you really want to confirm? This process can not be undone.`;
    confirmationDialogConfig.panelClass = "golp-dialog";
    const confirmationDialog = this.dialog.open(
      ConfirmationDialogComponent,
      confirmationDialogConfig
    );

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.upload();
      }
    });
  }

  upload() {
    let object = {
      "file": this.fileToBeUploaded,
      "merchantId": this.merchantId,
      "merchantOutletId": this.merchantOutletId
    }
    let navigationEgolps: NavigationExtras = {
      state: {
        transd: 'bulkResponse',
        workQueue: false,
        services: 1,
        code: object,
      }
    };

    this.router.navigate(['upload-amount-result'], navigationEgolps);
  }

  getHeadString(str) {
    if (str.toLowerCase() == "sl") return str;
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase() })
  }

  handlePageSizeChange($event) {
    this.pageSize = Number($event.target.value);
    this.p = 1;
  }

  cancel() {
    this.router.navigate(['bulk-gift']);
  }
}
