import { OfferModalComponent } from './../offer-modal/offer-modal.component';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as moment from 'moment';
import { MerchantOutletResponse } from 'src/app/models/view-models/merchant-outlet-response';
import { AlertService } from 'src/app/services';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import { DataService } from '../app-send-gift/services/shared/data.service';
import { NavigationExtras, Router } from '@angular/router';
@Component({
  selector: 'app-gift-with-offer',
  templateUrl: './gift-with-offer.component.html',
  styleUrls: ['./gift-with-offer.component.css']
})
export class GiftWithOfferComponent implements OnInit {

  merchantId: any;
  processId: any;
  userInfo: any;
  companyOutletId: any;
  merchantOutlets: any;
  orders: any;
  submitted: boolean;
  p: number = 1;
  offerReportForm: FormGroup;
  defaultStartDate: Date = new Date();
  defaultEndDate: Date = new Date();
  currentPage: number = 1;
  count = 0;
  pageSize = 25;
  pageSizes = [25, 50, 100];
  multiSelectOutlet: any;
  public defaultTime = [];

  giftCountFrom: any;
  giftCountTo: any;

  showGiftAmountSection: boolean = false;
  showGiftCountSection: boolean = false;
  showPurchaseAmountSection: boolean = false;
  showOutletSection: boolean = false;

  giftOrders: any;

  isSearchSectionCollapse: boolean = false;

  selectedGiftCard = new Set<string>();

  constructor(private dataService: DataService, private formBuilder: FormBuilder, private alertService: AlertService, private dialog: MatDialog, private router: Router) {
    var startDate1 = new Date();
    this.defaultStartDate = this.startOfMonth(startDate1);
    this.defaultEndDate = new Date();
    this.buildForm();
  }

  startOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  ngOnInit(): void {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userInfo = userInfo;
    // if (this.userInfo.outletQrCode == undefined || this.userInfo.outletQrCode == null)
    //   this.getMerchantOutlet();
    if (this.userInfo.outletQrCode != undefined && this.userInfo.outletQrCode != null)
      this.companyOutletId = this.userInfo.outletQrCode;

    this.loadData();
  }

  setDefaultTime() {
    let d = new Date();
    this.defaultTime = [d.getHours(), d.getMinutes(), d.getSeconds()]
    console.log(this.defaultTime)
  }

  loadSelectedData() {
    this.giftOrders.forEach(element => {
      if (element.completed) this.selectedGiftCard.add(element.receiverPhone);
      else this.selectedGiftCard.delete(element.receiverPhone);
    });
  }

  handlePageChange(event: number): void {
    this.allComplete = false;
    this.p = event;
    this.loadSelectedData();
    this.loadData();
  }

  handlePageSizeChange(event: any): void {
    this.allComplete = false;
    this.pageSize = event.target.value;
    this.p = 1;
    this.loadData();
  }

  loadData(reset: boolean = false) {
    if (reset) this.p = 1;
    this.submitted = true;
    // stop here if form is invalid
    if (this.offerReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    let giftAmountFrom = this.showGiftAmountSection ? this.f.giftAmountFrom.value : 0;
    let giftAmountTo = this.showGiftAmountSection ? this.f.giftAmountTo.value : 0;
    let salesAmountFrom = this.showPurchaseAmountSection ? this.f.salesAmountFrom.value : 0;
    let salesAmountTo = this.showPurchaseAmountSection ? this.f.salesAmountTo.value : 0;
    let minGiftCount = this.showGiftCountSection ? this.f.minGiftCount.value : 0;
    let maxGiftCount = this.showGiftCountSection ? this.f.maxGiftCount.value : 0;

    let fromDate = moment(this.f.fromDate.value).format("YYYY/MM/DDTHH:mm:ss");
    let todate = moment(this.f.toDate.value).format("YYYY/MM/DDTHH:mm:ss");
    // let outletId = this.showOutletSection ? this.f.outletId.value : 0;

    // this.companyOutletId = outletId != 0 ? outletId.qrCode : null;
    let url = `api/giftcard/gift-card-sale-summary/merchant/${this.userInfo.companyId}?fromDate=${fromDate}&toDate=${todate}&pageSize=${this.pageSize}&page=${this.p}`;

    // url = (this.userInfo.outletQrCode == null || outletId == 0) ? url : `${url}&outletId=${this.companyOutletId}`;
    url = (giftAmountFrom == null || giftAmountFrom == 0) ? url : `${url}&giftAmountFrom=${giftAmountFrom}`;
    url = (giftAmountTo == null || giftAmountTo == 0) ? url : `${url}&giftAmountTo=${giftAmountTo}`;
    url = (salesAmountFrom == null || salesAmountFrom == 0) ? url : `${url}&salesAmountFrom=${salesAmountFrom}`;
    url = (salesAmountTo == null || salesAmountTo == 0) ? url : `${url}&salesAmountTo=${salesAmountTo}`;
    url = (minGiftCount == null || minGiftCount == 0) ? url : `${url}&minGiftCount=${minGiftCount}`;
    url = (maxGiftCount == null || maxGiftCount == 0) ? url : `${url}&maxGiftCount=${maxGiftCount}`;

    this.dataService.get(url)
      .subscribe((response: any) => {
        if (response.success) {
          this.count = response.data.count;
          this.giftOrders = response.data.data;
          this.giftOrders?.forEach(m => {
            if (this.selectedGiftCard.size > 0) {
              this.selectedGiftCard.forEach(p => {
                if (p == m.receiverPhone) {
                  m["completed"] = true;
                }
              })
            }
            else {
              m["completed"] = false;
            }
          })
        }
        else {
          console.log(response);
        }
      });
  }

  getSerialNo(index) {
    return ((this.pageSize * (this.p - 1))) + index + 1;
  }
  buildForm() {
    this.offerReportForm = this.formBuilder.group({
      fromDate: [this.defaultStartDate, Validators.required],
      toDate: [this.defaultEndDate, Validators.required],
      // outletId: ['', Validators.nullValidator],
      giftAmountFrom: ['', Validators.nullValidator],
      giftAmountTo: ['', Validators.nullValidator],
      salesAmountFrom: ['', Validators.nullValidator],
      salesAmountTo: ['', Validators.nullValidator],
      minGiftCount: ['', Validators.nullValidator],
      maxGiftCount: ['', Validators.nullValidator]
    });
  }

  get f() { return this.offerReportForm.controls; }

  // getMerchantOutlet() {
  //   let url = `api/Outlets/company/${this.userInfo.companyId}/`;
  //   this.dataService.get(url).subscribe((data: MerchantOutletResponse[]) => {
  //     if (data != null && data.length > 0) {
  //       this.merchantOutlets = data;
  //     }
  //   });
  // }

  allComplete: boolean = false;

  updateAllComplete() {
    this.allComplete = this.giftOrders != null && this.giftOrders.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.giftOrders == null) {
      return false;
    }
    return this.giftOrders.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.giftOrders == null) {
      return;
    }
    this.giftOrders.forEach(t => t.completed = completed);
  }

  addFilter(filterType: any) {
    this.showGiftAmountSection = filterType === 'GiftAmountFilter' ? true : this.showGiftAmountSection;
    this.showGiftCountSection = filterType === 'GiftCountFilter' ? true : this.showGiftCountSection;
    this.showPurchaseAmountSection = filterType === 'PurchaseAmountFilter' ? true : this.showPurchaseAmountSection;
    this.showOutletSection = filterType === 'OutletFilter' ? true : this.showOutletSection;
  }

  deleteFilter(filterType: any) {
    this.showGiftAmountSection = filterType === 'GiftAmountFilter' ? false : this.showGiftAmountSection;
    this.showGiftCountSection = filterType === 'GiftCountFilter' ? false : this.showGiftCountSection;
    this.showPurchaseAmountSection = filterType === 'PurchaseAmountFilter' ? false : this.showPurchaseAmountSection;
    this.showOutletSection = filterType === 'OutletFilter' ? false : this.showOutletSection;
  }

  expandCollapse() {
    this.isSearchSectionCollapse = !this.isSearchSectionCollapse;
  }

  showSendOfferModal() {
    this.loadSelectedData();
    if (this.selectedGiftCard.size <= 0) {
      return;
    }
    const confirmationDialogConfig = new MatDialogConfig();
    // Setting different dialog configurations
    confirmationDialogConfig.data = `Would you like to proceed? This operation can not be undone.`;
    confirmationDialogConfig.panelClass = "golp-dialog";
    confirmationDialogConfig.minWidth = "60%";
    const confirmationDialog = this.dialog.open(
      OfferModalComponent,
      confirmationDialogConfig
    );

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.previewData(result);
      }
    });
  }

  previewData(offer: any) {
    offer = offer.filter(m => m.completed);
    let obj = {
      "offers": offer,
      "offerUsers": this.selectedGiftCard
    }
    let navigationEgolps: NavigationExtras = {
      state: {
        transId: 'giftWithOffer',
        workQueue: false,
        services: 1,
        code: obj,
      }
    };

    this.router.navigate(['send-offer-preview'], navigationEgolps);
  }

}
