export class GiftTemplatePagedListInputDto {
  name: string;
  merchantId?: number;
  isActive?: boolean;
  isPrinted?: boolean;
  isGiftCreated?: boolean;
  pageNumber: number;
  pageSize: number;


  public constructor(init?: Partial<GiftTemplatePagedListInputDto>) {
    Object.assign(this, init);
  }
}
