import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, Injector } from '@angular/core';
import { Offer } from 'src/app/models';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentMethod, OfferType } from 'src/app/enums';
import { CommonService, AccountServiceService, OfferAssignmentService, GolpProductService } from 'src/app/services';
import { RejectionReasonComponent } from '../rejection-reason/rejection-reason.component';
import { BaseComponent } from '../base/base.component';
import { CreateGolpProductComponent } from '../create-golp-product/create-golp-product.component';

@Component({
    selector: 'app-golp-product-detail',
    templateUrl: './golp-product-detail.component.html',
    styleUrls: ['./golp-product-detail.component.css']
})
export class GolpProductDetailComponent extends BaseComponent implements OnInit {

    @Input() offerDetailsInput: Offer;
    @Output() offerDetailsInputChange: EventEmitter<Offer> = new EventEmitter<Offer>();

    offerDetails: Offer;
    offerAssignmentSummary: any;
    userInfo: any;
    detailsLoaded: Promise<boolean>;

    canEdit: boolean = true;
    showHideAcceptButton: boolean = true;
    PaymentMethodsDisplay: { [index: number]: string } = {};

    constructor(public dialog: MatDialog, private accountService: AccountServiceService,
        private commonService: CommonService,
        private golpProductService: GolpProductService,
        private injector: Injector
    ) {
        super(injector);
    }

    ngOnInit() {
        this.PaymentMethodsDisplay = this.commonService.getPaymentMethodDisplayNames();
        this.getLoggedInUser();
        this.offerDetails = new Offer();
        this.showHideAcceptAction();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['offerDetailsInput']) {
            setTimeout(() => {
                this.offerDetails = this.offerDetailsInput;
                if (this.offerDetails != null) {
                    this.offerEditable();
                }
                this.showHideAcceptAction();
                this.makeSomeDelay(this.offerDetails);
            });
        }
    }

    editOffer(offer: Offer) {

        const editDialogConfig = new MatDialogConfig();

        // Setting different dialog configurations
        editDialogConfig.disableClose = true;
        editDialogConfig.autoFocus = true;
        editDialogConfig.panelClass = 'golp-dialog';

        editDialogConfig.data = offer

        var offerEditDialog = this.dialog.open(CreateGolpProductComponent, editDialogConfig);

        const successFullEdit = offerEditDialog.componentInstance.onOfferEditEvent.subscribe((data) => {
            this.golpProductService.getGolpProductById(data.id).subscribe(result => {
                this.offerDetails = result;
                this.offerDetailsInputChange.emit(result);
                this.showHideAcceptAction();
                this.makeSomeDelay(result);
                this.offerEditable();
            });
            
        });

        offerEditDialog.afterClosed().subscribe(() => {
            //successFullEdit.unsubscribe();
            //this.detailsLoaded = Promise.resolve(false);
        });
    }


    showRejectionReason() {
        const rejectionReasonDialogConfig = new MatDialogConfig();

        // Setting different dialog configurations
        rejectionReasonDialogConfig.panelClass = 'golp-dialog';
        rejectionReasonDialogConfig.data = this.offerDetails;

        const rejectDialog = this.dialog.open(RejectionReasonComponent, rejectionReasonDialogConfig);
    }

    showHideAcceptAction() {
        if (this.offerDetails != null) {
            if (this.offerDetails.isRejected || this.offerDetails.isActive || this.offerDetails.offerType == OfferType.GolpProduct) {
                this.showHideAcceptButton = false;
            }
            else {
                this.showHideAcceptButton = true;
            }
        }
    }

    makeSomeDelay(offer: Offer) {
        if (offer != null) {
            let readSomeValue = offer.name;
            this.detailsLoaded = Promise.resolve(true);
        }
    }

    //getOfferAssignmentSummary(id: number) {
    //  this.offerAssignmentService.getOfferAssignmentSummary(id)
    //    .subscribe((data) => {
    //      this.offerAssignmentSummary = data;
    //    })
    //}

    getLoggedInUser() {
        this.userInfo = this.accountService.getLoggedInUserInfo();
    }

    offerEditable() {
        this.canEdit = this.offerDetails.isActive || this.offerDetails.offerType == OfferType.GolpProduct;
    }
}
