import { Component, OnInit, Input, EventEmitter, Output, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { CompanyService, LoyaltyMerchantService } from '../../../services';
import { analyzeAndValidateNgModules } from '@angular/compiler';

@Component({
  selector: 'app-create-loyalty-card-tier-map',
  templateUrl: './create-loyalty-card-tier-map.component.html',
  styleUrls: ['./create-loyalty-card-tier-map.component.css']
})

export class CreateLoyaltyCardTierMapComponent implements OnInit, AfterContentChecked {

  @Output() loyaltyCardTierMapEmitter: EventEmitter<any> = new EventEmitter<any>();
  submitted = false;

  @Input() existingLoyaltyCardTierMap: any;

  keyValuesJson: any;
  keyValuesJsonArray: any;
  isSubmitted: false;
  public keyValuesForm: FormGroup;
  cardTiers: any;

  constructor(private _fb: FormBuilder, private loyaltyMerchantService: LoyaltyMerchantService, private cdref: ChangeDetectorRef) { }

  ngOnInit() {
    this.keyValuesForm = this._fb.group({
      itemRows: this._fb.array([this.initItemRows()])
    });

    this.getCardTiers();
    this.setFormArrayValue();
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  public setFormArrayValue() {
    if (this.existingLoyaltyCardTierMap) {
      this.deleteFirstEmptyRow();

      this.keyValuesJsonArray = JSON.parse(this.existingLoyaltyCardTierMap);

      var i = 0;

      //for (var arr in this.keyValuesJsonArray) {
      //  this.formArr.push(this.initItemRowsForEdit(arr));
      //  i = i + 1;
      //}

      for (var _i = 0, products_1 = this.keyValuesJsonArray; _i < products_1.length; _i++) {
        var product = products_1[_i];
        this.formArr.push(this.initItemRowsForEdit(product));
      }
      this.setLoyaltyCardTierMapJsonAndEmit();
    }
  }

  get formArr() {
    return this.keyValuesForm.get('itemRows') as FormArray;
  }

  initItemRowsForEdit(element: any) {
    return this._fb.group({
      cardTierId: [element.cardTierId],
      map: [element.map],
      tkAmount: [element.tkAmount],
      equivalentPoint: [element.equivalentPoint]
    });
  }

  initItemRows() {
    return this._fb.group({
      cardTierId: [''],
      map: [''],
      tkAmount: ['', Validators.required],
      equivalentPoint: ['', Validators.required]
    });
  }

  addItemRows() {
    return this._fb.group({
      cardTierId: [''],
      map: [''],
      tkAmount: [''],
      equivalentPoint: ['']
    });
  }

  addNewRow() {
    this.formArr.push(this.addItemRows());
    this.setLoyaltyCardTierMapJsonAndEmit();
  }

  deleteFirstEmptyRow() {
    this.formArr.removeAt(0);
    this.setLoyaltyCardTierMapJsonAndEmit();
  }

  deleteRow(index: number) {
    this.formArr.removeAt(index);
    this.setLoyaltyCardTierMapJsonAndEmit();
  }

  filterData(allData: any, search: string, filterData: any) {
    if (!allData) {
      return;
    }
    if (!search) {
      filterData.next(allData);
      return;
    } else {
      search = search.toLowerCase();
    }
    filterData.next(
      allData.filter(data => data.name.toLowerCase().indexOf(search) > -1)
    );
  }

  getCardTiers() {
    this.loyaltyMerchantService.getAllLoyaltyCardTiers()
      .subscribe(data => {
        this.cardTiers = data;
      });
  }

  setKeyValuesJson(): void {
    var customArray = this.keyValuesForm.controls.itemRows.value;
   

    var aaa: any = [];

    customArray.forEach((element1) => {
      var cardTierId = element1.cardTierId;
      var map = element1.map;
      var tkAmount = element1.tkAmount;
      var equivalentPoint = element1.equivalentPoint;

      var object = {};
      object["cardTierId"] = cardTierId;
      object["map"] = map;
      object["tkAmount"] = tkAmount;
      object["equivalentPoint"] = equivalentPoint;

      aaa.push(object)
    });

    this.keyValuesJson = JSON.stringify(aaa);
  }

  setLoyaltyCardTierMapJsonAndEmit(): void {
    this.setKeyValuesJson();
    this.loyaltyCardTierMapEmitter.emit(this.keyValuesJson);
  }

  get formData() { return <FormArray>this.keyValuesForm.get('itemRows'); }

}
