import { AccountServiceService } from './../../../../services/account-service.service';
import { CompanyService } from './../../../../services/company.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as moment from 'moment';
import { Select2OptionData } from 'ng-select2';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataService } from 'src/app/components/app-send-gift/services/shared/data.service';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { MerchantOutletResponse } from 'src/app/models/view-models/merchant-outlet-response';
import { AlertService } from 'src/app/services';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import {saveAs} from 'file-saver';
@Component({
  selector: 'app-avail-transaction-history',
  templateUrl: './avail-transaction-history.component.html',
  styleUrls: ['./avail-transaction-history.component.css'],
})
export class AvailTransactionHistoryComponent implements OnInit {
  merchantId: any;
  processId: any;
  userInfo: any;
  companyOutletId: any;
  merchantOutlets: any;
  orders: any;
  submitted: boolean;
  p: number = 1;
  offerReportForm: FormGroup;
  defaultStartDate: Date = new Date();
  defaultEndDate: Date = new Date();
  currentPage: number = 1;
  count = 0;
  pageSize = 25;
  pageSizes = [25, 50, 100];
  public defaultTime = [];
  providerList: any;
  merchantList: any;
  availTransactionHistory: any[];

  select2ProviderInfo: Array<Select2OptionData>;
  select2MerchantInfo: Array<Select2OptionData>;

  showIssuerFilter: boolean = true;
  showMerchantFilter: boolean = true;
  isSystemAdmin: boolean = false;
  isMerchantAdmin: boolean = false;
  isOutletAdmin: boolean = false;

  constructor(
    private accountService: AccountServiceService,
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private dialog: MatDialog,
    private companyService: CompanyService
  ) {
    var startDate1 = new Date();
    this.defaultStartDate = this.startOfMonth(startDate1);
    this.defaultEndDate = new Date();
    this.buildForm();
  }

  startOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  ngOnInit(): void {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userInfo = userInfo;
    this.isSystemAdmin =
      this.accountService.getLoggedInUserInfo().isSystemAdmin;
    if (
      !this.isSystemAdmin &&
      this.userInfo.outletQrCode == undefined &&
      this.userInfo.outletQrCode == null
    ) {
      this.isMerchantAdmin = true;
    }
    if (
      !this.isSystemAdmin &&
      this.userInfo.outletQrCode != undefined &&
      this.userInfo.outletQrCode != null
    ) {
      this.isOutletAdmin = true;
    }
    if (this.isOutletAdmin) this.companyOutletId = this.userInfo.outletQrCode;

    this.loadData();

    //check permission filter
    this.loadProvider();
    if (this.isSystemAdmin) this.loadMerchant();
    if (this.isMerchantAdmin) this.getMerchantOutlet();
  }

  setDefaultTime() {
    let d = new Date();
    this.defaultTime = [d.getHours(), d.getMinutes(), d.getSeconds()];
    console.log(this.defaultTime);
  }

  handlePageChange(event: number): void {
    this.p = event;
    this.loadData();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.p = 1;
    this.loadData();
  }

  loadData(reset: boolean = false) {
    this.submitted = true;
    if (reset) this.p = 1;
    let fromDate = moment(this.f.fromDate.value).format('YYYY/MM/DDTHH:mm:ss');
    let todate = moment(this.f.toDate.value).format('YYYY/MM/DDTHH:mm:ss');
    let outletId = this.f.outletId.value;
    if (outletId != 0 && outletId != '') this.companyOutletId = outletId.qrCode;
    let cardNo = this.f.cardNo.value;
    let cardHolderPhoneNo = this.f.cardHolderPhone.value;
    let url = `api/cards/card-transaction?pageSize=${this.pageSize}&pageNumber=${this.p}&fromDate=${fromDate}&toDate=${todate}`;

    if (
      (this.isMerchantAdmin || this.isOutletAdmin) &&
      this.companyOutletId &&
      this.companyOutletId != ''
    ) {
      url += `&outletId=${this.companyOutletId}`;
    }
    if (this.isSystemAdmin) {
      let merchantId = Number(this.f.merchantId.value);
      if (merchantId > 0) url += `&merchantId=${merchantId}`;
    }
    if (this.isMerchantAdmin || this.isOutletAdmin) {
      let merchantId = Number(this.userInfo.companyId);
      if (merchantId > 0) url += `&merchantId=${merchantId}`;
    }
    let issuer = Number(this.f.issuerId.value);
    if (issuer > 0) {
      url += `&issuerId=${issuer}`;
    }

    if (cardNo != '') {
      url += `&cardNumber=${cardNo}`;
    }

    if (cardHolderPhoneNo != '') {
      url += `&cardHolderPhone=${cardHolderPhoneNo}`;
    }

    this.dataService.get(url).subscribe((response: any) => {
      
      if (response.success) {
        this.count = response.data.totalRows;
        this.availTransactionHistory = response.data.data;
      } else {
        this.count = 0;
        this.availTransactionHistory = [];
      }
    });
  }

  buildForm() {
    this.offerReportForm = this.formBuilder.group({
      fromDate: [this.defaultStartDate, Validators.required],
      toDate: [this.defaultEndDate, Validators.required],
      outletId: ['', Validators.nullValidator],
      issuerId: ['', Validators.nullValidator],
      merchantId: ['', Validators.nullValidator],
      cardNo: ['', Validators.nullValidator],
      cardHolderPhone: ['', Validators.nullValidator],
    });
  }

  get f() {
    return this.offerReportForm.controls;
  }

  showConfirmation(item) {
    if (item.hasConsumed) return;
    const confirmationDialogConfig = new MatDialogConfig();
    // Setting different dialog configurations
    confirmationDialogConfig.data = `Would you like to proceed? This operation can not be undone.`;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(
      ConfirmationDialogComponent,
      confirmationDialogConfig
    );

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.sendSms(item);
      }
    });
  }

  sendSms(item) {
    let url = `/api/GiftCard/resend-gift-code/merchant/${this.userInfo.companyId}`;
    let payload = [item.orderNumber];
    this.dataService.post(url, payload).subscribe(
      (response: any) => {
        if (response.status) {
          this.alertService.success('SMS sent successfully');
        } else {
          this.alertService.error('SMS sending failed');
        }
      },
      (err) => {
        console.log('Err msg: ', err);
      }
    );
  }

  getMerchantOutlet() {
    let url = `api/Outlets/company/${this.userInfo.companyId}/`;
    // if(this.companyOutletId != null)
    //   url += `${this.companyOutletId}`
    this.dataService.get(url).subscribe((data: MerchantOutletResponse[]) => {
      if (data != null && data.length > 0) {
        this.merchantOutlets = data;
        console.log(this.merchantOutlets);
      }
    });
  }

  loadProvider() {
    let url = '/api/company/offer-provider/basics';
    this.dataService.get(url).subscribe(
      (data: any) => {
        this.providerList = data;
        this.select2ProviderInfo = this.providerList.map((p) => {
          return {
            id: p.id,
            text: p.name,
          };
        });
        let noMerchant: Select2OptionData = {
          id: '-1',
          text: 'Select Issuer',
        };
        this.select2ProviderInfo.unshift(noMerchant);
      },
      catchError((error) => {
        return throwError('Something went wrong!');
      })
    );
  }

  loadMerchant() {
    this.companyService.getMerchantBasics().subscribe(
      (response) => {
        this.merchantList = response;
        this.select2MerchantInfo = this.merchantList.map((p) => {
          return {
            id: p.id,
            text: p.name,
          };
        });
        let noMerchant: Select2OptionData = {
          id: '-1',
          text: 'Select Merchant',
        };
        this.select2MerchantInfo.unshift(noMerchant);
      },
      (err) => console.log(err)
    );
  }

  exportToExcel(){
    let fromDate = moment(this.f.fromDate.value).format('YYYY/MM/DDTHH:mm:ss');
    let toDate = moment(this.f.toDate.value).format('YYYY/MM/DDTHH:mm:ss');
    let outletId = this.f.outletId.value;
    if (outletId != 0 && outletId != '') this.companyOutletId = outletId.qrCode;
    let cardNo = this.f.cardNo.value;
    let cardHolderPhoneNo = this.f.cardHolderPhone.value;
    let url = "api/cards/privilege-card-avail-history-report"    
    let payload : any = {
      "fromDate": fromDate,
      "toDate": toDate
    }
    if (
      (this.isMerchantAdmin || this.isOutletAdmin) &&
      this.companyOutletId &&
      this.companyOutletId != ''
    ) {
      payload.outletId = this.companyOutletId;
    }
    if (this.isSystemAdmin) {
      let merchantId = Number(this.f.merchantId.value);
      if (merchantId > 0) {
        payload.merchantId = merchantId;
      }

    }
    if (this.isMerchantAdmin || this.isOutletAdmin) {
      let merchantId = Number(this.userInfo.companyId);
      if (merchantId > 0) {
        payload.merchantId = merchantId;
      }
    }
    let issuer = Number(this.f.issuerId.value);
    if (issuer > 0) {
      payload.issuerId = issuer;

    }

    if (cardNo != '') {
      payload.cardNumber = cardNo;
    }

    if (cardHolderPhoneNo != '') {
      payload.cardHolderPhone = cardHolderPhoneNo;
    }
    

    this.dataService.postWithOptions(url, payload, {
      responseType: 'blob'
    }).subscribe((response: any) => {
      this.downloadFile(response);
    }),//console.log(data),
    error => console.log("Error downloading the file."),
    () => console.log('Completed file download.');
  }

  downloadFile(data) {
    const blob = new Blob([data], {
      type: 'ext/csvapplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    var blob1 = new Blob([<any>data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const blob2 = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    var filename =
      'PrivilegeCardAvailHistory' +
      moment().format('MMMM Do YYYY, h:mm:ss').valueOf() +
      '.xlsx';
    saveAs(blob2, filename);
  }
}
