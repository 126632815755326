import { Component, OnInit, Input, SimpleChanges, EventEmitter, Injector } from '@angular/core';
import { Offer } from 'src/app/models/offer';
import { CreateOfferAssignmentComponent } from '../create-offer-assignment/create-offer-assignment.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AlertService, OfferAssignmentService } from 'src/app/services';
import { BaseComponent } from '../base/base.component';
import { DataService } from 'src/app/services';
@Component({
  selector: 'app-offer-assignments',
  templateUrl: './offer-assignments.component.html',
  styleUrls: ['./offer-assignments.component.css']
})
export class OfferAssignmentsComponent extends BaseComponent implements OnInit {

  @Input() offerDetails: Offer;

  offerAssignments: any[];

  expandedIndex: number = -1;

  constructor(private dialog: MatDialog,
    private offerAssignmentService: OfferAssignmentService,
    private alertService: AlertService,
    private dataService: DataService,
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    //this.getOfferAssignmentSummary();
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['offerDetails']){
      setTimeout(()=>{
        if(this.offerDetails != null){
          this.getOfferAssignments(this.offerDetails.id);
        }
        
      });
    }
 }

  createAssignment(offer: Offer){
    const createAssignmentDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    createAssignmentDialogConfig.disableClose = true;
    createAssignmentDialogConfig.autoFocus = true;
    createAssignmentDialogConfig.panelClass = "golp-dialog";

    createAssignmentDialogConfig.data = offer;
    createAssignmentDialogConfig.data.showPhoneNumberSection = true;
    createAssignmentDialogConfig.data.showFileUpload = true;
    createAssignmentDialogConfig.data.bulkCodeGenerate = false;
    createAssignmentDialogConfig.data.title = "New Assignment";

    const createAssignmentDialog = this.dialog.open(CreateOfferAssignmentComponent, createAssignmentDialogConfig);

    const successfullCreate = createAssignmentDialog.componentInstance.onAssignmentEvent.subscribe((data) =>{
      this.getOfferAssignments(data);
      this.offerAssignmentService.offerAssignmentChanged(this.offerDetails.id);
    });

    createAssignmentDialog.afterClosed().subscribe(() => {
    });

  }

  onDeleteAssignment(item){
    const confirmationDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    confirmationDialogConfig.data = "Are you sure to delete the assignment " + item.phoneNumber;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if(result != undefined){
        this.deleteAssignment(item.assignmentId);
      }
    });
  }

  expandCollapse(index){
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }

  getOfferAssignments(id:number){
    this.offerAssignmentService.getOfferAssignments(id)
      .subscribe((data) => {
        this.offerAssignments = data; 
      })
  }

  deleteAssignment(assignmentId: string){
    this.offerAssignmentService.deleteOfferAssignment(assignmentId).subscribe((data) => {
      this.alertService.success('Assignment deleted successfully');
      this.getOfferAssignments(this.offerDetails.id);
      this.offerAssignmentService.offerAssignmentChanged(this.offerDetails.id);
    },
    (error) => {
      //this.alertService.error('Internal server error happen');
      console.log(error);
    });
  }

}
