import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { AcceptRequestModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class GiftAvailService {

  private giftAvailBaseUri = 'api/offer-avail-process';

  constructor(
    private http: HttpClient
  ) { }

  getPendingAvailRequest(qrCode: string): Observable<any[]> {
    var requestHeader = {headers: new HttpHeaders({ 'Content-Type': 'application/json','No-Auth':'False' })};
    return this.http.get<any[]>(`${this.giftAvailBaseUri}/pending-avail-transactions/outlet-qr-code/${qrCode}`, requestHeader);
  }

  acceptAvailRequest(requestDetails: AcceptRequestModel){
    var requestHeader = {headers: new HttpHeaders({ 'Content-Type': 'application/json','No-Auth':'False' })};
    return this.http.put(`${this.giftAvailBaseUri}/accept-avail-request`, requestDetails, requestHeader);
  }

  declineAvailRequest(requestDetails: AcceptRequestModel){
    var requestHeader = {headers: new HttpHeaders({ 'Content-Type': 'application/json','No-Auth':'False' })};
    return this.http.put(`${this.giftAvailBaseUri}/decline-avail-request`, requestDetails, requestHeader);
  }

  getCompletedAvailTransaction(merchantId:number, qrCode: string, fromDate: string, toDate: string, invoiceNo: string, phoneNo: string): Observable<any> {
    const requestHeader = {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' })};
    var url = this.giftAvailBaseUri + '/completed-avail-transactions/merchant/'+ merchantId +'/from-date/' + fromDate + '/to-date/' + toDate;
    if(qrCode != undefined && qrCode!= null )
      url += `?outletQrCode=${qrCode}`;
    if(phoneNo != undefined && phoneNo != null)
      if(qrCode != undefined && qrCode!= null )
        url += `&PhoneNumber=${phoneNo}`;
      else
        url += `?PhoneNumber=${phoneNo}`;
    if(invoiceNo != undefined && invoiceNo != null)
      if((phoneNo != undefined && phoneNo != null) || (qrCode != undefined && qrCode!= null ))
        url += `&InvoiceNumber=${invoiceNo}`;
      else url += `?InvoiceNumber=${invoiceNo}`;
    //const url = `${this.giftAvailBaseUri}/completed-avail-transactions/outlet-qr-code/${qrCode}/from-date/${fromDate}/to-date/${toDate}`;

    return this.http.get<any[]>(url, requestHeader);
  }
}
