import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TrendingMerchant, GetTrendingMerchantsPagedListInput } from '../models';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TrendingMerchantService {
  private baseUri = 'api/trending-merchant';
    
  requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };

  constructor(private http: HttpClient) {
  }

  getTrendinMerchantPagedList(input: GetTrendingMerchantsPagedListInput): Observable<any> {
    return this.http.post<any[]>(this.baseUri + '/paged-list', input, this.requestHeader);
  }

  getAllTrendingMerchants(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUri + '/all', this.requestHeader);
  }


  createTrendingMerchant(company: TrendingMerchant) {
    return this.http.post(this.baseUri, company, this.requestHeader);
  }

  editTrendingMerchant(company: TrendingMerchant) {
    return this.http.put(this.baseUri, company, this.requestHeader);
  }

  deleteTrendingMerchant(trendingMerchantId: number) {
    return this.http.delete(this.baseUri + '/' + trendingMerchantId, this.requestHeader);
  }
}
