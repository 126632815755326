import * as moment from 'moment';


export class GetGiftGenerationReportInputDto {
    from: string;
    to:string;
    //isActive:boolean;
    type: any;
    pageNumber:number;
    pageSize :number;

    public constructor(init?: Partial<GetGiftGenerationReportInputDto>){
        Object.assign(this, init);
    }
}
