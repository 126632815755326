import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PromotionalGiftSetting, Company } from '../../../models';
import { ReplaySubject } from 'rxjs';
import { CompanyService } from '../../../services';

@Component({
  selector: 'app-create-exclude-merchants-setting',
  templateUrl: './create-exclude-merchants-setting.component.html',
  styleUrls: ['./create-exclude-merchants-setting.component.css']
})
export class CreateExcludeMerchantsSettingComponent implements OnInit {
    promotionalGiftSlabs: any;

    @Output() excludeMerchantEmitter: EventEmitter<any> = new EventEmitter<any>();


    constructor(private formBuilder: FormBuilder, private companyService: CompanyService) {
        this.buildForm();
    }

    excludeMerchantSettingForm: FormGroup;
    @Input() isSubmitted: false;

    submitted = false;

    @Input() existingExcludeMerchantSetting: any;
    showSlabSection: boolean = true;

    promotionalGiftSetting: PromotionalGiftSetting = new PromotionalGiftSetting();
    excludeMerchants: any;

    merchants: Company[];

    excludeMerchantsFilterCtrl: FormControl = new FormControl();
    filteredExcludeMerchants: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);

    ngOnInit() {
        if (this.existingExcludeMerchantSetting) {

            this.excludeMerchants = this.existingExcludeMerchantSetting.split(',').map(function (item) {
                return parseInt(item, 10);
            });

            this.excludeMerchantSettingForm.patchValue({
                excludeMerchants: this.excludeMerchants
            });
        }
        else {
        }



        this.getMerchants();
        this.bindFilterChangeEvent();

        this.setExcludeMerchantAndEmit();
    }

    ngOnChanges() {
        this.submitted = this.isSubmitted;

    }

    buildForm() {
        this.excludeMerchantSettingForm = this.formBuilder.group({
            excludeMerchants: ['', Validators.required]
        });
    }

    bindFilterChangeEvent() {
        this.excludeMerchantsFilterCtrl.valueChanges
            .subscribe(() => {
                this.filterData(this.merchants, this.excludeMerchantsFilterCtrl.value, this.filteredExcludeMerchants);
            });
    }

    filterData(allData: any, search: string, filterData: any) {
        if (!allData) {
            return;
        }
        if (!search) {
            filterData.next(allData);
            return;
        } else {
            search = search.toLowerCase();
        }
        filterData.next(
            allData.filter(data => data.name.toLowerCase().indexOf(search) > -1)
        );
    }

    getMerchants() {
        this.companyService.getMerchants()
            .subscribe(data => {
                this.merchants = data;
                this.filteredExcludeMerchants.next(this.merchants);
            });
    }

    onChangeMerchant($event) {
        this.setExcludeMerchantAndEmit();
    }


    get f() { return this.excludeMerchantSettingForm.controls; }


    setExcludeMerchant() {
        this.excludeMerchants = this.f.excludeMerchants.value;
    }


    setExcludeMerchantAndEmit() {
        this.setExcludeMerchant();
        this.excludeMerchantEmitter.emit(this.excludeMerchants);
    }


}
