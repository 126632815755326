import { Component, OnInit, Injector } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { CreateOfferComponent } from '../create-offer/create-offer.component';
import { Offer, GetOffersPagedListInput } from 'src/app/models';
import { CompanyRole } from 'src/app/enums';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { OfferService, CompanyService, AccountServiceService, AlertService, CommonService } from 'src/app/services';
import { BaseComponent } from '../base/base.component';

@Component({
    selector: 'app-offers',
    templateUrl: './offers.component.html',
    styleUrls: ['./offers.component.css']
})
export class OffersComponent extends BaseComponent implements OnInit {

    userInfo: any;
    dataLoaded: Promise<boolean>;

    // permission related property

    //showAssignmentTab: boolean = true;

    currentPage: number = 1;
    pageSize: number = 10;
    itemCountText: string;
    totalItems: number;

    pageTitle: string = "Gifts Creation & Assignment";
    offerProviderSelection: string = "Gift Senders";
    merchantSelection: string = "Merchant";

    offers: any[];
    offersPrimitiveList: any[];
    offerFilter: any = { name: '' };
    giftFilterText: string;

    selectedOffer: Offer;
    selectedOfferName: string;
    selectedOfferIndex: number = -1;

    merchants: any[];
    merchantFilter: any = { name: '' };
    searchedMerchantLogo: string = null;
    showMerchantSearch: boolean = false;
    selectedMerchantId: number = 0;

    offerProviders: any[];
    offerProviderFilter: any = { name: '' };
    searchOfferProviderLogo: string = null;
    showOfferProviderSearch: boolean = false;
    selectedOfferProviderId: number = 0;

    offerSubMenu: any[] = [];
    activeSubMenu: string = 'Details';
    selectedSubMenuIndex: number = 0;
    hasToShowOfferTextImg = false;
    isMerchantLoggedIn: boolean = false;

    constructor(private offerService: OfferService, private dialog: MatDialog,
        private companyService: CompanyService,
        private accountService: AccountServiceService,
        private alertService: AlertService,
        private commonService: CommonService,
        injector: Injector
    ) { super(injector) }

    ngOnInit() {
        if (this.checkPermission(['OfferRead']))
            this.offerSubMenu.push({ "name": "Details", "dataTarget": "offerDetailsTab" });
        if (this.checkPermission(['OfferAssignmentRead'])) {
            this.offerSubMenu.push({ "name": "Assignments", "dataTarget": "offerAssignmentsTab" });
        }
        const settings = JSON.parse(localStorage.getItem('_bulkCodeGenerateSetting'));
        if(this.checkPermission(['OfferAssignmentRead']) && settings === true) {
            this.offerSubMenu.push({ "name": "BulkCode", "dataTarget": "BulkCodeTab" });
        }

        this.getLoggedInUser();
        this.getOffers();
        this.getMerchants();
        this.getOfferProviders();
        this.dataLoaded = Promise.resolve(true);
    }

    createOffer() {
        const dialogConfig = new MatDialogConfig();

        // Setting different dialog configurations
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'golp-dialog';
        const offerCreateDialog = this.dialog.open(CreateOfferComponent, dialogConfig);

        const successfullCreate = offerCreateDialog.componentInstance.onOfferCreateEvent.subscribe((data) => {
            this.getOffers();
        });

        offerCreateDialog.afterClosed().subscribe(() => {
        });
    }

    onDeleteOffer(selectedOffer: Offer): void {
        const confirmationDialogConfig = new MatDialogConfig();

        // Setting different dialog configurations
        confirmationDialogConfig.data = "Are you sure to delete the gift " + selectedOffer.name;
        confirmationDialogConfig.panelClass = 'golp-dialog';
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

        confirmationDialog.afterClosed().subscribe((result) => {
            if (result != undefined) {
                this.deleteOffer(selectedOffer);
            }
        });
    }

    onOfferChange(offer, index) {
        if (this.selectedOfferIndex != index) {
            this.selectedOfferIndex = index === this.selectedOfferIndex ? -1 : index;
            this.selectedOffer = offer;
            this.setOutletScopeText();
        }
        let body = document.getElementsByTagName('body')[0];
        body.classList.add("display-tab-content");
        this.commonService.itemSelectionUpdated(this.selectedOffer);
    }

    selectAllOfferProvider() {
        this.offerProviderSelection = 'Gift Senders';
        this.searchOfferProviderLogo = null;
        this.selectedOfferProviderId = 0;
        this.getOffers();
    }

    selectAllMerchant() {
        this.searchedMerchantLogo = null;
        this.merchantSelection = 'Merchant';
        this.selectedMerchantId = 0;
        this.getOffers();
    }

    onOfferProviderChange(offerProvider, index) {
        this.selectedOfferProviderId = offerProvider.id;
        this.offerProviderSelection = offerProvider.name.split(' ').slice(0, 2).join(' ');
        this.searchOfferProviderLogo = offerProvider.companyLogoUrl;

        this.getOffers();
    }

    onMerchantChange(merchant, index) {
        this.selectedMerchantId = merchant.id;
        this.searchedMerchantLogo = merchant.companyLogoUrl;
        this.merchantSelection = merchant.name.split(' ').slice(0, 2).join(' ');

        this.getOffers();
    }

    onSubMenuChange(name, index) {
        if (this.selectedSubMenuIndex != index) {
            this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
            this.activeSubMenu = this.offerSubMenu.filter(c => c.name === name)[0].name;
        }
    }

    // capture child component change event
    getOfferDetailsChange(event) {
        let offer = this.offers.filter(c => c.id === event.id)[0];
        offer.offerImageUrl = event.offerImageUrl;
        offer.name = event.name;
        this.selectedOffer = offer;
    }

    giftFilteredItem(value) {
        this.selectedOfferName = value;
        this.getOffers();
    }

    getOffers() {
        var input = new GetOffersPagedListInput();

        if (this.selectedMerchantId == 0)
            input.merchantId = null;
        else
            input.merchantId = this.selectedMerchantId;

        if (this.selectedOfferProviderId == 0)
            input.giftSenderId = null;
        else
            input.giftSenderId = this.selectedOfferProviderId;

        input.giftName = this.selectedOfferName;
        input.pageNumber = this.currentPage;
        input.pageSize = this.pageSize;


        this.offerService.getOffersPagedList(input)
            .subscribe(data => {
                //this.offers = data;
                //this.offersPrimitiveList = data.viewModel;
                this.offers = data.viewModel;
                //this.giftCopy();
                this.totalItems = data.rowCount;

                if (CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
                    this.offers = this.offers.filter(c => c.merchantId === this.userInfo.companyId);
                    this.pageTitle = 'Gifts';
                }
                if (CompanyRole.GiftSender === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
                    this.offers = this.offers.filter(c => c.offerProviderId === this.userInfo.companyId);
                }

                this.highlightFirstItem(this.offers);
                this.itemCount();
            });
    }

    //getOfferById(id: number) {
    //    this.offerService.getOfferById(id).subscribe((offer) => {
    //        this.selectedOffer = offer;
    //    });
    //}

    getMerchants() {
        this.companyService.getMerchantBasics()
            .subscribe(data => {
                this.merchants = data;

                if (data.length > 1 || !(CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin)) {
                    this.showMerchantSearch = true;
                }
            });
    }

    getOfferProviders() {
        this.companyService.getOfferProviderBasics()
            .subscribe(data => {
                this.offerProviders = data;

                // if (data.length > 1 || !(CompanyRole.GiftSender === this.userInfo.companyRole && !this.userInfo.isSystemAdmin)) {
                //     this.showOfferProviderSearch = true;
                // }
                if (this.userInfo.isSystemAdmin) {
                    this.showOfferProviderSearch = true;
                }
            });
    }

    deleteOffer(offer: Offer) {
        this.offerService.deleteOffer(offer.id).subscribe((data) => {
            this.alertService.success('Gift deleted successfully');

            if (data == true) { // will reload data if only this item is deleted
                this.getOffers();
            }
        },
            (error) => {
                this.alertService.error(error.error.error[0]);
                console.log(error);
            });
    }

    getLoggedInUser() {
      this.userInfo = this.accountService.getLoggedInUserInfo();
      if (CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
        this.isMerchantLoggedIn = true;
      }
        // if (CompanyRole.GiftSender === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
        //   this.showOfferProviderSearch = false;
        // }

        //if (CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
        //  this.showMerchantSearch = false;
        //  this.showAssignmentTab = false;
        //  this.offerSubMenu = this.offerSubMenu.filter(function (item) {
        //    if (item.name === 'Assignments') {
        //      return false;
        //    }
        //  });
        //}
    }

    highlightFirstItem(offers: any[]) {
        if (this.offers !== null) {
            this.selectedOffer = this.offers[0];
            this.setOutletScopeText();
            this.selectedOfferIndex = 0;
            this.dataLoaded = Promise.resolve(true);
        }
    }

    giftCopy() {
        this.offers = Object.assign([], this.offersPrimitiveList);
    }

    pageChanged(event) {
        this.selectedOfferIndex = 0;
        this.currentPage = event;
        this.getOffers();
        this.setOutletScopeText();
    }

    itemCount() {
        let firstItem = (this.currentPage - 1) * this.pageSize + 1;
        let lastItem = (this.currentPage) * this.pageSize;
        if (lastItem > this.totalItems)
            lastItem = this.totalItems;
        this.itemCountText = firstItem + '-' + lastItem + ' of ' + this.totalItems + ' Gifts';
    }

    setSelectedOffer(offers) {
        if (offers.length > 0) {
            this.selectedOfferIndex = 0;
            this.selectedOffer = offers[0];
            this.setOutletScopeText();
        }
        else {
            this.selectedOffer = null;
        }
    }

    setOutletScopeText() {
        this.selectedOffer.offerScope = this.selectedOffer.isOutletSpecific ? 'Selected Outlets' : 'All Outlets';
    }

    errorHandlerOnImg(event, offerId) {
        document.getElementById('merchantImgLogotxt_' + offerId).style.display = "block";
        document.getElementById('merchantImgLogo_' + offerId).style.display = "none";
        //this.hasToShowText = true;
    }

    onMarkAsFavorite(offer: any, status) {
        offer.markAsFavorite = status;

        this.offerService.markOfferAssignmentAsFavorite(offer).subscribe((data) => {
            var message = "Gift marked as favourite successfully.";
            if (status == true) {
                message = "Gift marked as favourite successfully.";
            }
            else {
                message = "Gift unmarked as favourite successfully.";
            }
            this.alertService.success(message);
            //this.getOfferById(offer.id);
        });
    }
}
