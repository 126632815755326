import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountServiceService } from '../../services';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DataService } from '../app-send-gift/services/shared/data.service';
@Component({
  selector: 'app-redemption-otp',
  templateUrl: './redemption-otp.component.html',
  styleUrls: ['./redemption-otp.component.css']
})
export class RedemptionOtpComponent implements OnInit {

  @BlockUI('login-with-otp-section') blockUI: NgBlockUI;

  submitted = false;
  onValidOtpEvent: EventEmitter<any> = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private dialogRef: MatDialogRef<RedemptionOtpComponent>,
    @Inject(MAT_DIALOG_DATA) data, private dataService: DataService,
    private router: Router) {

    console.log(data);

    this.phoneNumber = data;
  }

  phoneNumber: any;
  acceptOtpForm: FormGroup;
  oneTimePassword: any;
  invalidPin: boolean = false;
  errorMsg: any = "Please enter your valid PIN";

  isSuccess:boolean = false;

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.acceptOtpForm = this.formBuilder.group({
      pin: [this.oneTimePassword, [Validators.required]],
    });
  }

  onContinue() {
    this.submitted = true;
    this.invalidPin = false;

    if (!this.oneTimePassword || this.oneTimePassword.length < 6) {
      this.invalidPin = true;
      return;
    }

    var payload = {
      "oneTimePassword": this.oneTimePassword,
      "phoneNumber": this.phoneNumber
    };
    this.verifyOtp(payload);
  }

  verifyOtp(payload) {
    let url = "api/OfferAssignments/verify-redemption-otp";

    this.blockUI.start();
    this.dataService.post(url, payload)
      .subscribe((result: any) => {
        this.blockUI.stop();
        if (!result.success) {
          this.invalidPin = true;
          this.isSuccess = false;
          this.errorMsg = result.data;
        }
        else{
          this.isSuccess = true;
          this.invalidPin = false;
          this.errorMsg = "";
          this.close();
          this.onValidOtpEvent.next(true);
        }
      },
        (error: any) => {
          this.blockUI.stop();
          this.invalidPin = true;
          this.errorMsg = error;
        })
  }


  close(result:boolean = false) {
    this.dialogRef.close(result);
  }

  get f() { return this.acceptOtpForm.controls; }


  onOtpChange(otp) {
    this.oneTimePassword = otp;
  }

}
