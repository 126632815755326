import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, GiftTemplateService, CompanyService } from '../../services';
import { GiftTemplateInputDto, Company } from '../../models';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-create-gift-template',
  templateUrl: './create-gift-template.component.html',
  styleUrls: ['./create-gift-template.component.css']
})

export class CreateGiftTemplateComponent implements OnInit {

  // onCreate event
  onCreategiftTemplateEvent: EventEmitter<any> = new EventEmitter();
  onEditgiftTemplateEvent: EventEmitter<any> = new EventEmitter();

  giftTemplateForm: FormGroup;
  submitted = false;
  offerAssignmentData: FormData;
  assignmentFiles: FormData;
  inputData: GiftTemplateInputDto;
  amount: number;
  name: string="";
  totalAssociations: number;
  expirationMonthType: any;
  expirationMonthTypes: any[] = [];
  merchantId?: number;
  isInEditMode: boolean = false;
  willPrint: boolean = false;
  merchants: Company[] = [];
  giftTemplateIds: any[] = [];
  merchantFilterCtrl: FormControl = new FormControl();
  filteredMerchants: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);


  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateGiftTemplateComponent>,
    private alertService: AlertService,
    private companyService: CompanyService,
    private giftTemplateService: GiftTemplateService,
    @Inject(MAT_DIALOG_DATA) data) {

    if (data !== null) {
      this.inputData = data;
      this.isInEditMode = true;
    }
    else {
      this.inputData = new GiftTemplateInputDto();
      this.isInEditMode = false;
    }
    this.expirationMonthTypes = [{ text: '1', value: 1 }, { text: '3', value: 3 }, { text: '6', value: 6 }, { text: '12', value: 12 }];

    this.giftTemplateForm = this.formBuilder.group({
      name: [this.name, [Validators.required]],
      amount: [this.amount, [Validators.required, Validators.min(1)]],
      merchantId: [this.merchantId],
      totalAssociations: [this.totalAssociations],
      expirationMonthType: [this.expirationMonthType, Validators.required ],
    });
  }

  ngOnInit() {
    this.getMerchants();
  }

  onSubmit() {
    this.submitted = true;

    this.validateForm();

    if (this.giftTemplateForm.invalid) {
      return;
    }

    this.dialogRef.close();     // to close the modal

    if (!this.inputData.id) {
      if (!this.f.totalAssociations) {
        return;
      }
      this.createGiftTemplate();
    }
    else {
      this.editGiftTemplate();
    }
  }

  onSubmitAndPrint() {
    this.willPrint = true;
    this.onSubmit();
  }

  validateForm() {
    const createTotalGiftTemplateControl = this.giftTemplateForm.get('totalAssociations');
    if (!this.isInEditMode) {
      createTotalGiftTemplateControl.setValidators([Validators.required]);
      createTotalGiftTemplateControl.updateValueAndValidity();
    }
  }

  onChangeMerchant(merchantId) {
    this.merchantId = merchantId;
  }

  createGiftTemplate() {
    this.inputData = new GiftTemplateInputDto();
    this.inputData.name = this.f.name.value;
    this.inputData.amount = this.f.amount.value;
    this.inputData.merchantId = this.f.merchantId.value;
    this.inputData.expirationMonthType = this.f.expirationMonthType.value;
    this.inputData.totalAssociations = this.f.totalAssociations.value;
    //this.inputData.userId = this.userId;
    this.giftTemplateService.createGiftTemplate(this.inputData)
      .subscribe((data: any) => {
        this.onCreategiftTemplateEvent.emit();

        this.alertService.success('Gift template created successfully');

        if (this.willPrint) {
          var ids = [];

          data.forEach(function (value) {
            ids.push(value.id);
          });

          this.giftTemplateIds = ids;

          this.printGiftTemplates(this.giftTemplateIds);
        }


      }, (error: any) => {
      });
  }

  editGiftTemplate() {
    this.inputData = new GiftTemplateInputDto();
    this.inputData.name = this.f.name.value;
    this.inputData.amount = this.f.amount.value;
    this.inputData.merchantId = this.f.merchantId.value;
    this.inputData.expirationMonthType = this.f.expirationMonthType.value;


    this.giftTemplateService.editGiftTemplate(this.inputData)
      .subscribe((data: any) => {
        this.onEditgiftTemplateEvent.emit(data);
        this.alertService.success('Gift template edited successfully');
      }, (error: any) => {
      });
  }


  printGiftTemplates(giftTemplateIds: any[]) {
    var newWindow = window.open('', "_blank");

    var input = { giftTemplateIds: this.giftTemplateIds };
    this.giftTemplateService.printGiftTemplates(input)
      .subscribe((data: any) => {
        if (data != null) {
          //window.open(data.printGiftUrl, '_blank');
          newWindow.location.href = data.printGiftUrl;

        }
      });
  }

  close() {
    this.dialogRef.close();
  }


  getMerchants() {
    this.companyService.getMerchants()
      .subscribe(data => {
        this.merchants = data;
        this.filteredMerchants.next(this.merchants);
      });
  }


  bindFilterChangeEvent() {
    this.merchantFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterData(this.merchants, this.merchantFilterCtrl.value, this.filteredMerchants);
      });
  }

  filterData(allData: any, search: string, filterData: any) {
    if (!allData) {
      return;
    }
    if (!search) {
      filterData.next(allData);
      return;
    } else {
      search = search.toLowerCase();
    }
    filterData.next(
      allData.filter(data => data.name.toLowerCase().indexOf(search) > -1)
    );
  }

  // convenience getter for easy access to form fields
  get f() { return this.giftTemplateForm.controls; }

}


