import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private paymentBaseUri = 'api/payment';

  constructor(private http: HttpClient) { }

  initCardPayment(paymentSession: any) {
    let requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' }) };
    return this.http.post(this.paymentBaseUri + '/ssl-gateway-payment', paymentSession, requestHeader);
  }
  initGolpCreditPayment(paymentSession: any) {
    let requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' }),
                          };
    return this.http.post(this.paymentBaseUri + '/init-credit-payment', paymentSession, requestHeader);
  }

  initWalletPayment(paymentSession: any) {
    let requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' }) };
    return this.http.post(this.paymentBaseUri + '/init-wallet-payment', paymentSession, requestHeader);
  }

  // bKash Payment
  initbKashPayment(request: any) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.paymentBaseUri + '/init-bkash-payment', request, requestHeader);
  }

  executebKashPayment(request: any) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.paymentBaseUri + '/execute-bkash-payment', request, requestHeader);
  }
}
