import { Injector } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CompanyRole } from 'src/app/enums';
import { AccountServiceService, AlertService, CommonService } from 'src/app/services';
import { DataService } from '../app-send-gift/services/shared/data.service';
import { BaseComponent } from '../base/base.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { CreateProductComponent } from './create-product/create-product.component';

@Component({
  selector: 'app-merchant-products',
  templateUrl: './merchant-products.component.html',
  styleUrls: ['./merchant-products.component.css']
})
export class MerchantProductsComponent extends BaseComponent implements OnInit {

  userInfo: any;
  dataLoaded: Promise<boolean>;

  currentPage: number = 1;
  pageSize: number = 10;
  itemCountText: string;
  totalItems: number;

  pageTitle: string = "Products";

  products: any[];
  productsPrimitiveList: any[];
  productFilter: any = { productName: '' };
  productFilterText: string;

  selectedProduct: any;
  selectedProductName: string;
  selectedProductIndex: number = -1;

  productSubMenu: any[] = [];
  activeSubMenu: string = 'Details';
  selectedSubMenuIndex: number = 0;
  constructor(private dataService: DataService,
    private dialog: MatDialog,
    private accountService: AccountServiceService,
    private alertService: AlertService,
    private commonService: CommonService,
    injector: Injector
  ) { super(injector) }

  ngOnInit() {
    //if (this.checkPermission(['SystemAdmin']))
    this.productSubMenu.push({ "name": "Details", "dataTarget": "productDetailsTab" });

    this.getLoggedInUser();
    this.getProducts();
    this.dataLoaded = Promise.resolve(true);
  }

  createProduct() {
    const dialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'golp-dialog';
    const productCreateDialog = this.dialog.open(CreateProductComponent, dialogConfig);

    productCreateDialog.componentInstance.onProductCreateEvent.subscribe((data) => {
      this.selectedProduct = data;
      this.getProducts();
    });

    productCreateDialog.componentInstance.onProductEditEvent.subscribe((data) => {
      this.selectedProduct = data;
      this.getProducts();
    });

    productCreateDialog.afterClosed().subscribe(() => {
    });
  }

  onDeleteProduct(selectedProduct: any): void {
    const confirmationDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    confirmationDialogConfig.data = "Are you sure to delete the product " + selectedProduct.name;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.deleteProduct(selectedProduct);
      }
    });
  }

  onProductChange(product, index) {
    if (this.selectedProductIndex != index) {
      this.selectedProductIndex = index === this.selectedProductIndex ? -1 : index;
      this.selectedProduct = product;
    }
    let body = document.getElementsByTagName('body')[0];
    body.classList.add("display-tab-content");
    this.commonService.itemSelectionUpdated(this.selectedProduct);
  }

  onSubMenuChange(name, index) {
    if (this.selectedSubMenuIndex != index) {
      this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
      this.activeSubMenu = this.productSubMenu.filter(c => c.name === name)[0].name;
    }
  }

  getproductDetailsChange(event) {
    this.getProducts();
  }

  productFilteredItem(value) {
    this.selectedProductName = value;
    this.getProducts();
  }

  getProducts() {
    // var input: any = {};

    // if (this.selectedMerchantId == 0)
    //   input.merchantId = null;
    // else
    //   input.merchantId = this.selectedMerchantId;

    // input.giftName = this.selectedProductName;
    // input.pageNumber = this.currentPage;
    // input.pageSize = this.pageSize;


    this.dataService.get(`api/merchant/products/${this.userInfo.companyId}`)
      .subscribe((result: any) => {
        this.productsPrimitiveList = result.data;
        this.productCopy();
        this.totalItems = result?.data?.length;

        if (CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
          this.products = this.products.filter(c => c.merchantId === this.userInfo.companyId);
        }

        this.highlightFirstItem(this.products);
        this.itemCount();
      });
  }



  deleteProduct(product: any) {
    this.dataService.delete(`api/merchant/delete-product/${this.userInfo.companyId}/${product.id}`).subscribe((data: any) => {
      if (data.success == true) {
        this.alertService.success('Product deleted successfully.');
        this.getProducts();
      }
    },
      (error) => {
        this.alertService.error(error.error.error[0]);
        console.log(error);
      });
  }

  getLoggedInUser() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
  }

  highlightFirstItem(products: any[]) {
    console.log("triggerrred first item.");
    if (this.products !== null) {
      if(this.selectedProduct == null){
        this.selectedProduct = this.products[0];
        this.selectedProductIndex = 0;
      }
      else {
        this.selectedProduct = this.products.find(x=>x.id == this.selectedProduct.id);
        this.selectedProductIndex = this.products.findIndex(x=>x.id == this.selectedProduct.id);
      }
      
      this.dataLoaded = Promise.resolve(true);
      
    }
  }

  highlightItem(products: any[]) {
    if (products !== null) {
    }
  }

  pageChanged(event) {
    this.selectedProductIndex = 0;
    this.currentPage = event;
    this.getProducts();
  }

  itemCount() {
    let firstItem = (this.currentPage - 1) * this.pageSize + 1;
    let lastItem = (this.currentPage) * this.pageSize;
    if (lastItem > this.totalItems)
      lastItem = this.totalItems;
    this.itemCountText = firstItem + '-' + lastItem + ' of ' + this.totalItems + ' Products';
  }

  setselectedProduct(products) {
    if (products.length > 0) {
      this.selectedProductIndex = 0;
      this.selectedProduct = products[0];
    }
    else {
      this.selectedProduct = null;
    }
  }

  productCopy() {
    this.products = Object.assign([], this.productsPrimitiveList);
  }
}