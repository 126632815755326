import { offerfiltertype } from "../enums/offer-filter-type";


export class GetOffersPagedListInput {
  giftName:string;
  merchantId?: number;
  giftSenderId?: number;
  offerFilterType?: offerfiltertype;
  consumerId?: any;
  pageNumber: number;
  pageSize: number;
  
  public constructor(init?: Partial<GetOffersPagedListInput>) {
    Object.assign(this, init);
  }
}
