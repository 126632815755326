export class UserCredit {
  id: any;
  userId: number;
  amount: number;
  createdOn: any;
  status: string;
  isActive: boolean;
  public constructor(init?: Partial<UserCredit>) {
    Object.assign(this, init);
  }
}
