export class GiftUsageLimitSetting {
  maxLimitPerTransaction : number;
  maxGiftsPerDay: number;
  maxGiftAmountPerDay: number;
  maxGiftsPerMonth: number;
  maxGiftAmountPerMonth: number;
  maxGiftSendToSameReceiver: number;
  public constructor(init?: Partial<GiftUsageLimitSetting>) {
    Object.assign(this, init);
  }
}
