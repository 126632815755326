import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
declare var jQuery: any;
@Component({
  selector: 'app-send-gift-popular',
  templateUrl: './send-gift-popular.component.html',
  styleUrls: ['./send-gift-popular.component.css']
})
export class SendGiftPopularComponent implements OnInit {
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  @Input() popularGifts: any[];

  popularGiftsData: any[];
  apiBaseUri: any;

  constructor(private router: Router) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['popularGifts']) {
      this.popularGiftsData = this.popularGifts;
    }
  }

  ngOnInit() {
    configureSlick();
  }

  onSendPopularGift(item: any) {
    console.log("clicked..........");
    let giftInfo = {
      "amount": item.productPrice,
      "isPopularGift": true
    };

    let navigationEgolps: NavigationExtras = {
      state: {
        transd: 'popularGift',
        workQueue: false,
        services: 1,
        code: giftInfo
      }
    };

    this.router.navigate(['send-gift-information'], navigationEgolps);
  }

  onViewAllPopularGifts() {
    this.router.navigate(['popular-gifts']);
  }

}

function configureSlick() {
  (function ($) {
    $(document).ready(function () {
      $('.popular-list').not('.slick-initialized').slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        centerMode: true,
        //variableWidth: true,
        centerPadding: "0px",
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              variableWidth: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              variableWidth: true
            }
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ],
        prevArrow: '<button type="button" style="color:black" class="slick-prev"><i class="fas fa-arrow-left"></i></button>',
        nextArrow: '<button type="button" style="color:black" class="slick-next"><i class="fas fa-arrow-right"></i></button>'
      });
    });

  })(jQuery);
}
