import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  //notificationItems: any[];

  constructor(private notificationService: NotificationService) { }

  ngOnInit() {
    //this.getNotification();
  }

  // getNotification(){
  //   this.notificationService.getNotifications(1,10)
  //     .subscribe(data => {
  //       this.notificationItems = data;
  //     });
  // }

}
