import { Component, OnInit, Input } from '@angular/core';
import { NotificationService } from 'src/app/services';

@Component({
  selector: 'app-notification-items',
  templateUrl: './notification-items.component.html',
  styleUrls: ['./notification-items.component.css']
})
export class NotificationItemsComponent implements OnInit {

  @Input() showPagination: boolean = true;

  notificationItems: any[];

  currentPage: number = 1;
  pageSize: number = 10;
  totalCount: number;

  constructor(private notificationService: NotificationService) { }

  ngOnInit() {
    this.getNotification(this.currentPage, this.pageSize);
  }

  getNotification(currentPage: number, pageSize: number){
    this.notificationService.getNotifications(currentPage, pageSize)
      .subscribe((data:any) => {
        this.notificationItems = data.data;
        this.totalCount = data.rowCount;
        console.log(data);

      });
  }

  pageChanged(event){
    this.getNotification(event, this.pageSize);
    this.currentPage = event;
  }
  onOfferLogoImgErrEvent(event, notificationId) {
    document.getElementById('notifcationImgText_' + notificationId).style.display = "block";
    document.getElementById('notifcationImg_' + notificationId).style.display = "none";
  }
}
