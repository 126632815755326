import { Component, OnInit, Input, Injector } from '@angular/core';
import { Company, UserModel, LoyaltyMerchantSetting } from '../../models';
import {
  AlertService,
  AccountServiceService,
  CompanyService,
  LoyaltyMerchantService,
} from '../../services';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { CreateUserComponent } from '../create-user/create-user.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { CompanySettingService } from '../../services/company-setting.service';
import { CreateCompanySettingComponent } from '../create-company-setting/create-company-setting.component';
import { CompanySetting } from '../../models/company-setting';
import { OfferAvailMethodService } from '../../services/offer-avail-method.service';
import { OfferAvailMethod } from '../../models/offer-avail-method';
import { Subject } from 'rxjs';
import { BaseComponent } from '../base/base.component';
import { CompanyRole } from '../../enums';
import { SharedService } from '../../services/shared.service';
import { JsonViewerComponent } from '../json-viewer/json-viewer.component';

@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.css'],
})
export class CompanySettingsComponent extends BaseComponent implements OnInit {
  @Input() providerDetails: Company;

  @Input() companyRole: CompanyRole = CompanyRole.None;

  settings: CompanySetting[] = [];
  companyId: any;
  mssg: string = 'Aplectrum';
  offerAvailMethods: OfferAvailMethod[];
  companyPaymentOnTypes: any[];
  merchantAcceptableMethodNames = '';

  companySettingTypes: { text: string; value: number }[];
  constructor(
    private companySettingService: CompanySettingService,
    private loyaltyMerchantService: LoyaltyMerchantService,
    private dialog: MatDialog,
    private sharedService: SharedService,
    private companyService: CompanyService,
    private alertService: AlertService,
    private offerAvailMethodService: OfferAvailMethodService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {}

  ngOnChanges() {
    this.getSettingsByCompany();
    this.getMerchantAvailInstructionSetting();
    this.companySettingTypes = this.sharedService.getCompanySettingTypes();

    this.companyPaymentOnTypes = this.sharedService.getCompanyPaymentOnTypes();
  }

  getMerchantAvailInstructionSetting() {
    var companyId = this.providerDetails ? this.providerDetails.id : null;

    if (this.companyRole == CompanyRole.Merchant && companyId) {
      this.companyService
        .getMerchantAvailInstructionsByCompanyId(companyId)
        .subscribe((result) => {
          var availInstructionSetting = new CompanySetting();
          availInstructionSetting.companyId = companyId;
          availInstructionSetting.displayName = 'Avail Instruction';
          availInstructionSetting.key = 16;
          availInstructionSetting.value = JSON.stringify(result.data);
          availInstructionSetting.displayValue = JSON.stringify(result.data);

          this.settings.push(availInstructionSetting);
        });
    }
  }

  getSettingsByCompany() {
    var companyId = this.providerDetails ? this.providerDetails.id : null;

    if (this.companyRole == CompanyRole.LoyaltyMerchant) {
      this.loyaltyMerchantService
        .getAllLoyaltyMerchantSettings(companyId)
        .subscribe((data) => {
          this.settings = data;
          this.setLoyaltySettings();
        });
    } else {
      this.companySettingService
        .getSettingsByCompany(companyId)
        .subscribe((data) => {
          this.settings = data;
          this.setSettingValues();
        });
    }
  }

  setLoyaltySettings() {
    this.settings.forEach((setting) => {
      setting.displayValue = setting.value;

      if (setting.loyaltySettingsKey == 1) {
        setting.displayName = 'Loyalty Card Tier Map';
      } else if (setting.loyaltySettingsKey == 2) {
        setting.displayName = 'Loyalty Point Setting';
      }
    });
  }

  setSettingValues() {
    this.settings.forEach((setting) => {
      if (setting.key == 1) {
        this.getAllOfferAvailMethodsAndSetName(setting);
      } else if (setting.key == 5) {
        setting.displayValue = this.companyPaymentOnTypes.filter(
          (x) => x.value == setting.value
        )[0].text;
      } else if (setting.key == 7) {
        this.getMerchantsAndSetNameForPromotionalGift(setting);
      } else if (setting.key == 8) {
        this.getMerchantsAndSetNameForMerchants(setting);
      } else if (setting.key == 12) {
        this.getMerchantsAndSetNameForMerchants(setting);
      } else {
        setting.displayValue = setting.value;
      }
    });
  }

  getAllOfferAvailMethodsAndSetName(setting) {
    this.offerAvailMethodService
      .GetAllOfferAvailMethods()
      .subscribe((result) => {
        var ids = setting.value.split(',');
        var nameArray = [];
        this.offerAvailMethods = result;

        ids.forEach((id) => {
          var offerAvailMethod = this.offerAvailMethods.find(
            (i) => i.id == +id
          );
          if (offerAvailMethod) {
            nameArray.push(offerAvailMethod.methodName);
          }
        });

        this.merchantAcceptableMethodNames = nameArray.join(',');
        setting.displayValue = this.merchantAcceptableMethodNames;
      });
  }

  getMerchantsAndSetNameForPromotionalGift(setting) {
    this.companyService.getMerchants().subscribe((data) => {
      var merchants = data;

      var promotionalGift = JSON.parse(setting.value);

      var eligibleMerchants = promotionalGift.eligibleMerchants;

      //var ids = eligibleMerchants.split(',');
      var nameArray = [];

      eligibleMerchants.forEach((id) => {
        var merchant = merchants.find((i) => i.id == +id);
        if (merchant) {
          nameArray.push(merchant.name);
        }
      });

      var merchantNames = nameArray.join(',');
      promotionalGift.eligibleMerchants = merchantNames;
      setting.displayValue = JSON.stringify(promotionalGift);
    });
  }

  getMerchantsAndSetNameForMerchants(setting) {
    this.companyService.getMerchants().subscribe((data) => {
      var merchants = data;

      var ids = setting.value.split(',');
      var nameArray = [];

      ids.forEach((id) => {
        var merchant = merchants.find((i) => i.id == +id);
        if (merchant) {
          nameArray.push(merchant.name);
        }
      });

      var merchantNames = nameArray.join(',');
      setting.displayValue = merchantNames;
    });
  }

  addSetting(model: any) {
    const companySettingDialogConfig = new MatDialogConfig();
    var companyId = model && model.id ? model.id : undefined;
    // Setting different dialog configurations
    companySettingDialogConfig.disableClose = true;
    companySettingDialogConfig.autoFocus = true;
    companySettingDialogConfig.data = {
      id: companyId,
      companyRole: this.companyRole,
      isCreateAction: true,
    };
    companySettingDialogConfig.panelClass = 'golp-dialog';

    const companySettingCreateDialog = this.dialog.open(
      CreateCompanySettingComponent,
      companySettingDialogConfig
    );

    const successfullCreate =
      companySettingCreateDialog.componentInstance.onSettingCreateEvent.subscribe(
        (data) => {
          this.getSettingsByCompany();
          this.getMerchantAvailInstructionSetting();
        }
      );

    companySettingCreateDialog.afterClosed().subscribe(() => {});
  }

  editSetting(model: CompanySetting) {
    model.companyRole = this.companyRole;

    const editDialogConfig = new MatDialogConfig();
    // Setting different dialog configurations
    editDialogConfig.disableClose = true;
    editDialogConfig.autoFocus = true;
    editDialogConfig.data = model;
    editDialogConfig.panelClass = 'golp-dialog';

    const companySettingEditDialog = this.dialog.open(
      CreateCompanySettingComponent,
      editDialogConfig
    );

    const successfullEdit =
      companySettingEditDialog.componentInstance.onSettingCreateEvent.subscribe(
        (data) => {
          this.getSettingsByCompany();
          this.getMerchantAvailInstructionSetting();
        }
      );

    companySettingEditDialog.afterClosed().subscribe(() => {});
  }

  onDeleteSetting(model: CompanySetting): void {
    const confirmationDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    //confirmationDialogConfig.data = "Are you sure to delete this setting " + model.key;
    confirmationDialogConfig.data = 'Are you sure to delete this setting ';
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(
      ConfirmationDialogComponent,
      confirmationDialogConfig
    );

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.deleteSetting(model);
      }
    });
  }

  onDeleteLoyaltySetting(model: LoyaltyMerchantSetting): void {
    const confirmationDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    //confirmationDialogConfig.data = "Are you sure to delete this setting " + model.key;
    confirmationDialogConfig.data = 'Are you sure to delete this setting ';
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(
      ConfirmationDialogComponent,
      confirmationDialogConfig
    );

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.deleteLoyaltySetting(model);
      }
    });
  }

  deleteSetting(model: CompanySetting) {
    if (model.key == 16) {
      // Company Setting key =16 will be saved on company table
    } else {
      this.companySettingService.deleteSetting(model.id).subscribe(
        (data) => {
          this.alertService.success('Setting deleted successfully');
          this.settings = this.settings.filter(function (item) {
            if (item.id !== model.id) {
              return true;
            } else {
              return false;
            }
          });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  deleteLoyaltySetting(model: LoyaltyMerchantSetting) {
    this.loyaltyMerchantService.deleteLoyaltyMerchantSetting(model).subscribe(
      (data) => {
        this.alertService.success('Loyalty Setting deleted successfully');
        this.settings = this.settings.filter(function (item) {
          if (item.id !== model.id) {
            return true;
          } else {
            return false;
          }
        });
      },
      (error) => {
        //this.alertService.error('Internal server error happen');
        console.log(error);
      }
    );
  }

  viewJsonUi(input, title) {
    if (!this.isJson(input)) return;
    const userDialogConfig = new MatDialogConfig();
    let data = {
      data: input,
      title: title,
    };
    userDialogConfig.disableClose = true;
    userDialogConfig.autoFocus = true;
    userDialogConfig.data = data;
    userDialogConfig.panelClass = 'golp-dialog';
    userDialogConfig.minWidth = '100vw';
    // userDialogConfig.maxHeight="80vw";
    userDialogConfig.height = '100%';

    const showOfferAssignmentDialog = this.dialog.open(
      JsonViewerComponent,
      userDialogConfig
    );
  }

  isJson(str) {
    if (str == null || str == undefined || str.length < 2) return false;
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  print(data) {
    console.log('log : ', data);
  }

  getBooleanInfo(input) {
    return input == '1' ? 'On' : 'Off';
  }
}
