import { PaymentMethod } from "../enums/payment-method.enum";
import { CompanyRole } from "../enums/company-role.enum";
import { CompanyType } from "../enums";

export class Company {
  id: any;
  name: string;
  shortName: string;
  phone: string;
  email: string;
  address: string;
  webAddress: string;
  imageName: string;
  companyCategoryId: number;
  paymentMethod: PaymentMethod;
  companyRole: CompanyRole;
  companyLogo: FormData;
  isGeneralDiscountEligible: boolean;
  companyLogoUrl: string;
  companyCategory: string;
  salesCommission: number;
  salesCommissionType?: any;
  maxDiscountHolder: number;
  isActive: boolean;
  companyType: CompanyType;

  public constructor(init?: Partial<Company>) {
    Object.assign(this, init);
  }
}
