import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-company-qr-code',
  templateUrl: './company-qr-code.component.html',
  styleUrls: ['./company-qr-code.component.css']
})
export class CompanyQrCodeComponent implements OnInit {

  outletQrCode:string = null;

  constructor(private dialogRef: MatDialogRef<CompanyQrCodeComponent>,
    @Inject(MAT_DIALOG_DATA) data) { 

      if(data !== null){
        this.outletQrCode = data.qrCode;
        console.log(data);
        console.log(data.qrCode);
      }
    }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close();
  }

}
