export class AcceptRequestModel {
    id: string;
    availDateLocal: Date;
    availDateUtc: Date;
    consumerId: string;
    consumerPhoneNumber: string;
    merchantId: number;
    merchantLogo: string;
    merchantName: string;
    offerAmount: number;
    offerAssignmentId: string;
    offerAvailStatus: number;
    offerId: number;
    outletName: string;
    outletQrCode: string;
    purchaseAmount: number;
    salesCommission: number;
    minimumPurchaseAmount: number;
    public constructor(init?: Partial<AcceptRequestModel>){
        Object.assign(this, init);
    }
}
