import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ValidationUtility } from '../../utility/validation.utility';
import { OfferService, AlertService } from '../../services';

@Component({
  selector: 'app-split-offer',
  templateUrl: './split-offer.component.html',
  styleUrls: ['./split-offer.component.css']
})
export class SplitOfferComponent implements OnInit {
  @Output() onOfferSplitEvent: EventEmitter<any> = new EventEmitter();

  offerForm: FormGroup;
  splittedOfferList: FormArray;
  offer: any;
  currentOfferPrice: number;
  giftSplitSettings: any;
  userInfo: any;
  userPhoneNumber: string;
  ownedByConsumerId: any;
  submitted = false;

  constructor(private formBuilder: FormBuilder, private offerService: OfferService,
    private dialogRef: MatDialogRef<SplitOfferComponent>, private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) data) {
    this.offer = data;
    this.currentOfferPrice = this.offer.offerPrice;
  }

  ngOnInit() {
    this.getLoggedInUser();
    this.getGiftSplitSettings();

    this.offerForm.valueChanges.subscribe((data) => {
      this.submitted = false;
    });
  }
  getLoggedInUser() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userInfo = userInfo;
    this.userPhoneNumber = userInfo.phoneNumber;
    this.ownedByConsumerId = userInfo.consumerId;
  }
  buildForm() {
    this.offerForm = this.formBuilder.group({
      ownedByConsumerId: [this.ownedByConsumerId],
      userId: [this.userInfo.id],
      splitFromOfferId: [this.offer.offerId],
      phoneNumber: [this.userPhoneNumber],
      offerPrice: [this.offer.offerPrice],
      splittedOffers: this.formBuilder.array([this.splitOfferForm()])
    });
    this.splittedOfferList = this.offerForm.get('splittedOffers') as FormArray;
  }
  splitOfferForm(): FormGroup {
    return this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(250)]],
      offerPrice: [, [Validators.required, Validators.min(this.giftSplitSettings.minNewGiftValue), Validators.pattern('^\\d+(\\.\\d{1,2})?$'), Validators.pattern('^[1-9]+[0-9]*$')]]
    });
  }
  splitOffer() {
    this.splittedOfferList.push(this.splitOfferForm());
  }
  removeSplittedOffer(index) {
    this.splittedOfferList.removeAt(index);
  }

  close() {
    this.dialogRef.close();
  }
  get splitOfferFormGroup() {
    return this.offerForm.get('splittedOffers') as FormArray;
  }

  getGiftSplitSettings() {
    this.giftSplitSettings = new GiftSplitSetting();
    this.offerService.getOfferSplitSetting().subscribe((data: GiftSplitSetting) => {
      this.giftSplitSettings = data;
      this.buildForm();
    });
    //this.giftSplitSettings.currentGiftMinValue = 200;
    //this.giftSplitSettings.minNewGiftValue = 50;
    //this.giftSplitSettings.maxSplit = 3;
  }
  onSubmit() {

    this.submitted = true;
    const formData = new FormData();

    // stop here if form is invalid
    if (this.offerForm.invalid) {
      //this.submitted = false;
      ValidationUtility.getError_Scroll(document);
      return;
    }
    if (this.giftSplitSettings.currentGiftMinValue > this.offer.offerPrice) {
      this.alertService.error("You can't split gift as price is less than current gift min value");
      this.dialogRef.close();
      return;
    }
    if (this.offerForm.value.splittedOffers.some(obj => obj.offerPrice < this.giftSplitSettings.minNewGiftValue)) {
      this.alertService.error("Price should be more than or equal " + this.giftSplitSettings.minNewGiftValue);
      this.dialogRef.close();
      return
    }
    var total = 0;
    for (var i = 0; i < this.offerForm.value.splittedOffers.length; i++) {
      var spOffer = this.offerForm.value.splittedOffers[i];
      total = total + parseInt(spOffer.offerPrice);
    }
    if (total != this.currentOfferPrice) {
      this.alertService.error("Total amount of splitted gift price must same as gift price ");
      this.dialogRef.close();
      return
    }
    this.offerService.splitOffer(this.offerForm.value)
      .subscribe((data: any) => {
        console.log(data);
        this.onOfferSplitEvent.emit(true);
        this.dialogRef.close();
      },
        (error: any) => {
          this.showErrorMessage(error);
        });
    //this.submitted = false;
  }
  showErrorMessage(error: any) {
    console.log(error);
  }
  getsplitOfferFormGroup(index){
    this.splittedOfferList = this.offerForm.get('splittedOffers') as FormArray;
    const formGroup = this.splittedOfferList.controls[index] as FormGroup;
    return formGroup.controls;
  }
}
export class GiftSplitSetting {
  currentGiftMinValue: number;
  minNewGiftValue: number;
  maxSplit: number

  public constructor(init?: Partial<GiftSplitSetting>) {
    Object.assign(this, init);
  }

}
