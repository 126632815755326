import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/services';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import { DataService } from '../../app-send-gift/services/shared/data.service';

@Component({
  selector: 'app-add-offer-merchant',
  templateUrl: './add-offer-merchant.component.html',
  styleUrls: ['./add-offer-merchant.component.css']
})
export class AddOfferMerchantComponent implements OnInit {

  // onCreate event
  onAddOfferMerchantEvent: EventEmitter<any> = new EventEmitter();

  inputData: any;

  addMerchantForm: FormGroup;
  submitted: boolean = false;
  isActive: boolean = false;

  merchantsVM: any[];
  merchantList: any;
  merchant: any;
  isEditMode: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddOfferMerchantComponent>,
    private dataService: DataService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) data) {

    if (data !== null) {
      this.inputData = data.offerProvider;
      this.merchant = data.merchant;
      this.isEditMode = this.merchant == null ? false : true;
    }

    this.addMerchantForm = this.formBuilder.group({
      merchants: [this.merchant == null ? '' : this.merchant.merchantId, Validators.required],
      maxDiscount: [this.merchant == null ? '' : this.merchant.maxDiscount, Validators.required],
      validFrom: [this.merchant == null ? '' : this.merchant.validFrom, Validators.required],
      validTill: [this.merchant == null ? '' : this.merchant.validTill, Validators.required],
      isActive: [this.merchant == null ? this.isActive : this.merchant.isActive],
    });

  }

  ngOnInit() {
    this.loadMerchants();
  }

  loadMerchants() {
    let url = '/api/company/merchant/basics';
    this.dataService.get(url).subscribe((data: any) => {
      this.merchantList = data;
      this.merchantsVM = this.merchantList.map((i) => {
        return {
          merchantId: i.id,
          merchantName: i.name,
          show: true
        };
      });
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.addMerchantForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    if (this.isEditMode) {
      this.editMerchant();
    } else {
      this.addMerchant();
    }


  }

  addMerchant() {
    let payload = {
      "offerProviderId": this.inputData.id,
      'maxDiscount': this.addMerchantForm.get('maxDiscount').value,
      "validFrom": this.addMerchantForm.get('validFrom').value,
      "validTill": this.addMerchantForm.get('validTill').value,
      "isActive": this.addMerchantForm.get('isActive').value,
      "merchants": this.addMerchantForm.get('merchants').value
    };

    this.dataService.post("api/offermerchant", payload)
      .subscribe((response: any) => {
        console.log("response here : ", response);
        if (response.success) {
          this.onAddOfferMerchantEvent.emit(this.inputData.id);
          this.alertService.success('Merchant added successfully.');
          this.dialogRef.close();
        }
        if (!response.success) {
          this.alertService.error("Failed to add merchant.");
        }
      },
        () => {
        });
  }

  editMerchant() {
    let payload = {
      "offerMerchantId": this.merchant.id,
      'maxDiscount': this.addMerchantForm.get('maxDiscount').value,
      "validFrom": this.addMerchantForm.get('validFrom').value,
      "validTill": this.addMerchantForm.get('validTill').value,
      "isActive": this.addMerchantForm.get('isActive').value,
      "merchantId": this.addMerchantForm.get('merchants').value
    };

    this.dataService.post("api/offermerchant/update-merchant", payload)
      .subscribe((response: any) => {
        console.log("response here : ", response);
        if (response.success) {
          this.onAddOfferMerchantEvent.emit(this.inputData.id);
          this.alertService.success('Merchant updated successfully.');
          this.dialogRef.close();
        }
        if (!response.success) {
          this.alertService.error("Failed to update merchant.");
        }
      },
        () => {
        });
  }


  onKey(value) {
    this.merchantsVM.forEach(m => {
      if (m.merchantName.toLowerCase().includes(value.toLowerCase())) {
        m["show"] = true;
      }
      else {
        m["show"] = false;
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  // convenience getter for easy access to form fields
  get f() { return this.addMerchantForm.controls; }

}
