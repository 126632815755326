import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Role } from '../models/role';


@Injectable({
  providedIn: 'root'
})

export class RolesService {

  private rolesBaseUrl = 'api/roles';

  constructor(private http: HttpClient) { }

  getRoles(): Observable<any[]> {
    return this.http.get<any[]>(this.rolesBaseUrl);
  }

  getRoleById(roleId: number): Observable<any[]> {
    return this.http.get<any[]>(this.rolesBaseUrl  + roleId);
  }

  createRole(role: Role) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.rolesBaseUrl, role, requestHeader);
  }

  editRole(role: Role) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.put(this.rolesBaseUrl, role, requestHeader);
  }

  deleteRole(roleId: number) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.delete(this.rolesBaseUrl + '/' + roleId, requestHeader);
  }
  getFilteredRoles(): Observable<any[]> {
    return this.http.get<any[]>(this.rolesBaseUrl + '/filtered-roles');
  }
}

