import { CorporateOfferType } from './corporate-offer-type';
import { OfferValueType } from './offer-value-type';

export class CorporateOffer {
  id: any;
  offerProviderId: number;
  offerProvider: string;
  providerShortName: string;
  providerImagePath: string;
  merchantId: number;
  merchant: string;
  merchantShortName: string;
  merchantImagePath: string;

  offerType: CorporateOfferType;
  offerName: string;
  offerImagePath: string;
  shortName: string;
  offerCode: string;
  offerValueType: any;
  offerValue: any;
  validFrom: Date | string | null;
  validTill: Date | string | null;
  description: string;
  commercialMessage: string;
  offerBrandingImage: string;
  isActive: boolean;

  public constructor(init?: Partial<CorporateOffer>) {
    Object.assign(this, init);
  }
}
