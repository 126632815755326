import { Component, OnInit } from '@angular/core';
import { ReportService } from 'src/app/services/report.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
import { GiftTransactionReport } from '../../models/gift-transacion-report';
import { IndividualGiftReportInput, IndividualGiftReportOutput } from '../../models';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ShowOfferAssignmentDetailsComponent } from '../show-offer-assignment-details/show-offer-assignment-details.component';
import { SharedService } from '../../services/shared.service';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { ValidationUtility } from '../../utility/validation.utility';
import { PrintService } from '../../services/print.service';

@Component({
  selector: 'app-individual-gift-report',
  templateUrl: './individual-gift-report.component.html',
  styleUrls: ['./individual-gift-report.component.css']
})


export class IndividualGiftReportComponent implements OnInit {
  @BlockUI('individual-gift-report-section') blockUI: NgBlockUI;

  individualGiftReportForm: FormGroup;
  input: IndividualGiftReportInput;
  types: any[];

  currentPage: number = 1;
  pageSize: number = 25;
  expandedIndex: number = -1;
  totalItems: number = 0;
  submitted: boolean;

  defaultStartDate: Date;
  defaultEndDate: Date;

  paginationPageNumbers: any[];
  currentPageNumberDetails: string;


  constructor(private formBuilder: FormBuilder, private reportService: ReportService,
    private dialog: MatDialog, private sharedService: SharedService, private printService: PrintService) {
    this.types = [{ text: 'All', value: 0 }, { text: 'Sent', value: 1 }, { text: 'Received', value: 2 }, { text: 'Consumed', value: 3 }];
    this.input = new IndividualGiftReportInput();

    var startDate1 = new Date();
    //startDate1.setDate(startDate1.getDate() - 7);
    this.defaultStartDate = startDate1;
    this.defaultEndDate = new Date();
    this.buildForm();
  }

  ngOnInit() {
    this.paginationPageNumbers = this.sharedService.getPaginationByPageNumbers();
    //this.getAllIndividualGiftTransactionReports();
  }

  giftTransactionReports: IndividualGiftReportOutput[] = [];


  buildForm() {
    //fromDate: [this.defaultStartDate, Validators.required],
    //  toDate: [this.defaultEndDate, Validators.required],
    this.individualGiftReportForm = this.formBuilder.group({
      fromDate: '',
      toDate: '',
      type: [0, Validators.required],
      phoneNumber: ['', Validators.required]
    });
  }


  expandCollapse(index) {
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }

  saveAllAsHtmlGiftTemplateAssociation(giftTemplateId: any) {
    var ids = [];
    ids.push(giftTemplateId);

    var input = { offerAssignmentIds: ids };

    this.printService.saveAsHtmlIndividualGifts(input)
      .subscribe((data: any) => {
        if (data != null) {
          var newwindow = window.open('', "_blank");
          newwindow.location.href = data.htmlPath;
        }
      });
  }



  saveIndividualGiftAsHtml(offerAssignmentDtos :any) {
    var ids = [];

    offerAssignmentDtos.forEach(function (value) {
      ids.push(value.id);
    });

    var input = { offerAssignmentIds: ids };

    this.printService.saveAsHtmlIndividualGifts(input)
      .subscribe((data: any) => {
        if (data != null) {
          var newwindow = window.open('', "_blank");
          newwindow.location.href = data.htmlPath;
        }
      });
  }



  printIndividualGiftAsPdf(offerAssignmentDtos: any) {
    var ids = [];

    offerAssignmentDtos.forEach(function (value) {
      ids.push(value.id);
    });

    var input = { offerAssignmentIds: ids, printAsPdf: true };

    this.printService.printIndividualGifts(input)
      .subscribe((data: any) => {
        if (data != null) {
          var newWindow = window.open('', "_blank");
          newWindow.location.href = data.printGiftPath;
        }
      });
  }



  getAllIndividualGiftTransactionReports() {
    this.submitted = true;
    if (this.individualGiftReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    this.input.from = this.f.fromDate.value ? moment(this.f.fromDate.value).format("YYYY/MM/DD") : this.f.fromDate.value;
    this.input.to = this.f.toDate.value ? moment(this.f.toDate.value).format("YYYY/MM/DD") : this.f.toDate.value;
    this.input.type = this.f.type.value;
    this.input.phoneNumber = this.f.phoneNumber.value;
    this.input.pageNumber = this.currentPage;
    this.input.pageSize = this.pageSize;

    this.blockUI.start();

    this.reportService.getIndividualGiftReportQuery(this.input).subscribe(result => {
      this.giftTransactionReports = result.data;
      this.totalItems = result.rowCount;
      this.currentPageNumberDetails = "(Showing " + result.firstRowOnPage + " to " + result.lastRowOnPage + " of " + result.rowCount + " items)"
      this.blockUI.stop();

    }, error => {
      this.blockUI.stop();
    });
  }

  showOfferAssignments(model: any) {
    const userDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    userDialogConfig.disableClose = true;
    userDialogConfig.autoFocus = true;
    userDialogConfig.data = model;
    userDialogConfig.panelClass = 'golp-dialog';

    const showOfferAssignmentDialog = this.dialog.open(ShowOfferAssignmentDetailsComponent, userDialogConfig);

    //showOfferAssignmentDialog.componentInstance.companyRoleType = this.companyRoleType;
  }

  get f() { return this.individualGiftReportForm.controls; }


  exportToExcel() {
    this.submitted = true;
    if (this.individualGiftReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    this.input.from = moment(this.f.fromDate.value).format("YYYY/MM/DD");
    this.input.to = moment(this.f.toDate.value).format("YYYY/MM/DD");
    this.input.type = this.f.type.value;
    this.input.pageNumber = this.currentPage;
    this.input.pageSize = this.pageSize;


    this.reportService.getIndividualGiftExcelReport(this.input)
      .subscribe(data => this.downloadFile(data)),//console.log(data),
      error => console.log("Error downloading the file."),
      () => console.log('Completed file download.');
  }


  downloadFile(data) {
    var filterText = this.types.filter(x => x.value == this.f.type.value)[0].text;
    const blob2 = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var filename = filterText + "-" + "IndividualGiftReports -" + moment().format('MMMM Do YYYY, h:mm:ss').valueOf() + ".xlsx";
    saveAs(blob2, filename);
  }

  pageChanged(event) {
    this.currentPage = event;
    this.getAllIndividualGiftTransactionReports();
  }

  itemCount() {
    let firstItem = (this.currentPage - 1) * this.pageSize + 1;
    let lastItem = (this.currentPage) * this.pageSize;
  }
}

