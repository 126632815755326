import { Component, OnInit, NgZone, Injector } from '@angular/core';
import { CompanyService, CommonService, OfferService, BillingService, OfferAssignmentService, DataService } from 'src/app/services';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CreateInvoiceComponent } from '../create-invoice/create-invoice.component';
import { AddTransactionComponent } from '../add-transaction/add-transaction.component';
import { PaymentSessionComponent } from '../payment-session/payment-session.component';
import { PaymentSession } from 'src/app/models/payment-session';
import { PaymentType, CompanyRole } from "src/app/enums";
import { Company, Invoice, GetInvoiceInput, GetTransactionInput, GetUnbilledOfferForCompanyInputDto } from 'src/app/models';
import { PaymentHolder } from 'src/app/enums/payment-holder';
import { AlertService, AccountServiceService } from 'src/app/services';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from '../base/base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { PaymentOptionsComponent } from '../payment-options/payment-options.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
    selector: 'app-billings',
    templateUrl: './billings.component.html',
    styleUrls: ['./billings.component.css'],
    animations: [
        trigger('detailExpand', [
            state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('*', style({ height: '*', visibility: 'visible' })),
            transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class BillingsComponent extends BaseComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;


    dataLoaded: boolean = false;
    invoiceCreateEventEmitter = new BehaviorSubject<boolean>(this.dataLoaded);
    billingForm: FormGroup;
    p: number = 1;
    userInfo: any;
    companies: any[];
    unBilledOffersWithoutFilter: any[];
    unBilledOffers: any[];
    nonPaidInvoices: any[];
    paidInvoices: any[];
    invoiceDetails: any[];
    transactionHistories: any[];
    companyFilter: any = { name: '' };
    offerFilter: any = { offerName: '' };
    invoiceFilter: any = { id: '' };
    transactionHistoriesFilter: any = { id: '' };
    paidInvoiceFilter: any = { id: '' };
    selectedCompany: Company;
    selectedCompanyName: string;
    selectedInvoiceId: number;
    selectedCompanyBalance: number;
    isShownUnbilledTab: boolean = false;
    currentBalanceText: string;
    isMerchantSelected: boolean;
    isProviderAdmin: boolean = false;

    providerSubMenu: any[] = [];
    selectedSubMenuIndex: number = 0;
    activeSubMenu: string = "Unbilled";
    selectedCompanyTypeText: string = "All";
    paymentTypes =
        {
            1: 'Golp To Gift Sender', 2: 'Gift Sender To Golp', 3: 'Golp To Merchant',
            4: 'Merchant To Golp', 5: 'Golp To Consumer', 6: 'Consumer To Golp'
        };
    transactionTypes =
        {
            1: 'Cash', 2: 'Checque', 3: 'Bank', 4: 'BKash',
            5: 'Rocket', 6: 'Card', 7: 'Invoice', 8: 'Golp Credit'
        };


    currentPage: number = 1;
    pageSize: number = 10;
    itemCountText: string;
    selectedCompanyIndex: number = 0;
    merchantId?: number;
    outletQRCode?: string;
    outletName?: string;
    hideMerchantSelectionSection: boolean = false;

    unbilledOffersCurrentPage: number = 1;
    unbilledOffersPageSize: number = 10;
    unbilledOffersExpandedIndex: number = -1;
    unbilledOffersTotalItems: number = 0;

    nonPaidInvoicesCurrentPage: number = 1;
    nonPaidInvoicesPageSize: number = 10;
    nonPaidInvoicesExpandedIndex: number = -1;
    nonPaidInvoicesTotalItems: number = 0;

    paidInvoicesCurrentPage: number = 1;
    paidInvoicesPageSize: number = 10;
    paidInvoicesExpandedIndex: number = -1;
    paidInvoicesTotalItems: number = 0;


    transactionCurrentPage: number = 1;
    transactionPageSize: number = 10;
    transactionExpandedIndex: number = -1;
    transactionTotalItems: number = 0;

    unbillFromDate: any;
    unbillToDate: any;
    dueFromDate: any;
    dueToDate: any;
    paidFromDate: any;
    paidToDate: any;
    transactionsFromDate: any;
    transactionsToDate: any;

    isSystemAdminOrSalesManager: boolean = false;
    isSystemAdmin: boolean = false;
    isMerchantLoggedIn: boolean = false;

    constructor(private companyService: CompanyService,
        private formBuilder: FormBuilder,
        private offerService: OfferService,
        private billingService: BillingService,
        private commonService: CommonService,
        public dialog: MatDialog,
        private accountService: AccountServiceService,
        private alertService: AlertService,
        private assignmentService: OfferAssignmentService,
        private route: ActivatedRoute,
        private router: Router,
        private zone: NgZone,
        private dataService: DataService,
        private injector: Injector) {
        super(injector);
        this.captureCreateInvoiceEvent();
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    }

    ngOnInit() {
        this.getLoggedInUser();
        this.billingForm = this.formBuilder.group({
            unbillFromDate: this.unbillFromDate,
            unbillToDate: this.unbillToDate,
            dueFromDate: this.dueFromDate,
            dueToDate: this.dueToDate,
            paidFromDate: this.paidFromDate,
            paidToDate: this.paidToDate,
            transactionsFromDate: this.transactionsFromDate,
            transactionsToDate: this.transactionsToDate
        });

        this.route
            .queryParams
            .subscribe(params => {
                // Defaults to 0 if no query param provided.
                this.merchantId = +params['merchantId'];
                this.outletQRCode = params['outletQRCode'];
                this.outletName = params['outletName'];
            });

        if (this.merchantId) {
            this.hideMerchantSelectionSection = true;
        }

        this.providerSubMenu.push({ "name": "Unbilled", "dataTarget": "unbilledTransactionsTab" });
        this.providerSubMenu.push({ "name": "Due", "dataTarget": "dueInvoicesTab" });
        this.providerSubMenu.push({ "name": "Paid", "dataTarget": "paidInvoicesTab" });
        this.providerSubMenu.push({ "name": "Transaction History", "dataTarget": "transactionHistoryTab" });

        //if (this.userInfo.isSystemAdmin) {
        if (!this.isSystemAdminOrSalesManager) {
            this.getCompaniesByRoleType(CompanyRole.None);
        }
        else {
            if (this.userInfo.companyRole == CompanyRole.GiftSender) {
                this.isProviderAdmin = true;
            }
            this.getCompaniesByRoleType(this.userInfo.companyRole);
        }
        this.commonService.backgroundJobEventReceiver()
            .subscribe((data) => {
                if (data.jobType === 'AddTransaction') {
                    if (data.model.status === 'Success') {
                        //this.alertService.success("Transaction has been added successfully for " + data.model.name);
                        this.getNonPaidInvoices();
                        this.getPaidInvoices();
                        this.getCompanyCurrentBalance();
                        this.getTransactionHistories();
                    } else {
                        this.alertService.success("Transaction is failed for " +
                            data.model.name +
                            '. Please contact with Golp admin.');
                    }
                }

                if (data.jobType === 'CreateInvoice') {
                    if (data.model.status === 'Success') {
                        //this.alertService.success("Invoice has been Created successfully for " + data.model.name);
                        this.getNonPaidInvoices();
                        this.getPaidInvoices();
                        this.getUnbilledOffers();
                        this.getCompanyCurrentBalance();
                        this.getTransactionHistories();
                        this.dataLoaded = true;
                        this.invoiceCreateEventEmitter.next(this.dataLoaded);
                    } else {
                        this.alertService.success("Invoice creation is failed for " +
                            data.model.name +
                            '. Please contact with Golp admin.');
                    }
                }

            },
                (error) => {
                    this.alertService.error(error.error);
                });
    }

    captureCreateInvoiceEvent() {
        this.commonService.getCreatedItem().subscribe((data: any) => {
            if (data.success && data.item == 'invoice') {
                this.getNonPaidInvoices();
                this.getPaidInvoices();
                this.getUnbilledOffers();
                this.getCompanyCurrentBalance();
                this.getTransactionHistories();
            }
        });
    }

    createInvoice() {
        const dialogConfig = new MatDialogConfig();

        let url = `api/MerchantBilling/unbilled-records?merchantId=${this.selectedCompany.id}&allRecord=true`;
        this.dataService.get(url)
            .subscribe((result: any) => {
                // Setting different dialog configurations
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.panelClass = 'golp-dialog';
                dialogConfig.data = { offers: result.records, selectedCompany: this.selectedCompany, outletQRCode: this.outletQRCode };
                const createInvoiceDiag = this.dialog.open(CreateInvoiceComponent, dialogConfig);
                const successfullInvoice = createInvoiceDiag.componentInstance.onInvoiceCreateEvent.subscribe((data) => {
                    this.alertService.success("Invoice generation initiated for " + this.selectedCompany.name);
                });
            }, () => {
            });


        // this.offerService.getOffersForBilling(this.selectedCompany.id, this.selectedCompany.companyRole, this.outletQRCode)
        //     .subscribe(data => {
        //         // Setting different dialog configurations
        //         dialogConfig.disableClose = true;
        //         dialogConfig.autoFocus = true;
        //         dialogConfig.panelClass = 'golp-dialog';
        //         dialogConfig.data = { offers: data, selectedCompany: this.selectedCompany, outletQRCode: this.outletQRCode };
        //         const createInvoiceDiag = this.dialog.open(CreateInvoiceComponent, dialogConfig);
        //         const successfullInvoice = createInvoiceDiag.componentInstance.onInvoiceCreateEvent.subscribe((data) => {
        //             this.alertService.success("Invoice generation initiated for " + this.selectedCompany.name);
        //         });
        //     });
    }

    addTransaction() {
        const dialogConfig = new MatDialogConfig();
        // Setting different dialog configurations
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'golp-dialog';
        dialogConfig.data = { companyId: this.selectedCompany.id, companyRole: this.selectedCompany.companyRole, userInfo: this.userInfo, outletQRCode: this.outletQRCode, isSystemAdminOrSalesManager: this.isSystemAdminOrSalesManager };
        const addTransactionDialog = this.dialog.open(AddTransactionComponent, dialogConfig);
        const successFullEdit = addTransactionDialog.componentInstance.onTransactionCreateEvent.subscribe((data) => {
            this.alertService.success("Transaction initiated for " + this.selectedCompany.name);
        });
    }

    startPayment() {
        const dialogConfig = new MatDialogConfig();
        // Setting different dialog configurations
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'golp-dialog';
        var paymentSession = new PaymentSession();
        paymentSession.companyId = this.selectedCompany.id;
        paymentSession.amount = this.selectedCompanyBalance;
        paymentSession.payThrough = 3;
        if (this.userInfo.companyRole == CompanyRole.Merchant) {
            paymentSession.paymentHolder = PaymentHolder.Merchant;
        } else {
            paymentSession.paymentHolder = PaymentHolder.OfferProvider;
        }
        dialogConfig.data = paymentSession;
        this.dialog.open(PaymentSessionComponent, dialogConfig);
    }

    startPaymentForInvoice(invoice: Invoice) {
        var paymentSession = new PaymentSession();
        paymentSession.companyId = this.selectedCompany.id;
        paymentSession.amount = invoice.amount;
        paymentSession.invoiceId = invoice.id;
        paymentSession.paymentHolder = PaymentHolder.OfferProvider;
        paymentSession.payThrough = 3;

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'golp-dialog';
        dialogConfig.data = { isInvoiceBillPayment: true, data: paymentSession };

        this.zone.run(() => {
            const paymentOptionDialog = this.dialog.open(PaymentOptionsComponent, dialogConfig);
            const successfullCreate = paymentOptionDialog.componentInstance.onOptionSelectionEvent.subscribe((data) => {
            });

            paymentOptionDialog.afterClosed().subscribe(() => {
            });
        });
    }

    getUnbilledOffers() {
        this.blockUI.start();
        let url = `api/MerchantBilling/unbilled-records?merchantId=${this.selectedCompany.id}&pageSize=${this.unbilledOffersPageSize}&pageNumber=${this.unbilledOffersCurrentPage}`;
        if (this.f.unbillFromDate.value != null) {
            url = `${url}&fromDate=${(new Date(this.f.unbillFromDate.value)).toDateString()}`;
        }
        if (this.f.unbillToDate.value != null) {
            url = `${url}&toDate=${(new Date(this.f.unbillToDate.value)).toDateString()}`;
        }

        this.dataService.get(url)
            .subscribe((result: any) => {
                this.unBilledOffers = result.records;
                this.unBilledOffersWithoutFilter = result.records;
                this.unbilledOffersTotalItems = result.totalCount;
                this.unbilledOffersItemCount();
                this.blockUI.stop();
            }, () => {
                this.blockUI.stop();
            });
    }

    getLoggedInUser() {
        this.userInfo = this.accountService.getLoggedInUserInfo();

        let isSystemAdmin = this.userInfo.roles.find(i => i.name === "SystemAdmin");
        let isSalesManager = this.userInfo.roles.find(i => i.name === "SalesManager");

        if (isSystemAdmin || isSalesManager) {
            this.isSystemAdminOrSalesManager = true;
        }
        if (isSystemAdmin) {
            this.isSystemAdmin = true;
        }

        if (CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
            this.isMerchantLoggedIn = true;
        }
    }

    getCompaniesByRoleType(companyRole: CompanyRole) {
        this.companyFilter.name = '';
        companyRole = CompanyRole.Merchant; // this page will show only merchant billing

        if (companyRole === CompanyRole.Merchant)
            this.selectedCompanyTypeText = 'Merchants';
        else if (companyRole === CompanyRole.GiftSender)
            this.selectedCompanyTypeText = 'Gift Senders';
        else
            this.selectedCompanyTypeText = 'All';

        this.currentPage = 1;
        this.selectedCompanyIndex = 0;
        this.companyService.getCompaniesByRoleType(companyRole)
            .subscribe(data => {
                this.companies = data;

                if (this.merchantId) { // newly added
                    this.companies = this.companies.filter(c => c.id == this.merchantId);
                }
                //else if (!this.userInfo.isSystemAdmin) {
                else if (!this.isSystemAdminOrSalesManager) {
                    this.companies = this.companies.filter(c => c.id == this.userInfo.companyId);
                }

                if (data) {
                    this.selectedCompany = this.companies[0];
                    this.isMerchantSelected = false;
                    if (this.selectedCompany.companyRole == CompanyRole.Merchant) {
                        this.isMerchantSelected = true;
                    }

                    this.selectedCompanyName = this.companies[0].name;
                    if (this.outletName) this.selectedCompanyName = this.selectedCompanyName + "'s " + this.outletName + " outlet";
                    this.getNonPaidInvoices();
                    this.getPaidInvoices();
                    this.getUnbilledOffers();
                    this.getCompanyCurrentBalance();
                    this.getTransactionHistories();
                    this.itemCount();
                }
            });
    }

    getNonPaidInvoices() {
        var input = new GetInvoiceInput();
        input.companyId = this.selectedCompany.id;
        input.outletQRCode = this.outletQRCode;
        input.pageNumber = this.nonPaidInvoicesCurrentPage;
        input.pageSize = this.nonPaidInvoicesPageSize;
        if (this.f.dueFromDate.value != null) {
            input.fromDate = moment.utc(this.f.dueFromDate.value).format();
        }
        if (this.f.dueToDate.value != null) {
            input.toDate = moment.utc(this.f.dueToDate.value).add(1, "days").add(-1, "seconds").format();
        }

        this.billingService.getNonPaidInvoicesPagedList(input)
            .subscribe(data => {
                this.nonPaidInvoices = data.viewModel;
                this.nonPaidInvoicesTotalItems = data.rowCount;
                this.nonPaidInvoicesItemCount();
            });
    }
    getPaidInvoices() {
        var input = new GetInvoiceInput();
        input.companyId = this.selectedCompany.id;
        input.outletQRCode = this.outletQRCode;
        input.pageNumber = this.paidInvoicesCurrentPage;
        input.pageSize = this.paidInvoicesPageSize;
        if (this.f.paidFromDate.value != null) {
            input.fromDate = moment.utc(this.f.paidFromDate.value).format();
        }
        if (this.f.paidToDate.value != null) {
            input.toDate = moment.utc(this.f.paidToDate.value).add(1, "days").add(-1, "seconds").format();
        }

        this.billingService.getPaidInvoicesPagedList(input)
            .subscribe(data => {
                this.paidInvoices = data.viewModel;
                this.paidInvoicesTotalItems = data.rowCount;
                this.paidInvoicesItemCount();
            });
    }
    getCompanyCurrentBalance() {
        var self = this;
        this.billingService.getCompanyCurrentBalance(this.selectedCompany.id, this.outletQRCode)
            .subscribe(data => {
                this.selectedCompanyBalance = ((data) as any);

                //if (!self.userInfo.isSystemAdmin) {
                if (!self.isSystemAdminOrSalesManager) {
                    this.selectedCompanyBalance = -1 * this.selectedCompanyBalance;
                }
            });
    }

    getTransactionHistories() {
        var self = this;
        var input = new GetTransactionInput();
        input.companyId = this.selectedCompany.id;
        input.outletQRCode = this.outletQRCode;
        input.pageNumber = this.transactionCurrentPage;
        input.pageSize = this.transactionPageSize;
        if (this.f.transactionsFromDate.value != null) {
            input.fromDate = moment.utc(this.f.transactionsFromDate.value).format();
        }
        if (this.f.transactionsToDate.value != null) {
            input.toDate = moment.utc(this.f.transactionsToDate.value).add(1, "days").add(-1, "seconds").format();
        }

        this.billingService.getTransactionHistoriesPagedList(input)
            .subscribe(data => {
                data.viewModel.forEach(item => {

                    //if (self.userInfo.isSystemAdmin) {
                    if (self.isSystemAdminOrSalesManager) {
                        switch (item.paymentType) {
                            case PaymentType.GiftSenderToGolp:
                                item.amount = '+ ' + item.amount;
                                break;
                            case PaymentType.GolpToGiftSender:
                                item.amount = '- ' + item.amount;
                                break;
                            case PaymentType.MerchantToGolp:
                                item.amount = '+ ' + item.amount;
                                break;
                            case PaymentType.GolpToMerchant:
                                item.amount = '- ' + item.amount;
                                break;
                            default:
                                item.amount = item.amount;
                                break;
                        }
                    } else {
                        switch (item.paymentType) {
                            case PaymentType.GiftSenderToGolp:
                                item.amount = '- ' + item.amount;
                                break;
                            case PaymentType.GolpToGiftSender:
                                item.amount = '+ ' + item.amount;
                                break;
                            case PaymentType.MerchantToGolp:
                                item.amount = '- ' + item.amount;
                                break;
                            case PaymentType.GolpToMerchant:
                                item.amount = '+ ' + item.amount;
                                break;
                            default:
                                item.amount = item.amount;
                                break;
                        }
                    }

                    item.transactionType = self.transactionTypes[item.transactionType];
                    item.paymentType = self.paymentTypes[item.paymentType];

                });

                this.transactionHistories = ((data.viewModel) as any);
                this.transactionTotalItems = data.rowCount;
                this.transactionItemCount();

            });
    }

    getInvoiceDetail(invoiceId) {
        if (invoiceId === this.selectedInvoiceId) {
            this.selectedInvoiceId = 0;
            return;
        }

        this.billingService.getInvoiceDetail(invoiceId)
            .subscribe(data => {
                this.invoiceDetails = data;
                this.selectedInvoiceId = invoiceId;
            });
    }

    onCompanyChange(item, index) {
        this.selectedCompanyIndex = index;
        this.selectedCompany = item;

        this.currentBalanceText = 'Payment Due From Gift Sender';
        this.isMerchantSelected = false;
        if (this.selectedCompany.companyRole === CompanyRole.Merchant) {
            this.currentBalanceText = 'Payment Due From Golp';
            this.isMerchantSelected = true;
        }

        this.selectedCompanyName = item.name;

        if (this.outletName) this.selectedCompanyName = this.selectedCompanyName + "'s " + this.outletName + " outlet";

        this.getNonPaidInvoices();
        this.getPaidInvoices();
        this.getUnbilledOffers();
        this.getCompanyCurrentBalance();
        this.getTransactionHistories();
        let body = document.getElementsByTagName('body')[0];
        body.classList.add("display-tab-content");
        this.commonService.itemSelectionUpdated(this.selectedCompany);
    }

    onSubMenuChange(name, index) {
        this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
        this.activeSubMenu = this.providerSubMenu.filter(c => c.name === name)[0].name;
    }


    pageChanged(event) {
        this.selectedCompanyIndex = 0;
        this.currentPage = event;
        let itemIndex = (this.currentPage * this.pageSize) - this.pageSize;
        this.selectedCompany = this.companies[itemIndex];
        this.onCompanyChange(this.selectedCompany, 0);
        this.itemCount();
    }

    itemCount() {
        let firstItem = (this.currentPage - 1) * this.pageSize + 1;
        let lastItem = (this.currentPage) * this.pageSize;

        this.itemCountText = firstItem + ' - ' + (lastItem < this.companies.length ? lastItem : this.companies.length) + ' of ' + this.companies.length + ' providers';

    }

    unbilledOffersPageChanged(event) {
        this.unbilledOffersCurrentPage = event;
        this.getUnbilledOffers();
        this.unbilledOffersItemCount();
    }

    unbilledOffersItemCount() {
        let firstItem = (this.unbilledOffersCurrentPage - 1) * this.unbilledOffersPageSize + 1;
        let lastItem = (this.unbilledOffersCurrentPage) * this.unbilledOffersPageSize;
    }

    nonPaidInvoicesPageChanged(event) {
        this.nonPaidInvoicesCurrentPage = event;
        this.getNonPaidInvoices();
        this.nonPaidInvoicesItemCount();
    }

    nonPaidInvoicesItemCount() {
        let firstItem = (this.nonPaidInvoicesCurrentPage - 1) * this.nonPaidInvoicesPageSize + 1;
        let lastItem = (this.nonPaidInvoicesCurrentPage) * this.nonPaidInvoicesPageSize;
    }


    paidInvoicesPageChanged(event) {
        this.paidInvoicesCurrentPage = event;
        this.getPaidInvoices();
        this.paidInvoicesItemCount();
    }

    paidInvoicesItemCount() {
        let firstItem = (this.paidInvoicesCurrentPage - 1) * this.paidInvoicesPageSize + 1;
        let lastItem = (this.paidInvoicesCurrentPage) * this.paidInvoicesPageSize;
    }


    transactionPageChanged(event) {
        this.transactionCurrentPage = event;
        this.getTransactionHistories();
        this.transactionItemCount();
    }

    transactionItemCount() {
        let firstItem = (this.transactionCurrentPage - 1) * this.transactionPageSize + 1;
        let lastItem = (this.transactionCurrentPage) * this.transactionPageSize;
    }

    getUnbillOffersByDateRangeFilter() {
        this.unbilledOffersCurrentPage = 1;
        this.getUnbilledOffers();
    }
    getNonPaidInvoicesByDateRangeFilter() {
        this.nonPaidInvoicesCurrentPage = 1;
        this.getNonPaidInvoices();
    }
    getPaidInvoicesByDateRangeFilter() {
        this.paidInvoicesCurrentPage = 1;
        this.getPaidInvoices();
    }
    getTransactionHistoriesByDateRangeFilter() {
        this.transactionCurrentPage = 1;
        this.getTransactionHistories();
    }


    approveGiftForThisInvoice(item: any) {
        const confirmationDialogConfig = new MatDialogConfig();

        // Setting different dialog configurations
        confirmationDialogConfig.data = `Are you sure, you want to approve all assignment for this invoice?`;
        confirmationDialogConfig.panelClass = "golp-dialog";
        const confirmationDialog = this.dialog.open(
            ConfirmationDialogComponent,
            confirmationDialogConfig
        );

        confirmationDialog.afterClosed().subscribe((result) => {
            if (result != undefined) {
                this.approveInvoice(item);
            }
        });
    }

    approveInvoice(item: any) {
        let requestModel = {
            invoiceNumber: item
        };
        this.assignmentService.approveAssignmentByInvoice(requestModel).subscribe(
            (data) => {
                this.alertService.success("Assignment approve for the selected invoice.");
            },
            (error) => {
                this.alertService.error("Failed to approve the assignment.");
            }
        );
    }

    // convenience getter for easy access to form fields
    get f() { return this.billingForm.controls; }

}
