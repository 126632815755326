export enum CompanySettingType {
  MerchantAcceptanceAvailMethod = 1,
  RealTimePayment = 2,
  ApiEndPointConfiguration = 3,
  GiftPreEnabled = 4,
  PaymentOnOutletOrCentrally = 5,
  GiftAmount = 6,
  PromotionalGift = 7,
  TrendingMerchants = 8,
  MerchantDuePayment = 9,
  MerchantPaymentAccountInfo = 10,
  GiftUsageLimit = 11,
  ExcludeMerchants = 12,
  GiftResendPolicy = 13,
  LoyaltyCardTierMap = 14,
  LoyaltyPointSettings = 15,
  AvailInstruction = 16,
  PrivilegeCardEmailTemplate = 24,
  PrivilegeCardSMSTemplate = 26
}
