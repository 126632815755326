import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { PromotionalGiftSetting, Company } from '../../../models';
import { ReplaySubject } from 'rxjs';
import { CompanyService } from '../../../services';

@Component({
  selector: 'app-create-trending-merchants-setting',
  templateUrl: './create-trending-merchants-setting.component.html',
  styleUrls: ['./create-trending-merchants-setting.component.css']
})

export class CreateTrendingMerchantsSettingComponent implements OnInit {
  promotionalGiftSlabs: any;

  @Output() trendingMerchantEmitter: EventEmitter<any> = new EventEmitter<any>();


  constructor(private formBuilder: FormBuilder, private companyService: CompanyService) {
    this.buildForm();
  }

  trendingMerchantSettingForm: FormGroup;
  @Input() isSubmitted: false;

  submitted = false;

  @Input() existingTrendingMerchantSetting: any;
  showSlabSection: boolean = true;

  promotionalGiftSetting: PromotionalGiftSetting = new PromotionalGiftSetting();
  trendingMerchants: any;

  merchants: Company[];

  trendingMerchantsFilterCtrl: FormControl = new FormControl();
  filteredTrendingMerchants: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);

  ngOnInit() {
    if (this.existingTrendingMerchantSetting) {

      this.trendingMerchants = this.existingTrendingMerchantSetting.split(',').map(function (item) {
        return parseInt(item, 10);
      });

      this.trendingMerchantSettingForm.patchValue({
        trendingMerchants: this.trendingMerchants
      });
    }
    else {
    }



    this.getMerchants();
    this.bindFilterChangeEvent();

    this.setTrendingMerchantAndEmit();
  }

  ngOnChanges() {
    this.submitted = this.isSubmitted;

  }

  buildForm() {
    this.trendingMerchantSettingForm = this.formBuilder.group({
      trendingMerchants: ['', Validators.required]
    });
  }

  bindFilterChangeEvent() {
    this.trendingMerchantsFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterData(this.merchants, this.trendingMerchantsFilterCtrl.value, this.filteredTrendingMerchants);
      });
  }

  filterData(allData: any, search: string, filterData: any) {
    if (!allData) {
      return;
    }
    if (!search) {
      filterData.next(allData);
      return;
    } else {
      search = search.toLowerCase();
    }
    filterData.next(
      allData.filter(data => data.name.toLowerCase().indexOf(search) > -1)
    );
  }

  getMerchants() {
    this.companyService.getMerchants()
      .subscribe(data => {
        this.merchants = data;
        this.filteredTrendingMerchants.next(this.merchants);
      });
  }

  onChangeMerchant($event) {
    this.setTrendingMerchantAndEmit();
  }


  get f() { return this.trendingMerchantSettingForm.controls; }


  setTrendingMerchant() {
    this.trendingMerchants = this.f.trendingMerchants.value;
  }


  setTrendingMerchantAndEmit() {
    this.setTrendingMerchant();
    this.trendingMerchantEmitter.emit(this.trendingMerchants);
  }


}
