import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataHistoryService {

  private dataHistoryBaseUrl = 'api/datahistory';
  constructor(private http: HttpClient) { }

  getOfferHistoryList(startDate: string, endDate: string): Observable<any[]> {
    var url = this.dataHistoryBaseUrl + '/offerhistory/' + startDate + '/' + endDate;
    return this.http.get<any[]>(url);
  }
  getCompaniesHistoryList(startDate: string, endDate: string): Observable<any[]> {
    var url = this.dataHistoryBaseUrl + '/companyhistory/' + startDate + '/' + endDate;
    return this.http.get<any[]>(url);
  }
  getOfferAssignmentsHistoryList(startDate: string, endDate: string): Observable<any[]> {
    var url = this.dataHistoryBaseUrl + '/offerassignmenthistory/' + startDate + '/' + endDate;
    return this.http.get<any[]>(url);
  }
  getAvailOfferTransactionHistoryList(startDate: string, endDate: string): Observable<any[]> {
    var url = this.dataHistoryBaseUrl + '/availoffertransactionhistory/' + startDate + '/' + endDate;
    return this.http.get<any[]>(url);
  }
  getInvoicesHistoryList(startDate: string, endDate: string): Observable<any[]> {
    var url = this.dataHistoryBaseUrl + '/invoicehistory/' + startDate + '/' + endDate;
    return this.http.get<any[]>(url);
  }
}
