import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { MerchantOutletResponse } from 'src/app/models/view-models/merchant-outlet-response';
import { AlertService, FileUploadService } from 'src/app/services';
import { DataService } from '../../services/shared/data.service';
import * as XLSX from 'xlsx';

type AOA = any[][];
@Component({
  selector: 'app-upload-bulk-amount',
  templateUrl: './upload-bulk-amount.component.html',
  styleUrls: ['./upload-bulk-amount.component.css']
})
export class UploadBulkAmountComponent implements OnInit {

  fileName: string;
  uploadedFiles: FormData = new FormData();;
  showFileError: boolean = false;
  merchant: any;
  merchantOutlets: any;
  showOutlet: boolean = true;
  showOutletError: boolean = false;
  companyOutletId: any;
  merchantId: any;

  files: any;

  data: any;
  headData: any

  xlLoading: boolean = false;

  constructor(private router: Router,
    private alertifyService: AlertService,
    private fileUploadService: FileUploadService,
    private dataService: DataService,
    private dialogRef: MatDialogRef<UploadBulkAmountComponent>) {
    this.setUserInfo();
  }

  ngOnInit(): void {
    this.loadMerchant();
    if (this.companyOutletId == null && this.merchantId != null)
      if (this.merchantOutlets == null || this.merchantOutlets.length <= 0)
        this.getMerchantOutlet();
  }
  setUserInfo() {
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.merchantId = userInfo.companyId;
    this.companyOutletId = userInfo.outletQrCode;
  }

  loadMerchant() {
    let merchantString = localStorage.getItem("merchant");
    if (merchantString != null)
      this.merchant = JSON.parse(merchantString);

  }

  onUploadPhoto(files) {
    this.xlLoading = true;
    let fileInfo = this.fileUploadService.anyFileUpload(files, "dataFile");
    if (fileInfo.validationError != null) {
      alert(fileInfo.validationError);
      return;
    }
    this.fileName = fileInfo.fileName;
    // this.uploadedFiles = fileInfo.formData;
    this.files = files;
    this.uploadedFiles.append("bulkGiftCardFile", files[0]);
    // var reader = new FileReader();
    // reader.onload = (event: any) => {
    //     this.imagePreviewPath = event.target.result;
    // }
    // reader.readAsDataURL(files[0]);
    // this.isNewImageSelected = true;
    //this.onFileChange(files);
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    this.xlLoading = true;
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, range: 0 }));

      this.headData = this.data[0];
      this.data = this.data.slice(1); // remove first header record

      const ws2: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[1]];
      this.readDataSheet(ws2, 10);
      this.xlLoading = false;
    };
    reader.readAsBinaryString(target.files[0]);
    this.onUploadPhoto(target.files);

  }

  private readDataSheet(ws: XLSX.WorkSheet, startRow: number) {
    /* save data */
    let datas = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, range: startRow }));
    console.log(datas[1]);
    let headDatas = datas[0];
    datas = datas.slice(1); // remove first header record

    for (let i = 0; i < this.data.length; i++) {
      this.data[i][this.headData.length] = datas.filter(x => x[12] == this.data[i][0])
    }
  }

  export(): void {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  gotoXlPreview() {
    let navigationEgolps: NavigationExtras = {
      state: {
        transd: 'xl-info',
        workQueue: false,
        services: 1,
        code: {
          "headData": this.headData,
          "data": this.data,
          "merchantId": this.merchantId,
          "merchantOutletId": this.companyOutletId,
          "file": this.files[0]
        },
      }
    };
    this.close();
    this.router.navigate(['upload-xl-preview'], navigationEgolps);

  }

  previewAndUploadButtonClicked() {
    let errorOccurred = false;
    if (this.files == null) {
      this.showFileError = true;
      errorOccurred = true;
    }
    else this.showFileError = false;

    if (this.companyOutletId == null) {
      this.showOutletError = true;
      errorOccurred = true;
    }
    else this.showOutletError = false;

    if (errorOccurred) return;

    // let payload = {
    //   "bulkGiftCardFile": this.uploadedFiles,
    //   "merchantId" : this.merchantId,
    //   "merchantOutletId" : this.companyOutletId
    // }
    this.uploadedFiles.append("merchantId", this.merchantId)
    this.uploadedFiles.append("merchantOutletId", this.companyOutletId)
    this.gotoXlPreview();
    // return this.dataService.post("api/giftcard/send-bulk-gift", this.uploadedFiles).subscribe(res=>{
    //   this.close();
    //   console.log(res);
    //   let navigationEgolps: NavigationEgolps = {
    //     state: {
    //       transd: 'bulkResponse',
    //       workQueue: false,
    //       services: 1,
    //       code: res,
    //     }
    // };

    // this.router.navigate(['upload-amount-result'], navigationEgolps);
    // }, err=>{});

  }

  close() {
    this.dialogRef.close();
  }

  getMerchantOutlet() {
    let url = `api/Outlets/company/${this.merchantId}/`;
    // if(this.companyOutletId != null)
    //   url += `${this.companyOutletId}`
    this.dataService.get(url).subscribe((data: MerchantOutletResponse[]) => {
      if (data != null && data.length > 0) {
        this.merchantOutlets = data;
      }
    });
  }

}
