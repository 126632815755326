import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ServiceConsumer } from '../models';


@Injectable({
  providedIn: 'root'
})
export class ServiceConsumersService {

  private baseUrl = 'api/serviceConsumers';

  constructor(private http: HttpClient) { }


  createPremiumUser(premiumUser: ServiceConsumer) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.baseUrl + "/create-premium-user", premiumUser, requestHeader);
  }

  updatePremiumUser(premiumUser: ServiceConsumer) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.put(this.baseUrl + "/update-premium-user", premiumUser, requestHeader);
  }

  markAsGeneralUser(id: number) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.get(this.baseUrl + "/mark-as-general-user?id=" + id);
  }

  getConsumerUserByPhoneNumber(phoneNumber: string): Observable<any> {
    return this.http.get<any[]>(this.baseUrl + '/search-user-by-phone-number?phoneNumber=' + phoneNumber);
  }

  getConsumerUserByConsumerId(consumerId: any): Observable<any> {
    return this.http.get<any[]>(this.baseUrl + '/consumer/' + consumerId);
  }

  getPremiumConsumerGolpCreditDetails(paymentInformation: any) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.put(this.baseUrl + "/consumer-golp-credit-detail", paymentInformation, requestHeader);
    //return this.http.get(this.baseUrl + "/consumer-golp-credit-detail", paymentInformation, requestHeader);
  }
  searchConsumerByPhone(phone: any): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/find-consumer-by-phone/${phone}`);
  }

  lockUser(user: any) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.put(`${this.baseUrl}/lock-user`, user, requestHeader);
  }

  unlockUser(user: any) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.put(`${this.baseUrl}/unlock-user`, user, requestHeader);
  }

}
