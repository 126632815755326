import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OfferHistoryService {
  
  private offerHistoryBaseUrl = 'api/offerhistory';
  constructor(private http: HttpClient) { }

  getOfferHistoryList(): Observable<any[]> {
    //var requestHeader = { headers: new HttpHeaders({ 'No-Auth': 'False' }) };
    //return this.http.get<any[]>(this.offerhistorybaseUrl + '/offer/', requestHeader);
    return this.http.get<any[]>(this.offerHistoryBaseUrl);
  }
}
