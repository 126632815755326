import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountServiceService } from '../../../services';
import { CorporateOfferService } from '../../services/corporate-offer.service';

@Component({
  selector: 'app-copy-offer',
  templateUrl: './copy-offer.component.html',
  styleUrls: ['./copy-offer.component.css']
})
export class CopyOfferComponent implements OnInit {

  userInfo: any;
  copyOfferForm: FormGroup;
  submitted = false;

  offerTemplateId: any = '';
  copyWithReceiver: boolean = false;

  copyTypes: any[];
  copyType: string;

  // onCreate event
  onCopyOfferEvent: EventEmitter<any> = new EventEmitter();


  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CopyOfferComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private accountService: AccountServiceService,
    private offerService: CorporateOfferService
  ) {
    this.getLoggedInUser();
    this.copyType = "without-receiver";
    this.copyTypes = [
      { "id": "without-receiver", "name": "Copy without Receiver" },
      { "id": "with-receiver", "name": "Copy with Receiver" }
    ];

    if (isNaN(data) && data.offer != null) {
      this.offerTemplateId = data.offer.id;
    }
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.copyOfferForm = this.formBuilder.group({
      copyType: [this.copyType],
    });

  }

  onSubmit() {
    this.submitted = true;

    let copyType = this.copyOfferForm.value;
    this.copyWithReceiver = copyType?.copyType === "with-receiver" ? true : false;

    let requestObject = {
      'offerTemplateId': this.offerTemplateId,
      'copyWithReceiver': this.copyWithReceiver
    };

    this.offerService.copyOfferTemplate(requestObject)
      .subscribe((response: any) => {
        if (response.success) {
          this.onCopyOfferEvent.emit(response.data);
          this.dialogRef.close();
        }
      });
    this.dialogRef.close();
  }


  showErrorMessage(error: any) {
    console.log(error);
  }

  getLoggedInUser() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
  }


  close() {
    this.dialogRef.close();
  }

  get f() { return this.copyOfferForm.controls; }

}
