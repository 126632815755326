import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GiftAmountSetting } from '../../../models';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-create-gift-amount-setting',
  templateUrl: './create-gift-amount-setting.component.html',
  styleUrls: ['./create-gift-amount-setting.component.css']
})
export class CreateGiftAmountSettingComponent implements OnInit {
  giftAmountSlabs: any;

  @Output() giftAmountSettingEmitter: EventEmitter<any> = new EventEmitter<any>();


  constructor(private formBuilder: FormBuilder) {
    this.buildForm();
  }

  giftAmountSettingForm: FormGroup;
  @Input() isSubmitted: false;

  submitted = false;

  @Input() existingGiftAmountSetting: any;
  @Input() showSlabSectionInput: boolean = true;
  showSlabSection: boolean = true;

  giftAmountSetting: GiftAmountSetting = new GiftAmountSetting();
  minAmount: number;
  maxAmount: number;
  useSlab: boolean;

  ngOnInit() {
    if (this.existingGiftAmountSetting) {
      this.giftAmountSetting = JSON.parse(this.existingGiftAmountSetting); // Need to convert json to this type;

      this.minAmount = this.giftAmountSetting.minAmount;
      this.maxAmount = this.giftAmountSetting.maxAmount;
      this.useSlab = this.giftAmountSetting.useSlab;
      this.giftAmountSlabs = this.giftAmountSetting.slabs;

      this.giftAmountSettingForm.patchValue({
        minAmount: this.giftAmountSetting.minAmount,
        maxAmount: this.giftAmountSetting.maxAmount,
        useSlab: this.giftAmountSetting.useSlab,
        slabs: this.giftAmountSetting.slabs
      });
    }
    else {
      this.giftAmountSetting = new GiftAmountSetting();
    }

    this.showSlabSection = this.showSlabSectionInput;

    this.setGiftAmountAndEmit();

  }

  ngOnChanges() {
    this.submitted = this.isSubmitted;

  }

  buildForm() {
    this.giftAmountSettingForm = this.formBuilder.group({
      minAmount: this.minAmount,
      maxAmount: this.maxAmount,
      useSlab: this.useSlab,
      slabs: ''
    });
  }

  showHideSlabSection($event) {
    if (this.f.useSlab.value && !!this.f.useSlab.value) {
      this.useSlab = true;
    }
    else {
      this.useSlab = false;
    }
    this.setGiftAmountAndEmit();
  }

  get f() { return this.giftAmountSettingForm.controls; }

  setSlabValuesByGiftAmount($event) {
    this.giftAmountSlabs = $event;
    this.setGiftAmountAndEmit();
  }


  setGiftAmount() {
    this.giftAmountSetting.maxAmount = this.f.maxAmount.value;
    this.giftAmountSetting.minAmount = this.f.minAmount.value;
    this.giftAmountSetting.useSlab = this.f.useSlab.value;
    this.giftAmountSetting.slabs = this.giftAmountSlabs;
  }


  setGiftAmountAndEmit() {
    this.setGiftAmount();
    this.giftAmountSettingEmitter.emit(this.giftAmountSetting);
  }


}
