export class GiftTemplateAssociationsInputDto {
  giftTemplateId?: any;
  giftTemplateAssociationId?: any;
  serialNo?: string;
  phoneNumber: number;
  soldDate: any;

  public constructor(init?: Partial<GiftTemplateAssociationsInputDto>) {
    Object.assign(this, init);
  }
}
