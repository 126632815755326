import { UserPrivilegeCardFacilitiesComponent } from './components/user/user-privilege-card-facilities/user-privilege-card-facilities.component';
import { AvailCardComponent } from './components/avail-process/avail-card/avail-card.component';
import { UserPrivilegeCardComponent } from './components/user/user-privilege-card/user-privilege-card.component';
import { PrivilegeCardComponent } from './components/privilege-card/privilege-card.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserPrivilegeCardDetailsComponent } from './components/user/user-privilege-card-details/user-privilege-card-details.component';

const routes: Routes = [
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrivilegeCardRoutingModule { }
