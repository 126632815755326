import { Component, OnInit } from '@angular/core';
import { TrackOrderService } from 'src/app/services';

@Component({
  selector: 'app-track-orders',
  templateUrl: './track-orders.component.html',
  styleUrls: ['./track-orders.component.css']
})
export class TrackOrdersComponent implements OnInit {

  expandedIndex: number = -1;
  orders: any;
  userInfo: any;
  phone: any;

  constructor(private trackOrderService: TrackOrderService) { }

  ngOnInit() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.getOrders();
  }

  expandCollapse(index) {
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }

  searchOrder() {
    this.getOrders();
  }

  getOrders() {
    var searchModel = {
      'merchantId': this.userInfo.companyId,
      'receiverNumber': this.phone !== undefined && this.phone.length > 0 ? this.phone : ''
    };

    this.trackOrderService.getOrderRecords(searchModel).subscribe(result => {
      this.orders = result;

    }, error => {
      console.log("data fetching error")
    });
  }

  processNextStep(trackingNumber) {
    var orderProcess = {
      "trackingNumber": trackingNumber
    }
    this.trackOrderService.processOrderRecords(orderProcess).subscribe(result => {
      this.getOrders();

    }, error => {
      console.log("data fetching error")
    });

  }
}
