import { AlertService } from './../../../../../services/alert.service';
import { DataService } from './../../../../../components/app-send-gift/services/shared/data.service';
import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NewBillSettingsKeyComponent } from '../../bill-settings-key/new-bill-settings-key/new-bill-settings-key.component';
import { Select2OptionData } from 'ng-select2';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ValidationUtility } from 'src/app/utility/validation.utility';

@Component({
  selector: 'app-new-bill-setting',
  templateUrl: './new-bill-setting.component.html',
  styleUrls: ['./new-bill-setting.component.css']
})
export class NewBillSettingComponent implements OnInit {

  // onCreate event
  onAddBillSettingEvent: EventEmitter<any> = new EventEmitter();
  
  //form
  billSettingsFormGroup: FormGroup;
  submitted:boolean = false;
  
  //BillSettingsKey
  allBillSettingsKey: any[];
  select2BillSettingsKeyInfo: Array<Select2OptionData>;

  //company list
  allCompany: any[];
  select2CompanyKeyInfo: Array<Select2OptionData>;


  constructor(private dialogRef: MatDialogRef<NewBillSettingsKeyComponent>,
    @Inject(MAT_DIALOG_DATA) data, private formBuilder: FormBuilder, private dataService:DataService, private alertService:AlertService) {
      this.buildForm();
      this.loadBillSettingsKey();
      this.loadAllCompany();
     }


  ngOnInit(): void {
  }

  buildForm(){
    this.billSettingsFormGroup = this.formBuilder.group({
      billSettingsKeyId: ["-1", checkSelection],
      companyId: ["-1", checkSelection],
      value: ['', Validators.required],
      order: [0, Validators.required],
      validFrom: ['', Validators.required],
      validTill: ['', Validators.required],
      description: [''],
      isActive: [0],
    });
  }

  onSubmit(){
    this.submitted = true;
    // stop here if form is invalid
    if (this.billSettingsFormGroup.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }
    let url = "api/billing/add-update-billsettings";
    let formData = this.billSettingsFormGroup.value;
    let payload= {
      "billSettingsKeyId": formData.billSettingsKeyId,
      "companyId": formData.companyId,
      "value": formData.value,
      "order": formData.order,
      "validFrom": formData.validFrom,
      "validTill": formData.validTill,
      "description": formData.description,
      "isActive": formData.isActive,
    }

    this.dataService.post(url, payload).subscribe((res:any)=>{
      if(res.result){
        this.onAddBillSettingEvent.next(res);
        this.close();
      }
      else{
        this.alertService.error(res.message);
      }
    }, err=>console.log(err));
    
  }

  close(){
    this.dialogRef.close();
  }

  loadBillSettingsKey(){
    let url = "api/billsettingskey/get-all";

    this.dataService.get(url).subscribe((res:any)=>{
      if(res.result){
        this.allBillSettingsKey = res.data;
        this.select2BillSettingsKeyInfo = this.allBillSettingsKey.map((p) => {
          return {
            id: p.billSettingsKeyId,
            text: p.keyName,
          };
        });
        let noMerchant: Select2OptionData = {
          id: "-1",
          text: "Select Bill Settings Key",
        };
        this.select2BillSettingsKeyInfo.unshift(noMerchant);
          
      }
      
    }, err=>{console.log(err)});
  }

  loadAllCompany(){
    let url = "api/company/all-merchant";

    this.dataService.get(url).subscribe(
      (data: any) => {
        this.allCompany = data;
        this.select2CompanyKeyInfo = this.allCompany.map((p) => {
          return {
            id: p.id,
            text: p.name,
          };
        });
        let noMerchant: Select2OptionData = {
          id: "-1",
          text: "Select Company",
        };
        this.select2CompanyKeyInfo.unshift(noMerchant);
      },
      catchError((error) => {
        return throwError('Something went wrong!');
      })
    );
  }



  get f() { return this.billSettingsFormGroup.controls; }

  

}

export function checkSelection() {

  return (control: AbstractControl): { [key: string]: any } | null => {

    console.log("control value ", control.value);
    const input = Number(control.value);
    return input < 0 ? { selectError: { value: control.value } } : null;
  };
}
