import { Component, Input, OnInit } from '@angular/core';
import { DashboardMonthlyResponse } from 'src/app/models/DashboardMonthlyResponse';
//import * from ''

@Component({
  selector: 'app-gift-consumption-chart',
  templateUrl: './gift-consumption-chart.component.html',
  styleUrls: ['./gift-consumption-chart.component.css']
})
export class GiftConsumptionChartComponent implements OnInit {

  single: any[];
  multi: any[];

  view: any[] = [1000, 400];
  gradient = false;

  colorScheme = {
    //domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
    domain: ['#A10A28', '#9370DB', '#87CEFA', '#FA8072', '#C7B42C', '#90EE90', '#9370DB']
  };
  @Input() summaryResponse:any;
  constructor() {
    //Object.assign(this, {single, multi})  

    this.single = [
      // {
      //   "name": "Gulshan",
      //   "value": 800000
      // },
      // {
      //   "name": "Dhanmondi",
      //   "value": 1500000
      // },
      // {
      //   "name": "Mirpur",
      //   "value": 2000000
      // },
      // {
      //   "name": "Uttara",
      //   "value": 500000
      // },
      // {
      //   "name": "Online",
      //   "value": 200000
      // }
    ];
  }


  populateData() {
    var totalAmount = 0.0;
    
    var regExp = /\(([^)]+)\)/;
    console.log("response : ", this.summaryResponse)
    this.summaryResponse.summary.outletAvailedInfos.forEach(element=>{
      var matches = regExp.exec(element.outletName);

      //matches[1] contains the value between the parentheses
      // console.log(matches[1]);
      if(matches != undefined && matches != null && matches.length >= 2) {
        this.single.push({
          "name": matches[1],
          "value": element.amount
        });
      }
      else {
        this.single.push({
          "name": element.outletName,
          "value": element.amount
        });
      }
      
      totalAmount += element.amount;
    });
    if(this.summaryResponse.summary.outletAvailedInfos.length > 5){
      this.single.push({
        "name": "Ohter",
        "value": this.summaryResponse.summary.redeemedAmount - totalAmount
      });
    }
    
    this.single.push();
  }


  onSelect(event) {
    console.log(event);
  }

  ngOnInit(): void {
    console.log("response first : ", this.summaryResponse);
    if(this.summaryResponse != undefined && this.summaryResponse != null)
      this.populateData();
  }

}
