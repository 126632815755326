import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../../services/shared/data.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-gift-delivered',
  templateUrl: './gift-delivered.component.html',
  styleUrls: ['./gift-delivered.component.css']
})
export class GiftDeliveredComponent implements OnInit {

  giftInformation: any;
  isDelivered: boolean = true;
  giftId: any;
  deliveredGift: any;
  merchantId: any;
  orderId: any;
  btnBackText = "BACK TO HOME";
  btnMoreText = "SEND MORE GIFTS";
  pageHeaderText = "Gift Delivered";

  merchantDetails: any;

  constructor(private router: Router,
    private dataService: DataService,
    private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.merchantId = this.route.snapshot.params.merchantId;
    this.orderId = this.route.snapshot.params.orderId;

    this.getMerchantDetails();

    if (this.isDelivered && this.merchantId && this.orderId) {
      this.getSendGiftInformation();
    }
    if (!this.isDelivered) {
      this.btnBackText = "BACK TO HOME";
      this.btnMoreText = "TRY AGAIN";
      this.pageHeaderText = "Payment Unsuccessful";
    }
  }

  getMerchantDetails() {
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.merchantDetails = {
      merchant: userInfo.companyName,
      outlet: userInfo.companyOutletDto?.name,
      outletAddress: userInfo.companyOutletDto?.address,
      contactPerson: userInfo.firstName,
      printDate: new Date()
    };
  }


  onBack() {
    this.router.navigate(['/send-gift-dashboard']);
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    printInvoice();
  }


  getSendGiftInformation() {
    console.log("merchant Id : ", this.merchantId, " order id ", this.orderId);
    let url = `api/giftcard/processed-order/merchant/${this.merchantId}/${this.orderId}`;
    this.dataService.get(url).subscribe((response: any) => {
      this.deliveredGift = response.data;

    });
  }
}

function printInvoice() {
  $('#printInvoice').click(function () {
    Popup($('#print-view').html());
    function Popup(data) {
      var getFullContent = document.body.innerHTML;
      document.body.innerHTML = data;
      window.print();
      document.body.innerHTML = getFullContent;
      window.location.reload();
    }
  });
}

