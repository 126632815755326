import { InfoModalComponent } from './../../info-modal/info-modal.component';
import { AlertService } from './../../../services/alert.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import * as $ from 'jquery';
import { DataService } from '../../app-send-gift/services/shared/data.service';
import { Select2OptionData } from 'ng-select2';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationWithTextComponent } from '../../confirmation-with-text/confirmation-with-text.component';
import { ExtendGiftExpireDateComponent } from '../../extend-gift-expire-date/extend-gift-expire-date.component';
import { ChangeGiftAssignmentComponent } from '../../change-gift-assignment/change-gift-assignment.component';
import { OfferAssignmentService, CompanyOutletService } from 'src/app/services';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { MerchantOutletResponse } from '../../app-send-gift/models/view-models/merchant-outlets-response';
import { MatSelect } from '@angular/material/select';
@Component({
  selector: 'app-manage-gift-card-business',
  templateUrl: './manage-gift-card-business.component.html',
  styleUrls: ['./manage-gift-card-business.component.css']
})
export class ManageGiftCardBusinessComponent implements OnInit {

  giftOrderSearchForm: FormGroup;

  currentPage: number = 1;
  pageSize: number = 5;
  expandedIndex: number = -1;
  totalItems: number = 0;
  count = 0;
  pageSizes = [5, 10, 25, 50, 100];

  paginationInfo: string = '';

  defaultStartDate: Date = new Date();
  defaultEndDate: Date = new Date();
  paginationPageNumbers: any[];
  currentPageNumberDetails: string;
  submitted: boolean;
  companyOutletId: any;

  userInfo: any;
  reportSummary: any[];

  soldGift: number = 0;
  soldGiftAmount: number = 0;
  redeemedGift: number = 0;
  redeemedGiftAmount: number = 0;
  p: number = 1;
  currentRemarks: string;
  merchantList: any;

  //select2MerchantInfo: Array<Select2OptionData>;
  giftResponse: any;
  actionOptions: any;
  filterTypes: any;
  viewActionButtons: boolean = true;

  //outlets
  merchantOutlets: any[];

  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private dialog: MatDialog,
    private offerAssignmentService: OfferAssignmentService,
    private alertService: AlertService,
    private outletService: CompanyOutletService
  ) {

    this.filterTypes = [{ text: 'All', value: -1 }, { text: 'Active', value: 1 }, { text: 'Inactive', value: 0 }];

    this.actionOptions = [
      { "id": 1, "text": "Change Receiver" },
      { "id": 2, "text": "Resend SMS" },
      //{ "id": 3, "text": "Block Gift" },
      { "id": 3, "text": "SMS Status" },
      { "id": 4, "text": "Extend Expire Date" },
      { "id": 5, "text": "Activate/Inactivate" }
    ]

    this.buildForm();
  }

  ngOnInit(): void {
    this.loadUser()
  }

  ngAfterViewInit(): void { }

  buildForm() {
    this.giftOrderSearchForm = this.formBuilder.group({
      fromDate: '',
      receiverPhoneNumber: '',
      globalInput: '',
      outletId: '',
      giftStatus: -1,
    });
  }

  onSelectOption(event: any, item: any) {

    if (event.value === 1 && !item.hasConsumed) { // change receiver
      this.showChangeAssignmentDailog(item.id, item.giftReceiver)
    }
    else if (event.value === 2 && !item.hasConsumed) { // resend sms
      this.showResendConfirmation(item);
    }
    else if (event.value === 3) {
      this.checkSms(item);
    }
    else if (event.value === 4 && !item.hasConsumed) { // extend expire
      this.showExtendGiftExpireDateDailog(item.id, item.expirationDate);
    }
    else if (event.value === 5 && !item.hasConsumed) { // activate gift card
      this.showGiftActivationConfirmation(item);
    }

    event.source.value = 0; // value set 0 to remove selection after event fire.
  }

  selection(event, index, item) {
    item.selected = !item.selected;
    this.actionButtonVisibility();
  }

  onChangeSelectAll(event) {
    if (event.checked) {
      this.giftResponse?.data.giftCards.forEach(obj => {
        obj.selected = true;
      });
    }
    else {
      this.giftResponse?.data.giftCards.forEach(obj => {
        obj.selected = false;
      });
    }

    this.actionButtonVisibility();
  }

  actionButtonVisibility() {
    let selectedItems = this.giftResponse?.data.giftCards.filter(x => x.selected == true);
    this.viewActionButtons = selectedItems?.length > 1 ? false : true;
  }

  onActivateClick() {
    let selectedItems = this.giftResponse?.data.giftCards.filter(x => x.selected == true);
    this.showGiftActivationConfirmation(selectedItems, true);

  }

  loadUser() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userInfo = userInfo;
    if (this.userInfo.outletQrCode == undefined || this.userInfo.outletQrCode == null)
      this.getMerchantOutlet();
    if (this.userInfo.outletQrCode != undefined && this.userInfo.outletQrCode != null)
      this.companyOutletId = this.userInfo.outletQrCode;
  }

  getMerchantOutlet() {
    this.outletService.getOutletsByCompany(this.userInfo.companyId).subscribe((data: MerchantOutletResponse[]) => {
      if (data != null && data.length > 0) {
        this.merchantOutlets = data;
      }
    });
  }

  checkSms(item) {

    let url = `api/giftcardmanagement/check-sms-status/${item.giftTemplateId}?receiver=${item.giftReceiver}&giftSendDate=${item.createdDate}`;
    this.dataService.get(url).subscribe((response: any) => {
      if (response.success) {
        const confirmationDialogConfig = new MatDialogConfig();
        // Setting different dialog configurations
        if (response.data?.smsDeliveryStatus == 'Delivered') {
          confirmationDialogConfig.data = `SMS has been ${response.data.smsDeliveryStatus?.toLowerCase()} at ${response.data?.deliveryDate}`;
        }
        else {
          confirmationDialogConfig.data = `SMS sending ${response.data?.smsDeliveryStatus?.toLowerCase()}`;
        }
        confirmationDialogConfig.panelClass = 'golp-dialog';
        const confirmationDialog = this.dialog.open(
          InfoModalComponent,
          confirmationDialogConfig
        );

        confirmationDialog.afterClosed().subscribe(() => { });
      } else {
        const confirmationDialogConfig = new MatDialogConfig();
        // Setting different dialog configurations
        confirmationDialogConfig.data = `${response.data}`;
        confirmationDialogConfig.panelClass = 'golp-dialog';
        const confirmationDialog = this.dialog.open(
          InfoModalComponent,
          confirmationDialogConfig
        );

        confirmationDialog.afterClosed().subscribe(() => {
        });
      }
    });
  }


  showResendConfirmation(item) {
    const confirmationDialogConfig = new MatDialogConfig();
    // Setting different dialog configurations
    confirmationDialogConfig.data = `Would you like to proceed? This operation can not be undone.`;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(
      ConfirmationDialogComponent,
      confirmationDialogConfig
    );

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        if (item.orderNo != null && item.orderNo != undefined) {
          this.resendSms(item.orderNo);
        }

      }
    });
  }

  resendSms(orderNo) {
    let url = `api/giftcardmanagement/resend-gift-code/merchant/${this.userInfo.companyId}`;
    this.dataService.post(url, [orderNo]).subscribe(
      (res: any) => {
        if (res.status) {
          this.alertService.success('SMS sent successfully.');
        } else {
          this.alertService.error('SMS sending failed.');
        }
      },
      () => { }
    );
  }

  showGiftActivationConfirmation(items: any, isMultiple: boolean = false) {
    const confirmationDialogConfig = new MatDialogConfig();
    let item = !isMultiple ? items : items[0];
    let title = item.isApproved ? 'Inactivate' : 'Activate'
    let data = {
      "title": `${title} Gift Card`,
      "message": `Are you sure to ${title.toLocaleLowerCase()} this gift?`
    };

    let giftCardIds = !isMultiple ? [item.id] : items.map(({ id }) => id);

    confirmationDialogConfig.data = data;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(
      ConfirmationWithTextComponent,
      confirmationDialogConfig
    );

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        var obj = {
          giftCardIds: giftCardIds,
          activationStatus: !item.isApproved,
          notes: result.note
        };
        this.activateGiftCard(obj, title);
      }
    });
  }

  activateGiftCard(payload: any, title: any) {
    let url = 'api/giftcardmanagement/active-inactive-gift-card';
    this.dataService.post(url, payload).subscribe(
      (res: any) => {
        if (res.success) {
          this.alertService.success(`Gift card ${title} successfully.`);
          this.getOrderReportData();
        } else {
          this.alertService.error(`Gift card ${title} failed.`);
        }
      },
      () => { }
    );
  }


  showMakeOpenMerchantDailog(assignmentId: string) {
    const confirmationDialogConfig = new MatDialogConfig();
    var text = 'Are you sure to make open merchant for this gift?';
    var successMessage = 'Gift merchant made open successfully';

    confirmationDialogConfig.data = text;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(
      ConfirmationWithTextComponent,
      confirmationDialogConfig
    );

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        var obj = { offerAssignmentId: assignmentId, note: result.note };

        this.offerAssignmentService
          .makeOpenMerchant(obj)
          .subscribe((result: any) => {
            if (result.isValid) {
              this.alertService.success(successMessage);
              this.getOrderReportData();
            }
            else {
              this.alertService.error("Open merchant operation can not be applied for this gift card.");
            }
          });
      }
    });
  }

  showChangeAssignment(item: any) {
    this.showChangeAssignmentDailog(item.id, item.giftReceiver);
  }

  showChangeAssignmentDailog(
    assignmentId: any,
    giftReceiverPhoneNumber: string
  ) {
    const userDialogConfig = new MatDialogConfig();
    const initFrom = "MANAGE_GIFT_CARD";
    userDialogConfig.disableClose = true;
    userDialogConfig.autoFocus = true;
    userDialogConfig.data = { assignmentId, giftReceiverPhoneNumber, initFrom };
    userDialogConfig.panelClass = 'golp-dialog';

    const showOfferAssignmentDialog = this.dialog.open(
      ChangeGiftAssignmentComponent,
      userDialogConfig
    );

    showOfferAssignmentDialog.afterClosed().subscribe(() => { });
  }

  showExtendGiftExpireDateDailog(
    assignmentId: any,
    currentExpiredDate: string
  ) {
    const userDialogConfig = new MatDialogConfig();
    const initFrom = "MANAGE_GIFT_CARD"
    userDialogConfig.disableClose = true;
    userDialogConfig.autoFocus = true;
    userDialogConfig.data = { assignmentId, currentExpiredDate, initFrom };
    userDialogConfig.panelClass = 'golp-dialog';

    const showOfferAssignmentDialog = this.dialog.open(
      ExtendGiftExpireDateComponent,
      userDialogConfig
    );

    showOfferAssignmentDialog.afterClosed().subscribe(() => { });
  }


  startOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }
  exportToExcel() { }

  getOrderReportData(submitted: boolean = false) {
    this.submitted = submitted;
    // stop here if form is invalid
    if (this.giftOrderSearchForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    let fromDate = this.f.fromDate.value;
    let receiver = this.f.receiverPhoneNumber.value;
    let globalInput = this.f.globalInput.value;
    let merchantId = this.userInfo.companyId;
    let outletId = this.f.outletId.value;
    let giftStatus = this.f.giftStatus.value;

    let statusFilter = (giftStatus != null && giftStatus === 0) ? false : true

    let url = `api/giftcardmanagement/search-order-gift-card?pageSize=${this.pageSize}&pageNumber=${this.p}&includeInactiveGift=true&`;
    if (fromDate != null && fromDate != '') url += `sendDate=${moment(this.f.fromDate.value).format('YYYY/MM/DD')}&`;
    if (receiver != null && receiver != '') url += `receiver=${receiver}&`;
    if (globalInput != null && globalInput != '') url += `freeTextSearch=${globalInput}&`;
    if (outletId && outletId != null && outletId.qrCode != 0) url += `outletId=${outletId.qrCode}&`
    if (merchantId != null && merchantId > 0) url += `merchantId=${merchantId}&`;
    if (giftStatus != null && giftStatus > -1) url += `isApproved=${statusFilter}`;

    this.dataService.get(url).subscribe((response: any) => {
      if (submitted) this.p = 1;
      this.giftResponse = response;
      this.count = this.giftResponse.data.totalCount;
      this.actionButtonVisibility();
      this.getCurrentRange();
      loadJquery();
    });
  }

  get f() {
    return this.giftOrderSearchForm.controls;
  }

  openRemarks(input) {
    this.currentRemarks = input;
  }

  getShortString(input) {
    if (input != undefined && input != null && input.length > 20) {
      input = input.substring(0, 20);
      input += '...';
    }
    return input;
  }

  handlePageChange(event: number): void {
    this.p = event;
    this.getOrderReportData();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.p = 1;
    this.getOrderReportData();
  }

  getCurrentRange() {
    this.paginationInfo = `Showing ${(this.p - 1) * this.pageSize + 1}-${(this.p - 1) * this.pageSize + this.giftResponse.data.totalCard} of ${this.count}`;
  }
}

function loadJquery() {
  $(document).ready(function () {
    // $("th.d-lg-table-cell").addClass('clone');
    var cells = $('.redesign');

    for (var headCell = 0; headCell < cells.length;) {
      $(cells[headCell++]).addClass('d-sticky');
      $(cells[headCell++]).addClass('d-sticky2');
      $(cells[headCell++]).addClass('d-sticky3');
      $(cells[headCell++]).addClass('d-sticky4');
    }
  });

}
