import { Component, OnInit } from '@angular/core';
import { RolesService } from 'src/app/services/roles.service';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/services';
import { Role } from 'src/app/models/role';
import { CreateRoleComponent } from '../create-role/create-role.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { CreateRolePermissionsComponent } from '../create-role-permissions/create-role-permissions.component';
import { RolePermissions } from 'src/app/models/role-permissions';
import { RolePermissionService } from 'src/app/services/role-permission.service';
import { RoleSharedService } from 'src/app/services/role-shared.service';

@Component({
  selector: 'app-role-permissions',
  templateUrl: './role-permissions.component.html',
  styleUrls: ['./role-permissions.component.css']
})
export class RolePermissionsComponent implements OnInit {


  roles :any[];
  mssg :string ='Aplectrum' ;
  rolePermission: RolePermissions;
  constructor(private roleService: RolesService, 
    private dialog: MatDialog,
    private alertService: AlertService,
    private roleSharedService : RoleSharedService,
    private rolePermissionService: RolePermissionService
    ) { }

  ngOnInit() {
    //this.getRoles();
    this.roleSharedService.events$.forEach(data => {
      this.roles = data;
    });
  }

  ngOnChanges() {
  }

  // getRoles() {
  //   this.roleService.getRoles()
  //       .subscribe(data => {
  //         this.roles = data;
  //       });
  // }

  
  editRole(roleModel: Role){
    this.getRolePermissionByNameAndOpenModal(roleModel.name);
  }

  getRolePermissionByNameAndOpenModal(roleName: string) {
    this.rolePermissionService.getRolePermissionsByName(roleName).subscribe(result => {
      this.rolePermission = result;

      const editDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    editDialogConfig.disableClose = true;
    editDialogConfig.autoFocus = true;
    editDialogConfig.data = result;
    editDialogConfig.panelClass = 'golp-dialog';

      const roleEditDialog = this.dialog.open(CreateRolePermissionsComponent, editDialogConfig);

    // const successfullEdit = roleEditDialog.componentInstance.onRoleEditEvent.subscribe((data) => {
    //   this.getRoles();
    // });

    // roleEditDialog.afterClosed().subscribe(() => {
    // });
    });
  }


}
