import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from 'src/app/components/app-send-gift/services/shared/data.service';
import { AlertService } from 'src/app/services';
import { ValidationUtility } from 'src/app/utility/validation.utility';

@Component({
  selector: 'app-add-card-issuer',
  templateUrl: './add-card-issuer.component.html',
  styleUrls: ['./add-card-issuer.component.css']
})
export class AddCardIssuerComponent implements OnInit {

  // onCreate event
  onAddIssuerEvent: EventEmitter<any> = new EventEmitter();

  inputData: any;

  addIssuerForm: FormGroup;
  submitted: boolean = false;
  isActive: boolean = false;

  issuersVM: any[];
  issuers: any;


  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddCardIssuerComponent>,
    private dataService: DataService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) data) {

    if (data !== null) {
      this.inputData = data.privilegeCard;
    }

    this.addIssuerForm = this.formBuilder.group({
      issuers: [[Validators.required]],
      activateFrom: [],
      isActive: [this.isActive],
    });

  }

  ngOnInit() {
    this.loadIssuers();
  }

  loadIssuers() {
    let url = '/api/company/offer-provider/basics';
    this.dataService.get(url).subscribe((data: any) => {
      this.issuers = data;
      this.issuersVM = this.issuers.map((i) => {
        return {
          issuerId: i.id,
          issuerName: i.name,
          show: true
        };
      });
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.addIssuerForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    let payload = {
      "golpCardId": this.inputData.cardId,
      "activeFrom": this.addIssuerForm.get('activateFrom').value,
      "isActive": this.addIssuerForm.get('isActive').value,
      "issuers": this.addIssuerForm.get('issuers').value
    };

    this.dataService.post("api/cards/add-card-issuers", payload)
      .subscribe((response: any) => {
        console.log("response here : ", response);
        if (response.success) {
          this.onAddIssuerEvent.emit(this.inputData.cardId);
          this.alertService.success('Issuer added successfully.');
          this.dialogRef.close();
        }
        if (!response.success) {
          this.alertService.error("Failed to add issuer.");
        }
      },
        () => {
        });
  }


  onKey(value) {
    this.issuersVM.forEach(m => {
      if (m.issuerName.toLowerCase().includes(value.toLowerCase())) {
        m["show"] = true;
      }
      else {
        m["show"] = false;
      }
    });
  }


  close() {
    this.dialogRef.close();
  }

  // convenience getter for easy access to form fields
  get f() { return this.addIssuerForm.controls; }

}
