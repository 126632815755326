import { Component, OnInit, EventEmitter, Inject, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValidationUtility } from '../../utility/validation.utility';
import { CompanyService, AccountServiceService, LoyaltyMerchantService } from '../../services';
import { LoyaltyMerchantPoint } from '../../models';

@Component({
  selector: 'app-create-loyalty-merchant-point',
  templateUrl: './create-loyalty-merchant-point.component.html',
  styleUrls: ['./create-loyalty-merchant-point.component.css']
})

export class CreateLoyaltyMerchantPointComponent implements OnInit {

  onPointCreateEvent: EventEmitter<any> = new EventEmitter();
  onPointEditEvent: EventEmitter<any> = new EventEmitter();

  loyaltyMerchantPointForm: FormGroup;
  submitted = false;
  loyaltyMerchantPoint: LoyaltyMerchantPoint;
  loyaltyMerchantId: any;
  isEdit = false;
  userInfo: any;
  cardTiers: any;

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateLoyaltyMerchantPointComponent>,
    private loyaltyMerchantService: LoyaltyMerchantService,
    private accountService: AccountServiceService,
    @Inject(MAT_DIALOG_DATA) data,
    private injector: Injector
  ) {
    this.loyaltyMerchantPoint = new LoyaltyMerchantPoint();
    if (data != null) {
      if (isNaN(data)) {
        this.loyaltyMerchantPoint = new LoyaltyMerchantPoint(data);
        this.loyaltyMerchantId = this.loyaltyMerchantPoint.loyaltyMerchantId;
        this.isEdit = true;
      }
      else {
        this.isEdit = false;
        this.loyaltyMerchantId = data;
      }
    }
  }

  ngOnInit() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
    this.isEdit = this.loyaltyMerchantPoint.id != undefined;
    this.getAllMerchantCardTiers();
    this.buildForm();
  }
  close() {
    this.dialogRef.close();
  }
  buildForm() {
    this.loyaltyMerchantPointForm = this.formBuilder.group({
      salesAmount: [this.loyaltyMerchantPoint.salesAmount ? this.loyaltyMerchantPoint.salesAmount : '', [Validators.required, Validators.min(1)]],
      customerPhoneNumber: [this.loyaltyMerchantPoint.customerPhoneNumber, Validators.required],
      customerExternalId: [this.loyaltyMerchantPoint.customerExternalId],
      cardTier: [this.loyaltyMerchantPoint.cardTier, Validators.required],
      expireDate: [this.loyaltyMerchantPoint.expireDate]
    });
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loyaltyMerchantPointForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    if (this.loyaltyMerchantPoint.id === undefined) { // create
      this.createCompanyPoint();
    }
    else if (this.loyaltyMerchantPoint.id != undefined && this.loyaltyMerchantPoint.id != null) { // edit
      this.editCompanyPoint();
    }
    this.dialogRef.close();
  }
  createCompanyPoint() {
    this.loyaltyMerchantPoint = new LoyaltyMerchantPoint(this.loyaltyMerchantPointForm.value);
    if (this.loyaltyMerchantPoint !== null) {
      this.loyaltyMerchantPoint.loyaltyMerchantId = this.loyaltyMerchantId;
    }
    this.loyaltyMerchantService.createOrEditLoyaltyMerchantPoint(this.loyaltyMerchantPoint).subscribe((data: any) => {
      this.onPointCreateEvent.emit(this.loyaltyMerchantPoint.id);
    },
      (error: any) => {
        //this.showErrorMessage(error);
      })
  }

  editCompanyPoint() {
    var creditId = this.loyaltyMerchantPoint.id;
    this.loyaltyMerchantPoint = new LoyaltyMerchantPoint(this.loyaltyMerchantPointForm.value);
    this.loyaltyMerchantPoint.id = creditId;
    this.loyaltyMerchantService.createOrEditLoyaltyMerchantPoint(this.loyaltyMerchantPoint).subscribe((data: any) => {
      this.onPointEditEvent.emit(this.loyaltyMerchantPoint.id);
    },
      (error: any) => {
        //this.showErrorMessage(error);
      })
  }

  getAllMerchantCardTiers() {
    this.loyaltyMerchantService.getAllMerchantCardTiers(this.loyaltyMerchantId)
      .subscribe(result => {
        this.cardTiers = result;
      });
  }

  get f() { return this.loyaltyMerchantPointForm.controls; }

}

