import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AccountServiceService, AlertService, DataService } from 'src/app/services';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { AddOfferMerchantComponent } from '../add-offer-merchant/add-offer-merchant.component';

@Component({
  selector: 'app-offer-merchant',
  templateUrl: './offer-merchant.component.html',
  styleUrls: ['./offer-merchant.component.css']
})
export class OfferMerchantComponent implements OnInit {

  // inputs
  @Input() providerDetails: any;

  provider: any;
  //cards: any[] = [];
  searchMerchantForm: FormGroup;

  //pagination
  merchantCurrentPage: number = 1;
  merchantPageSize: number = 10;
  expandedIndex: number = -1;
  merchantCount: any;
  merchantPageSizes: any = [10, 25, 50, 100];
  paginationInfo: any = '';

  //merchants
  offerMerchants: any;

  showIssuerFilter: boolean = false;
  constructor(private accountService: AccountServiceService, private dataService: DataService, private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private alertService: AlertService) {
    if (accountService.getLoggedInUserInfo().isSystemAdmin)
      this.showIssuerFilter = true;
    this.buildForm();
  }

  ngOnInit(): void {
    //this.loadMerchant();
  }

  buildForm() {
    this.searchMerchantForm = this.formBuilder.group({
      merchantName: ''
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['providerDetails']) {
      setTimeout(() => {
        this.provider = this.providerDetails;
        if (this.provider && this.provider?.id != null && this.provider?.id != undefined)
          this.getOfferMerchantsByProvider(true, this.provider.id, null);
      });
    }
  }

  onSubmit(reset: boolean) {
    let merchantName = this.searchMerchantForm.value.merchantName;

    let payLoad = {
      "merchantName": merchantName,
    }
    this.getOfferMerchantsByProvider(reset, this.provider.id, payLoad);
  }

  getOfferMerchantsByProvider(reset: boolean = false, offerProviderId: any, search: any) {
    let url = `api/offermerchant/?offerProviderId=${offerProviderId}&pageNumber=${this.merchantCurrentPage}&pageSize=${this.merchantPageSize}`

    if (search?.merchantName != undefined && search?.merchantName != null && search?.merchantName != "") {
      url += `&merchantName=${search.merchantName}`;
    }

    this.dataService.get(url)
      .subscribe((response: any) => {

        if (response.success) {
          this.offerMerchants = response.data.offerMerchants;
          this.merchantCount = response.data.totalCount;
          if (reset) this.merchantCurrentPage = 1;
          this.getCurrentRange();
        }
        else {
          this.offerMerchants = [];
        }
      });
  }

  addOfferMerchant(offerProvider: any) {
    const addMerchantDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    addMerchantDialogConfig.disableClose = true;
    addMerchantDialogConfig.autoFocus = true;
    addMerchantDialogConfig.panelClass = "golp-dialog";
    let data = {
      "offerProvider": offerProvider,
      "merchant": null
    }
    addMerchantDialogConfig.data = data

    const addIssuerDialog = this.dialog.open(AddOfferMerchantComponent, addMerchantDialogConfig);

    addIssuerDialog.componentInstance.onAddOfferMerchantEvent.subscribe((data: any) => {
      this.getOfferMerchantsByProvider(false, data, null);
    });

    addIssuerDialog.afterClosed().subscribe(() => {
    });
  }

  onEdit(item) {
    const addMerchantDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    addMerchantDialogConfig.disableClose = true;
    addMerchantDialogConfig.autoFocus = true;
    addMerchantDialogConfig.panelClass = "golp-dialog";
    let data = {
      "offerProvider": this.provider,
      "merchant": item
    }
    addMerchantDialogConfig.data = data

    const addIssuerDialog = this.dialog.open(AddOfferMerchantComponent, addMerchantDialogConfig);

    addIssuerDialog.componentInstance.onAddOfferMerchantEvent.subscribe((data: any) => {
      this.getOfferMerchantsByProvider(false, data, null);
    });

    addIssuerDialog.afterClosed().subscribe(() => {
    });
  }

  onDelete(item: any) {
    // const confirmationDialogConfig = new MatDialogConfig();

    // // Setting different dialog configurations
    // confirmationDialogConfig.data = `Are you sure to delete the number ${item.cardHolderPhone}?`;
    // confirmationDialogConfig.panelClass = 'golp-dialog';
    // const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    // confirmationDialog.afterClosed().subscribe((result) => {
    //   if (result != undefined) {
    //     let url = "api/cards/delete-beneficiary";
    //     let payload = {
    //       "cardId": item.golpCardId,
    //       "beneficiaryId": item.id,
    //     }
    //     this.dataService.post(url, payload)
    //       .subscribe((response: any) => {
    //         if (response.success) {
    //           this.getBeneficiaryByTemplate(true, item.golpCardId, null);
    //           this.alertService.success(response.data);
    //         } else if (!response.success) {
    //           this.alertService.error(response.data);
    //         }
    //       });
    //   }
    // });
  }

  onStatusChange(item: any) {
    const confirmationDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    let status = item.isActive ? "Deactivate" : "Activate";
    confirmationDialogConfig.data = `Are you sure to ${status.toLowerCase()} this offer merchant?`;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        let url = "api/offermerchant/change-merchant-status";
        let payload = {
          "merchantStatus": !item.isActive,
          "merchantId": item.id
        }

        this.dataService.post(url, payload)
          .subscribe((response: any) => {
            if (response.success) {
              this.getOfferMerchantsByProvider(false, this.provider.id, null);
              this.alertService.success(response.data);
            } else if (!response.success) {
              this.alertService.error(response.data);
            }
          });
      }
    });
  }


  handlePageChange(event: number): void {
    this.merchantCurrentPage = event;
    this.getOfferMerchantsByProvider(false, this.provider.id, null);
  }

  handlePageSizeChange(event: any): void {
    this.merchantPageSize = event.target.value;
    this.merchantCurrentPage = 1;
    this.getOfferMerchantsByProvider(false, this.provider.id, null);
  }

  getCurrentRange() {
    this.paginationInfo = `Showing ${((this.merchantCurrentPage - 1) * this.merchantPageSize) + 1}-${((this.merchantCurrentPage - 1) * this.merchantPageSize) + this.offerMerchants?.length} of ${this.merchantCount}`
  }

}
