import { Component, OnInit, Output, EventEmitter, Input, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ReplaySubject, Observable } from 'rxjs';
import { Company, LoyaltyMerchant, CompanyCategory } from '../../models';
import { CompanyService, LoyaltyMerchantService, AlertService, FileUploadService, CompanyCategoryService } from '../../services';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValidationUtility } from '../../utility/validation.utility';
import { PaymentMethod, CompanyRole } from '../../enums';

@Component({
  selector: 'app-create-loyalty-merchant',
  templateUrl: './create-loyalty-merchant.component.html',
  styleUrls: ['./create-loyalty-merchant.component.css']
})

    
export class CreateLoyaltyMerchantComponent implements OnInit {

  // onCreate event
  onLoyaltyMerchantCreateEvent: EventEmitter<LoyaltyMerchant> = new EventEmitter();
  // onEdit event;
  onLoyaltyMerchantEditEvent: EventEmitter<LoyaltyMerchant> = new EventEmitter();

  companyCategories: CompanyCategory[];
  loyaltyMerchant: LoyaltyMerchant;

  categoryForm = new FormControl();
  filteredCategory: Observable<CompanyCategory[]>;

  paymentOptions = PaymentMethod;
  paymentOptionsKeys: any[];
  loyaltyMerchantCreationForm: FormGroup;
  submitted = false;
  uploadedFiles: FormData;
  inEditMode: boolean = false;

  fileName: string = '';
  imagePreviewPath: string = '../../../assets/images/default.png';
  fileValidationError: string;

  loyaltyMerchantProviderFilterCtrl: FormControl = new FormControl();
  filteredLoyaltyMerchantProviders: ReplaySubject<CompanyCategory[]> = new ReplaySubject<CompanyCategory[]>(1);

  constructor(private companyCategoryService: CompanyCategoryService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateLoyaltyMerchantComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private companyService: CompanyService,
    private loyaltyMerchantService: LoyaltyMerchantService,
    private alertService: AlertService,
    private fileUploadService: FileUploadService
  ) {

    this.loyaltyMerchant = new LoyaltyMerchant();

    if (data !== null) {
      this.loyaltyMerchant = new LoyaltyMerchant(data);
      this.inEditMode = true;
      this.fileName = this.loyaltyMerchant.imageName;
      this.imagePreviewPath = this.loyaltyMerchant.imageFullPath;
    }
    this.buildForm();
  }

  ngOnInit() {
    this.getloyaltyMerchantCategories();
    this.paymentOptionsKeys = Object.keys(this.paymentOptions).filter(Number);
    this.bindFilterChangeEvent();
  }

  buildForm() {
    this.loyaltyMerchantCreationForm = this.formBuilder.group({
      name: [this.loyaltyMerchant.name, [Validators.required, Validators.maxLength(250)]],
      shortName: [this.loyaltyMerchant.shortName, [Validators.required, Validators.maxLength(10)]],
      phone: [this.loyaltyMerchant.phone, Validators.maxLength(50)],
      email: [this.loyaltyMerchant.email, [Validators.email, Validators.maxLength(50)]],
      address: [this.loyaltyMerchant.address, [Validators.maxLength(250)]],
      webAddress: [this.loyaltyMerchant.webAddress, [Validators.maxLength(100)]],
      loyaltyMerchantCategoryId: [this.loyaltyMerchant.loyaltyMerchantCategoryId, Validators.required],
      //salesCommission: [this.loyaltyMerchant.salesCommission, [Validators.required, Validators.pattern('^\\d+(\\.\\d{1,2})?$')]],

    });
  }

  onUploadLogo(files) {
    this.fileValidationError = null;
    let fileInfo = this.fileUploadService.imageFileUpload(files);
    if (fileInfo.validationError != null) {
      this.fileValidationError = fileInfo.validationError;
      return;
    }
    this.fileName = fileInfo.fileName;
    this.uploadedFiles = fileInfo.formData;

    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imagePreviewPath = event.target.result;
    }
    reader.readAsDataURL(files[0]);
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid

    if (this.loyaltyMerchantCreationForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    if (this.loyaltyMerchant.id === undefined) { // create
      this.createloyaltyMerchant();
    }
    else if (this.loyaltyMerchant.id !== undefined) { // edit
      this.editloyaltyMerchant();
    }
    this.dialogRef.close();
  }

  createloyaltyMerchant() {
    this.loyaltyMerchant = new LoyaltyMerchant(this.loyaltyMerchantCreationForm.value);
  
    this.loyaltyMerchantService.createLoyaltyMerchant(this.loyaltyMerchant)
      .subscribe((data: any) => {
        let logoUpload = false;
        // upload company logo
        if (this.uploadedFiles != null) {
          logoUpload = true;
          this.uploadedFiles.append('id', data.loyaltyMerchantVm.id)
          this.loyaltyMerchantService.uploadFile(this.uploadedFiles).subscribe((result: any) => {
            this.onLoyaltyMerchantCreateEvent.emit(result);
            this.alertService.success("Loyalty Merchant created successfully");
          },
            (error: any) => {
              this.showErrorMessage(error);
            });
        }
        if (!logoUpload) {
          this.loyaltyMerchant.id = data.loyaltyMerchantVm.id;
          this.loyaltyMerchant.companyCategory = this.companyCategories.filter(c => c.id === this.loyaltyMerchant.loyaltyMerchantCategoryId)[0].name;
          this.loyaltyMerchant.imageFullPath = this.imagePreviewPath;
          this.onLoyaltyMerchantCreateEvent.emit(this.loyaltyMerchant);
          this.alertService.success("Loyalty Merchant created successfully");
        }
      },
        (error: any) => {
          this.showErrorMessage(error);
        })
  }

  editloyaltyMerchant() {
    let newData = new LoyaltyMerchant(this.loyaltyMerchantCreationForm.value);

    if (this.loyaltyMerchant !== null) {
      this.loyaltyMerchant.loyaltyMerchantCategoryId = newData.loyaltyMerchantCategoryId;
      this.loyaltyMerchant.name = newData.name;
      this.loyaltyMerchant.shortName = newData.shortName;
      this.loyaltyMerchant.phone = newData.phone;
      this.loyaltyMerchant.email = newData.email;
      this.loyaltyMerchant.address = newData.address;
      this.loyaltyMerchant.webAddress = newData.webAddress;
      //this.loyaltyMerchant.salesCommission = newData.salesCommission;

      this.loyaltyMerchantService.editLoyaltyMerchant(this.loyaltyMerchant)
        .subscribe((data: any) => {
          let logoChanged = false;
          //change logo if updated
          if (this.uploadedFiles != null) {
            logoChanged = true;
            // upload company logo
            this.uploadedFiles.append('id', this.loyaltyMerchant.id.toString())
            this.loyaltyMerchantService.uploadFile(this.uploadedFiles).subscribe((result: any) => {
              this.loyaltyMerchant.imageFullPath = result.imageFullPath;
              this.loyaltyMerchant.imageName = result.imageName;
              this.loyaltyMerchant.companyCategory = this.companyCategories.filter(c => c.id === this.loyaltyMerchant.loyaltyMerchantCategoryId)[0].name;
              this.onLoyaltyMerchantEditEvent.emit(this.loyaltyMerchant);
              this.alertService.success("Loyalty Partner edited successfully");
            },
              (error: any) => {
                this.showErrorMessage(error);
              });
          }
          if (!logoChanged) {
            this.loyaltyMerchant.companyCategory = this.companyCategories.filter(c => c.id === this.loyaltyMerchant.loyaltyMerchantCategoryId)[0].name;
            this.onLoyaltyMerchantEditEvent.emit(this.loyaltyMerchant);
            this.alertService.success("Loyalty Partner edited successfully");
          }
        },
          (error: any) => {
            this.showErrorMessage(error);
          });
    }
  }

  getCompanyTypeId(loyaltyMerchantCategoryId) {
    this.loyaltyMerchant.loyaltyMerchantCategoryId = loyaltyMerchantCategoryId.id;
  }

  getloyaltyMerchantCategories() {
    this.loyaltyMerchantService.getAllLoyaltyMerchantCategories()
      .subscribe(data => {
        this.companyCategories = data;
        this.filteredLoyaltyMerchantProviders.next(this.companyCategories);
      });
  }

  displayCategoryFn(category?: CompanyCategory): string | undefined {
    let data = this.companyCategories;
    return category ? category.name : undefined;
  }

  bindFilterChangeEvent() {
    this.loyaltyMerchantProviderFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterloyaltyMerchantproviders();
      });
  }

  protected filterloyaltyMerchantproviders() {
    if (!this.companyCategories) {
      return;
    }

    let search = this.loyaltyMerchantProviderFilterCtrl.value;
    if (!search) {
      this.filteredLoyaltyMerchantProviders.next(this.companyCategories);
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredLoyaltyMerchantProviders.next(
      this.companyCategories.filter(comapnyCategory => comapnyCategory.name.toLowerCase().indexOf(search) > -1)
    );
  }

  showErrorMessage(error: any) {
    console.log(error);
    //this.alertService.error("Internal server error happen");
  }

  openFileBrowser(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById('CompanyPhotoId') as HTMLElement;
    element.click();
  }

  close() {
    this.dialogRef.close();
  }

  // convenience getter for easy access to form fields
  get f() { return this.loyaltyMerchantCreationForm.controls; }

}
