import { Component, OnInit, EventEmitter, Input, Output, SimpleChanges, Injector } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { CommonService, OfferService } from '../../services';
import { Offer } from '../../models';
import { offerconsumedstatus } from '../../enums/offer-consumed-status';

@Component({
  selector: 'app-consumer-send-gift-details',
  templateUrl: './consumer-send-gift-details.component.html',
  styleUrls: ['./consumer-send-gift-details.component.css']
})
export class ConsumerSendGiftDetailsComponent extends BaseComponent implements OnInit {

  @Input() offerDetailsInput: any;
  @Output() offerDetailsInputChange: EventEmitter<any> = new EventEmitter<any>();

  offerDetails: Offer;
  userInfo: any;
  hasToShowMerchantTextImg = false;
  hasToShowOfferTextImg = false;
  detailsLoaded: Promise<boolean>;
  offerconsumedstatus: offerconsumedstatus;
  constructor(
    private commonService: CommonService, private offerService: OfferService,
    injector: Injector
  ) { super(injector) }

  ngOnInit() {
    this.getLoggedInUser();
    if (this.offerDetails != null) {
      this.hasToShowMerchantTextImg = false;
      this.hasToShowOfferTextImg = false;
      this.getOfferStatus();
      this.detailsLoaded = Promise.resolve(true);
    }

  }
  getLoggedInUser() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userInfo = userInfo;
  }
  getOfferStatus() {
    var status = offerconsumedstatus[this.offerDetails.offerConsumedStatus];//this.offerDetails.offerConsumedStatus == 1 ? "Pending" : (this.offerDetails.offerConsumedStatus == 0 ? "None" : offerconsumedstatus.Availed.toString());
    this.offerDetails.offerConsumedStatusText = status;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['offerDetailsInput']) {
      setTimeout(() => {
        this.offerDetails = this.offerDetailsInput;
        //this.showHideAcceptAction();
        this.makeSomeDelay(this.offerDetails);
      });
    }
  }

  makeSomeDelay(offer: any) {
    if (offer != null) {
      let readSomeValue = offer.offerName;
      this.hasToShowMerchantTextImg = false;
      this.hasToShowOfferTextImg = false;
      this.getOfferStatus();
      this.detailsLoaded = Promise.resolve(true);
    }
  }
  printGift(offer: any) {
    var newWindow = window.open('',"_blank");
    this.offerService.printConsumerGift(offer.id)
      .subscribe((data: any) => {
        if (data != null) {
          //window.open(data.printGiftUrl, '_blank');
          newWindow.location.href = data.printGiftUrl;
          
        }
      });
  }

  errorHandlerOnOfferImg(event) {
    this.hasToShowOfferTextImg = true;
  }
  errorHandlerOnMerchantImg(event) {
    this.hasToShowMerchantTextImg = true;
  }
}
