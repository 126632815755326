import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { RegistrationValidator } from '../create-user/create-user.component';
import { ResetPasswordModel } from '../../models/reset-password-model';
import { AccountServiceService } from '../../services/account-service.service';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.css']
})
export class ResetPasswordDialogComponent implements OnInit {

  userResetPasswordForm: FormGroup;
  resetPasswordGroup: FormGroup;
  resetPassword: ResetPasswordModel;
  submitted = false;

  constructor(
    private dialogRef: MatDialogRef<ResetPasswordDialogComponent>,
    private formBuilder: FormBuilder,
    private accountService: AccountServiceService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) data) {

      this.resetPassword = new ResetPasswordModel();
      if (data !== null) {
        this.resetPassword.email = data;
      }
      this.buildForm();
      this.userResetPasswordForm.controls.email.disable();
    }


    buildForm() {
      this.resetPasswordGroup = this.formBuilder.group({
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required]
      }, {
          validator: RegistrationValidator.validate.bind(this)
        });

      this.userResetPasswordForm = this.formBuilder.group({
        email: [this.resetPassword.email, [Validators.required, Validators.email]],
        password: [this.resetPassword.password],
        passwordFormGroup: this.resetPasswordGroup
      });
    }

  ngOnInit() {
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.userResetPasswordForm.invalid) {
      return;
    }

    this.resetPassword.password = this.resetPasswordGroup.controls.password.value;
    this.accountService.resetUserPassword(this.resetPassword).subscribe(data => {
      if (data) {
        this.alertService.success('User password changed successfully');
      }
    }, error => {
      if (error && error.error && error.error.Message) {
        this.alertService.error(error.error.Message);
      } else {
        console.log(error);
      }
    });

    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

}
