import { Component, OnInit, Injector } from '@angular/core';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-due-payment-report-base',
  templateUrl: './due-payment-report-base.component.html',
  styleUrls: ['./due-payment-report-base.component.css']
})

export class DuePaymentReportBaseComponent extends BaseComponent implements OnInit {

  duePaymentReportsSubMenu: any[] = [];
  selectedSubMenuIndex = 0;
  isAdmin = false;
  activeSubMenu = 'Due Payments Report';

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.setSubMenu();
  }

  setSubMenu() {
    this.duePaymentReportsSubMenu.push({ 'name': 'Due Payments Report', 'dataTarget': 'duePaymentsReportTab' });
    this.duePaymentReportsSubMenu.push({ 'name': 'Day Wise Merchant Due Report', 'dataTarget': 'merchantDueReportTab' });

  }

  onSubMenuChange(name, index) {
    if (this.selectedSubMenuIndex !== index) {
      this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
      this.activeSubMenu = this.duePaymentReportsSubMenu.filter(c => c.name === name)[0].name;
    }
  }
}

