import { Component, OnInit, Input, SimpleChanges, Injector, Output, EventEmitter } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { UserModel, LoyaltyMerchant, LoyaltyMerchantPoint } from '../../models';
import { AlertService, LoyaltyMerchantService } from '../../services';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { BaseComponent } from '../base/base.component';
import { CreateLoyaltyMerchantPointComponent } from '../create-loyalty-merchant-point/create-loyalty-merchant-point.component';

@Component({
  selector: 'app-loyalty-merchant-points',
  templateUrl: './loyalty-merchant-points.component.html',
  styleUrls: ['./loyalty-merchant-points.component.css']
})

export class LoyaltyMerchantPointsComponent extends BaseComponent implements OnInit {
  @Input() loyaltyMerchantDetailsInput: LoyaltyMerchant;
  @Output() loyaltyMerchantDetailsInputChange: EventEmitter<LoyaltyMerchant> = new EventEmitter<LoyaltyMerchant>();

  loyaltyMerchantDetails: LoyaltyMerchant
  loyaltyMerchantLogoOnErr: String
  loyaltyMerchantLogoTxtOnErr = false;
  merchantPoints: any;
  loyaltyMerchantId: any;

  detailsLoaded: Promise<boolean>;
  showEnableButton: boolean = false;
  expandedIndex: any;

  constructor(public dialog: MatDialog, injector: Injector, private alertService: AlertService,
    private loyaltyMerchantService: LoyaltyMerchantService) {
    super(injector);
  }

  ngOnInit() {
    this.loyaltyMerchantDetails = new LoyaltyMerchant();
    this.loyaltyMerchantId = this.loyaltyMerchantDetails.id;
    this.detailsLoaded = Promise.resolve(true);
    //this.getAllPoints(this.loyaltyMerchantId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['loyaltyMerchantDetailsInput']) {
      setTimeout(() => {
        this.loyaltyMerchantDetails = this.loyaltyMerchantDetailsInput;
        this.loyaltyMerchantId = this.loyaltyMerchantDetails.id;
        this.loyaltyMerchantLogoTxtOnErr = false;
        this.detailsLoaded = Promise.resolve(true);
        this.getAllPoints(this.loyaltyMerchantId);

      });
    }
  }

  getAllPoints(merchantId) {
    this.loyaltyMerchantService.getMerchantCards(merchantId)
      .subscribe(result => {
        this.merchantPoints = result;
      });
  }
  createUserPoint() {
    const createUserPointDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    createUserPointDialogConfig.disableClose = true;
    createUserPointDialogConfig.autoFocus = true;
    createUserPointDialogConfig.panelClass = "golp-dialog";

    var userPoint = new LoyaltyMerchantPoint();
    userPoint.loyaltyMerchantId = this.loyaltyMerchantId;
    createUserPointDialogConfig.data = userPoint;

    const createUserPointDialog = this.dialog.open(CreateLoyaltyMerchantPointComponent, createUserPointDialogConfig);

    const successfullCreate = createUserPointDialog.componentInstance.onPointCreateEvent.subscribe((data) => {
      this.getAllPoints(this.loyaltyMerchantId);
    });

    createUserPointDialog.afterClosed().subscribe(() => {
    });


  }


  //changeStatus(item: UserPoint, active: boolean) {
  //  const confirmationDialogConfig = new MatDialogConfig()
  //  var text = "Are you sure to activate this user credit?";
  //  var successMessage = "User credit activated successfully";
  //  if (active == false) {
  //    text = "Are you sure to deactivate this user credit?";
  //    successMessage = "User credit deactivated successfully";
  //  }
  //  var userPoint = new UserPoint();
  //  userPoint.isActive = active;
  //  userPoint.id = item.id;
  //  // Setting different dialog configurations
  //  confirmationDialogConfig.data = text;
  //  confirmationDialogConfig.panelClass = 'golp-dialog';
  //  const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

  //  confirmationDialog.afterClosed().subscribe((result) => {
  //    if (result != undefined) {
  //      this.userPointService.changeUserPointStatus(userPoint).subscribe(result => {
  //        this.alertService.success(successMessage);
  //        this.getUserPoint();
  //        this.getUserPointSummary();
  //      });
  //    }
  //  });
  //}


  //changeStatusForAll(active: boolean) {
  //  const confirmationDialogConfig = new MatDialogConfig()
  //  var text = "Are you sure to activate user credits?";
  //  var successMessage = "User credits activated successfully";
  //  if (active == false) {
  //    text = "Are you sure to deactivate user credits?";
  //    successMessage = "User credits deactivated successfully";
  //  }
  //  var userPoint = new UserPoint();
  //  userPoint.isActive = active;
  //  userPoint.id = null;
  //  userPoint.userId = this.userId;
  //  // Setting different dialog configurations
  //  confirmationDialogConfig.data = text;
  //  confirmationDialogConfig.panelClass = 'golp-dialog';
  //  const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

  //  confirmationDialog.afterClosed().subscribe((result) => {
  //    if (result != undefined) {
  //      this.userPointService.changeAllUserPointStatus(userPoint).subscribe(result => {
  //        this.alertService.success(successMessage);
  //        this.getUserPoint();
  //        this.getUserPointSummary();
  //      });
  //    }
  //  });
  //}

  expandCollapse(index) {
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }
}
