import * as moment from 'moment';


export class GetGiftTransactionReportInputDto {
  from: string;
  to: string;
  type: any;
  paidThrough: any;
  paymentStatus: any;
  senderPhoneNumber: any;
  receiverPhoneNumber: any;
  pageNumber: number;
  pageSize: number;

  public constructor(init?: Partial<GetGiftTransactionReportInputDto>) {
    Object.assign(this, init);
  }
}
