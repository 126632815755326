import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LoginModel } from "../models/login-model";
import { Observable, Subject } from "rxjs";
import { UserModel } from "../models/user-model";
import { PermissionCheckerService } from "./permission-checker.service";
import { ResetPasswordModel } from "../models/reset-password-model";
import { PremiumUserPagedListInput, TabModel } from "../models";
import { GolpAppUtil } from '../utility/golp-app-utils';

@Injectable({
  providedIn: "root",
})
export class AccountServiceService {
  private authenticationBaseUri = "api/auth";
  private accountBaseUri = "api/account";
  private consumerBaseUri = "api/ServiceConsumers";

  private userModelChanged = new Subject<UserModel>();

  currentMerchant: number;

  constructor(
    private http: HttpClient,
    private permissionCheckerService: PermissionCheckerService

  ) { }


  userAuthentication(loginModel: LoginModel) {
    var requestHeader = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "No-Auth": "True",
      }),
    };
    return this.http.post(
      this.authenticationBaseUri,
      loginModel,
      requestHeader
    );
  }

  sendOtp(otpModel: any) {
    var requestHeader = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "No-Auth": "True",
      }),
    };
    return this.http.post(
      this.authenticationBaseUri + "/otp",
      otpModel,
      requestHeader
    );
  }

  consumerAuthentication(loginModel: any) {
    var requestHeader = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "No-Auth": "True",
      }),
    };
    return this.http.post(this.consumerBaseUri, loginModel, requestHeader);
  }

  getLoggedInUserInfo() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    userInfo.isSystemAdmin = userInfo.roles.some(function (role) {
      return role.name === "SystemAdmin";
    });
    return userInfo;
  }

  getMenu(): any[] {
    //let menus: any[] = this.menuItems;

    let userInfo = this.getLoggedInUserInfo();
    this.currentMerchant = Number(userInfo.companyId);
    let userRole = userInfo.roles[0].name;

    let menus: any[] = this.getMenuItems();
    return menus;
  }

  getUsersByCompany(companyId: number): Observable<any[]> {
    return this.http.get<any[]>(
      this.accountBaseUri + "/company/" + companyId + "/users"
    );
  }

  getHostUsersByCompany(): Observable<any[]> {
    return this.http.get<any[]>(this.accountBaseUri + "/host/users");
  }

  getPremiumUsers(): Observable<any[]> {
    return this.http.get<any[]>(this.accountBaseUri + "/host/premiumUsers");
  }

  getPremiumUsersPagedList(input: PremiumUserPagedListInput): Observable<any> {
    var requestHeader = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "No-Auth": "False",
      }),
    };
    return this.http.post<any[]>(
      this.accountBaseUri + "/host/premiumUsers/paged-list",
      input,
      requestHeader
    );
  }

  getMerchantUsersByCompany(companyId: number): Observable<any[]> {
    return this.http.get<any[]>(
      this.accountBaseUri + "/company/" + companyId + "/merchantUsers"
    );
  }

  createUser(user: UserModel) {
    var requestHeader = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "No-Auth": "False",
      }),
    };
    return this.http.post(
      this.authenticationBaseUri + "/register",
      user,
      requestHeader
    );
  }

  createPremiumUser(user: UserModel) {
    var requestHeader = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "No-Auth": "False",
      }),
    };
    return this.http.post(
      this.authenticationBaseUri + "/register/premium-users",
      user,
      requestHeader
    );
  }

  editUser(user: UserModel) {
    var requestHeader = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "No-Auth": "False",
      }),
    };
    return this.http.put(
      this.accountBaseUri + "/updateuser",
      user,
      requestHeader
    );
  }

  editPremiumUser(user: UserModel) {
    var requestHeader = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "No-Auth": "False",
      }),
    };
    return this.http.put(
      this.accountBaseUri + "/updatePremiumuser",
      user,
      requestHeader
    );
  }

  deleteUser(userId: number) {
    var requestHeader = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "No-Auth": "False",
      }),
    };
    return this.http.delete(this.accountBaseUri + "/" + userId, requestHeader);
  }

  resetUserPassword(resetPasswordModel: ResetPasswordModel) {
    const requestHeader = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "No-Auth": "False",
      }),
    };
    return this.http.put(
      this.authenticationBaseUri + "/resetpassword",
      resetPasswordModel,
      requestHeader
    );
  }

  uploadFile(uploadedFileData: FormData) {
    var requestHeader = {
      headers: new HttpHeaders({
        enctype: "multipart/form-data",
        "No-Auth": "False",
      }),
    };
    return this.http.post(
      this.accountBaseUri + "/upload-file",
      uploadedFileData
    );
  }

  uploadImage(uploadedFileData: FormData) {
    var requestHeader = {
      headers: new HttpHeaders({
        enctype: "multipart/form-data",
        "No-Auth": "False",
      }),
    };
    return this.http.post(
      this.accountBaseUri + "/upload-image",
      uploadedFileData
    );
  }

  updateProfileSettings(user: UserModel) {
    var requestHeader = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "No-Auth": "False",
      }),
    };
    return this.http.put(
      this.accountBaseUri + "/update-profile-settings",
      user,
      requestHeader
    );
  }

  updateAccountSettings(user: UserModel) {
    var requestHeader = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "No-Auth": "False",
      }),
    };
    return this.http.put(
      this.authenticationBaseUri + "/update-account-settings",
      user,
      requestHeader
    );
  }

  userModelUpdate(user: UserModel) {
    this.userModelChanged.next(user);
  }
  getUpdatedUserModel(): Observable<any> {
    return this.userModelChanged.asObservable();
  }

  storeBrowserTabId() {
    let localStorageTabs: TabModel[] = [];
    const sessionTabId: number = Number(sessionStorage.getItem(GolpAppUtil.TAB_ID));
    localStorageTabs = JSON.parse(localStorage.getItem(GolpAppUtil.TABS));
    if (sessionTabId != 0) { // existing active tabs ?
      if (localStorageTabs != null || localStorageTabs != undefined) {
        localStorageTabs.find(item => item.tabId == sessionTabId).status = true; //tab is refreshed, setting back to active.
      }
    } else { //new tab
      const tabId = new Date().getTime(); //tab Id
      sessionStorage.setItem(GolpAppUtil.TAB_ID, JSON.stringify(tabId));
      let tabmodel: TabModel = new TabModel();
      tabmodel.tabId = tabId;
      tabmodel.status = true; //tab is active
      if (localStorageTabs == null) {
        localStorageTabs = [];
      }
      localStorageTabs.push(tabmodel);
    }
    localStorage.setItem(GolpAppUtil.TABS, JSON.stringify(localStorageTabs));
  }

  getMenuItems(): any[] {
    var permittedMenus = [];
    const walletSummary = JSON.parse(localStorage.getItem("_walletSummary"));
    let userInfo = this.getLoggedInUserInfo();
    let userRole = userInfo.roles[0].name;

    let subscriptions: any = JSON.parse(localStorage.getItem("_sub"));
    let menus: any = JSON.parse(localStorage.getItem("_menus"));
    let giftCardSubmenus = [];
    let offerSubmenus = [];
    let privilegeCardSubmenus = [];
    let supportSubMenus = [];
    //permittedMenus.push({ 'name': 'Dashboard', 'routerLink': '/dashboard' });

    if (this.permissionCheckerService.hasPermission(["ProviderRead"])) {
      permittedMenus.push({
        name: "Gift Senders",
        routerLink: "/gift-senders",
      });
    }

    if (userRole == "SystemAdmin" || userRole == "SalesManager") {
      permittedMenus.push({
        name: "Revenue Dashboard",
        routerLink: "/revenue-dashboard",
      });
    }

    if (
      this.permissionCheckerService.hasPermission(["MerchantRead"]) &&
      userRole != "ServiceConsumer"
    ) {
      permittedMenus.push({ name: "Merchant", routerLink: "/merchants" });
    }
    if (
      this.permissionCheckerService.hasPermission(["TrendingMerchantRead"]) &&
      userRole != "ServiceConsumer"
    ) {
      permittedMenus.push({
        name: "Trending Merchants",
        routerLink: "/trending-merchants",
      });
    }

    //subscription privilege card
    if (subscriptions?.golpCard || this.permissionCheckerService.hasPermission(["SystemAdmin"])) {

      if (this.permissionCheckerService.hasPermission(["ViewStakeHolder"])) {
        privilegeCardSubmenus.push({
          name: "Manage Stakeholders",
          routerLink: "/manage-stakeholders",
        });
      }

      if (this.permissionCheckerService.hasPermission(["CreateGolpCard"])) {
        privilegeCardSubmenus.push({
          name: "Manage Card",
          routerLink: "/privilege-card",
        });
      }

      if (this.permissionCheckerService.hasPermission(["AddCardBeneficiary"])) {
        privilegeCardSubmenus.push({
          name: "Card Assignment",
          routerLink: "/privilege-card/show-card",
        });
      }

      if (this.permissionCheckerService.hasPermission(["ViewGolpCardTransaction"])) {
        privilegeCardSubmenus.push({
          name: "Card Transaction",
          routerLink: "/privilege-card/card-transaction",
        });
      }

      if (privilegeCardSubmenus.length > 0) {
        permittedMenus.push({
          name: "Privilege Card",
          routerLink: null,
          submenus: privilegeCardSubmenus,
          showSubmenus: false
        });
      }
    }

    if (subscriptions?.giftCard) {

      if (this.permissionCheckerService.hasPermission(["ViewGiftCard"])) {
        permittedMenus.push({ name: "Dashboard", routerLink: "/dashboard", });
      }

      if (this.permissionCheckerService.hasPermission(["ViewGiftCard"])) {
        giftCardSubmenus.push({ name: "Sell Info", routerLink: "/gift-sent-history", });
      }

      giftCardSubmenus.push(
        {
          name: "Transaction",
          routerLink: "/gift-sell-report",
        });

      giftCardSubmenus.push(
        {
          name: "Gift Vs Sales Amount",
          routerLink: "/gift-vs-sales-amount-report",
        });
      giftCardSubmenus.push(
        {
          name: "Gift Vs Redemption",
          routerLink: "/gift-vs-redeemption-report",
        });

      giftCardSubmenus.push(
        {
          name: "Expired Info",
          routerLink: "/gift-expiry-report",
        });

      giftCardSubmenus.push(
        {
          name: "Transaction Summary",
          routerLink: "/gift-sell-report-summary",
        });

      giftCardSubmenus.push(
        {
          name: "Gift Card Summary",
          routerLink: "/gift-summary-report",
        });

      giftCardSubmenus.push(
        {
          name: "SMS Status",
          routerLink: "/sms-status",
        });

      if (this.currentMerchant != null && this.currentMerchant > 0) {

        giftCardSubmenus.push(
          {
            name: "Gift Delivery Process",
            routerLink: `/upload-amount-report/merchant/${this.currentMerchant}`,
          });
      }

      if (this.permissionCheckerService.hasPermission(["ViewGiftCard"])) {
        permittedMenus.push({
          name: "Gift Cards",
          routerLink: "/send-gift-dashboard"
        });
      }

      if (this.permissionCheckerService.hasPermission(["ViewGiftCard"]) && userRole == "MerchantAdmin") { 
        permittedMenus.push({ name: "Bulk Gift", routerLink: "/bulk-gift", });
      }
    }

    if (
      this.permissionCheckerService.hasPermission(["OfferRead"]) && !subscriptions?.giftCard &&
      userRole != "ServiceConsumer"
    ) {
      permittedMenus.push({ name: "Gifts Management", routerLink: "/gifts" });
    }

    if (
      this.permissionCheckerService.hasPermission(["ViewCorporateOffer"]) && subscriptions?.offerCard &&
      userRole != "ServiceConsumer"
    ) {
      permittedMenus.push({
        name: "Offers",
        routerLink: null,
        submenus: offerSubmenus,
        showSubmenus: false
      });
      offerSubmenus.push({ name: "Manage Offers", routerLink: "/corporate-offers" });
      offerSubmenus.push({ name: "Offer Eligibility", routerLink: "/gift-offer" });
      //offerSubmenus.push({ name: "Sent Offer", routerLink: "/sent-offer-report" });

    }

    if (
      this.permissionCheckerService.hasPermission(["ViewCorporateReport"]) && subscriptions?.offerCard &&
      userRole != "ServiceConsumer"
    ) {
      offerSubmenus.push({ name: "Offer Reports", routerLink: "/offer-reports" });
    }

    if (
      this.permissionCheckerService.hasPermission([
        "ReadGiftTemplate",
        "ViewGiftTemplateAssignments",
      ])
    ) {
      permittedMenus.push({
        name: "Gift Templates",
        routerLink: "/gift-templates",
      });
    }

    if (this.permissionCheckerService.hasPermission(["SystemAdmin"])) {
      permittedMenus.push({
        name: "Golp Products",
        routerLink: "/golp-products",
      });
    }
    if (
      this.permissionCheckerService.hasPermission(["ConsumerRead"]) &&
      userRole == "ServiceConsumer"
    ) {
      permittedMenus.push({ name: "Gifts Received", routerLink: "/receive-gifts", });
      permittedMenus.push({ name: "Gifts Sent", routerLink: "/send-gifts" });
      permittedMenus.push({ name: "My Offers", routerLink: "/my-offers" });
      if (this.permissionCheckerService.hasPermission(["ViewOwnGolpCard"])) {
        permittedMenus.push({
          name: "Golp Privilege Cards",
          routerLink: "/privilege-card/my-privilege-card",
        });
      }
    }

    if (this.permissionCheckerService.hasPermission(["ProviderInvoiceCreate"])) {
      permittedMenus.push({ name: "Corporate Billing", routerLink: "/bill-process" });
    }

    if (this.permissionCheckerService.hasPermission(["ReadInvoice"]) && !this.permissionCheckerService.hasPermission(["ProviderRead"])) {
      permittedMenus.push({ name: "Merchant Billing", routerLink: "/billings" });
    }

    if (userRole == 'SalesManager' || userRole == 'SystemAdmin' || walletSummary) {
      permittedMenus.push({
        name: "Wallet",
        routerLink: "/wallet",
      });
    }

    //stock status
    if (menus != undefined && menus != null && menus.length > 0) {
      menus.forEach(m => {
        if (m.menuName.toLowerCase() == "vouchers"
          && (this.permissionCheckerService.hasPermission(["ViewVoucher"]) || this.permissionCheckerService.hasPermission(["ViewVoucherSummary"])))
          permittedMenus.push({ name: "Vouchers", routerLink: "/vouchers" });

      });
    }

    if (
      this.permissionCheckerService.hasPermission(["OfferAvailAccept"]) ||
      userRole == "MerchantAdmin"
    ) {
      let name = "Avail Process";
      if (userRole == "MerchantAdmin")
        name = "Gift Transaction"
      permittedMenus.push({
        name: name,
        routerLink: "/avail-process",
      });
    }

    if (userRole == "MerchantAdmin") {
      permittedMenus.push({ name: "Products", routerLink: "/merchant-products" });
    }

    if (subscriptions?.giftCard) {
      if(userRole == "MerchantAdmin") {
        permittedMenus.push({
          name: "Gift Card Management",
          routerLink: "/manage-gift-card-orders"
        });
      }

      permittedMenus.push({
        name: "Gift Card Report",
        routerLink: null,
        submenus: giftCardSubmenus,
        showSubmenus: false
      });
    }




    if (this.permissionCheckerService.hasPermission(["ViewReports"])) {
      permittedMenus.push({ name: "Report", routerLink: "/reports" });
    }

    if (this.permissionCheckerService.hasPermission(["ViewCustomerSupport"])) {

      supportSubMenus.push({
        name: "Customer Support",
        routerLink: "/customer-supports",
      });

      supportSubMenus.push({
        name: "Manage Gift Orders",
        routerLink: "/manage-gift-orders",
      });

      permittedMenus.push({
        name: "Support & Manage",
        routerLink: null,
        submenus: supportSubMenus,
        showSubmenus: false
      });
    }

    //if (this.permissionCheckerService.hasPermission(['SystemAdmin'])) {
    //  permittedMenus.push({ 'name': 'Promotions', 'routerLink': '/promotions' });
    //}

    if (this.permissionCheckerService.hasPermission(["SystemAdmin"])) {
      permittedMenus.push({
        name: "Loyalty Merchants",
        routerLink: "/loyalty-merchants",
      });
    }

    if (this.permissionCheckerService.hasPermission(["SystemAdmin"])) {
      permittedMenus.push({ name: "Golp Admin", routerLink: "/golp-admin" });
    }

    // if (this.permissionCheckerService.hasPermission(["SystemAdmin"])) {
    //   permittedMenus.push({ name: "Corporate Offers", routerLink: "/corporate-offers" });
    // }


    if (this.permissionCheckerService.hasPermission(["SystemAdmin"])) {
      permittedMenus.push({
        name: "Premium Users",
        routerLink: "/premium-users",
      });
    }

    // if (this.permissionCheckerService.hasPermission(["ViewOrder"])) {
    //   permittedMenus.push({ name: "Manage Orders", routerLink: "/manage-orders" });
    // }

    if (userRole != "ServiceConsumer") {
      permittedMenus.push({ name: "Settings", routerLink: "/settings" });
    }

    // if (this.permissionCheckerService.hasPermission(['SystemAdmin'])) {
    //   permittedMenus.push({ 'name': 'Data History', 'routerLink': '/data-history' });
    // }

    return permittedMenus;
  }
}
