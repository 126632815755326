import { LoyaltySettingsKey } from "./loyalty-setting-type";

export class LoyaltyMerchantSetting {
  id: any;
  loyaltyMerchantId: any;
  loyaltySettingsKey: LoyaltySettingsKey;
  dataType: string;
  value: string;
  isEnabled: boolean;
  isDeleted: boolean;
  public constructor(init?: Partial<LoyaltyMerchantSetting>) {
    Object.assign(this, init);
  }
}
