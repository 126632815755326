import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as $ from 'jquery';

@Component({
  selector: 'app-avail-info',
  templateUrl: './avail-info.component.html',
  styleUrls: ['./avail-info.component.css']
})
export class AvailInfoComponent implements OnInit {
  giftInformation: any;


  constructor(private dialogRef: MatDialogRef<AvailInfoComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    if (data != null) {
      this.giftInformation = data;
      console.log("avail info : ", this.giftInformation);
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    printInvoice();
  }
  close() {
    this.dialogRef.close();
  }
}

function printInvoice() {
  $('#printInvoice').click(function () {
    Popup($('#invoice-details')[0]);
    function Popup(data) {
      window.print();
      return true;
    }
  });
}
