import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, Injector } from '@angular/core';
import { Company } from 'src/app/models/company';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { CreateMerchantComponent } from '../create-merchant/create-merchant.component';
import { BaseComponent } from '../base/base.component';
import { CompanyService, AlertService } from 'src/app/services';

@Component({
  selector: 'app-merchant-details',
  templateUrl: './merchant-details.component.html',
  styleUrls: ['./merchant-details.component.css']
})
export class MerchantDetailsComponent extends BaseComponent implements OnInit {

  @Input() merchantDetailsInput: Company;
  @Output() merchantDetailsInputChange: EventEmitter<Company> = new EventEmitter<Company>();

  merchantDetails: Company
  merchantLogoOnErr: String
  merchantLogoTxtOnErr = false;
  commissionBasedOn: string;

  detailsLoaded: Promise<boolean>;
  showEnableButton: boolean = false;

  constructor(public dialog: MatDialog, private injector: Injector,
    private companyService: CompanyService,
    private alertService: AlertService
  ) { super(injector); }

  ngOnInit() {
    this.merchantDetails = new Company();
    this.detailsLoaded = Promise.resolve(true);
    this.visibleEnableDisableButton();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['merchantDetailsInput']) {
      setTimeout(() => {
        this.merchantDetails = this.merchantDetailsInput;
        //this.merchantLogoOnErr = "http://place-hold.it/80x80/ebe89f/9D2933&fontsize=40&text=" +  this.merchantDetails.name.charAt(0);
        this.merchantLogoTxtOnErr = false;
        this.visibleEnableDisableButton();
        if (this.merchantDetails != null) {
          if (this.merchantDetails.salesCommissionType == 1) {
            this.commissionBasedOn = "Sales Amount";
          }
          else if (this.merchantDetails.salesCommissionType == 2) {
            this.commissionBasedOn = "Gift Amount";
          }
          else {
            this.commissionBasedOn = "Sales Amount";
          }
        }
        else {
          this.commissionBasedOn = "";
        }
        this.detailsLoaded = Promise.resolve(true);
      });
    }
  }

  enableDisableMerchant(merchant: Company, enable: boolean) {
    merchant.isActive = enable;
    this.companyService.enableDisableMerchant(merchant)
      .subscribe((data) => {
        this.visibleEnableDisableButton();
        let message = enable ? 'Merchant enabled successfully' : 'Merchant disabled successfully';
        this.alertService.success(message);
      });
  }

  editMerchant(merchant: Company) {
    const editDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    editDialogConfig.disableClose = true;
    editDialogConfig.autoFocus = true;

    editDialogConfig.data = merchant
    editDialogConfig.panelClass = 'golp-dialog';

    const merchantEditDialog = this.dialog.open(CreateMerchantComponent, editDialogConfig);

    const successFullEdit = merchantEditDialog.componentInstance.onMerchantEditEvent.subscribe((data) => {
      this.merchantDetails = data;
      this.merchantDetailsInputChange.emit(this.merchantDetails);
    });

    merchantEditDialog.afterClosed().subscribe(() => {
    });
  }

  visibleEnableDisableButton() {
    this.showEnableButton = this.merchantDetails.isActive ? false : true;
  }

  errorHandler(event) {
    //event.target.src = this.merchantLogoOnErr;
    this.merchantLogoTxtOnErr = true;
  }
}
