import { Component, OnInit, Input, Injector } from '@angular/core';
import { CompanyOutletService } from 'src/app/services/company-outlet.service';
import { Company } from 'src/app/models/company';
import { CreateOutletComponent } from '../create-outlet/create-outlet.component';
import { CompanyOutlet } from 'src/app/models/company-outlet';
import { CompanyQrCodeComponent } from '../company-qr-code/company-qr-code.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AlertService } from 'src/app/services';
import { BaseComponent } from '../base/base.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-outlets',
  templateUrl: './outlets.component.html',
  styleUrls: ['./outlets.component.css']
})
export class OutletsComponent extends BaseComponent implements OnInit {

  @Input() providerDetails: Company;
  companyOutlets :any[];
  companyId:number;
  constructor(private companyOutleService:CompanyOutletService, private dialog: MatDialog,
    private alertService: AlertService,
    injector: Injector
    ) {
    super(injector);
  }

  ngOnInit() {
  }
  ngOnChanges(){
    this.getCompanyOutlets();
  }
  addOutlet(merchant: Company){
    const outletDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    outletDialogConfig.disableClose = true;
    outletDialogConfig.autoFocus = true;

    outletDialogConfig.data = merchant.id
    outletDialogConfig.panelClass = 'golp-dialog';

    const outletCreateDialog = this.dialog.open(CreateOutletComponent, outletDialogConfig);

    const successFullEdit = outletCreateDialog.componentInstance.onOutletCreateEvent.subscribe((data) => {
      this.getCompanyOutlets();
    });

    outletCreateDialog.afterClosed().subscribe(() => {
    });

  }

  editOutlet(companyOutlet: CompanyOutlet){
    const editDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    editDialogConfig.disableClose = true;
    editDialogConfig.autoFocus = true;
    editDialogConfig.data = companyOutlet
    editDialogConfig.panelClass = 'golp-dialog';
    
    const outletEditDialog = this.dialog.open(CreateOutletComponent, editDialogConfig);

    const successFullEdit = outletEditDialog.componentInstance.onOutletEditEvent.subscribe((data) => {
      this.getCompanyOutlets();
    });

    outletEditDialog.afterClosed().subscribe(() => {
    });
  }

  onDeleteOutlet(outlet: CompanyOutlet): void{
    const confirmationDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    confirmationDialogConfig.data = "Are you sure to delete the outlet " + outlet.name;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if(result != undefined){
        this.deleteOutlet(outlet);
      }
    });
  }

  outletQrCode(companyOutlet: CompanyOutlet){
    const qrCodeDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    qrCodeDialogConfig.data = companyOutlet
    qrCodeDialogConfig.panelClass = 'golp-dialog';
    
    const outletEditDialog = this.dialog.open(CompanyQrCodeComponent, qrCodeDialogConfig);

    outletEditDialog.afterClosed().subscribe(() => {
    });
  }

  getCompanyOutlets(){
    this.companyOutleService.getOutletsByCompany(this.providerDetails.id)
      .subscribe(data => {
        this.companyOutlets = data;
      });
  }

  deleteOutlet(outlet: CompanyOutlet){
    this.companyOutleService.deleteOutlet(outlet.id).subscribe((data) => {
      this.alertService.success('Outlet deleted successfully');
      this.companyOutlets = this.companyOutlets.filter(function(item){
        if(item.id !== outlet.id){
          return true;
        }
        else{
          return false;
        }
      });
    },
    (error) => {
      //this.alertService.error('Internal server error happen');
      console.log(error);
    });
  }

}
