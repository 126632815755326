export enum TransactionType {
    None = 0,
    Cash = 1,
    Checque = 2,
    Bank = 3,
    BKash = 4,
    Rocket = 5,
    Card = 6,
    Invoice = 7,
    GolpCredit = 8
}
