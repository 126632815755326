export class CompanyOutlet {
  id: number;
  name: string;
  contactPerson: string;
  contactNumber: string;
  openFrom: string;
  openTo: string;
  address: string;
  companyId: number;
  areaId: number;
  cityId: number;
  qrCode: string;
  isActive: boolean = true ;
  public constructor(init?: Partial<CompanyOutlet>) {
    Object.assign(this, init);
  }
}
