import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OfferType, CompanyRole } from 'src/app/enums';
import { CompanyService, AccountServiceService, CommonService, AlertService, DataService } from 'src/app/services';
import { CorporateOffer } from '../../models/corporate-offer';
import { CorporateOfferService } from '../../services/corporate-offer.service';
import { CreateCorporateOfferComponent } from '../create-corporate-offer/create-corporate-offer.component';
import { CorporateOfferAssignmentComponent } from '../../corporate-offer-assignment/corporate-offer-assignment.component';
import { CorporateOfferConditionsComponent } from '../corporate-offer-conditions/corporate-offer-conditions.component';
import { CorporateOfferDetailsComponent } from '../corporate-offer-details/corporate-offer-details.component';
import { Injector } from '@angular/core';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { BaseComponent } from 'src/app/components/base/base.component';

@Component({
  selector: 'app-corporate-offers',
  templateUrl: './corporate-offers.component.html',
  styleUrls: ['./corporate-offers.component.css']
})

export class CorporateOffersComponent extends BaseComponent implements OnInit {

  // view childs
  @ViewChild(CorporateOfferAssignmentComponent) private assignmentChild: CorporateOfferAssignmentComponent;
  @ViewChild(CorporateOfferConditionsComponent) private conditionsChild: CorporateOfferConditionsComponent;
  @ViewChild(CorporateOfferDetailsComponent) private detailsChild: CorporateOfferDetailsComponent;

  userInfo: any;
  dataLoaded: Promise<boolean>;

  pageTitle: string;
  createLinkText: string;
  searchPlaceholderText: string;
  detailsTitleText: string;

  currentPage: number = 1;
  pageSize: number = 10;
  itemCountText: string;

  offers: CorporateOffer[] = [];
  offersPrimitiveList: any[];
  offerFilter: any = { offerName: '' };

  selectedOffer: CorporateOffer;
  selectedOfferIndex: number = -1;
  selectedOfferName: string;

  merchants: any[];
  merchantFilter: any = { name: '' };
  searchedMerchantLogo: string = null;
  showMerchantSearch: boolean = true;
  selectedMerchantIndex = -1;

  offerSubMenu: any[] = [];
  activeSubMenu: string = 'Details';
  selectedSubMenuIndex: number = 0;

  merchantSelection: string = "Merchants";
  isMerchantLoggedIn: boolean = false;
  resetSelection: boolean = false;
  selectedOfferId: any = null;

  constructor(private corporateOfferService: CorporateOfferService, public dialog: MatDialog,
    private companyService: CompanyService,
    private accountService: AccountServiceService,
    private commonService: CommonService,
    private alertService: AlertService,
    private dataService: DataService,
    injector: Injector
  ) {
    super(injector);
    this.getLoggedInUser();
    this.getOffers();
  }

  ngOnInit() {
    this.getMerchants();

    this.offerSubMenu.push({ "name": "Details", "dataTarget": "offerDetailsTab" });
    this.offerSubMenu.push({ "name": "Conditions", "dataTarget": "offerConditionsTab" });
    this.offerSubMenu.push({ "name": "Receivers", "dataTarget": "offerAssignmentsTab" });
    this.dataLoaded = Promise.resolve(true);
  }

  onCreateOffer() {
    const dialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'golp-dialog';
    //dialogConfig.width = "100%";
    dialogConfig.data = { offer: null };

    const offerCreateDialog = this.dialog.open(CreateCorporateOfferComponent, dialogConfig);

    const successfullCreate = offerCreateDialog.componentInstance.onCorporateOfferCreateEvent.subscribe((data) => {
      this.getOffers();
      this.highlightFirstItem(this.offers);
    });

    offerCreateDialog.afterClosed().subscribe(() => {
    });

  }

  onOfferChange(item, index) {
    if (this.selectedOfferIndex !== index) {
      this.selectedOfferIndex = index === this.selectedOfferIndex ? -1 : index;
      this.selectedOffer = item;
    }
    let body = document.getElementsByTagName('body')[0];
    body.classList.add("display-tab-content");
    this.commonService.itemSelectionUpdated(this.selectedOffer);
    if (this.activeSubMenu === "Receivers") {
      this.assignmentChild.getOffersByTemplate(false, this.selectedOffer.id, null);
    }
    else if (this.activeSubMenu === "Conditions") {
      this.conditionsChild.getConditionsByOffer(this.selectedOffer.id);
    }
  }

  onMerchantChange(merchant, index) {
    this.selectedMerchantIndex = index;
    this.offers = this.offersPrimitiveList.filter(c => c.merchantId === merchant.id);
    if (this.offers.length > 0) {
      this.selectedOfferIndex = 0;
      this.selectedOffer = this.offers[0];
    }
    this.searchedMerchantLogo = merchant.companyLogoUrl;
    this.merchantSelection = merchant.name.split(' ').slice(0, 2).join(' ');
    this.itemCount();
  }

  onSubMenuChange(name, index) {
    if (this.selectedOffer === undefined || this.selectedOffer === null) return;

    if (this.selectedSubMenuIndex != index) {
      this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
      this.activeSubMenu = this.offerSubMenu.filter(c => c.name === name)[0].name;
      if (this.activeSubMenu === "Receivers") {
        this.assignmentChild.getOffersByTemplate(false, this.selectedOffer.id, null);
      }
      else if (this.activeSubMenu === "Conditions") {
        this.conditionsChild.getConditionsByOffer(this.selectedOffer.id);
      }
    }
  }

  onActiveDeactive(offer: any) {
    const confirmationDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    confirmationDialogConfig.data = `Are you sure to ${offer.isActive ? 'deactivate' : 'activate'} the offer ${offer.offerName}`;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.corporateOfferService.put(`api/corporateoffers/offer-template-activate/${offer.id}`, null)
          .subscribe((response: any) => {
            if (response.success) {
              this.getOffers();
              this.alertService.success(response.data);
            } else if (!response.success) {
              this.alertService.error(response.data);
            }
          });
      }
    });
  }

  onDelete(offer: any) {
    const confirmationDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    confirmationDialogConfig.data = `Are you sure to delete the offer ${offer.offerName}`;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.corporateOfferService.put(`api/corporateoffers/offer-template-delete/${offer.id}`, null)
          .subscribe((response: any) => {
            if (response.success) {
              this.getOffers();
              this.alertService.success(response.data);
            } else if (!response.success) {
              this.alertService.error(response.data);
            }
          });
      }
    });
  }

  selectAllMerchant() {
    this.offers = this.offersPrimitiveList;
    if (this.offers.length > 0) {
      this.selectedOfferIndex = 0;
      this.selectedOffer = this.offers[0];
    }
    this.searchedMerchantLogo = null;
    this.merchantSelection = 'Merchant';
    this.itemCount();
    this.selectedMerchantIndex = -1;
  }

  getOfferDetailsChange(event) {
    this.resetSelection = true;
    this.selectedOfferId = (event.data != undefined) ? event.data.id : event;
    this.getOffers();
  }

  pageChanged(event) {
    this.selectedOfferIndex = 0;
    this.currentPage = event;
    let itemIndex = (this.currentPage * this.pageSize) - this.pageSize;
    this.selectedOffer = this.offers[itemIndex];
    this.itemCount();
  }

  itemCount() {
    let firstItem = (this.currentPage - 1) * this.pageSize + 1;
    let lastItem = (this.currentPage) * this.pageSize;

    this.itemCountText = firstItem + ' - ' + (lastItem < this.offers.length ? lastItem : this.offers.length) + ' of ' + this.offers.length + ' offers';

  }

  offerFilteredItem(value) {
    this.selectedOfferName = value;
    this.getOffers();
  }

  getOffers() {
    let url = `api/corporateoffers/offer-template/${this.userInfo.companyId}`
    this.dataService.get(url).subscribe((data: any) => {
      this.offers = data;
      this.offersPrimitiveList = data;

      if (CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
        this.offers = this.offers.filter(c => c.merchantId === this.userInfo.companyId);
      }
      this.highlightFirstItem(this.offers);
      this.itemCount();
    });
  }

  getMerchants() {
    this.companyService.getMerchants()
      .subscribe(data => {
        this.merchants = data;
      });
  }

  getLoggedInUser() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
    if (CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
      this.showMerchantSearch = false;
      this.isMerchantLoggedIn = true;
    }
  }

  highlightFirstItem(data: any[]) {
    if (data != null && data.length > 0) {
      this.selectedOffer = this.resetSelection ? data.filter(c => c.id == this.selectedOfferId)[0] : data[0];
      let index = data.findIndex((item, index) => {
        if (item.id === this.selectedOfferId) return true;
      });
      this.selectedOfferIndex = this.resetSelection ? index : 0;
      if (this.resetSelection && this.selectedOfferIndex > 0) {
        this.currentPage = Math.ceil(this.selectedOfferIndex / this.pageSize);
        this.selectedOfferIndex = this.selectedOfferIndex - ((this.currentPage - 1) * this.pageSize);
      }
      this.dataLoaded = Promise.resolve(true);
    }
  }
}

