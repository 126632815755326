export class LoyaltyMerchant {
  id: number;
  name: string;
  shortName: string;
  phone: string;
  email: string;
  address: string;
  webAddress: string;
  imageName: string;
  loyaltyMerchantCategoryId: number;
  companyLogo: FormData;
  companyLogoUrl: string;
  imageFullPath: string;
  companyCategory: string;
  isActive: boolean;

  public constructor(init?: Partial<LoyaltyMerchant>) {
    Object.assign(this, init);
  }
}
