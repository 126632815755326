import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as moment from 'moment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DataService } from 'src/app/components/app-send-gift/services/shared/data.service';
import { BlockGiftCardComponent } from 'src/app/components/block-gift-card/block-gift-card.component';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import { saveAs } from 'file-saver';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';

@Component({
  selector: 'app-order-items',
  templateUrl: './order-items.component.html',
  styleUrls: ['./order-items.component.css']
})
export class OrderItemsComponent implements OnInit {

  @BlockUI('offer-report-section') blockUI: NgBlockUI;

  offerReportForm: FormGroup;
  userInfo: any;

  currentPage: number = 1;
  pageSize: number = 25;
  expandedIndex: number = -1;
  totalItems: number = 0;

  defaultStartDate: Date = new Date();
  defaultEndDate: Date = new Date();
  paginationPageNumbers: any[];
  currentPageNumberDetails: string;
  submitted: boolean;

  resellerPartnerId: any;
  orderData: any;
  p: number = 1;

  constructor(private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private dataService: DataService) {
    var startDate1 = new Date();
    this.defaultStartDate = this.startOfMonth(startDate1);
    this.defaultEndDate = new Date();
    this.buildForm();
  }

  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.resellerPartnerId = this.userInfo.resellerId;
    this.getOrderProcessedData();
  }

  buildForm() {
    this.offerReportForm = this.formBuilder.group({
      fromDate: [this.defaultStartDate, Validators.required],
      toDate: [this.defaultEndDate, Validators.required],
      senderReceiverNumber: '',
    });
  }
  startOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }
  exportToExcel() {

  }

  getOrderProcessedData() {

    this.submitted = true;
    // stop here if form is invalid
    if (this.offerReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    let fromDate = moment(this.f.fromDate.value).format("YYYY/MM/DD");
    let todate = moment(this.f.toDate.value).format("YYYY/MM/DD");
    let senderReceiverNumber = this.f.senderReceiverNumber.value

    let partnerId = this.resellerPartnerId

    let url = `api/v1/ResellerPartners/query/orders/reseller-partner/${partnerId}?fromDate=${fromDate}&toDate=${todate}`;
    if (senderReceiverNumber != null && senderReceiverNumber != '') {
      url += `&senderReceiverNumber=${senderReceiverNumber}`;
    }

    this.dataService.get(url)
      .subscribe((response: any) => {
        if (response.success) {
          this.orderData = response.data;
        }
      });
  }

  onBlockGiftCard(giftId: any) {
    const blockGiftCardDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    blockGiftCardDialogConfig.disableClose = true;
    blockGiftCardDialogConfig.autoFocus = true;
    blockGiftCardDialogConfig.panelClass = "golp-dialog";

    blockGiftCardDialogConfig.data = giftId;

    const createAssignmentDialog = this.dialog.open(BlockGiftCardComponent, blockGiftCardDialogConfig);

    const successfullCreate = createAssignmentDialog.componentInstance.onGiftCardBlockEvent.subscribe((data) => {
      this.getOrderProcessedData();
    });

    createAssignmentDialog.afterClosed().subscribe(() => {
    });
  }

  exportXLReport() {
    let url = `api/v1/ResellerPartners/query/orders/excel-export`;
    var input = {
      'resellerPartnerId': this.resellerPartnerId,
      'fromDate': moment(this.f.fromDate.value).format("YYYY/MM/DD"),
      'toDate': moment(this.f.toDate.value).format("YYYY/MM/DD"),
      'senderReceiverNumber': this.f.senderReceiverNumber.value,
      'companyId': this.userInfo.companyId
    };

    this.dataService.postWithOptions(url, input, {
      responseType: 'blob'
    }).subscribe((response: any) => {
      this.downloadFile(response);
    }, (err) => console.log(err));
  }

  downloadFile(data: any) {
    const blob2 = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var filename = "Order_Transaction" + "-" + moment().format('MMMM Do YYYY, h:mm:ss').valueOf() + ".xlsx";
    saveAs(blob2, filename);
  }

  blockNotes(notes: any) {
    const confirmationDialogConfig = new MatDialogConfig()
    // Setting different dialog configurations
    confirmationDialogConfig.data = notes;
    confirmationDialogConfig.panelClass = 'golp-dialog';

    const confirmationDialog = this.dialog.open(AlertDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
  }

  blockingNotAllow(notes: any) {
    const confirmationDialogConfig = new MatDialogConfig()
    // Setting different dialog configurations
    confirmationDialogConfig.data = `This gift has been redeemed or it is an online merchant. This gift is not allowed to block. Please contact with Golp.`;
    confirmationDialogConfig.panelClass = 'golp-dialog';

    const confirmationDialog = this.dialog.open(AlertDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
      }
    });
  }

  get f() { return this.offerReportForm.controls; }
}
