import { Component, OnInit, SimpleChanges, Injector } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { AlertService, GiftTemplateService } from '../../services';
import { BaseComponent } from '../base/base.component';
import { GiftTemplateAssignmentPagedListInputDto, GiftTemplateAssociationDto, AssignGiftTemplateInput } from '../../models';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { SharedService } from '../../services/shared.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AssignGiftTemplateComponent } from '../assign-gift-template/assign-gift-template.component';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';

@Component({
  selector: 'app-gift-template-assignments',
  templateUrl: './gift-template-assignments.component.html',
  styleUrls: ['./gift-template-assignments.component.css']
})


export class GiftTemplateAssignmentsComponent extends BaseComponent implements OnInit {
  @BlockUI('gift-template-assignment-block-ui') blockUI: NgBlockUI;

  serialNo: string = "";
  submitted: boolean = false;
  giftTemplateId: any;
  giftTemplateAssociations: GiftTemplateAssociationDto[] = [];
  //GiftTemplateAssignmentSummary: GiftTemplateAssignmentSummary;
  isSystemAdmin = false;
  currentPage: number = 1;
  pageSize: number = 10;
  expandedIndex: number = -1;
  totalItems: number = 0;

  paginationPageNumbers: any[];
  currentPageNumberDetails: string;

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private GiftTemplateAssignmentService: GiftTemplateService,
    injector: Injector
  ) {
    super(injector);
  }


  ngOnInit() {
    this.buildForm();

    this.getGiftTemplateAssignments();
    this.paginationPageNumbers = this.sharedService.getPaginationByPageNumbers();
  }

  ngOnChanges() {

  }


  giftTemplateForm: FormGroup;



  buildForm() {
    this.giftTemplateForm = this.formBuilder.group({
      serialNo: [this.serialNo],

      //fromDate: [this.defaultStartDate, Validators.required],
      //toDate: [this.defaultEndDate, Validators.required],
      //merchantId: [this.merchantId, Validators.required],
    });
  }



  get f() { return this.giftTemplateForm.controls; }


  assignGiftTemplate() {

    if (!this.giftTemplateAssociations.find(x => x.selected)) {

      const confirmationDialogConfig = new MatDialogConfig()

      // Setting different dialog configurations
      confirmationDialogConfig.data = "Please select at least one gift template.";
      confirmationDialogConfig.panelClass = 'golp-dialog';


      return;
    }


    var data = new AssignGiftTemplateInput();
    var selectedGiftAssociations = this.giftTemplateAssociations.filter(x => x.selected == true);

    const selectedIds = selectedGiftAssociations.map(({ id }) => id);

    data.giftTemplateAssociationIds = selectedIds;


    const assignGiftTemplateAssignmentDialogConfig = new MatDialogConfig();

    assignGiftTemplateAssignmentDialogConfig.disableClose = true;
    assignGiftTemplateAssignmentDialogConfig.autoFocus = true;
    assignGiftTemplateAssignmentDialogConfig.panelClass = "golp-dialog";

    assignGiftTemplateAssignmentDialogConfig.data = data;

    const assignGiftTemplateAssignmentDialog = this.dialog.open(AssignGiftTemplateComponent, assignGiftTemplateAssignmentDialogConfig);


    assignGiftTemplateAssignmentDialog.afterClosed().subscribe(() => {
    });
  }



  editGiftTemplateAssociation(giftTemplateAssignment: any) {
    var data = new AssignGiftTemplateInput();
    //var selectedGiftAssociations = this.giftTemplateAssociations.filter(x => x.selected == true);

    //const selectedIds = selectedGiftAssociations.map(({ id }) => id);

    var ids = [];
    ids.push(giftTemplateAssignment.id);

    data.giftTemplateAssociationIds = ids;
    data.phoneNumber = giftTemplateAssignment.phoneNumber;
    data.soldDate = giftTemplateAssignment.soldOn;
    data.serialNo = giftTemplateAssignment.serialNo;


    const assignGiftTemplateAssignmentDialogConfig = new MatDialogConfig();

    assignGiftTemplateAssignmentDialogConfig.disableClose = true;
    assignGiftTemplateAssignmentDialogConfig.autoFocus = true;
    assignGiftTemplateAssignmentDialogConfig.panelClass = "golp-dialog";

    assignGiftTemplateAssignmentDialogConfig.data = data;

    const assignGiftTemplateAssignmentDialog = this.dialog.open(AssignGiftTemplateComponent, assignGiftTemplateAssignmentDialogConfig);


    assignGiftTemplateAssignmentDialog.afterClosed().subscribe(() => {
    });
  }


  selection(item) {
    item.selected = !item.selected;
    //this.calculateTotal();

  }

  onChangeSelectAll(event) {
    if (event.checked) {
      this.giftTemplateAssociations.forEach(obj => {
        obj.selected = true;
      });
    }
    else {
      this.giftTemplateAssociations.forEach(obj => {
        obj.selected = false;
      });
    }
    // this.calculateTotal();
  }

  getGiftTemplateAssignments() {
    var input = new GiftTemplateAssignmentPagedListInputDto();
    input.serialNo = this.serialNo;
    input.pageNumber = this.currentPage;
    input.pageSize = this.pageSize;

    this.blockUI.start();

    this.GiftTemplateAssignmentService.getGiftTemplateAssociationPagedList(input).subscribe(result => {
      this.giftTemplateAssociations = result.data;
      this.totalItems = result.rowCount;
      this.currentPageNumberDetails = "(Showing " + result.firstRowOnPage + " to " + result.lastRowOnPage + " of " + result.rowCount + " items)"

      this.blockUI.stop();

    }, () => {
      this.blockUI.stop();
    });
  }

  pageChanged(event) {
    this.currentPage = event;
    this.getGiftTemplateAssignments();
  }

  itemCount() {
  }


  onSearchGiftTemplateAssignment() {
    this.submitted = true;

    this.serialNo = this.f.serialNo.value;

    if (!this.serialNo) {
      this.submitted = false; // made false to remove the error message if no name is searched
    }
    //if (this.giftTemplateForm.invalid) {
    //  return;
    //}

    this.getGiftTemplateAssignments();
  }

  expandCollapse(index) {
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }
}

