import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as moment from 'moment';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { MerchantOutletResponse } from 'src/app/models/view-models/merchant-outlet-response';
import { AlertService } from 'src/app/services';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import { DataService } from '../../services/shared/data.service';

@Component({
  selector: 'app-sms-status',
  templateUrl: './sms-status.component.html',
  styleUrls: ['./sms-status.component.css']
})
export class SmsStatusComponent implements OnInit {
  merchantId: any;
  processId: any;
  userInfo: any;
  companyOutletId: any;
  merchantOutlets: any;
  orders: any;
  submitted: boolean;
  p: number = 1;
  offerReportForm: FormGroup;
  defaultStartDate: Date = this.startOfMonth(new Date());
  defaultEndDate: Date = new Date();

  currentPage: number = 1;
  count: number = 0;
  pageSize: number = 25;
  pageSizes = [25, 50, 100];
  paginationInfo: string = '';

  public defaultTime = [];
  smsList: any;

  constructor(private dataService: DataService, private formBuilder: FormBuilder, private alertService: AlertService, private dialog: MatDialog) {
    this.buildForm();
  }

  startOfMonth(date) {
    let startDateOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    startDateOfMonth.setMinutes(date.getMinutes() + 360); // 360 is for GMT+6 hours
    return startDateOfMonth;
  }

  ngOnInit(): void {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userInfo = userInfo;
    if (this.userInfo.outletQrCode == undefined || this.userInfo.outletQrCode == null)
      this.getMerchantOutlet();
    if (this.userInfo.outletQrCode != undefined && this.userInfo.outletQrCode != null)
      this.companyOutletId = this.userInfo.outletQrCode;
  }

  setDefaultTime() {
    let d = new Date();
    this.defaultTime = [d.getHours(), d.getMinutes(), d.getSeconds()]
  }

  handlePageChange(event: number): void {
    this.p = event;
    this.loadData();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = parseInt(event.target.value);
    this.p = 1;
    this.loadData();
  }

  getCurrentRange() {
    this.paginationInfo = `Showing ${((this.p - 1) * this.pageSize) + 1}-${((this.p - 1) * this.pageSize) + this.smsList?.length} of ${this.count} items`
  }

  loadData(reset: boolean = false) {
    if (reset) this.p = 1;
    this.submitted = true;
    // stop here if form is invalid
    if (this.offerReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }
    let deliveryStatus = this.f.deliveryStatus.value;

    let orderNo = this.f.orderNo.value;
    let invoiceNo = this.f.invoiceNo.value;
    let receiver = this.f.receiver.value;


    let inputDeliveryStatus = null;
    if (deliveryStatus == 1) {
      inputDeliveryStatus = true;
    }
    else if (deliveryStatus == 2) {
      inputDeliveryStatus = false;
    }
    else inputDeliveryStatus = null;

    let fromDate = moment(this.f.fromDate.value).format("YYYY/MM/DDTHH:mm:ss");
    let todate = moment(this.f.toDate.value).format("YYYY/MM/DDTHH:mm:ss");
    let outletId = this.f.outletId.value;
    if (outletId != 0)
      this.companyOutletId = outletId.qrCode;
    let url = `api/giftcard/order-sms-delivery-status/merchant/${this.userInfo.companyId}?fromDate=${fromDate}&toDate=${todate}&pageSize=${this.pageSize}&pageNumber=${this.p}`;
    if (this.userInfo.outletQrCode != null || this.userInfo.outletQrCode != undefined || outletId != 0)
      url += `&outletId=${this.companyOutletId}`;
    if (inputDeliveryStatus != null)
      url += `&isDelivered=${inputDeliveryStatus}`;

    if (receiver != null && receiver != undefined && receiver != "") {
      url += `&receiver=${receiver}`;
    }

    if (invoiceNo != null && invoiceNo != undefined && invoiceNo != "") {
      url += `&invoiceNumber=${invoiceNo}`;
    }

    if (orderNo != null && orderNo != undefined && orderNo != "") {
      url += `&orderNumber=${orderNo}`;
    }
    this.dataService.get(url)
      .subscribe((response: any) => {
        if (response.success) {
          this.count = response.data.totalCount;
          this.smsList = response.data.orders;
          this.getCurrentRange();
        }
        else {
          console.log(response);
        }
      });
  }

  buildForm() {
    this.offerReportForm = this.formBuilder.group({
      fromDate: [this.defaultStartDate, Validators.required],
      toDate: [this.defaultEndDate, Validators.required],
      outletId: ['', Validators.nullValidator],
      processId: ['', Validators.nullValidator],
      deliveryStatus: ['', Validators.nullValidator],
      invoiceNo: ['', Validators.nullValidator],
      orderNo: ['', Validators.nullValidator],
      receiver: ['', Validators.nullValidator],
    });
  }

  get f() { return this.offerReportForm.controls; }

  showConfirmation(item) {
    if (item.hasConsumed) return;
    const confirmationDialogConfig = new MatDialogConfig();
    // Setting different dialog configurations
    confirmationDialogConfig.data = `Would you like to proceed? This operation can not be undone.`;
    confirmationDialogConfig.panelClass = "golp-dialog";
    const confirmationDialog = this.dialog.open(
      ConfirmationDialogComponent,
      confirmationDialogConfig
    );

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.sendSms(item);
      }
    });
  }

  sendSms(item) {
    let url = `/api/GiftCard/resend-gift-code/merchant/${this.userInfo.companyId}`;
    let payload = [item.orderNumber];
    this.dataService.post(url, payload)
      .subscribe((response: any) => {
        if (response.status) {
          this.alertService.success("SMS sent successfully");
        }
        else {
          this.alertService.error("SMS sending failed");
        }
      }, err => { console.log("Err msg: ", err) });

  }

  getMerchantOutlet() {
    let url = `api/Outlets/company/${this.userInfo.companyId}/`;
    this.dataService.get(url).subscribe((data: MerchantOutletResponse[]) => {
      if (data != null && data.length > 0) {
        this.merchantOutlets = data;
      }
    });
  }
}
