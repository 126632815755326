import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as moment from 'moment';
import { MerchantOutletResponse } from 'src/app/models/view-models/merchant-outlet-response';
import { AlertService } from 'src/app/services';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import { DataService } from '../app-send-gift/services/shared/data.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-gift-with-offer-by-count',
  templateUrl: './gift-with-offer-by-count.component.html',
  styleUrls: ['./gift-with-offer-by-count.component.css']
})
export class GiftWithOfferByCountComponent implements OnInit {

  merchantId:any;
  processId: any;
  userInfo:any;
  companyOutletId:any;
  merchantOutlets:any;
  orders:any;
  submitted:boolean;
  p: number = 1;
  offerReportForm: FormGroup;
  defaultStartDate: Date = new Date();
  defaultEndDate: Date = new Date();
  currentPage: number = 1;
  count = 0;
  pageSize = 25;
  pageSizes = [25, 50, 100];
  multiSelectOutlet:any;
  public defaultTime = [];

  giftOrders:any;
  constructor(private dataService: DataService, private formBuilder: FormBuilder, private alertService: AlertService, private dialog: MatDialog) {
    var startDate1 = new Date();
    this.defaultStartDate = this.startOfMonth(startDate1);
    this.defaultEndDate = new Date();
    this.buildForm();
   }

   startOfMonth(date)
  {
    return new Date(date.getFullYear(), date.getMonth(), 1); 
  }

  ngOnInit(): void {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userInfo = userInfo;
    if(this.userInfo.outletQrCode == undefined || this.userInfo.outletQrCode == null) 
      this.getMerchantOutlet();
    if(this.userInfo.outletQrCode != undefined && this.userInfo.outletQrCode != null)
      this.companyOutletId = this.userInfo.outletQrCode;

      this.loadData();
  }

  setDefaultTime(){
    let d = new Date();
    this.defaultTime = [d.getHours(),d.getMinutes(),d.getSeconds()]
    console.log(this.defaultTime)
  }

  handlePageChange(event: number): void {
    this.p = event;
    this.loadData();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.p = 1;
    this.loadData();
  }

  loadData(reset:boolean = false){
    if(reset) this.p = 1;
    this.submitted = true;
    // stop here if form is invalid
    if (this.offerReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    let giftCountFrom = this.f.giftCountFrom.value;
    let giftCountTo = this.f.giftCountTo.value;


    let fromDate = moment(this.f.fromDate.value).format("YYYY/MM/DDTHH:mm:ss");
    let todate = moment(this.f.toDate.value).format("YYYY/MM/DDTHH:mm:ss");
    let outletId = this.f.outletId.value;
    if(outletId != 0)
      this.companyOutletId = outletId.qrCode;
    let url = `api/giftcard/gift-card-reports-by-count/merchant/${this.userInfo.companyId}?fromDate=${fromDate}&toDate=${todate}&pageSize=${this.pageSize}&pageNumber=${this.p}`;
    if(this.userInfo.outletQrCode != null || this.userInfo.outletQrCode != undefined || outletId != 0)
      url += `&outletId=${this.companyOutletId}`;

    if(giftCountFrom != null && giftCountFrom != undefined && giftCountFrom > 0){
      url += `&giftCountFrom=${giftCountFrom}`;
    }

    if(giftCountTo != null && giftCountTo != undefined && giftCountTo > 0){
      url += `&giftCountTo=${giftCountTo}`;
    }
    this.dataService.get(url)
    .subscribe((response: any) => {
      if(response.success){
        this.count = response.data.count;
        this.giftOrders = response.data.data;
      }
      else{
        console.log(response);
      }
    
    });
  }

  buildForm() {
    this.offerReportForm = this.formBuilder.group({
      fromDate: [this.defaultStartDate, Validators.required],
      toDate: [this.defaultEndDate, Validators.required],
      outletId: ['', Validators.nullValidator],
      giftCountFrom:['', Validators.nullValidator],
      giftCountTo: ['', Validators.nullValidator],
    });
  }

  getSerialNo(index){
    return ((this.pageSize * (this.p - 1))) + index + 1;
  }

  get f() { return this.offerReportForm.controls; }

  getMerchantOutlet() {
    let url = `api/Outlets/company/${this.userInfo.companyId}/`;
    // if(this.companyOutletId != null)
    //   url += `${this.companyOutletId}`
    this.dataService.get(url).subscribe((data: MerchantOutletResponse[]) => {
      if(data != null && data.length > 0){
        this.merchantOutlets = data;
        // this.multiSelectOutlet = this.merchantOutlets.map(p=>return {name:p});
      }
    });
  }

  allComplete: boolean = false;

  updateAllComplete() {
    this.allComplete = this.giftOrders != null && this.giftOrders.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.giftOrders == null) {
      return false;
    }
    return this.giftOrders.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.giftOrders== null) {
      return;
    }
    this.giftOrders.forEach(t => t.completed = completed);
  }

}
