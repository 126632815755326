export class GiftTemplateAssignmentPagedListInputDto {
  giftTemplateId?: any;
  giftTemplateAssociationId?: any;
  serialNo?: string;
  pageNumber: number;
  pageSize: number;

  public constructor(init?: Partial<GiftTemplateAssignmentPagedListInputDto>) {
    Object.assign(this, init);
  }
}
