import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AreaService, CityService, AlertService } from 'src/app/services';
import { Role } from 'src/app/models/role';
import { RolesService } from 'src/app/services/roles.service';
import { ValidationUtility } from '../../utility/validation.utility';

@Component({
  selector: 'app-create-role',
  templateUrl: './create-role.component.html',
  styleUrls: ['./create-role.component.css']
})

export class CreateRoleComponent implements OnInit {

  onRoleCreateEvent: EventEmitter<number> = new EventEmitter();;
  onRoleEditEvent: EventEmitter<number> = new EventEmitter();;


  roleForm: FormGroup
  submitted = false;
  selectedCity: number = 0;
  role: Role;
  cities: any[];
  areas: any[];

  roleScopes: any;

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateRoleComponent>,
    private roleService: RolesService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) data) {

    this.role = new Role();

    if (isNaN(data)) { // coming from role list page
      console.log(new Role(data));
      this.role = new Role(data);
    }
    else { // coming from merchant page
    }
    this.buildForm();
  }

  ngOnInit() {
    this.getRoleScopes();
  }

  getRoleScopes() {
    this.roleScopes = [{ text: 'None', value: 'None' }, { text: 'GOLP Admin', value: 'GOLPAdmin' }, { text: 'Merchant', value: 'Merchant' },
    { text: 'Provider', value: 'Provider' }, { text: 'Reseller', value: 'Reseller' }];
  }

  buildForm() {
    this.roleForm = this.formBuilder.group({
      name: [this.role.name, [Validators.required, Validators.maxLength(250)]],
      displayName: [this.role.displayName, [Validators.required, Validators.maxLength(100)]],
      roleScope: [this.role.roleScope, [Validators.required]],
    });
  }


  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.roleForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }
    if (this.role.id === undefined) {
      this.createRole();
    }
    else if (this.role.id > 0) {
      this.editRole();
    }
    this.dialogRef.close();
  }

  createRole() {
    this.role = new Role(this.roleForm.value);

    this.roleService.createRole(this.role)
      .subscribe((data: any) => {
        this.onRoleCreateEvent.emit();
        this.alertService.success("Role added successfully");
      },
        (error: any) => {
          this.showErrorMessage(error);
        });
  }

  editRole() {
    let newData = new Role(this.roleForm.value);
    if (this.role !== null) {
      this.role.name = newData.name;
      this.role.displayName = newData.displayName;
      this.role.roleScope = newData.roleScope;

      this.roleService.editRole(this.role)
        .subscribe((data: any) => {
          this.onRoleEditEvent.emit();
          this.alertService.success("Role edited successfully");
        },
          (error: any) => {
            this.showErrorMessage(error);
          });

    }
  }



  showErrorMessage(error: any) {
    console.log(error);
    this.alertService.error("Internal server error happen");
  }

  close() {
    this.dialogRef.close();
  }

  // convenience getter for easy access to form fields
  get f() { return this.roleForm.controls; }

}

