import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
declare var jQuery: any;
@Component({
  selector: 'app-popular-gift',
  templateUrl: './popular-gift.component.html',
  styleUrls: ['./popular-gift.component.css']
})
export class PopularGiftComponent implements OnInit {

  @Input() item: any;
  apiBaseUri: any;

  constructor(private router: Router) {
  }

  ngOnInit() {
  }
  ngAfterViewInit(): void {

    customClickOption(this);
  }
  itemClicked() {
    this.onSendPopularGift(this.item);
  }
  onSendPopularGift(item: any) {
    console.log("clicked..........");
    let giftInfo = {
      "amount": item.productPrice,
      "isPopularGift": true
    };

    let navigationEgolps: NavigationExtras = {
      state: {
        transd: 'popularGift',
        workQueue: false,
        services: 1,
        code: giftInfo
      }
    };

    this.router.navigate(['send-gift-information'], navigationEgolps);
  }

  r
}

function customClickOption(component) {
  (function ($) {
    $(".popular-items").click(function () {
      component.itemClicked();
    });
  })(jQuery);
}
