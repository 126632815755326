export class AvailOfferTransaction {
    offerId: number;
    consumerId: string;
    merchantId: number;
    merchantName: string;
    outletName: string;
    consumerPhoneNumber: string;
    offerAmount: number;
    purchaseAmount: number;
    salesCommission?: number;
    availDateUtc?: Date;
    availDateLocal: string;
}
