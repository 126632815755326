import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyService } from '../../services';

@Component({
  selector: 'app-show-avail-instructions',
  templateUrl: './show-avail-instructions.component.html',
  styleUrls: ['./show-avail-instructions.component.css']
})
export class ShowAvailInstructionsComponent implements OnInit {
  merchantName: string;
  availInstructions: any[] = [];

  constructor(private dialogRef: MatDialogRef<ShowAvailInstructionsComponent>,
    private companyService: CompanyService,
    @Inject(MAT_DIALOG_DATA) data) {

    if (data !== null) {
      this.merchantName = data.merchantName;

      if (data.merchantId) {
        this.getAvailInstructionsByCompany(data.merchantId);
      }
    }
  }

  ngOnInit() {
  }
  getAvailInstructionsByCompany(merchantId: any) {
    this.companyService.getMerchantAvailInstructionsByCompanyId(merchantId)
      .subscribe(data => {
        this.availInstructions = data.data.availInstructions;
      });
  }

  close() {
    this.dialogRef.close();
  }


}
