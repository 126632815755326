export class PromotionalGiftSetting {
  eligibleMerchants: number[];
  giftAmount: number;
  promotionalGiftType: number;
  validity: number;
  maxGiftAmount:number;
  minGiftSendAmount:number;
  giftSenderId:string;
  public constructor(init?: Partial<PromotionalGiftSetting>) {
    Object.assign(this, init);
  }
}
