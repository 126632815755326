import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../services/shared/data.service';

@Component({
  selector: 'app-gift-dashboard',
  templateUrl: './gift-dashboard.component.html',
  styleUrls: ['./gift-dashboard.component.css']
})
export class GiftDashboardComponent implements OnInit {

  dashboardData: any;
  userName: any;
  merchantId: any;
  companyOutletId: any;
  occasionsMessage: any[] = [];
  companyInfo: any;
  isMerchantAdmin: boolean = false;
  constructor(private dataService: DataService,
    private router: Router) {
    this.setUserInfo();
    this.getAndSetCompanyInfo();
    this.isMerchantAdmin = localStorage.getItem("userRole") == "MerchantAdmin";
  }


  setUserInfo() {
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.merchantId = userInfo.companyId;
    this.companyOutletId = userInfo.outletQrCode;
  }
  ngOnInit() {

    this.getDashboardData();
    this.userName = localStorage.getItem("name");
  }

  getAndSetCompanyInfo() {
    let companyJson = localStorage.getItem("userInfo");
    this.companyInfo = JSON.parse(companyJson);
  }
  onSendGift() {
    this.router.navigate(['/send-gift-information']);
  }

  getDashboardData() {
    this.dataService.get(`api/merchant/dashboard/${this.merchantId}`)
      .subscribe((response: DashboardResponse) => {
        if (response.success) {
          this.dashboardData = response.data;
          this.dashboardData?.occasions.forEach(element => {
            switch (element.occasionName) {
              case "Anniversary": {
                this.occasionsMessage.push("Happy Anniversary. Wish you a happy and prosperous Life. ")
                break;
              }
              case "Birthday": {
                this.occasionsMessage.push("Happy Birthday. Wish you a happy and prosperous Life. ")
                break;
              }
              case "Marriage": {
                this.occasionsMessage.push("Happy Marriage Day. Wish you a happy and prosperous Life. ")
                break;
              }
              case "Surprise": {
                this.occasionsMessage.push("Hoping this gift of love can bring little special moments in your life.")
                break;
              }
              case "Valentine's Gift": {
                this.occasionsMessage.push("Happy Valentine's Day. Wish you a happy and prosperous Life. ")
                break;
              }
              default: {
                break;
              }

            }
          });
        }
        else {
          console.log("Failed to parse dashboard data.")
        }
      }, (err) => { console.log("Failed to parse dashboard data.") });
  }

}

export interface DashboardResponse {
  success: boolean;
  data: DashboardResponseData;
}

export interface DashboardResponseData {
  product: Product[];
  occasions: Occasion[];
}

export interface Occasion {
  id: number;
  occasionName: string;
  description: null;
  isActive: boolean;
  imageName: string;
  imageUrl: string;
}

export interface Product {
  merchantId: number;
  productName: string;
  productPrice: number;
  productImage: string;
  id: string;
  domainEvents: null;
}
