import { Injectable } from '@angular/core';
import { Offer, GetOffersPagedListInput } from '../models';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GolpProductService {
    private offerBaseUrl = 'api/offers';
    private golpProductBaseUri = 'api/golp-products'

    constructor(private http: HttpClient) { }


    getGolpProductById(id: number): Observable<any> {
        return this.http.get<any>(this.golpProductBaseUri + "/" + id);
    }

    getGolpProducts(): Observable<any[]> {
        return this.http.get<any[]>(this.golpProductBaseUri);
    }
    getGolpProductsPagedList(input: GetOffersPagedListInput): Observable<any> {
        var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };

        return this.http.post<any[]>(`${this.golpProductBaseUri}/paged-list`, input, requestHeader);
    }

    createGolpProduct(offer: Offer) {
        var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
        return this.http.post(this.offerBaseUrl, offer, requestHeader);
    }

    editGolpProduct(offer: Offer) {
        var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
        return this.http.put(this.offerBaseUrl + '/' + offer.id, offer, requestHeader);
    }
}
