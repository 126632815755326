import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AccountServiceService } from 'src/app/services/account-service.service';
import { UserModel } from 'src/app/models';
import { PermissionCheckerService } from 'src/app/services/permission-checker.service';
declare var jQuery: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnChanges {

  userInfo: any;
  userFullName: string;
  menus: any[];
  profilePicturePath: string;

  selectedMenuIndex: number = 0;
  hastoShowProfileImagetext = false;
  hideNotificationIcon: boolean = false;
  baseUrl: any;
  hasGiftCardPermission: boolean = false;
  subscription: any;
  showReportSubmenu: boolean = false;
  selectedSubmenu = {
    link: "",
    selectedSubmenuIndex: -1
  }
  constructor(private router: Router,
    private accountService: AccountServiceService, private permissionService: PermissionCheckerService) {
    this.getSubscription();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // setShow();
  }

  ngOnInit() {
    this.baseUrl = this.router.url;
    this.getLoggedInUser();
    this.getMenu();
    this.setSelectedMenuForNavigation();
    this.accountService.getUpdatedUserModel().subscribe((user: UserModel) => {
      this.userInfo = user;
      if(this.userInfo.firstName != undefined && this.userInfo.firstName != null && this.userInfo.firstName != ""){
        this.userFullName = this.userInfo.firstName + ' ' + this.userInfo.lastName;
      }
      else if(localStorage.getItem('userRole') === 'ServiceConsumer'){
        this.userFullName = this.userInfo.phoneNumber;   
      }
      this.setProfilePicture(this.userInfo);
    });

    if (localStorage.getItem('userRole') === 'ServiceConsumer') {
      this.hideNotificationIcon = true;
    }
  }

  getSubscription() {
    let sub = JSON.parse(localStorage.getItem("_sub"));
    if (sub != undefined && sub != null) {
      if (sub.giftCard)
        this.hasGiftCardPermission = true;
    }
  }

  onMenuChange(item, index) {
    // if(item.name != "Sell Report" && item.name != "Transaction Report") {
    //   this.showReportSubmenu = false;

    //   this.menus.forEach((item , index)=> {
    //     if(item.name == "Sell Report")
    //       this.menus.splice(index, 2);
    //   });

    //   index = -2;
    // }

    if (item.showSubmenus != null && item.showSubmenus != undefined) {
      item.showSubmenus = !item.showSubmenus;
      setShow();
    }


    if (this.selectedMenuIndex != index) {
      this.selectedMenuIndex = index === this.selectedMenuIndex ? -1 : index;
    }
    this.setSelectedMenuForNavigation();
  }

  getLoggedInUser() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
    this.userFullName = `${this.userInfo.firstName != null ? this.userInfo.firstName : ''}  ${this.userInfo.lastName != null ? this.userInfo.lastName : ''}`;
    if(this.userInfo.firstName == null && localStorage.getItem('userRole') === 'ServiceConsumer'){
      this.userFullName = this.userInfo.phoneNumber;   
    }
    this.setProfilePicture(this.userInfo);
  }

  getMenu() {
    this.menus = this.accountService.getMenu();
  }
  setProfilePicture(user: UserModel) {
    if (user.profileImageUrl != null) {
      this.profilePicturePath = user.profileImageUrl;
    }
    else {
      this.profilePicturePath = user.profileImageName;
    }
  }

  // toggleReport() {
  //   this.selectedMenuIndex = 2;
  //   this.showReportSubmenu = !this.showReportSubmenu;
  //   // console.log(this.showReportSubmenu);
  //   this.menus.forEach((item , index)=> {
  //     if(item.name == "Sell Report")
  //       this.menus.splice(index, 2);
  //   });
  //   if(this.showReportSubmenu){
  //     setShow();

  //     this.menus.splice(3, 0, {
  //       name: "Sell Report",
  //       routerLink: "/gift-sent-history",
  //     });
  //     this.menus.splice(4, 0, {
  //       name: "Transaction Report",
  //       routerLink: "/sent-gift-report",
  //     });
  //   }
  //   console.log(this.menus);
  //   this.setSelectedMenuForNavigation()
  // }

  setSelectedMenuForNavigation() {
    let navigationUrl = `${this.router.url}`;
    if (navigationUrl != '/') {
      this.selectedMenuIndex = this.menus.findIndex(c => c.routerLink === navigationUrl);
      if (this.selectedMenuIndex < 0) {

        this.menus.forEach((item) => {
          if (item.showSubmenus != undefined && item.showSubmenus != null && item.showSubmenus) {
            let subItem = item.submenus.find(c => c.routerLink === navigationUrl);
            let subIndex = item.submenus.findIndex(c => c.routerLink === navigationUrl);
            if (subItem != undefined && subItem != null) {
              this.selectedSubmenu = {
                link: subItem.routerLink,
                selectedSubmenuIndex: subIndex
              }
            }
          }
        })
      }
      else {
        this.selectedSubmenu = {
          link: "",
          selectedSubmenuIndex: -1
        }
      }
    }
  }

  logout() {
    let userRole = localStorage.getItem('userRole');
    localStorage.clear();
    sessionStorage.clear();
    this.navigation(userRole);
  }

  errorHandlerOnProfileImage(event) {
    this.hastoShowProfileImagetext = true;
  }

  navigation(userRole) {
    if (userRole === 'ServiceConsumer') {
      this.router.navigate(['/loginwithphone']);
    }
    else {
      this.router.navigate(['/login']);
    }
  }

  goToHome() {
    let userRole = localStorage.getItem('userRole');
    let url = "/";
    if (this.hasGiftCardPermission && (userRole === 'MerchantAdmin' || userRole === 'OutletAdmin')) {
      url = "/dashboard";
    }
    else if (this.hasGiftCardPermission && (userRole === 'OutletUser')) {
      url = "/avail-process";
    }
    else if (this.hasGiftCardPermission && (userRole === 'ReportViewer')) {
      url = "/gift-sell-report";
    }

    this.router.navigate([url]);
  }
}
function setShow() {
  (function ($) {
    $(".navigation-menu .dropdown-menu").first().dropdown('toggle');;

  })(jQuery);
}