import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Select2OptionData } from 'ng-select2';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConstantText } from 'src/app/constants/constant.text';
import { AccountServiceService, DataService } from 'src/app/services';


@Component({
  selector: 'app-wallet-transaction',
  templateUrl: './wallet-transaction.component.html',
  styleUrls: ['./wallet-transaction.component.css']
})

export class WalletTransactionComponent implements OnInit {

  @Input() advanceBalanceInput: any;

  transactionsSubMenu: any;
  selectedSubMenuIndex = 0;
  activeSubMenu = "Deposit Transactions";

  walletBalance: any;
  depositTransactions: any;
  usedTransactions: any;
  userInfo: any;
  p: number = 1;

  merchantList: any;
  walletUser: any;
  showSelectSection: Boolean = false;

  advanceBalanceData: any;
  selectedWalletUser: any;

  constructor(
    private accountService: AccountServiceService,
    private dataService: DataService
  ) {
  }

  ngOnInit() {
    this.transactionsSubMenu = [
      {
        name: "Deposit Transactions",
        dataTarget: "DepositTab",
      },
      {
        name: "Used Transactions",
        dataTarget: "UsedTab",
      }
    ]
    this.loadOfferProvider();
    this.getUserInfo();
    this.getWalletBalance();
    this.getWalletTransactions();
  }

  getUserInfo() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
    const userRole = this.userInfo.roles[0].name;
    if(userRole == 'SystemAdmin' || userRole == 'SalesManager') {
      this.showSelectSection = true;
    }
  }

  loadOfferProvider() {
    let url = ConstantText.OfferProviderApi;
    this.dataService.get(url).subscribe(
        (data: any) => {
          this.merchantList = data;
          this.walletUser = this.merchantList.map((p) => {
            return {
              id: p.id,
              text: p.name,
            };
          });
          let noMerchant: Select2OptionData = {
            id: "-1",
            text: "Select Offer Provider",
          };
          this.walletUser.unshift(noMerchant);
        },
        catchError(() => {
          return throwError('Something went wrong!');
        })
      );
  }

  getWalletBalance() {
    this.dataService.get(`api/GolpWallet/balance?companyId=${this.userInfo.companyId}`)
      .subscribe((result: any) => {
        this.walletBalance = result.data;
      });
  }

  search() {
    this.userInfo.companyId = this.selectedWalletUser;
    this.getWalletBalance();
    this.getWalletTransactions();
  }

  getWalletTransactions() {
    this.dataService.get(`api/GolpWallet/transactions?companyId=${this.userInfo.companyId}`)
      .subscribe((result: any) => {
        this.depositTransactions = result.data?.walletTransactions?.filter(x => x.transactionType.toLowerCase() == 'debt');
        this.usedTransactions = result.data?.walletTransactions?.filter(x => x.transactionType.toLowerCase() == 'credit');
      });
  }

  onSubMenuChange(name, index) {
    if (this.selectedSubMenuIndex != index) {
      this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
      this.activeSubMenu = this.transactionsSubMenu.filter(c => c.name === name)[0].name;
    }
  }

}


