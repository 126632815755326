
export class GetGiftCustomerSupportInputDto {
  from: string;
  to: string;
  senderMobileNumber: any;
  receiverMobileNumber: any;
  pageNumber: number;
  pageSize: number;

  public constructor(init?: Partial<GetGiftCustomerSupportInputDto>) {
    Object.assign(this, init);
  }
}
