export class CompanyCreditModel {
  id: any;
  companyId: number;
  amount: number;
  isActive: boolean;
  status: string;
  createdDate: string;
  createdBy?: number;
  public constructor(init?: Partial<CompanyCreditModel>) {
    Object.assign(this, init);
  }
}
