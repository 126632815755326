export class GiftTemplateDto {
  id: any;
  name: string;
  amount: number;
  merchantId?: number;
  merchantName: string;
  selected: boolean;
  createdOn: string;
  isAllPrinted: boolean;
  expirationMonthType: any;
  giftTemplateAssociations: GiftTemplateAssociationDto[] = [];
  //isGiftCreated: boolean;
  //printedOn: string;
  //giftCreatedOn: string;

  public constructor(init?: Partial<GiftTemplateDto>) {
    Object.assign(this, init);
  }
}


export class GiftTemplateAssociationDto {
  id: any;
  giftTemplateId: any;
  name: string;
  amount: number;
  serialNo: string;
  phoneNumber: string;
  merchantId?: number;
  merchantName: string;
  selected: boolean;
  isActive: boolean;
  isPrinted: boolean;
  isGiftCreated: boolean;
  createdOn: string;
  printedOn: string;
  soldOn?: string;
  giftCreatedOn?: string;

  public constructor(init?: Partial<GiftTemplateAssociationDto>) {
    Object.assign(this, init);
  }
}
