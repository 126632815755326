import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services';
import * as $ from 'jquery';

@Component({
  selector: 'app-redeemed-transaction',
  templateUrl: './redeemed-transaction.component.html',
  styleUrls: ['./redeemed-transaction.component.css']
})
export class RedeemedTransactionComponent implements OnInit {

  transaction: any;

  constructor(private activatedRouter: ActivatedRoute,
    private router: Router,
    private dataService: DataService,) { }

  ngOnInit() {
    this.activatedRouter.params.subscribe(params => {
      let transactionId = params['transactionId'];
      this.getTransaction(transactionId);
    });
  }

  ngAfterViewInit(): void {
    printInvoice();
  }

  back() {
    this.router.navigate(['/avail-process']);
  }

  getTransaction(transactionId: any) {
    let url = `api/offer-avail-process/transaction/${transactionId}`;
    this.dataService.get(url).subscribe((result: any) => {
      if (result.success) {
        this.transaction = result.data;
        console.log(this.transaction);
      }
    },
      (err) => {
        console.log(err);
      }
    );
  }
}

function printInvoice() {
  $('#printInvoice').click(function () {
    Popup($('#print-view')[0]);
    function Popup(data) {
      window.print();
      return true;
    }
  });
}
