import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-gift-code',
  templateUrl: './view-gift-code.component.html',
  styleUrls: ['./view-gift-code.component.css']
})
export class ViewGiftCodeComponent implements OnInit {

  giftCodeData: any;

  constructor(private dialogRef: MatDialogRef<ViewGiftCodeComponent>,
    @Inject(MAT_DIALOG_DATA) data) {

    if (data !== null) {
      this.giftCodeData = data;
    }
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
