import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConsumerPaymentService, BkashPaymentService } from 'src/app/services';
import * as $ from 'jquery';
import { HttpClient } from '@angular/common/http';
import { ServiceConsumer } from '../../models';
import { ServiceConsumersService } from '../../services/service-consumers.service';
import { UserType } from '../../enums';

declare let bKash: any;

@Component({
  selector: 'app-app-payment-option',
  templateUrl: './app-payment-option.component.html',
  styleUrls: ['./app-payment-option.component.css']
})
export class AppPaymentOptionComponent implements OnInit, AfterViewInit {


  paymentInformation: any = {};
  golpPaymentInformation: any = {};
  routeParams: any;
  existingCards: any[];
  sessionInformation: any = {};

  paymentMenu: any[] = [];
  activeMenu: string = "Mobile";
  selectedMenuIndex: number = 0;
  accessToken: string;
  consumer: ServiceConsumer;
  isPremiumUser = false;
  availableUserCredit: number;
  hasAvailableCredit: boolean;
  offerId: number

  constructor(private router: ActivatedRoute, private consumerPaymentService: ConsumerPaymentService,
    private bKashPaymentService: BkashPaymentService,
    private http: HttpClient,
    private consumerService: ServiceConsumersService, private route: Router
  ) { }

  ngOnInit() {
    this.paymentMenu.push({ "name": "Mobile" });
    this.paymentMenu.push({ "name": "Card and Others" });
    //this.paymentMenu.push({ "name": "Golp Credit" });
    //this.paymentMenu.push({"name": "Bank"});

    this.routeParams = this.router.params.subscribe(params => {
      this.paymentInformation.consumerId = params['id'];
      this.paymentInformation.offerId = params['offerId'];
      this.paymentInformation.assignmentId = params['assignmentId'];
      this.paymentInformation.amount = params['amount'];
      this.paymentInformation.token1 = params['token1'];
      this.paymentInformation.token2 = params['token2'];
      //this.paymentInformation.phone = params['phone'];
      localStorage.setItem("userToken", this.paymentInformation.token1 + this.paymentInformation.token2);
      this.getConsumerUser();
    });
  }
  getConsumerUser() {
    this.consumerService.getPremiumConsumerGolpCreditDetails(this.paymentInformation).subscribe((data: any) => {
      //this.consumer = data;
      this.isPremiumUser = data.isPremiumUser;
      this.availableUserCredit = data.availableUserCredit;
      this.hasAvailableCredit = data.hasAvailableCredit;
      this.offerId = data.offerId;
      this.golpPaymentInformation.offerId = this.offerId;
      this.golpPaymentInformation.totalAmount = data.amount;
      this.golpPaymentInformation.consumerId = data.consumerId;
      if (this.isPremiumUser) {
        //this.paymentMenu.push({ "name": "Golp Credit" });
      }
    });
  }

  getData(url: any) {
    this.http.get(url, { observe: 'response' }).subscribe(res => {
      this.accessToken = res.headers.get('FbAcctKit');

    });
  }

  onMenuChange(name, index) {
    if (this.selectedMenuIndex != index) {
      this.selectedMenuIndex = index === this.selectedMenuIndex ? -1 : index;
      this.activeMenu = this.paymentMenu.filter(c => c.name === name)[0].name;
      if (this.activeMenu == 'Mobile') {
        //window.location.reload();
        this.bKashInitialize();
      }

    }
  }

  onCardPayment() {
    this.consumerPaymentService.startPayment(this.paymentInformation)
      .subscribe((data: any) => {
        $("div#pBar").show();
        console.log(data);
        if (data.status == "SUCCESS") {
          console.log("Payment session successfully created");

            //window.location.href = data.directPaymentURL;
            window.location.href = data.gatewayPageURL;
          } else {
            console.log("Failed to create Payment session.");
            $("div#pBar").hide();
          }
        },
        (error: any) => {
          console.log(error);
        }
      );

    console.log('card payment');
    console.log(this.paymentInformation);
    console.log(this.routeParams);
  }
  /*
    onSavedCardPayment(tokenValue) {
      let savedCardCommand = { 
        amount: this.paymentInformation.amount, 
        consumerId: this.paymentInformation.consumerId, 
        offerId: this.paymentInformation.offerId,
        tokenId: tokenValue,
        cardAuthorizeURL: this.sessionInformation.cardAuthorizeURL
      };
      this.consumerPaymentService.payBySavedCardCommand(savedCardCommand)
        .subscribe((data: any) => {
            if (data.status == "SUCCESS") {
              console.log("Direct card payment started");
              window.location.href = data.redirectURL;
            } else {
              console.log("Failed to Direct card payment.");
            }
          },
          (error: any) => {
            console.log(error);
          }
        );
    }
  */
  ngAfterViewInit(): void {
    this.bKashInitialize();
  }

  bKashInitialize() {
    $("div#pBar").hide();
    let paymentData = this.paymentInformation;
    let bKashToken = localStorage.getItem('bKashToken');
    var paymentID;
    let paymentService = this.bKashPaymentService;

    let paymentCreateRequest = {
      amount: paymentData.amount,
      consumerId: paymentData.consumerId,
      offerId: paymentData.offerId,
      assignmentId: paymentData.assignmentId,
      token: bKashToken
    };

    bKash.init({
      paymentMode: 'checkout',
      paymentRequest: paymentCreateRequest,
      createRequest: function (request) {
        console.log('=> createRequest (request) :: ');
        console.log(request);
        paymentCreateRequest.amount = paymentData.amount;

        $("div#pBar").show();

        paymentService.createPayment(paymentCreateRequest)
          .subscribe((data: any) => {
            if (data != null && data.paymentID != null) {
              paymentID = data.paymentID;
              paymentCreateRequest.amount = data.amount;
              bKash.create().onSuccess(data);

            } else {
              bKash.create().onError();
              window.location.href = "/consumer-payment/false/" + data.errorCode + "/" + data.errorMessage;
            }
            $("div#pBar").hide();
          }, (error: any) => {
            console.log(error);
            bKash.create().onError();
            window.location.href = "/consumer-payment/false/500/internal error happen";
          });
      },
      executeRequestOnAuthorization: function () {
        console.log('=> executeRequestOnAuthorization');
        let paymentExecuteRequest = {
          paymentID: paymentID,
          token: bKashToken,
          offerId: paymentCreateRequest.offerId,
          consumerId: paymentCreateRequest.consumerId,
        };

        paymentService.executePayment(paymentExecuteRequest)
          .subscribe((data: any) => {
            if (data != null && data.paymentID != null) {
              //urlRouter.navigate(['/consumer-payment/true']);  
              window.location.href = "/consumer-payment/true/0/0";
            } else {
              bKash.create().onError();
              //alert.error('Payment does not complete successfully');
              window.location.href = "/consumer-payment/false/" + data.errorCode + "/" + data.errorMessage;
            }
          }, (error: any) => {
            console.log(error);
            bKash.create().onError();
            window.location.href = "/consumer-payment/false/500/internal error happen";
          });
      },
      onClose: function () {
        console.log('user close');
        //alert.info('User has clicked the close button');
        window.location.reload();
      }
    });
  }

  onbKashPayment() {
    console.log('bKash payment');
  }

  getbKashToken() {
    this.bKashPaymentService.getToken()
      .subscribe((data: any) => {
        console.log('bKash token');
        localStorage.setItem('bKashToken', data.id_token);
        console.log(data.id_token);
      },
        (error: any) => {
          console.log(error);
        });
  }
  onGolpCreditPayment() {
    this.consumerPaymentService.payByGolpCredit(this.golpPaymentInformation)
      .subscribe((data: any) => {
        $("div#pBar").show();
        console.log(data);
        if (data.status == "SUCCESS") {
          console.log("Payment session successfully created");

          this.route.navigate(['/send-gifts']);
          //window.location.href = data.directPaymentURL;
        } else {
          console.log("Failed to create Payment session.");
          $("div#pBar").hide();
        }
      },
        (error: any) => {
          console.log(error);
        }
      );

  }
}
