import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { InformationalCondition } from '../../models/informational-condition';

@Component({
  selector: 'app-create-offer-informational-condition',
  templateUrl: './create-offer-informational-condition.component.html',
  styleUrls: ['./create-offer-informational-condition.component.css']
})

export class CreateOfferInformationalConditionComponent implements OnInit {

  keyValues = [{ key: '', value: '' }];
  @Input() informationalConditions: any;
  @Input() isSubmitted: false;
  @Output() addInformationEmitter: EventEmitter<any> = new EventEmitter<any>();

  submitted = false;
  keyValuesJson: any;
  keyValuesJsonArray: any;
  public keyValuesForm: FormGroup;

  constructor(private _fb: FormBuilder) { }

  ngOnInit() {
    this.keyValuesForm = this._fb.group({
      itemRows: this._fb.array([this.initItemRows()])
    });

    this.setFormArrayValue();
  }

  public setFormArrayValue() {
    var existingSlabArray: any = [];

    if (this.informationalConditions) {
      this.informationalConditions?.conditionText.forEach(item => {
        existingSlabArray.push(item);
      });
    }

    if (existingSlabArray && existingSlabArray.length > 0) {
      this.deleteFirstEmptyRow();

      var i = 0;
      Object.entries(existingSlabArray).forEach(element => {
        this.formArr.push(this.initItemRowsForEdit(existingSlabArray[i]));
        i = i + 1;

      });
      this.setKeyValuesJsonAndEmit();
    }
  }

  get formArr() {
    return this.keyValuesForm.get('itemRows') as FormArray;
  }

  initItemRowsForEdit(element: any) {
    return this._fb.group({
      value: [element]
    });
  }

  initItemRows() {
    return this._fb.group({
      value: ['']
    });
  }

  addNewInformation() {
    this.formArr.push(this.initItemRows());
    this.setKeyValuesJsonAndEmit();
  }

  deleteFirstEmptyRow() {
    this.formArr.removeAt(0);
    this.setKeyValuesJsonAndEmit();
  }

  deleteRow(index: number) {
    this.formArr.removeAt(index);
    this.setKeyValuesJsonAndEmit();
  }

  setKeyValuesJson(): void {
    var customArray = this.keyValuesForm.controls.itemRows.value;
    var object = {};

    var slabArray: string[] = [];

    customArray.forEach((element1) => {
      var val = element1.value;
      if (val)
        slabArray.push(element1.value);
    });

    this.keyValuesJson = slabArray;
  }

  setKeyValuesJsonAndEmit(): any {
    this.setKeyValuesJson();
    //this.addInformationEmitter.emit(this.keyValuesJson);
    return this.keyValuesJson;
  }

  get formData() { return <FormArray>this.keyValuesForm.get('itemRows'); }

}
