import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AccountServiceService, DataService } from 'src/app/services';


@Component({
  selector: 'app-advance-balance',
  templateUrl: './advance-balance.component.html',
  styleUrls: ['./advance-balance.component.css']
})

export class AdvanceBalanceComponent implements OnInit {

  @Input() advanceBalanceInput: any;

  advanceBalanceData: any;
  userInfo: any;
  p: number = 1;

  constructor(
    private accountService: AccountServiceService,
    private dataService: DataService
  ) {
  }

  ngOnInit() {
    this.getUserInfo();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['advanceBalanceInput']) {
      setTimeout(() => {
        this.advanceBalanceData = this.advanceBalanceInput;
      });
    }
  }

  getUserInfo() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
  }

  getAdvanceBalance() {
    this.getMerchantAdvanceBalance();
  }

  getMerchantAdvanceBalance() {
    this.dataService.get(`api/MerchantBilling/payment-transactions?merchantId=${this.userInfo.companyId}`)
      .subscribe((result: any) => {
        this.advanceBalanceData = result;
      });
  }

}

