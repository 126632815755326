import { Component, OnInit, Injector } from "@angular/core";
import { BaseComponent } from "../base/base.component";
import { AccountServiceService, DataService } from "src/app/services";
import { CompanyRole } from '../../enums';

@Component({
  selector: "app-avail-process",
  templateUrl: "./avail-process.component.html",
  styleUrls: ["./avail-process.component.css"],
})
export class AvailProcessComponent extends BaseComponent implements OnInit {
  availProcessSubMenus: any[] = [];
  selectedSubMenuIndex = 0;
  activeSubMenu = "Redemption";
  innerWidth: any = 992;
  userRole: string;
  isMerchantLoggedIn: boolean = false;
  offerString: string;
  redemptionType: any[] = [];
  currentRedemptionType: number = 0;

  userInfo: any;
  advancePaymentData: any;

  constructor(
    injector: Injector,
    private accountService: AccountServiceService,
    private dataService: DataService
  ) {
    super(injector);
  }

  getSubscription() {
    let subscriptions: any = JSON.parse(localStorage.getItem("_sub"));
    if (subscriptions.offerCard)
      this.offerString = "/Offer";
    return subscriptions;
  }

  ngOnInit() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
    this.userRole = this.userInfo.roles[0].name;

    this.getMerchantAdvanceBalance();

    if (CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
      this.isMerchantLoggedIn = true;
    }

    let showRedemptionTab = this.userInfo.merchantAvailMethod === "GolpGiftCode" || this.userInfo.merchantAvailMethod === "GolpCouponCode";

    if (this.checkPermission(["OfferAvailAccept"]) && showRedemptionTab) {

      this.availProcessSubMenus.push({
        name: "Redemption",
        dataTarget: "RedemptionTab",
      });
      this.redemptionType.push("Gift Card Redemption")
      this.activeSubMenu = "Redemption";
    }

    if (this.getSubscription().golpCard) {
      this.redemptionType.push("Privilege Card Redemption")
    }

    if (this.checkPermission(["OfferAvailAccept"])) { }
    if (this.checkPermission(["OfferAvailAccept"]) || this.userRole === "MerchantAdmin") { }

    if (this.checkPermission(["OfferAvailTransactionRead"])) {

      if (this.availProcessSubMenus.length == 0)
        this.activeSubMenu = "Transactions";

      this.availProcessSubMenus.push({
        name: "Transactions",
        dataTarget: "TransactionsTab",
      });
    }

    this.getSubscription();
  }

  toggleRedemption(i) {
    this.currentRedemptionType = i;
  }

  onSubMenuChange(name, index) {
    if (this.selectedSubMenuIndex !== index) {
      this.selectedSubMenuIndex =
        index === this.selectedSubMenuIndex ? -1 : index;
      this.activeSubMenu = this.availProcessSubMenus.filter(
        (c) => c.name === name
      )[0].name;
    }
  }

  getMerchantAdvanceBalance() {
    this.dataService.get(`api/MerchantBilling/payment-transactions?merchantId=${this.userInfo.companyId}`)
      .subscribe((result: any) => {
        this.advancePaymentData = result;

        if (this.advancePaymentData?.isAdvanceRequired) {
          this.availProcessSubMenus.push({
            name: "Available Balance",
            dataTarget: "BalanceTab",
          });
        }
      });
  }
}
