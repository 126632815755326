import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { DataService } from '../../services/shared/data.service';

@Component({
  selector: 'app-gift-sent-history',
  templateUrl: './gift-sent-history.component.html',
  styleUrls: ['./gift-sent-history.component.css']
})
export class GiftSentHistoryComponent implements OnInit {

  giftsData: any[];

  receivedData: any;
  availed: any;
  expired: any;
  receivedCount: number = 0;
  availedCount: number = 0;
  expiredCount: number = 0;
  apiBaseUri: any;
  merchantId: any;
  companyOutletId: any;
  responseData: any;
  //filteredData: any;
  currentPageNumber: number = 1;
  currentPageSize: number = 50;
  filterType: number = 1;
  totalViewedItem: number = 0;
  searchQuery: string = '';

  showLoadMoreButton: boolean = true;

  oldData = "";

  constructor(private router: Router, private dataService: DataService) {
    this.setUserInfo();
  }

  ngOnInit() {
    this.getGiftSummary(this.searchQuery);
    this.getGiftsData(this.searchQuery);
  }

  setUserInfo() {
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.merchantId = userInfo.companyId;
    this.companyOutletId = userInfo.outletQrCode;
  }

  getGiftSummary(data: string = "") {
    let uri = `api/giftcard/sold-gift-summary-count/merchant/${this.merchantId}`;
    uri = this.companyOutletId != null ? `${uri}?outletId=${this.companyOutletId}` : `${uri}?t=1`;
    uri = data != "" ? `${uri}&searchQuery=${data}` : uri;

    this.dataService.get(uri)
      .subscribe((response: any) => {
        if (response.success) {
          this.responseData = response.data;
          this.receivedCount = response.data?.availableCount;
          this.availedCount = response.data?.redeemedCount;
          this.expiredCount = response.data?.expiredCount;
          this.totalViewedItem = this.receivedCount + this.availedCount + this.expiredCount;
        }

      }, () => { });
  }

  getGiftsData(data: string = "") {
    this.oldData = data;
    let uri = `api/giftcard/sold-gift-cards/merchant/${this.merchantId}`;

    uri = `${uri}?pageSize=${this.currentPageSize}&pageNumber=${this.currentPageNumber}&filterType=${this.filterType}`;
    uri = this.companyOutletId != null ? `${uri}&outletId=${this.companyOutletId}` : uri;
    uri = data != "" ? `${uri}&globalSearch=${data}` : uri;

    this.dataService.get(uri)
      .subscribe((response: any) => {
        if (response.success) {
          this.responseData = response.data;

          if (this.filterType === 1) {
            this.receivedData = (this.receivedData != null && this.receivedData.length > 0)
              ? [...this.receivedData, ...this.responseData?.giftCardOrders] : this.responseData?.giftCardOrders;

            this.showLoadMoreButton = (this.currentPageSize * this.currentPageNumber) <= this.responseData?.filterCount ? true : false;
          }
          else if (this.filterType === 2) {
            this.availed = (this.availed != null && this.availed.length > 0)
              ? [...this.availed, ...this.responseData?.giftCardOrders] : this.responseData?.giftCardOrders;

            this.showLoadMoreButton = (this.currentPageSize * this.currentPageNumber) <= this.responseData?.filterCount ? true : false;
          }
          else if (this.filterType === 3) {
            this.expired = (this.expired != null && this.expired.length > 0)
              ? [...this.expired, ...this.responseData?.giftCardOrders] : this.responseData?.giftCardOrders;

            this.showLoadMoreButton = (this.currentPageSize * this.currentPageNumber) <= this.responseData?.filterCount ? true : false;
          }
        }
      }, () => { });
  }

  searchGift(searchKey: any) {
    this.searchQuery = searchKey;
    this.clearCurrentData();
    this.getGiftSummary(searchKey);
    this.getGiftsData(searchKey);
  }

  // onKeyEnter($event) {
  //   let key = $event.target.value;
  //   this.getGiftsData(key);
  // }

  onMenuChange(index: any) {
    this.filterType = index;
    this.currentPageNumber = 1;
    this.currentPageSize = 50;
    this.receivedData = null;
    this.availed = null;
    this.expired = null;
    this.getGiftsData(this.searchQuery);
  }

  loadMore() {
    this.currentPageNumber += 1;
    this.getGiftsData(this.oldData);
  }

  clearCurrentData() {
    this.receivedData = null;
    this.availed = null;
    this.expired = null;
    this.receivedCount = 0;
    this.availedCount = 0;
    this.expiredCount = 0;
  }

  selectedGiftItemClicked($event) {
    let navigationEgolps: NavigationExtras = {
      state: {
        transd: 'giftInformation',
        workQueue: false,
        services: 1,
        code: $event,
      }
    };
    this.router.navigate(['gift-details'], navigationEgolps);
  }
}



