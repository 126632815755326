import { Component, OnInit, Injector } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyRole } from 'src/app/enums/company-role.enum';
import { Company } from 'src/app/models/company';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component'
import { CommonService, CompanyService, AccountServiceService, AlertService, LoyaltyMerchantService } from 'src/app/services';
import { BaseComponent } from '../base/base.component';
import { LoyaltyMerchant, LoyaltyMerchantPagedListInput } from '../../models';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CreateLoyaltyMerchantComponent } from '../create-loyalty-merchant/create-loyalty-merchant.component';

@Component({
  selector: 'app-loyalty-merchants',
  templateUrl: './loyalty-merchants.component.html',
  styleUrls: ['./loyalty-merchants.component.css']
})


export class LoyaltyMerchantsComponent extends BaseComponent implements OnInit {
  @BlockUI('loyalty-merchant-section') blockUI: NgBlockUI;

  dataLoaded: Promise<boolean>;

  currentPage: number = 1;
  pageSize: number = 10;
  itemCountText: string;

  userInfo: any;
  canCreate: boolean = true;

  filteredLoyaltyMerchants: LoyaltyMerchant[] = [];
  loyaltyMerchants: any[];

  loyaltyMerchantFilter: any = { name: '' };
  loyaltyMerchantFilterText: string;
  selectedLoyaltyMerchantIndex: number = -1;
  selectedLoyaltyMerchant: LoyaltyMerchant;
  selectedLoyaltyMerchantName: string;

  loyaltyMerchantSubMenu: any[] = [];
  selectedSubMenuIndex: number = 0;
  activeSubMenu: string = "About";
  companyRoleType: CompanyRole = CompanyRole.LoyaltyMerchant;
  totalItems: any;
  searchText: string;

  constructor(private companyService: CompanyService, private dialog: MatDialog,
    private accountService: AccountServiceService,
    private commonService: CommonService,
    private LoyaltyMerchantService: LoyaltyMerchantService,
    private alertService: AlertService,
    injector: Injector
  ) {
    super(injector);

  }

  ngOnInit() {
    this.getLoggedInUser();
    this.getAllLoyaltyMerchants(null);

    this.loyaltyMerchantSubMenu.push({ "name": "About", "dataTarget": "aboutProviderTab" });
    this.loyaltyMerchantSubMenu.push({ "name": "Settings", "dataTarget": "providerSettingsTab" });
    this.loyaltyMerchantSubMenu.push({ "name": "Points", "dataTarget": "pointsTab" });

    //if (this.checkPermission(['CompanySettingRead']))

    //this.merchantSubMenu.push({ "name": "About", "dataTarget": "aboutProviderTab" });
    //if (this.checkPermission(['OutletRead']))
    //  this.merchantSubMenu.push({ "name": "Outlets", "dataTarget": "providerOutletsTab" });


    this.dataLoaded = Promise.resolve(true);

  }



  createLoyaltyMerchant() {
    const dialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'golp-dialog';

    const loyaltyMerchantCreateDialog = this.dialog.open(CreateLoyaltyMerchantComponent, dialogConfig);

    const successfullCreate = loyaltyMerchantCreateDialog.componentInstance.onLoyaltyMerchantCreateEvent.subscribe((data) => {
      this.loyaltyMerchants.unshift(data);
      this.filteredLoyaltyMerchants.unshift(data);
      this.highlightFirstItem(this.filteredLoyaltyMerchants);
      this.dataLoaded = Promise.resolve(true);
    });

    const successfullEdit = loyaltyMerchantCreateDialog.componentInstance.onLoyaltyMerchantEditEvent.subscribe((data) => {
      this.loyaltyMerchants.unshift(data);
      this.filteredLoyaltyMerchants.unshift(data);
      this.highlightFirstItem(this.filteredLoyaltyMerchants);
      this.dataLoaded = Promise.resolve(true);

    });

    loyaltyMerchantCreateDialog.afterClosed().subscribe(() => {
      //successFullEdit.unsubscribe();
      //this.detailsLoaded = Promise.resolve(false);
    });

  }

  onLoyaltyMerchantChange(provider, index) {
    if (this.selectedLoyaltyMerchantIndex != index) {
      this.selectedLoyaltyMerchantIndex = index === this.selectedLoyaltyMerchantIndex ? -1 : index;
      this.selectedLoyaltyMerchant = provider;
      this.selectedLoyaltyMerchantName = provider.name;
    }
    let body = document.getElementsByTagName('body')[0];
    body.classList.add("display-tab-content");
    this.commonService.itemSelectionUpdated(this.selectedLoyaltyMerchant);
  }

  onSubMenuChange(name, index) {
    if (this.selectedSubMenuIndex != index) {
      this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
      this.activeSubMenu = this.loyaltyMerchantSubMenu.filter(c => c.name === name)[0].name;
    }
  }

  getloyaltyMerchantDetailsChange(event) {
    let merchant = this.filteredLoyaltyMerchants.filter(c => c.id === event.id)[0];
    merchant.imageFullPath = event.imageFullPath;
    merchant.name = event.name;
  }

  OnDeleteLoyaltyMerchant(LoyaltyMerchant: LoyaltyMerchant): void {
    const confirmationDialogConfig = new MatDialogConfig()

    // Setting different dialog configurations
    confirmationDialogConfig.data = "Are you sure to delete the loyalty Merchant " + LoyaltyMerchant.name + " from trending list.";
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.deleteloyaltyMerchant(LoyaltyMerchant);
      }
    });
  }

  deleteloyaltyMerchant(LoyaltyMerchant: LoyaltyMerchant) {
    this.LoyaltyMerchantService.deleteLoyaltyMerchant(LoyaltyMerchant).subscribe((data) => {
      this.alertService.success('Loyalty Merchant deleted successfully');
      this.getAllLoyaltyMerchants(null);
    },
      (error) => {
      });
  }

  getAllLoyaltyMerchants(currentlyCreatedloyaltyMerchant: any): void {
    var input = new LoyaltyMerchantPagedListInput();
    input.pageNumber = this.currentPage;
    input.pageSize = this.pageSize;
    input.searchText = this.searchText;

    this.blockUI.start();

    this.LoyaltyMerchantService.getAllLoyaltyMerchants()
      .subscribe((result) => {
        this.loyaltyMerchants = result;//.data;
        this.merchantCopy();

        this.highlightFirstItem(this.filteredLoyaltyMerchants);

        this.itemCount();
        //this.totalItems = this.loyaltyMerchants.length;
        //this.totalItems = result.rowCount;
        //this.itemCountText = "(Showing " + result.firstRowOnPage + " to " + result.lastRowOnPage + " of " + result.rowCount + " items)"
        this.blockUI.stop();

        //this.itemCount();
      },
        (error) => {
          this.blockUI.stop();

        });
  }



  deleteMerchant(merchant: Company) {
    this.companyService.deleteCompany(merchant.id).subscribe((data) => {
      this.alertService.success('Merchant deleted successfully');
      this.filteredLoyaltyMerchants = this.filteredLoyaltyMerchants.filter(function (item) {
        if (item.id !== merchant.id) {
          return true;
        }
        else {
          return false;
        }
      });

      this.filteredLoyaltyMerchants = this.filteredLoyaltyMerchants.filter(function (item) {
        if (item.id !== merchant.id) {
          return true;
        }
        else {
          return false;
        }
      });

      this.selectedLoyaltyMerchantIndex = 0
      this.selectedLoyaltyMerchant = this.filteredLoyaltyMerchants[this.selectedLoyaltyMerchantIndex];
      this.selectedLoyaltyMerchantName = this.selectedLoyaltyMerchant.name;
      this.currentPage = 1;
      this.itemCount();
    },
      (error) => {

      });
  }


  getLoggedInUser() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
    if (CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
      this.canCreate = false;
    }
  }

  highlightFirstItem(merchants: LoyaltyMerchant[]) {
    // set initial offeror
    if (merchants !== null) {
      this.selectedLoyaltyMerchant = merchants[0];
      this.selectedLoyaltyMerchantName = this.selectedLoyaltyMerchant.name;
      this.selectedLoyaltyMerchantIndex = 0;
    }
  }

  pageChanged(event) {
    this.selectedLoyaltyMerchantIndex = 0;
    this.currentPage = event;
    let itemIndex = (this.currentPage * this.pageSize) - this.pageSize;
    this.selectedLoyaltyMerchant = this.filteredLoyaltyMerchants[itemIndex];
    this.selectedLoyaltyMerchantName = this.selectedLoyaltyMerchant.name;
    this.itemCount();
  }

  loyaltyMerchantFilteredItem(value) {
    if (!value) {
      this.merchantCopy();
    }
    this.filteredLoyaltyMerchants = Object.assign([], this.loyaltyMerchants).filter(item => item.name.toLowerCase().indexOf(value.toLowerCase()) > - 1);
    this.selectedLoyaltyMerchant = this.filteredLoyaltyMerchants[this.selectedLoyaltyMerchantIndex];
    this.selectedLoyaltyMerchantName = this.selectedLoyaltyMerchant != null ? this.selectedLoyaltyMerchant.name : '';
    this.itemCount();
  }

  merchantCopy() {
    this.filteredLoyaltyMerchants = Object.assign([], this.loyaltyMerchants);
  }

  itemCount() {
    let firstItem = (this.currentPage - 1) * this.pageSize + 1;
    let lastItem = (this.currentPage) * this.pageSize;

    this.itemCountText = firstItem + ' - ' + (lastItem < this.filteredLoyaltyMerchants.length ? lastItem : this.filteredLoyaltyMerchants.length) + ' of ' + this.filteredLoyaltyMerchants.length + ' merchants';

  }


  errorHandlerCompanyLogo(event, merchantId) {
    //event.target.src = "http://place-hold.it/24x24/ebe89f/9D2933&fontsize=12&text=" + merchantFirstChar;

    document.getElementById('companyLogoImgtxt_' + merchantId).style.display = "block";
    document.getElementById('companyLogoImg_' + merchantId).style.display = "none";
  }
}
