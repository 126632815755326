import { Component, OnInit, Injector } from '@angular/core';
import { PermissionCheckerService } from 'src/app/services/permission-checker.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css']
})
export class BaseComponent implements OnInit {
  permissionCheckerService: PermissionCheckerService;

  constructor(injector: Injector) { 
    this.permissionCheckerService = injector.get(PermissionCheckerService);
  }

  ngOnInit() {
  }


  checkPermission(name:any):boolean{
    return this.permissionCheckerService.hasPermission(name);
  }

}
