import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { ServiceConsumer } from '../../models';
import { PaymentService } from 'src/app/services/payment.service';
import { DataService } from '../app-send-gift/services/shared/data.service';

declare let bKash: any;

@Component({
    selector: 'app-payment-method',
    templateUrl: './payment-method.component.html',
    styleUrls: ['./payment-method.component.css']
})
export class PaymentMethodComponent implements OnInit, AfterViewInit {

    paymentInformation: any = {};
    golpPaymentInformation: any = {};
    routeParams: any;
    existingCards: any[];
    sessionInformation: any = {};

    paymentMenu: any[] = [];
    activeMenu: string = "Mobile";
    selectedMenuIndex: number = 0;
    accessToken: string;
    consumer: ServiceConsumer;
    isPremiumUser = false;
    availableUserCredit: number = 0;
    hasAvailableCredit: boolean = false;
    offerId: number

    constructor(private router: ActivatedRoute,
        private paymentService: PaymentService,
        private dataService: DataService
    ) {
    }

    ngOnInit() {
        this.paymentMenu.push({ "name": "Mobile" });
        this.paymentMenu.push({ "name": "Card and Others" });

        this.routeParams = this.router.params.subscribe(params => {
            this.paymentInformation.isDataEncrypted = true;
            //this.paymentInformation.giftType = params['giftType'];
            this.paymentInformation.sessionType = params['sessionType'];
            this.paymentInformation.resendRequestId = params['resendRequestId'];
            this.paymentInformation.orderId = params['order'];
            //this.paymentInformation.assignmentId = params['assignmentId'];
            this.paymentInformation.amount = params['amount'];
            this.paymentInformation.token1 = params['token1'];
            this.paymentInformation.token2 = params['token2'];
            localStorage.setItem("userToken", this.paymentInformation.token1 + this.paymentInformation.token2);

            this.checkGolpCredit();
        });
    }

    onMenuChange(name, index) {
        if (this.selectedMenuIndex != index) {
            this.selectedMenuIndex = index === this.selectedMenuIndex ? -1 : index;
            this.activeMenu = this.paymentMenu.filter(c => c.name === name)[0].name;
            if (this.activeMenu == 'Mobile') {
                this.bKashInitialize();
            }
        }
    }

    checkGolpCredit() {
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        let userId = (userInfo == null || userInfo == undefined || userInfo == '') ? 0 : userInfo.id;
        let url = `api/usercredit/user-availabe-credit/${userId}`;
        this.dataService.get(url).subscribe((data: any) => {
            if (data) {
                this.availableUserCredit = data?.remainingCredit;
                this.hasAvailableCredit = this.availableUserCredit >= this.paymentInformation.amount ? true : false;
            }
        }, (err) => {
        });
    }

    onGolpCreditPayment() {
        this.paymentService.initGolpCreditPayment(this.paymentInformation)
            .subscribe((data: any) => {
                if (data) {
                    window.location.href = "/consumer-payment/true/0/0";
                }
            },
                (error: any) => {
                    console.log(error);
                });
    }

    onCardPayment() {
        this.paymentService.initCardPayment(this.paymentInformation)
            .subscribe((data: any) => {
                $("div#pBar").show();
                console.log(data);
                if (data.status == "SUCCESS") {
                    console.log("Payment session successfully created");
                    window.location.href = data.gatewayPageURL;
                } else {
                    console.log("Failed to create Payment session.");
                    $("div#pBar").hide();
                }
            },
                (error: any) => {
                    console.log(error);
                }
            );
    }

    ngAfterViewInit(): void {
        this.bKashInitialize();
    }

    bKashInitialize() {
        $("div#pBar").hide();
        let paymentData = this.paymentInformation;
        let bKashToken = localStorage.getItem('bKashToken');
        var paymentID;
        let bKashPaymentService = this.paymentService;

        let paymentCreateRequest = {
            amount: paymentData.amount,
            consumerId: paymentData.consumerId,
            orderId: paymentData.orderId,
            //giftId: paymentData.giftId,
            //giftType: paymentData.giftType,
            resendRequestId: paymentData.resendRequestId,

            sessionType: paymentData.sessionType,
            isDataEncrypted: true,
            assignmentId: paymentData.assignmentId,
            token: bKashToken
        };

        bKash.init({
            paymentMode: 'checkout',
            paymentRequest: paymentCreateRequest,
            createRequest: function () {
                console.log('=> createRequest (request) :: ');
                paymentCreateRequest.amount = paymentData.amount;

                $("div#pBar").show();

                bKashPaymentService.initbKashPayment(paymentCreateRequest)
                    .subscribe((data: any) => {
                        if (data != null && data.paymentID != null) {
                            paymentID = data.paymentID;
                            paymentCreateRequest.amount = data.amount;
                            bKash.create().onSuccess(data);

                        } else {
                            bKash.create().onError();
                            window.location.href = "/consumer-payment/false/" + data.errorCode + "/" + data.errorMessage;
                        }
                        $("div#pBar").hide();
                    }, (error: any) => {
                        console.log(error);
                        bKash.create().onError();
                        window.location.href = "/consumer-payment/false/500/internal error happen";
                    });
            },
            executeRequestOnAuthorization: function () {
                console.log('=> executeRequestOnAuthorization');
                let paymentExecuteRequest = {
                    paymentID: paymentID,
                    token: bKashToken,
                    //offerId: paymentCreateRequest.giftId,
                    //giftType: paymentCreateRequest.giftType,
                    sessionType: paymentCreateRequest.sessionType,
                    assignmentId: paymentCreateRequest.assignmentId,
                    isDataEncrypted: true
                    //consumerId: paymentCreateRequest.consumerId,
                };

                bKashPaymentService.executebKashPayment(paymentExecuteRequest)
                    .subscribe((data: any) => {
                        if (data != null && data.paymentID != null) {
                            if (paymentExecuteRequest.sessionType == 'ConsumerGift') {
                                window.location.href = "/consumer-payment/true/0/0";
                            }
                            else {
                                window.location.href = `/payments/1/transaction/${data.paymentID}?payment-status=success`;
                            }

                        } else {
                            bKash.create().onError();
                            if (paymentExecuteRequest.sessionType == 'ConsumerGift') {
                                window.location.href = "/consumer-payment/false/" + data.errorCode + "/" + data.errorMessage;
                            }
                            else {
                                window.location.href = `/payments/0/transaction/${data.paymentID}?payment-status=failed&messge=payment failed`;
                            }

                        }
                    }, (error: any) => {
                        console.log(error);
                        bKash.create().onError();
                        if (paymentExecuteRequest.sessionType == 'ConsumerGift') {
                            window.location.href = "/consumer-payment/false/500/internal error happen";
                        }
                        else {
                            window.location.href = `/payments/0/transaction/0?payment-status=failed&messge=internal error happen`;
                        }
                        window.location.href = "/consumer-payment/false/500/internal error happen";
                    });
            },
            onClose: function () {
                console.log('user close');
                window.location.reload();
            }
        });
    }

    onbKashPayment() {
        console.log('bKash payment');
    }
}
