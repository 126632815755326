import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { Offer, Company, PaymentSession } from '../../models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ValidationUtility } from '../../utility/validation.utility';
import { CompanyService, AlertService, PaymentService, BillingService } from '../../services';
import { PaymentHolder } from '../../enums/payment-holder';
import { CompanyType, OfferType } from '../../enums';
import { CompanyCreditSummary } from '../../models/company-credit-summary';
import { AssignmentResponse } from 'src/app/models/assignmentresponse';
import * as $ from 'jquery';
import { DataService } from '../app-send-gift/services/shared/data.service';
import { saveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { consumerOffer } from 'src/app/models/ConsumerOffer';

declare let bKash: any;

@Component({
  selector: 'app-payment-options',
  templateUrl: './payment-options.component.html',
  styleUrls: ['./payment-options.component.css']
})
export class PaymentOptionsComponent implements OnInit {
  onOptionSelectionEvent: EventEmitter<any> = new EventEmitter();

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<PaymentOptionsComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private companySevice: CompanyService,
    private alertService: AlertService,
    private dataService: DataService,
    private billingService: BillingService,
    private paymentService: PaymentService, 
    private http: HttpClient) {
    if (data.isInvoiceBillPayment) {
      this.billPaymentSession = data.data;
      this.isInvoiceBillPayment = true;
    }
    else {
      this.assignmentRespone = data.data;
    }
    this.bulkCodeGenerate = data.bulkCodeGenerate;
  }

  paymentOptions: any[] = [];
  offer: Offer;
  assignmentRespone: AssignmentResponse;
  billPaymentSession: PaymentSession;
  offerPaymentForm: FormGroup;
  submitted = false;
  paymentInformation: paymentInformation = new paymentInformation();
  companyType: CompanyType;
  companyCurrentGolpBalance: number;
  totalOfferPrice: number;
  showbKashButton: boolean = false; //when bKash payment enabled it should be true
  isInvoiceBillPayment: boolean = false;
  giftSenderId: any;
  walletBalance: any;
  bulkCodeGenerate: boolean;

  ngOnInit() {
    this.setGiftSender();
    this.getWalletBalance();
    this.preparePaymentInformation();
    this.getCompanyType();
    this.getGolpCreditBalance();
    this.buildForm();
    this.bKashInitialize();
    //this.totalOfferPrice = this.assignmentRespone.giftAmount;
  }

  ngAfterViewInit(): void {
    this.bKashInitialize();
  }

  setGiftSender() {
    this.giftSenderId = this.isInvoiceBillPayment ? this.billPaymentSession.companyId : this.assignmentRespone.giftSenderId;
  }

  buildPaymentOption() {
    if (this.companyType == CompanyType.Premium) {
      this.paymentOptions.push({ "name": "Golp Credit Payment", "value": "GolpCredit" });
    }

    //this.paymentOptions.push({ "name": "bKash", "value": "bKash" });
    //this.paymentOptions.push({ "name": "Rocket", "value": "Rocket" });
    this.paymentOptions.push({ "name": "Card and Others", "value": "Card" });
  }
  getGolpCreditBalance() {
    this.companySevice.getCompanyCreditSummaryByCompanyId(this.giftSenderId).subscribe((data: CompanyCreditSummary) => {
      if (data) {
        this.companyCurrentGolpBalance = data.remainingCredits;
      }
    });
  }

  getWalletBalance() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let url = `api/golpwallet/balance?companyId=${userInfo.companyId}&userId=${userInfo.id}`;
    this.dataService.get(url).subscribe((data: any) => {
      if (data.result) {
        this.walletBalance = data.data;
        if (this.walletBalance?.availableBalance > 0) {
          this.paymentOptions.push({ "name": "Wallet Payment", "value": "Wallet" });
        }
      }
    }, (err) => {
    });
  }

  getCompanyType() {
    this.companySevice.getCompanyById(this.giftSenderId).subscribe((data: Company) => {
      if (data) {
        this.companyType = data.companyType;
        this.buildPaymentOption();
      }
    });
  }

  buildForm() {
    this.offerPaymentForm = this.formBuilder.group({
      paymentOption: ['', [Validators.required]],
    });
  }

  preparePaymentInformation() {

    if (!this.isInvoiceBillPayment) {
      this.paymentInformation = new paymentInformation();
      this.paymentInformation.amount = this.assignmentRespone.giftAmount;
      this.paymentInformation.giftSenderId = this.assignmentRespone.giftSenderId;
      this.paymentInformation.giftId = this.assignmentRespone.giftId;
      this.paymentInformation.paymentHolder = PaymentHolder.OfferProvider;
      this.paymentInformation.assignmentId = this.assignmentRespone.referenceAssignmentId;
      this.totalOfferPrice = this.assignmentRespone.giftAmount;
      this.paymentInformation.orderId = this.assignmentRespone.orderId;
      this.paymentInformation.isBulkCodeGenerate = this.bulkCodeGenerate;
    }
    else {
      this.totalOfferPrice = this.billPaymentSession.amount;
    }
  }



  onStartPayment(paymentOption) {
    if (this.isInvoiceBillPayment) {
      this.invoiceBillPayment();
    }
    else {
      this.directPayment(paymentOption);
    }
  }


  directPayment(paymentOption) {
    this.paymentInformation.paymentOption = paymentOption;
    this.paymentInformation.isDataEncrypted = false;
    this.paymentInformation.giftType = OfferType.CompanyGift;

    this.paymentService.initCardPayment(this.paymentInformation)
      .subscribe((data: any) => {
        if (data.status == "SUCCESS") {
          this.alertService.success('Payment session successfully created');
          console.log("Payment session successfully created");
          window.location.href = data.gatewayPageURL;
        } else {
          this.alertService.error('Failed to create Payment session');
          console.log("Failed to create Payment session.");
        }
      },
        (error: any) => {
          console.log(error);
        }
      );
  }


  invoiceBillPayment() {
    this.billingService.startPayment(this.billPaymentSession)
      .subscribe((data: any) => {
        //this.blockUI.stop();
        if (data.status == "SUCCESS") {
          this.alertService.success("Payment session successfully created");
          setTimeout(function () {
            window.location.href = data.gatewayPageURL;
          }, 1000);
        } else {
          this.alertService.warn("Failed to create Payment session.");
        }
      });
  }

  onGolpCreditStartPayment(paymentOption) {
    if (this.isInvoiceBillPayment) {
      this.invoiceBillGolpCreditPayment();
    }
    else {
      this.directGolpCreditPayment(paymentOption);
    }
  }

  onWalletPayment(paymentOption) {
    this.initWalletPayment(paymentOption);
  }


  invoiceBillGolpCreditPayment() {
    this.billingService.startGolpCreditPayment(this.billPaymentSession)
      .subscribe((data: any) => {
        if (data) {
          this.alertService.success('Payment By Golp Credit successfully completed');
          console.log("Payment By Golp Credit successfully completed");
        } else {
          this.alertService.error('Failed to create Golp Credit Payment session');
          console.log("Failed to create Golp Credit Payment session");
        }
      },
        (error: any) => {
          console.log(error);
        }
      );

  }

  directGolpCreditPayment(paymentOption) {
    this.paymentInformation.paymentOption = paymentOption;
    this.paymentInformation.isBulkCodeGenerate = this.bulkCodeGenerate;
    this.paymentService.initGolpCreditPayment(this.paymentInformation)
      .subscribe((data: any) => {
        if (data) {
          this.alertService.success('Payment By Golp Credit successfully completed');
          console.log("Payment By Golp Credit successfully completed");
        } else {
          this.alertService.error('Failed to create Golp Credit Payment session');
          console.log("Failed to create Golp Credit Payment session");
        }
      },
        (error: any) => {
          console.log(error);
        }
      );
  }

  initWalletPayment(paymentOption) {
    this.paymentInformation.paymentOption = paymentOption;

    this.paymentService.initWalletPayment(this.paymentInformation)
      .subscribe((data: any) => {
        if (data) {
          this.alertService.success('Payment By wallet successfully completed');
        } else {
          this.alertService.error('Failed to complete payment through wallet');
          console.log("Failed to create Golp Credit Payment session");
        }
      },
        (error: any) => {
          console.log(error);
        }
      );
  }

  onSubmit() {
    this.submitted = true;
    if (this.offerPaymentForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    if (this.offerPaymentForm.value.paymentOption == "GolpCredit") {
      this.onGolpCreditStartPayment(this.offerPaymentForm.value.paymentOption);
    }
    else if (this.offerPaymentForm.value.paymentOption == "Wallet") {
      this.onWalletPayment(this.offerPaymentForm.value.paymentOption);
    }
    else {
      this.onStartPayment(this.offerPaymentForm.value.paymentOption);
    }

    this.dialogRef.close();
  }

  bKashInitialize() {
    $("div#pBar").hide();
    let paymentData = this.paymentInformation;
    let bKashToken = localStorage.getItem('bKashToken');
    var paymentID;
    let bKashPaymentService = this.paymentService;

    let paymentCreateRequest = {
      amount: paymentData.amount,
      giftId: paymentData.giftId,
      giftType: paymentData.giftType,
      isDataEncrypted: false,
      assignmentId: paymentData.assignmentId,
      token: bKashToken
    };

    bKash.init({
      paymentMode: 'checkout',
      paymentRequest: paymentCreateRequest,
      createRequest: function () {
        console.log('=> createRequest (request) :: ');
        paymentCreateRequest.amount = paymentData.amount;

        $("div#pBar").show();

        bKashPaymentService.initbKashPayment(paymentCreateRequest)
          .subscribe((data: any) => {
            if (data != null && data.paymentID != null) {
              paymentID = data.paymentID;
              paymentCreateRequest.amount = data.amount;
              bKash.create().onSuccess(data);

            } else {
              bKash.create().onError();
              window.location.href = "/consumer-payment/false/" + data.errorCode + "/" + data.errorMessage;
            }
            $("div#pBar").hide();
          }, (error: any) => {
            console.log(error);
            bKash.create().onError();
            window.location.href = "/consumer-payment/false/500/internal error happen";
          });
      },
      executeRequestOnAuthorization: function () {
        console.log('=> executeRequestOnAuthorization');
        let paymentExecuteRequest = {
          paymentID: paymentID,
          token: bKashToken,
          offerId: paymentCreateRequest.giftId,
          giftType: paymentCreateRequest.giftType,
          assignmentId: paymentCreateRequest.assignmentId,
          isDataEncrypted: false
          //consumerId: paymentCreateRequest.consumerId,
        };

        bKashPaymentService.executebKashPayment(paymentExecuteRequest)
          .subscribe((data: any) => {
            if (data != null && data.paymentID != null) {
              //   if(paymentExecuteRequest.giftType == 'ConsumerGift'){
              //     window.location.href = "/consumer-payment/true/0/0";
              //   }
              //   else{
              //     window.location.href = `/payments/1/transaction/${data.paymentID}?payment-status=success`;
              //   }
              window.location.href = `/payments/1/transaction/${data.paymentID}?payment-status=success`;
            } else {
              bKash.create().onError();
              //   if(paymentExecuteRequest.giftType == 'ConsumerGift'){
              //     window.location.href = "/consumer-payment/false/" + data.errorCode + "/" + data.errorMessage;
              //   }
              //   else{
              //     window.location.href = `/payments/0/transaction/${data.paymentID}?payment-status=failed&messge=payment failed`;
              //   }
              window.location.href = `/payments/0/transaction/${data.paymentID}?payment-status=failed&messge=payment failed`;
            }
          }, (error: any) => {
            console.log(error);
            bKash.create().onError();
            // if(paymentExecuteRequest.giftType == 'ConsumerGift'){
            //   window.location.href = "/consumer-payment/false/500/internal error happen";
            // }
            // else{
            //   window.location.href = `/payments/0/transaction/0?payment-status=failed&messge=internal error happen`;
            // }
            // window.location.href = "/consumer-payment/false/500/internal error happen";
            window.location.href = `/payments/0/transaction/0?payment-status=failed&messge=internal error happen`;
          });
      },
      onClose: function () {
        console.log('user close');
        //window.location.reload();
      }
    });
  }

  changePaymentOption(event) {
    //this.showbKashButton = event.value === 'bKash' ? true : false;
    if (event.value === 'bKash') {
      this.bKashInitialize();
      this.showbKashButton = true;
    }
    else {
      this.showbKashButton = false;
    }
  }

  close() {
    this.dialogRef.close();
  }
  get f() { return this.offerPaymentForm.controls; }
}
export class paymentInformation {
  amount: number;
  giftSenderId: number;
  giftId: number;
  paymentHolder: PaymentHolder;
  paymentOption: string;
  assignmentId: string;
  isDataEncrypted: boolean;
  giftType: OfferType;
  orderId: string;
  isBulkCodeGenerate: boolean;
}

