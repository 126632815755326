import { Component, OnInit, Injector } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateMerchantComponent } from '../create-merchant/create-merchant.component';
import { CompanyRole } from 'src/app/enums/company-role.enum';
import { Company } from 'src/app/models/company';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component'
import { CommonService, CompanyService, AccountServiceService, AlertService } from 'src/app/services';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-merchants',
  templateUrl: './merchants.component.html',
  styleUrls: ['./merchants.component.css']
})
export class MerchantsComponent extends BaseComponent implements OnInit {

  dataLoaded: Promise<boolean>;

  currentPage: number = 1;
  pageSize: number = 10;
  itemCountText: string;

  userInfo: any;
  canCreate: boolean = true;

  filteredMerchants: any[];
  merchants: any[];

  merchantFilter: any = { name: '' };
  merchantFilterText: string;
  selectedMerchantIndex: number = -1;
  selectedMerchant: Company;
  selectedMerchantName: string;

  merchantSubMenu: any[] = [];
  selectedSubMenuIndex: number = 0;
  activeSubMenu: string = "About";
  companyRoleType: CompanyRole = CompanyRole.Merchant;
  isMerchantLoggedIn: boolean = false;

  constructor(private companyService: CompanyService, private dialog: MatDialog,
    private accountService: AccountServiceService,
    private commonService: CommonService,
    private alertService: AlertService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.getLoggedInUser();
    this.getMerchants();

    this.merchantSubMenu.push({ "name": "About", "dataTarget": "aboutProviderTab" });
    if (this.checkPermission(['OutletRead'])) {
      this.merchantSubMenu.push({ "name": "Outlets", "dataTarget": "providerOutletsTab" });
    }
    if (this.checkPermission(['CompanyUpdate'])) {
      this.merchantSubMenu.push({ "name": "Users", "dataTarget": "providerUersTab" });
    }  
    if (this.checkPermission(['CompanySettingRead'])) {
      this.merchantSubMenu.push({ "name": "Settings", "dataTarget": "providerSettingsTab" });
    }
    this.dataLoaded = Promise.resolve(true);

  }

  createMerchant() {
    const dialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'golp-dialog';

    const merchantCreateDialog = this.dialog.open(CreateMerchantComponent, dialogConfig);

    const successfullCreate = merchantCreateDialog.componentInstance.onMerchantCreateEvent.subscribe((data) => {
      this.merchants.unshift(data);
      this.filteredMerchants.unshift(data);
      //this.highlightFirstItem(this.filteredMerchants);
      this.highlightItem(this.filteredMerchants, data);
      this.dataLoaded = Promise.resolve(true);

    });

    const successfullUpdate = merchantCreateDialog.componentInstance.onMerchantEditEvent.subscribe((data) => {
      this.merchants.unshift(data);
      this.filteredMerchants.unshift(data);
      //this.highlightFirstItem(this.filteredMerchants);
      this.highlightItem(this.filteredMerchants, data);
      this.dataLoaded = Promise.resolve(true);

    });

    merchantCreateDialog.afterClosed().subscribe(() => {
      //successFullEdit.unsubscribe();
      //this.detailsLoaded = Promise.resolve(false);
    });

  }

  highlightItem(merchants: Company[], showedItem: Company) {
    console.log("showed item: , ", showedItem);
    if (merchants !== null) {
      this.selectedMerchant = showedItem;
      this.selectedMerchantName = this.selectedMerchant.name;
      this.selectedMerchantIndex = merchants.findIndex(m=>m.id == showedItem.id);
    }
  }



  onMerchantChange(provider, index) {
    if (this.selectedMerchantIndex != index) {
      this.selectedMerchantIndex = index === this.selectedMerchantIndex ? -1 : index;
      this.selectedMerchant = provider;
      this.selectedMerchantName = provider.name;
    }
    let body = document.getElementsByTagName('body')[0];
    body.classList.add("display-tab-content");
    this.commonService.itemSelectionUpdated(this.selectedMerchant);
  }

  onSubMenuChange(name, index) {
    if (this.selectedSubMenuIndex != index) {
      this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
      this.activeSubMenu = this.merchantSubMenu.filter(c => c.name === name)[0].name;
    }
  }

  getMerchantDetailsChange(event) {
    let merchant = this.filteredMerchants.filter(c => c.id === event.id)[0];
    merchant.companyLogoUrl = event.companyLogoUrl;
    merchant.name = event.name;
  }

  OnDeleteMerchant(selectedMerchant: Company): void {
    const confirmationDialogConfig = new MatDialogConfig()

    // Setting different dialog configurations
    confirmationDialogConfig.data = "Are you sure to delete the merchant " + selectedMerchant.name;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.deleteMerchant(selectedMerchant);
      }
    });
  }

  deleteMerchant(merchant: Company) {
    this.companyService.deleteCompany(merchant.id).subscribe((data) => {
      this.alertService.success('Merchant deleted successfully');
      this.filteredMerchants = this.filteredMerchants.filter(function (item) {
        if (item.id !== merchant.id) {
          return true;
        }
        else {
          return false;
        }
      });

      this.merchants = this.merchants.filter(function (item) {
        if (item.id !== merchant.id) {
          return true;
        }
        else {
          return false;
        }
      });

      this.selectedMerchantIndex = 0
      this.selectedMerchant = this.filteredMerchants[this.selectedMerchantIndex];
      this.selectedMerchantName = this.selectedMerchant.name;
      this.currentPage = 1;
      this.itemCount();
    },
      (error) => {

        // if(error.status == 403){
        //   this.alertService.error('You do not have permission');
        // }
        // else{
        //   this.alertService.error('Internal server error happen');
        // }
      });
  }

  getMerchants(): void {
    this.companyService.getAllMerchants()
      .subscribe((data) => {
        this.merchants = data;
        this.merchantCopy();

        this.highlightFirstItem(this.filteredMerchants);
        this.itemCount();

      },
        (error) => {
          // if(error.status == 403){
          //   this.alertService.error('You do not have permission');
          // }
        });
  }

  getLoggedInUser() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
    if (CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
      this.canCreate = false;
      this.isMerchantLoggedIn = true;
    }
  }

  highlightFirstItem(merchants: Company[]) {
    // set initial offeror
    if (merchants !== null) {
      this.selectedMerchant = merchants[0];
      this.selectedMerchantName = this.selectedMerchant.name;
      this.selectedMerchantIndex = 0;
    }
  }

  pageChanged(event) {
    this.selectedMerchantIndex = 0;
    this.currentPage = event;
    let itemIndex = (this.currentPage * this.pageSize) - this.pageSize;
    this.selectedMerchant = this.filteredMerchants[itemIndex];
    this.selectedMerchantName = this.selectedMerchant.name;
    this.itemCount();
  }

  merchantFilteredItem(value) {
    if (!value) {
      this.merchantCopy();
    }
    this.filteredMerchants = Object.assign([], this.merchants).filter(item => item.name.toLowerCase().indexOf(value.toLowerCase()) > - 1);
    this.selectedMerchant = this.filteredMerchants[this.selectedMerchantIndex];
    this.selectedMerchantName = this.selectedMerchant != null ? this.selectedMerchant.name : '';
    this.itemCount();
  }

  merchantCopy() {
    this.filteredMerchants = Object.assign([], this.merchants);
  }

  itemCount() {
    let firstItem = (this.currentPage - 1) * this.pageSize + 1;
    let lastItem = (this.currentPage) * this.pageSize;

    this.itemCountText = firstItem + ' - ' + (lastItem < this.filteredMerchants.length ? lastItem : this.filteredMerchants.length) + ' of ' + this.filteredMerchants.length + ' merchants';

  }


  errorHandlerCompanyLogo(event, merchantId) {
    //event.target.src = "http://place-hold.it/24x24/ebe89f/9D2933&fontsize=12&text=" + merchantFirstChar;

    document.getElementById('companyLogoImgtxt_' + merchantId).style.display = "block";
    document.getElementById('companyLogoImg_' + merchantId).style.display = "none";
  }

}
