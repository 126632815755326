import { Component, OnInit } from '@angular/core';
import { DataHistoryService } from 'src/app/services';
import { AccountServiceService, CommonService, AlertService, CompanyService } from 'src/app/services';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-data-history',
  templateUrl: './data-history.component.html',
  styleUrls: ['./data-history.component.css']
})
export class DataHistoryComponent implements OnInit {
  dataLoaded: Promise<boolean>;
  currentPage: number = 1;
  pageSize: number = 10;
  itemCountText: string;
  selectedCompanyIndex: number = 0;

  userInfo: any;
  canCreate: boolean = true;

  filteredsiteAdmins: any[];
  siteAdmins: any[];

  siteAdminFilter: any = { name: '' };
  siteAdminFilterText: string;
  selectedsiteAdminIndex: number = -1;
  selectedsiteAdminName: string;

  dataHistorySubMenu: any[] = [];
  selectedSubMenuIndex: number = 0;
  activeSubMenu: string = "Offers";
  offerHistoryList: any[];
  companiesHistoryList: any[];
  offerAssignmentsHistoryList: any[];
  availOfferTransactionHistoryList: any[];
  invoicesHistoryList: any[];
  offerFilterStartDate = new FormControl(new Date());
  offerFilterEndDate = new FormControl(new Date());
  companiesFilterStartDate = new FormControl(new Date());
  companiesFilterEndDate = new FormControl(new Date());
  offerAssignmentFilterStartDate = new FormControl(new Date());
  offerAssignmentFilterEndDate = new FormControl(new Date());
  availOfferTransactionFilterStartDate = new FormControl(new Date());
  availOfferTransactionFilterEndDate = new FormControl(new Date());
  invoicesFilterStartDate = new FormControl(new Date());
  invoicesFilterEndDate = new FormControl(new Date());
  constructor(private datahistoryservice: DataHistoryService, private dialog: MatDialog, private accountService: AccountServiceService,
    private commonService: CommonService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.dataHistorySubMenu.push({ "name": "Offers", "displayName": "Offers", "dataTarget": "offersTab" });
    this.dataHistorySubMenu.push({ "name": "Companies", "displayName": "Companies",  "dataTarget": "companiesTab" });
    this.dataHistorySubMenu.push({ "name": "OfferAssignments", "displayName": "Offer Assignments",  "dataTarget": "offerAssignmentsTab" });
    this.dataHistorySubMenu.push({ "name": "AvailOfferTransactions", "displayName": "Avail Offer Transactions",  "dataTarget": "availOfferTransactionsTab" });
    this.dataHistorySubMenu.push({ "name": "Invoices", "displayName": "Invoices",  "dataTarget": "invoicesTab" });
    this.getOfferHistory();
  }
  getOfferHistory() {
    const fromDate = moment(this.offerFilterStartDate.value).format("YYYY-MM-DD");
    const toDate = moment(this.offerFilterEndDate.value).add(1, 'd').format("YYYY-MM-DD");
    this.datahistoryservice.getOfferHistoryList(fromDate, toDate)
      .subscribe((data) => {
        this.offerHistoryList = data;
        console.log(this.offerHistoryList);
      });
  }
  getCompaniesHistory() {
    const fromDate = moment(this.companiesFilterStartDate.value).format("YYYY-MM-DD");
    const toDate = moment(this.companiesFilterEndDate.value).add(1, 'd').format("YYYY-MM-DD");
    this.datahistoryservice.getCompaniesHistoryList(fromDate, toDate)
      .subscribe((data) => {
        this.companiesHistoryList = data;
        console.log(this.companiesHistoryList);
      });
  }
  getOfferAssignmentsHistory() {
    const fromDate = moment(this.offerAssignmentFilterStartDate.value).format("YYYY-MM-DD");
    const toDate = moment(this.offerAssignmentFilterEndDate.value).add(1, 'd').format("YYYY-MM-DD");
    this.datahistoryservice.getOfferAssignmentsHistoryList(fromDate, toDate)
      .subscribe((data) => {
        this.offerAssignmentsHistoryList = data;
        console.log(this.offerAssignmentsHistoryList);
      });
  }
  getAvailOfferTransactionHistory() {
    const fromDate = moment(this.availOfferTransactionFilterStartDate.value).format("YYYY-MM-DD");
    const toDate = moment(this.availOfferTransactionFilterEndDate.value).add(1, 'd').format("YYYY-MM-DD");
    this.datahistoryservice.getAvailOfferTransactionHistoryList(fromDate, toDate)
      .subscribe((data) => {
        this.availOfferTransactionHistoryList = data;
        console.log(this.availOfferTransactionHistoryList);
      });
  }
  getInvoicesHistory() {
    const fromDate = moment(this.invoicesFilterStartDate.value).format("YYYY-MM-DD");
    const toDate = moment(this.invoicesFilterEndDate.value).add(1, 'd').format("YYYY-MM-DD");
    this.datahistoryservice.getInvoicesHistoryList(fromDate, toDate)
      .subscribe((data) => {
        this.invoicesHistoryList = data;
        console.log(this.invoicesHistoryList);
      });
  }

  onDateChange() {
    switch (this.activeSubMenu) {
      case 'Offers':
        this.getOfferHistory();
        break;
      case 'Companies':
        this.getCompaniesHistory();
        break;
      case 'OfferAssignments':
        this.getOfferAssignmentsHistory();
        break;
      case 'AvailOfferTransactions':
        this.getAvailOfferTransactionHistory();
        break;
      case 'Invoices':
        this.getInvoicesHistory();
        break;
      default:
        this.getOfferHistory();
        break;
    }
  }

  onSubMenuChange(name, index) {
    if (this.selectedSubMenuIndex != index) {
      this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
      this.activeSubMenu = this.dataHistorySubMenu.filter(c => c.name === name)[0].name;
    }
  }
}
