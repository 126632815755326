import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CorporateOfferService } from 'src/app/corporate-offers/services/corporate-offer.service';

import * as moment from 'moment';
import { ValidationUtility } from 'src/app/utility/validation.utility';

@Component({
  selector: 'app-offer-summary-report',
  templateUrl: './offer-summary-report.component.html',
  styleUrls: ['./offer-summary-report.component.css']
})
export class OfferSummaryReportComponent implements OnInit {
  @BlockUI('offer-report-section') blockUI: NgBlockUI;

  offerReportForm: FormGroup;

  currentPage: number = 1;
  pageSize: number = 25;
  expandedIndex: number = -1;
  totalItems: number = 0;

  defaultStartDate: Date = new Date();
  defaultEndDate: Date = new Date();
  paginationPageNumbers: any[];
  currentPageNumberDetails: string;
  submitted: boolean;

  userInfo: any;
  offerSummary: any[];

  offerSend: number = 0;
  offeredAmount: number = 0;
  redeemedAmount: number = 0;
  salesAmount: number = 0;

  constructor(private formBuilder: FormBuilder,
    private corporateOfferService: CorporateOfferService) {
    var startDate1 = new Date();
    //startDate1.setDate(startDate1.getDate() - 7);
    this.defaultStartDate = startDate1;
    this.defaultEndDate = new Date();
    this.buildForm();
  }

  ngOnInit(): void {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userInfo = userInfo;

    this.getOfferReportData();
  }



  buildForm() {
    this.offerReportForm = this.formBuilder.group({
      fromDate: [this.defaultStartDate, Validators.required],
      toDate: [this.defaultEndDate, Validators.required],
      receiverPhoneNumber: '',
    });
  }

  exportToExcel() {

  }

  getOfferReportData() {

    this.submitted = true;
    // stop here if form is invalid
    if (this.offerReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    let fromDate = moment(this.f.fromDate.value).format("YYYY/MM/DD");
    let todate = moment(this.f.toDate.value).format("YYYY/MM/DD");


    this.corporateOfferService.get(`api/offerreports/offer-summary/${this.userInfo.companyId}?fromDate=${fromDate}&toDate=${todate}`)
      .subscribe((response: any) => {
        if (response.success) {
          this.offerSummary = response.data;
          if (this.offerSummary != null && this.offerSummary.length > 0) {
            this.offerSend = 0;
            this.offeredAmount = 0;
            this.redeemedAmount = 0;
            this.salesAmount = 0;
            this.offerSummary?.forEach(os => {
              this.offerSend += os.offerSend;
              this.offeredAmount += os.offeredAmount;
              this.redeemedAmount += os.redeemedAmount;
              this.salesAmount += os.salesAmount;
            });
          }
        }
      });
  }

  expandCollapse($index) {

  }

  get f() { return this.offerReportForm.controls; }


}