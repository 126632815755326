export class MerchantDueReport {
  outlet: string;
  giftAmount: number;
  salesAmount: number;
  invoiceNumber: string;
  salesCommission: number;
  payableAmount: number;
  createdDate: Date;
  public constructor(init?: Partial<MerchantDueReport>) {
    Object.assign(this, init);
  }
}
