import { Injectable } from "@angular/core";
import { DataService } from "../components/app-send-gift/services/shared/data.service";
import { MerchantOutletResponse } from "../models/view-models/merchant-outlet-response";



@Injectable({
    providedIn: 'root'
  })
export class MerchantService {

    userInfo:any;

    constructor(private dataService: DataService){
        this.setUserInfo();
    }

    setUserInfo() {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.userInfo = userInfo;    
    }

    

    getAllOutletMerchant(): any{
        if(this.userInfo.companyId == undefined || this.userInfo.companyId == null)
            return null;
        let url = `api/Outlets/company/${this.userInfo.companyId}/`;
        return this.dataService.get(url).subscribe((data: MerchantOutletResponse[]) => {
            if(data != null && data.length > 0){
                return data;
            }
        });
    }

}