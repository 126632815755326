import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { OfferAssignmentService } from 'src/app/services';
import { FormGroup } from '@angular/forms';
import { RedemptionGiftDetails } from 'src/app/models/redemption-gift-details';

@Component({
  selector: 'app-redemption',
  templateUrl: './redemption.component.html',
  styleUrls: ['./redemption.component.css']
})
export class RedemptionComponent implements OnInit {

  @ViewChild('purchaseamount') purchaseAmountRef: ElementRef;

  redemptionForm: FormGroup
  submitted = false;

  userInfo: any;
  giftDetails: RedemptionGiftDetails;
  offerDetails: any;
  giftCode: any;
  giftPin: any;
  notFoundMessage: string = "search gift by gift code & PIN.";
  noGiftFound: boolean = true;
  offerString:string;
  isVisibleGiftRedemption: boolean = false;
  isVisibleOfferRedemption: boolean = false;

  constructor(
    private assignmentService: OfferAssignmentService) {
    this.giftDetails = new RedemptionGiftDetails();
  }

  ngOnInit() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    //console.log(this.userInfo);
    this.getSubscription();
  }
  getSubscription(){
    let subscriptions: any = JSON.parse(localStorage.getItem("_sub"));
    if(subscriptions.offerCard){
      this.offerString = "/Offer";
      this.notFoundMessage = "search gift/offer by gift/offer code & PIN.";
    }
  }

  searchGift() {
    this.noGiftFound = true;
    if (this.giftCode == undefined) return;
    if (this.giftCode.length == 0) return;
    this.getGiftByGiftCode();
  }

  getGiftByGiftCode() {
    let searchObject = {
      'giftCode': this.giftCode,
      'giftPin': this.giftPin,
      'merchantId': this.userInfo.companyId
    };
    this.assignmentService.getGiftByGiftCode(searchObject)
      .subscribe((result: any) => {
        if (result.success && result.type == "gift") {
          this.renderGiftRedemptionPanel(result);
        }
        else if (result.success && result.type == "offer") {
          this.renderOfferRedemptionPanel(result);
        }
        if (!result.success) {
          console.log( "Avail Response : ",  result);
          // let subscriptions: any = JSON.parse(localStorage.getItem("_sub"));
          // if(!subscriptions.offerCard){
          //   this.notFoundMessage = "Gift not found using this code and pin.";
          // }
          // else this.notFoundMessage = result.data;
          this.notFoundMessage = result.data;
          this.noGiftFound = true;
          this.isVisibleGiftRedemption = false;
          this.isVisibleOfferRedemption = false;
        }
      }, (error) => {
        console.log(error);
      });
  }

  renderGiftRedemptionPanel(result: any) {
    this.noGiftFound = false;
    this.giftDetails = result.data;
    this.isVisibleGiftRedemption = true;
    this.isVisibleOfferRedemption = false;
  }

  renderOfferRedemptionPanel(result: any) {
    this.noGiftFound = false;
    this.offerDetails = result.data;
    this.isVisibleOfferRedemption = true;
    this.isVisibleGiftRedemption = false;
  }

  // convenience getter for easy access to form fields
  get f() { return this.redemptionForm.controls; }

}
