import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-change-merchant',
  templateUrl: './change-merchant.component.html',
  styleUrls: ['./change-merchant.component.css'],
})
export class ChangeMerchantComponent implements OnInit {
  confirmationMessage: string;
  confirmationWithTextForm: FormGroup;
  submitted: boolean = false;
  merchantList: any;

  constructor(
    private dialogRef: MatDialogRef<ChangeMerchantComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    if (data !== null) {
      this.confirmationMessage = data.confirmationMessage;
      this.merchantList = data.merchantList;
    }
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.confirmationWithTextForm = this.formBuilder.group({
      merchantId: ['', [Validators.required]]
    });
  }

  confirm() {
    this.submitted = true;
    if(this.confirmationWithTextForm.invalid)
      return;
    this.dialogRef.close(this.confirmationWithTextForm.value);
  }
  close() {
    this.dialogRef.close();
  }

  get f() {
    return this.confirmationWithTextForm.controls;
  }
}
