import { ChangeMerchantComponent } from './../change-merchant/change-merchant.component';
import { InfoModalComponent } from './../info-modal/info-modal.component';
import { AlertService } from './../../services/alert.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import * as $ from 'jquery';
import { DataService } from '../app-send-gift/services/shared/data.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Select2OptionData } from 'ng-select2';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationWithTextComponent } from '../confirmation-with-text/confirmation-with-text.component';
import { ExtendGiftExpireDateComponent } from '../extend-gift-expire-date/extend-gift-expire-date.component';
import { ChangeGiftAssignmentComponent } from '../change-gift-assignment/change-gift-assignment.component';
import { OfferAssignmentService } from 'src/app/services';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
@Component({
  selector: 'app-customer-support-page',
  templateUrl: './customer-support-page.component.html',
  styleUrls: ['./customer-support-page.component.css'],
})
export class CustomerSupportPageComponent implements OnInit {
  offerReportForm: FormGroup;

  currentPage: number = 1;
  pageSize: number = 5;
  expandedIndex: number = -1;
  totalItems: number = 0;
  count = 0;
  pageSizes = [5, 10, 25, 50, 100];

  paginationInfo: string = '';

  defaultStartDate: Date = new Date();
  defaultEndDate: Date = new Date();
  paginationPageNumbers: any[];
  currentPageNumberDetails: string;
  submitted: boolean;
  companyOutletId: any;

  userInfo: any;
  reportSummary: any[];

  soldGift: number = 0;
  soldGiftAmount: number = 0;
  redeemedGift: number = 0;
  redeemedGiftAmount: number = 0;
  p: number = 1;
  currentRemarks: string;
  merchantList: any;

  select2MerchantInfo: Array<Select2OptionData>;
  giftResponse: any;
  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private dialog: MatDialog,
    private offerAssignmentService: OfferAssignmentService,
    private alertService: AlertService
  ) {
    // var startDate1 = new Date();
    //startDate1.setDate(startDate1.getDate() - 7);
    // this.defaultStartDate = this.startOfMonth(startDate1);
    // this.defaultEndDate = new Date();
    this.buildForm();
    //this.dateInput.focus();
  }

  ngOnInit(): void {
    this.loadMerchant();
    // this.getOfferReportData();
  }

  ngAfterViewInit(): void { }

  buildForm() {
    this.offerReportForm = this.formBuilder.group({
      fromDate: '',
      receiverPhoneNumber: '',
      globalInput: '',
      merchantId: '',
    });
  }

  loadMerchant() {
    let url = 'api/company/merchant/basics';
    this.dataService.get(url).subscribe(
      (data: any) => {
        this.merchantList = data;
        this.select2MerchantInfo = this.merchantList.map((p) => {
          return {
            id: p.id,
            text: p.name,
          };
        });
        let noMerchant: Select2OptionData = {
          id: "-1",
          text: "Select Merchant",
        };
        this.select2MerchantInfo.unshift(noMerchant);
      },
      catchError(() => {
        return throwError('Something went wrong!');
      })
    );
  }

  checkSms(item) {

    let url = `api/contact-support/check-sms-status/${item.giftTemplateId}?receiver=${item.giftReceiver}&giftSendDate=${item.createdDate}`;
    this.dataService.get(url).subscribe((response: any) => {
      if (response.success) {
        const confirmationDialogConfig = new MatDialogConfig();
        // Setting different dialog configurations
        if (response.data?.smsDeliveryStatus == 'Delivered') {
          //let message = `<div>SMS has been ${response.data.smsDeliveryStatus?.toLowerCase()} at ${response.data?.deliveryDate}</div>`
          confirmationDialogConfig.data = `SMS has been ${response.data.smsDeliveryStatus?.toLowerCase()} at ${response.data?.deliveryDate}`;
        }
        else {
          confirmationDialogConfig.data = `SMS sending ${response.data?.smsDeliveryStatus?.toLowerCase()}`;
        }
        confirmationDialogConfig.panelClass = 'golp-dialog';
        const confirmationDialog = this.dialog.open(
          InfoModalComponent,
          confirmationDialogConfig
        );

        confirmationDialog.afterClosed().subscribe(() => { });
      } else {
        const confirmationDialogConfig = new MatDialogConfig();
        // Setting different dialog configurations
        confirmationDialogConfig.data = `${response.data}`;
        confirmationDialogConfig.panelClass = 'golp-dialog';
        const confirmationDialog = this.dialog.open(
          InfoModalComponent,
          confirmationDialogConfig
        );

        confirmationDialog.afterClosed().subscribe(() => {
        });
      }
    });
  }

  viewCode(item) {
    const confirmationDialogConfig = new MatDialogConfig();
    confirmationDialogConfig.data = `<strong>Gift Card Id:</strong> ${item.id} <br /><strong>Code:</strong> ${item.giftCode}`;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(
      InfoModalComponent,
      confirmationDialogConfig
    );

    confirmationDialog.afterClosed().subscribe(() => { });
  }

  showResendConfirmation(item) {
    const confirmationDialogConfig = new MatDialogConfig();
    // Setting different dialog configurations
    confirmationDialogConfig.data = `Would you like to proceed? This operation can not be undone.`;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(
      ConfirmationDialogComponent,
      confirmationDialogConfig
    );

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.resendSms(item.id);
      }
    });
  }

  resendSms(giftAssignmentId) {
    let url = 'api/OfferAssignments/send-sms-email-notification';
    let payload = {
      assignmentId: giftAssignmentId
    }
    this.dataService.post(url, payload).subscribe(
      (res: any) => {
        if (res.success) {
          this.alertService.success('SMS sent successfully.');
        } else {
          this.alertService.error('SMS sending failed.');
        }
      },
      () => { }
    );
  }

  showBlockGiftConfirmation(item: any) {
    const confirmationDialogConfig = new MatDialogConfig();
    var data = {
      "title": 'Block Gift Card',
      "message": 'Are you sure to block this gift?'
    };

    confirmationDialogConfig.data = data;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(
      ConfirmationWithTextComponent,
      confirmationDialogConfig
    );

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        var obj = { giftId: item.id.toString(), notes: result.note };
        this.blockGift(obj);
      }
    });
  }

  blockGift(payload: any) {
    let url = 'api/OfferAssignments/block-gift-card';
    this.dataService.post(url, payload).subscribe(
      (res: any) => {
        if (res.success) {
          this.alertService.success('Gift card blocked successfully.');
          this.getOfferReportData();
        } else {
          this.alertService.error('Gift card blocking failed.');
        }
      },
      () => { }
    );
  }


  showMakeOpenMerchantDailog(assignmentId: string) {
    const confirmationDialogConfig = new MatDialogConfig();
    var text = 'Are you sure to make open merchant for this gift?';
    var successMessage = 'Gift merchant made open successfully';

    confirmationDialogConfig.data = text;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(
      ConfirmationWithTextComponent,
      confirmationDialogConfig
    );

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        var obj = { offerAssignmentId: assignmentId, note: result.note };

        this.offerAssignmentService
          .makeOpenMerchant(obj)
          .subscribe((result: any) => {
            if (result.isValid) {
              this.alertService.success(successMessage);
              this.getOfferReportData();
            }
            else {
              this.alertService.error("Open merchant operation can not be applied for this gift card.");
            }
          });
      }
    });
  }

  showChangeAssignment(item: any) {
    this.showChangeAssignmentDailog(item.id, item.giftReceiver);
  }

  showChangeAssignmentDailog(
    assignmentId: any,
    giftReceiverPhoneNumber: string
  ) {
    const userDialogConfig = new MatDialogConfig();

    userDialogConfig.disableClose = true;
    userDialogConfig.autoFocus = true;
    userDialogConfig.data = { assignmentId, giftReceiverPhoneNumber };
    userDialogConfig.panelClass = 'golp-dialog';

    const showOfferAssignmentDialog = this.dialog.open(
      ChangeGiftAssignmentComponent,
      userDialogConfig
    );

    showOfferAssignmentDialog.afterClosed().subscribe(() => { });
  }

  showExtendGiftExpireDateDailog(
    assignmentId: any,
    currentExpiredDate: string
  ) {
    const userDialogConfig = new MatDialogConfig();

    userDialogConfig.disableClose = true;
    userDialogConfig.autoFocus = true;
    userDialogConfig.data = { assignmentId, currentExpiredDate };
    userDialogConfig.panelClass = 'golp-dialog';

    const showOfferAssignmentDialog = this.dialog.open(
      ExtendGiftExpireDateComponent,
      userDialogConfig
    );

    showOfferAssignmentDialog.afterClosed().subscribe(() => { });
  }

  showChangeMerchant(item: any) {
    //if (item.merchantId == null) return;
    const confirmationDialogConfig = new MatDialogConfig();
    var text = 'Are you sure to change merchant for this gift?';
    let obj = {
      confirmationMessage: text,
      merchantList: this.select2MerchantInfo,
    };
    confirmationDialogConfig.data = obj;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(
      ChangeMerchantComponent,
      confirmationDialogConfig
    );

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        let url = 'api/OfferAssignments/change-merchant';
        var obj = {
          newMerchantId: result.merchantId,
          oldMerchantId: item.merchantId,
          giftId: item.id
        };
        //remote call 
        this.dataService.post(url, obj).subscribe((res: any) => {
          if (res.success) {
            this.alertService.success("Merchant has been changed successfully.")
            this.getOfferReportData();
          }
          else {
            this.alertService.error('Merchant can not be changed for this gift.');
          }
        }, () => {

        });
      }
    });
  }

  startOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }
  exportToExcel() { }

  getMerchantChangeButtonText(item) {
    console.log('Item', item);
    if(item.merchantId == null) {
      return 'Select Merchant';
    }
    return 'Change Merchant';
  }

  getOfferReportData(submitted: boolean = false) {
    this.submitted = submitted;
    // stop here if form is invalid
    if (this.offerReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    let fromDate = this.f.fromDate.value;
    let receiver = this.f.receiverPhoneNumber.value;
    let globalInput = this.f.globalInput.value;
    let merchantId = this.f.merchantId.value;

    let url = `api/contact-support/search-gift-card?pageSize=${this.pageSize}&pageNumber=${this.p}&`;
    if (fromDate != null && fromDate != '')
      url += `sendDate=${moment(this.f.fromDate.value).format('YYYY/MM/DD')}&`;
    if (receiver != null && receiver != '') url += `receiver=${receiver}&`;
    if (globalInput != null && globalInput != '')
      url += `globalSearch=${globalInput}&`;
    if (merchantId != null && merchantId > 0) url += `merchantId=${merchantId}`;

    this.dataService.get(url).subscribe((response: any) => {
      if (submitted) this.p = 1;
      this.giftResponse = response;
      console.log(this.giftResponse);
      this.count = this.giftResponse.data.totalCount;
      this.getCurrentRange();
      loadJquery();
    });
  }

  get f() {
    return this.offerReportForm.controls;
  }

  openRemarks(input) {
    this.currentRemarks = input;
  }

  getShortString(input) {
    if (input != undefined && input != null && input.length > 20) {
      input = input.substring(0, 20);
      input += '...';
    }
    return input;
  }

  handlePageChange(event: number): void {
    this.p = event;
    this.getOfferReportData();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.p = 1;
    this.getOfferReportData();
  }

  getCurrentRange() {
    this.paginationInfo = `Showing ${(this.p - 1) * this.pageSize + 1}-${(this.p - 1) * this.pageSize + this.giftResponse.data.totalCard} of ${this.count}`;
  }
}

function loadJquery() {
  $(document).ready(function () {
    // $("th.d-lg-table-cell").addClass('clone');
    var cells = $('.redesign');

    for (var headCell = 0; headCell < cells.length;) {
      $(cells[headCell++]).addClass('d-sticky');
      $(cells[headCell++]).addClass('d-sticky2');
      $(cells[headCell++]).addClass('d-sticky3');
      $(cells[headCell++]).addClass('d-sticky4');
    }
  });
}
