import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CompanyService } from '../../../services';
import { Company, merchantDuePaymentSetting } from '../../../models';
import { ReplaySubject } from 'rxjs';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'app-create-merchant-due-payment-setting',
  templateUrl: './create-merchant-due-payment-setting.component.html',
  styleUrls: ['./create-merchant-due-payment-setting.component.css']
})

export class CreateMerchantDuePaymentSettingComponent implements OnInit {

  @Output() merchantDuePaymentEmitter: EventEmitter<any> = new EventEmitter<any>();
  merchantDuePaymentSetting: merchantDuePaymentSetting = new merchantDuePaymentSetting();
  showBy: number;
  showOnDay: number;
  dailyWeeklyMonthlyEnum: any;
  dayOfWeeks: any;

  constructor(private formBuilder: FormBuilder, private sharedService: SharedService) {
    this.buildForm();
  }

  merchantDuePaymentSettingForm: FormGroup;
  @Input() isSubmitted: false;

  submitted = false;

  @Input() existingMerchantDuePaymentSetting: any;

  ngOnInit() {
    if (this.existingMerchantDuePaymentSetting) {
      this.merchantDuePaymentSetting = JSON.parse(this.existingMerchantDuePaymentSetting); // Need to convert json to this type;

      this.showBy = this.merchantDuePaymentSetting.showBy;
      this.showOnDay = this.merchantDuePaymentSetting.showOnDay;

      this.merchantDuePaymentSettingForm.patchValue({
        showBy: this.merchantDuePaymentSetting.showBy,

        showOnDay: this.merchantDuePaymentSetting.showOnDay,
      });
    }
    else {
      this.merchantDuePaymentSetting = new merchantDuePaymentSetting();
    }

    this.getDailyWeeklyMonthlyTypes();
    this.getDayOfWeeks();

    this.setMerchantDuePaymentAndEmit();
  }
  getDailyWeeklyMonthlyTypes() {
    this.dailyWeeklyMonthlyEnum = this.sharedService.getDailyWeeklyMonthlyTypes();
  }

  ngOnChanges() {
    this.submitted = this.isSubmitted;
  }

  getDayOfWeeks() {
    this.dayOfWeeks = this.sharedService.getDayOfWeeks();
  }

  buildForm() {
    this.merchantDuePaymentSettingForm = this.formBuilder.group({
      showBy: [this.merchantDuePaymentSetting.showBy, Validators.required],
      showOnDay: [this.merchantDuePaymentSetting.showOnDay, Validators.required]
    });
  }



  filterData(allData: any, search: string, filterData: any) {
    if (!allData) {
      return;
    }
    if (!search) {
      filterData.next(allData);
      return;
    } else {
      search = search.toLowerCase();
    }
    filterData.next(
      allData.filter(data => data.name.toLowerCase().indexOf(search) > -1)
    );
  }



  onChangemerchantDuePaymentShowType($event) {
    //this.merchantDuePaymentSetting.showOnDay = null;
    //this.f.showOnDay.setValue("");
    //this.f.showOnDay.updateValueAndValidity();
    this.setMerchantDuePaymentAndEmit();
  }


  get f() { return this.merchantDuePaymentSettingForm.controls; }


  setMerchantDuePayment() {
    this.merchantDuePaymentSetting.showBy = this.f.showBy.value;
    if (this.merchantDuePaymentSetting.showBy == 1) { // daily
      this.merchantDuePaymentSetting.showOnDay = null;
    }
    else if (this.merchantDuePaymentSetting.showBy == 3) { // monthly
      this.merchantDuePaymentSetting.showOnDay = this.f.showOnDay.value;

      var days = this.merchantDuePaymentSetting.showOnDay.split(',');

      var isValidNumberArray = true;
      var isRangeValid = true;
      days.forEach(x => {
        x = x.trim();
        if (!Number(x) && +x != 0) {
          isValidNumberArray = false;
        }
        else {
          if (+x < 1 || +x > 31) {
            isRangeValid = false;
          }
        }
      })

      if (!isValidNumberArray) {
        this.merchantDuePaymentSettingForm.controls['showOnDay'].setErrors({ 'invalid': true });
      }

      if (!isRangeValid) {
        this.merchantDuePaymentSettingForm.controls['showOnDay'].setErrors({ 'range': true });
      }

    } else { // weekly
      this.merchantDuePaymentSetting.showOnDay = this.f.showOnDay.value;
    }
  }


  setMerchantDuePaymentAndEmit() {
    this.setMerchantDuePayment();
    this.merchantDuePaymentEmitter.emit(this.merchantDuePaymentSetting);
  }


}

