import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { RolePermission } from "../enums";
import { TabModel } from '../models';
import { GolpAppUtil } from '../utility/golp-app-utils';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let authorites = route.data['authorites'];
    let unauthorizedRole = route.data['unauthorizedRole'];
    let subscriptionRequired = route.data["subscriptionRequired"];
    let subscriptions: any = JSON.parse(localStorage.getItem("_sub"));

    this.checkActiveTabs();

    if (subscriptionRequired !== undefined && !subscriptions[subscriptionRequired[0]]) {
      this.navigation();
      return false;
    }

    if (subscriptions?.giftCard && (localStorage.getItem('userRole') === 'MerchantAdmin' || localStorage.getItem('userRole') === 'OutletAdmin' || localStorage.getItem('userRole') === 'OutletUser')
      && route?.routeConfig.path === "gifts") {
      this.router.navigate(['/dashboard']);
      return false;
    }
    else if (subscriptions?.giftCard && (localStorage.getItem('userRole') === 'ReportViewer')
      && route?.routeConfig.path === "gifts") {
      this.router.navigate(['/gift-sell-report']);
      return false;
    }

    var isValid = false;
    var hasPermission = false;

    var userRolePermissions = localStorage.getItem('userRolePermissions');
    if (userRolePermissions) {

      if (authorites) {
        let intersection = authorites.filter(x => userRolePermissions.includes(RolePermission[x]));
        hasPermission = intersection.length > 0;
        if (unauthorizedRole) {
          let existUnauthorized = unauthorizedRole.filter(x => x === localStorage.getItem('userRole'));

          hasPermission = hasPermission && !(existUnauthorized.length > 0);
        }
      }
      else {
        hasPermission = true;
      }
      isValid = true;
    }

    if (localStorage.getItem('userToken') !== null && isValid && hasPermission) {
      return true;
    }
    else if (localStorage.getItem('userToken') !== null && !hasPermission) {
      this.navigation();
      return false;
    }

    this.navigation();
    return false;
  }

  navigation() {
    if (localStorage.getItem('userRole') === 'ServiceConsumer') {
      this.router.navigate(['/loginwithphone']);
    }
    else {
      this.router.navigate(['/login']);
    }
  }

  checkActiveTabs() {
    let localStorageTabs: TabModel[] = [];
    const sessionTabId: number = Number(sessionStorage.getItem(GolpAppUtil.TAB_ID));
    localStorageTabs = JSON.parse(localStorage.getItem(GolpAppUtil.TABS));
    if (sessionTabId == 0 && localStorageTabs != null) {
      let activeTabs = localStorageTabs.find(item => item.status == true);
      if (activeTabs == undefined) {
        localStorage.clear();
        this.navigation();
      }
    }
  }
}
