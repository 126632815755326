import { AccountServiceService } from './../../../../services/account-service.service';
import { DataService } from './../../../../components/app-send-gift/services/shared/data.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CompanyRole } from 'src/app/enums';

@Component({
  selector: 'app-avail-card',
  templateUrl: './avail-card.component.html',
  styleUrls: ['./avail-card.component.css']
})
export class AvailCardComponent implements OnInit {

  redemptionForm: FormGroup
  submitted = false;

  userInfo: any;
  cardDetails: any;
  searchQuery: any;
  notFoundMessage: string = "search by Privilege Card No. / Mobile No.";
  noGiftFound: boolean = true;
  isVisibleCardRedemption: boolean = false;
  userRole:any;
  isMerchantLoggedIn:boolean = false;
  constructor(
    private dataService: DataService, private accountService:AccountServiceService) {}

  ngOnInit() {
    let userInfo = this.accountService.getLoggedInUserInfo();
    this.userInfo = userInfo;
    this.userRole = userInfo.roles[0].name;

    if (CompanyRole.Merchant === userInfo.companyRole && !userInfo.isSystemAdmin) {
      this.isMerchantLoggedIn = true;
    }
    //console.log(this.userInfo);
    this.getSubscription();
  }
  getSubscription(){
    let subscriptions: any = JSON.parse(localStorage.getItem("_sub"));
    if(subscriptions.offerCard){
      this.notFoundMessage = "search by Privilege Card No. / Mobile No.";
    }
  }

  searchGift() {
    this.noGiftFound = true;
    if (this.searchQuery == undefined) return;
    if (this.searchQuery.length == 0) return;
    this.getGiftByGiftCode();
  }

  getGiftByGiftCode() {
    let url = `api/CardBeneficiary/search-card/${this.userInfo.companyId}?searchQuery=${this.searchQuery}`
    
    this.dataService.get(url)
      .subscribe((result: any) => {
        if(result.success){
          this.cardDetails = result.data.card;
          this.cardDetails["termsConditions"] = result.data.termsConditions
          this.isVisibleCardRedemption = true;
        }
        else{
          this.isVisibleCardRedemption = false;
          this.notFoundMessage = result.data;
        }
      }, (error) => {
        console.log(error);
      });
  }

  // convenience getter for easy access to form fields
  get f() { return this.redemptionForm.controls; }

}
