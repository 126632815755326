export class LoyaltyMerchantPoint {
  id: any;
  loyaltyMerchantId: any;
  customerPhoneNumber: string;
  customerExternalId: string;
  cardTier: string;
  salesAmount: number;
  expireDate: any;

  public constructor(init?: Partial<LoyaltyMerchantPoint>) {
    Object.assign(this, init);
  }
}
