import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, UserCreditsService } from '../../services';
import { UserCredit } from '../../models';

@Component({
  selector: 'app-create-user-credit',
  templateUrl: './create-user-credit.component.html',
  styleUrls: ['./create-user-credit.component.css']
})

export class CreateUserCreditComponent implements OnInit {

  // onCreate event
  onCreateUserCreditEvent: EventEmitter<number> = new EventEmitter();

  userCreditForm: FormGroup;
  submitted = false;
  offerAssignmentData: FormData;
  assignmentFiles: FormData;
  inputData: UserCredit;
  userId: number;


  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateUserCreditComponent>,
    private alertService: AlertService,
    private userCreditsService: UserCreditsService,
    @Inject(MAT_DIALOG_DATA) data) {

    if (data !== null) {
     this.userId = data.userId;
    }

    this.userCreditForm = this.formBuilder.group({
        amount: [null, [Validators.required, Validators.min(1)]],
    });
  }

  ngOnInit() {
  }

  onSubmit() {
    this.submitted = true;

    if (this.userCreditForm.invalid) {
      return;
    }

    this.dialogRef.close();     // to close the modal

    this.inputData = new UserCredit();
    this.inputData.amount = this.userCreditForm.get('amount').value;
    this.inputData.userId = this.userId;

    this.userCreditsService.createUserCredit(this.inputData)
      .subscribe((data: any) => {
        this.onCreateUserCreditEvent.emit(data);
        this.alertService.success('Credit added successfully');
      },
        (error: any) => {
        });
  }

  close() {
    this.dialogRef.close();
  }

  // convenience getter for easy access to form fields
  get f() { return this.userCreditForm.controls; }

}

