import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-card-date-range-operation-condition',
  template: `
                  <form class="offer-from" [formGroup]="dateRangeConditionForm">
                      <div class="form-group">
                          <mat-form-field>
                              <input class="form-control" matInput (focus)="fromDate.open()" [matDatepicker]="fromDate"
                                  formControlName="fromDate" placeholder="Valid From"
                                  [ngClass]="{'is-invalid': submitted && fd.fromDate.errors}">
                              <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
                              <mat-datepicker #fromDate></mat-datepicker>
                              <div *ngIf="submitted && fd.fromDate.errors">
                                <mat-error *ngIf="fd.fromDate.errors.required">Valid from is required</mat-error>
                              </div>
                          </mat-form-field>
                      </div>

                      <div class="form-group">
                          <mat-form-field>
                              <input class="form-control" matInput (focus)="toDate.open()" [matDatepicker]="toDate"
                                  formControlName="toDate" placeholder="Valid Till"
                                  [ngClass]="{'is-invalid': submitted && fd.toDate.errors}">
                              <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
                              <mat-datepicker #toDate></mat-datepicker>
                              <div *ngIf="submitted && fd.toDate.errors">
                                <mat-error *ngIf="fd.toDate.errors.required">Valid till is required</mat-error>
                              </div>
                          </mat-form-field>
                      </div>
                  </form>
                `,
  styleUrls: ['./date-range-operation-condition.component.css']
})
export class CardDateRangeOperationConditionComponent implements OnInit {

  operationalInformationConditionEmitter: EventEmitter<any> = new EventEmitter();
  @Input() dateRangeConditionForm: FormGroup;
  @Input() submitted: boolean;

  constructor() { }

  ngOnInit(): void {
  }


  // convenience getter for easy access to form fields
  get fd() { return this.dateRangeConditionForm.controls; }

}
