import { DataService } from './../../../components/app-send-gift/services/shared/data.service';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountServiceService, AlertService, FileUploadService } from 'src/app/services';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import { PrivilegeCardModel } from '../../models/privilege-card';

@Component({
  selector: 'app-new-privilege-card',
  templateUrl: './new-privilege-card.component.html',
  styleUrls: ['./new-privilege-card.component.css']
})
export class NewPrivilegeCardComponent implements OnInit {

  // onCreate event
  @Output() onOfferCreateEvent: EventEmitter<any> = new EventEmitter();
  // onEdit event;
  @Output() onOfferEditEvent: EventEmitter<any> = new EventEmitter();

  userInfo: any;
  submitted = false;

  privilegeCardForm: FormGroup;
  uploadedFiles: FormData;

  showOfferProviderSelection: boolean = true;
  inEditMode: boolean = false;

  fileName: string = '';
  imagePreviewPath: string = '../../../../assets/images/default.png';
  fileValidationError: string;

  privilegeCard:PrivilegeCardModel;

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<NewPrivilegeCardComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private alertService: AlertService,
    private accountService: AccountServiceService,
    private fileUploadService: FileUploadService,
    private dialog: MatDialog,
    private dataService: DataService
  ) {
    this.getLoggedInUser();
    this.setEditModeData(data);
  }

  async ngOnInit() {
    this.buildForm();
  }

  setEditModeData(data: any) {
    console.log("edit info : ", data);
    this.privilegeCard = data;
  }

  buildForm() {
    this.privilegeCardForm = this.formBuilder.group({
      cardId: this.privilegeCard?.cardId,
      cardName: [this.privilegeCard?.cardName, [Validators.required]],
      validFrom: this.privilegeCard?.validFrom,
      validTill: this.privilegeCard?.validTill,
      description: this.privilegeCard?.description,
      isActive: this.privilegeCard?.isActive,
    });
  }

  openFileBrowser(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById('offerPhotoId') as HTMLElement;
    element.click();
  }

  uploadLogo(files) {
    this.fileValidationError = null;
    let fileInfo = this.fileUploadService.imageFileUpload(files);
    if (fileInfo.validationError != null) {
      this.fileValidationError = fileInfo.validationError;
      return;
    }
    this.fileName = fileInfo.fileName;
    this.uploadedFiles = fileInfo.formData;

    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imagePreviewPath = event.target.result;
    }
    reader.readAsDataURL(files[0]);
  }

  onSubmit() {
    this.submitted = true;
    const formData = new FormData();
    

    // stop here if form is invalid
    if (this.privilegeCardForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }
    this.privilegeCard = new PrivilegeCardModel(this.privilegeCardForm.value);
    if (this.privilegeCard.cardId === undefined || this.privilegeCard.cardId === null || this.privilegeCard.cardId === "") { // create
      this.createOffer();
    }
    else if (this.privilegeCard.cardId != undefined && this.privilegeCard.cardId != null && this.privilegeCard.cardId != "") { // edit
      this.editOffer();
    }

    this.dialogRef.close();
  }

  createOffer() {
    let url = "api/Cards/create-update-card"
    this.privilegeCard.isActive =  this.privilegeCard.isActive == null? false: this.privilegeCard.isActive;
    this.dataService.post(url, this.privilegeCard)
      .subscribe((data: any) => {
        if(data.success){
          this.alertService.success(data.data);
          this.onOfferCreateEvent.next(data);
        }
        else{
          this.alertService.error("Privilege card creation failed, try again.");
        }
      },
        (error: any) => {
          console.log(error);
        });
  }


  editOffer() {
    let url = "api/Cards/create-update-card"
    this.privilegeCard.isActive = this.privilegeCard.isActive == null? false: this.privilegeCard.isActive;
    this.dataService.post(url, this.privilegeCard)
      .subscribe((data: any) => {
        if(data.success){
          this.alertService.success(data.data);
          this.onOfferEditEvent.next(this.privilegeCard);
        }
        else{
          this.alertService.error("Privilege card update failed, try again.");
        }
      },
        (error: any) => {
          console.log(error);
        });
  }

  
  getLoggedInUser() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
    console.log(this.userInfo);
  }

  

  close() {
    this.dialogRef.close();
  }

  // convenience getter for easy access to form fields
  get f() { return this.privilegeCardForm.controls; }

  setOfferPriceOrSlabValidators() {
    
  }

}
