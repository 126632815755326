import { Component, OnInit, Injector } from '@angular/core';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-gift-template-base',
  templateUrl: './gift-template-base.component.html',
  styleUrls: ['./gift-template-base.component.css']
})


export class GiftTemplateBaseComponent extends BaseComponent implements OnInit {

  dataLoaded: Promise<boolean>;

  userInfo: any;
  canCreate: boolean = true;

  filteredsiteAdmins: any[];
  siteAdmins: any[];

  siteAdminFilter: any = { name: '' };
  siteAdminFilterText: string;
  selectedsiteAdminIndex: number = -1;
  selectedsiteAdmin: any;
  selectedsiteAdminName: string;

  siteAdminSubMenu: any[] = [];
  selectedSubMenuIndex: number = 0;
  activeSubMenu: string = "Templates";



  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.siteAdminSubMenu.push({ "name": "Templates", "dataTarget": "giftTemplatesTab" });
    this.siteAdminSubMenu.push({ "name": "Assignments", "dataTarget": "giftTemplateAssignmentsTab" });

    if (!this.checkPermission(['CreateGiftTemplate'])) {//ViewGiftTemplateAssignments
      this.siteAdminSubMenu = this.siteAdminSubMenu.filter(x => x.name != "Templates");
      this.activeSubMenu = "Assignments";
    }
    this.dataLoaded = Promise.resolve(true);

  }

  onSubMenuChange(name, index) {
    if (this.selectedSubMenuIndex != index) {
      this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
      this.activeSubMenu = this.siteAdminSubMenu.filter(c => c.name === name)[0].name;
    }
  }

}
