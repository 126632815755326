import { Component, Input, OnInit } from '@angular/core';
import { MonthlyGiftVsRedemtionInfo } from 'src/app/models/DashboardYearlyResponse';

@Component({
  selector: 'app-gift-redeemed-chart',
  templateUrl: './gift-redeemed-chart.component.html',
  styleUrls: ['./gift-redeemed-chart.component.css']
})
export class GiftRedeemedChartComponent implements OnInit {
  public data = [];

  @Input() monthlyGiftVsRedemtionInfo: MonthlyGiftVsRedemtionInfo[]

  constructor() { }


  ngOnInit(): void {
    if(this.monthlyGiftVsRedemtionInfo != undefined && this.monthlyGiftVsRedemtionInfo != null){
      this.monthlyGiftVsRedemtionInfo.forEach(m=>{
        this.data.push({
          "name": m.monthName,
          "series": [
            {
              "name": "Redeemed",
              "value": m.totalReedemptionCount
            },
            {
              "name": "Gifts",
              "value": m.totalGiftCount
            }
          ]
        });
      });
    }
  }


  view: any[] = [500, 400];

  // options for the chart
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Months';
  showYAxisLabel = true;
  yAxisLabel = 'Count';
  timeline = true;
  tooltipDisabled = false;
  legendPosition = "below";
  legendTitle = "";

  colorScheme = {
    domain: ['#87CEFA', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  //pie
  showLabels = true;

  onSelect(event) {
    console.log(event);
  }

  
}
