import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, Injector } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { BaseComponent } from '../base/base.component';
import { CompanyService, AlertService } from 'src/app/services';
import { Promotion } from '../../models';
import { CreatePromotionComponent } from '../create-promotion/create-promotion.component';

@Component({
  selector: 'app-promotion-details',
  templateUrl: './promotion-details.component.html',
  styleUrls: ['./promotion-details.component.css']
})


export class PromotionDetailsComponent extends BaseComponent implements OnInit {

  @Input() promotionDetailInput: any;
  @Output() promotionDetailInputChange: EventEmitter<Promotion> = new EventEmitter<Promotion>();

  promotionDetail: Promotion;
  merchantLogoOnErr: String;
  merchantLogoTxtOnErr = false;

  detailsLoaded: Promise<boolean>;
  showEnableButton: boolean = false;

  constructor(public dialog: MatDialog, private injector: Injector) {
    super(injector);
    this.promotionDetail = new Promotion();
  }

  ngOnInit() {
    this.detailsLoaded = Promise.resolve(true);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['promotionDetailInput']) {
      setTimeout(() => {
        this.promotionDetail = this.promotionDetailInput;
        this.merchantLogoTxtOnErr = false;
        this.detailsLoaded = Promise.resolve(true);
      });
    }
  }

  editPromotionDetail(trendingMerchant: Promotion) {
    const editDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    editDialogConfig.disableClose = true;
    editDialogConfig.autoFocus = true;

    editDialogConfig.data = trendingMerchant
    editDialogConfig.panelClass = 'golp-dialog';

    const merchantEditDialog = this.dialog.open(CreatePromotionComponent, editDialogConfig);

    //const successFullEdit = merchantEditDialog.componentInstance.onPromotionEditEvent.subscribe((data) => {
    //  this.promotionDetail.orderNo = data.orderNo;
    //  this.promotionDetail.merchantId = data.merchantId;
    //  this.promotionDetail.isActive = data.isActive;
    //  this.promotionDetailInputChange.emit(this.promotionDetail);
    //});

    merchantEditDialog.afterClosed().subscribe(() => {
    });
  }


  errorHandler(event) {
    //event.target.src = this.merchantLogoOnErr;
    this.merchantLogoTxtOnErr = true;
  }

}

