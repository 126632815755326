import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyOutletService } from '../../services';

@Component({
  selector: 'app-show-outlets',
  templateUrl: './show-outlets.component.html',
  styleUrls: ['./show-outlets.component.css']
})
export class ShowOutletsComponent implements OnInit {

  merchantName: string;
  outlets: any[] = [];

  constructor(private dialogRef: MatDialogRef<ShowOutletsComponent>,
    private companyOutleService: CompanyOutletService,
    @Inject(MAT_DIALOG_DATA) data) {

    if (data !== null) {
      this.merchantName = data.merchantName;

      if (data.merchantId)
        this.getOutletsByCompany(data.merchantId);
    }
  }

  ngOnInit() {
  }

  getOutletsByCompany(merchantId: any) {
    this.companyOutleService.getOutletsByCompany(merchantId)
      .subscribe(data => {
        this.outlets = data;
      });
  }


  close() {
    this.dialogRef.close();
  }

}
