import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor(private http: HttpClient) { }

  individualGiftApi = 'api/individual-gift';  //print pdf controller

  saveAsHtmlIndividualGifts(input: any): Observable<string> {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post<string>(this.individualGiftApi +'/saveAs-Html', input, requestHeader);
    //return this.http.post<string>(this.giftTemplateBaseUrl + 'api/individual-gif/saveAs-Html', input, requestHeader);
  }

  printIndividualGifts(input: any) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post<any>(this.individualGiftApi + '/print', input, requestHeader);
  }
}
