import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-json-table-viewer',
  templateUrl: './json-table-viewer.component.html',
  styleUrls: ['./json-table-viewer.component.css']
})
export class JsonTableViewerComponent implements OnInit {

  headers: any;
  constructor() {}

  ngOnInit() {
    this.headers = Object.keys(this.items[0]);
  }
  @Input()
  items: any[];

  makeTitle(input){
    input = input.charAt(0).toUpperCase() + input.slice(1);;
    input = input.replace(/([A-Z])/g, ' $1').trim()
    return input;
  }

}
