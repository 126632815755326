import { Component, EventEmitter, Input, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { OperationalCondition } from '../../models/operational-condition';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CorporateOfferService } from '../../services/corporate-offer.service';
import { Validators } from '@angular/forms';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import { DataService } from 'src/app/components/app-send-gift/services/shared/data.service';

@Component({
  selector: 'app-create-offer-operational-condition',
  templateUrl: './create-offer-operational-condition.component.html',
  styleUrls: ['./create-offer-operational-condition.component.css']
})
export class CreateOfferOperationalConditionComponent implements OnInit {

  operationalInformationConditionEmitter: EventEmitter<any> = new EventEmitter();
  @Input() isSubmitted: boolean = false;
  @Input() operationalConditions: OperationalCondition[] = [];

  operationalConditionEnums: any[] = [];
  submitted = false;

  userInfo: any;

  operationalConditionForm: FormGroup;
  offerConditionId: any;
  offerTemplate: any;
  offerTemplateId: any;
  condition: any;

  // operational field
  conditionOperationType: any;
  message: any = '';
  isEnabled: boolean = false;
  // purchase amount
  showPurchaseAmountSection: boolean = false;
  purchaseAmount: number;
  isPurchaseAmountInvalid: boolean = false;

  // usages limit
  showUsagesLimitSection: boolean = false;
  usagesLimit: number;
  isUsagesLimitInvalid: boolean = false;

  // date time condition
  showDateRangeSection: boolean = false;
  dateTimeConditionForm: FormGroup;
  fromDate: any;
  toDate: any;

  // time range condition
  showTimeRangeSection: boolean = false;
  timeRangeConditionForm: FormGroup;
  startTime: any;
  endTime: any;

  // outlet specific condition
  showOutletSection: boolean = false;
  availableOutletsForm: FormGroup;
  availableOutlets: any = [];
  merchantOutlets: any;
  isOutletSelectionInvalid: boolean = false;

  isEditMode: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateOfferOperationalConditionComponent>,
    private offerService: CorporateOfferService,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) data) {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.getOperationalConditionEnums();
    if (data != null && data != undefined) {
      this.offerTemplateId = data.offerTemplateId;
      this.isEnabled = data?.condition?.isActive;
      this.condition = data.condition;
      this.offerConditionId = this.condition?.offerConditionId;
      this.editMode();
    }
  }

  ngOnInit(): void {
    this.getOperationalConditionEnums();
    //this.getMerchantOutlet();
  }

  getOperationalConditionEnums() {
    this.operationalConditionEnums = [
      { text: 'Date Range', value: 1 },
      { text: 'Available Outlets', value: 2 },
      { text: 'Minimum Purchase Amount', value: 3 },
      { text: 'Time Range', value: 4 },
      { text: 'Usage Limit', value: 5 }
    ];
  }

  editMode() {
    if (this.condition !== undefined) {
      this.getMerchantOutlet();
      this.isEditMode = true;
      this.conditionOperationType = this.condition.operationalCondition?.value;
      if (this.condition.operationalCondition?.value === 1) {
        // parse data and assign fromDate & toDate
        let jsonObject = JSON.parse(this.condition?.operationalConditionJsonObject);
        this.fromDate = jsonObject.startDate;
        this.toDate = jsonObject.endDate;
        this.showDateRangeSection = true;
        this.dateTimeConditionForm = this.formBuilder.group({
          fromDate: [this.fromDate, Validators.required],
          toDate: [this.toDate, Validators.required]
        });
      }
      else if (this.condition.operationalCondition?.value === 2) {
        this.message = this.condition.message;
        this.showOutletSection = true;
      }
      else if (this.condition.operationalCondition?.value === 3) {
        this.purchaseAmount = this.condition.operationalData;
        this.message = this.condition.message;
        this.showPurchaseAmountSection = true;
      }
      else if (this.condition.operationalCondition?.value === 4) {
        // parse data and assign fromDate & toDate
        let jsonObject = JSON.parse(this.condition?.operationalConditionJsonObject);
        this.startTime = jsonObject?.startTime?.slice(11, jsonObject.startTime.length);
        this.endTime = jsonObject?.endTime?.slice(11, jsonObject.endTime.length);;
        this.showTimeRangeSection = true;
        this.timeRangeConditionForm = this.formBuilder.group({
          startTime: [this.startTime, Validators.required],
          endTime: [this.endTime, Validators.required]
        });
      }
      else if (this.condition.operationalCondition?.value === 5) {
        this.usagesLimit = this.condition.operationalData;
        this.message = this.condition.message;
        this.showUsagesLimitSection = true;
      }
    }
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.showDateRangeSection && this.dateTimeConditionForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    if (this.showTimeRangeSection && this.timeRangeConditionForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    if (this.conditionOperationType === 2 && (this.availableOutlets?.length == 0)) {
      this.isOutletSelectionInvalid = true;
      return;
    }

    if (this.conditionOperationType === 3 && (this.purchaseAmount <= 0 || this.purchaseAmount == undefined)) {
      this.isPurchaseAmountInvalid = true;
      return;
    }

    if (this.conditionOperationType === 5 && (this.usagesLimit <= 0 || this.usagesLimit == undefined)) {
      this.isUsagesLimitInvalid = true;
      return;
    }

    if (this.offerConditionId === undefined) {
      this.createCondition(this.conditionOperationType);
    }
    else if (this.offerConditionId !== undefined) {
      this.editCondition(this.conditionOperationType);
    }
  }

  createCondition(conditionType: any) {

    let offerTemplateConditions = {};
    let conditionDetails = this.prepareConditionObject(conditionType);
    offerTemplateConditions = {
      'corporateOfferTemplateId': this.offerTemplateId,
      'isActive': this.isEnabled,
      'operationalConditions': [
        {
          'offerConditionOperation': conditionDetails.offerConditionOperation,
          'operationalConditionJsonObject': conditionDetails.operationalConditionJsonObject
        }
      ]
    };

    this.offerService.createOfferCondition(offerTemplateConditions)
      .subscribe((response: any) => {
        this.dialogRef.close();
        this.operationalInformationConditionEmitter.emit(this.offerTemplateId);
      });

  }

  editCondition(conditionType: any) {

    let conditionDetails = this.prepareConditionObject(conditionType);
    let offerTemplateConditions = {
      'corporateOfferId': this.offerTemplateId,
      'OfferConditionId': this.condition.offerConditionId,
      'conditionType': 1,
      'operationalCondition': conditionDetails.offerConditionOperation,
      'operationalConditionJsonObject': conditionDetails.operationalConditionJsonObject,
      'isActive': this.isEnabled,
    };

    this.offerService.editOfferCondition(offerTemplateConditions)
      .subscribe((response: any) => {
        this.dialogRef.close();
        this.operationalInformationConditionEmitter.emit(this.offerTemplateId);
      });
  }

  prepareConditionObject(conditionType: any) {
    let conditionDetails = { offerConditionOperation: '', operationalConditionJsonObject: '' };
    if (conditionType === 1) {
      let fromDate = new Date(this.dateTimeConditionForm.value.fromDate).toISOString().split('T')[0];
      let toDate = new Date(this.dateTimeConditionForm.value.toDate).toISOString().split('T')[0];
      conditionDetails.offerConditionOperation = 'DateRange';
      conditionDetails.operationalConditionJsonObject = `{"startDate": "${fromDate}", "endDate": "${toDate}", "message": "${this.message}"}`;
    }
    if (conditionType === 2) {
      let outletIds = Array.from(this.availableOutlets, (x: any) => x.qrCode).map(item => `"${item}"`).join(',');
      let outletNames = Array.from(this.availableOutlets, (x: any) => x.name).join();
      this.message = `This offer is only available from ${outletNames}`;

      conditionDetails.offerConditionOperation = 'OuletSpecific';
      conditionDetails.operationalConditionJsonObject = `{"outletIds": [${outletIds}], "outlets": "${outletNames}", "message": "${this.message}"}`;
    }
    else if (conditionType === 3) {
      conditionDetails.offerConditionOperation = 'MinPurchaseAmount';
      conditionDetails.operationalConditionJsonObject = "{'minPurchaseAmount':" + this.purchaseAmount + ", 'message':" + `'${this.message}'` + "}";
    }
    else if (conditionType === 4) {
      let todate = new Date().toISOString().split('T')[0]
      let startTime = `${todate} ${this.timeRangeConditionForm.value.startTime}`;
      let endTime = `${todate} ${this.timeRangeConditionForm.value.endTime}`;

      conditionDetails.offerConditionOperation = 'TimeRange';
      conditionDetails.operationalConditionJsonObject = `{"startTime": "${startTime}", "endTime": "${endTime}", "message": "${this.message}"}`;
    }
    else if (conditionType === 5) {
      conditionDetails.offerConditionOperation = 'UsagesLimit';
      conditionDetails.operationalConditionJsonObject = "{'maxUsagesLimit':" + this.usagesLimit + ", 'message':" + `'${this.message}'` + "}";
    }

    return conditionDetails;
  }

  onTypeChange(value: any) {
    this.showDateRangeSection = value === 1 ? true : false;
    this.showOutletSection = value === 2 ? true : false;
    this.showPurchaseAmountSection = value === 3 ? true : false;
    this.showTimeRangeSection = value === 4 ? true : false;
    this.showUsagesLimitSection = value === 5 ? true : false;

    if (this.showDateRangeSection) {
      this.dateTimeConditionForm = this.formBuilder.group({
        fromDate: [this.fromDate, Validators.required],
        toDate: [this.toDate, Validators.required]
      });
    }

    if (this.showTimeRangeSection) {
      this.timeRangeConditionForm = this.formBuilder.group({
        startTime: [this.startTime, Validators.required],
        endTime: [this.endTime, Validators.required]
      });
    }

    if (this.showOutletSection) {
      this.getMerchantOutlet();
      this.availableOutletsForm = this.formBuilder.group({
        availableOutlets: [this.availableOutlets, Validators.required]
      });
    }
  }

  getMerchantOutlet() {
    let url = `api/Outlets/company/${this.userInfo.companyId}/`;
    this.dataService.get(url).subscribe((data: any) => {
      if (data != null && data.length > 0) {
        this.merchantOutlets = data;
        if (this.isEditMode && this.showOutletSection) {
          let jsonObject: any = JSON.parse(this.condition?.operationalConditionJsonObject);
          let selectedOutlets = this.merchantOutlets?.filter(ot => jsonObject.outletIds.some(filter => ot.qrCode === filter));
          this.availableOutlets = Array.from(selectedOutlets);
          this.availableOutletsForm = this.formBuilder.group({
            availableOutlets: [this.availableOutlets, Validators.required]
          });
        }
      }
    });
  }

  changeSelection(matOption: any) {
    if (matOption.selected) {
      this.availableOutlets.push(matOption.value);
    }
    if (!matOption.selected) {
      this.availableOutlets = this.availableOutlets.filter((item: any) => item.id != matOption.value.id);
    }

    let outletNames = Array.from(this.availableOutlets, (x: any) => x.name).join();
    this.message = `This offer is only available from ${outletNames}`;
  }

  close() {
    this.dialogRef.close();
  }

  get f() { return this.operationalConditionForm.controls; }
  get fd() { return this.dateTimeConditionForm.controls; }
  get ft() { return this.timeRangeConditionForm.controls; }
}
