import { Component, OnInit, Injector } from '@angular/core';
import { Company } from 'src/app/models';
import { CompanyRole } from 'src/app/enums';
import { AccountServiceService, CommonService, AlertService, CompanyService } from 'src/app/services';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-golp-admin',
  templateUrl: './golp-admin.component.html',
  styleUrls: ['./golp-admin.component.css']
})


export class GolpAdminComponent extends BaseComponent implements OnInit {

  dataLoaded: Promise<boolean>;

  userInfo: any;
  canCreate: boolean = true;

  filteredsiteAdmins: any[];
  siteAdmins: any[];

  siteAdminFilter: any = { name: '' };
  siteAdminFilterText: string;
  selectedsiteAdminIndex: number = -1;
  selectedsiteAdmin: Company = null;
  selectedsiteAdminName: string;

  siteAdminSubMenu: any[] = [];
  selectedSubMenuIndex: number = 0;
  activeSubMenu: string = "Users";
  companyRoleType: CompanyRole = CompanyRole.None;

  constructor(private companyService: CompanyService, private dialog: MatDialog,
    private accountService: AccountServiceService,
    private commonService: CommonService,
    private alertService: AlertService,
    injector: Injector
  ) {
      super(injector);
  }

  ngOnInit() {
    this.getLoggedInUser();

    this.siteAdminSubMenu.push({ "name": "Users", "dataTarget": "usersTab" });
    this.siteAdminSubMenu.push({ "name": "Roles", "dataTarget": "rolesTab" });
    this.siteAdminSubMenu.push({ "name": "Permissions", "dataTarget": "permissionsTab" });
    //this.siteAdminSubMenu.push({ "name": "Reports", "dataTarget": "giftGenerationReportsTab" });
    this.siteAdminSubMenu.push({ "name": "Settings", "dataTarget": "settingsTab" });


    this.dataLoaded = Promise.resolve(true);

  }

  onSubMenuChange(name, index) {
    if (this.selectedSubMenuIndex != index) {
      this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
      this.activeSubMenu = this.siteAdminSubMenu.filter(c => c.name === name)[0].name;
    }
  }

  getLoggedInUser() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
    this.canCreate = true;
    // if(CompanyRole.siteAdmin === this.userInfo.companyRole && !this.userInfo.isSystemAdmin){
    //   this.canCreate = false;
    // }
  }

}
