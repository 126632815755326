import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CorporateOfferService } from 'src/app/corporate-offers/services/corporate-offer.service';
import { CompanyRole } from 'src/app/enums';
import { AccountServiceService } from 'src/app/services';

@Component({
  selector: 'app-offer-modal',
  templateUrl: './offer-modal.component.html',
  styleUrls: ['./offer-modal.component.css']
})
export class OfferModalComponent implements OnInit {

  constructor(private corporateOfferService: CorporateOfferService, private accountService: AccountServiceService, private dialogRef: MatDialogRef<OfferModalComponent>,
    @Inject(MAT_DIALOG_DATA) data) { }

  userInfo: any;
  offers:any;
  isMerchantLoggedIn:boolean;
  informationalCondition:any[] = [];
  operationalCondition:any[] = [];
  corporateOfferConditions:any;
  ngOnInit(): void {
    this.getLoggedInUser();
    this.getOffers();
  }

  getOffers() {
    this.corporateOfferService.getOfferTemplates()
      .subscribe(data => {
        this.offers = data;
        if (CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
          this.offers = this.offers.filter(c => c.merchantId === this.userInfo.companyId);
          this.offers.forEach(m=>{
            m["completed"] = false;
          })
        }
      });
  }

  

  getLoggedInUser() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
    if (CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
      this.isMerchantLoggedIn = true;
    }
  }

  setOpen(index) {
    if(this.informationalCondition.length > index){}
    else{
      this.loadConditions(index);
    }
  }

  loadConditions(index){
    let currentOffer = this.offers[index];
    this.corporateOfferService.getOfferConditionByOfferId(currentOffer.id)
      .subscribe(data => {
        this.corporateOfferConditions = data;
        if (this.corporateOfferConditions != null) {
          this.informationalCondition[index] = this.corporateOfferConditions.filter(c => c.conditionType == "Informational")[0];
          this.operationalCondition[index] = this.corporateOfferConditions.filter(c => c.conditionType == "Operational")


          console.log(this.operationalCondition[index]);
          this.operationalCondition[index].forEach(m=>console.log(m));
        }
      });
  }

  close(){
    this.dialogRef.close(); 
  }

  confirm(){
    let anyOfferSelected = this.offers.find(m=>m.completed);
    if(anyOfferSelected)
      this.dialogRef.close(this.offers);
  }

}
