export class RedemptionGiftDetails {
    giftId: string;
    giftReceiverPhone: string;
    giftCode: string;
    giftAmount: number;
    merchant: string;
    giftStatus: string;
    purchaseAmount: number;
    merchantInvoiceNumber: string;
    otpRedemptionEnabled?: boolean;

    constructor(init?: Partial<RedemptionGiftDetails>) {
        Object.assign(this, init);
    }
}
