import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AcceptRequestModel } from 'src/app/models';
import { GiftAvailService, AlertService, CommonService } from 'src/app/services';

@Component({
  selector: 'app-accept-avail-request',
  templateUrl: './accept-avail-request.component.html',
  styleUrls: ['./accept-avail-request.component.css']
})
export class AcceptAvailRequestComponent implements OnInit {

  onAcceptRequestEvent: EventEmitter<any> = new EventEmitter();

  requestDetails: AcceptRequestModel;
  acceptRequestForm: FormGroup
  submitted = false;
  minPurchaseAmount: number;
  
  constructor(private formBuilder: FormBuilder, private giftAvailService: GiftAvailService,
    private alertService: AlertService,
    private commonService: CommonService,
    private dialogRef: MatDialogRef<AcceptAvailRequestComponent>,
    @Inject(MAT_DIALOG_DATA) data) { 

      if(isNaN(data)){ 
        this.requestDetails = new AcceptRequestModel(data);
        this.minPurchaseAmount = this.requestDetails.minimumPurchaseAmount;
      }
      this.buildForm();
    }

  ngOnInit() {
  }

  buildForm(){
    this.acceptRequestForm = this.formBuilder.group({
      phone: [{value: this.requestDetails.consumerPhoneNumber, disabled: true}],
      discount: [{value: this.requestDetails.offerAmount, disabled: true}],
      purchaseAmount: [this.requestDetails.purchaseAmount, [Validators.required]],
    });
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.acceptRequestForm.invalid) {
       return;
    }

    let newData = new AcceptRequestModel(this.acceptRequestForm.value);
    if(newData.purchaseAmount < this.requestDetails.offerAmount){
      this.acceptRequestForm.controls['purchaseAmount'].setErrors({'incorrect': true});
      return;
    }
    if(this.requestDetails.minimumPurchaseAmount != null && newData.purchaseAmount < this.requestDetails.minimumPurchaseAmount){
      this.acceptRequestForm.controls['purchaseAmount'].setErrors({'minamount': true});
      return;
    }


    if(this.requestDetails != null){
      this.requestDetails.purchaseAmount = newData.purchaseAmount;

      this.giftAvailService.acceptAvailRequest(this.requestDetails)
          .subscribe((data) => {
            this.onAcceptRequestEvent.emit(this.requestDetails);
            this.commonService.backgroundJobEventEmitter({"jobType":"RequestAccept", "model":"Request successfully accepted"});
            this.alertService.success("Request successfully accepted");
          },
          (error) => {
            this.alertService.error(error.error);
          });
    }
    
    this.dialogRef.close();
  }


  close() {
    this.dialogRef.close();
  }

  // convenience getter for easy access to form fields
  get f() { return this.acceptRequestForm.controls; }

}
