import { Component, OnInit, Input, Injector } from '@angular/core';
import { AccountServiceService } from 'src/app/services/account-service.service';
import { Company } from 'src/app/models/company';
import { UserModel } from 'src/app/models/user-model';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { CreateUserComponent } from '../create-user/create-user.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AlertService } from 'src/app/services';
import { CompanyRole } from '../../enums';
import { PermissionCheckerService } from '../../services/permission-checker.service';
import { ResetPasswordDialogComponent } from '../reset-password-dialog/reset-password-dialog.component';
import { BaseComponent } from '../base/base.component';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css']
})
export class UsersComponent extends BaseComponent implements OnInit {

    @Input() providerDetails: Company;
    @Input() companyRoleType: CompanyRole = CompanyRole.None;

    users: any[];
    companyId: number;
    mssg: string = 'Aplectrum';
    hasResetPermission = false;
    constructor(
        private accountService: AccountServiceService,
        private dialog: MatDialog,
        private alertService: AlertService,
        //private permissionCheckerService: PermissionCheckerService,
        injector: Injector
    ) {
        super(injector);
        // if (this.permissionCheckerService.hasPermission(['SystemAdmin'])) {
        //     this.hasResetPermission = true;
        // }
    }

    ngOnInit() {
    }

    ngOnChanges() {
        this.getUsers();
    }

    getUsers() {
        var companyId = this.providerDetails ? this.providerDetails.id : null;
        if (this.companyRoleType == CompanyRole.Merchant) {
            this.accountService.getMerchantUsersByCompany(companyId)
                .subscribe(data => {
                    this.users = data;
                });
        }
        else {
            if (companyId) {
                this.accountService.getUsersByCompany(companyId)
                    .subscribe(data => {
                        this.users = data;
                    });
            }
            else {
                this.accountService.getHostUsersByCompany()
                    .subscribe(data => {
                        this.users = data;
                    });
            }
        }
    }

    addUser(company: any) {
        const userDialogConfig = new MatDialogConfig();

        // Setting different dialog configurations
        userDialogConfig.disableClose = true;
        userDialogConfig.autoFocus = true;
        userDialogConfig.data = company != null ? company.id : null;
        userDialogConfig.panelClass = 'golp-dialog';

        const userCreateDialog = this.dialog.open(CreateUserComponent, userDialogConfig);

        userCreateDialog.componentInstance.companyRoleType = this.companyRoleType;

        const successfullCreate = userCreateDialog.componentInstance.onUserCreateEvent.subscribe((data) => {
            this.getUsers();
        });

        userCreateDialog.afterClosed().subscribe(() => {
        });

    }

    editUser(userModel: UserModel) {
        const editDialogConfig = new MatDialogConfig();
        userModel.companyRoleType = this.companyRoleType;

        // Setting different dialog configurations
        editDialogConfig.disableClose = true;
        editDialogConfig.autoFocus = true;
        editDialogConfig.data = userModel;
        editDialogConfig.panelClass = 'golp-dialog';

        const userEditDialog = this.dialog.open(CreateUserComponent, editDialogConfig);

        const successfullEdit = userEditDialog.componentInstance.onUserEditEvent.subscribe((data) => {
            this.getUsers();
        });

        userEditDialog.afterClosed().subscribe(() => {
        });
    }

    onDeleteUser(user: UserModel): void {
        const confirmationDialogConfig = new MatDialogConfig();

        // Setting different dialog configurations
        confirmationDialogConfig.data = "Are you sure to delete the user " + user.email;
        confirmationDialogConfig.panelClass = 'golp-dialog';
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

        confirmationDialog.afterClosed().subscribe((result) => {
            if (result != undefined) {
                this.deleteUser(user);
            }
        });
    }

    deleteUser(user: UserModel) {
        this.accountService.deleteUser(user.id).subscribe((data) => {
            this.alertService.success('User deleted successfully');
            this.users = this.users.filter(function (item) {
                if (item.id !== user.id) {
                    return true;
                } else {
                    return false;
                }
            });
        },
            (error) => {
                //this.alertService.error('Internal server error happen');
                console.log(error);
            });
    }

    onResetPassword(user: UserModel) {
        const resetPasswordDialogConfig = new MatDialogConfig();
        resetPasswordDialogConfig.data = user.email;
        resetPasswordDialogConfig.panelClass = 'golp-dialog';
        const resetPasswordDialog = this.dialog.open(ResetPasswordDialogComponent, resetPasswordDialogConfig);
        resetPasswordDialog.afterClosed().subscribe((result) => {
        });
    }

}
