import { DataService } from './../../../../components/app-send-gift/services/shared/data.service';
import { Component, OnInit, EventEmitter, NgZone, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OfferAssignment } from 'src/app/models';
import { AssignmentResponse } from 'src/app/models/assignmentresponse';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, CompanyService } from 'src/app/services';
import { ValidationUtility } from 'src/app/utility/validation.utility';

@Component({
  selector: 'app-new-facility',
  templateUrl: './new-facility.component.html',
  styleUrls: ['./new-facility.component.css']
})
export class NewFacilityComponent implements OnInit {

  // onCreate event
  onaddNewFacilityEvent: EventEmitter<number> = new EventEmitter();

  inputData: any;

  addFacilityForm: FormGroup;
  submitted = false;
  offerAssignmentData: FormData;
  assignmentFiles: FormData;
  offerAssignment: OfferAssignment;
  assignmentResponse: AssignmentResponse;

  fileValidationError: string;
  fileName: string = '';

  giftDeliveryMethods: any;
  showDeliveryAddressInput = false;
  showDeliveryMethodSelection = false;

  facility: any;

  public merchants: any[];
  public filteredMerchant: any[];

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<NewFacilityComponent>,
    private dataService: DataService,
    private companyService: CompanyService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) data) {

    if (data !== null) {
      this.inputData = data.privilegeCard;
      this.facility = data.facility;
    }
    this.addFacilityForm = this.formBuilder.group({
      merchantIds: [this.facility == null ? '' : this.facility?.merchantId, [Validators.required]],
      isEnabled: [this.facility == null ? '' : this.facility?.isActive, [Validators.nullValidator]],
      facilityType: [this.facility == null ? 'Discount' : this.facility?.facilityType, [Validators.required]],
      valueType: [this.facility == null ? 'Fixed Amount' : this.facility?.valueType, [Validators.required]],
      value: [this.facility == null ? 0 : this.facility?.value, [Validators.required]],
    });

  }

  ngOnInit() {
    this.getMerchants();
  }

  openFileBrowser(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById('offerAssignmentFileId') as HTMLElement;
    element.click();
  }

  uploadLogo(files) {
    this.fileValidationError = null;
    if (files.length === 0)
      return;

    const formData = new FormData();

    if (files[0].size > (1024 * 1024)) {
      this.fileValidationError = "Maximum file size should be 1 MB";
      return;
    }

    if (files[0].type != 'application/vnd.ms-excel') {
      this.fileValidationError = "Only CSV file allowed";
      return;
    }

    for (let file of files) {
      formData.append(file.name, file);
      this.fileName += ' ' + file.name;
    }
    this.assignmentFiles = formData;
  }

  getMerchants() {
    this.companyService.getMerchants()
      .subscribe(data => {
        this.merchants = data;
        this.filteredMerchant = this.merchants.filter(m => m.id != null);
        this.filteredMerchant.forEach(m => {
          m["show"] = true;
        });
      });
  }

  onSubmit() {
    this.submitted = true;
    let url = "";
    // stop here if form is invalid
    if (this.addFacilityForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    let formObj = this.addFacilityForm.getRawValue();
    let status = formObj.isEnabled == null ? false : formObj.isEnabled;
    let payload = {
      "facilityType": formObj.facilityType,
      "golpCardId": this.inputData.cardId,
      "valueType": formObj.valueType,
      "IsActive": status,
      "value": Number(formObj.value)
    };
    if (this.facility == null) {
      url = "api/cards/add-facility";
      payload["merchantIds"] = formObj.merchantIds;
    }
    else {
      url = "api/cards/update-facility";
      payload["facilityId"] = this.facility.id;
      payload["merchantId"] = this.facility.merchantId;
    }
    this.dataService.post(url, payload)
      .subscribe((response: any) => {
        console.log("response here : ", response);
        if (response.success) {
          this.onaddNewFacilityEvent.emit(this.inputData.cardId);
          if (this.facility == null)
            this.alertService.success('Offer added successfully.');
          else this.alertService.success('Offer updated successfully.');
          this.dialogRef.close();
        }
        if (!response.success) {
          this.alertService.error("Failed to add Offer, please check if you have duplicate merchant under this selected card");
        }
      },
        () => {
        });
  }


  onKey(value) {
    this.filteredMerchant.forEach(m => {
      if (m.name.toLowerCase().includes(value.toLowerCase())) {
        m["show"] = true;
      }
      else {
        m["show"] = false;
      }
    });
  }


  close() {
    this.dialogRef.close();
  }

  // convenience getter for easy access to form fields
  get f() { return this.addFacilityForm.controls; }
}
