import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-gift-history-item',
  templateUrl: './gift-history-item.component.html',
  styleUrls: ['./gift-history-item.component.css']
})
export class GiftHistoryItemComponent implements OnInit {

  @Input() giftItem: any;
  @Output() selectedGiftItem = new EventEmitter<any>()
  item: any;
  apiBaseUri: any;

  constructor() {
    console.log("current gift..........: "+ this.giftItem);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['giftItem']) {
      this.item = this.giftItem;
    }
  }

  itemClicked() {
    this.selectedGiftItem.next(this.item);
  }

}
