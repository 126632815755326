import { DataService } from './../../../components/app-send-gift/services/shared/data.service';
import { StockStatusComponent } from './../../../components/stock-status/stock-status.component';
import { CardInformationConditionComponent } from './card-information-condition/card-information-condition.component';
import { CreateCardOperationalConditionComponent } from './create-card-operational-condition/create-card-operational-condition.component';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/components/base/base.component';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { CompanyRole } from 'src/app/enums';
import { OfferAvailMethod } from 'src/app/models/offer-avail-method';
import { CompanyService, AlertService, AccountServiceService } from 'src/app/services';
import { SharedService } from 'src/app/services/shared.service';
import { CorporateOfferService } from 'src/app/corporate-offers/services/corporate-offer.service';
import { CorporateOffer } from 'src/app/corporate-offers/models/corporate-offer';

@Component({
  selector: 'app-privilege-card-conditions',
  templateUrl: './privilege-card-conditions.component.html',
  styleUrls: ['./privilege-card-conditions.component.css']
})
export class PrivilegeCardConditionsComponent extends BaseComponent implements OnInit {

  @Input() privilegeCardInput: any;
  @Input() facilityInput: any;

  corporateOfferConditions: any[] = [];
  informationalCondition: any;
  operationalCondition: any[];

  canOperate:boolean = false;

  corporateOfferTypes: { text: string; value: number; }[];
  constructor(private dataService:DataService, private accountService: AccountServiceService, private dialog: MatDialog,
    private sharedService: SharedService, private companyService: CompanyService,
    private alertService: AlertService,
    injector: Injector
  ) {
    super(injector);
    this.currentUserCanOperate();
  }

  ngOnInit() {
    //this.getConditionsByCard("Terms");
  }

  ngOnChanges() {
    this.getConditionsByCard("Terms");
    this.getConditionsByCard("Conditions");
  }

  getConditionsByCard(entryType:any) {
    let url=`api/cards/card-terms-conditions/${this.privilegeCardInput.cardId}?entryType=${entryType}&merchantId=${this.facilityInput.merchantId}`;

    this.dataService.get(url)
      .subscribe((data:any) => {
        if(data.success){
          if(entryType == "Terms")
            this.informationalCondition = data.data;
          else this.operationalCondition = data.data;
        }
        else{
          this.alertService.error("Something went wrong!");
        }
      });
  }

  currentUserCanOperate(){
    this.canOperate = this.accountService.getLoggedInUserInfo().isSystemAdmin;
  }


  addOperationalCondition() {
    const corporateOfferDialogConfig = new MatDialogConfig();
    // CorporateOffer different dialog configurations
    corporateOfferDialogConfig.disableClose = true;
    corporateOfferDialogConfig.autoFocus = true;
    corporateOfferDialogConfig.data = { cardConditions: this.corporateOfferConditions, cardTemplateId: this.privilegeCardInput.cardId, facilityInput: this.facilityInput };
    corporateOfferDialogConfig.panelClass = 'golp-dialog';


    const privilegeCardCreateConditonDialog = this.dialog.open(CreateCardOperationalConditionComponent, corporateOfferDialogConfig);

    const successfullCreate = privilegeCardCreateConditonDialog.componentInstance.operationalInformationConditionEmitter.subscribe((data) => {
      //parse data

      this.getConditionsByCard("Conditions");
    });

    privilegeCardCreateConditonDialog.afterClosed().subscribe(() => {
    });
  }

  editOperationalCondition(condition: any) {
    const corporateOfferDialogConfig = new MatDialogConfig();
    // CorporateOffer different dialog configurations
    corporateOfferDialogConfig.disableClose = true;
    corporateOfferDialogConfig.autoFocus = true;
    corporateOfferDialogConfig.data = {
      cardConditions: this.corporateOfferConditions,
      offerTemplateId: this.privilegeCardInput.cardId,
      condition: condition,
      facilityInput: this.facilityInput 
    };
    corporateOfferDialogConfig.panelClass = 'golp-dialog';

    const privilegeCardCreateConditonDialog = this.dialog.open(CreateCardOperationalConditionComponent, corporateOfferDialogConfig);

    const successfullCreate = privilegeCardCreateConditonDialog.componentInstance.operationalInformationConditionEmitter.subscribe((data) => {
      //parse list
    this.getConditionsByCard("Conditions");

    });

    privilegeCardCreateConditonDialog.afterClosed().subscribe(() => {
    });
  }

  onDeleteOfferCondition(item: any): void {
    const confirmationDialogConfig = new MatDialogConfig();

    // CorporateOffer different dialog configurations
    //confirmationDialogConfig.data = "Are you sure to delete this corporateOffer " + model.key;
    confirmationDialogConfig.data = "Are you sure to delete this condition?";
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result: any) => {
      if (result === "yes") {
        this.deleteOfferCondition(item);
      }
    });
  }

  deleteOfferCondition(item: any) {
    let payload = {
      "cardId": item.golpCardId,
      "conditionId": item.id
    }

    let url = "api/cards/delete-condition"
    this.dataService.post(url, payload).subscribe((data : any) => {
      if(data.success){
        this.alertService.success('Condition deleted successfully');
      //parse list
        this.getConditionsByCard("Conditions");
      }
      else {
        this.alertService.error('Failed to delete card condition.');
      }
      
    },
      (error) => {
        console.log(error);
      });
  }


  
  addCorporateOfferCondition() {
    const privelegeCardDialogConfig = new MatDialogConfig();
    // CorporateOffer different dialog configurations
    privelegeCardDialogConfig.disableClose = true;
    privelegeCardDialogConfig.autoFocus = true;
    privelegeCardDialogConfig.data = { informationalCondition: this.informationalCondition, privilegeCardId: this.privilegeCardInput.cardId, merchantId: this.facilityInput.merchantId};
    privelegeCardDialogConfig.panelClass = 'golp-dialog';


    const privilegeCardCreateConditonDialog = this.dialog.open(CardInformationConditionComponent, privelegeCardDialogConfig);

    const successfullCreate = privilegeCardCreateConditonDialog.componentInstance.onCorporateOfferConditionCreateEvent.subscribe((data) => {
      //parse list here
      this.getConditionsByCard("Terms");
    });

    privilegeCardCreateConditonDialog.afterClosed().subscribe(() => {
    });

  }

  deleteComponent(item){
    //delete component from here
  }

  editCorporateOfferCondition(model: CorporateOffer) {

    const editDialogConfig = new MatDialogConfig();
    // CorporateOffer different dialog configurations
    editDialogConfig.disableClose = true;
    editDialogConfig.autoFocus = true;
    editDialogConfig.data = model;
    editDialogConfig.panelClass = 'golp-dialog';

    const corporateOfferEditDialog = this.dialog.open(CardInformationConditionComponent, editDialogConfig);

    const successfullEdit = corporateOfferEditDialog.componentInstance.onCorporateOfferConditionEditEvent.subscribe((data) => {
      //parse list
    });

    corporateOfferEditDialog.afterClosed().subscribe(() => {
    });
  }


}
