import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, Injector } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { BaseComponent } from '../base/base.component';
import { CompanyService, AlertService, LoyaltyMerchantService } from 'src/app/services';
import { LoyaltyMerchant } from '../../models';
import { CreateLoyaltyMerchantComponent } from '../create-loyalty-merchant/create-loyalty-merchant.component';

@Component({
  selector: 'app-loyalty-merchants-details',
  templateUrl: './loyalty-merchants-details.component.html',
  styleUrls: ['./loyalty-merchants-details.component.css']
})

export class LoyaltyMerchantsDetailsComponent extends BaseComponent implements OnInit {

  @Input() loyaltyMerchantDetailsInput: LoyaltyMerchant;
  @Output() loyaltyMerchantDetailsInputChange: EventEmitter<LoyaltyMerchant> = new EventEmitter<LoyaltyMerchant>();

  loyaltyMerchantDetails: LoyaltyMerchant
  loyaltyMerchantLogoOnErr: String
  loyaltyMerchantLogoTxtOnErr = false;

  detailsLoaded: Promise<boolean>;
  showEnableButton: boolean = false;

  constructor(public dialog: MatDialog, injector: Injector, private alertService: AlertService,
    private loyaltyMerchantService: LoyaltyMerchantService) {
    super(injector);
  }

  ngOnInit() {
    this.loyaltyMerchantDetails = new LoyaltyMerchant();
    this.detailsLoaded = Promise.resolve(true);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['loyaltyMerchantDetailsInput']) {
      setTimeout(() => {
        this.loyaltyMerchantDetails = this.loyaltyMerchantDetailsInput;
        this.loyaltyMerchantLogoTxtOnErr = false;
        this.visibleEnableDisableButton();
        this.detailsLoaded = Promise.resolve(true);
      });
    }
  }

  editLoyaltyMerchant(loyaltyMerchant: LoyaltyMerchant) {
    const editDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    editDialogConfig.disableClose = true;
    editDialogConfig.autoFocus = true;

    editDialogConfig.data = loyaltyMerchant
    editDialogConfig.panelClass = 'golp-dialog';

    const loyaltyMerchantEditDialog = this.dialog.open(CreateLoyaltyMerchantComponent, editDialogConfig);

    const successFullEdit = loyaltyMerchantEditDialog.componentInstance.onLoyaltyMerchantEditEvent.subscribe((data) => {
      this.loyaltyMerchantDetails.id = data.id;
      this.loyaltyMerchantDetails.isActive = data.isActive;
      this.loyaltyMerchantDetails.name = data.name;
      this.loyaltyMerchantDetails.imageFullPath = data.imageFullPath;
      this.loyaltyMerchantDetails.imageName = data.imageName;
      this.loyaltyMerchantDetails.shortName = data.shortName;
      this.loyaltyMerchantDetails.phone = data.phone;
      this.loyaltyMerchantDetails.email = data.email;
      this.loyaltyMerchantDetails.address = data.address;
      this.loyaltyMerchantDetailsInputChange.emit(this.loyaltyMerchantDetails);
    });

    loyaltyMerchantEditDialog.afterClosed().subscribe(() => {
    });
  }

  visibleEnableDisableButton() {
    this.showEnableButton = this.loyaltyMerchantDetails.isActive ? false : true;
  }

  enableDisableLoyaltyMerchant(loyaltyMerchant: LoyaltyMerchant, enable: boolean) {
    loyaltyMerchant.isActive = enable;
    this.loyaltyMerchantService.editLoyaltyMerchant(loyaltyMerchant)
      .subscribe((data) => {
        this.visibleEnableDisableButton();
        let message = enable ? 'Loyalty Merchant enabled successfully' : 'Loyalty Merchant disabled successfully';
        this.alertService.success(message);
      });
  }

  errorHandler(event) {
    //event.target.src = this.loyaltyMerchantLogoOnErr;
    this.loyaltyMerchantLogoTxtOnErr = true;
  }

}

