import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { Offer } from 'src/app/models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, OfferService } from 'src/app/services';

@Component({
  selector: 'app-offer-rejection',
  templateUrl: './offer-rejection.component.html',
  styleUrls: ['./offer-rejection.component.css']
})
export class OfferRejectionComponent implements OnInit {

  // onCreate event
  onOfferRejectionEvent: EventEmitter<Offer> = new EventEmitter();
  
  offerRejectionForm: FormGroup;
  submitted = false;
  offerRejectionData: FormData;

  inputData: Offer;

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<OfferRejectionComponent>,
    private offerService: OfferService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) data) { 

      if(data !== null){
        this.inputData = data;
        console.log(data);
      }

      this.offerRejectionForm = this.formBuilder.group({
        rejectionReason: ['', Validators.required]
      });

    }

  ngOnInit() {
  }

   // convenience getter for easy access to form fields
   get f() { return this.offerRejectionForm.controls; }

  onSubmit(){
    this.submitted = true;

    // stop here if form is invalid
    if (this.offerRejectionForm.invalid) {
       return;
    }
    
    this.inputData.rejectionReason = this.offerRejectionForm.controls["rejectionReason"].value;
    this.inputData.isRejected = true;

    this.offerService.rejectOffer(this.inputData)
        .subscribe((data: any) => {
          this.inputData.offerStatus = 'Rejected';
          this.onOfferRejectionEvent.emit(this.inputData);
          this.alertService.success("Gift rejected");
          console.log(data);
        },
        (error: any) => {
          console.log(error);
          //this.alertService.error("Internal server error happen");
        });


    this.dialogRef.close();
  }

  close(){
    this.dialogRef.close();
  }

}
