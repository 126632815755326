import { DataService } from 'src/app/components/app-send-gift/services/shared/data.service';
import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AssignmentResponse } from 'src/app/models/assignmentresponse';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import * as moment from 'moment'; import { AlertService, AccountServiceService, FileUploadService } from 'src/app/services';

import * as XLSX from 'xlsx';

@Component({
  selector: 'app-new-card-assignment',
  templateUrl: './new-card-assignment.component.html',
  styleUrls: ['./new-card-assignment.component.css']
})
export class NewCardAssignmentComponent implements OnInit {

  // onCreate event
  onAddAssignmentEvent: EventEmitter<any> = new EventEmitter();

  inputData: any;

  cardAssignmentForm: FormGroup;
  submitted = false;
  cardAssignmentData: FormData;
  assignmentFiles: FormData;
  cardAssignment: any;
  assignmentResponse: AssignmentResponse;

  fileValidationError: string;
  fileName: string = '';

  giftDeliveryMethods: any;
  showDeliveryAddressInput = false;
  showDeliveryMethodSelection = false;
  isActive: boolean = false;

  // file uploads properties
  files: any;

  // date time picker
  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 30;
  public stepSecond = 1;

  public select2MerchantInfo: any;
  public merchantList: any;
  public showIssuer: boolean = false;
  public showCommaSeparateValue: boolean = true;
  public xlData: any[] = [];

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<NewCardAssignmentComponent>,
    private dataService: DataService,
    private alertService: AlertService,
    private fileUploadService: FileUploadService,
    @Inject(MAT_DIALOG_DATA) data, private accountService: AccountServiceService) {

    if (accountService.getLoggedInUserInfo().isSystemAdmin)
      this.showIssuer = true;
    if (data !== null) {
      this.inputData = data;
      this.select2MerchantInfo = data.select2Merchant;
    }
    this.cardAssignmentForm = this.formBuilder.group({
      phoneNumbers: '',
      deliveryAddress: '',
      effectiveFrom: [this.inputData.validFrom, Validators.required],
      effectiveFromTime: [''],
      expireDate: [this.inputData.validTill, Validators.required],
      cardSlogan: '',
      merchantId: [-1, superAdmin(accountService.getLoggedInUserInfo().isSystemAdmin)],
      isActive: [this.isActive]
    });

  }

  ngOnInit(): void { }

  onFileChange(evt: any) {
    /* wire up file reader */
    this.showCommaSeparateValue = false;
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) {
      this.showCommaSeparateValue = true;
      throw new Error('Cannot use multiple files');
    }
    this.onUploadDataFile(target.files);
  }

  onUploadDataFile(files) {
    let fileInfo = this.fileUploadService.anyFileUpload(files, "dataFile");
    if (fileInfo.validationError != null) {
      alert(fileInfo.validationError);
      return;
    }
    this.fileName = fileInfo.fileName;
    this.files = files;
    this.getJsonFromFile(files[0]);
  }

  getPhoneList(phoneList: string) {
    let phoneObjects = [];
    if (this.showCommaSeparateValue) {
      var phones = phoneList.split(',');
      phones.forEach(m => {
        phoneObjects.push({ "id": null, "phoneNumber": m, "email": "" });
      });
    }
    else {
      this.xlData.forEach(p => {
        if (p.sheet1 != undefined && p.sheet1 != null && p.sheet1.length > 0) {
          p.sheet1.forEach(d => {
            phoneObjects.push({ "id": null, "phoneNumber": d.PhoneNumber, "email": d.Email, "firstName": d.FirstName, "lastName": d.LastName, "gender": d.Gender, "designation": d.Designation, "stakeHolderType": d.StakeHolderType, "dateOfBirth": this.excelDateToJSDate(d.DateOfBirth) });
          });
        }
      });
    }
    return phoneObjects;
  }

  excelDateToJSDate(excelDate) {
    if (excelDate == "" || excelDate == null || excelDate == undefined) return;
    var date = new Date(Math.round((excelDate - (25567 + 1)) * 86400 * 1000));
    var converted_date = date.toISOString().split('T')[0];
    return converted_date;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.cardAssignmentForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    this.cardAssignmentData = new FormData();
    if (this.assignmentFiles != null) {
      this.cardAssignmentData = this.assignmentFiles;
    }

    var fromDate = moment(this.cardAssignmentForm.value.effectiveFrom).format("YYYY/MM/DD  HH:mm");
    const toDate = moment(this.cardAssignmentForm.value.expireDate).format("YYYY/MM/DD  HH:mm");

    //
    let cardIssuer = 0;
    let userInfo = this.accountService.getLoggedInUserInfo();
    if (userInfo.isSystemAdmin) {
      cardIssuer = this.cardAssignmentForm.value.merchantId;
    }
    else {
      cardIssuer = userInfo.companyId;
    }

    let payload = {
      "golpCardId": this.inputData.cardId,
      "cardIssuerId": cardIssuer,
      "beneficiaries": this.getPhoneList(this.cardAssignmentForm.value.phoneNumbers),
      "validFrom": fromDate,
      "validTill": toDate,
      "cardSlogan": this.cardAssignmentForm.value.cardSlogan,
      "isActive": this.cardAssignmentForm.value.isActive
    }
    let url = `api/cards/add-card-beneficiary`
    this.dataService.post(url, payload)
      .subscribe((response: any) => {
        if (response.success) {
          this.onAddAssignmentEvent.emit(this.inputData.cardId);
          this.alertService.success('Card sent successfully.');
          this.dialogRef.close();
        }
        if (!response.success) {
          this.alertService.error(response.data);
        }
      },
        () => {
        });
  }

  close() {
    this.dialogRef.close();
  }

  // convenience getter for easy access to form fields
  get f() { return this.cardAssignmentForm.controls; }


  getJsonFromFile(file: any) {
    let exceltoJson = {};
    let headerJson = {};
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(file);
    exceltoJson['filename'] = file.name;
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
      for (var i = 0; i < wb.SheetNames.length; ++i) {
        const wsname: string = wb.SheetNames[i];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
        exceltoJson[`sheet${i + 1}`] = data;
        const headers = this.get_header_row(ws);
        headerJson[`header${i + 1}`] = headers;
        //  console.log("json",headers)
      }
      exceltoJson['headers'] = headerJson;
      console.log(exceltoJson);
      this.xlData.push(exceltoJson);
      return exceltoJson;

    }
  }

  get_header_row(sheet) {
    var headers = [];
    var range = XLSX.utils.decode_range(sheet['!ref']);
    var C, R = range.s.r; /* start in the first row */
    /* walk every column in the range */
    for (C = range.s.c; C <= range.e.c; ++C) {
      var cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })] /* find the cell in the first row */
      // console.log("cell",cell)
      var hdr = "UNKNOWN " + C; // <-- replace with your desired default 
      if (cell && cell.t) {
        hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
    }
    return headers;
  }

}

export function superAdmin(isSuperAdmin: boolean) {
  if (!isSuperAdmin) return null;

  return (control: AbstractControl): { [key: string]: any } | null => {
    const input = Number(control.value);
    return input < 0 ? { issuerError: { value: control.value } } : null;
  };
}
