import { Component, OnInit } from '@angular/core';
import { DashboardMonthlyResponse, Summary } from 'src/app/models/DashboardMonthlyResponse';
import { DashboardYearlyResponse, MonthlyGiftsAndSalesInfo, MonthlyGiftVsRedemtionInfo } from 'src/app/models/DashboardYearlyResponse';
import { NotificationService } from 'src/app/services/notification.service';
import { DataService } from '../app-send-gift/services/shared/data.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  notificationItems: any[];
  userInfo:any;
  dashboardMonthlySummary: Summary;
  dashboardMonthlyResponse: DashboardMonthlyResponse;
  dashboardYearlyResponse: DashboardYearlyResponse;
  monthlyGiftsAndSalesInfo : MonthlyGiftsAndSalesInfo[]
  monthlyGiftVsRedemtionInfo :MonthlyGiftVsRedemtionInfo[]


  averageGiftAmount:number = 0.0;
  averageSalesAmount:number = 0.0;
  amountSold: number = 0.0;
  redeemedAmount: number = 0.0;
  
  //string
  averageGiftAmountString:string;
  averageSalesAmountString:string;
  amountSoldString:string;
  redeemedAmountString:string;


  diffAmountPercentage: number = 0.0;
  diffRedeemedAmountPercentage: number = 0.0;

  constructor(private notificationService: NotificationService, private dataService: DataService) { }

  ngOnInit() {
    this.setUserInfo();
    this.getNotification();
    this.getDashboardInformation();
    this.getDashboardYearlyInformation();
  }

  setUserInfo(){
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
  }
  getNotification(){
    this.notificationService.getNotifications(1,10)
      .subscribe(data => {
        this.notificationItems = data;
      });
  }

  getDashboardMonthlyInformation() {
    let url = `api/GiftCard/dashboard-gift-card-reports/merchant/${this.userInfo.companyId}?`;
    if(this.userInfo.outletQrCode != null)
      url += `outletId=${this.userInfo.outletQrCode}`
    this.dataService.get(url).subscribe((data: DashboardMonthlyResponse) => {
      if(data != null && data.success){
        this.dashboardMonthlyResponse = data;
        this.dashboardMonthlySummary = data.summary

        //dashboard amount data
        this.amountSold = this.dashboardMonthlySummary.amountSold;
        this.redeemedAmount = this.dashboardMonthlySummary.redeemedAmount;
        this.averageGiftAmount = (this.dashboardMonthlySummary.averageGiftAmount != undefined && this.dashboardMonthlySummary.averageGiftAmount != null)?Math.floor(this.dashboardMonthlySummary?.averageGiftAmount): this.averageGiftAmount;
        this.averageSalesAmount = (this.dashboardMonthlySummary.averageSalesAmount != undefined && this.dashboardMonthlySummary.averageSalesAmount != null)?Math.floor(this.dashboardMonthlySummary?.averageSalesAmount): this.averageSalesAmount;

        this.amountSoldString = this.getCommaSeparatedString(this.amountSold);
        this.redeemedAmountString = this.getCommaSeparatedString(this.redeemedAmount.toString());
        this.averageGiftAmountString = this.getCommaSeparatedString(this.averageGiftAmount.toString());
        this.averageSalesAmountString = this.getCommaSeparatedString(this.averageSalesAmount.toString());

        this.diffAmountPercentage = (this.dashboardMonthlySummary.diffAmountPercentage != undefined && this.dashboardMonthlySummary.diffAmountPercentage != null)?Math.floor(this.dashboardMonthlySummary?.diffAmountPercentage): this.diffAmountPercentage;
        this.diffRedeemedAmountPercentage = (this.dashboardMonthlySummary.diffRedeemedAmountPercentage != undefined && this.dashboardMonthlySummary.diffRedeemedAmountPercentage != null)?Math.floor(this.dashboardMonthlySummary?.diffRedeemedAmountPercentage): this.diffRedeemedAmountPercentage;
      }
    });
  }

  //dashboard-all-gift-card-reports

  getDashboardInformation() {
    let url = `api/GiftCard/dashboard-all-gift-card-reports/merchant/${this.userInfo.companyId}?`;
    if(this.userInfo.outletQrCode != null)
      url += `outletId=${this.userInfo.outletQrCode}`
    this.dataService.get(url).subscribe((data: DashboardMonthlyResponse) => {
      if(data != null && data.success){
        this.dashboardMonthlyResponse = data;
        this.dashboardMonthlySummary = data.summary

        //dashboard amount data
        this.amountSold = this.dashboardMonthlySummary.amountSold;
        this.redeemedAmount = this.dashboardMonthlySummary.redeemedAmount;
        this.averageGiftAmount = (this.dashboardMonthlySummary.averageGiftAmount != undefined && this.dashboardMonthlySummary.averageGiftAmount != null)?Math.floor(this.dashboardMonthlySummary?.averageGiftAmount): this.averageGiftAmount;
        this.averageSalesAmount = (this.dashboardMonthlySummary.averageSalesAmount != undefined && this.dashboardMonthlySummary.averageSalesAmount != null)?Math.floor(this.dashboardMonthlySummary?.averageSalesAmount): this.averageSalesAmount;

        this.amountSoldString = this.getCommaSeparatedString(this.amountSold);
        this.redeemedAmountString = this.getCommaSeparatedString(this.redeemedAmount.toString());
        this.averageGiftAmountString = this.getCommaSeparatedString(this.averageGiftAmount.toString());
        this.averageSalesAmountString = this.getCommaSeparatedString(this.averageSalesAmount.toString());
      }
    });
  }

  getCommaSeparatedString(input){
    input = input.toString();
    let iLen = input.length;
    if(iLen <= 3) return input;
    var res;
    res = input.substring(iLen - 3, iLen);
    var substr = input.substring(0, iLen - 3);
    substr = substr.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
    res = substr +"," + res;
    
    return res;
  }


  getDashboardYearlyInformation(){
    let url = `api/GiftCard/dashboard-gift-card-reports-yearly/merchant/${this.userInfo.companyId}?`;
    if(this.userInfo.outletQrCode != null)
      url += `outletId=${this.userInfo.outletQrCode}`
    this.dataService.get(url).subscribe((data: DashboardYearlyResponse) => {
      if(data != null && data.success){
        this.monthlyGiftsAndSalesInfo = data.summary.monthlyGiftsAndSalesInfos;
        this.monthlyGiftVsRedemtionInfo = data.summary.monthlyGiftVsRedemtionInfos;
      }
    });
  }
}
