import { Component, OnInit, EventEmitter, Inject, Input, Output } from '@angular/core';
import { CorporateOffer } from 'src/app/corporate-offers/models/corporate-offer';
import { CompanyRole, OfferType } from 'src/app/enums';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Company, CompanyOutlet } from 'src/app/models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyService, CompanyOutletService, AlertService, FileUploadService, AccountServiceService } from 'src/app/services';
import { ReplaySubject } from 'rxjs';
import { CreateCorporateOfferComponent } from '..';
import { CorporateOfferService } from 'src/app/corporate-offers/services/corporate-offer.service';
import { DataService } from 'src/app/components/app-send-gift/services/shared/data.service';

@Component({
  selector: 'app-create-single-offer',
  templateUrl: './create-single-offer.component.html',
  styleUrls: ['./create-single-offer.component.css']
})
export class CreateSingleOfferComponent implements OnInit {

  @Input() OfferId: any;
  @Output() singleOfferCreateEditEvent: EventEmitter<any> = new EventEmitter<any>();

  userInfo: any;

  corporateOfferForm: FormGroup;
  submitted = false;
  inEditMode: boolean = false;
  showProviderSection: boolean = true;
  showMerchantSection: boolean = true;
  showOfferValueSection: boolean = false;
  showOfferCodeField: boolean = false;

  offer: CorporateOffer;
  offerType: OfferType;
  isOutletSpecific: boolean = false;
  selectedMerchantId: number;
  companyOutlets: CompanyOutlet[];
  selectedOutlets: string[];
  createCorporateOfferFormData: FormData = new FormData();
  fileToUpload: File;


  merchants: any[];
  merchantFilterCtrl: FormControl = new FormControl();
  filteredMerchants: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  selectedMerchant: any;

  offerProviders: Company[];
  offerProviderFilterCtrl: FormControl = new FormControl();
  filteredOfferProviders: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);

  //salesCommission: string;

  //startDateView: Date;
  //endDateView: Date;

  fileName: string = '';
  imagePreviewPath: string = '../../../assets/images/default.png';
  fileValidationError: string;
  offerValueType: any;

  codeTypes: any[];
  codeType: string;

  // onCreate event
  onCorporateOfferCreateEvent: EventEmitter<CorporateOffer> = new EventEmitter();
  // onEdit event;
  onCorporateOfferEditEvent: EventEmitter<CorporateOffer> = new EventEmitter();

  corporateOfferTypes: any[] = [];
  offerValueTypes: any[] = [];
  uploadedFiles: any;

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateCorporateOfferComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private companyService: CompanyService,
    private outletService: CompanyOutletService,
    private offerService: CorporateOfferService,
    private alertService: AlertService,
    private fileUploadService: FileUploadService,
    private accountService: AccountServiceService,
    private dataService: DataService
  ) {
    this.offer = new CorporateOffer();
    this.getLoggedInUser();
    this.codeType = "automatic";
    this.codeTypes = [
      { "id": "automatic", "name": "Automatic Offer Code" },
      { "id": "manual", "name": "Manual Offer Code" }
    ];

    // if (isNaN(this.OfferId) && this.OfferId != null) {
    //   this.getOffer(this.OfferId);
    //   this.inEditMode = true;
    // }
  }

  ngOnInit() {

    if (isNaN(this.OfferId) && this.OfferId != null) {
      this.getOffer(this.OfferId);
      this.inEditMode = true;
    }

    this.getLoggedInUser();
    this.getCorporateOfferTypes();
    this.getOfferValueTypes();
    this.buildForm();
    this.bindFilterChangeEvent();
    this.getMerchants();
    this.getOfferProviders();
    if (CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
      this.showProviderSection = false;
      this.showMerchantSection = false;
    }

    if (CompanyRole.GiftSender === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
      this.showProviderSection = false;
    }

  }

  getCorporateOfferTypes() {
    this.corporateOfferTypes = [
      { text: 'BOGO Offer', value: 1 },
      { text: 'Discount Offer', value: 2 },
      { text: 'Gift Back Offer', value: 3 },
      { text: 'Reward Point Offer', value: 4 }
    ];
  }

  getOfferValueTypes() {
    this.offerValueTypes = [
      { text: 'Fixed', value: 1 },
      { text: 'Percent', value: 2 }
    ];
  }

  getOfferProviders() {
    if (CompanyRole.GiftSender === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
      this.companyService.getCompanyById(this.userInfo.companyId)
        .subscribe((data: Company) => {
          this.offerProviders = [];
          this.offerProviders.push(data);
          this.corporateOfferForm.get('offerProviderId').setValue(this.offerProviders[0].id);
          this.filteredOfferProviders.next(this.offerProviders.slice());
        });
    } else {
      this.companyService.getOfferProviders()
        .subscribe(data => {
          this.offerProviders = data;
          this.filteredOfferProviders.next(this.offerProviders.slice());

        });
    }
  }

  getMerchants() {
    if (CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
      this.companyService.getCompanyById(this.userInfo.companyId)
        .subscribe((data: Company) => {
          this.merchants = [];
          this.merchants.push(data);
          this.corporateOfferForm.get('merchantId').setValue(this.merchants[0].id);
          this.filteredMerchants.next(this.merchants.slice());
        });
    } else {
      let url = `api/offermerchant/?offerProviderId=${this.userInfo.companyId}&pageNumber=1&pageSize=1000`
      this.dataService.get(url).subscribe((response: any) => {
        this.merchants = response?.data?.offerMerchants.map((i: any) => {
          return {
            id: i.merchantId,
            name: i.name,
            maxDiscount: i.maxDiscount,
            validFrom: i.validFrom,
            validTill: i.validTill
          };
        });
        this.filteredMerchants.next(this.merchants.slice());
      });
    }
  }

  openFileBrowser(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById('corporateOfferPhotoId') as HTMLElement;
    element.click();
  }

  bindFilterChangeEvent() {
    // listen for search field value changes
    this.merchantFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterMerchants();
      });
  }

  protected filterMerchants() {
    if (!this.merchants) {
      return;
    }
    // get the search keyword
    let search = this.merchantFilterCtrl.value;
    if (!search) {
      this.filteredMerchants.next(this.merchants);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the merchants
    this.filteredMerchants.next(
      this.merchants.filter(merchant => merchant.name.toLowerCase().indexOf(search) > -1)
    );
  }

  buildForm() {
    this.corporateOfferForm = this.formBuilder.group({
      offerProviderId: [this.offer.offerProviderId],
      merchantId: [this.offer.merchantId, [Validators.required]],
      offerType: this.offer.offerType,
      offerName: [this.offer.offerName, [Validators.maxLength(500), Validators.required]],
      shortName: [this.offer.shortName, Validators.required],
      offerCode: [this.offer.offerCode, [
        conditionalValidator(() => this.corporateOfferForm.get('codeType').value === 'manual',
          Validators.compose([Validators.required]))
      ]],
      offerValueType: [this.offer.offerValueType, [
        conditionalValidator(() => this.corporateOfferForm.get('offerType').value === 2 || this.corporateOfferForm.get('offerType').value === 3,
          Validators.compose([Validators.required]))
      ]],
      offerValue: [this.offer.offerValue, [
        conditionalValidator(() => this.corporateOfferForm.get('offerType').value === 2 || this.corporateOfferForm.get('offerType').value === 3,
          Validators.compose([Validators.required]))
      ]],
      validFrom: [this.offer.validFrom, [Validators.required]],
      validTill: [this.offer.validTill, [Validators.required]],
      description: [this.offer.description],
      commercialMessage: [this.offer.commercialMessage],
      codeType: [this.codeType],
    });

  }

  onSubmit() {
    this.submitted = true;

    if (CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
      this.corporateOfferForm.value.merchantId = this.userInfo.companyId;
      this.corporateOfferForm.value.offerProviderId = this.userInfo.companyId;
    }
    else if (CompanyRole.GiftSender === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
      this.corporateOfferForm.value.offerProviderId = this.userInfo.companyId;
    }

    // stop here if form is invalid
    if (this.corporateOfferForm.invalid) {
      return;
    }

    if (this.offer.id === undefined) { // create
      this.createOffer();
    }
    else { // edit
      this.editOffer();
    }
  }

  createOffer() {
    this.offer = new CorporateOffer(this.corporateOfferForm.value);

    this.createCorporateOfferFormData = new FormData();

    Object.keys(this.corporateOfferForm.value).forEach(key => {
      this.createCorporateOfferFormData.append(key, this.corporateOfferForm.value[key])
    });

    if (this.offer.offerValueType === null) {
      this.createCorporateOfferFormData.delete('offerValueType');
      this.createCorporateOfferFormData.delete('offerValue');
    }
    if (this.offer.offerCode === null) {
      this.createCorporateOfferFormData.delete('offerCode');
    }

    // removing date fields - deleted because it was converting to string . SO C# was unable to identify as a date
    this.createCorporateOfferFormData.delete("validFrom");

    if (this.offer.validTill) {
      var validFromDatestr = (new Date(this.offer.validFrom)).toUTCString();
      this.createCorporateOfferFormData.append("validFrom", validFromDatestr);
    }

    this.createCorporateOfferFormData.delete("validTill");

    if (this.offer.validTill) {
      var validTillDatestr = (new Date(this.offer.validTill)).toUTCString();
      this.createCorporateOfferFormData.append("validTill", validTillDatestr);
    }

    if (this.fileToUpload) {
      this.createCorporateOfferFormData.append('file', this.fileToUpload, this.fileToUpload.name);
    }

    this.offerService.createOfferTemplate(this.createCorporateOfferFormData)
      .subscribe((data: any) => {
        //this.alertService.success("Corporate Offer created successfully");
        //this.onCorporateOfferCreateEvent.emit(data);
        this.singleOfferCreateEditEvent.emit({ operation: 'create', data: data });
      },
        (error: any) => {
          this.showErrorMessage(error);
        })
  }

  editOffer() {
    let newData = new CorporateOffer(this.corporateOfferForm.value);
    if (this.offer !== null) {
      this.createCorporateOfferFormData = new FormData();
      Object.keys(this.corporateOfferForm.value).forEach(key => {
        this.createCorporateOfferFormData.append(key, this.corporateOfferForm.value[key])
      });

      if (newData.offerValueType === null) {
        this.createCorporateOfferFormData.delete('offerValueType');
        this.createCorporateOfferFormData.delete('offerValue');
      }
      if (newData.offerCode === null) {
        this.createCorporateOfferFormData.delete('offerCode');
      }

      this.createCorporateOfferFormData.append('id', this.offer.id);
      // removing date fields - deleted because it was converting to string . SO C# was unable to identify as a date
      this.createCorporateOfferFormData.delete("validFrom");

      if (this.offer.validTill) {
        var validFromDatestr = (new Date(newData.validFrom)).toUTCString();
        this.createCorporateOfferFormData.append("validFrom", validFromDatestr);
      }

      this.createCorporateOfferFormData.delete("validTill");

      if (this.offer.validTill) {
        var validTillDatestr = (new Date(newData.validTill)).toUTCString();
        this.createCorporateOfferFormData.append("validTill", validTillDatestr);
      }

      if (this.fileToUpload) {
        this.createCorporateOfferFormData.append('file', this.fileToUpload, this.fileToUpload.name);
      }

      this.offerService.editOfferTemplate(this.createCorporateOfferFormData)
        .subscribe((data: any) => {
          //this.alertService.success("Corporate Offer edited successfully");
          //this.onCorporateOfferEditEvent.emit(data);
          this.singleOfferCreateEditEvent.emit({ operation: 'edit', data: data });
        },
          (error: any) => {
            this.showErrorMessage(error);
          })
    }
  }

  showErrorMessage(error: any) {
    console.log(error);
    //this.alertService.error("Internal server error happen");
  }

  getLoggedInUser() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
  }

  getOffer(id: any) {
    this.offerService.getOfferTemplateForEdit(id)
      .subscribe((response: any) => {
        this.offer = response;
        if (this.offer != null) {
          this.imagePreviewPath = (this.offer.offerBrandingImage != null && this.offer.offerBrandingImage != '' && this.offer.offerBrandingImage != undefined)
            ? `image\\corporate_offer\\${this.offer.offerBrandingImage}` : this.imagePreviewPath;
          this.codeType = this.offer.offerCode == null || this.offer.offerCode == '' ? "automatic" : "manual";
          this.showOfferCodeField = this.codeType == 'manual' ? true : false;
          this.buildForm();
          this.onOfferTypeChange(this.offer.offerType);
        }
      });
  }

  onUploadImage(files) {
    this.fileValidationError = null;
    let fileInfo = this.fileUploadService.imageFileUpload(files);
    if (fileInfo.validationError != null) {
      this.fileValidationError = fileInfo.validationError;
      return;
    }
    this.fileName = fileInfo.fileName;
    this.uploadedFiles = fileInfo.formData;
    this.fileToUpload = <File>files[0];

    //this.registrationFormData.append('file', fileToUpload, fileToUpload.name);

    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imagePreviewPath = event.target.result;
    }
    reader.readAsDataURL(files[0]);
  }

  onOfferTypeChange(offerTypeId: any) {
    this.showOfferValueSection = offerTypeId === 2 || offerTypeId === 3 ? true : false;
  }

  codeTypeChange($event) {
    this.showOfferCodeField = $event.value === "manual" ? true : false;
  }

  onMerchantSelection(merchantId: any) {
    this.selectedMerchant = this.merchants.find(x => x.id === merchantId);

    this.corporateOfferForm.get('validFrom').setValue(this.selectedMerchant.validFrom);
    this.corporateOfferForm.get('validTill').setValue(this.selectedMerchant.validTill);
    this.corporateOfferForm.get('offerValue').setValue(this.selectedMerchant.maxDiscount);
  }

  close() {
    this.dialogRef.close();
  }

  get f() { return this.corporateOfferForm.controls; }

}

function conditionalValidator(predicate, validator) {
  return (formControl => {
    if (!formControl.parent) {
      return null;
    }
    if (predicate()) {
      return validator(formControl);
    }
    return null;
  })
}