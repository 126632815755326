import { DataService } from './../../services/data.service';
import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { ChangeGiftAssignmentInput } from '../../models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OfferAssignmentService, AlertService } from '../../services';

@Component({
  selector: 'app-change-gift-assignment',
  templateUrl: './change-gift-assignment.component.html',
  styleUrls: ['./change-gift-assignment.component.css']
})

export class ChangeGiftAssignmentComponent implements OnInit {
  assignmentId: string;
  existingReceiverNumber: string;
  newReceiverNumber: string;
  changeGiftAssignmentForm: FormGroup;
  submitted: boolean;
  onChangeGiftAssignmentEvent: EventEmitter<any> = new EventEmitter();

  //navigation
  initFrom:string;
  constructor(private formBuilder: FormBuilder, private offerAssignmentService: OfferAssignmentService,
    private dialogRef: MatDialogRef<ChangeGiftAssignmentComponent>, private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) data, private dataService: DataService) {

    if (isNaN(data)) { // coming from user list page
      this.assignmentId = data.assignmentId;
      this.existingReceiverNumber = data.giftReceiverPhoneNumber;
      if(data.initFrom && data.initFrom != ""){
        this.initFrom = data.initFrom;
      }
    }
  }

  ngOnInit() {
    this.buildForm();
  }

  close() {
    this.dialogRef.close();
  }

  buildForm() {
    this.changeGiftAssignmentForm = this.formBuilder.group({
      existingReceiverNumber: [this.existingReceiverNumber, [Validators.required, Validators.maxLength(15)]],
      newReceiverNumber: [this.newReceiverNumber, [Validators.required, Validators.maxLength(15)]],
    });
  }

  get f() { return this.changeGiftAssignmentForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.changeGiftAssignmentForm.invalid) {
      return;
    }

    var changeAssignment = new ChangeGiftAssignmentInput();
    changeAssignment.offerAssignmentId = this.assignmentId;
    changeAssignment.existingMobileNumber = this.existingReceiverNumber;
    changeAssignment.newMobileNumber = this.f.newReceiverNumber.value;

    this.dialogRef.close();

    if(this.initFrom && this.initFrom == "MANAGE_GIFT_CARD"){
      let url = "api/giftcardmanagement/change-assignment";
      this.dataService.post(url, changeAssignment).subscribe((res: any)=>{
        if (res.isValid) {
          this.alertService.success("Receiver changed successfully.");
        }
        else {
          this.alertService.error("Receiver changed not allowed for this gift.");
        }
        this.onChangeGiftAssignmentEvent.emit(changeAssignment);
      });
    }
    else{
      this.offerAssignmentService.changeOfferAssignment(changeAssignment).subscribe((result: any) => {
        if (result.isValid) {
          this.alertService.success("Receiver changed successfully.");
        }
        else {
          this.alertService.error("Receiver changed not allowed for this gift.");
        }
        this.onChangeGiftAssignmentEvent.emit(changeAssignment);
      });
    }
    

  }

}
