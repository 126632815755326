
export enum RolePermission {
  // system admin permission
  SystemAdmin = "0001",

  CompanyRead = "0010",
  CompanyCreate = "0011",
  CompanyUpdate = "0012",
  CompanyDelete = "0013",

  // merchant permission
  MerchantRead = "0014",
  MerchantCreate = "0015",
  MerchantUpdate = "0016",
  MerchantDelete = "0017",

  // provider permission
  ProviderRead = "0018",
  ProviderCreate = "0019",
  ProviderUpdate = "001A",
  ProviderDelete = "001B",

  // offer permission
  OfferRead = "0020",
  OfferCreate = "0021",
  OfferUpdate = "0022",
  OfferDelete = "0023",
  OfferApprove = "0024",
  OfferReject = "0025",
  OfferAvailedConsumerRead = "0026",
  ExtendGiftExpireDate = "0027",

  // offer assignment
  OfferAssignmentRead = "0030",
  OfferAssignmentCreate = "0031",
  OfferAssignmentUpdate = "0032",
  OfferAssignmentDelete = "0033",
  BlockGiftCard = "0034",

  // offer avail
  OfferAvailInitiate = "0040",
  OfferAvailAccept = "0041",
  OfferAvailDecline = "0042",
  OfferAvailTransactionRead = "0043",

  // outlet permission
  OutletRead = "0050",
  OutletCreate = "0051",
  OutletUpdate = "0052",
  OutletDelete = "0053",

  // billing
  InvoiceCreate = "0060",
  ProviderInvoiceCreate = "0061",
  MerchantInvoiceCreate = "0062",
  ReadInvoice = "0063",
  CreateTransaction = "0064",
  ReadTransaction = "0065",
  ReadPaymentDueReport = "0066",

  // consumer
  ConsumerRead = "0070",
  ConsumerCreate = "0071",
  ConsumerUpdate = "0072",
  ConsumerDelete = "0073",

  // Company Setting
  CompanySettingRead = "0080",
  CompanySettingCreate = "0081",
  CompanySettingUpdate = "0082",
  CompanySettingDelete = "0083",


  // User
  UserRead = "0091",
  UserCreate = "0092",
  UserUpdate = "0093",
  UserDelete = "0094",
  ResetPassword = "0095",

  //Report
  ViewReports = "0090",
  ConsumerGiftReport = "0096",
  CorporateGiftReport = "0097",
  UserRegistrationReport = "0098",
  UserRegistrationAdminReport = "0099",
  PremiumUserCreditUsageReport = "009A", //154
  PremiumUserCreditLimitExceedReport = "009B", //155

  //Golp User Credit
  ReadGolpUserCredit = "00A0",
  CreateGolpUserCredit = "00A1",
  EditGolpUserCredit = "00A2",
  DeleteGolpUserCredit = "00A3",

  //Golp Company Credit
  ReadGolpCompanyCredit = "00A6",
  CreateGolpCompanyCredit = "00A7",
  EditGolpCompanyCredit = "00A8",
  DeleteGolpCompanyCredit = "00A9",

  //Golp Reference
  ReadGolpReference = "00AA",//170
  CreateGolpReference = "00AB",
  EditGolpReference = "00AC",
  DeleteGolpReference = "00AD",

  // Customer Support
  ViewCustomerSupport = "00AE",
  MakeOpenMerchant = "00AF",
  ChangeOfferAssignment = "00B0", //176

  // Trending Merchant
  TrendingMerchantRead = "00B1",
  TrendingMerchantCreate = "00B2",
  TrendingMerchantUpdate = "00B3",
  TrendingMerchantDelete = "00B4",//180

  // Trending Merchant
  ReadGiftTemplate = "00B7", //183
  CreateGiftTemplate = "00B8",
  UpdateGiftTemplate = "00B9",
  DeleteGiftTemplate = "00BA",//186
  ViewGiftTemplateAssignments = "00BB",
  PrintGiftTemplate = "00BC",
  ViewGiftTemplate = "00BD",
  AssignGiftTemplate = "00BE",//190

  ViewCorporateOffer = "00BF", // 191
  CreateCorporateOffer = "00C0", //192
  DeleteCorporateOffer = "00C1", //193
  ActivateCorporateOffer = "00C2", //194
  ViewCorporateReport = "00C3", //195
  SearchCustomerOffer = "00C4", //196
  ViewOfferSummary = "00C5", //197

  // gift card permission
  IssueGiftCard = "00C6", // 198
  ViewGiftCard = "00C7", // 199
  EditGiftCard = "00C8", // 200
  ViewGiftCardReport = "00C9", // 201
  ViewVoucher = "00CA", // 202
  SyncVoucher = "00CB", // 203
  ViewVoucherSummary = "00CC", // 204

  CreatePartner = "00D3", //211
  ViewPartner = "00D4", //212
  EditPartner = "00D5", // 213
  DeletePartner = "00D6", // 214
  ViewOrder = "00D7", // 215

  // privilege card
  CreateGolpCard = "00D8", // 216
  EditGolpCard = "00D9", // 217
  DeleteGolpCard = "00DA", // 218
  ViewGolpCard = "00DB", // 219

  AddCardFacility = "00DC", // 220
  DeleteCardFacility = "00DD", // 221

  AddCardBeneficiary = "00DE", // 222
  DeleteCardBeneficiary = "00DF", // 223
  ViewCardBeneficiary = "00E0", // 224

  ViewOwnGolpCard = "00E1", // 225
  ViewGolpCardTransaction = "00E2", //226

  CreateStakeHolder = "00E3", //227
  ViewStakeHolder = "00E4", //228
  EditStakeHolder = "00E5", //229
  DeleteStakeHolder = "00E6", //230

}
