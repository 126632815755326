import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { UserModel } from '../../models';
import { UserType } from '../../enums';
import { extend } from 'webdriver-js-extender';
import { BaseComponent } from '../base/base.component';
import { UserReferenceModel } from '../../models/company-reference-model';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { CreatePremiumUserReferenceComponent } from '../create-premium-user-reference/create-premium-user-reference.component';
import { PremiumUserReferenceServiceService } from '../../services/premium-user-reference-service.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AlertService } from '../../services';

@Component({
  selector: 'app-user-references',
  templateUrl: './user-references.component.html',
  styleUrls: ['./user-references.component.css']
})
export class UserReferencesComponent implements OnInit {

  constructor(public dialog: MatDialog, private premiumUserReferenceService: PremiumUserReferenceServiceService,
    private alertService: AlertService) {
    if (this.premiumUserDetailsInput != null) {
      this.userId = this.premiumUserDetailsInput.id
      this.userType = this.premiumUserDetailsInput.userType;
      this.isPremiumUser = this.premiumUserDetailsInput.userType == UserType.Premium;
    }
  }

  @Input() premiumUserDetailsInput: UserModel;
  userId: number;
  userType: UserType;
  isPremiumUser = false;
  userReferences: UserReferenceModel[];

  ngOnInit() {
    this.getUserReferences();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['premiumUserDetailsInput']) {
      setTimeout(() => {
        if (this.premiumUserDetailsInput != null) {
          this.userId = this.premiumUserDetailsInput.id
          this.userType = this.premiumUserDetailsInput.userType;
          this.isPremiumUser = this.premiumUserDetailsInput.userType == UserType.Premium;
          this.getUserReferences();
        }

      });
    }
  }
  getUserReferences() {
    if (this.userId > 0) {
      this.premiumUserReferenceService.getAllUserReferenceByUserId(this.userId).subscribe((data: any) => {
        this.userReferences = data;
      });
    }
  }
  createUserReference() {
    const dialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "golp-dialog";
    var userId = this.premiumUserDetailsInput ? this.premiumUserDetailsInput.id : null;
    dialogConfig.data = userId != null ? { userId: userId } : null;

    const companyReferenceCreateDialog = this.dialog.open(CreatePremiumUserReferenceComponent, dialogConfig);

    const successfullCreate = companyReferenceCreateDialog.componentInstance.onReferenceCreateEvent.subscribe((data) => {
      this.getUserReferences();
    });

    companyReferenceCreateDialog.afterClosed().subscribe(() => {
    });
  }
  editUserReference(reference) {
    const dialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "golp-dialog";
    dialogConfig.data = reference;

    const companyReferenceEditDialog = this.dialog.open(CreatePremiumUserReferenceComponent, dialogConfig);

    const successfullCreate = companyReferenceEditDialog.componentInstance.onReferenceEditEvent.subscribe((data) => {
      this.getUserReferences();
    });

    companyReferenceEditDialog.afterClosed().subscribe(() => {
    });
  }
  deleteUserReference(reference: UserReferenceModel) {
    const confirmationDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    confirmationDialogConfig.data = "Are you sure to delete the reference " + reference.firstName + " " + reference.lastName;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.premiumUserReferenceService.deleteUserReference(reference.id).subscribe((data: any) => {
          this.alertService.success('User reference deleted successfully');
          this.getUserReferences();
        },
          (error) => {
            console.log(error);
          });
      }
    });

  }
}
