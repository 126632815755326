import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OfferAssignmentService } from 'src/app/services';

@Component({
  selector: 'app-availed-consumer-list',
  templateUrl: './availed-consumer-list.component.html',
  styleUrls: ['./availed-consumer-list.component.css']
})
export class AvailedConsumerListComponent implements OnInit {

  offerId: number;
  merchant: string;
  availedConsumers: any;
  availedConsumerFilter: any = { phoneNumber: '' };
  
  constructor(private dialogRef: MatDialogRef<AvailedConsumerListComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private offerAssignmentService: OfferAssignmentService) { 
      this.offerId = data.id;
      this.merchant = data.merchant;
      this.getAvailedConsumers();
    }

  ngOnInit() {
  }

  getAvailedConsumers(){
    this.offerAssignmentService.getAvailedConsumers(this.offerId)
      .subscribe((data) => {
        this.availedConsumers = data;
      },
      (error) => {
        console.log(error);
      });
  }


  close() {
    this.dialogRef.close();
  }
}
