import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CorporateOfferService } from 'src/app/corporate-offers/services/corporate-offer.service';
import { ValidationUtility } from 'src/app/utility/validation.utility';

@Component({
  selector: 'app-search-customer-offers',
  templateUrl: './search-customer-offers.component.html',
  styleUrls: ['./search-customer-offers.component.css']
})
export class SearchCustomerOffersComponent implements OnInit {

  @BlockUI('offer-report-section') blockUI: NgBlockUI;

  offerReportForm: FormGroup;

  currentPage: number = 1;
  pageSize: number = 25;
  expandedIndex: number = -1;
  totalItems: number = 0;

  paginationPageNumbers: any[];
  currentPageNumberDetails: string;
  submitted: boolean;

  userInfo: any;
  customerOffers: any[];

  constructor(private formBuilder: FormBuilder,
    private corporateOfferService: CorporateOfferService) {
    this.buildForm();
  }

  ngOnInit(): void {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userInfo = userInfo;
  }

  buildForm() {
    this.offerReportForm = this.formBuilder.group({
      customerPhone: ['', Validators.required],
    });
  }

  exportToExcel() {

  }

  getOfferByCustomerData() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.offerReportForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    this.corporateOfferService.get(`api/offerreports/offer-by-customer/${this.userInfo.companyId}?customerPhone=${this.f.customerPhone.value}`)
      .subscribe((response: any) => {
        if (response.success) {
          this.customerOffers = response.data;
        }
      });
  }

  get f() { return this.offerReportForm.controls; }

}
