import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoyaltyMerchant, LoyaltyMerchantPagedListInput, LoyaltyMerchantSetting, LoyaltyMerchantPoint } from '../models';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})


export class LoyaltyMerchantService {
  private baseUri = 'api/loyalty-merchant';
  private pointBaseUri = 'api/loyalty-point';

  requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };

  constructor(private http: HttpClient) {
  }

  getTrendinMerchantPagedList(input: LoyaltyMerchantPagedListInput): Observable<any> {
    return this.http.post<any[]>(this.baseUri + '/paged-list', input, this.requestHeader);
  }

  getAllLoyaltyMerchants(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUri, this.requestHeader);
  }
  createLoyaltyMerchant(loyaltyMerchant: LoyaltyMerchant) {
    return this.http.post(this.baseUri, loyaltyMerchant, this.requestHeader);
  }

  editLoyaltyMerchant(loyaltyMerchant: LoyaltyMerchant) {
    return this.http.put(this.baseUri, loyaltyMerchant, this.requestHeader);
  }

  //enableDisableMerchant(loyaltyMerchant: LoyaltyMerchant) {
  //  return this.http.put(`${this.baseUri}/merchant/activate`, loyaltyMerchant, this.requestHeader);
  //}

  deleteLoyaltyMerchant(loyaltyMerchant: any) {
    return this.http.put(this.baseUri + "/delete", loyaltyMerchant, this.requestHeader);
  }

  getAllLoyaltyMerchantCategories(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUri + "/categories", this.requestHeader);
  }
  getAllLoyaltyCardTiers(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUri + "/card-tiers", this.requestHeader);
  }

  getAllLoyaltyMerchantSettings(merchantId: any): Observable<any[]> {
    return this.http.get<any[]>(this.baseUri + "/settings/" + merchantId, this.requestHeader);
  }

  createOrEditLoyaltyMerchantSetting(loyaltyMerchantSetting: LoyaltyMerchantSetting) {
    return this.http.post(this.baseUri + "/settings", loyaltyMerchantSetting, this.requestHeader);
  }

  deleteLoyaltyMerchantSetting(loyaltyMerchantSetting: LoyaltyMerchantSetting) {
    loyaltyMerchantSetting.isDeleted = true;
    return this.http.post(this.baseUri + "/settings", loyaltyMerchantSetting, this.requestHeader);
  }

  uploadFile(uploadedFileData: FormData) {
    return this.http.post(this.baseUri + '/upload-file', uploadedFileData);
  }

  //getAllPointsByLoyaltyMerchantId(merchantId: any): Observable<any[]> {
  //  return this.http.get<any[]>(this.pointBaseUri + "/merchant/" + merchantId, this.requestHeader);
  //}

  createOrEditLoyaltyMerchantPoint(loyaltyMerchantPoint: LoyaltyMerchantPoint) {
    return this.http.post(this.pointBaseUri + "/add-point", loyaltyMerchantPoint, this.requestHeader);
  }

  getMerchantCards(loyaltyMerchantId: any): Observable<any[]> {
    return this.http.get<any[]>(this.baseUri + "/" + loyaltyMerchantId + "/cards", this.requestHeader);
  }

  getAllMerchantCardTiers(loyaltyMerchantId: any): Observable<any[]> {
    return this.http.get<any[]>(this.baseUri + "/merchant/" + loyaltyMerchantId + "/card-tiers", this.requestHeader);
  }
}

