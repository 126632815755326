import { Component, OnInit, Input } from '@angular/core';
import { UserRegistrationReportOutput } from 'src/app/models/user-registration-report-output';
import { PermissionCheckerService } from 'src/app/services/permission-checker.service';
import { ReportService } from 'src/app/services/report.service';
import { UserRegistrationReportInput } from 'src/app/models/user-registration-report-input';
import * as moment from 'moment';
import { SharedService } from '../../services/shared.service';
import { NewRegisteredUniqueDeviceCountInput } from '../../models';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-user-registration-detail-report',
  templateUrl: './user-registration-detail-report.component.html',
  styleUrls: ['./user-registration-detail-report.component.css']
})
export class UserRegistrationDetailReportComponent implements OnInit {

  @BlockUI('user-registration-report-section') blockUI: NgBlockUI;

  userRegistrationReportOutputs: UserRegistrationReportOutput[] = [];
  currentPage = 1;
  pageSize = 25;
  expandedIndex = -1;
  totalItems = 0;
  reportTypes: any[];
  paginationPageNumbers: any[];
  selectedReportType = 1;
  reportFromCustomDateRange = false;
  currentPageNumberDetails: string;
  newRegisteredUniqueDeviceString: string;

  fromDate = moment().format();
  toDate = moment().format();
  fromDateTime = moment().format();
  toDateTime = moment().format();

  @Input() showCustomDateField: boolean;

  constructor(
    private reportService: ReportService,
    private permissionCheckerService: PermissionCheckerService,
    private sharedService: SharedService) {
    this.reportTypes = [{ text: 'Today', value: 1 }, { text: 'Yesterday', value: 2 },
    { text: 'Last 7 Days', value: 3 }, { text: 'Last 30 Days', value: 4 }];

  }

  ngOnInit() {
    this.getUserRegistrationReport();
    this.paginationPageNumbers = this.sharedService.getPaginationByPageNumbers();
  }
  getUserRegistrationReportFromreportType() {
    this.reportFromCustomDateRange = false;
    this.getUserRegistrationReport();
  }

  getUserRegistrationReport() {
    const input = new UserRegistrationReportInput();
    input.pageNumber = this.currentPage;
    input.pageSize = this.pageSize;
    input.reportType = this.selectedReportType;
    if (this.reportFromCustomDateRange) {
      // 5 is for customDateRange;
      input.reportType = 5;
      this.selectedReportType = -1;
      input.fromDateTime = moment(this.fromDateTime).format().toString();
      input.toDateTime = moment(this.toDateTime).format().toString();
    }

    this.getTotalNewUniqueDeviceNumbers();

    this.blockUI.start();

    this.reportService.getUserRegistrationReport(input).subscribe(result => {
      this.userRegistrationReportOutputs = result.viewModel;
      this.totalItems = result.rowCount;
      this.currentPageNumberDetails = "(Showing " + result.firstRowOnPage + " to " + result.lastRowOnPage + " of " + result.rowCount + " items)"
      this.blockUI.stop();

    }, error => {
      this.blockUI.stop();
    });
  }

  getTotalNewUniqueDeviceNumbers() {
    var input = new NewRegisteredUniqueDeviceCountInput();
    input.reportType = this.selectedReportType;

    if (this.reportFromCustomDateRange) {
      // 5 is for customDateRange;
      input.reportType = 5;
      this.selectedReportType = -1;
    }
    input.fromDateTime = moment(this.fromDateTime).format().toString();
    input.toDateTime = moment(this.toDateTime).format().toString();

    this.reportService.getNewUniqueDeviceCountReport(input).subscribe(result => {
      this.newRegisteredUniqueDeviceString = "New Registered Device: " + result;
    });
  }

  pageChanged(event) {
    this.currentPage = event;
    this.getUserRegistrationReport();
  }

  submit() {
    this.reportFromCustomDateRange = true;
    this.getUserRegistrationReport();
  }

}
