import { Component, OnInit, Injector } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountServiceService, AlertService, CommonService, PromotionService } from 'src/app/services';
import { BaseComponent } from '../base/base.component';
import { Promotion } from 'src/app/models';
import { CreatePromotionComponent } from '../create-promotion/create-promotion.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.css']
})


export class PromotionsComponent extends BaseComponent implements OnInit {
  @BlockUI('promotion-section') blockUI: NgBlockUI;

  dataLoaded: Promise<boolean>;

  currentPage: number = 1;
  pageSize: number = 10;
  itemCountText: string;

  userInfo: any;
  canCreate: boolean = true;

  filteredPromotions: any[];
  promotions: any[]=[];

  promotionFilter: any = { name: '' };
  promotionFilterText: string;
  selectedPromotionIndex: number = -1;
  selectedPromotion: Promotion;
  selectedPromotionName: string;

  promotionSubMenu: any[] = [];
  selectedSubMenuIndex: number = 0;
  activeSubMenu: string = "About";
  totalItems: any;
  searchText: string;

  constructor(private promotionService: PromotionService, private dialog: MatDialog,
    private accountService: AccountServiceService,
    private commonService: CommonService,
    private alertService: AlertService,
    injector: Injector
  ) {
    super(injector);
  }
  //
  ngOnInit() {
    //this.getLoggedInUser();
    this.getPromotions();

    this.promotionSubMenu.push({ "name": "About", "dataTarget": "aboutProviderTab" });

    this.dataLoaded = Promise.resolve(true);
  }


  
  //OnDeleteTrendingMerchant(trendingMerchant: TrendingMerchant): void {
  //  const confirmationDialogConfig = new MatDialogConfig()

  //  // Setting different dialog configurations
  //  confirmationDialogConfig.data = "Are you sure to delete the promotion " + trendingMerchant.name + " from trending list.";
  //  confirmationDialogConfig.panelClass = 'golp-dialog';
  //  const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

  //  confirmationDialog.afterClosed().subscribe((result) => {
  //    if (result != undefined) {
  //      this.deleteMerchant(trendingMerchant);
  //    }
  //  });
  //}

  //deleteMerchant(trendingMerchant: TrendingMerchant) {
  //  this.trendingMerchantService.deleteTrendingMerchant(trendingMerchant.id).subscribe((data) => {
  //    this.alertService.success('Trending Merchant deleted successfully');
  //    this.getAllTrendingPromotions(null);
  //  },
  //    (error) => {
  //    });
  //}

  createPromotion() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'golp-dialog';
    const offerCreateDialog = this.dialog.open(CreatePromotionComponent, dialogConfig);

    const successfullCreate = offerCreateDialog.componentInstance.onPromotionCreateEvent.subscribe((data) => {
      //this.getGolpProductById(data.id);
    });

    offerCreateDialog.afterClosed().subscribe(() => {
    });
  }


  activatePromotion(promotion: Promotion) {
    promotion.isActive = true;
    this.promotionService.activatePromotion(promotion)
      .subscribe((data: any) => {
        this.selectedPromotion.isActive = true;
        this.selectedPromotion.promotionStatus = "Active";
        this.alertService.success('Promotion activated successfully');
      });
  }

  deactivatePromotion(promotion: Promotion) {
    promotion.isActive = false;
    this.promotionService.deactivatePromotion(promotion)
      .subscribe((data: any) => {
        this.selectedPromotion.isActive = false;
        this.selectedPromotion.promotionStatus = "Inactive";
        this.alertService.success('Promotion deactivated successfully');
      });
  }

  onPromotionChange(promotion, index) {
    if (this.selectedPromotionIndex != index) {
      this.selectedPromotionIndex = index === this.selectedPromotionIndex ? -1 : index;
      this.selectedPromotion = promotion;
      this.selectedPromotionName = promotion.promotionCode;
    }
    let body = document.getElementsByTagName('body')[0];
    body.classList.add("display-tab-content");
    this.commonService.itemSelectionUpdated(this.selectedPromotionIndex);
  }

  getPromotionDetailsChange(event) {
    //let promotion = this.filteredPromotions.filter(c => c.id === event.id)[0];
    //promotion.companyId = event.companyId;
    //promotion.isActive = event.isActive;
    //promotion.orderNo = event.orderNo;

    //this.selectedPromotion = promotion;

    this.getPromotions();
  }

  onSubMenuChange(name, index) {
    if (this.selectedSubMenuIndex != index) {
      this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
      this.activeSubMenu = this.promotionSubMenu.filter(c => c.name === name)[0].name;
    }
  }

  getPromotions() {
    var input: any = {};

    input.promotionName = this.selectedPromotionName;
    input.pageNumber = this.currentPage;
    input.pageSize = this.pageSize;

    this.promotionService.getPromotionsPagedList(input)
      .subscribe(data => {
        this.promotions = data.viewModel;
        //console.log(this.promotionsPrimitiveList);
        this.promotionCopy();
        this.totalItems = data.rowCount;

        this.highlightFirstItem(this.filteredPromotions);
        this.totalItems = data.rowCount;
        this.itemCountText = "(Showing " + data.firstRowOnPage + " to " + data.lastRowOnPage + " of " + data.rowCount + " items)"
        this.blockUI.stop();
      },
        (error) => {
          this.blockUI.stop();

        });
  }

  highlightFirstItem(promotions: Promotion[]) {
    // set initial offeror
    if (promotions !== null && promotions.length > 0) {
      this.selectedPromotion = promotions[0];
      this.selectedPromotionName = this.selectedPromotion.promotionCode;
      this.selectedPromotionIndex = 0;
      this.dataLoaded = Promise.resolve(true);

    }
  }

  pageChanged(event) {
    this.currentPage = event;
    this.getPromotions();
  }

  promotionFilteredItem(value) {
    this.searchText = value;
    this.getPromotions();
  }

  promotionCopy() {
    this.filteredPromotions = Object.assign([], this.promotions);
  }


  errorHandlerCompanyLogo(event, promotionId) {
    //event.target.src = "http://place-hold.it/24x24/ebe89f/9D2933&fontsize=12&text=" + promotionFirstChar;

    document.getElementById('companyLogoImgtxt_' + promotionId).style.display = "block";
    document.getElementById('companyLogoImg_' + promotionId).style.display = "none";
  }

}
