import { CompanyRole } from "../enums";
import { LoyaltySettingsKey } from "./loyalty-setting-type";

export class CompanySetting {
  id: any;
  key: number;
  value: string;
  displayValue: string;
  displayName: string;
  companyId: number;
  isEnabled: boolean;
  dataType: string;
  companyRole: CompanyRole;
  loyaltySettingsKey: LoyaltySettingsKey;
  public constructor(init?: Partial<CompanySetting>) {
    Object.assign(this, init);
  }
}
