
import { Component, OnInit, Input, SimpleChanges, Injector } from '@angular/core';
import { BaseComponent } from 'src/app/components/base/base.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { AlertService } from 'src/app/services';
import { DataService } from 'src/app/components/app-send-gift/services/shared/data.service';

@Component({
  selector: 'app-user-privilege-card-details',
  templateUrl: './user-privilege-card-details.component.html',
  styleUrls: ['./user-privilege-card-details.component.css']
})
export class UserPrivilegeCardDetailsComponent extends BaseComponent implements OnInit {


  privilegeCardInput: any;

  privilegeCard: any;
  cards: any[] = [];

  constructor(private dataService: DataService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['privilegeCardInput']) {
      setTimeout(() => {
        this.privilegeCard = this.privilegeCardInput;
        //update table list 
        if(this.privilegeCard && this.privilegeCard.cardId)
          this.getCardsByTemplate(this.privilegeCard.cardId);
      });
    }
  }

  getCardsByTemplate(privilegeCardId: any) {
    let url = `api/cards/card-facility/${privilegeCardId}`
    this.dataService.get(url)
      .subscribe((response :any) => {

        if(response.success){
          let allCard = response.data;
          allCard.forEach(p=>{
            let m = p;
            m["show"] = false;
            this.cards.push(m);
          });
        }
        else{
          this.cards = [];
        }
        
      });
  }

  toggleInfo(index){
    this.cards[index].show = !this.cards[index].show;
  }

}
