import { Component, OnInit, Input, SimpleChanges, Injector } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { CreateUserCreditComponent } from '../create-user-credit/create-user-credit.component';
import { UserModel, UserCredit, UserCreditSummary } from '../../models';
import { UserCreditsService, AlertService } from '../../services';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-user-credits',
  templateUrl: './user-credits.component.html',
  styleUrls: ['./user-credits.component.css']
})
export class UserCreditsComponent extends BaseComponent implements OnInit {
  premiumUserDetails: UserModel;
  userFullName: string;
  userId: number;
  userCredits: UserCredit[];
  userCreditSummary: UserCreditSummary;
  expandedIndex: number = -1;
  isSystemAdmin = false;


  constructor(
    private dialog: MatDialog,
    private alertService: AlertService,
    private userCreditService: UserCreditsService,
    private injector: Injector
  ) { super(injector); }
  @Input() premiumUserDetailsInput: UserModel;

  ngOnInit() {

    this.premiumUserDetails = new UserModel();
    this.userFullName = this.premiumUserDetails.firstName != null ? this.premiumUserDetails.firstName : ""
      + " " + this.premiumUserDetails.lastName != null ? this.premiumUserDetails.lastName : "";
    this.userId = this.premiumUserDetails.id;
    this.isSystemAdmin = this.checkPermission(['SystemAdmin']);


    this.getUserCredit();
    this.getUserCreditSummary();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['premiumUserDetailsInput']) {
      setTimeout(() => {
        this.premiumUserDetails = this.premiumUserDetailsInput;
        this.userFullName = this.premiumUserDetails.firstName != null ? this.premiumUserDetails.firstName : ""
          + " " + this.premiumUserDetails.lastName != null ? this.premiumUserDetails.lastName : "";
        this.userId = this.premiumUserDetails.id;
        this.getUserCredit();
        this.getUserCreditSummary();
      });
    }
  }

  createUserCredit() {
    const createUserCreditDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    createUserCreditDialogConfig.disableClose = true;
    createUserCreditDialogConfig.autoFocus = true;
    createUserCreditDialogConfig.panelClass = "golp-dialog";

    var userCredit = new UserCredit();
    userCredit.userId = this.userId;
    createUserCreditDialogConfig.data = userCredit;

    const createUserCreditDialog = this.dialog.open(CreateUserCreditComponent, createUserCreditDialogConfig);

    const successfullCreate = createUserCreditDialog.componentInstance.onCreateUserCreditEvent.subscribe((data) => {
      this.getUserCredit();
      this.getUserCreditSummary();
    });

    createUserCreditDialog.afterClosed().subscribe(() => {
    });


  }

  getUserCredit() {
    if (this.userId) {
      this.userCreditService.getUserCreditByUserId(this.userId).subscribe(result => {
        this.userCredits = result;
      });
    }
  }


  getUserCreditSummary() {
    if (this.userId) {
      this.userCreditService.getUserCreditSummaryByUserId(this.userId).subscribe(result => {
        this.userCreditSummary = result;
      });
    }
  }

  changeStatus(item: UserCredit, active: boolean) {
    const confirmationDialogConfig = new MatDialogConfig()
    var text = "Are you sure to activate this user credit?";
    var successMessage = "User credit activated successfully";
    if (active == false) {
      text = "Are you sure to deactivate this user credit?";
      successMessage = "User credit deactivated successfully";
    }
    var userCredit = new UserCredit();
    userCredit.isActive = active;
    userCredit.id = item.id;
    // Setting different dialog configurations
    confirmationDialogConfig.data = text;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.userCreditService.changeUserCreditStatus(userCredit).subscribe(result => {
          this.alertService.success(successMessage);
          this.getUserCredit();
          this.getUserCreditSummary();
        });
      }
    });
  }


  changeStatusForAll(active: boolean) {
    const confirmationDialogConfig = new MatDialogConfig()
    var text = "Are you sure to activate user credits?";
    var successMessage = "User credits activated successfully";
    if (active == false) {
      text = "Are you sure to deactivate user credits?";
      successMessage = "User credits deactivated successfully";
    }
    var userCredit = new UserCredit();
    userCredit.isActive = active;
    userCredit.id = null;
    userCredit.userId = this.userId;
    // Setting different dialog configurations
    confirmationDialogConfig.data = text;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.userCreditService.changeAllUserCreditStatus(userCredit).subscribe(result => {
          this.alertService.success(successMessage);
          this.getUserCredit();
          this.getUserCreditSummary();
        });
      }
    });
  }

  expandCollapse(index) {
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }
}
