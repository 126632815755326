import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GiftTemplateDto, GiftTemplateInputDto, GiftTemplatePagedListInputDto, GiftTemplateAssociationsInputDto, GiftTemplateAssignmentPagedListInputDto } from '../models';

@Injectable({
  providedIn: 'root'
})

export class GiftTemplateService {

  private giftTemplateBaseUrl = 'api/gift-templates';

  constructor(private http: HttpClient) { }

  getGiftTemplates(): Observable<GiftTemplateDto[]> {
    return this.http.get<GiftTemplateDto[]>(this.giftTemplateBaseUrl);
  }

  getGiftTemplatesPagedList(input: GiftTemplatePagedListInputDto): Observable<any> {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post<any>(this.giftTemplateBaseUrl + "/paged-list", input, requestHeader);
  }

  getGiftTemplateAssociations(input: GiftTemplateAssociationsInputDto): Observable<any> {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post<any>(this.giftTemplateBaseUrl + "/associations", input, requestHeader);
  }

  getGiftTemplateAssociationPagedList(input: GiftTemplateAssignmentPagedListInputDto): Observable<any> {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post<any>(this.giftTemplateBaseUrl + "/associations/paged-list", input, requestHeader);
  }

  getGiftTemplateById(giftTemplateId: number): Observable<GiftTemplateDto> {
    return this.http.get<GiftTemplateDto>(this.giftTemplateBaseUrl + giftTemplateId);
  }

  createGiftTemplate(giftTemplate: GiftTemplateInputDto) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.giftTemplateBaseUrl, giftTemplate, requestHeader);
  }

  editGiftTemplate(giftTemplate: GiftTemplateInputDto) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.put(this.giftTemplateBaseUrl, giftTemplate, requestHeader);
  }


  changeGiftTemplateStatus(giftTemplate: GiftTemplateInputDto) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.put(this.giftTemplateBaseUrl + "/change-status", giftTemplate, requestHeader);
  }


  deleteGiftTemplate(giftTemplateId: number) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.delete(this.giftTemplateBaseUrl + '/' + giftTemplateId, requestHeader);
  }

  assignGiftTemplates(input: any) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post<any>(this.giftTemplateBaseUrl + '/assign', input, requestHeader);
  }

  printGiftTemplates(input: any) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post<any>(this.giftTemplateBaseUrl + '/print',input,requestHeader);
  }
  downloadXlPinAndCode(input: any, options:any) {
    //var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False', 'responseType': 'blob' }) };
    
    return this.http.post<any>(this.giftTemplateBaseUrl + '/download-xl',input,options);
  }
  

  saveAsHtmlGiftTemplates(input: any): Observable<string> {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post<string>(this.giftTemplateBaseUrl + '/saveAs-Html', input, requestHeader);
  }

  exportToExcelGiftTemplates(input: any): Observable<any> {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post<any>(this.giftTemplateBaseUrl + '/export-excel', input, requestHeader);
  }

}
