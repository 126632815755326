import { DatePipe } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/components/base/base.component';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { AlertService } from 'src/app/services';
import { DataService } from '../../../services/shared/data.service';

@Component({
  selector: 'app-gift-details',
  templateUrl: './gift-details.component.html',
  styleUrls: ['./gift-details.component.css']
})
export class GiftDetailsComponent extends BaseComponent implements OnInit {

  giftId: any;
  giftType: any;
  giftDetails: any;
  giftSender: any;
  apiBaseUri: any;
  merchentId: any;
  availInstruction: any;
  instructionCollapse: boolean = true;
  instructionExpand: boolean = false;
  qrResultString: any;
  showCode: boolean = false;
  giftCode: any = "XUIA-809T-PYAI-0178";
  giftPin: any = "123456";
  qrIsOpened = false;
  navigationData: any;
  qrCodeInfo: any;
  qrPin: any;
  extendDateForm: FormGroup;
  submitted: boolean;
  defaultExtendedDate: Date = new Date();
  showExentedDateForm: boolean = false;
  merchantId: any;
  companyOutletId: any;
  showViewPinAndCode: boolean = false;
  constructor(private formBuilder: FormBuilder, private dialog: MatDialog, private route: ActivatedRoute, private dataService: DataService, private router: Router, private alert: AlertService,
    private datePipe: DatePipe,
    injector: Injector) {
    super(injector);
    this.getGiftDetails();
    this.buildForm();
    this.viewPinAndCode();
  }
  ngOnInit() {
    this.setUserInfo();
  }

  viewPinAndCode() {
    if (!this.giftDetails?.hasAvailed && new Date(this.giftDetails?.validTill) >= new Date())
      this.showViewPinAndCode = true;
    else this.showViewPinAndCode = false;
  }

  setUserInfo() {
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.merchantId = userInfo.companyId;
    this.companyOutletId = userInfo.outletQrCode;
  }
  buildForm() {
    // this.defaultExtendedDate.setMonth(this.defaultExtendedDate.getMonth() + 6);
    this.extendDateForm = this.formBuilder.group({
      extendedDate: [this.giftDetails?.validTill, [Validators.required, validDateCheck(new Date(this.giftDetails?.validTill))]]
    });
  }
  get f() { return this.extendDateForm.controls; }

  getGiftDetails() {
    let navigation = this.router.getCurrentNavigation();
    let state = navigation.extras.state as {
      transId: string,
      workQueue: boolean,
      services: number,
      code: any
    };

    if (state != undefined && state.code != undefined) {
      this.giftDetails = state.code;
      this.giftId = this.giftDetails?.giftId;
    }
  }

  checkDateValid() {
    console.log(this.defaultExtendedDate <= new Date());
    return this.defaultExtendedDate <= new Date();
  }

  veiwCode() {
    let url = `api/GiftCard/view-gift-code-pin/merchant/${this.merchantId}/${this.giftId}`
    this.dataService.get(url).subscribe((response: any) => {
      if (response.success) {
        console.log(response);
        this.giftCode = response.data?.giftCode;
        this.giftPin = this.giftDetails?.offerAvailMethod == "GolpCouponCode" ? "No PIN Required" : response.data?.pin;
        this.showCode = true;
      }
    });
  }

  // isExpired(){
  //   var expirationDate = new Date(this.giftDetails?.expirationDate);
  //   if(expirationDate)
  //     return expirationDate < new Date();

  //   return true;
  // }



  hideCode() {
    this.showCode = false;
  }

  expandCollapse() {
    this.instructionCollapse = this.instructionCollapse ? false : true;
    this.instructionExpand = this.instructionExpand ? false : true;
  }

  onBack() {
    this.router.navigate(['gift-sent-history']);
  }

  popUpExtend() {
    const confirmationDialogConfig = new MatDialogConfig();
    // Setting different dialog configurations
    confirmationDialogConfig.data = `Do you really want to proceed?`;
    confirmationDialogConfig.panelClass = "golp-dialog";
    const confirmationDialog = this.dialog.open(
      ConfirmationDialogComponent,
      confirmationDialogConfig
    );

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.extendDate();
      }
    });
  }

  extendDate() {
    this.submitted = true;
    if (!this.extendDateForm.valid) {
      return;
    }
    this.showExentedDateForm = false;
    let extendedDate = this.extendDateForm.value.extendedDate;
    extendedDate = this.datePipe.transform(extendedDate, 'MM/dd/yyyy');
    let extendDateRequest = {
      cardIssuerId: this.merchantId,
      giftCardId: this.giftId,
      extendedDate: extendedDate,

    };



    this.dataService.post('api/giftcard/extend-gift-card-validity', extendDateRequest).subscribe((data: any) => {
      if (data.success) {
        this.alert.success(data.data);
        this.giftDetails.validTill = extendedDate;
        console.log(this.giftDetails.validTill);
        Object.keys(this.extendDateForm.controls).forEach(control => this.extendDateForm.controls[control].updateValueAndValidity())
      }
      else {
        this.alert.error(data.data);
      }
    },
      (error: any) => {
        console.log(error);
      }
    );
  }

  extendForm() {
    this.showExentedDateForm = true;
  }
  hideForm() {
    this.showExentedDateForm = false;
  }
}

export function validDateCheck(value: Date) {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const input = new Date(control.value);
    return input < value ? { validDateError: { value: control.value } } : null;
  };
}