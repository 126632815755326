import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Select2OptionData } from 'ng-select2';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataService } from 'src/app/services';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import { saveAs } from 'file-saver';
import { ConstantText } from 'src/app/constants/constant.text';
import { UrlBuilder } from 'src/app/utility/UrlBuilder';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DeliverGiftOrderComponent } from 'src/app/components/customer-support-page/deliver-gift-order/deliver-gift-order.component';



@Component({
  selector: 'revenue-dashboard-component',
  templateUrl: './revenue-dashboard.component.html',
  styleUrls: ['./revenue-dashboard.component.css']
})
export class RevenueDashboardComponent {

  queryGeneratorForm: FormGroup;

  currentPage: number = 1;
  pageSize: number = 10;
  expandedIndex: number = -1;
  totalItems: number = 0;
  count = 0;
  pageSizes = [10, 25, 50, 100];
  revenueTypeList = ['All', 'Prepaid', 'Postpaid', 'Business solution']

  paginationInfo: string = '';

  defaultStartDate: Date = new Date();
  defaultEndDate: Date = new Date();
  paginationPageNumbers: any[];
  currentPageNumberDetails: string;
  submitted: boolean;
  companyOutletId: any;

  userInfo: any;
  reportSummary: any[];

  soldGift: number = 0;
  soldGiftAmount: number = 0;
  redeemedGift: number = 0;
  redeemedGiftAmount: number = 0;
  p: number = 1;
  currentRemarks: string;
  merchantList: any;
  fromDate: Date;
  toDate: Date;
  totalRevenue: number;
  totalGiftAmount: number;
  totalRedeemedAmount: number;
  flag: boolean = true;

  select2MerchantInfo: Array<Select2OptionData>;
  selectOfferProvider: Array<Select2OptionData>;
  revenueTypes: Array<Select2OptionData>;
  selectedCompany: any[];
  selectedMerchnat: any[];
  selectedRevenueTypes: any[];
  giftResponse: any;

  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private dialog: MatDialog,
  ) {
    this.fromDate = this.startOfMonth(new Date());
    this.toDate = new Date();
    this.selectedCompany = ['-1'];
    this.selectedMerchnat = ['-1'];
    this.selectedRevenueTypes = ['-1'];
    this.buildForm();
    this.count = 0;
  }

  ngOnInit(): void {
    this.count = 0;
    this.loadMerchant();
    this.loadOfferProvider();
    this.revenueTypeInitiator();
    this.getData();
  }

  revenueTypeInitiator() {
    let position = -1;
    this.revenueTypes = this.revenueTypeList.map((p) => {
      const tempId = position++;
      return {
        id: tempId.toString(),
        text: p
      };
    });
  }

  buildForm() {
    this.queryGeneratorForm = this.formBuilder.group({
      selectedMerchnat: [this.selectedMerchnat],
      selectedCompany: [this.selectedCompany],
      fromDate: [this.fromDate, Validators.required],
      toDate: [this.toDate, Validators.required],
      selectedRevenueType: [this.selectedRevenueTypes]
    });
  }

  change(options, whichField) {
    options = this.removeAllOptions(options);
    if (options.length == 0) {
      options.push('-1');
    }
    if (whichField == 0) {
      this.selectedRevenueTypes = options;
    }
    else if (whichField == 1) {
      this.selectedMerchnat = options;
    }
    else {
      this.selectedCompany = options;
    }
  }

  removeAllOptions(options) {
    let removedOptions = [];
    for (let option of options) {
      if (option == '-1') {
        continue;
      }
      removedOptions.push(option);
    }
    return removedOptions;
  }

  loadMerchant() {
    let url = 'api/company/merchant/basics';
    this.dataService.get(url).subscribe(
      (data: any) => {
        this.merchantList = data;
        this.select2MerchantInfo = this.merchantList.map((p) => {
          return {
            id: p.id,
            text: p.name,
          };
        });
        let noMerchant: Select2OptionData = {
          id: "-1",
          text: "All",
        };
        this.select2MerchantInfo.unshift(noMerchant);
      },
      catchError(() => {
        return throwError('Something went wrong!');
      })
    );
  }

  loadOfferProvider() {
    let url = 'api/company/offer-provider';
    this.dataService.get(url).subscribe(
      (data: any) => {
        this.merchantList = data;
        this.selectOfferProvider = this.merchantList.map((p) => {
          return {
            id: p.id,
            text: p.name,
          };
        });
        let noMerchant: Select2OptionData = {
          id: "-1",
          text: "All",
        };
        this.selectOfferProvider.unshift(noMerchant);
      },
      catchError(() => {
        return throwError('Something went wrong!');
      })
    );
  }

  populateSummaryData(data: any) {
    this.totalGiftAmount = data.totalGiftAmount;
    this.totalRedeemedAmount = data.totalRedeemedAmount;
    this.totalRevenue = data.totalRevenue;
  }

  startOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1, 11, 59, 59);
  }

  getData(submitted: boolean = false) {
    this.submitted = submitted;

    if (this.queryGeneratorForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      alert('Please provide Requered Field');
      return;
    }

    this.fromDate = this.f.fromDate.value;
    this.toDate = this.f.toDate.value;
    let merchantIds = this.f.selectedMerchnat.value;
    let selectedCompany = this.f.selectedCompany.value;
    let revenueType = this.f.selectedRevenueType.value;

    let params = {
      FromDate: moment(this.fromDate).format("YYYY/MM/DD"),
      ToDate: moment(this.toDate).format("YYYY/MM/DD"),
      CompanyIds: selectedCompany,
      MerchantIds: merchantIds,
      RevenueTypes: revenueType
    }

    const url = UrlBuilder.buildUrlWithParams(ConstantText.RevenueDashboardApi, params);

    this.dataService.get(url).subscribe((response: any) => {
      this.giftResponse = response.data.transactionDetails;
      this.count = response.data.transactionDetails.length;
      this.populateSummaryData(response.data.summary);
    });

  }

  removeIdForAll(data: any) {
    let processIds: any[] = [];
    data.forEach(element => {
      if (element != '-1') {
        processIds.unshift(element);
      }
    });
    return processIds;
  }

  getTwoPrecissionValue(value: any) {
    if (!value) return '0';
    return parseFloat(value).toFixed(2);
  }

  hasConsumed(type: any, hasConsumed: any) {
    return hasConsumed ? "Yes" : ((type == 2) ? '' : 'No');
  }

  revenueTypeToString(type: any) {
    return this.revenueTypeList[type + 1];
  }

  downloadReport() {
    if (this.queryGeneratorForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      alert('Please provide Requered Field');
      return;
    }

    this.fromDate = this.f.fromDate.value;
    this.toDate = this.f.toDate.value;
    let merchantIds = this.f.selectedMerchnat.value;
    let selectedCompany = this.f.selectedCompany.value;
    let revenueType = this.f.selectedRevenueType.value;

    merchantIds = this.removeIdForAll(merchantIds);
    selectedCompany = this.removeIdForAll(selectedCompany);
    revenueType = this.removeIdForAll(revenueType);

    const payload = {
      CompanyIds: selectedCompany,
      MerchantIds: merchantIds,
      FromDate: this.fromDate,
      ToDate: this.toDate,
      RevenueTypes: revenueType
    }

    let params = {}

    const url = UrlBuilder.buildUrlWithParams(ConstantText.RevenueReportDownloadApi, params);

    this.dataService.postWithOptions(url, payload, {
      responseType: 'blob'
    })
      .subscribe((response: any) => {
        this.downloadFile(response);
      }, () => { console.log("Error Occurred") }, () => { console.log("Download Finished") });

  }

  downloadFile(data) {
    const blob2 = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var filename = "Revenue-Details-" + moment().format('MMMM Do YYYY, h:mm:ss').valueOf() + ".xlsx";
    saveAs(blob2, filename);
  }

  get f() {
    return this.queryGeneratorForm.controls;
  }

  handlePageChange(event: number): void {
    this.p = event;
    this.getData();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.p = 1;
    this.getData();
  }

  getCurrentRange() {
    this.paginationInfo = `Showing ${(this.p - 1) * this.pageSize + 1}-${(this.p - 1) * this.pageSize + this.giftResponse.length} of ${this.count}`;
  }

  getTypeValue(type: any) {
    if (type == 'Prepaid') return 1;
    if (type == 'Postpaid') return 2;
    if (type == 'Business solution') return 3;
    return 'All';
  }

  getFormData() {
    if (this.queryGeneratorForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      alert('Please provide Requered Field');
      return;
    }

    this.fromDate = this.f.fromDate.value;
    this.toDate = this.f.toDate.value.setDate(this.f.toDate.value.getData() + 1);
    let merchantIds = this.f.selectedMerchnat.value;
    let selectedCompany = this.f.selectedCompany.value;
    let revenueType = this.f.selectedRevenueType.value;

    merchantIds = this.removeIdForAll(merchantIds);
    selectedCompany = this.removeIdForAll(selectedCompany);
    revenueType = this.removeIdForAll(revenueType);

    const formDate = {
      CompanyIds: selectedCompany,
      MerchantIds: merchantIds,
      FromDate: this.fromDate,
      ToDate: this.toDate,
      RevenueTypes: revenueType
    }
    return formDate;
  }

  processInvoice() {
    const confirmationDialogConfig = new MatDialogConfig();
    var text = 'Are you sure want to process?';
    let obj = {
      confirmationMessage: text,
      title: 'Process Invoice',
      merchantList: this.selectOfferProvider,
      notifyCheckboxShow: false,
      showFileUploadSection: false,
      merchantOrCompanySelectOption: 'Select Company',
      invoice: true,
      dropdownListName: 'Companies'
    };
    confirmationDialogConfig.data = obj;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(
      DeliverGiftOrderComponent,
      confirmationDialogConfig
    );
    confirmationDialog.afterClosed().subscribe((result: FormData) => {
      try {
        const date = new Date(result.get('invoiceToDate').toString());

        date.setDate(date.getDate() + 1);

        const payload = {
          CompanyId: result.get("merchantId"),
          fromDate: moment(result.get('invoiceFromDate').toString()).format("YYYY/MM/DD"),
          toDate: moment(date).format("YYYY/MM/DD"),
          paymentDate: moment(result.get('paymentDate').toString()).format("YYYY/MM/DD"),
          invoiceNumber: result.get('invoiceReferance')
        }

        let url = 'api/billing/company-payment-invoice-update';
        this.dataService.post(url, payload).subscribe(
          (data: any) => {
            
          },
          catchError(() => {
            return throwError('Something went wrong!. Contact with concern person.');
          })
        );

      } catch (error) {
        console.log(error);
      }
    });
  }

}