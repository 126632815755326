import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MerchantAcceptableAvailMethodService {

  private baseUri = 'api/merchantacceptableavailmethods';

  constructor(
    private http: HttpClient
  ) { }


  getMerchantAcceptableAvailMethodByCompanyId(companyId: number): Observable<any[]> {
    return this.http.get<any[]>(this.baseUri + '/company/' + companyId);
  }
}
