import { Component, OnInit, EventEmitter, Inject, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { get } from 'http';
import { CompanyCreditModel } from '../../models/company-credit-model';
import { ValidationUtility } from '../../utility/validation.utility';
import { CompanyService, AccountServiceService } from '../../services';

@Component({
  selector: 'app-create-company-credit',
  templateUrl: './create-company-credit.component.html',
  styleUrls: ['./create-company-credit.component.css']
})
export class CreateCompanyCreditComponent implements OnInit {

  onCreditCreateEvent: EventEmitter<any> = new EventEmitter();
  onCreditEditEvent: EventEmitter<any> = new EventEmitter();

  companyCreditForm: FormGroup;
  submitted = false;
  companyCredit: CompanyCreditModel;
  companyId: number;
  isEdit = false;
  userInfo: any;

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateCompanyCreditComponent>,
    private companyServie: CompanyService,
    private accountService: AccountServiceService,
    @Inject(MAT_DIALOG_DATA) data,
    private injector: Injector
  ) {    
    this.companyCredit = new CompanyCreditModel();
    if (data != null) {
      if (isNaN(data)) {
        this.companyCredit = new CompanyCreditModel(data);
        this.companyId = this.companyCredit.companyId;
        this.isEdit = true;
      }
      else {
        this.isEdit = false;
        this.companyId = data;
      }
    }
  }

  ngOnInit() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
    this.isEdit = this.companyCredit.id != undefined;
    this.buildForm();
  }
  close() {
    this.dialogRef.close();
  }
  buildForm() {
    this.companyCreditForm = this.formBuilder.group({
      //id: [this.companyCredit.id],
      companyId: [this.companyCredit.companyId],
      amount: [this.companyCredit.amount ? this.companyCredit.amount : '', [Validators.required,Validators.min(1)]],
      //createdDate: [this.companyCredit.createdDate],
      createdBy: [this.companyCredit.createdBy],
      isActive: [this.companyCredit.isActive],
    });
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.companyCreditForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    if (this.companyCredit.id === undefined) { // create
      this.createCompanyCredit();
    }
    else if (this.companyCredit.id != undefined && this.companyCredit.id != null) { // edit
      this.editCompanyCredit();
    }
    this.dialogRef.close();
  }
  createCompanyCredit() {
    this.companyCredit = new CompanyCreditModel(this.companyCreditForm.value);
    if (this.companyCredit !== null) {
      this.companyCredit.companyId = this.companyId;
      this.companyCredit.isActive = true;
      this.companyCredit.createdBy = this.userInfo.id;
    }
    this.companyServie.saveCompanyCredit(this.companyCredit).subscribe((data: any) => {
      this.onCreditCreateEvent.emit(this.companyCredit.id);
    },
      (error: any) => {
        //this.showErrorMessage(error);
      })
    }
  editCompanyCredit() {
    var creditId = this.companyCredit.id;
    var createdDate = this.companyCredit.createdDate;
    this.companyCredit = new CompanyCreditModel(this.companyCreditForm.value);
    this.companyCredit.id = creditId;
    this.companyCredit.createdDate = createdDate;
    this.companyServie.saveCompanyCredit(this.companyCredit).subscribe((data: any) => {
      this.onCreditEditEvent.emit(this.companyCredit.id);
    },
      (error: any) => {
        //this.showErrorMessage(error);
      })
  }

  get f() { return this.companyCreditForm.controls; }

}
