import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AreaService {

  private areaBaseUri = 'api/area';

  constructor(
    private http: HttpClient
  ) { }

  getAreaByCity(cityId: number): Observable<any[]> {
    return this.http.get<any[]>(this.areaBaseUri + '/all-area/city/' + cityId);
  }
}
