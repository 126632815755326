import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-corporate-gift-reports',
  templateUrl: './corporate-gift-reports.component.html',
  styleUrls: ['./corporate-gift-reports.component.css']
})
export class CorporateGiftReportsComponent implements OnInit {

  corporateGiftTransactionReportsSubMenu: any[] = [];
  selectedSubMenuIndex = 0;
  isAdmin = false;
  activeSubMenu = 'Active Gifts';

  constructor() {
    this.setSubMenu();
  }

  ngOnInit() {
  }

  setSubMenu() {

    this.corporateGiftTransactionReportsSubMenu.push({ 'name': 'Active Gifts', 'dataTarget': 'activeCorporateGiftReportTab' });
    this.corporateGiftTransactionReportsSubMenu.push({ 'name': 'Inactive Gifts', 'dataTarget': 'inActiveCorporateGiftReportTab' });
    
  }

  onSubMenuChange(name, index) {
    if (this.selectedSubMenuIndex !== index) {
      this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
      this.activeSubMenu = this.corporateGiftTransactionReportsSubMenu.filter(c => c.name === name)[0].name;
    }
  }
}
