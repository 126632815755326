import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PromotionService {

  private promotionBaseUrl = 'api/product-promotion';
  private requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
  
  constructor(private http: HttpClient) { }

  getPromotions(): Observable<any[]> {
    return this.http.get<any[]>(this.promotionBaseUrl);
  }

  getPromotionById(id: string): Observable<any> {
    return this.http.get<any>(this.promotionBaseUrl + "/" + id);
  }

  getPromotionsPagedList(input: any): Observable<any> {
    return this.http.post<any[]>(`${this.promotionBaseUrl}/paged-list`, input, this.requestHeader);
  }

  activatePromotion(promotion: any){
    return this.http.put(`${this.promotionBaseUrl}/activate-promotion`, promotion, this.requestHeader);
  }

  deactivatePromotion(promotion: any){
    return this.http.put(`${this.promotionBaseUrl}/deactivate-promotion`, promotion, this.requestHeader);
  }

  createPromotion(promotion: any) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.post(this.promotionBaseUrl, promotion, requestHeader);
  }

  editPromotion(promotion: any) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.put(`${this.promotionBaseUrl}/${promotion.id}`, promotion, requestHeader);
  }

}
