import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { CompanyService, OfferAssignmentService, AlertService } from '../../services';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { Company } from '../../models';
import { ReplaySubject } from 'rxjs';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-select-merchant',
  templateUrl: './select-merchant.component.html',
  styleUrls: ['./select-merchant.component.css']
})
export class SelectMerchantComponent implements OnInit {
  onSaveMerchantEvent: EventEmitter<any> = new EventEmitter();

  merchantName: string;
  availInstructions: any[] = [];
  selectedMerchantId: number;
  merchantFilterCtrl: FormControl = new FormControl();
  merchants: Company[];
  filteredMerchants: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);
  submitted: boolean;
  selectMerchantForm: any;
  merchantId: any;
  merchantRawData: any;
  merchantAvailMethod: any = 0;

  offerAssignmentId: any;

  constructor(private dialogRef: MatDialogRef<SelectMerchantComponent>,
    private companyService: CompanyService, private formBuilder: FormBuilder,
    private dialog: MatDialog, private offerAssignmentService: OfferAssignmentService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) data) {
    if (data !== null) {
      this.offerAssignmentId = data.id;
    }
  }

  ngOnInit() {
    this.getMerchants();
    this.bindFilterChangeEvent();
    this.buildForm();
  }

  buildForm() {
    this.selectMerchantForm = this.formBuilder.group({
      merchantId: [this.merchantId, [Validators.required]],
    });
  }

  getMerchants() {
    this.companyService.getMerchants()
      .subscribe(data => {
        this.merchants = data;
        this.merchantRawData = data;
        this.filteredMerchants.next(this.merchants);
      });
  }


  onSubmit() {
    this.submitted = true;

    if (this.selectMerchantForm.invalid) {
      return;
    }
    this.showConfirmationAndSave();

  }


  showConfirmationAndSave() {
    const confirmationDialogConfig = new MatDialogConfig()
    let text = this.merchantAvailMethod === "3" ? "Your selected merchant is QR code enabled. To avail the gift you have to download & install Golp App. Want to proceed?"
      : "Are you sure to select this merchant for this gift?";

    let successMessage = "Gift merchant assigned successfully";

    confirmationDialogConfig.data = text;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);


    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {// yes

        //this.user = new UserModel(this.userForm.value);

        var obj = { giftAssignmentId: this.offerAssignmentId, merchantId: this.merchantId };


        this.offerAssignmentService.assignMerchant(obj).subscribe(result => {
          this.alertService.success(successMessage);
          this.onSaveMerchantEvent.emit();
        });

        this.dialogRef.close();

      }
      else {// no

      }

    });
  }



  onChangeMerchant(merchantId) {
    this.merchantId = merchantId;
    let selectedMerchant = this.merchantRawData.filter(c => c.id == merchantId);
    this.merchantAvailMethod = selectedMerchant[0].companySettings.MerchantAcceptanceAvailMethod;

  }


  bindFilterChangeEvent() {
    this.merchantFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterData(this.merchants, this.merchantFilterCtrl.value, this.filteredMerchants);
      });
  }

  filterData(allData: any, search: string, filterData: any) {
    if (!allData) {
      return;
    }
    if (!search) {
      filterData.next(allData);
      return;
    } else {
      search = search.toLowerCase();
    }
    filterData.next(
      allData.filter(data => data.name.toLowerCase().indexOf(search) > -1)
    );
  }

  close() {
    this.dialogRef.close();
  }
}
