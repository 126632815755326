import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inactive-consumer-gift',
  templateUrl: './inactive-consumer-gift.component.html',
  styleUrls: ['./inactive-consumer-gift.component.css']
})
export class InactiveConsumerGiftComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
