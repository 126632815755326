import { PaymentHolder } from "../enums/payment-holder";
import { PaymentMethod } from "../enums";

export class PaymentSession {
  amount: number;
  companyId: number;
  invoiceId: number;
  paymentHolder: PaymentHolder;
  payThrough: number;
  bulkCodeGenerate: boolean;
  public constructor(init?: Partial<PaymentSession>) {
    Object.assign(this, init);
  }
}
