import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-show-merchant-payment-info',
  templateUrl: './show-merchant-payment-info.component.html',
  styleUrls: ['./show-merchant-payment-info.component.css']
})
export class ShowMerchantPaymentInfoComponent implements OnInit {

  merchantName: string;
  paymentInfo: string;
  paymentInfoArray: any;
  paymentInfoFormattedString: string;

  constructor(private dialogRef: MatDialogRef<ShowMerchantPaymentInfoComponent>,
    @Inject(MAT_DIALOG_DATA) data) {

    if (data !== null) {
      this.merchantName = data.merchantName;
      this.paymentInfo = data.paymentInfo;
      this.paymentInfoArray = this.paymentInfo.split(",");

      console.log(data);
      console.log(data.qrCode);
    }
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
