import { Component, OnInit } from '@angular/core';
import { AccountServiceService } from '../../services';
import { CompanyRole } from '../../enums';

@Component({
  selector: 'app-merchant-logo-renderer',
  templateUrl: './merchant-logo-renderer.component.html',
  styleUrls: ['./merchant-logo-renderer.component.css']
})
export class MerchantLogoRendererComponent implements OnInit {
  userInfo: any;

  constructor(private accountService: AccountServiceService,) { }

  ngOnInit(): void {
    this.getLoggedInUser();
  }

  getLoggedInUser() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
  }
   
}
