import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyOutlet } from 'src/app/models/company-outlet';
import { CompanyOutletService } from 'src/app/services/company-outlet.service';
import { AreaService } from 'src/app/services/area.service';
import { CityService } from 'src/app/services/city.service';
import { AlertService } from 'src/app/services';
import { ReplaySubject } from 'rxjs';
import { ValidationUtility } from '../../utility/validation.utility';

@Component({
  selector: 'app-create-outlet',
  templateUrl: './create-outlet.component.html',
  styleUrls: ['./create-outlet.component.css']
})
export class CreateOutletComponent implements OnInit {

  // onCreate event
  onOutletCreateEvent: EventEmitter<number> = new EventEmitter();
  // onEdit event;
  onOutletEditEvent: EventEmitter<number> = new EventEmitter();
  
  outletForm: FormGroup
  submitted = false;
  selectedCity : number = 0;
  outlet: CompanyOutlet;
  companyId: number;
  cities: any[];
  areas: any[];

  cityFilterCtrl: FormControl = new FormControl();
  filteredCities: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  areaFilterCtrl: FormControl = new FormControl();
  filteredAreas: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateOutletComponent>,
    private outletService: CompanyOutletService,
    private areaService: AreaService,
    private cityService: CityService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) data) {
      
      this.outlet = new CompanyOutlet();

      if(isNaN(data)){ // coming from outlet list page
        console.log(new CompanyOutlet(data));
        this.outlet = new CompanyOutlet(data);
        this.selectedCity = this.outlet.cityId;
      }
      else{ // coming from merchant page
        this.companyId = data;
        this.outlet.companyId = this.companyId;
      }
      this.buildForm();
  }

  ngOnInit() {
    this.getCities();
    this.selectedCity = this.outlet.cityId;
    this.getAreas(this.selectedCity);
    this.bindFilterChangeEvent();
  }

  buildForm() {
    this.outletForm = this.formBuilder.group({
      name: [this.outlet.name, [Validators.required, Validators.maxLength(250)]],
      contactPerson: [this.outlet.contactPerson, [Validators.maxLength(150)]],
      contactNumber: [this.outlet.contactNumber, [Validators.required, Validators.maxLength(50)]],
      openFrom: [this.outlet.openFrom, [Validators.maxLength(50)]],
      openTo: [this.outlet.openTo, [Validators.maxLength(50)]],
      address: [this.outlet.address, [Validators.maxLength(250)]],
      selectedCity : [this.outlet.cityId, Validators.required],
      areaId: [this.outlet.areaId, [Validators.required]],
      isActive: [this.outlet.isActive]
    });
  }

  onChangeCity(cityId){
    if(cityId != null){
      this.areaService.getAreaByCity(cityId)
      .subscribe((data: any) => {
        this.areas = data;
        this.filteredAreas.next(this.areas.slice());
        this.outletForm.controls['areaId'].setValue(null);
      });
    }
  }

  getAreas(cityId) {
    if(cityId != null) { 
      this.areaService.getAreaByCity(cityId)
      .subscribe((data: any) => {
        this.areas = data;
        this.filteredAreas.next(this.areas);
      });
    }
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.outletForm.invalid) {
      ValidationUtility.getError_Scroll(document);
       return;
    }
    if(this.outlet.id === undefined){
      this.createOutlet();
    }
    else if(this.outlet.id > 0){
      this.editOutlet();
    }
    this.dialogRef.close();
  }

  createOutlet(){
    this.outlet = new CompanyOutlet(this.outletForm.value);
    if(this.outlet !== null){
      this.outlet.companyId = this.companyId;
    }
    this.outletService.createOutlet(this.outlet)
        .subscribe((data: any) =>{
          this.onOutletCreateEvent.emit(this.outlet.companyId);
          this.alertService.success("Outlet added successfully");
        },
        (error: any) => {
          this.showErrorMessage(error);
        });
  }

  editOutlet(){
    let newData = new CompanyOutlet(this.outletForm.value);
    if(this.outlet !== null){
      this.outlet.name = newData.name;
      this.outlet.contactPerson = newData.contactPerson;
      this.outlet.contactNumber = newData.contactNumber;
      this.outlet.openFrom = newData.openFrom;
      this.outlet.openTo = newData.openTo;
      this.outlet.areaId = newData.areaId;
      this.outlet.address= newData.address;
      this.outlet.isActive = newData.isActive;

      this.outletService.editOutlet(this.outlet)
          .subscribe((data: any) =>{
            this.onOutletEditEvent.emit(this.outlet.companyId);
            this.alertService.success("Outlet edited successfully");
          },
          (error: any) => {
            this.showErrorMessage(error);
          });

    }
  }
  
  getCities(){
    this.cityService.getCities()
      .subscribe((data: any) => {
        this.cities = data;
        this.filteredCities.next(this.cities);
      });
  }

  bindFilterChangeEvent() {
    this.cityFilterCtrl.valueChanges.subscribe(() => {
      this.filterCities();
    });

    this.areaFilterCtrl.valueChanges.subscribe(() => {
      this.filterAreas();
    });
  }

  filterCities() {
    if (!this.cities) {
      return;
    }
    let search = this.cityFilterCtrl.value;
    if (!search) {
       this.filteredCities.next(this.cities);
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredCities.next(
      this.cities.filter(city => city.name.toLowerCase().indexOf(search) > -1)
    );
  }

  filterAreas() {
    if (!this.areas) {
      return;
    }
    let search = this.areaFilterCtrl.value;
    if (!search) {
       this.filteredAreas.next(this.areas);
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredAreas.next(
      this.areas.filter(area => area.name.toLowerCase().indexOf(search) > -1)
    );
  }

  showErrorMessage(error: any){
    console.log(error);
    //this.alertService.error("Internal server error happen");
  }

  close() {
    this.dialogRef.close();
  }

  // convenience getter for easy access to form fields
  get f() { return this.outletForm.controls; }

}
