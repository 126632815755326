import { OfferOutlet } from "./offer-outlet";
import { PaymentMethod, OfferType, PayableAmountMode, PaymentOption } from "../enums";
import { offerconsumedstatus } from "../enums/offer-consumed-status";

export class Offer {
  id: number;
  name: string;
  offerShortName: string
  offerCode: string;
  description: string;
  isActive: boolean;
  isScheduled: boolean = false;
  maximumConsumer: number;
  isOutletSpecific: boolean;
  offerProviderId: number;
  merchantId: number;
  amount: number;
  offerProvider: string;
  merchant: string;
  merchantDeliveryMethod: string;
  ownOffer: boolean;
  offerPrice: number;
  discountedPrice: number;
  paymentOption: PaymentOption;
  payableAmount: number;
  payableAmountMode: PayableAmountMode;
  offerOutlets: OfferOutlet[];
  selected: false;
  offerType: OfferType;
  startDate: string;
  endDate: string;
  offerScope: string;
  offerProviderLogoUrl: string;
  merchantLogoUrl: string;
  offerStatus: string;
  isRejected: boolean;
  rejectionReason: string;
  imageName: string;
  offerImageUrl: string;
  offerProviderPaymentMethod: PaymentMethod;
  isMultiTimeAssignable: boolean;
  assignmentPerUser: number;
  referenceOfferId: number;
  isShareable: boolean;
  offerConsumedStatus: offerconsumedstatus;
  offerConsumedStatusText: string;
  occasionId: number;

  orderNo: number;
  public constructor(init?: Partial<Offer>) {
    Object.assign(this, init);
  }
}
