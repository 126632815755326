import { NewBillSettingsKeyComponent } from './components/bill-settings/bill-settings-key/new-bill-settings-key/new-bill-settings-key.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingComponent } from './components/billing/billing.component';
import { BillSettingsComponent } from './components/bill-settings/bill-settings.component';
import { BillSettingsKeyComponent } from './components/bill-settings/bill-settings-key/bill-settings-key.component';
import { BillSettingsTabComponent } from './components/bill-settings/bill-settings-tab/bill-settings-tab.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgSelect2Module } from 'ng-select2';
import { MaterialModule } from '../material';
import { NewBillSettingComponent } from './components/bill-settings/bill-settings-tab/new-bill-setting/new-bill-setting.component';
import { BillProcessComponent } from './components/bill-process/bill-process.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { BillInvoiceComponent } from './components/bill-invoice/bill-invoice.component';
import { BillInvoiceDetailsComponent } from './components/bill-invoice/bill-invoice-details/bill-invoice-details.component';
import { NewBillProcessComponent } from './components/bill-process/new-bill-process/new-bill-process.component';
import { BillClaimComponent } from './components/bill-claim/bill-claim.component';



@NgModule({
  declarations: [
    BillingComponent,
    BillSettingsComponent,
    BillSettingsKeyComponent,
    BillSettingsTabComponent,
    NewBillSettingComponent,
    NewBillSettingsKeyComponent,
    BillProcessComponent,
    BillInvoiceComponent,
    BillInvoiceDetailsComponent,
    NewBillProcessComponent,
    BillClaimComponent
  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FilterPipeModule,
    NgxMaterialTimepickerModule,
    NgSelect2Module,
    AngularMultiSelectModule
  ],
  entryComponents: [
    NewBillSettingComponent,
    NewBillSettingsKeyComponent,
    NewBillProcessComponent,
    BillInvoiceDetailsComponent,
    BillClaimComponent
  ],
  exports: [
    BillingComponent,
    BillSettingsComponent,
    BillSettingsKeyComponent,
    BillSettingsTabComponent,
    NewBillSettingComponent,
    NewBillSettingsKeyComponent
  ]
})
export class BillingModule { }
