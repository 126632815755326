import { DataService } from './../../../../components/app-send-gift/services/shared/data.service';
import { AlertService } from './../../../../services/alert.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { Select2OptionData } from 'ng-select2';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValidationUtility } from 'src/app/utility/validation.utility';

@Component({
  selector: 'app-new-bill-process',
  templateUrl: './new-bill-process.component.html',
  styleUrls: ['./new-bill-process.component.css'],
})
export class NewBillProcessComponent implements OnInit {
  submitted: boolean;
  newBillForm: FormGroup;
  select2CompanyKeyInfo: Array<Select2OptionData>;

  //date
  defaultStartDate: Date = new Date();
  defaultEndDate: Date = new Date();

  //form-validation
  companyIdNotSelected = false;
  companyId :any = "-1";

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<NewBillProcessComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private alertService: AlertService,
    private dataService: DataService
  ) {
    var startDate1 = new Date();
    this.defaultStartDate = this.startOfMonth(startDate1);
    this.defaultEndDate = new Date();
    if (data !== null) {
      this.select2CompanyKeyInfo = data;
    }
    this.buildForm();
  }

  startOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  ngOnInit(): void {}

  buildForm() {
    this.newBillForm = this.formBuilder.group({
      fromDate: [this.defaultStartDate, Validators.required],
      toDate: [this.defaultEndDate, Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.newBillForm.controls;
  }

  close() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.newBillForm.invalid) {
      // ValidationUtility.getError_Scroll(document);
      return;
    }

    if (Number(this.companyId) < 0) {
      this.companyIdNotSelected = true;
      return;
    }

    //communicate with server
    let url = 'api/Bills/generate-bill';
    let payload = {
      companyId: Number(this.companyId),
      fromDate: this.newBillForm.value.fromDate,
      toDate: this.newBillForm.value.toDate,
    };
    this.dataService.post(url, payload).subscribe(
      (res: any) => {
        if (res.result) {
          this.alertService.success('Bill generated successfully.');
          this.close();
        } else {
          this.alertService.error(res.message);
        }
      },
      (err) => console.log(err)
    );
  }
}
