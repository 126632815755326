import { Component, OnInit } from '@angular/core';
import { CommonService, GiftAvailService, AlertService } from 'src/app/services';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { AcceptAvailRequestComponent } from '../accept-avail-request/accept-avail-request.component';
import { AcceptRequestModel } from 'src/app/models';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-pending-avail-request',
  templateUrl: './pending-avail-request.component.html',
  styleUrls: ['./pending-avail-request.component.css']
})
export class PendingAvailRequestComponent implements OnInit {

  pendingAvailRequests: any[];
  userInfo: any;
  qrcode: string;
  noPendingRequest: boolean = true;

  constructor(private commonService: CommonService, private giftAvailService: GiftAvailService,
    private alertService: AlertService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.userInfo =  JSON.parse(localStorage.getItem('userInfo'));
    this.qrcode = this.userInfo.outletQrCode;
    this.getPendingAvailRequestItem(this.qrcode);
    this.commonService.backgroundJobEventReceiver()
        .subscribe((data) =>{
          if(data.jobType == 'AvailRequest'){
            this.getPendingAvailRequestItem(this.qrcode);
            this.alertService.success("New avail request arrive");
          }
        },
        (error) =>{
          this.alertService.error(error.error);
        })
  }

  refreshPendingList(){
    this.getPendingAvailRequestItem(this.qrcode);
  }

  acceptRequest(requestDetails: any){
    const acceptDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    acceptDialogConfig.disableClose = true;
    acceptDialogConfig.autoFocus = true;

    acceptDialogConfig.data = requestDetails
    acceptDialogConfig.panelClass = 'golp-dialog';

    const acceptRequestDialog = this.dialog.open(AcceptAvailRequestComponent, acceptDialogConfig);

    const onSuccess = acceptRequestDialog.componentInstance.onAcceptRequestEvent.subscribe((data) => {
      this.getPendingAvailRequestItem(this.qrcode);
    });

    acceptRequestDialog.afterClosed().subscribe(() => {
    });
  }

  onDeclineRequest(requestModel: AcceptRequestModel): void{
    const confirmationDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    confirmationDialogConfig.data = "Are you sure to decline the request from  " + requestModel.consumerPhoneNumber;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if(result != undefined){
        this.declineAvailRequest(requestModel);
      }
    });
  }

  getPendingAvailRequestItem(qrCode: string){
    if(qrCode != null){
      this.giftAvailService.getPendingAvailRequest(qrCode)
      .subscribe((data) => {
        this.pendingAvailRequests = data;
        this.noPendingRequest = (this.pendingAvailRequests == null || this.pendingAvailRequests.length == 0) ? true: false;
      });
    }
  }

  declineAvailRequest(requestModel: AcceptRequestModel){
    this.giftAvailService.declineAvailRequest(requestModel)
      .subscribe((data) => {
        this.getPendingAvailRequestItem(this.qrcode);
        this.alertService.success("Request Declined Successfully");
      },
      (error) => {
        this.alertService.error(error.error);
      });
  }

}
