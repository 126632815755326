import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  apiBaseUrl: any;
  constructor(private http: HttpClient) {
    this.apiBaseUrl = environment.apiUrl;
  }

  // this function returns an observable of type T in the form of JSON
  // the advantage of an observable : if it changes then this will fire off the change detection as the DOM has changed
  public get<T>(url: string): Observable<T> {
    return this.http.get<T>(url);
  }

  public getForText(url: string): Observable<any> {
    return this.http.get(url, { responseType: 'text' });
  }

  public getForBlob(url: string): Observable<any> {
    return this.http.get(url, { responseType: 'blob' });
  }

  public put<T>(url: string, itemToUpdate): Observable<T> {
    return this.http.put<T>(url, itemToUpdate);
  }

  public post<T>(url: string, body: any): Observable<T> {
    return this.http.post<T>(url, body);
  }

  public postWithOptions<T>(url: string, body: any, options: any): any {
    return this.http.post<T>(url, body, options);
  }

  public patch<T>(url: string, body: any): Observable<T> {
    return this.http.patch<T>(url, body);
  }

  public delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(url);
  }

  public postBolb(url: string, body: any): Observable<any> {
    return this.http.post(url, body, {
      observe: 'response',
      responseType: 'blob'
    });
  }
}
