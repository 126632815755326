import { AccountServiceService } from './../../../../services/account-service.service';
import { DataService } from './../../../../components/app-send-gift/services/shared/data.service';
import { Component, OnInit, SimpleChanges } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-user-privilege-card',
  templateUrl: './user-privilege-card.component.html',
  styleUrls: ['./user-privilege-card.component.css']
})
export class UserPrivilegeCardComponent implements OnInit {

  item: any;
  apiBaseUri: any;
  receivedData: any;
  constructor(private router: Router, private dataService: DataService, private accoutnService: AccountServiceService) {
    this.loadCards();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  itemClicked(item: any) {
    let cardFacilities = item.cardFacilities;
    let card = {
      "cardFacilities": cardFacilities,
      "cardDetails": item
    }
    let navigationEgolps: NavigationExtras = {
      state: {
        transId: 'cardFacilities',
        workQueue: false,
        services: 1,
        code: card,
      }
    };

    this.router.navigate(['privilege-card/card-facilities'], navigationEgolps);
  }

  loadCards() {
    let phone = this.accoutnService.getLoggedInUserInfo().phoneNumber;

    let url = `api/CardBeneficiary/my-card/${phone}`;
    this.dataService.get(url).subscribe((response: any) => {
      console.log("my cards : ", response);
      this.receivedData = response;
    }, err => console.log(err));
  }


  makeProperCase(txt) {
    return txt.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
  };


}




