import { ThrowStmt } from '@angular/compiler';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as $ from 'jquery';
@Component({
  selector: 'app-bill-invoice-details',
  templateUrl: './bill-invoice-details.component.html',
  styleUrls: ['./bill-invoice-details.component.css']
})
export class BillInvoiceDetailsComponent implements OnInit {

  //bill information
  billDetails: any;
  billing: any;
  company: any;

  constructor(private dialogRef: MatDialogRef<BillInvoiceDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) data,) {
    this.billDetails = data;
    if (this.billDetails != undefined && this.billDetails != null) {
      this.billing = this.billDetails.billing;
      this.company = this.billDetails.company;
    }
  }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    printInvoice();
  }

  close() {
    this.dialogRef.close();
  }

}

function printInvoice() {
  $('#printInvoice').click(function () {
    Popup($('#invoice-details')[0]);
    function Popup(data) {
      window.print();
      return true;
    }
  });
}
// $('#printInvoice').click(function(){
//   Popup($('.invoice')[0].outerHTML);
//   function Popup(data) 
//   {
//       window.print();
//       return true;
//   }
// });

