import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CouponService {
  private couponBaseUri = "api/coupon";

  constructor(private http: HttpClient) {}

  getCouponDetails(merchantId: any, coupon: any, phone: any): Observable<any> {
    return this.http.get<any>(
      `${this.couponBaseUri}/verify-coupon/merchant/${merchantId}/coupon/${coupon}/phone/${phone}`
    );
  }

  processDelivery(giftDetails: any) {
    let requestHeader = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "No-Auth": "False",
      }),
    };
    return this.http.post(
      this.couponBaseUri + "/process-gift-delivery",
      giftDetails,
      requestHeader
    );
  }
}
