import { AlertService } from 'src/app/services/alert.service';
import { DataService } from 'src/app/components/app-send-gift/services/shared/data.service';
import { map } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-send-offer-preview',
  templateUrl: './send-offer-preview.component.html',
  styleUrls: ['./send-offer-preview.component.css']
})
export class SendOfferPreviewComponent implements OnInit {

  offers: any;
  offerUsers: any;

  constructor(private router: Router, private dialog: MatDialog, private dataService: DataService, private alertService: AlertService) {
    let navigation = this.router.getCurrentNavigation();
    let state = navigation.extras.state as {
      transId: string,
      workQueue: boolean,
      services: number,
      code: any
    };

    if (state != undefined && state.code != undefined) {
      this.offers = state.code.offers;
      this.offerUsers = state.code.offerUsers;
    }
  }

  ngOnInit(): void {

  }

  back() {
    this.router.navigate(['gift-offer']);
  }

  sendOffer() {
    const confirmationDialogConfig = new MatDialogConfig();
    // Setting different dialog configurations
    confirmationDialogConfig.data = `Would you like to proceed? This operation can not be undone.`;
    confirmationDialogConfig.panelClass = "golp-dialog";
    const confirmationDialog = this.dialog.open(
      ConfirmationDialogComponent,
      confirmationDialogConfig
    );

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.proceedSendOffer();
      }
    });
  }

  proceedSendOffer() {
    let url = `/api/CorporateOffers/multi-offer-assignment`;
    let payload = {
      "offerTemplateIds": this.offers.map(m => m.id),
      "phoneNumbers": [...this.offerUsers.values()]
    }

    this.dataService.post(url, payload)
      .subscribe((response: any) => {
        console.log("response : ", response);
        if (response.success) {
          this.alertService.success("Offer sent successfully");
        }
        else {
          this.alertService.error("Offer sending failed");
        }
      }, err => { console.log("Err msg: ", err) });
  }

}
