import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { BillingService } from 'src/app/services/billing.service';
import { Invoice, Company, CompanyOutlet } from 'src/app/models';
import { CompanyRole } from 'src/app/enums/company-role.enum';
import * as moment from 'moment';
import { CompanyOutletService, DataService } from 'src/app/services';
import { CompanySettingService } from '../../services/company-setting.service';
import { CompanySettingType } from '../../enums/company-setting.type';
import { PaymentOnOutletOrCentrallyType } from '../../enums/PaymentOnOutletOrCentrally-type';
import { ReplaySubject } from 'rxjs';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
    selector: 'app-create-invoice',
    templateUrl: './create-invoice.component.html',
    styleUrls: ['./create-invoice.component.css']
})
export class CreateInvoiceComponent implements OnInit {
    onInvoiceCreateEvent: EventEmitter<number> = new EventEmitter();
    invoiceCreationForm: FormGroup;
    invoice: Invoice;
    offers: any[];
    offersWithoutFilter: any[];
    selectedCompany: Company;
    isMerchant: boolean;
    subTotal: number;
    submitted = false;
    inEditMode: boolean = false;
    outletQRCode?: string;
    isOutletSpecific: boolean = false;
    companyOutlets: CompanyOutlet[];
    selectedOutlet: string;
    disableSelectOutlet: boolean = false;

    outletFilterCtrl: FormControl = new FormControl();
    filteredOutlets: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    fromDate: any;
    toDate: any;

    selectedDateRange: { startDate: moment.Moment, endDate: moment.Moment };
    ranges: any = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }

    constructor(
        private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<CreateInvoiceComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private companySettingService: CompanySettingService,
        private outletService: CompanyOutletService,
        private billingService: BillingService,
        private dataService: DataService,
        private dialog: MatDialog) {
        this.invoice = new Invoice();

        this.subTotal = 0;
        this.invoice.discountAmount = 0;
        this.invoice.amount = 0;
        this.selectedDateRange = {
            startDate: moment().subtract(29, 'days'),
            endDate: moment()
        }
        if (data !== null) {
            this.selectedCompany = data.selectedCompany;
            this.isMerchant = this.selectedCompany.companyRole === CompanyRole.Merchant;
            this.offers = data.offers;
            this.outletQRCode = data.outletQRCode;
            this.offersWithoutFilter = data.offers;
        }
    }

    ngOnInit() {
        this.invoiceCreationForm = this.formBuilder.group({
            subTotal: this.subTotal,
            discountAmount: new FormControl({ value: this.invoice.discountAmount, disabled: true }),
            total: this.invoice.amount,
            dueDate: [new Date()],
            selectedOutletDDL: '',
            fromDate: this.fromDate,
            toDate: this.toDate
        });
        this.invoiceCreationForm.controls['discountAmount'].valueChanges.subscribe(value => {
            this.invoice.discountAmount = value;
            this.invoice.amount = this.subTotal - value;
            this.invoiceCreationForm.controls['total'].setValue(this.invoice.amount);
        });
        this.getOutletsByCompany(this.selectedCompany.id);

        this.companySettingService.getSettingsByCompanyAndType(CompanySettingType.PaymentOnOutletOrCentrally, this.selectedCompany.id).subscribe(result => {
            if (result && !!result.isEnabled && +result.value == PaymentOnOutletOrCentrallyType.OutletSpecific && this.isMerchant) {
                this.isOutletSpecific = true;
                this.bindOutletFilterChangeEvent();
            }
        });
    }

    onChangeSelectedOutlet() {
        this.getOffersBySelection();
    }

    getOffersBySelection() {
        this.offers = this.offersWithoutFilter;

        if (this.selectedOutlet)
            this.offers = this.offersWithoutFilter.filter(x => x.outletQrCode == this.selectedOutlet);
        var from = moment(this.f.fromDate.value);
        var to = moment(this.f.toDate.value).add(1, "days").add(-1, "seconds");

        if (this.isMerchant) {
            if (this.f.fromDate.value && this.f.toDate.value) {
                this.offers = this.offers.filter(x => moment(x.availDate).isBetween(from, to, 'days', '[]'));
            }
            else if (this.f.fromDate.value) {
                this.offers = this.offers.filter(x => moment(x.availDate).isSameOrAfter(from, 'day'));
            }
            else if (this.f.toDate.value) {
                this.offers = this.offers.filter(x => moment(x.availDate).isSameOrBefore(to, 'day'));
            }
        }
        else {
            if (this.f.fromDate.value && this.f.toDate.value) {
                this.offers = this.offers.filter(x => moment(x.offerCreationDate).isBetween(from, to));
            }
            else if (this.f.fromDate.value) {
                this.offers = this.offers.filter(x => moment(x.offerCreationDate).isSameOrAfter(from));
            }
            else if (this.f.toDate.value) {
                this.offers = this.offers.filter(x => moment(x.offerCreationDate).isSameOrBefore(to));
            }
        }
    }

    bindOutletFilterChangeEvent() {
        this.outletFilterCtrl.valueChanges
            .subscribe(() => {
                this.filterOutlets();
                this.setSelectedOutlet();
            });
    }

    protected filterOutlets() {
        if (!this.companyOutlets) {
            return;
        }
        // get the search keyword
        let search = this.outletFilterCtrl.value;
        if (!search) {
            this.filteredOutlets.next(this.companyOutlets);
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the CompanyCategories
        this.filteredOutlets.next(
            this.companyOutlets.filter(role => role.name.toLowerCase().indexOf(search) > -1)
        );
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.invoiceCreationForm.invalid) {
            return;
        }
        this.invoice.companyId = this.selectedCompany.id;
        if (this.invoice.amount <= 0) {
            const confirmationDialogConfig = new MatDialogConfig()

            // Setting different dialog configurations
            confirmationDialogConfig.data = "Please select at least one bill";
            confirmationDialogConfig.panelClass = 'golp-dialog';

            const confirmationDialog = this.dialog.open(AlertDialogComponent, confirmationDialogConfig);

            confirmationDialog.afterClosed().subscribe((result) => {
            });

            return;
        }
        this.invoice.dueDate = this.invoiceCreationForm.value.dueDate;
        if (this.selectedCompany.companyRole === CompanyRole.GiftSender) {
            var offerIds = this.offers.reduce((a, o) => {
                o.selected && a.push(o.giftTemplateId);
                return a;
            }, []);
            this.invoice.offerIds = offerIds;
            this.billingService.createInvoiceForOfferProvider(this.invoice)
                .subscribe((data: any) => {
                    this.onInvoiceCreateEvent.emit(this.invoice.companyId);
                    console.log(data);
                },
                    (error: any) => {
                        console.log(error);
                    });
        }
        else {

            var offerAssignmentIds = this.offers.reduce((a, o) => {
                o.selected && a.push(o.giftId);
                return a;
            }, []);
            this.invoice.offerAssignmentIds = offerAssignmentIds;
            if (this.outletQRCode)
                this.invoice.outletQRCode = this.outletQRCode;
            else if (this.isOutletSpecific)
                this.invoice.outletQRCode = this.selectedOutlet;

            this.billingService.createInvoiceForMerchant(this.invoice)
                .subscribe((data: any) => {
                    this.onInvoiceCreateEvent.emit(this.invoice.companyId);
                    console.log(data);
                },
                    (error: any) => {
                        console.log(error);
                    });
        }

        this.dialogRef.close();
    }
    choosedDate(event) {
        if (event.startDate != null) {
            this.selectedDateRange = event;
            this.offers = this.offersWithoutFilter.filter(x => moment(x.offerConsumedDate).isBetween(event.startDate, event.endDate));
        }
    }

    close() {
        this.dialogRef.close();
    }

    getOutletsByCompany(companyId) {
        if (companyId !== undefined) {
            this.outletService.getOutletsByCompany(companyId)
                .subscribe((data: any) => {
                    this.companyOutlets = data;
                    this.filteredOutlets.next(this.companyOutlets);

                    this.setSelectedOutlet();
                },
                    (error: any) => {
                        console.log(error);
                    });
        }
    }

    setSelectedOutlet() {

        if (!this.outletQRCode && this.isOutletSpecific) {
            if (this.companyOutlets.length == 1)
                this.selectedOutlet = this.companyOutlets[0].qrCode;
        }
        else if (this.outletQRCode) {
            this.selectedOutlet = this.outletQRCode;
            this.disableSelectOutlet = true;
        }
        this.invoiceCreationForm.controls['selectedOutletDDL'].setValue(this.selectedOutlet);
        this.onChangeSelectedOutlet();
    }

    calculateTotal() {
        this.subTotal = this.offers.filter((offer) => offer.selected)
            .reduce((sum, offer) => {
                return sum + offer.payableAmount;
            },
                0);

        this.invoice.amount = this.subTotal - this.invoice.discountAmount;
        this.invoiceCreationForm.controls['subTotal'].setValue(this.subTotal.toFixed(2));
        this.invoiceCreationForm.controls['total'].setValue(this.invoice.amount.toFixed(2));
    }

    // convenience getter for easy access to form fields
    get f() { return this.invoiceCreationForm.controls; }

    selection(event, i, item: any) {
        let selected = event.checked;
        item.selected = selected;
        this.calculateTotal();
    }

    onChangeSelectAll(event) {
        if (event.checked) {
            this.offers.forEach(obj => {
                obj.selected = true;
            });
        }
        else {
            this.offers.forEach(obj => {
                obj.selected = false;
            });
        }
        this.calculateTotal();
    }
}
