import { Component, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { TransactionSummary, AvailOfferTransaction } from 'src/app/models';
import { AccountServiceService, GiftAvailService, CommonService } from 'src/app/services';
import { MerchantOutletResponse } from '../app-send-gift/models/view-models/merchant-outlets-response';
import { DataService } from '../app-send-gift/services/shared/data.service';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-completed-transaction',
  templateUrl: './completed-transaction.component.html',
  styleUrls: ['./completed-transaction.component.css']
})

export class CompletedTransactionComponent implements OnInit {

  userInfo: any;
  summary: TransactionSummary;
  transactions: AvailOfferTransaction[];
  formatString = 'YYYY-MM-DD';
  fromDate = moment().format();
  toDate = moment().format();
  fromDateTime: any;
  toDateTime: any;
  invoiceNo: string;
  phoneNo: string;
  merchantOutlets: any;
  selectedMerchant: any;
  nullValue = null;
  p: number = 1;
  excel: any = [];
  input: FormData;
  constructor(
    private accountService: AccountServiceService,
    private giftAvailService: GiftAvailService,
    private commonService: CommonService,
    private dataService: DataService,
    private http: HttpClient
  ) {

  }

  ngOnInit() {
    this.getUserInfo();
    this.fromDateTime = this.fromDate;
    this.toDateTime = this.toDate;
    this.commonService.backgroundJobEventReceiver()
      .subscribe((data) => {
        if (data.jobType == "RequestAccept") {
          this.getTransactions(this.fromDate, this.toDate);
        }
      }, (error) => {
        console.log(error);
      });
    this.getSelectedMerchantOutlets();
    this.getTransactions(this.fromDate, this.toDate);
  }
  getUserInfo() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
  }
  
  getTransactions(from?: any, to?: any) {
    if (this.merchantOutlets != null && this.merchantOutlets.length > 0) {
      this.userInfo.outletQrCode = this.selectedMerchant?.qrCode;
    }
    let fromDateTimeUtc = moment(this.fromDateTime).format(this.formatString);
    let toDateTimeUtc = moment(this.toDateTime).format(this.formatString);
    this.giftAvailService.getCompletedAvailTransaction(Number(this.userInfo.companyId), this.userInfo.outletQrCode,
      fromDateTimeUtc, toDateTimeUtc, this.invoiceNo, this.phoneNo)
      .subscribe(data => {
        console.log("response : ", data);
        this.summary = (data.summary && data.summary.length > 0) ? data.summary[0] : new TransactionSummary();
        this.transactions = data.availOfferTransactions;
        this.p = 1;
      });
  }

  downloadTransactions() {
    this.getTransactions(); // load the data as well when download
    const merchantId = this.userInfo.companyId;
    this.input = new FormData();

    this.input.append('merchantId', merchantId.toString());
    this.input.append('fromDate', moment(this.fromDateTime).format("YYYY/MM/DD"));
    this.input.append('toDate', moment(this.toDateTime).format("YYYY/MM/DD"));

    if(this.phoneNo) {
      this.input.append('phoneNumber', this.phoneNo);
    }
    
    if(this.invoiceNo) {
      this.input.append('invoiceNumber', this.invoiceNo.trim());
      console.log(this.invoiceNo.trim());
    }
    
    if(this.userInfo.outletQrCode) {
      this.input.append('outlate', this.userInfo.outletQrCode);
    }

    return this.getMerchantTransactionReport(this.input)
      .subscribe(data => this.downloadFile(data)),
      error => console.log("Error downloading the file."),
      () => console.log('Completed file download.');
  }

  downloadFile(data) {
    const blob2 = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var filename = 'AvailedGiftTransaction '+ moment().format('MMMM Do YYYY, h:mm:ss').valueOf() +  ".xlsx";
    saveAs(blob2, filename);
  }

  getMerchantTransactionReport(input: any): any {
    let url = `api/report/marchent-transaction/download`;
    return this.http.post(url, input, {
      responseType: 'blob'
    });
  }
  
  getSelectedMerchantOutlets() {
    if (this.userInfo.outletQrCode == null && this.userInfo.companyId != null) {
      let url = `api/Outlets/company/${this.userInfo.companyId}`;
      this.dataService.get(url).subscribe((data: MerchantOutletResponse[]) => {
        if (data != null && data.length > 0) {
          this.merchantOutlets = data;
        }
      });
    }
  }
  changeFromDate(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      let _ = moment();
      this.fromDateTime = moment(this.fromDate).add({ hours: _.hour(), minutes: _.minute(), seconds: _.second() });
      // this.getTransactions();
    }
  }

  changeToDate(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      let _ = moment();
      this.toDateTime = moment(this.toDate).add({ hours: _.hour(), minutes: _.minute(), seconds: _.second() });
      // this.getTransactions();
    }
  }

  // onInvoiceKeyUp($event){
  //   this.getTransactions();
  // }

  // onConsumerKeyUp($event){
  //   this.getTransactions();
  // }

}
