import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-show-more-or-less',
  templateUrl: './show-more-or-less.component.html',
  styleUrls: ['./show-more-or-less.component.css']
})
export class ShowMoreOrLessComponent implements OnInit {
  @Input() text: string;
  @Input() defaultMaxShownChar: number = 0;

  showMore: boolean = true;
  showFullText : boolean = false;

  constructor() { }

  ngOnInit() {
    if (this.text.length <= this.defaultMaxShownChar) {
      this.showFullText = true;
    }
    else {
      this.showFullText = false;
    }
  }

  showAllText() {
    this.showFullText = true;
    this.showMore = false;
  }
  showLessText() {
    this.showFullText = false;
    this.showMore = true;
  }

}
