import { Component, OnInit, Injector } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { CreateOfferComponent } from '../create-offer/create-offer.component';
import { Offer, GetOffersPagedListInput } from 'src/app/models';
import { CompanyRole } from 'src/app/enums';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { OfferService, CompanyService, AccountServiceService, AlertService, CommonService, GolpProductService } from 'src/app/services';
import { BaseComponent } from '../base/base.component';
import { CreateGolpProductComponent } from '../create-golp-product/create-golp-product.component';

@Component({
  selector: 'app-golp-products',
  templateUrl: './golp-products.component.html',
  styleUrls: ['./golp-products.component.css']
})
export class GolpProductsComponent extends BaseComponent implements OnInit {

  userInfo: any;
  dataLoaded: Promise<boolean>;

  // permission related property

  //showAssignmentTab: boolean = true;

  currentPage: number = 1;
  pageSize: number = 10;
  itemCountText: string;
  totalItems: number;

  pageTitle: string = "Golp Products";
  merchantSelection: string = "Merchant";

  offers: any[];
  offersPrimitiveList: any[];
  offerFilter: any = { name: '' };
  giftFilterText: string;

  selectedOffer: Offer;
  selectedOfferName: string;
  selectedOfferIndex: number = -1;

  merchants: any[];
  merchantFilter: any = { name: '' };
  searchedMerchantLogo: string = null;
  showMerchantSearch: boolean = true;
  selectedMerchantId: number = 0;

  offerSubMenu: any[] = [];
  activeSubMenu: string = 'Details';
  selectedSubMenuIndex: number = 0;

  constructor(private offerService: OfferService,
    private golpProductService: GolpProductService,
    private dialog: MatDialog,
    private companyService: CompanyService,
    private accountService: AccountServiceService,
    private alertService: AlertService,
    private commonService: CommonService,
    injector: Injector
  ) { super(injector) }

  ngOnInit() {
    //if (this.checkPermission(['SystemAdmin']))
    this.offerSubMenu.push({ "name": "Details", "dataTarget": "offerDetailsTab" });

    this.getLoggedInUser();
    this.getGolpProducts();
    this.getMerchants();
    this.dataLoaded = Promise.resolve(true);
  }

  createOffer() {
    const dialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'golp-dialog';
    const offerCreateDialog = this.dialog.open(CreateGolpProductComponent, dialogConfig);

    const successfullCreate = offerCreateDialog.componentInstance.onOfferCreateEvent.subscribe((data) => {
      this.getGolpProducts();
    });

    offerCreateDialog.afterClosed().subscribe(() => {
    });
  }

  onDeleteOffer(selectedOffer: Offer): void {
    const confirmationDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    confirmationDialogConfig.data = "Are you sure to delete the Golp product " + selectedOffer.name;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationDialogConfig);

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.deleteOffer(selectedOffer);
      }
    });
  }

  activateGolpProduct(product: Offer) {
    product.isActive = true;
    this.offerService.editOffer(product)
      .subscribe((data: any) => {
        this.selectedOffer.isActive = true;
        this.selectedOffer.offerStatus = "Active";
        this.alertService.success('Golp Product activated successfully');
      });
  }

  deActivateGolpProduct(product: Offer) {
    product.isActive = false;
    this.offerService.editOffer(product)
      .subscribe((data: any) => {
        this.selectedOffer.isActive = false;
        this.selectedOffer.offerStatus = "Inactive";
        this.alertService.success('Golp Product deactivated successfully');
      });
  }

  onOfferChange(offer, index) {
    if (this.selectedOfferIndex != index) {
      this.selectedOfferIndex = index === this.selectedOfferIndex ? -1 : index;
      this.selectedOffer = offer;
      this.setOutletScopeText();
    }
    let body = document.getElementsByTagName('body')[0];
    body.classList.add("display-tab-content");
    this.commonService.itemSelectionUpdated(this.selectedOffer);
  }



  selectAllMerchant() {
    this.searchedMerchantLogo = null;
    this.merchantSelection = 'Merchant';
    this.selectedMerchantId = 0;
    //this.getGolpProducts();
  }


  onMerchantChange(merchant, index) {
    this.selectedMerchantId = merchant.id;
    this.searchedMerchantLogo = merchant.companyLogoUrl;
    this.merchantSelection = merchant.name.split(' ').slice(0, 2).join(' ');

    //this.getGolpProducts();
  }

  onSubMenuChange(name, index) {
    if (this.selectedSubMenuIndex != index) {
      this.selectedSubMenuIndex = index === this.selectedSubMenuIndex ? -1 : index;
      this.activeSubMenu = this.offerSubMenu.filter(c => c.name === name)[0].name;
    }
  }

  // capture child component change event
  getOfferDetailsChange(event) {
    //let offer = this.offers.filter(c => c.id === event.id)[0];
    ////offer.offerImageUrl = event.offerImageUrl;
    //offer.name = event.name;
    //offer.offerPrice = event.offerShortName;
    //offer.offerPrice = event.offerPrice;
    //offer.orderNo = event.orderNo;
    //offer.offerScope = event.offerScope; 
    //offer.offerStatus = event.offerStatus;
    //offer.giftOccasion = event.giftOccasion;
    ////offer.occasionId = event.occasionId;
    //offer.description = event.description;
    //this.selectedOffer = offer;

    //this.setOutletScopeText();
    this.getGolpProducts();
  }

  giftFilteredItem(value) {
    this.selectedOfferName = value;
    this.getGolpProducts();
  }

  getGolpProducts() {
    var input = new GetOffersPagedListInput();

    if (this.selectedMerchantId == 0)
      input.merchantId = null;
    else
      input.merchantId = this.selectedMerchantId;

    input.giftName = this.selectedOfferName;
    input.pageNumber = this.currentPage;
    input.pageSize = this.pageSize;


    this.golpProductService.getGolpProductsPagedList(input)
      .subscribe(data => {
        //this.offers = data;
        this.offersPrimitiveList = data.viewModel;
        //this.offers = data.viewModel;
        this.giftCopy();
        this.totalItems = data.rowCount;

        if (CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
          this.offers = this.offers.filter(c => c.merchantId === this.userInfo.companyId);
          //this.pageTitle = 'Golp Products';
        }

        this.highlightFirstItem(this.offers);
        this.itemCount();
      });
  }

  getGolpProductById(id: number) {
    this.golpProductService.getGolpProductById(id).subscribe((data) => {
      this.offersPrimitiveList.unshift(data);
      this.offers.unshift(data);
      this.highlightFirstItem(this.offers);
      this.setOutletScopeText();
    });
  }

  getMerchants() {
    this.companyService.getMerchantBasics()
      .subscribe(data => {
        this.merchants = data;
      });
  }


  deleteOffer(offer: Offer) {
    this.offerService.deleteOffer(offer.id).subscribe((data) => {
      this.alertService.success('Golp Product deleted successfully');

      if (data == true) { // will reload data if only this item is deleted
        this.getGolpProducts();
      }
    },
      (error) => {
        this.alertService.error(error.error.error[0]);
        console.log(error);
      });
  }

  getLoggedInUser() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
  }

  highlightFirstItem(offers: any[]) {
    if (this.offers !== null) {
      this.selectedOffer = this.offers[0];
      this.setOutletScopeText();
      this.selectedOfferIndex = 0;
      this.dataLoaded = Promise.resolve(true);
    }
  }

  giftCopy() {
    this.offers = Object.assign([], this.offersPrimitiveList);
  }

  pageChanged(event) {
    this.selectedOfferIndex = 0;
    this.currentPage = event;
    this.getGolpProducts();
    this.setOutletScopeText();
  }

  itemCount() {
    let firstItem = (this.currentPage - 1) * this.pageSize + 1;
    let lastItem = (this.currentPage) * this.pageSize;
    if (lastItem > this.totalItems)
      lastItem = this.totalItems;
    this.itemCountText = firstItem + '-' + lastItem + ' of ' + this.totalItems + ' Gifts';
  }

  setSelectedOffer(offers) {
    if (offers.length > 0) {
      this.selectedOfferIndex = 0;
      this.selectedOffer = offers[0];
      this.setOutletScopeText();
    }
    else {
      this.selectedOffer = null;
    }
  }

  setOutletScopeText() {
    this.selectedOffer.offerScope = this.selectedOffer.isOutletSpecific ? 'Selected Outlets' : 'All Outlets';
  }

}
