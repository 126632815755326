export class LoyaltyPointSetting {
  minRedeemablePoint: any;
  defaultExpireMonth: any;
  pointType: any;
  point: any;
  money: any;
  public constructor(init?: Partial<LoyaltyPointSetting>) {
    Object.assign(this, init);
  }
}
