import { Component, HostListener, Renderer2 } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { TabModel } from './models';
import { GolpAppUtil } from './utility/golp-app-utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  showHeader: boolean = false;
  showFooter: boolean = false;

  constructor(private router: Router, private renderer: Renderer2) {

    let token = localStorage.getItem('userToken');
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'] === '/login' || (event['url'] === '/' && token == null)) {
          this.renderer.addClass(document.body, 'layout-sidebar-login');
          this.renderer.removeClass(document.body, 'layout-sidebar');
          this.showHeader = false;
          this.showFooter = false;
        }
        else if (event['url'].startsWith('/payment-option/consumer')) {
          this.showHeader = false;
          this.showFooter = false;
        }
        else {
          this.showHeader = true;
          this.showFooter = true;
          this.renderer.addClass(document.body, 'layout-sidebar');
          this.renderer.removeClass(document.body, 'layout-sidebar-login');
        }
      }
    });
  }

  @HostListener("window:beforeunload", ["$event"])
  clearLocalStorage(event) {
    const sessionTabId = sessionStorage.getItem(GolpAppUtil.TAB_ID);
    const localTabId: TabModel[] = JSON.parse(localStorage.getItem(GolpAppUtil.TABS));
    if (localTabId != null || localTabId != undefined) {
      localTabId.find(item => item.tabId == +sessionTabId).status = false;
      localStorage.setItem(GolpAppUtil.TABS, JSON.stringify(localTabId));
    }
  }
}
