import { Component, EventEmitter, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/services';
import { ValidationUtility } from 'src/app/utility/validation.utility';
import { CreateOfferInformationalConditionComponent } from '../create-offer-informational-condition/create-offer-informational-condition.component';
import { CorporateOfferService } from '../../services/corporate-offer.service';

@Component({
  selector: 'app-create-corporate-offer-condition',
  templateUrl: './create-corporate-offer-condition.component.html',
  styleUrls: ['./create-corporate-offer-condition.component.css']
})

export class CreateCorporateOfferConditionComponent implements OnInit {

  // view childs
  @ViewChild(CreateOfferInformationalConditionComponent) private informationalComponent: CreateOfferInformationalConditionComponent;

  // onCreate event
  onCorporateOfferConditionCreateEvent: EventEmitter<any> = new EventEmitter();
  // onEdit event;
  onCorporateOfferConditionEditEvent: EventEmitter<any> = new EventEmitter();

  corporateOfferCondition: any[];

  informationalConditions: any[] = [];
  informationalCondition: any;
  offerTemplateId: any;
  offerConditionId: any;

  offerConditionForm: FormGroup
  submitted = false;

  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateCorporateOfferConditionComponent>,
    private alertService: AlertService,
    private offerService: CorporateOfferService,
    @Inject(MAT_DIALOG_DATA) data) {

    if (isNaN(data) && data != null) { // coming from offerCondition list page
      this.corporateOfferCondition = data?.offerConditions;
      this.offerTemplateId = data?.offerTemplateId;
      this.informationalCondition = this.corporateOfferCondition.filter(c => c.conditionType == "Informational")[0]
      this.offerConditionId = this.informationalCondition != undefined ? this.informationalCondition.offerConditionId : undefined;
    }
    else { // coming from merchant page

    }
    this.buildForm();
  }

  ngOnInit() {
  }

  buildForm() {

  }


  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    // if (this.offerConditionForm.invalid) {
    //   ValidationUtility.getError_Scroll(document);
    //   return;
    // }

    if (this.offerConditionId === undefined) {
      this.createofferCondition();
    }
    else if (this.offerConditionId !== undefined) {
      this.editofferCondition();
    }
  };

  createofferCondition() {
    let condition = this.informationalComponent.setKeyValuesJsonAndEmit();
    let offerTemplateConditions = {
      'corporateOfferTemplateId': this.offerTemplateId,
      'isActive': true,
      'informationalConditions': { 'conditionTexts': condition }
    };

    this.offerService.createOfferCondition(offerTemplateConditions)
      .subscribe((response: any) => {
        this.dialogRef.close();
        this.onCorporateOfferConditionCreateEvent.emit(this.offerTemplateId);
      });
  }

  editofferCondition() {
    let condition = this.informationalComponent.setKeyValuesJsonAndEmit();
    let offerTemplateConditions = {
      'corporateOfferId': this.offerTemplateId,
      'isActive': true,
      'OfferConditionId': this.offerConditionId,
      'conditionType': 2,
      'conditionText': condition
    };

    this.offerService.editOfferCondition(offerTemplateConditions)
      .subscribe((response: any) => {
        this.dialogRef.close();
        this.onCorporateOfferConditionCreateEvent.emit(this.offerTemplateId);
      });
  }

  showErrorMessage(error: any) {
    console.log(error);
    //this.alertService.error("Internal server error happen");
  }

  close() {
    this.dialogRef.close();
  }

  // convenience getter for easy access to form fields
  get f() { return this.offerConditionForm.controls; }

  setValuesByInformationalCondition($event): void {
    this.informationalConditions.push($event);
  }

  setValuesByOperationalCondition($event): void {
    // Json converted previously
    // this.availInstructions = $event;
    // this.availInstructionsSettingJsonValue = JSON.stringify($event);
    // this.companySetting.value = this.availInstructionsSettingJsonValue;
  }

}
