import { Component, EventEmitter, Injector, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AccountServiceService, CommonService } from 'src/app/services';
import { DataService } from '../../app-send-gift/services/shared/data.service';
import { BaseComponent } from '../../base/base.component';
import { CreateProductComponent } from '../create-product/create-product.component';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent extends BaseComponent implements OnInit {

  @Input() productDetailsInput: any;
  @Output() productDetailsInputChange: EventEmitter<any> = new EventEmitter<any>();

  productDetails: any;
  userInfo: any;
  detailsLoaded: Promise<boolean>;

  canEdit: boolean = true;
  showHideAcceptButton: boolean = true;
  PaymentMethodsDisplay: { [index: number]: string } = {};

  constructor(public dialog: MatDialog, private accountService: AccountServiceService,
    private commonService: CommonService,
    private dataService: DataService,
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.getLoggedInUser();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['productDetailsInput']) {
      setTimeout(() => {
        this.productDetails = this.productDetailsInput;
        this.makeSomeDelay(this.productDetails);
      });
    }
  }

  editProduct(product: any) {

    const editDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    editDialogConfig.disableClose = true;
    editDialogConfig.autoFocus = true;
    editDialogConfig.panelClass = 'golp-dialog';

    editDialogConfig.data = product

    var offerEditDialog = this.dialog.open(CreateProductComponent, editDialogConfig);

    offerEditDialog.componentInstance.onProductEditEvent.subscribe((data) => {
      this.productDetailsInputChange.emit(data);
    });

    offerEditDialog.afterClosed().subscribe(() => {
    });
  }

  makeSomeDelay(product: any) {
    if (product != null) {
      this.detailsLoaded = Promise.resolve(true);
    }
  }

  getLoggedInUser() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
  }

}
