import { Component, OnInit } from '@angular/core';
import { LoginModel } from 'src/app/models/login-model';
import { AccountServiceService } from 'src/app/services/account-service.service';
import { Router } from '@angular/router';
import { CompanyRole } from 'src/app/enums/company-role.enum';
import { LoginApp } from 'src/app/enums/login-app';
//import { Accountkit } from '../../services/account.kit';
import { LoginMethod } from '../../enums/LoginMethod';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isLoginError: boolean = false
  loginModel: LoginModel;

  constructor(private accountService: AccountServiceService,
    private router: Router) {
  }
  accountkit: any;
  ngOnInit() {
  }

  OnSubmit(userName, passwrod) {
    this.loginModel = new LoginModel(userName, passwrod);
    this.loginModel.loginApp = LoginApp.Web;
    if (this.isValidPhone(userName)) {
      this.loginModel.loginMethod = LoginMethod.Pin;
    }
    this.accountService.userAuthentication(this.loginModel)
      .subscribe((data: any) => {
        let userRole = data.userInfo.roles[0].name;
        localStorage.setItem('userToken', data.token);
        localStorage.setItem('userRole', userRole);
        localStorage.setItem('_sub', JSON.stringify(data.subscriptions));
        localStorage.setItem('_menus', JSON.stringify(data.menus))
        localStorage.setItem('_availableFormats', JSON.stringify(data.availableFormats))
        localStorage.setItem('_bulkCodeGenerateSetting', JSON.stringify(data.bulkCodeGenerateRes));
        localStorage.setItem("_walletSummary", data.walletSummary);

        localStorage.setItem('userInfo', JSON.stringify(data.userInfo));
        localStorage.setItem('userRolePermissions', JSON.stringify(this.getPermissionsArray(data.userInfo.permissions, 4)));

        this.accountService.storeBrowserTabId();

        if ((userRole === 'OutletAdmin' || userRole === 'MerchantAdmin') && data.subscriptions?.giftCard) {
          this.router.navigate(['/dashboard']);
        }
        else if ((userRole === 'OutletUser') && data.subscriptions?.giftCard) {
          this.router.navigate(['/avail-process']);
        }
        else if ((userRole === 'ReportViewer') && data.subscriptions?.giftCard) {
          this.router.navigate(['/gift-sell-report']);
        }
        else if ((userRole === 'OutletAdmin' || userRole === 'MerchantAdmin')) {
          this.router.navigate(['/avail-process']);
        }
        else if (userRole === 'ServiceConsumer') {
          this.router.navigate(['/receive-gifts']);
        }
        else if (userRole === 'SystemAdmin' || userRole === 'ExternalSalesUser') {
          this.router.navigate(['reports']);
        }
        else if (userRole === 'ResellerAdmin') {
          this.router.navigate(['/manage-orders']);
        }
        else {
          this.router.navigate(['/gifts']);
        }
      },
        (error: any) => {
          this.isLoginError = true;
        })
  }

  consumerLogin(userInfo) {
    this.accountService.consumerAuthentication(userInfo)
      .subscribe((result: any) => {
        let userRole = result.data.roles[0].name;
        localStorage.setItem('userToken', result.token);
        localStorage.setItem('userRole', userRole);

        localStorage.setItem('userInfo', JSON.stringify(result.data));
        localStorage.setItem('userRolePermissions', JSON.stringify(this.getPermissionsArray(result.data.permissions, 4)));

        if (userRole === 'ServiceConsumer') {
          this.router.navigate(['/send-gifts']);
        }
      },
        (error: any) => {
          this.isLoginError = true;
        })
  }

  isValidPhone(phoneNumber) {
    var found = phoneNumber.search(/^(\+{1}\d{2,3}\s?[(]{1}\d{1,3}[)]{1}\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}$/);
    if (found > -1) {
      return true;
    }
    else {
      return false;
    }
  }

  getPermissionsArray(input: string, length: number) {

    var permission = input.replace("H4-", '');

    var size = Math.ceil(permission.length / length),
      ret = new Array(size),
      offset;

    for (var i = 0; i < size; i++) {
      offset = i * length;
      ret[i] = permission.substring(offset, offset + length);
    }

    return ret;
  }
}
