import { Component, ElementRef, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { MerchantOutletResponse } from 'src/app/models/view-models/merchant-outlet-response';
import { DataService } from '../app-send-gift/services/shared/data.service';
import * as XLSX from 'xlsx';
import { FileUploadService } from 'src/app/services';

type AOA = any[][];

@Component({
  selector: 'app-business-bulk-gift',
  templateUrl: './business-bulk-gift.component.html',
  styleUrls: ['./business-bulk-gift.component.css']
})
export class BusinessBulkGiftComponent implements OnInit {

  giftInfoForm: FormGroup;
  merchantOutlets: any;
  submitted: boolean = false;
  giftInformation: any;
  merchantId: any;
  companyOutletId: any;
  showOutlet: boolean = true;
  selectedOutlet: any;
  outletValidator: any;
  isPaperGiftCard: boolean = false;
  isSerialVerified: boolean = false;
  checkSerialVerification: boolean = true;
  isAmountFieldDisabled: boolean = false;
  giftAmount: number;
  salesAmount: number;
  isSingleGift: boolean = false;
  giftValidityPeriod: number;
  externalInvoiceNumber: string;
  seller: string;

  fileName: string;
  uploadedFiles: FormData = new FormData();;
  showFileError: boolean = false;
  merchant: any;
  showOutletError: boolean = false;

  files: any;

  data: any;
  headData: any

  xlLoading: boolean = false;


  constructor(public dialog: MatDialog, private fileUploadService: FileUploadService, private formBuilder: FormBuilder, private router: Router, private dataService: DataService, private elRef: ElementRef) {
    let navigation = this.router.getCurrentNavigation();
    let state = navigation.extras.state as {
      transId: string,
      workQueue: boolean,
      services: number,
      code: any
    };

    if (state != undefined && state.code != undefined) {
      this.giftInformation = state.code;
      // console.log("Gift information : " , this.giftInformation);
      if (state.transId != undefined && state.transId != null && state.transId == "giftInformation") {
        this.isPaperGiftCard = this.giftInformation?.isPaperGiftCard;
        this.isSerialVerified = this.giftInformation?.isSerialVerified;
        this.isAmountFieldDisabled = this.giftInformation?.isAmountFieldDisabled;
        this.merchantOutlets = this.giftInformation?.merchantOutlets;
        this.salesAmount = this.giftInformation?.salesAmount;
        this.giftValidityPeriod = this.giftInformation?.giftValidityPeriod;
        this.externalInvoiceNumber = this.giftInformation?.externalInvoiceNumber;
        this.seller = this.giftInformation?.seller;
      }

      this.giftAmount = this.giftInformation?.amount;
      // if(this.giftAmount != undefined && this.giftAmount != undefined) {
      //   this.salesAmount = this.giftAmount;
      // }
      this.isAmountFieldDisabled = this.giftInformation?.isPopularGift;
    }
    this.setUserInfo();
    this.buildFormAndData();

    if (state != undefined && state.code != undefined) {
      this.giftInfoForm.controls['amount'].setValue(this.giftAmount);
      if (this.isAmountFieldDisabled)
        this.giftInfoForm.controls['amount'].disable();
    }
  }
  setUserInfo() {
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.merchantId = userInfo.companyId;
    this.companyOutletId = userInfo.outletQrCode;
    if (this.companyOutletId == undefined || this.companyOutletId == null) {
      this.companyOutletId = this.giftInformation?.outletId;
      this.selectedOutlet = this.companyOutletId;
      this.showOutlet = true;
      this.outletValidator = Validators.required;
    }
    else {
      this.showOutlet = false;
      this.selectedOutlet = this.companyOutletId;
      this.outletValidator = Validators.nullValidator;
    }
  }

  toggleGift(i) {
    this.isSingleGift = i;
  }

  buildFormAndData() {
    this.giftInfoForm = this.formBuilder.group({
      sender: [this.giftInformation?.sender, Validators.required],
      receiver: [this.giftInformation?.receiver, Validators.required],
      amount: [this.giftAmount, [Validators.required, amountCheck(50)]],
      salesAmount: [this.salesAmount, [Validators.nullValidator]],
      giftValidityPeriod: [this.giftValidityPeriod, [Validators.required, validDaysCheck()]],
      externalInvoiceNumber: [this.externalInvoiceNumber, [Validators.required]],
      seller: [this.seller, [Validators.required]],
      outletId: [this.selectedOutlet, this.outletValidator],
      remarks: [this.giftInformation?.remarks, Validators.nullValidator],
    });
  }

  ngOnInit() {

    // console.log("gift information for person : " , this.companyOutletId, this.merchantId);
    if (this.companyOutletId == null && this.merchantId != null)
      if (this.merchantOutlets == null || this.merchantOutlets.length <= 0)
        this.getMerchantOutlet();

  }

  ngAfterViewInit(): void {

  }
  get f() { return this.giftInfoForm.controls; }

  setSelectedOutlet() {
    this.selectedOutlet = this.merchantOutlets.find(m => m.id == this.companyOutletId);
    if (this.selectedOutlet != null || this.selectedOutlet != undefined)
      this.giftInfoForm = this.formBuilder.group({
        sender: [this.giftInformation?.sender, Validators.required],
        receiver: [this.giftInformation?.receiver, Validators.required],
        amount: [this.giftAmount, [Validators.required, amountCheck(50)]],
        salesAmount: [this.salesAmount, Validators.nullValidator],
        giftValidityPeriod: [this.giftValidityPeriod, [Validators.required, validDaysCheck()]],
        externalInvoiceNumber: [this.externalInvoiceNumber, [Validators.required]],
        seller: [this.seller, [Validators.required]],
        outletId: [this.selectedOutlet, Validators.nullValidator],
        remarks: [this.giftInformation?.remarks, Validators.nullValidator],
      });
  }

  giftAmountTouched($event) {
    this.isSerialVerified = false;
  }

  onSubmit() {

    this.submitted = true;
    this.checkSerialVerification = true;

    // console.log("form : ", this.giftInfoForm, " is invalid ",this.giftInfoForm.invalid , "is paper gift ", this.isPaperGiftCard , "serial verified : ", this.isSerialVerified)
    if (this.giftInfoForm.invalid || (this.isPaperGiftCard && !this.isSerialVerified)) {
      return;
    }
    this.submitted = false;
    let giftInfo = this.getGiftInfo(this.giftInfoForm.value)
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.giftInfoForm.value))
    let navigationEgolps: NavigationExtras = {
      state: {
        transId: 'bulkGiftInformation',
        workQueue: false,
        services: 1,
        code: giftInfo,
      }
    };

    this.router.navigate(['send-gift-confirmation'], navigationEgolps);
  }



  getGiftInfo(data) {
    console.log("input amount : ", data.amount);
    if (this.isPaperGiftCard) {
      return {
        "sender": data.sender,
        "receiver": data.receiver,
        "amount": this.giftAmount,
        "salesAmount": this.salesAmount,
        "remarks": data.remarks,
        "outletId": this.showOutlet ? data.outletId : this.selectedOutlet,
        "showOutlet": this.showOutlet,
        "isPaperGiftCard": this.isPaperGiftCard,
        "isSerialVerified": this.isSerialVerified,
        "isAmountFieldDisabled": this.isAmountFieldDisabled,
        "merchantOutlets": this.merchantOutlets,
        "giftValidityPeriod": data.giftValidityPeriod,
        "externalInvoiceNumber": data.externalInvoiceNumber,
        "seller": data.seller,
        "isBulkGift": true
      }
    }
    else if (this.giftInformation?.isPopularGift) {
      return {
        "sender": data.sender,
        "receiver": data.receiver,
        "amount": this.giftAmount,
        "salesAmount": data.salesAmount,
        "remarks": data.remarks,
        "outletId": this.showOutlet ? data.outletId : this.selectedOutlet,
        "showOutlet": this.showOutlet,
        "isPaperGiftCard": this.isPaperGiftCard,
        "isSerialVerified": this.isSerialVerified,
        "isAmountFieldDisabled": this.isAmountFieldDisabled,
        "merchantOutlets": this.merchantOutlets,
        "isPopularGift": this.giftInformation?.isPopularGift,
        "giftValidityPeriod": data.giftValidityPeriod,
        "externalInvoiceNumber": data.externalInvoiceNumber,
        "seller": data.seller,
        "isBulkGift": true
      }
    }
    return {
      "sender": data.sender,
      "receiver": data.receiver,
      "amount": data.amount,
      "salesAmount": data.salesAmount,
      "remarks": data.remarks,
      "outletId": this.showOutlet ? data.outletId : this.selectedOutlet,
      "showOutlet": this.showOutlet,
      "isPaperGiftCard": this.isPaperGiftCard,
      "isSerialVerified": this.isSerialVerified,
      "isAmountFieldDisabled": this.isAmountFieldDisabled,
      "merchantOutlets": this.merchantOutlets,
      "giftValidityPeriod": data.giftValidityPeriod,
      "externalInvoiceNumber": data.externalInvoiceNumber,
      "seller": data.seller,
      "isBulkGift": true

    }
  }


  getMerchantOutlet() {
    let url = `api/Outlets/company/${this.merchantId}/`;
    // if(this.companyOutletId != null)
    //   url += `${this.companyOutletId}`
    this.dataService.get(url).subscribe((data: MerchantOutletResponse[]) => {
      if (data != null && data.length > 0) {
        this.merchantOutlets = data;
        this.setSelectedOutlet();
      }
    });
  }


  downloadDemo() {
    window.open(`/api/giftcard/DownloadBulkReportDemo`, '_blank');
  }

  onUploadPhoto(files) {
    this.xlLoading = true;
    let fileInfo = this.fileUploadService.anyFileUpload(files, "dataFile");
    if (fileInfo.validationError != null) {
      alert(fileInfo.validationError);
      return;
    }
    this.fileName = fileInfo.fileName;
    // this.uploadedFiles = fileInfo.formData;
    this.files = files;
    this.uploadedFiles.append("bulkGiftCardFile", files[0]);
    // var reader = new FileReader();
    // reader.onload = (event: any) => {
    //     this.imagePreviewPath = event.target.result;
    // }
    // reader.readAsDataURL(files[0]);
    // this.isNewImageSelected = true;
    //this.onFileChange(files);
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    this.xlLoading = true;
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, range: 0 }));

      this.headData = this.data[0];
      this.data = this.data.slice(1); // remove first header record

      const ws2: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[1]];
      this.readDataSheet(ws2, 10);
      this.xlLoading = false;
    };
    reader.readAsBinaryString(target.files[0]);
    this.onUploadPhoto(target.files);

  }

  private readDataSheet(ws: XLSX.WorkSheet, startRow: number) {
    /* save data */
    let datas = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, range: startRow }));
    console.log(datas[1]);
    let headDatas = datas[0];
    datas = datas.slice(1); // remove first header record

    for (let i = 0; i < this.data.length; i++) {
      this.data[i][this.headData.length] = datas.filter(x => x[12] == this.data[i][0])
    }
  }

  export(): void {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  gotoXlPreview() {
    let navigationEgolps: NavigationExtras = {
      state: {
        transd: 'xl-info',
        workQueue: false,
        services: 1,
        code: {
          "headData": this.headData,
          "data": this.data,
          "merchantId": this.merchantId,
          "merchantOutletId": this.companyOutletId,
          "file": this.files[0]
        },
      }
    };
    this.router.navigate(['upload-xl-preview'], navigationEgolps);

  }

  previewAndUploadButtonClicked() {
    let errorOccurred = false;
    if (this.files == null) {
      this.showFileError = true;
      errorOccurred = true;
    }
    else this.showFileError = false;

    if (this.companyOutletId == null) {
      this.showOutletError = true;
      errorOccurred = true;
    }
    else this.showOutletError = false;

    if (errorOccurred) return;

    // let payload = {
    //   "bulkGiftCardFile": this.uploadedFiles,
    //   "merchantId" : this.merchantId,
    //   "merchantOutletId" : this.companyOutletId
    // }
    this.uploadedFiles.append("merchantId", this.merchantId)
    this.uploadedFiles.append("merchantOutletId", this.companyOutletId)
    this.gotoXlPreview();
    // return this.dataService.post("api/giftcard/send-bulk-gift", this.uploadedFiles).subscribe(res=>{
    //   this.close();
    //   console.log(res);
    //   let navigationEgolps: NavigationEgolps = {
    //     state: {
    //       transd: 'bulkResponse',
    //       workQueue: false,
    //       services: 1,
    //       code: res,
    //     }
    // };

    // this.router.navigate(['upload-amount-result'], navigationEgolps);
    // }, err=>{});

  }


}


export function amountCheck(value: number) {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const input = Number(control.value);
    return input < value ? { amountError: { value: control.value } } : null;
  };
}

export function validDaysCheck() {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const input = Number(control.value);
    return (input < 1 || input > 365) ? { validDaysError: { value: control.value } } : null;
  };
}