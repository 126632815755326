import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { GiftAssignmentDtos } from '../../models/gift-transacion-report';
import * as moment from 'moment';

@Component({
  selector: 'app-show-offer-assignment-details',
  templateUrl: './show-offer-assignment-details.component.html',
  styleUrls: ['./show-offer-assignment-details.component.css']
})
export class ShowOfferAssignmentDetailsComponent implements OnInit {

  offerAssignmentDtos: GiftAssignmentDtos[] = [];

  days = 7; // Days you want to subtract
  todate = new Date();
  sevenDaysAgoDate = new Date(this.todate.getTime() - (this.days * 24 * 60 * 60 * 1000));


  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ShowOfferAssignmentDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) data) {

    if (isNaN(data)) { // coming from user list page


      this.offerAssignmentDtos = data as GiftAssignmentDtos[];

      for (let dto of this.offerAssignmentDtos) {
        var createdDate = new Date(dto.createdDate);
        if (createdDate > this.sevenDaysAgoDate) {
          dto.isNewGift = true;
        }
        else {
          dto.isNewGift = false;
        }
      }
    }
    else { // coming from other page

    }

  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
