import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { CompanyRole, UserType } from '../../enums';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { UserModel, CompanyOutlet, ServiceConsumer } from '../../models';
import { ReplaySubject } from 'rxjs';
import { AccountServiceService, AlertService, CompanyOutletService, FileUploadService, RolesService } from '../../services';
import { ServiceConsumersService } from '../../services/service-consumers.service';
import { RegistrationValidator } from '../create-user/create-user.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-create-premium-user',
    templateUrl: './create-premium-user.component.html',
    styleUrls: ['./create-premium-user.component.css']
})

export class CreatePremiumUserComponent implements OnInit {

    // onCreate event
    onUserCreateEvent: EventEmitter<number> = new EventEmitter();
    // onEdit event;
    onUserEditEvent: EventEmitter<any> = new EventEmitter();

    companyRoleType: CompanyRole = CompanyRole.None;


    userForm: FormGroup
    submitted = false;
    user: UserModel;
    companyId: number;
    roles: any[];
    uploadedFiles: FormData;
    inEditMode: boolean = false;

    fileName: string = '';
    imagePreviewPath: string = '../../../assets/images/default.png';
    fileValidationError: string;
    showUserScopeSecion: boolean = false;
    showOutlets: boolean = false;
    userScopes: any;
    companyOutlets: CompanyOutlet[];

    roleFilterCtrl: FormControl = new FormControl();
    userScopeFilterCtrl: FormControl = new FormControl();
    outletFilterCtrl: FormControl = new FormControl();
    filteredRoles: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    filteredUserScopes: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    filteredOutlets: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

    constructor(private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<CreatePremiumUserComponent>,
        private accountService: AccountServiceService,
        private alertService: AlertService,
        private fileUploadService: FileUploadService,
        private roleService: RolesService,
        private serviceConsumerService: ServiceConsumersService,
        @Inject(MAT_DIALOG_DATA) data) {

        this.user = new UserModel();



        if (isNaN(data)) { // coming from user list page
            this.user = new UserModel(data);

            this.inEditMode = true;
            this.companyRoleType = this.user.companyRoleType;
            this.companyId = this.user.companyId;
        }
        else { // coming from other page
            //this.companyId = data.companyId;
            if (data && data.companyId) {
                this.companyId = data.companyId;
            }
            this.user.companyId = this.companyId;
            this.user.userType = UserType.Premium;

            this.companyRoleType = this.user.companyRoleType;
        }
        if (this.user.profileImageUrl != null) {
            this.imagePreviewPath = this.user.profileImageUrl;
            this.fileName = this.user.profileImageName;
        }
        this.buildForm();
    }

    ngOnInit() {
        this.getRoles();
    }

    buildForm() {
        this.userForm = this.formBuilder.group({
            email: [this.user.email, [Validators.email]],
            firstName: [this.user.firstName, [Validators.required, Validators.maxLength(30)]],
            lastName: [this.user.lastName, [Validators.required, Validators.maxLength(30)]],
            phoneNumber: [this.user.phoneNumber, [Validators.required, Validators.maxLength(50)]],
            profileImageName: this.user.profileImageName,
            roleId: [this.user.roleId, [Validators.required]],
        });
    }

    onUploadPhoto(files) {
        this.fileValidationError = null;
        let fileInfo = this.fileUploadService.imageFileUpload(files);
        if (fileInfo.validationError != null) {
            this.fileValidationError = fileInfo.validationError;
            return;
        }
        this.fileName = fileInfo.fileName;
        this.uploadedFiles = fileInfo.formData;

        var reader = new FileReader();
        reader.onload = (event: any) => {
            this.imagePreviewPath = event.target.result;
        }
        reader.readAsDataURL(files[0]);
    }

    onSubmit() {
        this.submitted = true;

        this.findInvalidControls();

        if (this.userForm.invalid) {
            return;
        }
        if (this.user.id === undefined || this.user.id == 0) {
            this.createUser();
        }
        else if (this.user.id > 0) {
            this.editUser();
        }
        this.dialogRef.close();
    }

    public findInvalidControls() {
        const invalid = [];
        const controls = this.userForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        return invalid;
    }


    createUser() {
        var serviceConsumer = new ServiceConsumer(this.userForm.value);

        this.serviceConsumerService.createPremiumUser(serviceConsumer)
            .subscribe((result: any) => {
                // upload company logo
                if (this.uploadedFiles != null) {
                    this.uploadedFiles.append('id', result.data.id)
                    this.accountService.uploadFile(this.uploadedFiles).subscribe((result: any) => {
                    },
                        (error: any) => {
                        });
                }
                this.onUserCreateEvent.emit(this.user.companyId);
                this.alertService.success("User added successfully");
            },
                (error: any) => {

                    if (error && error.error && error.error.Message) {
                        this.alertService.error(error.error.Message);
                    }
                    else {
                        this.showErrorMessage(error);
                    }
                })
    }

    editUser() {
        let newData = new UserModel(this.userForm.value);
        if (this.user !== null) {
            this.user.userName = newData.userName;
            this.user.email = newData.email;
            this.user.firstName = newData.firstName;
            this.user.middleInitial = newData.middleInitial;
            this.user.lastName = newData.lastName;
            this.user.phoneNumber = newData.phoneNumber;
            this.user.profileImageName = newData.profileImageName;
            this.user.companyId = newData.companyId;
            this.user.roleId = newData.roleId;

            this.serviceConsumerService.updatePremiumUser(this.user)
                .subscribe((data: any) => {
                    let imageChanged = false;
                    // upload company logo
                    if (this.uploadedFiles != null) {
                        imageChanged = true;
                        this.uploadedFiles.append('id', this.user.id.toString());
                        this.accountService.uploadFile(this.uploadedFiles).subscribe((result: any) => {
                            this.user.profileImageUrl = result.profileImageUrl;
                            this.user.profileImageName = result.profileImageName;
                        },
                            (error: any) => {
                            });
                    }
                    this.onUserEditEvent.emit(this.user);
                    this.onUserCreateEvent.emit(this.user.companyId);
                    this.alertService.success("User edited successfully");
                },
                    (error: any) => {
                        this.showErrorMessage(error);
                    });
        }
    }

    getRoles() {
        this.roleService.getFilteredRoles()
            .subscribe((data: any) => {
                this.roles = data;

                this.roles = this.roles.filter(c => c.name == 'ServiceConsumer');
                this.filteredRoles.next(this.roles);

                this.user.roleId = this.roles[0].id;

                this.userForm.controls['roleId'].setValue(this.user.roleId, { onlySelf: true });
                //this.userForm.updateValueAndValidity();

            });
    }

    searchPhoneNumbers() {
        var phoneNumber = this.userForm.get('phoneNumber').value;
        this.serviceConsumerService.getConsumerUserByPhoneNumber(phoneNumber).subscribe(data => {
            if (data) {
                this.inEditMode = true;
                this.user.id = data.id;

                this.userForm.patchValue({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email
                });
            }
            else {
                this.inEditMode = false;
            }
        });
    }


    close() {
        this.dialogRef.close();
    }

    showErrorMessage(error: any) {
        console.log(error);
        //this.alertService.error("Internal server error happen");
    }

    openFileBrowser(event: any) {
        event.preventDefault();
        let element: HTMLElement = document.getElementById('UserPhotoId') as HTMLElement;
        element.click();
    }

    // convenience getter for easy access to form fields
    get f() { return this.userForm.controls; }


}

