import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BkashPaymentService {

  private paymentBaseUri = 'api/consumerpayment';
  
  constructor(private http: HttpClient) { }

  getToken(){
    var requestHeader = {headers: new HttpHeaders({ 'Content-Type': 'application/json','No-Auth':'True' })};
    return this.http.post(this.paymentBaseUri + '/bkash-payment/token', null, requestHeader);
  }

  createPayment(request: any){
    var requestHeader = {headers: new HttpHeaders({ 'Content-Type': 'application/json','No-Auth':'False'})};
    return this.http.post(this.paymentBaseUri + '/bkash-payment/create-payment', request, requestHeader);
  }

  executePayment(request: any){
    var requestHeader = {headers: new HttpHeaders({ 'Content-Type': 'application/json','No-Auth':'False'})};
    return this.http.post(this.paymentBaseUri + '/bkash-payment/execute-payment', request, requestHeader);
  }

}
