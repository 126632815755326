
export class consumerOffer {
  id: number;
  phoneNumbers: string;
  emailAddress: string;
  merchantId: number;
  offerPrice: number;
  description: string;
  ownedByConsumer: string;
  offerOccasion: string

  public constructor(init?: Partial<consumerOffer>) {
    Object.assign(this, init);
  }
}
