import { Component, OnInit, Injector } from '@angular/core';
import { OfferService, CommonService, CompanyService, AlertService } from 'src/app/services';
import { BaseComponent } from '../base/base.component';
import { GetOffersPagedListInput, Offer } from 'src/app/models';
import { CompanyRole } from 'src/app/enums';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { GiftSentProcessComponent } from '../gift-sent-process/gift-sent-process.component';
import { offerfiltertype } from '../../enums/offer-filter-type';
@Component({
  selector: 'app-consumer-send-gift',
  templateUrl: './consumer-send-gift.component.html',
  styleUrls: ['./consumer-send-gift.component.css']
})
export class ConsumerSendGiftComponent extends BaseComponent implements OnInit {
  constructor(private offerService: OfferService,
    private commonService: CommonService,
    private companyService: CompanyService,
    private alertService: AlertService,
    private dialog: MatDialog,
    injector: Injector
  ) { super(injector); }

  userInfo: any;
  dataLoaded: Promise<boolean>;
  pageTitle = 'Gifts Sent';

  currentPage = 1;
  pageSize = 10;
  itemCountText: string;
  totalItems: number;

  offerSubMenu: any[] = [];
  activeSubMenu = 'Details';
  selectedSubMenuIndex = 0;

  merchants: any[];
  merchantSelection = 'Merchant';
  merchantFilter: any = { name: '' };
  searchedMerchantLogo: string = null;
  showMerchantSearch = false;
  selectedMerchantId = 0;

  selectedOffer: any;
  selectedOfferName: string;
  selectedOfferIndex = -1;

  offers: Offer[];
  offerFilter: any = { offerShortName: '' };
  hasToShowText = false;

  ngOnInit() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userInfo = userInfo;
    this.getConsumerGifts();
    this.getMerchants();
    this.dataLoaded = Promise.resolve(true);
  }

  giftFilteredItem(value) {
    this.selectedOfferName = value;
    this.getConsumerGifts();
  }
  getConsumerGifts() {
    const input = new GetOffersPagedListInput();

    input.giftSenderId = null;
    input.offerFilterType = offerfiltertype.None;
    input.consumerId = this.userInfo.consumerId;
    input.giftName = this.selectedOfferName;
    input.pageNumber = this.currentPage;
    input.pageSize = this.pageSize;

    if (this.selectedMerchantId === 0) {
      input.merchantId = null;
    } else {
      input.merchantId = this.selectedMerchantId;
    }

    this.offerService.getConsumerSendOffersPagedList(input)
      .subscribe(data => {
        this.offers = data.data;
        this.totalItems = data.rowCount;

        this.highlightFirstItem(this.offers);
        this.itemCount();
      });
  }
  getMerchants() {
    this.companyService.getMerchantBasics()
      .subscribe(data => {
        this.merchants = data;

        if (data.length > 1 || !(CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin)) {
          this.showMerchantSearch = true;
        }
      });
  }
  highlightFirstItem(offers: Offer[]) {
    if (this.offers !== null) {
      this.selectedOffer = this.offers[0];
      this.selectedOfferIndex = 0;
      this.dataLoaded = Promise.resolve(true);
    }
  }
  selectAllMerchant() {
    this.searchedMerchantLogo = null;
    this.merchantSelection = 'Merchant';
    this.selectedMerchantId = 0;
    this.getConsumerGifts();
  }
  onOfferChange(offer, index) {
    if (this.selectedOfferIndex !== index) {
      this.selectedOfferIndex = index === this.selectedOfferIndex ? -1 : index;
      this.selectedOffer = offer;
    }
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('display-tab-content');
    this.commonService.itemSelectionUpdated(this.selectedOffer);
  }

  itemCount() {
    const firstItem = (this.currentPage - 1) * this.pageSize + 1;
    let lastItem = (this.currentPage) * this.pageSize;
    if (lastItem > this.totalItems) {
      lastItem = this.totalItems;
    }
    this.itemCountText = firstItem + '-' + lastItem + ' of ' + this.totalItems + ' Gifts';
  }
  pageChanged(event) {
    this.selectedOfferIndex = 0;
    this.currentPage = event;
    this.getConsumerGifts();
  }
  onMerchantChange(merchant, index) {
    this.selectedMerchantId = merchant.id;
    this.searchedMerchantLogo = merchant.companyLogoUrl;
    this.merchantSelection = merchant.name.split(' ').slice(0, 2).join(' ');

    this.getConsumerGifts();
  }

  errorHandlerOnImg(event, offerId) {
    document.getElementById('merchantImgLogotxt_' + offerId).style.display = "block";
    document.getElementById('merchantImgLogo_' + offerId).style.display = "none";
    //this.hasToShowText = true;
  }
  sendGift() {
    const dialogConfig = new MatDialogConfig();
    // Setting different dialog configurations
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'golp-dialog';
    const offerCreateDialog = this.dialog.open(GiftSentProcessComponent, dialogConfig);

    const successfullCreate = offerCreateDialog.componentInstance.onSentGiftEvent.subscribe((data) => {
      //this.getOfferById(data.id);
      this.alertService.success("Gift sent successfully");
    });

    // offerCreateDialog.afterClosed().subscribe(() => {
    // });
  }

  onMarkAsFavorite(offer: any, status) {
    offer.markAsFavorite = status
    this.offerService.markOfferAssignmentAsFavorite(offer).subscribe((data) => {
      this.getConsumerGifts();
    });
  }
}
