import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CompanyCategory } from '../models/company-category';

@Injectable({
  providedIn: 'root'
})
export class CompanyCategoryService {

  private companyCategoryBaseUri = 'api/companytypes';

  constructor(
    private http: HttpClient
  ) { }

  getCompanyCategories(): Observable<CompanyCategory[]> {
    return this.http.get<CompanyCategory[]>(this.companyCategoryBaseUri);
  }

}
