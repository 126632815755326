import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})
export class AlertDialogComponent  implements OnInit {

  confirmationMessage:string;

  constructor(private dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) { 

      if(data !== null){
        this.confirmationMessage = data;
      }
    }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close(); 
  }

}
