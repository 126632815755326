import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Alert } from '../models/alert';
import { Router, NavigationStart } from '@angular/router';
import { AlertType } from '../enums/alert-type';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private subject = new Subject<Alert>();
  private keepAfterRouteChange = false;

  constructor(private router: Router) {
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    router.events.subscribe(event => {
      if(event instanceof NavigationStart){
        if(this.keepAfterRouteChange){
          // only keep for a single route change
          this.keepAfterRouteChange = false;
        }
        else{
          this.clear();
        }
      }
    });
   }

   getAlert(): Observable<any>{
     return this.subject.asObservable();
   }

   success(message: string, keepAfterRouteChange = false){
     this.alert(AlertType.Success, message, keepAfterRouteChange);
   }

   error(message: string, keepAfterRouteChange = false) {
      if(this.router.url != "/login"){
        this.alert(AlertType.Error, message, keepAfterRouteChange);
      }
    }

    info(message: string, keepAfterRouteChange = false) {
      this.alert(AlertType.Info, message, keepAfterRouteChange);
    }

    warn(message: string, keepAfterRouteChange = false) {
      this.alert(AlertType.Warning, message, keepAfterRouteChange);
    }

    clear(){
      this.subject.next();
    }

   alert(type: AlertType, message: string, keepAfterRouteChange = false){
     this.keepAfterRouteChange = keepAfterRouteChange;
     this.subject.next(<Alert>{type: type, message: message});
   }
}
