import { Injectable } from '@angular/core';
import { PaymentMethod } from '../enums';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private PaymentMethodsDisplay: { [index: number]: string } = {};

  private itemSelectionChanged = new Subject<any>();
  private itemCreated = new Subject<any>();
  private itemEdited = new Subject<any>();
  public backgroundJobModel = new Subject<any>();
  
  constructor() { 
    this.generatePaymentMethodsDisplay();
  }

  getPaymentMethodDisplayNames(){
    return this.PaymentMethodsDisplay;
  }

  itemSelectionUpdated(item: any){
    this.itemSelectionChanged.next(item);
  }

  getChangedItem(): Observable<any>{
    return this.itemSelectionChanged.asObservable();
  }

  createEventEmitter(id: any){
    this.itemCreated.next(id);
  }

  getCreatedItem(): Observable<any>{
    return this.itemCreated.asObservable();
  }

  editEventEmitter(id: any){
    this.itemEdited.next(id);
  }

  getEditedItem(): Observable<any>{
    return this.itemEdited.asObservable();
  }

  backgroundJobEventEmitter(model: any){
    this.backgroundJobModel.next(model);
  }
  backgroundJobEventReceiver(): Observable<any>{
    return this.backgroundJobModel.asObservable();
  }
  errorHandlerOnImg(event, width, height, bgColor, txtColor, fontSize, text) {
    event.target.src = "http://place-hold.it/" + width + "x" + height + "/" + bgColor + "/" + txtColor + "&fontsize=" + fontSize + "&text=" + text;
  }

  private generatePaymentMethodsDisplay(){
    this.PaymentMethodsDisplay[PaymentMethod.None] = "None";
    this.PaymentMethodsDisplay[PaymentMethod.Direct] = "Direct";
    this.PaymentMethodsDisplay[PaymentMethod.ThroughGolp] = "Through Golp";
  }
}
