import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { AlertType } from 'src/app/enums';
import { AlertService } from 'src/app/services';
import { DataService } from '../../services/shared/data.service';

@Component({
  selector: 'appc-gift-confirmation',
  templateUrl: './gift-confirmation.component.html',
  styleUrls: ['./gift-confirmation.component.scss']
})
export class GiftConfirmationComponent implements OnInit {

  giftInformation: any;
  merchantId: any;
  companyOutletId: any;
  isDisabled: boolean = false;
  merchantOutlets: any;
  navigatedFrom: any;

  constructor(private router: Router, private dataService: DataService, private alertService: AlertService) {
    let navigation = this.router.getCurrentNavigation();
    let state = navigation.extras.state as {
      transId: string,
      workQueue: boolean,
      services: number,
      code: any
    };

    if (state != undefined && state.code != undefined) {
      this.giftInformation = state.code;
      this.navigatedFrom = state.transId;
    }

    this.setUserInfo();
  }

  ngOnInit() {

  }

  setUserInfo() {
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.merchantId = userInfo.companyId;
    this.companyOutletId = userInfo.outletQrCode;
    if (this.companyOutletId == undefined || this.companyOutletId == null) {
      if (this.giftInformation.showOutlet)
        this.companyOutletId = this.giftInformation.outletId.qrCode;
      else this.companyOutletId = this.giftInformation.outletId.qrCode;
    }
  }

  onContinue() {
    this.isDisabled = true;
    var paymentCreateRequest: any;
    if (this.giftInformation.paperGiftCardId != undefined && this.giftInformation.paperGiftCardId != null) {
      paymentCreateRequest = {
        giftAmount: this.giftInformation.amount,
        senderPhone: this.giftInformation.sender,
        receiverPhone: this.giftInformation.receiver,
        receiverEmail: this.giftInformation.receiverEmail,
        senderMessage: this.giftInformation.message,
        merchantId: this.merchantId,
        MerchantOutletId: this.companyOutletId,
        paperGiftCardId: this.giftInformation.paperGiftCardId,
        smsTemplate: this.giftInformation.smsTemplate,
        smsTemplateId: this.giftInformation.smsTemplateId
      };
    }
    else {
      paymentCreateRequest = {
        giftAmount: this.giftInformation.amount,
        senderPhone: this.giftInformation.sender,
        receiverPhone: this.giftInformation.receiver,
        receiverEmail: this.giftInformation.receiverEmail,
        senderMessage: this.giftInformation.message,
        merchantId: this.merchantId,
        MerchantOutletId: this.companyOutletId,
        smsTemplate: this.giftInformation.smsTemplate,
        smsTemplateId: this.giftInformation.smsTemplateId
      };
    }

    if (this.navigatedFrom == "bulkGiftInformation") {
      paymentCreateRequest["externalInvoiceNumber"] = this.giftInformation.externalInvoiceNumber;
      paymentCreateRequest["seller"] = this.giftInformation.seller;
      paymentCreateRequest["remarks"] = this.giftInformation.remarks;
      paymentCreateRequest["giftValidityPeriod"] = this.giftInformation.giftValidityPeriod;
    }

    if (this.giftInformation.salesAmount != undefined && this.giftInformation.salesAmount != null) {
      paymentCreateRequest["salesAmount"] = this.giftInformation.salesAmount;
    }

    if (this.giftInformation.isBulkGift != undefined && this.giftInformation.isBulkGift != null) {
      paymentCreateRequest["isBulkGift"] = this.giftInformation.isBulkGift;
    }
    paymentCreateRequest["isActivate"] = this.giftInformation.isActive;

    this.dataService.post('api/giftcard/process-orders', paymentCreateRequest).subscribe((data: any) => {
      if (data.success) {
        this.alertService.alert(AlertType.Success, "Gift sent successfully");
        this.router.navigate([`/send-gift-delivered/${this.merchantId}/${data.data.orderId}`])
      }
      else {
        this.alertService.alert(AlertType.Error, data.data);
        this.isDisabled = false;
      }

    },
      (error: any) => {
        console.log(error);
        this.isDisabled = false;
      }
    );
  }

  onBack() {
    let navigationEgolps: NavigationExtras = {
      state: {
        transId: 'giftInformation',
        workQueue: false,
        services: 1,
        code: this.giftInformation,
      }
    };
    this.navigatedFrom == "bulkGiftInformation" ? this.router.navigate(['/bulk-gift'], navigationEgolps) : this.router.navigate(['/send-gift-information'], navigationEgolps);
  }

}
