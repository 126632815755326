import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, Injector } from '@angular/core';
import { Offer } from 'src/app/models';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateOfferComponent } from '../create-offer/create-offer.component';
import { PaymentMethod, CompanyRole, OfferType } from 'src/app/enums';
import { CommonService, AccountServiceService, OfferService, AlertService, OfferAssignmentService } from 'src/app/services';
import { OfferRejectionComponent } from '../offer-rejection/offer-rejection.component';
import { RejectionReasonComponent } from '../rejection-reason/rejection-reason.component';
import { BaseComponent } from '../base/base.component';
import { AvailedConsumerListComponent } from '../availed-consumer-list/availed-consumer-list.component';

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.css']
})
export class OfferDetailsComponent extends BaseComponent implements OnInit {

  @Input() offerDetailsInput: Offer;
  @Output() offerDetailsInputChange: EventEmitter<Offer> = new EventEmitter<Offer>();
  
  offerDetails: Offer;
  offerAssignmentSummary: any;
  userInfo: any;
  detailsLoaded: Promise<boolean>;

  canEdit: boolean = true;
  PaymentMethod: typeof PaymentMethod = PaymentMethod;
  showHideAcceptButton: boolean = true;
  PaymentMethodsDisplay: { [index: number]: string } = {};
  hasToShowOfferTextImg = false;
  hasToShowMerchantTextImg = false;
  hasToShowOfferProviderTextImg = false;

  constructor(public dialog: MatDialog, private accountService: AccountServiceService,
    private commonService: CommonService,
    private offerService: OfferService,
    private alertService: AlertService,
    private offerAssignmentService: OfferAssignmentService,
    private injector: Injector
    ) { 
      super(injector);
    }

  ngOnInit() {
    this.PaymentMethodsDisplay = this.commonService.getPaymentMethodDisplayNames();
    this.getLoggedInUser();
    this.offerDetails = new Offer();
    this.showHideAcceptAction();

    this.offerAssignmentService.getChangedAssignmentOfferId().subscribe((item: any) => {
      if(this.offerDetails != null && item == this.offerDetails.id){
        this.getOfferAssignmentSummary(this.offerDetails.id);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['offerDetailsInput']){
      setTimeout(()=>{
        this.offerDetails = this.offerDetailsInput;
        if(this.offerDetails != null){
          this.getOfferAssignmentSummary(this.offerDetails.id);
          this.offerEditable();
        }
        this.showHideAcceptAction();
        this.makeSomeDelay(this.offerDetails);
      });
    }
 }

  editOffer(offer: Offer){

    const editDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    editDialogConfig.disableClose = true;
    editDialogConfig.autoFocus = true;
    editDialogConfig.panelClass = 'golp-dialog';

    editDialogConfig.data = offer

    const offerEditDialog = this.dialog.open(CreateOfferComponent, editDialogConfig);

    const successFullEdit = offerEditDialog.componentInstance.onOfferEditEvent.subscribe((data) =>{
      this.offerDetails = data;
      this.getOfferAssignmentSummary(this.offerDetails.id);
      this.offerDetailsInputChange.emit(this.offerDetails);
      this.showHideAcceptAction();
      this.makeSomeDelay(this.offerDetails);
      this.offerEditable();
    });

    offerEditDialog.afterClosed().subscribe(() => {
      Promise.resolve(true);
      //successFullEdit.unsubscribe();
      //this.detailsLoaded = Promise.resolve(false);
    });
  }

  availedConsumerList(offer: Offer){

    const availedConsumerDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    availedConsumerDialogConfig.disableClose = true;
    availedConsumerDialogConfig.autoFocus = true;
    availedConsumerDialogConfig.panelClass = 'golp-dialog';

    availedConsumerDialogConfig.data = offer

    const availedConsumerDialog = this.dialog.open(AvailedConsumerListComponent, availedConsumerDialogConfig);

    // const success = availedConsumerDialog.componentInstance..subscribe((data) =>{
    //   this.offerDetails = data;
    //   this.showHideAcceptAction();
    //   this.makeSomeDelay(this.offerDetails);
    // });

    // availedConsumerDialog.afterClosed().subscribe(() => {
    // });
  }

  rejectOffer(offer: Offer){
    const rejectDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    rejectDialogConfig.disableClose = true;
    rejectDialogConfig.autoFocus = true;
    rejectDialogConfig.panelClass = 'golp-dialog';

    rejectDialogConfig.data = offer;

    const rejectDialog = this.dialog.open(OfferRejectionComponent, rejectDialogConfig);

    const successfullRejection = rejectDialog.componentInstance.onOfferRejectionEvent.subscribe((data) =>{
      this.offerDetails = data;
      this.showHideAcceptAction();
      this.makeSomeDelay(this.offerDetails);
    });

    rejectDialog.afterClosed().subscribe(() => {
    });

  }

  activateOffer(offerDetails: Offer){
    offerDetails.isActive = true;

    this.offerService.activateOffer(offerDetails)
      .subscribe((data) => {
        this.offerDetails = offerDetails;
        this.offerDetails.offerStatus = 'Active';
        this.showHideAcceptAction();
        this.offerEditable();
        this.alertService.success("Gift activated successfully");
      },
      (error) =>{
        console.log(error);
        //this.alertService.error("Internal server error happen");
      });

  }
  showRejectionReason(){
      const rejectionReasonDialogConfig = new MatDialogConfig();

      // Setting different dialog configurations
      rejectionReasonDialogConfig.panelClass = 'golp-dialog';
      rejectionReasonDialogConfig.data = this.offerDetails;

      const rejectDialog = this.dialog.open(RejectionReasonComponent, rejectionReasonDialogConfig);
  }

  showHideAcceptAction(){
    if (this.offerDetails != null) {
      // if((CompanyRole.GiftSender === this.userInfo.companyRole || CompanyRole.Merchant === this.userInfo.companyRole) && !this.userInfo.isSystemAdmin){
      //   this.showHideAcceptButton = false;
      // }
      if (this.offerDetails.isRejected || this.offerDetails.isActive || this.offerDetails.offerType == OfferType.GolpProduct){
        this.showHideAcceptButton = false;
      }
      else{
        this.showHideAcceptButton = true;
      }
    }
  }

  makeSomeDelay(offer: Offer){
    if(offer != null){
      let readSomeValue = offer.name;
      this.hasToShowOfferTextImg = false;
      this.hasToShowMerchantTextImg = false;
      this.hasToShowOfferProviderTextImg = false;
      this.detailsLoaded = Promise.resolve(true);
    }
  }

  getOfferAssignmentSummary(id:number){
    this.offerAssignmentService.getOfferAssignmentSummary(id)
      .subscribe((data) => {
        this.offerAssignmentSummary = data; 
      })
  }

  getLoggedInUser(){
    this.userInfo = this.accountService.getLoggedInUserInfo();
  }

  offerEditable(){
    // if(CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin){
    //   this.canEdit = false;
    // }
    // else if(this.offerDetails.isActive && !this.userInfo.isSystemAdmin){
    //   this.canEdit = false;
    // }
    // else{
    //   this.canEdit = true;
    // }
    this.canEdit = this.offerDetails.isActive || this.offerDetails.offerType == OfferType.GolpProduct;
  }
  errorHandlerOnOfferImg(event) {
    this.hasToShowOfferTextImg = true;
  }
  errorHandlerOnMerchantImg(event) {
    this.hasToShowMerchantTextImg = true;
  }
  errorHandlerOnOfferProviderImg(event) {
    this.hasToShowOfferProviderTextImg = true;
  }
}
