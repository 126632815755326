import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { UserReferenceModel } from '../../models/company-reference-model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountServiceService } from '../../services';
import { ValidationUtility } from '../../utility/validation.utility';
import { PremiumUserReferenceServiceService } from '../../services/premium-user-reference-service.service';

@Component({
  selector: 'app-create-premium-user-reference',
  templateUrl: './create-premium-user-reference.component.html',
  styleUrls: ['./create-premium-user-reference.component.css']
})
export class CreatePremiumUserReferenceComponent implements OnInit {

  onReferenceCreateEvent: EventEmitter<any> = new EventEmitter();
  onReferenceEditEvent: EventEmitter<any> = new EventEmitter();
  userReference: UserReferenceModel;
  userId: number;
  isEdit = false;
  userInfo: any;
  userReferenceForm: FormGroup;
  submitted = false;
  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreatePremiumUserReferenceComponent>,
    private accountService: AccountServiceService,
    private premiumUserReferenceService: PremiumUserReferenceServiceService,
    @Inject(MAT_DIALOG_DATA) data) {
    if (data != null) {
      if (isNaN(data)) {
        this.userReference = new UserReferenceModel(data);
        this.userId = this.userReference.userId;
        this.isEdit = true;
      }
      else {
        this.isEdit = false;
        this.userId = data;
      }
    }
  }

  ngOnInit() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
    this.isEdit = this.userReference.id != undefined;
    this.buildForm();
  }
  buildForm() {
    this.userReferenceForm = this.formBuilder.group({
      //id: [this.companyCredit.id],
      userId: [this.userReference.userId],
      firstName: [this.userReference.firstName ? this.userReference.firstName : '', [Validators.required, Validators.maxLength(30)]],
      lastName: [this.userReference.lastName ? this.userReference.lastName : '', [Validators.required, Validators.maxLength(30)]],
      email: [this.userReference.email],
      phone: [this.userReference.phone ? this.userReference.phone : '', Validators.required],
      createdBy: [this.userReference.createdBy],
      isActive: [this.userReference.isActive],
      address: [this.userReference.address, Validators.required]
    });
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.userReferenceForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }
    if (this.userReference.id === undefined) { // create
      this.createUserReference();
    }
    else if (this.userReference.id != undefined && this.userReference.id != null) { // edit
      this.editUserReference();
    }
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }
    editUserReference() {
      var referenceId = this.userReference.id;
      var createdDate = this.userReference.createdDate;
      this.userReference = new UserReferenceModel(this.userReferenceForm.value);
      this.userReference.id = referenceId;
      this.userReference.createdDate = createdDate;
      this.premiumUserReferenceService.saveUserReference(this.userReference).subscribe((data: any) => {
        this.onReferenceEditEvent.emit(this.userReference.id);
      },
        (error: any) => {
          //this.showErrorMessage(error);
        })
    }
    createUserReference() {
      this.userReference = new UserReferenceModel(this.userReferenceForm.value);
      if (this.userReference !== null) {
        this.userReference.userId = this.userId;
        this.userReference.isActive = true;
        this.userReference.createdBy = this.userInfo.id;
      }
      this.premiumUserReferenceService.saveUserReference(this.userReference).subscribe((data: any) => {
        this.onReferenceCreateEvent.emit(this.userReference.id);
      },
        (error: any) => {
          //this.showErrorMessage(error);
        });
    }
  get f() { return this.userReferenceForm.controls; }
}
