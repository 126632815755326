import { Injectable } from '@angular/core';
import { UserReferenceModel } from "../models/company-reference-model";
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PremiumUserReferenceServiceService {

  private userReferenceBaseUrl = 'api/userreference';
  
  constructor(private http: HttpClient) { }

  saveUserReference(userReference: UserReferenceModel) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.put(this.userReferenceBaseUrl + '/save-reference', userReference, requestHeader);
  }

  deleteUserReference(id: number) {
    var requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'False' }) };
    return this.http.delete(this.userReferenceBaseUrl + '/delete-reference/' + id, requestHeader);
  }

  getAllUserReferenceByUserId(userId: number) {
    return this.http.get<UserReferenceModel[]>(this.userReferenceBaseUrl + '/getall-reference/' + userId);
  }
}
