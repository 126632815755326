import { PaymentType, TransactionType } from "src/app/enums";

export class AddTransaction {
  companyId: number;
  transactionType: TransactionType;
  paymentType: PaymentType;
  amount: number;
  referenceNumber: string;
  paymentDate: string;
  notes: string;
  outletQRCode: any;

  public constructor(init?: Partial<AddTransaction>) {
    Object.assign(this, init);
  }
}
