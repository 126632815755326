import { NewBillSettingComponent } from './new-bill-setting/new-bill-setting.component';
import { FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DataService } from 'src/app/components/app-send-gift/services/shared/data.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-bill-settings-tab',
  templateUrl: './bill-settings-tab.component.html',
  styleUrls: ['./bill-settings-tab.component.css']
})
export class BillSettingsTabComponent implements OnInit {
  baseUrl: string;
  constructor(private dialog: MatDialog, private dataService: DataService) {
    this.baseUrl = environment.apiUrl;
    this.loadBillSettings();
  }

  billSettingsKey:string;

  billSettingsKeySearchForm: FormGroup;
  billSettingsResult : any[]


  //pagination
  currentPage: number = 1;
  pageSize: number = 25;
  expandedIndex: number = -1;
  totalItems: number = 0;
  count = 0;
  pageSizes = [25, 50, 100];


  ngOnInit(): void {}

  onSubmit(isReset:boolean = false){

  }

  loadBillSettings(){
    let url = "api/billing/get-all-billsettings";
    this.dataService.get(url).subscribe((res:any)=>{
      console.log("result : ", res);
      if(res.result){
        this.billSettingsResult = res.data;
        this.count = this.billSettingsResult.length;
      }
      else{this.billSettingsResult = [] ;this.count = 0;}
    }, err=>{
      console.log(err);
    });
  }

  handlePageChange(event: number): void {
    this.currentPage = event;
    this.loadBillSettings();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.currentPage = 1;
    this.loadBillSettings();
  }

  getCurrentRange(){
    return `Showing ${((this.currentPage - 1) * this.pageSize) + 1}-${((this.currentPage - 1) * this.pageSize) + this.billSettingsResult?.length} of ${this.count}`
  }

  addNewClicked(){
    const addBillSettingDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    addBillSettingDialogConfig.disableClose = true;
    addBillSettingDialogConfig.autoFocus = true;
    addBillSettingDialogConfig.panelClass = "golp-dialog";

    const createAssignmentDialog = this.dialog.open(NewBillSettingComponent, addBillSettingDialogConfig);

    const successfullCreate = createAssignmentDialog.componentInstance.onAddBillSettingEvent.subscribe((data) => {
      this.loadBillSettings()
    });

    createAssignmentDialog.afterClosed().subscribe(() => {
    });
  }

}
