import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyOutlet } from '../models/company-outlet';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CompanyOutletService {

  private outletsBaseUrl = 'api/outlets';

  constructor(private http: HttpClient) { }

  getOutlets(): Observable<any[]> {
    return this.http.get<any[]>(this.outletsBaseUrl);
  }

  getOutletsByCompany(companyId: number): Observable<any[]> {
    return this.http.get<any[]>(this.outletsBaseUrl + '/company/' + companyId);
  }

  createOutlet(outlet: CompanyOutlet){
    var requestHeader = {headers: new HttpHeaders({ 'Content-Type': 'application/json','No-Auth':'False' })};
    return this.http.post(this.outletsBaseUrl, outlet, requestHeader);
  }

  editOutlet(outlet: CompanyOutlet){
    var requestHeader = {headers: new HttpHeaders({ 'Content-Type': 'application/json','No-Auth':'False' })};
    return this.http.put(this.outletsBaseUrl, outlet, requestHeader);
  }

  deleteOutlet(outletId: number){
    var requestHeader = {headers: new HttpHeaders({ 'Content-Type': 'application/json','No-Auth':'False' })};
    return this.http.delete(this.outletsBaseUrl + '/' + outletId, requestHeader);
  }
}
