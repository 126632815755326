import { Injectable } from '@angular/core';
import { RolePermission } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class PermissionCheckerService {

  constructor() { }

  hasPermission(permissionName: any):boolean{
    if(localStorage.getItem('userToken') == null) 
      return false;

    var userRolePermissions = localStorage.getItem('userRolePermissions');
   
    if (userRolePermissions && permissionName) {
      // let intersection = permissionName.filter(x => userRolePermissions.includes(x));
      // var hasPermission = intersection.length > 0;

      var hasPermission = false;
      if(typeof permissionName =="string"){
        hasPermission =  userRolePermissions.includes(RolePermission[permissionName]);
      }
      else if(typeof permissionName =="object"){
         let intersection = permissionName.filter(x => userRolePermissions.includes(RolePermission[x]));
         hasPermission = intersection.length > 0;
      }
      return hasPermission;
    }

    return false;
  }


  
}
