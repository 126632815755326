import { Component, OnInit } from '@angular/core';
import { OfferType } from 'src/app/enums';

@Component({
  selector: 'app-bundle-offers',
  templateUrl: './bundle-offers.component.html',
  styleUrls: ['./bundle-offers.component.css']
})
export class BundleOffersComponent implements OnInit {

  offerType: OfferType = OfferType.Bundle;

  constructor(
    ) { }

  ngOnInit() {
  }

}
