import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountServiceService } from '../../services';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-accept-otp',
  templateUrl: './accept-otp.component.html',
  styleUrls: ['./accept-otp.component.css']
})
export class AcceptOtpComponent implements OnInit {
  @BlockUI('login-with-otp-section') blockUI: NgBlockUI;

  submitted = false;
  onValidOtpEvent: EventEmitter<any> = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private dialogRef: MatDialogRef<AcceptOtpComponent>,
    @Inject(MAT_DIALOG_DATA) data, private accountService: AccountServiceService,
    private router: Router) {

    console.log(data);

    this.phoneNumber = data;
  }

  phoneNumber: any;
  acceptOtpForm: FormGroup;
  oneTimePassword: any;
  invalidPin: boolean = false;
  errorMsg: any = "Please enter your valid PIN";

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.acceptOtpForm = this.formBuilder.group({
      pin: [this.oneTimePassword, [Validators.required]],
    });
  }

  onContinue() {
    this.submitted = true;
    this.invalidPin = false;

    //let pin = this.acceptOtpForm.value;
    //if (pin.pin === "" || pin.pin.length < 6) {
    //  this.invalidPin = true;
    //  return;
    //}

    if (!this.oneTimePassword || this.oneTimePassword.length < 6) {
      this.invalidPin = true;
      return;
    }

    var consumerModel = {
      "longBirthDate": 0,
      "otp": this.oneTimePassword,
      "id": 0,
      "device": "SM-A205F",
      "operatingSystem": "Android",
      "phoneNumber": this.phoneNumber,
      "osVersion": "9"
    };
    this.consumerLogin(consumerModel);
  }

  consumerLogin(userInfo) {
    this.blockUI.start();
    this.accountService.consumerAuthentication(userInfo)
      .subscribe((result: any) => {
        this.blockUI.stop();

        if (!result.status) {
          this.invalidPin = true;
          this.errorMsg = result.message;
        }

        let userRole = result.data.roles[0].name;
        localStorage.setItem('userToken', result.token);
        localStorage.setItem('userRole', userRole);

        localStorage.setItem('userInfo', JSON.stringify(result.data));
        localStorage.setItem('userRolePermissions', JSON.stringify(this.getPermissionsArray(result.data.permissions, 4)));

        this.accountService.storeBrowserTabId();

        if (userRole === 'ServiceConsumer') {
          this.dialogRef.close();
          this.router.navigate(['/receive-gifts']);
        }
      },
        (error: any) => {
          this.blockUI.stop();
          this.invalidPin = true;
          this.errorMsg = error;
        })
  }

  getPermissionsArray(input: string, length: number) {

    var permission = input.replace("H4-", '');

    var size = Math.ceil(permission.length / length),
      ret = new Array(size),
      offset;

    for (var i = 0; i < size; i++) {
      offset = i * length;
      ret[i] = permission.substring(offset, offset + length);
    }

    return ret;
  }

  close() {
    this.dialogRef.close();
  }

  get f() { return this.acceptOtpForm.controls; }


  onOtpChange(otp) {
    this.oneTimePassword = otp;
  }
}
