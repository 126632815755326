import { ActionType, PromotionScope, PromotionFor, PromotionResponse } from "../enums";

export class Promotion {
    id: number;
    promotionCode: string;
    description: string;
    isActive: boolean;
    actionType: ActionType;
    actionValue: string;
    promotionScope: PromotionScope;
    promotionFor: PromotionFor;
    promotionResponse: PromotionResponse;
    firstImageName: string;
    startDate: Date;
    endDate: Date;
    viewOrder: number;
    promotionalNotes: string;
    giftExpireAfterDays: number;
    executionOrder: number;
    promotionImageUrls: any;
    promotionRules: any;
    promotionStatus: string;

    public constructor(init?: Partial<Promotion>) {
        Object.assign(this, init);
      }

}
