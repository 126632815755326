import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReportService } from 'src/app/services/report.service';
import * as moment from 'moment';
import { PaymentDueReportDto } from '../../models/payment-due-report-input';
import { Router } from '@angular/router';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ShowMerchantPaymentInfoComponent } from '../show-merchant-payment-info/show-merchant-payment-info.component';
import { SharedService } from '../../services/shared.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { saveAs } from 'file-saver';
import { DataService } from 'src/app/services';

@Component({
  selector: 'app-payment-due-report',
  templateUrl: './payment-due-report.component.html',
  styleUrls: ['./payment-due-report.component.css']
})

export class PaymentDueReportComponent implements OnInit {
  @BlockUI('due-payment-report-section') blockUI: NgBlockUI;

  paymentDueReportForm: FormGroup;

  currentPage: number = 1;
  pageSize: number = 25;
  expandedIndex: number = -1;
  expandedGiftIndex: number = -1;
  expandedOutletIndex: number = -1;
  totalItems: number = 0;
  currentPageNumberDetails: string;
  paginationPageNumbers: any[];

  constructor(private router: Router,
    private dialog: MatDialog,
    private dataService: DataService,
    private sharedService: SharedService) {
  }

  ngOnInit() {
    this.paginationPageNumbers = this.sharedService.getPaginationByPageNumbers();

    this.getAllPaymentDueReports();
  }

  paymentDueReports: any = [];


  expandCollapse(index) {
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }

  expandCollapseGift(index) {
    this.expandedGiftIndex = index === this.expandedGiftIndex ? -1 : index;
  }
  expandCollapseOutlet(index) {
    this.expandedOutletIndex = index === this.expandedOutletIndex ? -1 : index;
  }


  navigateToBillingPage(merchantId: number, outletQRCode: string, outletName: string) {
    this.router.navigate(['/billings'], { queryParams: { merchantId: merchantId, outletQRCode: outletQRCode, outletName: outletName } });
  }

  getAllPaymentDueReports() {
    this.blockUI.start();
    let url = `api/MerchantBilling/due-payment-summary?pageSize=${this.pageSize}&pageNumber=${this.currentPage}`;

    this.dataService.get(url)
      .subscribe((result: any) => {
        this.paymentDueReports = result.billSummary;
        this.totalItems = result.totalCount;
        this.getCurrentRange();
        this.blockUI.stop();
      }, () => {
        this.blockUI.stop();
      });
  }

  get f() { return this.paymentDueReportForm.controls; }

  pageChanged(event) {
    this.currentPage = event;
    this.getAllPaymentDueReports();
  }

  itemCount() {

  }

  exportToExcel() {

    let obj = {};
    let url = "api/MerchantBilling/export-payment-due-report";

    this.dataService.postWithOptions(url, obj, {
      responseType: 'blob'
    }).subscribe((response: any) => {
      this.downloadFile(response);
    }, (err) => console.log(err));
  }

  showPaymentInfo(merchantName: string, paymentInfo: string) {
    const paymentInfoDialog = new MatDialogConfig();

    // Setting different dialog configurations
    paymentInfoDialog.data = { merchantName: merchantName, paymentInfo: paymentInfo };
    paymentInfoDialog.panelClass = 'golp-dialog';

    const outletEditDialog = this.dialog.open(ShowMerchantPaymentInfoComponent, paymentInfoDialog);

    outletEditDialog.afterClosed().subscribe(() => {
    });
  }

  downloadFile(data) {
    const blob2 = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var filename = "payment_due_report_" + moment().format('MMMM Do YYYY, h:mm:ss').valueOf() + ".xlsx";
    saveAs(blob2, filename);
  }

  getCurrentRange() {
    this.currentPageNumberDetails = `Showing ${((this.currentPage - 1) * this.pageSize) + 1}-${((this.currentPage - 1) * this.pageSize) + this.paymentDueReports?.length} of ${this.totalItems}`
  }

}
