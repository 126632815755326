import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConsumerPaymentService {

  private billingBaseUri = 'api/consumerpayment';

  constructor(
    private http: HttpClient
  ) { }

  startPayment(paymentSession: any){
    let requestHeader = {headers: new HttpHeaders({ 'Content-Type': 'application/json','No-Auth':'True' })};
    return this.http.post(this.billingBaseUri + '/app-card-payment', paymentSession, requestHeader);
  }
  payBySavedCardCommand(savedCardCommand: any){
    let requestHeader = {headers: new HttpHeaders({ 'Content-Type': 'application/json','No-Auth':'True' })};
    return this.http.post(this.billingBaseUri + '/pay-by-saved-card', savedCardCommand, requestHeader);
  }

  payByGolpCredit(paymentSession: any) {
    let requestHeader = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' }) };
    return this.http.post(this.billingBaseUri + '/consumer-payment-by-golp-credit-success', paymentSession, requestHeader);
  }
}
