import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, Injector } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { BaseComponent } from '../base/base.component';
import { CompanyService, AlertService } from 'src/app/services';
import { CreateUserComponent } from '../create-user/create-user.component';
import { UserModel } from '../../models';
import { CreatePremiumUserComponent } from '../create-premium-user/create-premium-user.component';
import { ResetPasswordDialogComponent } from '../reset-password-dialog/reset-password-dialog.component';

@Component({
  selector: 'app-premium-user-details',
  templateUrl: './premium-user-details.component.html',
  styleUrls: ['./premium-user-details.component.css']
})

export class PremiumUserDetailsComponent extends BaseComponent implements OnInit {

  @Input() premiumUserDetailsInput: UserModel;
  @Output() premiumUserDetailsInputChange: EventEmitter<any> = new EventEmitter<any>();

  premiumUserDetails: UserModel;
  userFullName: string;

  detailsLoaded: Promise<boolean>;
  showEnableButton: boolean = false;
  hasResetPermission = false;
  
  constructor(public dialog: MatDialog, private injector: Injector,
    private alertService: AlertService
  ) { super(injector); }

  ngOnInit() {
    this.premiumUserDetails = new UserModel();
    this.detailsLoaded = Promise.resolve(true);
    this.userFullName = this.premiumUserDetails.firstName != null ? this.premiumUserDetails.firstName : ""
      + " " + this.premiumUserDetails.lastName != null ? this.premiumUserDetails.lastName : "";

    if (this.permissionCheckerService.hasPermission(['SystemAdmin'])) {
      this.hasResetPermission = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['premiumUserDetailsInput']) {
      setTimeout(() => {
        this.premiumUserDetails = this.premiumUserDetailsInput;
        this.userFullName = this.premiumUserDetails.firstName != null ? this.premiumUserDetails.firstName : ""
          + " " + this.premiumUserDetails.lastName != null ? this.premiumUserDetails.lastName : "";
        this.detailsLoaded = Promise.resolve(true);
      });
    }
  }

  //enableDisablePremiumUser(premiumUser: Company, enable: boolean) {
  //  premiumUser.isActive = enable;
  //  this.companyService.enableDisablePremiumUser(premiumUser)
  //    .subscribe((data) => {
  //      this.visibleEnableDisableButton();
  //      let message = enable ? 'PremiumUser enabled successfully' : 'PremiumUser disabled successfully';
  //      this.alertService.success(message);
  //    });
  //}

  editPremiumUser(premiumUser: any) {
    const editDialogConfig = new MatDialogConfig();

    // Setting different dialog configurations
    editDialogConfig.disableClose = true;
    editDialogConfig.autoFocus = true;

    premiumUser.isFromPremiumPage = true;
    premiumUser.isFromPremiumEditPage = true;
    editDialogConfig.data = premiumUser;
    editDialogConfig.panelClass = 'golp-dialog';

    const premiumUserEditDialog = this.dialog.open(CreatePremiumUserComponent, editDialogConfig);

    const successFullEdit = premiumUserEditDialog.componentInstance.onUserEditEvent.subscribe((data: any) => {
      this.premiumUserDetails = data;
      this.premiumUserDetailsInputChange.emit(this.premiumUserDetails);
    });

    premiumUserEditDialog.afterClosed().subscribe(() => {
    });
  }

}

