import { Component, OnInit, Input, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AlertService, CompanyOutletService } from 'src/app/services';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { OfferCodeVerificationDetails } from 'src/app/corporate-offers/models/offer-code-verification-details';
import { CorporateOfferService } from '../../../corporate-offers/services/corporate-offer.service';
import { CompanyOutlet } from 'src/app/models';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-offer-redemption',
  templateUrl: './offer-redemption.component.html',
  styleUrls: ['./offer-redemption.component.css']
})
export class OfferRedemptionComponent implements OnInit {

  @Input() offerDetailsInput: OfferCodeVerificationDetails;
  @ViewChild('purchaseamount') purchaseAmountRef: ElementRef;
  @ViewChild('payableAmountEL') payableAmountRef: ElementRef;

  redemptionForm: FormGroup
  submitted = false;

  userInfo: any;
  offerDetails: OfferCodeVerificationDetails;
  giftCode: any;
  giftPin: any;
  redeemSuccessMessage: string = "search gift/offer by code & PIN.";
  redeemSuccess: boolean = true;
  //offerService: any;

  showOutlets: boolean = false;
  payableAmount:number = 0;

  companyOutlets: CompanyOutlet[];
  outletFilterCtrl: FormControl = new FormControl();
  filteredOutlets: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private alertService: AlertService,
    private offerService: CorporateOfferService,
    private outletService: CompanyOutletService
  ) {
    this.offerDetails = new OfferCodeVerificationDetails();
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));

    this.showOutlets = this.userInfo.roles[0].name === 'MerchantAdmin';

    this.buildForm();

    
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['offerDetailsInput']) {
      setTimeout(() => {
        this.offerDetails = this.offerDetailsInput;
        console.log("offer details : ", this.offerDetails);
        if (this.offerDetails != null) {
          this.redeemSuccess = false;
          this.buildForm();
          setTimeout(() => {
            this.purchaseAmountRef.nativeElement.focus();
          }, 100);

        }
        if (this.offerDetails == null) {
          this.redeemSuccessMessage = "Offer does not found.";
          this.redeemSuccess = true;
        }
      });
    }
  }

  salesAmountChanged($event){
    let salesValue = Number($event.target.value);
    if(this.offerDetails.offerValueType == "Percent"){
      this.payableAmountRef.nativeElement.value = salesValue - Math.ceil(salesValue * (this.offerDetails.offerValue / 100))
    }
    else if(this.offerDetails.offerValueType =="Fixed"){
      let value = salesValue - this.offerDetails.offerValue;
      if(value < 0){
        this.payableAmountRef.nativeElement.value = 0;
      }
      else this.payableAmountRef.nativeElement.value = value;
    }
    else {}
  }

  buildForm() {
    this.redemptionForm = this.formBuilder.group({
      offerReceiverPhone: [{ value: this.offerDetails.offerReceiverPhone, disabled: true }],
      offerName: [{ value: this.offerDetails.offerName, disabled: true }],
      offerAmount: [{ value: this.offerDetails.offerAmount, disabled: true }],
      purchaseAmount: [this.offerDetails.purchaseAmount, [Validators.required]],
      merchantInvoiceNumber: [this.offerDetails.merchantInvoiceNumber],
      invoiceDetails: [this.offerDetails.invoiceDetails],
      outletId: [this.userInfo.outletQrCode, [Validators.required]],
      payableAmount:[this.payableAmount, [Validators.nullValidator]]
    });
  }

  ngOnInit() {
    this.getOutletsByCompany(this.userInfo.companyId);
  }

  onClickRedemption(item: any) {

    this.submitted = true;

    if (this.userInfo.outletQrCode != null && this.userInfo.outletQrCode != undefined) {
      this.redemptionForm.value.outletId = this.userInfo.outletQrCode;
    }

    // stop here if form is invalid
    if (this.redemptionForm.invalid) {
      return;
    }

    let newData = new OfferCodeVerificationDetails(this.redemptionForm.value);

    if (this.offerDetails != null) {
      this.offerDetails.purchaseAmount = newData.purchaseAmount;
      this.offerDetails.merchantInvoiceNumber = newData.merchantInvoiceNumber;
      this.offerDetails.outletId = newData.outletId;
      this.offerDetails.invoiceDetails = newData.invoiceDetails;
    }
    const confirmationDialogConfig = new MatDialogConfig();
    // Setting different dialog configurations
    confirmationDialogConfig.data = `Offer will be redeemed with the sales amount ${this.offerDetails.purchaseAmount}. Want to Proceed? `;
    confirmationDialogConfig.panelClass = "golp-dialog";
    const confirmationDialog = this.dialog.open(
      ConfirmationDialogComponent,
      confirmationDialogConfig
    );

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.applyRedemption(this.offerDetails);
      }
    });
  }

  applyRedemption(offerDetails: any) {
    let requestObject = {
      "offerId": offerDetails.offerId,
      "offerCode": offerDetails.offerCode,
      "pin": offerDetails.offerPin,
      "merchantId": this.userInfo.companyId,
      "purchaseAmount": offerDetails.purchaseAmount,
      "outletQRCode": offerDetails.outletId,
      "invoiceDetails":offerDetails.invoiceDetails,
      "merchantInvoiceNumber": this.offerDetails.merchantInvoiceNumber
    };

    this.offerService.redeemOffer(requestObject)
      .subscribe((response: any) => {
        if (response.success) {
          this.redeemSuccessMessage = "Offer redeemed successfully.";
          this.redeemSuccess = true;
          this.giftCode = '';
        }
        if (!response.success) {
          this.redeemSuccess = false;
          this.alertService.error(response.data);
        }
      }, (error) => {
        console.log(error);
      });
  }

  getOutletsByCompany(companyId) {
    if (companyId) {
      this.outletService.getOutletsByCompany(companyId)
        .subscribe((data: any) => {
          this.companyOutlets = data;
          this.filteredOutlets.next(this.companyOutlets);
        },
          (error: any) => {
            console.log(error);
          });
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.redemptionForm.controls; }

}
