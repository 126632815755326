import { Component, OnInit, Injector } from '@angular/core';
import { ReportService } from 'src/app/services/report.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
import { GiftTransactionReport, GiftAssignmentDtos } from '../../models/gift-transacion-report';
import { GetGiftTransactionReportInputDto } from '../../models/gift-transaction-report-input';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ShowOfferAssignmentDetailsComponent } from '../show-offer-assignment-details/show-offer-assignment-details.component';
import { SharedService } from '../../services/shared.service';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { GetGiftCustomerSupportInputDto } from '../../models';
import { ChangeGiftAssignmentComponent } from '../change-gift-assignment/change-gift-assignment.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { OfferAssignmentService, AlertService } from '../../services';
import { GiftCustomerSupportReport } from '../../models/gift-customer-support-report';
import { BaseComponent } from '../base/base.component';
import { ConfirmationWithTextComponent } from '../confirmation-with-text/confirmation-with-text.component';
import { ExtendGiftExpireDateComponent } from '../extend-gift-expire-date/extend-gift-expire-date.component';

@Component({
  selector: 'app-gift-customer-support',
  templateUrl: './gift-customer-support.component.html',
  styleUrls: ['./gift-customer-support.component.css']
})

export class GiftCustomerSupportComponent extends BaseComponent implements OnInit {
  @BlockUI('gift-customer-support') blockUI: NgBlockUI;

  giftCustomerSupportForm: FormGroup;
  input: GetGiftCustomerSupportInputDto;
  filterTypes: any[];
  paymentMethods: any[];
  paymentStatuses: any[];

  currentPage: number = 1;
  pageSize: number = 25;
  expandedIndex: number = -1;
  totalItems: number = 0;

  defaultStartDate: Date;
  defaultEndDate: Date;
  paginationPageNumbers: any[];
  currentPageNumberDetails: string;


  constructor(private formBuilder: FormBuilder, private reportService: ReportService, private alertService: AlertService,
    private dialog: MatDialog, private sharedService: SharedService,
    private offerAssignmentService: OfferAssignmentService, private injector: Injector) {
    super(injector);

    this.input = new GetGiftCustomerSupportInputDto();

    var startDate1 = new Date();
    //startDate1.setDate(startDate1.getDate() - 7);
    this.defaultStartDate = startDate1;
    this.defaultEndDate = new Date();
    this.buildForm();
  }

  ngOnInit() {
    this.paginationPageNumbers = this.sharedService.getPaginationByPageNumbers();
    //this.getCustomerSupportsGift();
  }

  giftTransactionReports: GiftCustomerSupportReport[] = [];


  buildForm() {
    this.giftCustomerSupportForm = this.formBuilder.group({
      fromDate: [this.defaultStartDate, Validators.required],
      toDate: [this.defaultEndDate, Validators.required],
      senderMobileNumber: '',
      receiverMobileNumber: ''
    });
  }


  expandCollapse(index) {
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }


  getCustomerSupportsGift() {
    this.input.from = moment(this.f.fromDate.value).format("YYYY/MM/DD");
    this.input.to = moment(this.f.toDate.value).format("YYYY/MM/DD");
    this.input.senderMobileNumber = this.f.senderMobileNumber.value;
    this.input.receiverMobileNumber = this.f.receiverMobileNumber.value;
    this.input.pageNumber = this.currentPage;
    this.input.pageSize = this.pageSize;

    if (this.giftCustomerSupportForm.invalid) {
      return;
    }

    this.blockUI.start();

    this.reportService.getGiftCustomerSupportReport(this.input).subscribe(result => {
      this.giftTransactionReports = result.data;
      this.totalItems = result.rowCount;
      this.currentPageNumberDetails = "(Showing " + result.firstRowOnPage + " to " + result.lastRowOnPage + " of " + result.rowCount + " items)"
      this.blockUI.stop();

    }, error => {
      this.blockUI.stop();
    });
  }

  showMakeOpenMerchantDailog(assignmentId: string) {
    const confirmationDialogConfig = new MatDialogConfig()
    var data = {
      "title": 'Open Gift Card',
      "message": 'Are you sure to make open merchant for this gift?'
    };

    var successMessage = "Gift merchant made open successfully";

    confirmationDialogConfig.data = data;
    confirmationDialogConfig.panelClass = 'golp-dialog';
    const confirmationDialog = this.dialog.open(ConfirmationWithTextComponent, confirmationDialogConfig);


    confirmationDialog.afterClosed().subscribe((result) => {
      if (result != undefined) {
        var obj = { offerAssignmentId: assignmentId, note: result.note };

        this.offerAssignmentService.makeOpenMerchant(obj).subscribe(result => {
          this.alertService.success(successMessage);
          this.getCustomerSupportsGift();
        });
      }
    });
  }

  showChangeAssignmentDailog(assignmentId: any, giftReceiverPhoneNumber: string) {
    const userDialogConfig = new MatDialogConfig();

    userDialogConfig.disableClose = true;
    userDialogConfig.autoFocus = true;
    userDialogConfig.data = { assignmentId, giftReceiverPhoneNumber };
    userDialogConfig.panelClass = 'golp-dialog';

    const showOfferAssignmentDialog = this.dialog.open(ChangeGiftAssignmentComponent, userDialogConfig);

    const successfullChange = showOfferAssignmentDialog.componentInstance.onChangeGiftAssignmentEvent.subscribe((data) => {
      //var aa = this.giftTransactionReports.find(i => i.offerAssignmentId == data.offerAssignmentId);

      //if (aa != null) {
      //  aa.giftReceiverPhoneNumber = data.newPhoneNumber;
      //}
      this.getCustomerSupportsGift();
    });

    showOfferAssignmentDialog.afterClosed().subscribe(() => {
    });
  }


  showExtendGiftExpireDateDailog(assignmentId: any, currentExpiredDate: string) {
    const userDialogConfig = new MatDialogConfig();

    userDialogConfig.disableClose = true;
    userDialogConfig.autoFocus = true;
    userDialogConfig.data = { assignmentId, currentExpiredDate };
    userDialogConfig.panelClass = 'golp-dialog';

    const showOfferAssignmentDialog = this.dialog.open(ExtendGiftExpireDateComponent, userDialogConfig);

    const successfullChange = showOfferAssignmentDialog.componentInstance.onExtendGiftExpireDateEvent.subscribe((data) => {
      //var aa = this.giftTransactionReports.find(i => i.offerAssignmentId == data.offerAssignmentId);

      //if (aa != null) {
      //  aa.giftReceiverPhoneNumber = data.newPhoneNumber;
      //}
      this.getCustomerSupportsGift();
    });

    showOfferAssignmentDialog.afterClosed().subscribe(() => {
    });
  }

  get f() { return this.giftCustomerSupportForm.controls; }


  //exportToExcel() {
  //  this.input.from = moment(this.f.fromDate.value).format("YYYY/MM/DD");
  //  this.input.to = moment(this.f.toDate.value).format("YYYY/MM/DD");
  //  this.input.type = this.f.type.value;
  //  this.input.paidThrough = this.f.paidThrough.value;
  //  this.input.paymentStatus = this.f.paymentStatus.value;
  //  this.input.pageNumber = this.currentPage;
  //  this.input.pageSize = this.pageSize;


  //  this.reportService.getConsumerGiftGenerationExcelReport(this.input)
  //    .subscribe(data => this.downloadFile(data)),//console.log(data),
  //    error => console.log("Error downloading the file."),
  //    () => console.log('Completed file download.');
  //}


  downloadFile(data) {
    var filterText = this.filterTypes.filter(x => x.value == this.f.type.value)[0].text;
    const blob2 = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var filename = filterText + "-" + "ConsumerGiftReports -" + moment().format('MMMM Do YYYY, h:mm:ss').valueOf() + ".xlsx";
    saveAs(blob2, filename);
  }

  pageChanged(event) {
    this.currentPage = event;
    this.getCustomerSupportsGift();
  }

  itemCount() {
    let firstItem = (this.currentPage - 1) * this.pageSize + 1;
    let lastItem = (this.currentPage) * this.pageSize;
  }
}
