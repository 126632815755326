import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';

import { Company, Offer, CompanyOutlet, OfferOutlet, GiftAmountSetting } from 'src/app/models';
import { PaymentMethod, PaymentOption, PayableAmountMode, OfferType, CompanyRole } from 'src/app/enums';
import { CompanyService, AlertService, OfferService, CompanyOutletService, AccountServiceService, FileUploadService } from 'src/app/services';
import { ReplaySubject } from 'rxjs';
import { CompanySettingType } from '../../enums/company-setting.type';
import { CompanySettingService } from '../../services/company-setting.service';
import { ValidationUtility } from '../../utility/validation.utility';
import { PaymentOptionsComponent } from '../payment-options/payment-options.component';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-create-offer',
  templateUrl: './create-offer.component.html',
  styleUrls: ['./create-offer.component.css']
})
export class CreateOfferComponent implements OnInit {
  // onCreate event
  @Output() onOfferCreateEvent: EventEmitter<Offer> = new EventEmitter();
  // onEdit event;
  @Output() onOfferEditEvent: EventEmitter<Offer> = new EventEmitter();

  userInfo: any;
  submitted = false;

  offerForm: FormGroup;
  uploadedFiles: FormData;

  showOfferProviderSelection: boolean = true;
  inEditMode: boolean = false;
  isOwnOffer: boolean = false;
  isOutletSpecific: boolean = false;
  selectedOfferProviderId: number;
  selectedMerchantId: number;

  fileName: string = '';
  imagePreviewPath: string = '../../../assets/images/default.png';
  fileValidationError: string;

  offer: Offer;
  offerProviders: Company[];
  merchants: Company[];
  companyOutlets: CompanyOutlet[];
  selectedOutlets: string[];

  showOfferPriceSection: boolean = false;
  showPayableAmountSection: boolean = false;
  showAssignmentPerUserControl: boolean = false;
  showGiftSlabs: boolean = false;

  paymentOptions = PaymentOption;
  paymentOptionsKeys: any[];

  payableAmountMode = PayableAmountMode;
  payableAmountModeKeys: any[];

  offerType = OfferType;
  offerTypeKeys: any[];

  globalGiftAmountSetting: GiftAmountSetting;
  globalMinAmount: number = 0;
  globalMaxAmount?: number;

  minAmount: number;
  maxAmount?: number;
  useSlab: boolean = false;
  slabs: any;

  bundleOffers: any[];
  showBundleOfferSection: boolean = false;
  merchantHasBundleOffer: boolean = true;

  offerProviderFilterCtrl: FormControl = new FormControl();
  filteredOfferProviders: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);
  offerTypeKeyFilterCtrl: FormControl = new FormControl();
  filteredOfferTypeKeys: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  merchantFilterCtrl: FormControl = new FormControl();
  filteredMerchants: ReplaySubject<Company[]> = new ReplaySubject<Company[]>(1);
  offerPriceSlab: any;


  constructor(private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateOfferComponent>,
    @Inject(MAT_DIALOG_DATA) data, private companyService: CompanyService,
    private outletService: CompanyOutletService,
    private companySettingService: CompanySettingService,
    private offerService: OfferService,
    private alertService: AlertService,
    private accountService: AccountServiceService,
    private fileUploadService: FileUploadService,
    private dialog: MatDialog
  ) {
    this.getLoggedInUser();
    this.getOfferProviders();
    this.getMerchants();
    this.paymentOptionsKeys = Object.keys(this.paymentOptions).filter(Number);
    this.payableAmountModeKeys = Object.keys(this.payableAmountMode).filter(Number);
    this.offerTypeKeys = Object.keys(this.offerType).filter(Number)
      .filter(c => c != '3' && c != '5' && c != '6');
    // When offerTypeKeys comes from service offerTypeKeys need to be populate in subscribe.
    this.filteredOfferTypeKeys.next(this.offerTypeKeys);

    this.offer = new Offer();
    this.setEditModeData(data);
  }

  async ngOnInit() {
    // if(CompanyRole.OfferProvider === this.userInfo.companyRole && !this.userInfo.isSystemAdmin){
    //   this.showOfferProviderSelection = false;
    // }
    this.buildForm();
    this.bindFilterChangeEvent();
    this.getGlobalSettings();
    await this.getSettingsBySelectedCompany(this.selectedMerchantId);
  }

  setEditModeData(data: any) {
    if (data !== null) {
      this.offer = new Offer(data);
      this.imagePreviewPath = this.offer.offerImageUrl;
      if (this.offer.offerProviderId === this.offer.merchantId) {
        this.offer.ownOffer = this.isOwnOffer = true;
      }

      // if(data.offerProviderPaymentMethod === PaymentMethod.ThroughGolp){
      //   this.showOfferPriceSection = true;
      // }
      if (this.offer.paymentOption === PaymentOption.Partial) {
        this.showPayableAmountSection = true;
      }

      this.inEditMode = true;
      this.isOutletSpecific = this.offer.isOutletSpecific;
      this.selectedMerchantId = this.offer.merchantId;
      this.showAssignmentPerUserControl = this.offer.isMultiTimeAssignable;
      if (this.isOutletSpecific) {
        this.getOutletsByCompany(this.selectedMerchantId);
        this.selectedOutlets = Array.from(this.offer.offerOutlets, c => c.companyOutletId);
      }
      this.fileName = this.offer.imageName;
    }
    else {
      this.offer.isMultiTimeAssignable = false;
      this.offer.assignmentPerUser = 0;
      this.offer.offerType = this.offerType.CompanyGift;

    }
  }

  buildForm() {
    this.offerForm = this.formBuilder.group({
      name: [this.offer.name, [Validators.required, Validators.maxLength(250)]],
      offerShortName: [this.offer.offerShortName],
      offerCode: this.offer.offerCode,
      maximumConsumer: [this.offer.maximumConsumer],
      description: [this.offer.description, [Validators.maxLength(500)]],
      offerProviderId: [this.offer.offerProviderId, Validators.required],
      merchantId: [this.offer.merchantId],
      ownOffer: this.offer.ownOffer,
      isOutletSpecific: this.offer.isOutletSpecific,
      offerPrice: [this.offer.offerPrice, [Validators.required, Validators.pattern('^\\d+(\\.\\d{1,2})?$'), Validators.pattern('^[1-9]+[0-9]*$'),
      (control: AbstractControl) => Validators.max(this.maxAmount)(control),
      (control: AbstractControl) => Validators.min(this.minAmount)(control)]],
      offerPriceSlab: [this.offerPriceSlab],
      paymentOption: this.offer.paymentOption ? this.offer.paymentOption.toString() : '',
      payableAmount: this.offer.payableAmount,
      payableAmountMode: this.offer.payableAmountMode ? this.offer.payableAmountMode.toString() : '',
      //offerOutlets: '' // Array.from(this.offer.offerOutlets, c => c.companyOutletId),
      isMultiTimeAssignable: this.offer.isMultiTimeAssignable,
      assignmentPerUser: [this.offer.assignmentPerUser],
      offerType: [this.offer.offerType ? this.offer.offerType.toString() : '', [Validators.required]],
      referenceOfferId: [this.offer.referenceOfferId],
      isScheduled: [this.offer.isScheduled]
    });

    this.isOwnerValueChanged();
    this.isMultiTimeAssignableChanged();
    this.onPriceChange();
    //this.isOfferProviderValueChanged();
  }

  openFileBrowser(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById('offerPhotoId') as HTMLElement;
    element.click();
  }

  isOwnerValueChanged() {
    const offerProviderControl = this.offerForm.get('offerProviderId');

    this.offerForm.get('ownOffer').valueChanges
      .subscribe((mode: boolean) => {
        if (mode) {
          offerProviderControl.clearValidators();
        }
        else {
          offerProviderControl.setValidators([Validators.required]);
        }
        offerProviderControl.updateValueAndValidity();
      });
    if (CompanyRole.GiftSender === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
      offerProviderControl.clearValidators();
      offerProviderControl.updateValueAndValidity();
    }
  }

  isMultiTimeAssignableChanged() {
    const assignmentPerUserControl = this.offerForm.get('assignmentPerUser');

    this.offerForm.get('isMultiTimeAssignable').valueChanges
      .subscribe((mode: boolean) => {
        if (mode) {
          assignmentPerUserControl.setValidators([Validators.required, Validators.pattern('^[1-9]+[0-9]*$')]);
        }
        else {
          assignmentPerUserControl.clearValidators();
        }
        assignmentPerUserControl.updateValueAndValidity();
      });

  }

  // isOfferProviderValueChanged(){
  //   const offerPriceControl = this.offerForm.get('offerPrice');
  //   this.offerForm.get('offerProviderId').valueChanges
  //     .subscribe((offerProviderId: number) => {
  //       if(this.isPaymentMethodThroughGolp(offerProviderId)){
  //         offerPriceControl.setValidators([Validators.required]);
  //       }
  //       else{
  //         offerPriceControl.clearValidators();
  //       }
  //       offerPriceControl.updateValueAndValidity();
  //   });
  // }

  isPaymentMethodThroughGolp(offerProviderId: number) {
    let offerorPaymentMethod = this.offerProviders.filter(c => c.id == offerProviderId)[0].paymentMethod;
    return offerorPaymentMethod === PaymentMethod.ThroughGolp ? true : false;
  }

  onChangeOwnOffer(event: MatCheckboxChange) {
    if (this.isOwnOffer === true) {
      this.isOwnOffer = false;
    }
    else {
      this.isOwnOffer = true;
    }

  }
  onChangeOutletSpecific(event: MatCheckboxChange) {
    if (this.isOutletSpecific === true) {
      this.isOutletSpecific = false;
    }
    else {
      this.isOutletSpecific = true;
    }
    if (this.isOutletSpecific) {
      this.getOutletsByCompany(this.selectedMerchantId);
    }
  }

  onChangeOfferProvider(offerProviderId) {
    this.selectedOfferProviderId = offerProviderId;
    this.showOfferPriceSection = this.isPaymentMethodThroughGolp(offerProviderId);
  }

  //onChangeOfferType(offerTypeKey: number) {
  //    this.showBundleOfferSection = !this.showBundleOfferSection && offerTypeKey == OfferType.Bundle ? true : false;
  //    if (this.showBundleOfferSection && this.selectedMerchantId != null) {
  //        this.getBundleOfferByMerchant(this.selectedMerchantId);
  //    }
  //    this.setDefaultValue(this.selectedMerchantId, offerTypeKey);
  //}

  onChangeMerchant(merchantId) {
    this.selectedMerchantId = merchantId;
    this.getSettingsBySelectedCompany(merchantId);

    if (this.isOutletSpecific) {
      this.getOutletsByCompany(merchantId);
    }
    //if (this.showBundleOfferSection) {
    //    this.getBundleOfferByMerchant(merchantId);
    //}
  }

  //onChangeBundleOffer(bundleOffer) {
  //    console.log(bundleOffer);
  //    this.setDefaultValue(bundleOffer, this.offer.offerType);
  //}

  onChangeMultiTimeAssignable(event: MatCheckboxChange) {
    this.showAssignmentPerUserControl = this.showAssignmentPerUserControl ? false : true;
  }

  onChangePaymentOption(paymentOptionKey: number) {
    let optionKey = PaymentOption[paymentOptionKey];
    if (optionKey === PaymentOption[PaymentOption.Partial]) {
      this.showPayableAmountSection = true;
    }
    else {
      this.showPayableAmountSection = false;
    }
  }
  onPriceChange() {
    this.offerForm.get('offerPrice').valueChanges
      .subscribe((value) => {
        let shortName = `${value} Taka Gift`;
        this.offerForm.get('offerShortName').setValue(shortName);
      });

    this.offerForm.get('offerPriceSlab').valueChanges
      .subscribe((value) => {
        let shortName = `${value ? value : ""} Taka Gift`;
        this.offerForm.get('offerShortName').setValue(shortName);
      });
  }

  uploadLogo(files) {
    this.fileValidationError = null;
    let fileInfo = this.fileUploadService.imageFileUpload(files);
    if (fileInfo.validationError != null) {
      this.fileValidationError = fileInfo.validationError;
      return;
    }
    this.fileName = fileInfo.fileName;
    this.uploadedFiles = fileInfo.formData;

    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imagePreviewPath = event.target.result;
    }
    reader.readAsDataURL(files[0]);
  }

  onSubmit() {
    this.submitted = true;
    const formData = new FormData();
    this.offer.offerType = OfferType.CompanyGift;

    // stop here if form is invalid
    if (this.offerForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    if (this.offer.id === undefined) { // create
      this.createOffer();
    }
    else if (this.offer.id > 0) { // edit
      this.editOffer();
    }

    this.dialogRef.close();
  }

  createOffer() {
    this.offer = new Offer(this.offerForm.value);
    if (this.offer !== null) {
      if (this.useSlab) {
        this.offer.offerPrice = this.offerPriceSlab;
      }
      this.offer.isActive = false;
      if (this.offer.ownOffer) {
        this.offer.offerProviderId = this.offer.merchantId;
        this.offer.isActive = true;
      }
      //if (this.offer.offerType == OfferType.Bundle) {
      //    this.offer.isActive = true;
      //    let bundleOffer = this.bundleOffers.filter(c => c.id == this.offer.referenceOfferId)[0];
      //    if (bundleOffer != null) {
      //        this.offer.name = bundleOffer.name;
      //        this.offer.offerShortName = bundleOffer.offerShortName;
      //        this.offer.offerPrice = bundleOffer.discountedPrice;
      //        this.offer.startDate = bundleOffer.startDate;
      //        this.offer.endDate = bundleOffer.endDate;
      //    }
      //}
      this.offer.isOutletSpecific = this.isOutletSpecific;
      this.offer.offerOutlets = [];
      this.offer.paymentOption = PaymentOption.Full;

      if (PaymentOption[this.offer.paymentOption] === PaymentOption[PaymentOption.Full]) {
        this.offer.payableAmount = 0;
        this.offer.payableAmountMode = PayableAmountMode.None;
      }
      if (this.offer.paymentOption in PaymentOption) {
        // do nothing
      }
      else {
        this.offer.paymentOption = PaymentOption.None;
        this.offer.payableAmount = 0;
        this.offer.payableAmountMode = PayableAmountMode.None;
        this.offer.offerPrice = 0;
      }
      this.offer.offerType = OfferType.CompanyGift;
      this.offer.offerProvider = this.offerProviders.filter(c => c.id === this.offer.offerProviderId)[0].name;
    }
    this.generateOfferOutletItems(this.offer);

    this.offerService.createOffer(this.offer)
      .subscribe((data: any) => {
        this.offer.id = data.id;
        let logoChanged = false;
        // upload company logo
        if (this.uploadedFiles != null) {
          logoChanged = true;
          this.uploadedFiles.append('id', data.id)
          this.offerService.uploadFile(data.id, this.uploadedFiles).subscribe((result: any) => {
            this.offer.offerImageUrl = result.offerImageUrl;
            this.onOfferCreateEvent.emit(this.offer);
            this.alertService.success('Gift created successfully');
          },
            (error: any) => {
              this.showErrorMessage(error);
            });
        }
        if (!logoChanged) {
          this.offer.offerImageUrl = this.imagePreviewPath;
          this.onOfferCreateEvent.emit(this.offer);
          this.alertService.success('Gift created successfully');
        }
        //this.selectpaymentOption();
      },
        (error: any) => {
          this.showErrorMessage(error);
        });
  }

  //selectpaymentOption() {
  //    const dialogConfig = new MatDialogConfig();

  //    // Setting different dialog configurations
  //    dialogConfig.disableClose = true;
  //    dialogConfig.autoFocus = true;
  //    dialogConfig.panelClass = 'golp-dialog';
  //    dialogConfig.data = this.offer;
  //    const offerCreateDialog = this.dialog.open(PaymentOptionsComponent, dialogConfig);

  //    const successfullCreate = offerCreateDialog.componentInstance.onOptionSelectionEvent.subscribe((data) => {
  //        this.onOfferCreateEvent.emit(data);
  //        //this.onOfferEditEvent.emit(this.offer);
  //    });

  //    offerCreateDialog.afterClosed().subscribe(() => {
  //    });
  //}

  editOffer() {
    let newData = new Offer(this.offerForm.value);
    if (this.offer !== null) {
      if (this.useSlab) {
        this.offer.offerPrice = this.offerPriceSlab;
      }
      //this.offer.offerType = OfferType.Personalized;
      this.offer.name = newData.name;
      this.offer.offerShortName = newData.offerShortName;
      this.offer.offerCode = newData.offerCode;
      this.offer.description = newData.description;
      this.offer.maximumConsumer = newData.maximumConsumer;
      this.offer.isOutletSpecific = newData.isOutletSpecific;

      this.offer.offerProviderId = newData.offerProviderId;
      this.setOfferProviderDetails(this.offer);

      if (newData.merchantId != null) {
        this.offer.merchantId = newData.merchantId;
        this.setMerchantDetails(this.offer.merchantId);
      }
      this.offer.offerPrice = newData.offerPrice;
      this.offer.isMultiTimeAssignable = newData.isMultiTimeAssignable;
      this.offer.isScheduled = newData.isScheduled;
      this.offer.assignmentPerUser = newData.assignmentPerUser;

      this.offer.payableAmount = newData.payableAmount;
      this.offer.payableAmountMode = newData.payableAmountMode in PayableAmountMode ? newData.payableAmountMode : PayableAmountMode.None;
      //this.offer.paymentOption = newData.paymentOption in PaymentOption ? newData.paymentOption : PaymentOption.None;
      this.offer.paymentOption = PaymentOption.Full;
      if (PaymentOption[this.offer.paymentOption] === PaymentOption[PaymentOption.Full]) {
        this.offer.payableAmount = 0;
        this.offer.payableAmountMode = PayableAmountMode.None;
      }
      if (this.offer.paymentOption in PaymentOption) {
        // do nothing
      }
      else {
        this.offer.paymentOption = PaymentOption.None;
        this.offer.payableAmount = 0;
        this.offer.payableAmountMode = PayableAmountMode.None;
        this.offer.offerPrice = 0;
      }

      this.offer.offerOutlets = [];
      this.generateOfferOutletItems(this.offer);

      this.offerService.editOffer(this.offer)
        .subscribe((data: any) => {
          let logoChanged = false;
          //change logo if updated
          if (this.uploadedFiles != null) {
            logoChanged = true;
            // upload company logo
            this.uploadedFiles.append('id', this.offer.id.toString())
            this.offerService.uploadFile(this.offer.id, this.uploadedFiles).subscribe((result: any) => {
              this.offer.offerImageUrl = result.offerImageUrl;
              this.onOfferEditEvent.emit(this.offer);
              this.alertService.success('Gift edited successfully');
            },
              (error: any) => {
                this.showErrorMessage(error);
              });
          }
          if (!logoChanged) {
            this.onOfferEditEvent.emit(this.offer);
            this.alertService.success('Gift edited successfully');
          }
        },
          (error: any) => {
            this.showErrorMessage(error);
          })
    }
  }

  generateOfferOutletItems(offer: Offer) {
    if (this.isOutletSpecific) {
      for (let item of this.selectedOutlets) {
        let outlet = new OfferOutlet();
        outlet.companyOutletId = item;

        this.offer.offerOutlets.push(outlet);
      }
    }
  }

  setOfferProviderDetails(offer: Offer) {
    let offerProvider: Company;
    if (offer.ownOffer) {
      offerProvider = this.merchants.filter(c => c.id == offer.offerProviderId)[0];
    }
    else {
      offerProvider = this.offerProviders.filter(c => c.id == offer.offerProviderId)[0];
    }

    this.offer.offerProvider = offerProvider.name;
    this.offer.offerProviderLogoUrl = offerProvider.companyLogoUrl;
    this.offer.offerProviderPaymentMethod = offerProvider.paymentMethod;

  }
  setMerchantDetails(merchantId: number) {
    let provider = this.merchants.filter(c => c.id == merchantId)[0];
    this.offer.merchant = provider.name;
    this.offer.merchantLogoUrl = provider.companyLogoUrl;
  }

  setDefaultValue(bundleOfferId: number, offerType: OfferType) {
    let nameControl = this.offerForm.get('name');
    let shortNameControl = this.offerForm.get('offerShortName');
    let offerPriceControl = this.offerForm.get('offerPrice');

    //if (offerType == OfferType.Bundle) {
    //    let bundleOffer = this.bundleOffers.filter(c => c.id == bundleOfferId)[0];
    //    if (bundleOffer != null) {
    //        nameControl.setValue(bundleOffer.name);
    //        nameControl.disable();
    //        shortNameControl.setValue(bundleOffer.offerShortName);
    //        shortNameControl.disable();
    //        offerPriceControl.setValue(bundleOffer.discountedPrice);
    //        offerPriceControl.disable();
    //    }
    //}
    if (offerType == OfferType.CompanyGift) {
      nameControl.setValue('');
      nameControl.enable();
      shortNameControl.setValue('');
      shortNameControl.enable();
      offerPriceControl.setValue('');
      offerPriceControl.enable();
    }
  }

  showErrorMessage(error: any) {
    console.log(error);
    //this.alertService.error("Internal server error happen");
  }

  getLoggedInUser() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
    console.log(this.userInfo);
  }

  getOfferProviders() {
    if (CompanyRole.GiftSender === this.userInfo.companyRole && !this.userInfo.isSystemAdmin) {
      this.companyService.getCompanyById(this.userInfo.companyId)
        .subscribe((data: Company) => {
          this.offerProviders = [];
          this.offerProviders.push(data);
          this.showOfferProviderSelection = false;
          this.offerForm.get('offerProviderId').setValue(this.offerProviders[0].id);
          this.filteredOfferProviders.next(this.offerProviders);
        });
    } else {
      this.companyService.getOfferProviders()
        .subscribe(data => {
          this.offerProviders = data;
          this.filteredOfferProviders.next(this.offerProviders);

        });
    }
  }

  getMerchants() {
    this.companyService.getMerchants()
      .subscribe(data => {
        this.merchants = data;
        this.filteredMerchants.next(this.merchants);
      });
  }

  resetMerchant() {
    this.offerForm.controls.merchantId.reset();
    this.offer.merchantId = null;
  }

  //getBundleOfferByMerchant(merchantId: number) {
  //    this.offerService.getSpecializedOfferByMerchant(merchantId, OfferType.Bundle)
  //        .subscribe(data => {
  //            this.bundleOffers = data;
  //            this.merchantHasBundleOffer = this.bundleOffers.length > 0 ? true : false;
  //        });
  //}

  async getSettingsBySelectedCompany(merchantId: number) {
    this.companySettingService.getSettingsByCompanyAndType(CompanySettingType.GiftAmount, merchantId).subscribe(result => {
      if (result) {
        var giftAmountSetting = new GiftAmountSetting(JSON.parse(result.value));
        this.minAmount = giftAmountSetting.minAmount;
        this.maxAmount = giftAmountSetting.maxAmount ? giftAmountSetting.maxAmount : undefined;
        this.useSlab = giftAmountSetting.useSlab;
        this.slabs = giftAmountSetting.slabs;

        if (this.inEditMode && this.useSlab)
          this.offerForm.get('offerPriceSlab').setValue(this.offer.offerPrice);

      }
      else {
        this.minAmount = this.globalMinAmount;
        this.maxAmount = this.globalMaxAmount;
        this.useSlab = false;
      }

      this.setOfferPriceOrSlabValidators();
    }, error => {

    });

  }

  getGlobalSettings() {
    this.companySettingService.getSettingsByCompanyAndType(CompanySettingType.GiftAmount).subscribe(result => {
      if (result) {
        this.globalGiftAmountSetting = new GiftAmountSetting(JSON.parse(result.value));
        this.globalMinAmount = this.globalGiftAmountSetting.minAmount;
        this.globalMaxAmount = this.globalGiftAmountSetting.maxAmount ? this.globalGiftAmountSetting.maxAmount : undefined;
        this.minAmount = this.globalMinAmount;
        this.maxAmount = this.globalMaxAmount;
        this.useSlab = false;
      }
    });
  }

  getOutletsByCompany(companyId) {
    if (companyId !== undefined) {
      this.outletService.getOutletsByCompany(companyId)
        .subscribe((data: any) => {
          this.companyOutlets = data;
        },
          (error: any) => {
            console.log(error);
          });
    }
  }

  bindFilterChangeEvent() {
    this.offerProviderFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterData(this.offerProviders, this.offerProviderFilterCtrl.value, this.filteredOfferProviders);
      });

    this.offerTypeKeyFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterGiftType();
      });

    this.merchantFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterData(this.merchants, this.merchantFilterCtrl.value, this.filteredMerchants);
      });
  }

  filterData(allData: any, search: string, filterData: any) {
    if (!allData) {
      return;
    }
    if (!search) {
      filterData.next(allData);
      return;
    } else {
      search = search.toLowerCase();
    }
    filterData.next(
      allData.filter(data => data.name.toLowerCase().indexOf(search) > -1)
    );
  }

  filterGiftType() {
    if (!this.offerTypeKeys) {
      return;
    }

    let search = this.offerTypeKeyFilterCtrl.value;
    if (!search) {
      this.filteredOfferTypeKeys.next(this.offerTypeKeys);
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredOfferTypeKeys.next(
      this.offerTypeKeys.filter(key => this.offerType[key].toLowerCase().indexOf(search) > -1)
    );
  }

  close() {
    this.dialogRef.close();
  }

  // convenience getter for easy access to form fields
  get f() { return this.offerForm.controls; }

  setOfferPriceOrSlabValidators() {
    const offerPriceControl = this.offerForm.get('offerPrice');
    const offerPriceSlabControl = this.offerForm.get('offerPriceSlab');

    if (this.useSlab == false) {
      offerPriceControl.setValidators([Validators.required]);
      offerPriceSlabControl.setValidators(null);
    }

    if (this.useSlab == true) {
      offerPriceControl.setValidators(null);
      offerPriceSlabControl.setValidators([Validators.required]);
    }

    offerPriceControl.updateValueAndValidity();
    offerPriceSlabControl.updateValueAndValidity();
  }
}
