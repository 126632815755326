import { Component, OnInit, Inject, EventEmitter, Injector } from '@angular/core';
import { Company, CompanyCategory } from 'src/app/models';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, ReplaySubject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyService, CompanyCategoryService, CommonService, AlertService, FileUploadService, AccountServiceService } from 'src/app/services';
import { CompanyRole, PaymentMethod, UserType, CompanyType } from 'src/app/enums';
import { ValidationUtility } from '../../utility/validation.utility';
import { BaseComponent } from '../base/base.component';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-create-offer-provider',
  templateUrl: './create-offer-provider.component.html',
  styleUrls: ['./create-offer-provider.component.css']
})
export class CreateOfferProviderComponent extends BaseComponent implements OnInit {

  // onCreate event
  onOfferProviderCreateEvent: EventEmitter<Company> = new EventEmitter();
  // onEdit event;
  onOfferProviderEditEvent: EventEmitter<Company> = new EventEmitter();

  userInfo: any;
  setDiscountEligibility: boolean = true;
  companyCategories: CompanyCategory[];
  offerProvider: Company;

  categoryForm = new FormControl();
  filteredCategory: Observable<CompanyCategory[]>;

  paymentOptions = PaymentMethod;
  paymentOptionsKeys: any[];


  companyTypes = CompanyType;
  companyTypeKeys: any[];

  offerProviderCreationForm: FormGroup;
  submitted = false;
  uploadedFiles: FormData;
  inEditMode: boolean = false;

  fileName: string = '';
  imagePreviewPath: string = '../../../assets/images/default.png';
  fileValidationError: string;

  discountHolderRequired: boolean = false;

  PaymentMethodsDisplay: { [index: number]: string } = {};

  companyCategoryFilterCtrl: FormControl = new FormControl();
  filteredCompanyCategories: ReplaySubject<CompanyCategory[]> = new ReplaySubject<CompanyCategory[]>(1);

  constructor(private companyCategoryService: CompanyCategoryService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateOfferProviderComponent>,
    @Inject(MAT_DIALOG_DATA) data, private companyService: CompanyService,
    private commonService: CommonService,
    private alertService: AlertService,
    private fileUploadService: FileUploadService,
    private accountService: AccountServiceService,
    private injector: Injector
  ) {
    super(injector);
    this.getLoggedInUser();
    this.PaymentMethodsDisplay = this.commonService.getPaymentMethodDisplayNames();
    this.paymentOptionsKeys = Object.keys(this.paymentOptions).filter(Number);
    this.companyTypeKeys = Object.keys(this.companyTypes).filter(Number);
    this.offerProvider = new Company();
    this.offerProvider.isGeneralDiscountEligible = false;

    if (data !== null) {
      this.offerProvider = new Company(data);
      this.inEditMode = true;
      this.fileName = this.offerProvider.imageName;
      this.imagePreviewPath = this.offerProvider.companyLogoUrl;
    }
    this.buildForm();
    this.initUI();
  }

  ngOnInit() {
    this.getCompanyCategories();
    this.bindFilterChangeEvent();
  }

  buildForm() {
    this.offerProviderCreationForm = this.formBuilder.group({
      name: [this.offerProvider.name, [Validators.required, Validators.maxLength(250)]],
      shortName: [this.offerProvider.shortName, [Validators.required, Validators.maxLength(10)]],
      phone: [this.offerProvider.phone, Validators.maxLength(50)],
      email: [this.offerProvider.email, [Validators.email, Validators.maxLength(50)]],
      address: [this.offerProvider.address, [Validators.maxLength(250)]],
      webAddress: [this.offerProvider.webAddress, [Validators.maxLength(100)]],
      companyCategoryId: [this.offerProvider.companyCategoryId, Validators.required],
      companyType: [this.offerProvider.companyType ? this.offerProvider.companyType.toString() : '', [Validators.required]],
      paymentMethod: [this.offerProvider.paymentMethod ? this.offerProvider.paymentMethod.toString() : '', Validators.required],
      isGeneralDiscountEligible: this.offerProvider.isGeneralDiscountEligible,
      maxDiscountHolder: [this.offerProvider.maxDiscountHolder],
    });
    this.isDiscountEligibilityChanged();
  }

  isDiscountEligibilityChanged() {
    const maxDiscountHolderControl = this.offerProviderCreationForm.get('maxDiscountHolder');
    this.offerProviderCreationForm.get('isGeneralDiscountEligible').valueChanges
      .subscribe((data: boolean) => {
        if (data) {
          maxDiscountHolderControl.setValidators([Validators.required, Validators.pattern('^[1-9]+[0-9]*$')]);
        }
        else {
          maxDiscountHolderControl.clearValidators();
        }
        maxDiscountHolderControl.updateValueAndValidity();
      });
  }

  initUI() {
    if (!this.userInfo.isSystemAdmin && CompanyRole.GiftSender == this.userInfo.companyRole) {
      this.setDiscountEligibility = false;
    }
    if (this.offerProvider.isGeneralDiscountEligible) {
      this.discountHolderRequired = true;
    }
    this.offerProviderCreationForm.get('paymentMethod').setValue(PaymentMethod.ThroughGolp);
  }

  onChangeDiscountEligibility(event: MatCheckboxChange) {
    if (this.discountHolderRequired === true) {
      this.discountHolderRequired = false;
    }
    else {
      this.discountHolderRequired = true;
    }
  }

  onUploadLogo(files) {
    this.fileValidationError = null;
    let fileInfo = this.fileUploadService.imageFileUpload(files);
    if (fileInfo.validationError != null) {
      this.fileValidationError = fileInfo.validationError;
      return;
    }
    this.fileName = fileInfo.fileName;
    this.uploadedFiles = fileInfo.formData;

    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imagePreviewPath = event.target.result;
    }
    reader.readAsDataURL(files[0]);
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.offerProviderCreationForm.invalid) {
      ValidationUtility.getError_Scroll(document);
      return;
    }

    if (this.offerProvider.id === undefined) { // create
      this.createOfferProvider();
    }
    else if (this.offerProvider.id > 0) { // edit
      this.editOfferProvider();
    }
    this.dialogRef.close();
  }

  createOfferProvider() {
    this.offerProvider = new Company(this.offerProviderCreationForm.value);
    if (this.offerProvider !== null) {
      this.offerProvider.companyRole = CompanyRole.GiftSender;
      this.offerProvider.maxDiscountHolder = 0;
      if (this.offerProvider.companyType == null) {
        this.offerProvider.companyType = CompanyType.General;
      }
    }

    this.companyService.createCompany(this.offerProvider)
      .subscribe((data: any) => {
        let logoUpload = false;
        // upload company logo
        if (this.uploadedFiles != null) {
          logoUpload = true;
          this.uploadedFiles.append('id', data.id)
          this.companyService.uploadFile(this.uploadedFiles).subscribe((result: any) => {
            this.offerProvider.id = data.id;
            this.offerProvider.name = data.name;
            this.offerProvider.address = data.address;
            this.offerProvider.companyType = data.companyType;
            this.offerProvider.companyCategoryId = data.companyCategoryId;
            this.offerProvider.email = data.email;
            this.offerProvider.phone = data.phone;
            this.offerProvider.shortName = data.shortName;
            this.offerProvider.webAddress = data.webAddress;
            this.offerProvider.companyLogoUrl = this.imagePreviewPath;
            this.offerProvider.companyCategory = this.companyCategories.filter(c => c.id === this.offerProvider.companyCategoryId)[0].name;
            this.onOfferProviderCreateEvent.emit(this.offerProvider);
            this.alertService.success("Gift sender created successfully");
          },
            (error: any) => {
              this.showErrorMessage(error);
            });
        }
        if (!logoUpload) {
          this.offerProvider.id = data.id;
          this.offerProvider.name = data.name;
          this.offerProvider.address = data.address;
          this.offerProvider.companyType = data.companyType;
          this.offerProvider.companyCategoryId = data.companyCategoryId;
          this.offerProvider.email = data.email;
          this.offerProvider.phone = data.phone;
          this.offerProvider.shortName = data.shortName;
          this.offerProvider.webAddress = data.webAddress;
          this.offerProvider.companyLogoUrl = this.imagePreviewPath;
          this.offerProvider.companyCategory = this.companyCategories.filter(c => c.id === this.offerProvider.companyCategoryId)[0].name;
          this.onOfferProviderCreateEvent.emit(this.offerProvider);
          this.alertService.success("Gift sender created successfully");
        }

      },
        (error: any) => {
          this.showErrorMessage(error);
        })
  }

  editOfferProvider() {
    let newData = new Company(this.offerProviderCreationForm.value);
    if (this.offerProvider != null) {
      this.offerProvider.companyCategoryId = newData.companyCategoryId;
      this.offerProvider.companyType = newData.companyType;
      this.offerProvider.paymentMethod = newData.paymentMethod;
      this.offerProvider.name = newData.name;
      this.offerProvider.shortName = newData.shortName;
      this.offerProvider.phone = newData.phone;
      this.offerProvider.email = newData.email;
      this.offerProvider.address = newData.address;
      this.offerProvider.webAddress = newData.webAddress;
      this.offerProvider.isGeneralDiscountEligible = newData.isGeneralDiscountEligible;
      this.offerProvider.maxDiscountHolder = newData.maxDiscountHolder;
      if (!this.offerProvider.isGeneralDiscountEligible) {
        this.offerProvider.maxDiscountHolder = 0;
      }

      this.companyService.editCompany(this.offerProvider)
        .subscribe((data: any) => {
          let logoChanged = false;
          //change logo if updated
          if (this.uploadedFiles != null) {
            logoChanged = true;
            // upload company logo
            this.uploadedFiles.append('id', this.offerProvider.id.toString())
            this.companyService.uploadFile(this.uploadedFiles).subscribe((result: any) => {
              this.offerProvider.companyLogoUrl = result.companyLogoUrl;

              this.offerProvider.name = data.name;
              this.offerProvider.address = data.address;
              this.offerProvider.companyType = data.companyType;
              this.offerProvider.companyCategoryId = data.companyCategoryId;
              this.offerProvider.email = data.email;
              this.offerProvider.phone = data.phone;
              this.offerProvider.shortName = data.shortName;
              this.offerProvider.webAddress = data.webAddress;
              this.offerProvider.companyCategory = this.companyCategories.filter(c => c.id === this.offerProvider.companyCategoryId)[0].name;
              this.onOfferProviderEditEvent.emit(this.offerProvider);
              this.alertService.success("Gift sender edited successfully");
            },
              (error: any) => {
                this.showErrorMessage(error);
              });
          }
          if (!logoChanged) {
            this.offerProvider.name = data.name;
            this.offerProvider.address = data.address;
            this.offerProvider.companyType = data.companyType;
            this.offerProvider.companyCategoryId = data.companyCategoryId;
            this.offerProvider.email = data.email;
            this.offerProvider.phone = data.phone;
            this.offerProvider.shortName = data.shortName;
            this.offerProvider.webAddress = data.webAddress;
            this.offerProvider.companyCategory = this.companyCategories.filter(c => c.id === this.offerProvider.companyCategoryId)[0].name;
            this.onOfferProviderEditEvent.emit(this.offerProvider);
            this.alertService.success("Gift sender edited successfully");
          }
        },
          (error: any) => {
            this.showErrorMessage(error);
          })
    }
  }

  getCompanyCategories() {
    this.companyCategoryService.getCompanyCategories()
      .subscribe(data => {
        this.companyCategories = data;
        this.filteredCompanyCategories.next(this.companyCategories.slice());
      });
  }

  getLoggedInUser() {
    this.userInfo = this.accountService.getLoggedInUserInfo();
    // if(!this.userInfo.isSystemAdmin && (CompanyRole.Merchant == this.userInfo.companyRole || CompanyRole.OfferProvider == this.userInfo.companyRole)){
    //   this.router.navigate(['/'])
    // }
  }

  showErrorMessage(error: any) {
    console.log(error);
    //this.alertService.error("Internal server error happen");
  }

  close() {
    this.dialogRef.close();
  }

  openFileBrowser(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById('offerProviderPhotoId') as HTMLElement;
    element.click();
  }

  // convenience getter for easy access to form fields
  get f() { return this.offerProviderCreationForm.controls; }

  bindFilterChangeEvent() {
    this.companyCategoryFilterCtrl.valueChanges
      .subscribe(() => {
        this.filterCompanyCategories();
      });
  }

  protected filterCompanyCategories() {
    if (!this.companyCategories) {
      return;
    }

    let search = this.companyCategoryFilterCtrl.value;
    if (!search) {
      this.filteredCompanyCategories.next(this.companyCategories);
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCompanyCategories.next(
      this.companyCategories.filter(category => category.name.toLowerCase().indexOf(search) > -1)
    );
  }
}
