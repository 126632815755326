import { UserType } from "../enums";

export class ServiceConsumer {
  id: number;
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  userType: UserType;
  password: string;
  public constructor(init?: Partial<ServiceConsumer>) {
    Object.assign(this, init);
  }
}
