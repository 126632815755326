import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';

@Component({
  selector: 'app-create-gift-resend-policy',
  templateUrl: './create-gift-resend-policy.component.html',
  styleUrls: ['./create-gift-resend-policy.component.css']
})

export class CreateGiftResendPolicyComponent implements OnInit {
    @Output() giftResendPolicyEmitter: EventEmitter<any> = new EventEmitter<any>();

    keyValuesJson: any;
    keyValuesJsonArray: any;

    public giftResendPolicySettingForm: FormGroup;
    @Input() isSubmitted: false;

    submitted = false;

    @Input() existingGiftResendPolicySetting: any;

    constructor(private _fb: FormBuilder) { }

    ngOnInit() {
        this.giftResendPolicySettingForm = this._fb.group({
            itemRows: this._fb.array([this.initItemRows()])
        });

        this.setFormArrayValue();
    }

    public setFormArrayValue() {
        if (this.existingGiftResendPolicySetting) {
            this.deleteFirstEmptyRow();

            this.keyValuesJson = JSON.parse(this.existingGiftResendPolicySetting);

            //this.keyValuesJsonArray = this.keyValuesJson.split(',');
            this.keyValuesJsonArray = this.keyValuesJson;

            var i = 0;
            Object.entries(this.keyValuesJsonArray).forEach(element => {
                this.formArr.push(this.initItemRowsForEdit(element));
                i = i + 1;
            });
            this.setKeyValuesJsonAndEmit();
        }
    }

    get formArr() {
        return this.giftResendPolicySettingForm.get('itemRows') as FormArray;
    }

    initItemRowsForEdit(element: any) {
        return this._fb.group({
            chargeAmount: [element[1].chargeAmount]
        });
    }

    initItemRows() {
        return this._fb.group({
            chargeAmount: ['']
        });
    }

    addItemRows() {
        return this._fb.group({
            chargeAmount: ['']
        });
    }

    addNewRow() {
        this.formArr.push(this.addItemRows());
        this.setKeyValuesJsonAndEmit();
    }

    deleteFirstEmptyRow() {
        this.formArr.removeAt(0);
        this.setKeyValuesJsonAndEmit();
    }

    deleteRow(index: number) {
        this.formArr.removeAt(index);
        this.setKeyValuesJsonAndEmit();
    }

    setKeyValuesJson(): void {
        var customArray = this.giftResendPolicySettingForm.controls.itemRows.value;
        //var object = {};

        //customArray.forEach((element1) => {
        //    //var key = element1.key;
        //    //if (key)
        //    //    object[key] = chargeAmount;
        //    var chargeAmount = element1.chargeAmount;
        //    if (chargeAmount)
        //        object[chargeAmount] = chargeAmount;
        //});

        //this.keyValuesJson = JSON.stringify(customArray);

        this.keyValuesJson = customArray;

    }

    setKeyValuesJsonAndEmit(): void {
        this.setKeyValuesJson();
        this.giftResendPolicyEmitter.emit(this.keyValuesJson);
    }

    get formData() { return <FormArray>this.giftResendPolicySettingForm.get('itemRows'); }

}


