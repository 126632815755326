import { Component, OnInit, Injector, EventEmitter, Output } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { Offer, GetOffersPagedListInput } from '../../models';
import { OfferAssignmentService, CommonService, CompanyService, AlertService } from '../../services';
import { GiftSentProcessComponent } from '../gift-sent-process/gift-sent-process.component';
import { CompanyRole } from '../../enums';
import { offerfiltertype } from '../../enums/offer-filter-type';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-consumergift',
  templateUrl: './consumergift.component.html',
  styleUrls: ['./consumergift.component.css']
})

export class ConsumergiftComponent extends BaseComponent implements OnInit {
  @Output() onOfferSplitEvent: EventEmitter<any> = new EventEmitter();
  constructor(private offerAssignmentService: OfferAssignmentService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private commonService: CommonService, private companyService: CompanyService,
    injector: Injector
  ) { super(injector) }

  userInfo: any;
  dataLoaded: Promise<boolean>;
  pageTitle: string = "Gifts Received";

  currentPage: number = 1;
  pageSize: number = 10;
  itemCountText: string;
  totalItems: number;

  offerSubMenu: any[] = [];
  activeSubMenu: string = 'Details';
  selectedSubMenuIndex: number = 0;

  merchants: any[];
  merchantSelection: string = "Merchant";
  merchantFilter: any = { name: '' };
  searchedMerchantLogo: string = null;
  showMerchantSearch: boolean = false;
  selectedMerchantId: number = 0;

  selectedOffer: any;
  selectedOfferName: string;
  selectedOfferIndex: number = -1;

  offers: any[];
  offerFilter: any = { offerName: '' };

  ngOnInit() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    console.log("ngInit");
    this.userInfo = userInfo;
    this.getConsumerGifts();
    this.getMerchants();
    this.dataLoaded = Promise.resolve(true);
  }

  giftFilteredItem(value) {
    this.selectedOfferName = value;
    this.getConsumerGifts();
  }
  getConsumerGifts() {
    var input = new GetOffersPagedListInput();

    input.giftSenderId = null;
    input.offerFilterType = offerfiltertype.None;
    input.consumerId = this.userInfo.consumerId;
    input.giftName = this.selectedOfferName;
    input.pageNumber = this.currentPage;
    input.pageSize = this.pageSize;

    if (this.selectedMerchantId == 0)
      input.merchantId = null;
    else
      input.merchantId = this.selectedMerchantId;

    this.offerAssignmentService.getConsumerOffersPagedList(input)
      .subscribe(data => {
        this.offers = data.data;
        this.totalItems = data.rowCount;

        this.highlightFirstItem(this.offers);
        this.itemCount();
      });
  }
  getMerchants() {
    this.companyService.getMerchantBasics()
      .subscribe(data => {
        this.merchants = data;

        if (data.length > 1 || !(CompanyRole.Merchant === this.userInfo.companyRole && !this.userInfo.isSystemAdmin)) {
          this.showMerchantSearch = true;
        }
      });
  }
  highlightFirstItem(offers: any[]) {
    if (this.offers !== null) {
      this.selectedOffer = this.offers[0];
      //this.setOutletScopeText();
      this.selectedOfferIndex = 0;
      this.dataLoaded = Promise.resolve(true);
    }
  }

  sendGift() {
    const dialogConfig = new MatDialogConfig();
    // Setting different dialog configurations
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'golp-dialog';
    const offerCreateDialog = this.dialog.open(GiftSentProcessComponent, dialogConfig);

    const successfullCreate = offerCreateDialog.componentInstance.onSentGiftEvent.subscribe((data) => {
      //this.getOfferById(data.id);
      this.alertService.success("Gift sent successfully");

    });

    // offerCreateDialog.afterClosed().subscribe(() => {
    // });
  }


  selectAllMerchant() {
    this.searchedMerchantLogo = null;
    this.merchantSelection = 'Merchant';
    this.selectedMerchantId = 0;
    this.getConsumerGifts();
  }
  onOfferChange(offer, index) {
    if (this.selectedOfferIndex != index) {
      this.selectedOfferIndex = index === this.selectedOfferIndex ? -1 : index;
      this.selectedOffer = offer;
      //this.setOutletScopeText();
    }
    let body = document.getElementsByTagName('body')[0];
    body.classList.add("display-tab-content");
    this.commonService.itemSelectionUpdated(this.selectedOffer);
  }

  itemCount() {
    let firstItem = (this.currentPage - 1) * this.pageSize + 1;
    let lastItem = (this.currentPage) * this.pageSize;
    if (lastItem > this.totalItems)
      lastItem = this.totalItems;
    this.itemCountText = firstItem + '-' + lastItem + ' of ' + this.totalItems + ' Gifts';
  }
  pageChanged(event) {
    this.selectedOfferIndex = 0;
    this.currentPage = event;
    this.getConsumerGifts();
    //this.setOutletScopeText();
  }
  onMerchantChange(merchant, index) {
    this.selectedMerchantId = merchant.id;
    this.searchedMerchantLogo = merchant.companyLogoUrl;
    this.merchantSelection = merchant.name.split(' ').slice(0, 2).join(' ');

    this.getConsumerGifts();
  }
  onMarkAsFavorite(offer: any, status) {
    offer.markAsFavorite = status
    this.offerAssignmentService.markOfferAssignmentAsFavorite(offer).subscribe((data) => {
      this.getConsumerGifts();
    });
  }

  getOfferSplittedChange(event) {
    this.getConsumerGifts();
  }

  getOfferDetailsChange(event) {
    var offer = this.offers.find(x => x.id == event.id);

    if (offer) {
      var index = this.offers.indexOf(offer);

      if (index !== -1) {
        this.offers[index] = event; // event is basically offer
      }

    }
  }
}
