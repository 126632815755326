import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-json-viewer',
  templateUrl: './json-viewer.component.html',
  styleUrls: ['./json-viewer.component.css']
})
export class JsonViewerComponent implements OnInit {

  headers:any;
  title:string;

  jsonData = {
    "giftPrice": "2500",
    "gift": "2500 tk ",
    "shortName": [{
      "giftPrice": "2500",
      "gift": "2500 tk "
    },
    {
      "giftPrice": "2500",
      "gift": "2500 tk "
    },
    {
      "giftPrice": "2500",
      "gift": "2500 tk "
    }] 
  }

  data:any;

  constructor(private dialogRef: MatDialogRef<JsonViewerComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.jsonData = JSON.parse(data.data);
    this.title = this.makeTitle(data.title);
    // console.log(this.jsonData);
    this.headers = Object.keys(this.jsonData);
   }

  
  ngOnInit(): void {
  }

  isArray(input) {
    return Object.prototype.toString.call(input) === '[object Array]';
  }

  makeTitle(input){
    input = input.charAt(0).toUpperCase() + input.slice(1);;
    input = input.replace(/([A-Z])/g, ' $1').trim()
    return input;
  }

  close() {
    this.dialogRef.close();
  }

}
