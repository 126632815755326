import { Component, OnInit } from '@angular/core';
import { ReportService } from 'src/app/services/report.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
import { GiftTransactionReport } from '../../models/gift-transacion-report';
import { GetGiftTransactionReportInputDto } from '../../models/gift-transaction-report-input';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ShowOfferAssignmentDetailsComponent } from '../show-offer-assignment-details/show-offer-assignment-details.component';
import { SharedService } from '../../services/shared.service';
import { CreditUsageReportInputDto, PremiumUserCreditUsageReportOutput, PremiumUserCreditLimitExceedOutput } from '../../models';
import { BlockUI, NgBlockUI } from 'ng-block-ui';


@Component({
  selector: 'app-premium-users-credit-limit-exceeded-report',
  templateUrl: './premium-users-credit-limit-exceeded-report.component.html',
  styleUrls: ['./premium-users-credit-limit-exceeded-report.component.css']
})

export class PremiumUsersCreditLimitExceededReportComponent implements OnInit {
    @BlockUI('block-section') blockUI: NgBlockUI;

    premiumUserCreditUsageReportForm: FormGroup;
    input: CreditUsageReportInputDto;
    filterTypes: any[];
    paymentMethods: any[];
    paymentStatuses: any[];

    currentPage: number = 1;
    pageSize: number = 25;
    expandedIndex: number = -1;
    totalItems: number = 0;

    defaultStartDate: Date;
    defaultEndDate: Date;
    paginationPageNumbers: any[];
    currentPageNumberDetails: string;


    constructor(private formBuilder: FormBuilder, private reportService: ReportService,
        private dialog: MatDialog, private sharedService: SharedService) {
        //this.filterTypes = [{ text: 'All', value: 0 }, { text: 'Active', value: 1 }, { text: 'In Active', value: 2 }];
        this.filterTypes = [{ text: 'All', value: 0 }, { text: 'Active', value: 1 }, { text: 'In Active', value: 2 }];
        this.paymentMethods = [{ text: 'All', value: '' }, { text: 'bKash', value: 1 }, { text: 'Card', value: 3 }];
        this.paymentStatuses = [{ text: 'All', value: '' }, { text: 'None', value: 0 }, { text: 'In Progress', value: 5 }, { text: 'Failed', value: 10 }];

        this.input = new GetGiftTransactionReportInputDto();

        var startDate1 = new Date();
        //startDate1.setDate(startDate1.getDate() - 7);
        this.defaultStartDate = startDate1;
        this.defaultEndDate = new Date();
        this.buildForm();
    }

    ngOnInit() {
        this.paginationPageNumbers = this.sharedService.getPaginationByPageNumbers();
        this.getAllPremiumUserCreditExceedReport();
    }

    creditLimitExceeded: PremiumUserCreditLimitExceedOutput[] = [];


    buildForm() {
        this.premiumUserCreditUsageReportForm = this.formBuilder.group({
            fromDate: [this.defaultStartDate, Validators.required],
            toDate: [this.defaultEndDate, Validators.required],
            type: 1,
            paidThrough: '',
            paymentStatus: ''
        });
    }


    expandCollapse(index) {
        this.expandedIndex = index === this.expandedIndex ? -1 : index;
    }


    getAllPremiumUserCreditExceedReport() {
        this.input.pageNumber = this.currentPage;
        this.input.pageSize = this.pageSize;

        this.blockUI.start();
        this.reportService.getPremiumUserCreditLimitExceededSummaryReport(this.input).subscribe(result => {
            this.creditLimitExceeded = result.data;
            this.totalItems = result.rowCount;
            this.currentPageNumberDetails = "(Showing " + result.firstRowOnPage + " to " + result.lastRowOnPage + " of " + result.rowCount + " items)"
            this.blockUI.stop();

        }, error => {
                this.blockUI.stop();
        });
    }

    showOfferAssignments(model: any) {
        const userDialogConfig = new MatDialogConfig();

        // Setting different dialog configurations
        userDialogConfig.disableClose = true;
        userDialogConfig.autoFocus = true;
        userDialogConfig.data = model;
        userDialogConfig.panelClass = 'golp-dialog';

        const showOfferAssignmentDialog = this.dialog.open(ShowOfferAssignmentDetailsComponent, userDialogConfig);

        //showOfferAssignmentDialog.componentInstance.companyRoleType = this.companyRoleType;
    }

    get f() { return this.premiumUserCreditUsageReportForm.controls; }


    exportToExcel() {
        this.input.pageNumber = this.currentPage;
        this.input.pageSize = this.pageSize;


        this.reportService.getPremiumUserCreditUsageSummaryExcelReport(this.input)
            .subscribe(data => this.downloadFile(data)),//console.log(data),
            error => console.log("Error downloading the file."),
            () => console.log('Completed file download.');
    }


    downloadFile(data) {
        var filterText = this.filterTypes.filter(x => x.value == this.f.type.value)[0].text;
        const blob2 = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        var filename = filterText + "-" + "ConsumerGiftReports -" + moment().format('MMMM Do YYYY, h:mm:ss').valueOf() + ".xlsx";
        saveAs(blob2, filename);
    }

    pageChanged(event) {
        this.currentPage = event;
        this.getAllPremiumUserCreditExceedReport();
    }

    itemCount() {
        let firstItem = (this.currentPage - 1) * this.pageSize + 1;
        let lastItem = (this.currentPage) * this.pageSize;
    }
}


